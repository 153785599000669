import { User } from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwActionLabel,
  SwAlert,
  SwColumn,
  SwFormGrid,
  SwModal,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { RequiredStar } from '@kaa/ui-flanders/components/form-message/SwFormRequiredStar';
import { FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi, useDispatchUpdateUserEmail } from '../../../../utils';
import {
  EventAction,
  EventLabel,
  sendCTAEventToGTM,
  sendModalInteractionToGTM,
  sentPageViewEvent,
} from '../../../../utils/google-analytics';
import { handleApiError } from '../../../../utils/validation';
import {
  ProfileUserInfoChangeEmailFormState,
  ProfileUserInfoChangePasswordFormState,
} from '../ProfileScreen.type';
import { ProfileUserInfoChangeEmailModal } from './ProfileUserInfoChangeEmailModal';
import { ProfileUserInfoChangePasswordModal } from './ProfileUserInfoChangePasswordModal';
import { ProfilUserInfoFormInput } from './ProfileUserInfoFormInput';

type ProfileUserInfoFormProps = {
  user: User;
};

export const ProfileUserInfoForm = ({ user }: ProfileUserInfoFormProps) => {
  const { t } = useTranslation();
  const dispatchUpdateUserEmail = useDispatchUpdateUserEmail();
  const [emailFormStatus, setEmailFormStatus] = useState<{
    msg: string;
    type: AlertType;
  } | null>(null);
  const [passwordFormStatus, setPasswordFormStatus] = useState<{
    msg: string;
    type: AlertType;
  } | null>(null);

  const { users } = useApi();
  const { emailAddress, login, lastName, firstName } = user;

  const [{ value: submitedEmailForm }, submitNewEmail] = useAsyncCallback(
    async (
      formikData: ProfileUserInfoChangeEmailFormState,
      formikActions: FormikActions<ProfileUserInfoChangeEmailFormState>,
    ) => {
      const [error, response] = await httpTo(
        users.putEmail({
          newEmail: formikData.emailAddress,
          oldEmail: emailAddress,
        }),
      );
      sentPageViewEvent();
      return { error, response, formikData, formikActions };
    },
    [emailAddress],
  );

  useEffect(() => {
    if (submitedEmailForm) {
      const { error, formikActions, formikData } = submitedEmailForm;
      const { emailAddress } = formikData;

      if (handleApiError(error, formikActions)) {
        return;
      }

      dispatchUpdateUserEmail(emailAddress);
      setEmailFormStatus({
        msg: t(i18nKeys.profile.successMessage),
        type: AlertType.SUCCESS,
      });
      toggleModalById(Modals.CHANGE_EMAIL_MODAL_ID);
    }
  }, [submitedEmailForm]);

  const [{ value: submitedPasswordForm }, submitNewPassword] = useAsyncCallback(
    async (
      formikData: ProfileUserInfoChangePasswordFormState,
      formikActions: FormikActions<ProfileUserInfoChangePasswordFormState>,
    ) => {
      const { newPassword, oldPassword } = formikData;
      const [error, response] = await httpTo(
        users.putPassword({
          newPassword,
          oldPassword,
        }),
      );
      sentPageViewEvent();
      return { error, response, formikData, formikActions };
    },
  );

  useEffect(() => {
    if (submitedPasswordForm) {
      const { error, formikActions } = submitedPasswordForm;

      if (handleApiError(error, formikActions)) {
        return;
      }

      setPasswordFormStatus({
        msg: t('profile.successMessage'), // TODO: i18n
        type: AlertType.SUCCESS,
      });
      toggleModalById(Modals.CHANGE_PASSWORD_MODAL_ID);
    }
  }, [submitedPasswordForm]);

  return (
    <SwFormGrid modStacked>
      <ProfilUserInfoFormInput
        name="userFirstName"
        label={t(i18nKeys.general.label.firstName)}
        value={firstName}
        modRequired
      />

      <ProfilUserInfoFormInput
        name="userLastName"
        label={t(i18nKeys.general.label.name)}
        value={lastName}
        modRequired
      />
      {user.isLocalLogin && (
        <>
          <ProfilUserInfoFormInput
            name="userAccountName"
            label={t(i18nKeys.profile.accountName)}
            value={login}
            modRequired
          />

          <SwActionLabel
            id="editLoginEmailAddress"
            label={
              <>
                {t(i18nKeys.general.label.emailAddress)}
                <RequiredStar />
              </>
            }
            value={emailAddress}
            onClick={() => {
              toggleModalById(Modals.CHANGE_EMAIL_MODAL_ID);
              sendCTAEventToGTM(
                EventLabel.PROFILE_CHANGE_EMAIL_MODAL,
                EventAction.OPEN_MODAL,
              );
              sentPageViewEvent({
                pathname: '/new-email-modal',
              });
            }}
          />

          {emailFormStatus && (
            <SwColumn>
              <SwAlert
                {...getAlertPropsByType(emailFormStatus.type)}
                modSmall
                closable
                close={() => setEmailFormStatus(null)}
              >
                {emailFormStatus.msg}
              </SwAlert>
            </SwColumn>
          )}

          <SwActionLabel
            id="editLoginPassword"
            label={
              <>
                {t(i18nKeys.profile.currentPassword)}
                <RequiredStar />
              </>
            }
            value="***********"
            linkText={t(i18nKeys.profile.changePassword)}
            onClick={() => {
              toggleModalById(Modals.CHANGE_PASSWORD_MODAL_ID);
              sendCTAEventToGTM(
                EventLabel.PROFILE_CHANGE_PASSWORD_MODAL,
                EventAction.OPEN_MODAL,
              );
              sentPageViewEvent({
                pathname: '/new-password-modal',
              });
            }}
          />

          {passwordFormStatus && (
            <SwColumn>
              <SwAlert
                {...getAlertPropsByType(passwordFormStatus.type)}
                modSmall
                closable
                close={() => setPasswordFormStatus(null)}
              >
                {passwordFormStatus.msg}
              </SwAlert>
            </SwColumn>
          )}

          <SwModal
            id={Modals.CHANGE_EMAIL_MODAL_ID}
            closable
            onClose={() => {
              sendModalInteractionToGTM(
                EventLabel.PROFILE_CHANGE_EMAIL_MODAL,
                EventAction.CLOSE_MODAL,
              );
              sentPageViewEvent();
            }}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            userEmailAddress={emailAddress}
            onSubmit={submitNewEmail}
            component={ProfileUserInfoChangeEmailModal}
          />
          <SwModal
            id={Modals.CHANGE_PASSWORD_MODAL_ID}
            closable
            onClose={() => {
              sendModalInteractionToGTM(
                EventLabel.PROFILE_CHANGE_PASSWORD_MODAL,
                EventAction.CLOSE_MODAL,
              );
              sentPageViewEvent();
            }}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            onSubmit={submitNewPassword}
            component={ProfileUserInfoChangePasswordModal}
          />
        </>
      )}
    </SwFormGrid>
  );
};
