import { i18nKeys } from '@kaa/i18n/common/keys';
import React, { AllHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { SwButton } from '../button/SwButton';
import { SwIntroduction } from '../introduction/SwIntroduction';
import { SwTitle } from '../title/SwTitle';

type FetchErrorMessageProps<T = HTMLElement> = AllHTMLAttributes<T> & {
  title?: string;
  subtitle?: string;
  cta?: string;
  redirectTo?: string;
  onClick?: () => void;
};

export const SwFetchErrorMessage = ({
  title = i18nKeys.errors.serviceUnavailableScreen.title,
  subtitle = i18nKeys.errors.serviceUnavailableScreen.subTitle,
  cta = i18nKeys.errors.serviceUnavailableScreen.cta,
  redirectTo,
  onClick,
}: FetchErrorMessageProps) => {
  const { t } = useTranslation();

  if (redirectTo && onClick) {
    throw new Error(
      'Props redirectTo and onClick can not be provided in same time',
    );
  }

  return (
    <>
      <SwTitle tagName="h1" className="vl-u-spacer--medium">
        {t(title)}
      </SwTitle>
      <SwIntroduction className="vl-u-spacer--large">
        {t(subtitle)}
      </SwIntroduction>
      {redirectTo && <SwButton to={redirectTo}>{t(cta)}</SwButton>}
      {onClick && <SwButton onClick={onClick}>{t(cta)}</SwButton>}
    </>
  );
};
