import { ApiMode } from '@kaa/api/common';
import { RegionCode } from '@kaa/api/customers';
import { AuthProvider, AuthService, AuthServiceMock } from '@kaa/auth/common';
import {
  Logout,
  ChangeTitleRoute,
  ErrorCrashTrackingBoundary,
  RemoveIfInIframe,
  RootErrorCrashTrackingBoundary,
} from '@kaa/core-app/common/components';
import { ActionProvider, AlertProvider } from '@kaa/common/context';
import { AvailableLanguages } from '@kaa/common/enums/AvailableLanguages';

import { BuildConfig, getConfig } from '@kaa/core-app/common/config';
import {
  ActiveRouteProvider,
  Blocked,
  Breadcrumb,
  DeveloperConsole,
  IdleUserTimeout,
  LoadAuthUser,
  LoadUser,
  PageViewGTMListener,
  PrivateRoute,
  VlFunctionalHeader,
} from '@kaa/core-app/customers/components';
import { UserAccessTokenExpiringListener } from '@kaa/core-app/customers/components/headless/UserAccessTokenExpiringListener';
import { UserSignedOutListener } from '@kaa/core-app/customers/components/headless/UserSignedOutListener';
import { TITLE_ROUTES } from '@kaa/core-app/customers/constants/titleRoutes';
import { getI18nInstance } from '@kaa/core-app/customers/i18n';
import { getPath, getRouterPath, Routes } from '@kaa/core-app/customers/routes';
import {
  AccountScreen,
  BlockedScreen,
  DashboardScreen,
  FiscalAttestationScreen,
  ForbiddenWrongRegion,
  LanguageRequestScreen,
  MaintenanceScreen,
  MyOrderDetailPendingScreen,
  MyOrderDetailScreen,
  MyOrderScreen,
  NotFoundScreen,
  PlanALeaveScreen,
  PreferencesScreen,
  PrestationsScreen,
  PrestationsWorkHistoryScreen,
  ProfileScreen,
  RegisterCompleteScreen,
  RegisterScreen,
  RegisterSummaryScreen,
  ServicesVouchersScreen,
  ServiceUnavailableScreen,
  WalletScreen,
  WorksManagementScreen,
} from '@kaa/core-app/customers/screens';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwMain, SwPage } from '@kaa/ui-flanders/components';
import { Redirect, Router } from '@reach/router';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { ConfigLoadFailedFallbackWithTranslation } from './components/ConfigLoadFailedCallbackWithTranslation';
import { Footer } from './components/Footer';
import { Header } from './components/header/Header';
import { ChangeLanguage } from './components/headless/ChangeLanguage';
import { UnknownUserListener } from './components/unknown-user-listener/UnknownUserListener';
import { HandiwebRequestScreen } from './screens/handiweb-request/HandiwebRequestScreen';
import { LandingScreen } from './screens/LandingScreen';
import { LoginRedirectScreen } from './screens/LoginRedirectScreen';

export const App: React.FC = () => {
  const config = getConfig();

  if (!config) {
    return <ServiceUnavailableScreen />;
  }

  const {
    app: {
      mode,
      auth: { oidc },
    },
    buildConfig,
  } = config;

  const { id, regionCode } = buildConfig;

  const initialState =
    mode === ApiMode.MOCK
      ? new AuthServiceMock()
      : new AuthService(
          {
            ...oidc,
            monitorSession: false,
          },
          buildConfig as BuildConfig<RegionCode>,
        );

  return (
    <RootErrorCrashTrackingBoundary
      appId={id}
      regionCode={regionCode as RegionCode}
      config={config}
      user={null}
      actions={null}
      errorComponent={ConfigLoadFailedFallbackWithTranslation}
    >
      <div className="vl-u-sticky-gf">
        <I18nextProvider i18n={getI18nInstance()}>
          <AuthProvider initialState={initialState}>
            <ActionProvider>
              <AlertProvider>
                <ErrorCrashTrackingBoundary
                  appId={id}
                  regionCode={regionCode as RegionCode}
                  config={config}
                >
                  <ChangeLanguage />
                  <IdleUserTimeout />
                  <UnknownUserListener />
                  <UserSignedOutListener
                    redirectUrl={getRouterPath(Routes.LANDING)}
                  />
                  <UserAccessTokenExpiringListener />
                  <RemoveIfInIframe>
                    <PageViewGTMListener />
                    <LoadAuthUser />
                    <LoadUser />
                    <Header />
                    <DeveloperConsole />
                  </RemoveIfInIframe>
                  <SwPage>
                    <SwMain>
                      <RemoveIfInIframe>
                        <ActiveRouteProvider>
                          {({ activeRoute }) => (
                            <>
                              <Blocked activeRoute={activeRoute} />
                              <ChangeTitleRoute
                                activeRoute={activeRoute}
                                titleRoutes={TITLE_ROUTES}
                              />
                              <Breadcrumb activeRoute={activeRoute} />
                              <VlFunctionalHeader activeRoute={activeRoute} />
                            </>
                          )}
                        </ActiveRouteProvider>
                      </RemoveIfInIframe>
                      <Router className="mainContent" primary={false}>
                        <LandingScreen
                          path={getRouterPath(Routes.LANDING)}
                          default
                        />
                        <NotFoundScreen path={getRouterPath(Routes.PAGE_404)} />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LANDING,
                          )}
                          component={BlockedScreen}
                          path={getRouterPath(Routes.BLOCKED)}
                        />

                        <ServiceUnavailableScreen
                          path={getRouterPath(Routes.SERVICE_UNAVAILABLE)}
                        />
                        <ForbiddenWrongRegion
                          path={getRouterPath(Routes.FORBIDDEN_WRONG_REGION)}
                          links={[
                            {
                              id: 1,
                              href:
                                i18nKeys.bl.general.serviceVouchers.website.url,
                              text: i18nKeys.bl.general.region.name,
                            },
                            {
                              id: 2,
                              href:
                                i18nKeys.wl.general.serviceVouchers.website.url,
                              text: i18nKeys.wl.general.region.name,
                            },
                          ]}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LANDING,
                          )}
                          path={getRouterPath(Routes.DASHBOARD)}
                          component={DashboardScreen}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LANDING,
                          )}
                          path={getRouterPath(Routes.ACCOUNT)}
                          component={AccountScreen}
                        >
                          <Redirect
                            from="*"
                            to={getPath(Routes.ACCOUNT_PROFILE)}
                            noThrow
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(Routes.ACCOUNT_PROFILE)}
                            component={ProfileScreen}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(Routes.ACCOUNT_PREFERENCES)}
                            component={PreferencesScreen}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(
                              Routes.ACCOUNT_FISCAL_ATTESTATION,
                            )}
                            component={FiscalAttestationScreen}
                          />
                        </PrivateRoute>
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LANDING,
                          )}
                          path={getRouterPath(Routes.PRESTATIONS)}
                          component={PrestationsScreen}
                        >
                          <Redirect
                            from="*"
                            to={getPath(Routes.PRESTATIONS_WORKS_MANAGEMENT)}
                            noThrow
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(
                              Routes.PRESTATIONS_WORKS_MANAGEMENT,
                            )}
                            component={WorksManagementScreen}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(
                              Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY,
                            )}
                            component={PrestationsWorkHistoryScreen}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(
                              Routes.PRESTATIONS_PLAN_A_LEAVE,
                            )}
                            component={PlanALeaveScreen}
                          />
                        </PrivateRoute>
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LANDING,
                          )}
                          path={getRouterPath(Routes.SERVICES_VOUCHERS)}
                          component={ServicesVouchersScreen}
                        >
                          <Redirect
                            from="*"
                            to={getPath(Routes.SERVICES_VOUCHERS_WALLET)}
                            noThrow
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(
                              Routes.SERVICES_VOUCHERS_WALLET,
                            )}
                            component={WalletScreen}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(Routes.SERVICES_VOUCHERS_ORDER)}
                            component={MyOrderScreen}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(
                              Routes.SERVICES_VOUCHERS_ORDER_DETAILS,
                            )}
                            component={MyOrderDetailScreen}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LANDING,
                            )}
                            path={getRouterPath(
                              Routes.SERVICES_VOUCHERS_ORDER_PENDING,
                            )}
                            component={MyOrderDetailPendingScreen}
                          />
                        </PrivateRoute>
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LANDING,
                          )}
                          path={getRouterPath(Routes.LANGUAGE_REQUEST)}
                          component={LanguageRequestScreen}
                          language={AvailableLanguages.FRENCH}
                        />
                        <PrivateRoute
                          unauthenticatedRedirectUrl={getRouterPath(
                            Routes.LANDING,
                          )}
                          path={getRouterPath(Routes.HANDIWEB_REQUEST)}
                          component={HandiwebRequestScreen}
                        />
                        <RegisterScreen path={getRouterPath(Routes.REGISTER)} />
                        <RegisterCompleteScreen
                          path={getRouterPath(Routes.REGISTER_COMPLETE)}
                        />
                        <RegisterSummaryScreen
                          path={getRouterPath(Routes.REGISTER_SUMMARY)}
                        />
                        {
                          // LoginCallback is html page in public folder
                        }
                        <LoginRedirectScreen
                          path={getRouterPath(Routes.LOGIN)}
                        />
                        <LoginRedirectScreen
                          path={getRouterPath(Routes.LOGIN_OCMW)}
                          ocmw
                        />
                        <Logout path={getRouterPath(Routes.LOGOUT)} />
                        <MaintenanceScreen
                          path={getRouterPath(Routes.MAINTENANCE)}
                        />
                        {
                          // LogoutCallback is html page in public folder
                        }
                      </Router>
                    </SwMain>
                  </SwPage>
                  <RemoveIfInIframe>
                    <Footer />
                  </RemoveIfInIframe>
                </ErrorCrashTrackingBoundary>
              </AlertProvider>
            </ActionProvider>
          </AuthProvider>
        </I18nextProvider>
      </div>
    </RootErrorCrashTrackingBoundary>
  );
};
