import { City, Street } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwFormAddress,
  SwFormGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MotionDiv } from '../../../../components/animation/MotionDiv';
import { dataTest } from '../../../../datatest/keys';
import { RegisterCompleteScreenFieldNames } from '../RegisterCompleteScreen.constants';
import { RegisterFormState } from '../RegisterCompleteScreen.types';

const gridMargin = window.innerWidth < 767 ? '1.5rem' : '3rem';
const toggleDeliveryVariants = {
  close: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    marginTop: '2px',
    width: '100%',
    transitionEnd: {
      marginTop: `-${gridMargin}`,
    },
  },
  open: {
    height: 'auto',
    overflow: 'hidden',
    marginTop: gridMargin,
    opacity: 1,
    transitionEnd: {
      overflow: 'inherit',
    },
  },
};

type RegisterDeliveryAddressProps = {
  availableCities?: City[];
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
  values: Partial<RegisterFormState>;
};

export const RegisterDeliveryAddress = ({
  values,
  availableCities,
  getStreets,
}: RegisterDeliveryAddressProps) => {
  const { t } = useTranslation();
  return (
    <MotionDiv
      testid={dataTest.profile.deliveryAddressCard}
      isOpen={!values.useMainAddressForDeliveryAddress}
      transition={{ ease: 'easeInOut' }}
      variants={toggleDeliveryVariants}
    >
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--small">
        {t(i18nKeys.profile.deliveryAddress.title)}
      </SwTitle>
      <SwFormGrid modStacked>
        <SwFormAddress
          fieldNamePrefix={RegisterCompleteScreenFieldNames.DELIVERY_ADDRESS}
          availableCities={availableCities}
          getStreets={getStreets}
        />
      </SwFormGrid>
    </MotionDiv>
  );
};
