import { Vacancy } from '@kaa/api/customers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwBadge, SwIcon, useTooltip } from '@kaa/ui-flanders/components';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EventAction,
  EventCategory,
  sendCustomInteractionToGTM,
} from '../../../../utils/google-analytics';

type PlanALeaveTableRowProps = {
  deleteVacancy: (
    vacancy: Vacancy,
  ) => Promise<[Error | undefined, any | undefined]>;
  deleteIsAllowed: boolean;
  vacancy: Vacancy;
};

export const PlanALeaveTableRow = ({
  deleteVacancy,
  vacancy,
  deleteIsAllowed = false,
}: PlanALeaveTableRowProps) => {
  const { t } = useTranslation();
  const { startDate, endDate, workDays } = vacancy;
  const startDateJs = DateTime.fromISO(startDate);
  const endDateJs = DateTime.fromISO(endDate);

  const refTooltip2yearsInRaw = useTooltip({
    value: t(i18nKeys.planALeave.table.tooltip.twoYearsInRaw),
    placement: 'auto',
  });

  const [{ loading }, deleteV] = useAsyncCallback(deleteVacancy);

  const [isDeleted, setIsDeleted] = useState(false);
  const deleteRow = () => {
    deleteV(vacancy);
    sendCustomInteractionToGTM(
      EventCategory.PLAN_LEAVE,
      EventAction.CLICK,
      EventAction.DELETE,
    );
  };
  const transitions = { ease: 'easeOut', duration: 0.4 };
  const animateDiv = isDeleted
    ? { opacity: 0, height: 0, overflow: 'hidden' }
    : {};
  const animateTd = isDeleted ? { paddingTop: 0, paddingBottom: 0 } : {};

  return (
    <tr className={!deleteIsAllowed ? 'vl-u-bg-alt' : ''}>
      <motion.td
        data-title={t(i18nKeys.planALeave.table.dates)}
        style={{
          verticalAlign: 'middle',
        }}
        transition={transitions}
        animate={animateTd}
      >
        <motion.div transition={transitions} animate={animateDiv}>
          {t(i18nKeys.planALeave.absentFrom)}{' '}
          <strong>{startDateJs.toFormat('dd/LL/yyyy')} </strong>
          {t(i18nKeys.planALeave.to)}{' '}
          <strong>{endDateJs.toFormat('dd/LL/yyyy')} </strong>
          {t(i18nKeys.planALeave.included)}{' '}
          {startDateJs.get('year') !== endDateJs.get('year') ? (
            <SwBadge
              modAction
              modXsmall
              icon={Icon.INFO}
              style={{ verticalAlign: 'middle' }}
              ref={refTooltip2yearsInRaw}
            />
          ) : null}
        </motion.div>
      </motion.td>
      <motion.td
        data-title={t(i18nKeys.planALeave.table.duration)}
        style={{
          verticalAlign: 'middle',
        }}
        transition={transitions}
        animate={animateTd}
      >
        <motion.div transition={transitions} animate={animateDiv}>
          <div className="vl-u-display-flex vl-u-flex-align-center">
            <div>
              <strong>
                {t(i18nKeys.planALeave.numberOfDays, {
                  days: workDays,
                })}
              </strong>{' '}
              {t(i18nKeys.planALeave.ofAbsence)}
            </div>
          </div>
        </motion.div>
      </motion.td>
      <motion.td
        data-title=""
        style={{
          verticalAlign: 'middle',
          width: '10rem',
        }}
        transition={transitions}
        animate={animateTd}
      >
        {deleteIsAllowed && (
          <motion.div
            transition={transitions}
            animate={animateDiv}
            onAnimationComplete={deleteRow}
          >
            <button
              type="button"
              style={
                loading
                  ? {
                      color: '#cbd2da',
                      border: 'none',
                    }
                  : {
                      color: 'red',
                      border: 'none',
                    }
              }
              disabled={loading}
              onClick={() => setIsDeleted(true)}
            >
              <SwIcon modLarge icon={Icon.BIN} />
              <br />
              {t(i18nKeys.general.cta.delete)}
            </button>
          </motion.div>
        )}
      </motion.td>
    </tr>
  );
};
