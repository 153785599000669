import { HandiwebRequestBeneficiary } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { TFunction } from 'i18next';

export const isMyself = (beneficiary: HandiwebRequestBeneficiary): boolean =>
  beneficiary === HandiwebRequestBeneficiary.SELF;

export const getLabel = (
  key: string,
  beneficiary: HandiwebRequestBeneficiary,
  t: TFunction,
): string => {
  return `${t(key)}${
    beneficiary === HandiwebRequestBeneficiary.DEPENDENT
      ? ` ${t(i18nKeys.handiweb.form.labels.other)}`
      : ''
  }`;
};
