import { AuthServiceAbstract, useAuthState } from '@kaa/auth/common';
import { useAsync } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwColumn,
  SwGrid,
  SwLayout,
  SwLoader,
  SwRegion,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LoginRedirectScreen = ({
  ocmw = false,
}: RouteComponentProps<{ ocmw: boolean }>) => {
  const { t } = useTranslation();
  const { signinRedirect, signinRedirectForOCMW } = useAuthState<
    AuthServiceAbstract
  >();

  const { loading } = useAsync(
    () => (!ocmw ? signinRedirect() : signinRedirectForOCMW()),
    [signinRedirect, signinRedirectForOCMW],
  );

  if (loading) {
    return (
      <SwRegion>
        <SwLayout>
          <SwGrid modStacked>
            <SwColumn>
              <SwLoader message={t(i18nKeys.general.pageLoading)} />
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </SwRegion>
    );
  }

  return null;
};
