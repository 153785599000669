import { ServiceStatus } from '@kaa/api/customers';
import { Icon } from '@kaa/ui-flanders/components';

export const getStatusSwBadgeProps = (status: ServiceStatus) => {
  const base = {
    icon: Icon.TIMELINE,
    modMedium: true,
    style: {
      verticalAlign: 'top',
      minWidth: '2.75rem',
      width: '2.75rem',
      height: '2.75rem',
    },
  };

  switch (status) {
    case ServiceStatus.ARCHIVED_PAID:
      return {
        ...base,
        icon: Icon.CHECK_CIRCLE,
        modSuccess: true,
      };
    case ServiceStatus.ACTIVE_PARTIALLY_PAID:
      return {
        ...base,
        icon: Icon.CHECK_CIRCLE,
        modAction: true,
      };
    case ServiceStatus.ARCHIVED_CANCELLED:
      return {
        ...base,
        icon: Icon.PLUS_CIRCLE,
        modError: true,
        style: {
          ...base.style,
          transform: 'rotate(45deg)',
        },
      };
    case ServiceStatus.ARCHIVED_OUTSIDE_SV:
    case ServiceStatus.ARCHIVED_PARTIALLY_PAID:
    case ServiceStatus.ACTIVE_CONTESTED:
    case ServiceStatus.ACTIVE_NOT_ENOUGH_VOUCHERS:
      return {
        ...base,
        icon: Icon.ALERT_CIRCLE,
        modWarning: true,
      };
    default:
      return {
        ...base,
        icon: Icon.HOURGLASS,
        modSmall: false,
        modMedium: true,
        style: {
          verticalAlign: 'middle',
          minWidth: '2.75rem',
          width: '2.75rem',
          height: '2.75rem',
        },
      };
  }
};
