/**
 * Back For Front (BFF) Customer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CodeError =
  | 'ENTITY_NOT_FOUND'
  | 'INVALID_REQUEST_SYNTAX'
  | 'VALIDATION_EXCEPTION'
  | 'CLIENT_NOT_AUTHENTICATED'
  | 'INTERNAL_SERVER_ERROR'
  | 'EXTERNAL_SERVICE_ERROR';

export const CodeError = {
  ENTITYNOTFOUND: 'ENTITY_NOT_FOUND' as CodeError,
  INVALIDREQUESTSYNTAX: 'INVALID_REQUEST_SYNTAX' as CodeError,
  VALIDATIONEXCEPTION: 'VALIDATION_EXCEPTION' as CodeError,
  CLIENTNOTAUTHENTICATED: 'CLIENT_NOT_AUTHENTICATED' as CodeError,
  INTERNALSERVERERROR: 'INTERNAL_SERVER_ERROR' as CodeError,
  EXTERNALSERVICEERROR: 'EXTERNAL_SERVICE_ERROR' as CodeError,
};
