import React, { AllHTMLAttributes, Ref } from 'react';
import { mergeClassNames } from '../utilities';

type FormInputProps = AllHTMLAttributes<HTMLDivElement>;

const getClasses = () => {
  return ['vl-form__input'].join(' ');
};

export const SwFormInput = React.forwardRef(
  (
    { className, children, ...rest }: FormInputProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const classes = getClasses();

    return (
      <div {...rest} ref={ref} className={mergeClassNames(classes, className)}>
        {children}
      </div>
    );
  },
);
