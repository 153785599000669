import { useOnScreen } from '@kaa/common/utils';
import { SwSliderSlide, SwTitle } from '@kaa/ui-flanders/components';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Slide } from './OnBoardingModal.type';

type OnboardingModalSlideProps = {
  slide: Slide;
};

export const OnboardingModalSlide = ({ slide }: OnboardingModalSlideProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (ref.current && onScreen) {
      ref.current.focus();
    }
  }, [ref, onScreen]);

  return (
    <SwSliderSlide width="12">
      <SwTitle
        ref={ref}
        tagName="h3"
        className="vl-u-spacer--xsmall onboarding-title"
      >
        {t(slide.content.subtitle)}
      </SwTitle>
      <SwTitle tagName="h2">{t(slide.content.title)}</SwTitle>
      {slide.visual && (
        <div className="onboarding-img-wrapper">
          <div>
            <img
              style={{ minHeight: '19rem' }}
              src={`/onboarding/mobile/${slide.visual}`}
              className="vl-u-hidden vl-u-visible--m"
              alt=""
            />
            <img
              style={{ minHeight: '25rem' }}
              src={`/onboarding/${slide.visual}`}
              className="vl-u-hidden--m"
              alt=""
            />
          </div>
        </div>
      )}
    </SwSliderSlide>
  );
};
