import { httpTo } from '@kaa/api/customers/utilities';
import { AuthAction, useAuthDispatch, useAuthState } from '@kaa/auth/common';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { StorageKeys } from '@kaa/common/enums';
import { navigate } from '@reach/router';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getPath, Routes } from '../../routes';
import { AuthContext } from '../../types';
import { useApi } from '../../utils';

export const LoadUser = () => {
  const dispatch = useAuthDispatch<AuthContext>();
  const dispatchAction = useActionDispatch();
  const { authUser, user } = useAuthState<AuthContext>();
  const { i18n } = useTranslation();

  const { users } = useApi();

  const fetchMe = useCallback(async () => {
    const [error, response] = await httpTo(users.getMe());
    if (error) {
      return;
    }

    if (!response) {
      navigate(getPath(Routes.SERVICE_UNAVAILABLE));
      return;
    }

    const {
      data: { data, messages, actions },
    } = response;

    const selectedCustomer =
      data.customers.find(
        ({ id }) =>
          localStorage.getItem(StorageKeys.LAST_SELECTED_CUSTOMER_ID) ===
          id.toString(),
      ) || data.customers[0];

    localStorage.setItem(
      StorageKeys.LAST_SELECTED_CUSTOMER_ID,
      selectedCustomer && selectedCustomer.id.toString(),
    );

    dispatch({
      type: AuthAction.UPDATE,
      payload: {
        ...data,
        messages,
        selectedCustomerId: selectedCustomer && selectedCustomer.id.toString(),
      },
    });

    if (actions) {
      dispatchAction({ type: ActionType.ADD, payload: actions });
    }
  }, [users, dispatch, dispatchAction, i18n]);

  useEffect(() => {
    if (authUser && !user) {
      fetchMe();
    }
  }, [authUser, user, fetchMe]);

  return null;
};
