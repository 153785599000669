import { AxiosInstance, AxiosResponse } from 'axios';
import { toAxiosResponse } from '../common';
import { IDPModel } from './model/idpModel';
import { LoginCredentials } from './model/loginCredentials';
import { LoginResponse, StateModel } from './model/models';
import { EidDataResponse } from './model/eidDataResponse';
import { IdmViewModel } from './model/idmResponse';
import {
  RecoveryCredentials,
  RecoveryPayload,
} from './model/recoveryCredentials';
import { ChangePassword } from './model/changePassword';

export interface IdpApi {
  login(credendials: LoginCredentials): Promise<AxiosResponse<LoginResponse>>;

  recoverPassword(
    recoveryCredentials: RecoveryPayload,
  ): Promise<AxiosResponse<{}>>;

  changePassword(
    changePassword: ChangePassword,
  ): Promise<AxiosResponse<{ redirectUrl: string }>>;

  validateCode(payload: StateModel): Promise<AxiosResponse<LoginResponse>>;

  getModel(search: string): Promise<AxiosResponse<IDPModel>>;

  readEidData(token?: string): Promise<AxiosResponse<EidDataResponse>>;

  idm(string: string): Promise<AxiosResponse<IdmViewModel>>;

  completeIdm(
    companyId: string,
    cacheId: string,
  ): Promise<AxiosResponse<LoginResponse>>;

  getIdmSwitchRedirectUrl(
    accessToken: any,
    callbackUrl: string,
  ): Promise<AxiosResponse<LoginResponse>>;
}

export const getIdpApi = (axios: AxiosInstance): IdpApi => {
  return {
    login(
      credendials: LoginCredentials,
    ): Promise<AxiosResponse<LoginResponse>> {
      return axios.post(`/core/login`, {
        Username: credendials.username,
        Password: credendials.password,
        RememberLogin: credendials.rememberMe,
        ReturnUrl: credendials.returnUrl,
        UiLocale: credendials.uiLocale,
      });
    },
    recoverPassword(
      recoveryCredentials: RecoveryPayload,
    ): Promise<AxiosResponse<{}>> {
      return axios.post(
        `/security/password/request-reset`,
        recoveryCredentials,
      );
    },
    changePassword(
      changePassword: ChangePassword,
    ): Promise<AxiosResponse<{ redirectUrl: string }>> {
      return axios.post(`/security/password/reset`, {
        newPassword: changePassword.newPassword,
      });
    },
    validateCode(payload: StateModel): Promise<AxiosResponse<LoginResponse>> {
      return axios.post(`/core/2fa`, payload);
    },

    getModel(search: string): Promise<AxiosResponse<IDPModel>> {
      return axios.get(`/core/login${search}`);
    },

    readEidData(token: string): Promise<AxiosResponse<EidDataResponse>> {
      return axios.get(`/eid/read?token=${token}`);
    },

    idm(state: string): Promise<AxiosResponse<IdmViewModel>> {
      return axios.get(`/external/idm?sid=${state}`);
    },

    completeIdm(
      companyId: string,
      cacheId: string,
    ): Promise<AxiosResponse<LoginResponse>> {
      return axios.post(`/external/idm`, {
        SessionId: cacheId,
        CompanyId: companyId,
      });
    },
    getIdmSwitchRedirectUrl(
      accessToken: any,
      callbackUrl: string,
    ): Promise<AxiosResponse<LoginResponse>> {
      return axios.get(
        `/core/idm-switch?redirect=${encodeURIComponent(callbackUrl)}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
  };
};

export const getIdpMock = (): IdpApi => {
  return {
    login(
      credendials: LoginCredentials,
    ): Promise<AxiosResponse<LoginResponse>> {
      return toAxiosResponse(
        Promise.resolve({ redirectUrl: 'localhost:3000' }),
      );
    },
    recoverPassword(
      recoveryCredentials: RecoveryCredentials,
    ): Promise<AxiosResponse<{}>> {
      return toAxiosResponse(Promise.resolve({}));
    },
    changePassword(
      changePassword: ChangePassword,
    ): Promise<AxiosResponse<{ redirectUrl: string }>> {
      return toAxiosResponse(
        Promise.resolve({ redirectUrl: 'http://localhost:3000' }),
      );
    },
    validateCode(payload: StateModel): Promise<AxiosResponse<LoginResponse>> {
      return toAxiosResponse(
        Promise.resolve({ redirectUrl: 'localhost:3000' }),
      );
    },
    getModel(search: string): Promise<AxiosResponse<IDPModel>> {
      return toAxiosResponse(import('./mocks/getModel.json'));
    },
    readEidData(token: string): Promise<AxiosResponse<EidDataResponse>> {
      return toAxiosResponse(import('./mocks/readEidData.json'));
    },
    idm(state: string): Promise<AxiosResponse<any>> {
      return toAxiosResponse(import('./mocks/idm.json'));
    },
    completeIdm(
      companyId: string,
      cacheId: string,
    ): Promise<AxiosResponse<any>> {
      return toAxiosResponse(Promise.resolve({}));
    },
    getIdmSwitchRedirectUrl(
      accessToken: any,
      callbackUrl: string,
    ): Promise<AxiosResponse<LoginResponse>> {
      return toAxiosResponse(Promise.resolve({}));
    },
  };
};
