import { ContractType, SupportType } from '@kaa/api/customers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwBadge,
  SwColumn,
  SwGrid,
  SwInfoTile,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { SwProgressBarStep } from '@kaa/ui-flanders/components/progress-bar/components/SwProgressBarStep';
import { SwProgressBar } from '@kaa/ui-flanders/components/progress-bar/SwProgressBar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { OrderTypes } from '../../MyOrderScreen.constants';
import { Order } from '../../MyOrderScreen.types';
import { getCustomerVoucherSetQuantityAndTotal } from '../../MyOrderScreen.utils';
import { MyOrderDetailsRow } from './MyOrderDetailRow';
import {
  getFormattedAmount,
  getOrderDetailsSteps,
  getOrderDetailsStepsSubTitle,
  OrderDetailsSteps,
} from './MyOrderDetails.utils';

type MyOrderDetailProps = {
  order?: Order;
};

export const MyOrderDetail = ({ order }: MyOrderDetailProps) => {
  const { t } = useTranslation();

  if (!order) {
    return null;
  }

  const { step, steps } = getOrderDetailsSteps(order, t);

  if (!step) {
    return null;
  }

  const orderWithQuantityAndTotal = getCustomerVoucherSetQuantityAndTotal(
    order,
  );

  return (
    <SwColumn width="10" widthS="12">
      <SwTitle
        tagName="h1"
        className="vl-u-spacer--small"
        data-testid={dataTest.myorders.details.myordersDetailsTitle}
      >
        {order.type === OrderTypes.PENDING
          ? t(i18nKeys.orders.details.pendingTitle, {
              paymentDueDate: Format.date(order.paymentDueDate),
            })
          : t(i18nKeys.orders.details.purchaseTitle, {
              paymentDate: Format.date(order.paymentDate),
            })}
      </SwTitle>
      <SwTitle tagName="h3" className="vl-u-spacer--xsmall">
        {t(i18nKeys.orders.details.subtitle)}
      </SwTitle>
      <SwInfoTile
        className="vl-u-spacer--large vl-u-bg-alt"
        tagName="div"
        title={t(
          i18nKeys.orders.details.steps.titles[step.type as OrderDetailsSteps],
        )}
        subtitle={getOrderDetailsStepsSubTitle(order, step.type, t)}
        data-testid={dataTest.myorders.details.alert}
        badge={<SwBadge modMedium modWhite icon={Icon.INFO_CIRCLE} />}
      />
      <SwProgressBar className="vl-u-spacer--large">
        {steps.map((step, i) => (
          <SwProgressBarStep
            key={`SwProgressBarStep-MyOrderDetailSteps-${i}`}
            label={step.label}
            modActive={step.modActive}
            placement="bottom"
          />
        ))}
      </SwProgressBar>
      <SwGrid>
        <MyOrderDetailsRow>
          {t(i18nKeys.general.label.quantity)}
          <span className="vl-u-display-block">
            {t(i18nKeys.orders.details.quantity, {
              quantity: orderWithQuantityAndTotal.quantity,
            })}
          </span>
        </MyOrderDetailsRow>
        <MyOrderDetailsRow>
          {t(i18nKeys.general.label.voucherType)}
          {order.contractType === ContractType.MATERNITY
            ? `${t(i18nKeys.general.supportType[order.supportType])} - ${t(
                i18nKeys.general.contractType.MATERNITY,
              )}`
            : t(i18nKeys.general.supportType[order.supportType])}
        </MyOrderDetailsRow>
        <MyOrderDetailsRow>
          {t(i18nKeys.general.label.amount)}
          {getFormattedAmount(orderWithQuantityAndTotal)}
        </MyOrderDetailsRow>
        <MyOrderDetailsRow>
          {t(i18nKeys.general.label.paymentDate)}
          {order.type === OrderTypes.PURCHASE
            ? Format.date(order.paymentDate)
            : t(i18nKeys.general.notYetDefined)}
        </MyOrderDetailsRow>
        {order.supportType === SupportType.PAPER && (
          <MyOrderDetailsRow>
            {t(i18nKeys.orders.details.sendDateTitle)}
            {order.type === OrderTypes.PURCHASE
             ? (order.shippingDate != null ? Format.date(order.shippingDate)
             : t(i18nKeys.general.notYetDefined)) : t(i18nKeys.general.notYetDefined) }
          </MyOrderDetailsRow>
        )}
        <MyOrderDetailsRow>
          {t(i18nKeys.general.label.paymentMethod)}
          {t(i18nKeys.general.paymentMethod[order.paymentMethod])}
        </MyOrderDetailsRow>
        <MyOrderDetailsRow>
          {t(i18nKeys.orders.details.orderRefTitle)}
          {order.id}
        </MyOrderDetailsRow>
      </SwGrid>
    </SwColumn>
  );
};
