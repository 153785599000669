import { ContractType, CustomerContract } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwFormGrid,
  SwInputTextField,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../../datatest/keys';
import { ExchangeModalPaperFormKeys } from '../../ExchangeModal.constants';

type ExchangeModalPaperAmountProps = {
  quantity: number;
  contracts: CustomerContract[];
  next: () => void;
  buttonType: 'button' | 'submit';
  isSubmitting: boolean;
};

export const ExchangeModalPaperAmount = ({
  quantity,
  contracts,
  next,
  buttonType,
  isSubmitting,
}: ExchangeModalPaperAmountProps) => {
  const { t } = useTranslation();

  const minQuantity = 1;
  const maxQuantity = 2000;

  return (
    <SwFormGrid modStacked>
      {contracts.length === 1 ? (
        <Field
          className="vl-u-spacer--xsmall vl-u-hidden"
          name={ExchangeModalPaperFormKeys.AUTHORIZATION_ID}
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          component={SwInputTextField}
          modDisabled
        />
      ) : (
        <Field
          className="vl-u-spacer--xsmall"
          name={ExchangeModalPaperFormKeys.AUTHORIZATION_ID}
          label={t(i18nKeys.exchange.contractNumber)}
          options={contracts.map((contract) => ({
            value: contract.authorizationId,
            text: `${contract.authorizationId} ${
              contract.type === ContractType.MATERNITY
                ? `- ${t(i18nKeys.general.contractType.MATERNITY)}`
                : ''
            }`,
          }))}
          column={{ width: '12' }}
          labelColumn={{ width: '12' }}
          component={SwSelectField}
        />
      )}
      <Field
        className="vl-u-spacer--xsmall"
        name={ExchangeModalPaperFormKeys.QUANTITY}
        label={t(i18nKeys.exchange.label.paper.voucherQuantity)}
        parse={(value: string) => (value ? parseInt(value, 10) : 0)}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        component={SwInputTextField}
        type="number"
        min={minQuantity}
        max={maxQuantity}
      />

      <SwActionGroup>
        <SwButton
          data-testid={dataTest.wallet.exchange.paper.next}
          type={buttonType}
          modDisabled={!quantity}
          modLarge
          onClick={next}
          modLoading={isSubmitting}
        >
          {t(i18nKeys.general.next)}
        </SwButton>
      </SwActionGroup>
    </SwFormGrid>
  );
};
