import { i18nKeys } from '@kaa/i18n/common/keys';
import React, { AllHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { SwButton } from '../button/SwButton';
import { SwIcon } from '../icon/SwIcon';
import { Icon } from '../icon/SwIcon.constants';
import { SwInputField } from '../input-field/SwInputField';
import { mergeClassNames } from '../utilities';

type SearchProps<T = HTMLElement> = AllHTMLAttributes<T> & {
  id: string;
  name: string;
  value?: string;
  modInline?: boolean;
  modBlock?: boolean;
  modAlt?: boolean;
  onSubmit?: (value: any) => void;
  onChange?: (value: any) => void;
};

type Classes = {
  modInline?: boolean;
  modBlock?: boolean;
  modAlt?: boolean;
};
const getClasses = ({ modBlock, modAlt, modInline }: Classes) => {
  return [
    'vl-search',
    modInline ? 'vl-search--inline' : '',
    modBlock ? 'vl-search--block' : '',
    modAlt ? 'vl-search--alt' : '',
  ].join(' ');
};

export const SwSearch = <T extends HTMLElement>({
  id,
  name,
  value,
  modBlock,
  modAlt,
  modInline,
  className,
  onSubmit,
  children,
  ...rest
}: SearchProps<HTMLInputElement>) => {
  const { t } = useTranslation();

  const classes = getClasses({
    modBlock,
    modAlt,
    modInline,
  });

  const submitSearch = (submitValue: any) => {
    if (onSubmit) {
      onSubmit(submitValue);
    }
  };

  return (
    <form action="" noValidate>
      <div className={mergeClassNames(classes, className)}>
        <label className="vl-search__label" htmlFor={id}>
          {modInline ? <SwIcon icon={Icon.SEARCH} /> : null}
          {t(i18nKeys.general.keyword)}
        </label>
        <SwInputField
          className="vl-search__input"
          type="search"
          name={name}
          id={id}
          value={value}
          title={t(i18nKeys.general.keyword)}
          required
          {...rest}
        />
        <SwButton
          type="submit"
          className="vl-search__submit"
          onClick={(event) => {
            event.preventDefault();
            submitSearch(event);
          }}
        >
          {modInline ? <SwIcon icon={Icon.SEARCH} /> : null}
          {t(i18nKeys.general.search)}
        </SwButton>
      </div>
    </form>
  );
};
