import { Customer, CustomerActionType } from '@kaa/api/customers';
import { ActionsHandler } from '@kaa/common/handlers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwFunctionalHeaderAction,
  SwFunctionalHeaderActions,
  SwIcon,
  SwLink,
  SwPopover,
  SwPopverRenderProps,
} from '@kaa/ui-flanders/components';
import { COMMON_ROUTES } from '@kaa/common/constants';
import { navigate, Location } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SERVICES_VOUCHERS_ORDER_START_BUY } from '../../constants';
import { dataTest } from '../../datatest/keys';
import { getPath, Routes } from '../../routes';
import {
  EventAction,
  sendMainNavigationEventToGTM,
} from '../../utils/google-analytics';
import './VlFunctionalHeader.style.scss';

type VlFunctionalHeaderActionsProps = {
  customers: Customer[];
  selectedCustomerId: string;
  logout: (
    ...args: any
  ) => Promise<[Error | undefined, [any, void] | undefined]>;
  loadingLogout: boolean;
  dispatchUpdateSelectedCustomer: (customerId: React.ReactText) => void;
};

const navigateToMyOrder = () => navigate(SERVICES_VOUCHERS_ORDER_START_BUY);
const RemoveOrderButtonPaths = [COMMON_ROUTES.MAINTENANCE.path];

export const VlFunctionalHeaderActions = ({
  customers,
  selectedCustomerId,
  logout,
  loadingLogout,
  dispatchUpdateSelectedCustomer,
}: VlFunctionalHeaderActionsProps) => {
  const { t } = useTranslation();

  const selectedCustomer = customers.find(
    ({ id }) => selectedCustomerId === id.toString(),
  );

  if (!selectedCustomer) {
    return null;
  }

  return (
    <>
      <SwFunctionalHeaderActions>
        <SwFunctionalHeaderAction>
          {customers.length === 1 ? (
            <>
              <SwPopover
                className="vl-functional-header__action vl-u-text--small "
                modRight
                action={dataTest.header.toggleProfile}
                basicLabel={{
                  tag: 'span',
                  text: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
                }}
                icon={Icon.ARROW_DOWN}
              >
                {({ close }: SwPopverRenderProps) => (
                  <ul className="vl-popover__link-list">
                    <li>
                      <SwLink
                        className="vl-u-text--small "
                        modBlock
                        data-testid={dataTest.header.linkAccount}
                        to={getPath(Routes.ACCOUNT_PROFILE)}
                      >
                        {t(i18nKeys.navigation.myInfos)}
                      </SwLink>
                    </li>
                    <li
                      role="none"
                      className="vl-popover__link-list__separator"
                    />
                    <li>
                      <SwLink
                        className="vl-u-text--small "
                        modBlock
                        tagName="button"
                        type="button"
                        data-testid={dataTest.header.linkLogout}
                        onClick={(e) => {
                          e.preventDefault();
                          sendMainNavigationEventToGTM(
                            EventAction.CLICK,
                            EventAction.LOG_OUT,
                          );
                          close();
                          logout();
                        }}
                      >
                        <SwIcon icon={Icon.LOGOUT} modLarge />
                        &nbsp; {t(i18nKeys.navigation.logout)}
                      </SwLink>
                    </li>
                  </ul>
                )}
              </SwPopover>
            </>
          ) : (
            <SwPopover
              className="vl-functional-header__action vl-u-text--small "
              modRight
              action="openProfilesDropdown"
              basicLabel={{
                tag: 'span',
                text: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
              }}
              icon={Icon.ARROW_DOWN}
            >
              {({ close }: SwPopverRenderProps) => (
                <ul className="vl-popover__link-list">
                  <li>
                    <SwLink
                      className="vl-u-text--small "
                      modBlock
                      data-testid={dataTest.header.linkAccount}
                      to={getPath(Routes.ACCOUNT_PROFILE)}
                    >
                      {t(i18nKeys.navigation.myInfos)}
                    </SwLink>
                  </li>
                  <li
                    role="none"
                    className="vl-popover__link-list__separator"
                  />
                  {customers
                    .filter(({ id }) => id !== selectedCustomer.id)
                    .map((customer, i) => {
                      return (
                        <li
                          key={`${customer.id}-${customer.lastName}`}
                          className="vl-popover__link-list__item"
                        >
                          <SwLink
                            className="vl-u-text--small "
                            modBlock
                            tagName="button"
                            type="button"
                            onClick={() => {
                              sendMainNavigationEventToGTM(
                                EventAction.CLICK,
                                EventAction.SWITCH_USER,
                              );
                              dispatchUpdateSelectedCustomer(customer.id);
                              document.documentElement.scrollTop = 0;
                              window.location.reload();
                            }}
                          >
                            <SwIcon icon={Icon.USER} modLarge />
                            &nbsp;
                            {`${customer.firstName} ${customer.lastName}`}
                          </SwLink>
                        </li>
                      );
                    })}
                  <li
                    role="none"
                    className="vl-popover__link-list__separator"
                  />
                  <li>
                    <SwLink
                      className="vl-u-text--small "
                      modBlock
                      tagName="button"
                      type="button"
                      data-testid={dataTest.header.linkLogout}
                      onClick={(e) => {
                        e.preventDefault();
                        sendMainNavigationEventToGTM(
                          EventAction.CLICK,
                          EventAction.LOG_OUT,
                        );
                        close();
                        logout();
                      }}
                      disabled={loadingLogout}
                    >
                      <SwIcon icon={Icon.LOGOUT} modLarge />
                      &nbsp; {t(i18nKeys.navigation.logout)}
                    </SwLink>
                  </li>
                </ul>
              )}
            </SwPopover>
          )}
        </SwFunctionalHeaderAction>
        <Location>
          {({ location }) => {
            if (RemoveOrderButtonPaths.includes(location.pathname)) {
              return null;
            }
            return (
              <ActionsHandler
                resourceId={selectedCustomer.resourceId}
                actions={[CustomerActionType.CAN_ORDER]}
              >
                <SwFunctionalHeaderAction>
                  <div className="vl-popover  vl-popover--align-right vl-functional-header__action vl-u-text--small">
                    <button
                      type="button"
                      data-testid={dataTest.header.linkOrderVouchers}
                      className="vl-link vl-u-text--small vl-popover__toggle"
                      onClick={() => {
                        sendMainNavigationEventToGTM(
                          EventAction.CLICK,
                          EventAction.ORDER_VOUCHERS,
                        );
                        navigateToMyOrder();
                      }}
                    >
                      {t(i18nKeys.general.cta.order)}
                    </button>
                  </div>
                </SwFunctionalHeaderAction>
              </ActionsHandler>
            );
          }}
        </Location>
      </SwFunctionalHeaderActions>
    </>
  );
};
