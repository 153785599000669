import { SupportType } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';

export enum PaymentType {
  BANK_TRANSFER = 'bankTransfer',
  KLARNA = 'klarna',
}

export const supportTypeTranslate = {
  [SupportType.ELECTRONIC]: i18nKeys.general.supportType.ELECTRONIC,
  [SupportType.PAPER]: i18nKeys.general.supportType.PAPER,
};
