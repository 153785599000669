import { CustomerActionType } from '@kaa/api/customers';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  selectPage,
  SwBadge,
  SwColumn,
  SwContainer,
  SwDataTable,
  SwFetchErrorMessage,
  SwGrid,
  SwInfoTile,
  SwLoader,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContainerLoadingPageHeader,
  Onboarding,
  PageHeader,
} from '../../../components';
import { dataTest } from '../../../datatest/keys';
import { Routes } from '../../../routes';
import { useApi, useSelectedCustomerState } from '../../../utils';
import { ServicesVouchersModals } from '../components/common-modal/ServicesVouchersModals';
import { EventLabelsModalsRoutes } from '../components/common-modal/ServicesVouchersModals.types';
import { ServicesVouchersModalsButtons } from '../components/common-modal/ServicesVouchersModalsButtons';
import { WalletScreenTableRow } from './components/WalletScreenTableRow';
import {
  getBirthAssistanceLabel,
  getBundlesGroupedByContractTypeExpirationDateAndConcatByRemainingQuantity,
  getBundlesGroupedByExpirationDateAndConcatByRemainingQuantity,
  getInfoTitleLabel,
} from './WalletScreen.utils';

const WALLET_PAGE_SIZE = 20;

export const WalletScreen = () => {
  const { t } = useTranslation();
  const dispatchAction = useActionDispatch();

  const customer = useSelectedCustomerState();
  const [selectedPage, setSelectedPage] = useState(1);

  const { customers: customersApi } = useApi();

  const canOrder = useActionsHandler(customer.resourceId, [
    CustomerActionType.CAN_ORDER,
  ]);

  const [
    { value: wallet, loading, error },
    getCustomerWallet,
  ] = useAsyncCallback(
    async () => {
      const {
        data: { data: wallet, actions },
      } = await customersApi.getCustomerWallet(customer.id);

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      return wallet;
    },
    [customer.id, customersApi],
    { loading: true },
  );

  useEffect(() => {
    getCustomerWallet();
  }, [getCustomerWallet]);

  if (!wallet && loading) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.wallet.title)}
        introduction={t(i18nKeys.wallet.introduction)}
      />
    );
  }

  if (error || !wallet) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getCustomerWallet} />
      </SwContainer>
    );
  }

  const { availableVouchers, voucherBundles } = wallet;

  const concatedExpirationDateBundles = getBundlesGroupedByExpirationDateAndConcatByRemainingQuantity(
    voucherBundles,
  );

  const {
    maternity: concatedMaternityBundles = [],
    standard: concatedStandardBundles = [],
  } = getBundlesGroupedByContractTypeExpirationDateAndConcatByRemainingQuantity(
    voucherBundles,
  );

  const infoTitleLabel = getInfoTitleLabel(
    concatedExpirationDateBundles[0],
    canOrder,
    t,
  );

  const birthAssistanceLabel = getBirthAssistanceLabel(
    concatedMaternityBundles,
    t,
  );

  return (
    <SwContainer>
      <Onboarding activeRoute={Routes.SERVICES_VOUCHERS_WALLET} />
      <SwGrid modStacked>
        <SwColumn width="10" widthS="12">
          <PageHeader
            title={t(i18nKeys.wallet.title)}
            introduction={t(i18nKeys.wallet.introduction)}
            className="push-pageHeader"
          />

          <SwInfoTile
            data-testid={dataTest.wallet.availableVouchers}
            tagName="div"
            badge={<SwBadge modLarge modAccent icon={Icon.WALLET} />}
            title={t(i18nKeys.general.vouchersCount, {
              availableVouchers,
              count: availableVouchers,
            })}
            subtitle={
              <>
                {birthAssistanceLabel && (
                  <>
                    {birthAssistanceLabel} <br />
                  </>
                )}
                {infoTitleLabel}
              </>
            }
          />
        </SwColumn>
        <ServicesVouchersModalsButtons
          customer={customer}
          route={Routes.SERVICES_VOUCHERS_WALLET as EventLabelsModalsRoutes}
        />
        {!!concatedStandardBundles.length && (
          <>
            <SwColumn width="10" widthS="12">
              <SwTitle tagName="h2" className="vl-u-spacer--none">
                {t(i18nKeys.wallet.tableTitles.standard)}
              </SwTitle>
            </SwColumn>
            <SwColumn width="10" widthS="12" className="vl-u-spacer">
              <div className="vl-u-table-overflow">
                <SwDataTable>
                  <thead data-testid={dataTest.wallet.vouchersTable}>
                    <tr>
                      <th>{t(i18nKeys.general.label.amount)}</th>
                      <th>{t(i18nKeys.wallet.expiresDateVouchers)}</th>
                    </tr>
                  </thead>
                  <tbody data-testid={dataTest.myorders.tableBody}>
                    {loading && (
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <SwLoader
                            style={{
                              display: 'inline-block',
                            }}
                            modMessageHidden
                          />
                        </td>
                      </tr>
                    )}
                    {selectPage(
                      concatedStandardBundles,
                      selectedPage,
                      WALLET_PAGE_SIZE,
                    ).map((concatedBundle, index) => (
                      <WalletScreenTableRow
                        key={index}
                        concatedBundle={concatedBundle}
                        testId={dataTest.wallet.vouchersTable}
                      />
                    ))}
                  </tbody>
                </SwDataTable>
              </div>
            </SwColumn>
            {concatedStandardBundles.length >= WALLET_PAGE_SIZE && (
              <SwColumn width="10" widthS="12">
                <SwPaginator
                  itemCount={concatedStandardBundles.length}
                  pageSize={WALLET_PAGE_SIZE}
                  selectedPage={selectedPage}
                  setPage={setSelectedPage}
                />
              </SwColumn>
            )}
          </>
        )}
        {!!concatedMaternityBundles.length && (
          <>
            <SwColumn width="10" widthS="12">
              <SwTitle tagName="h2" className="vl-u-spacer--none">
                {t(i18nKeys.wallet.tableTitles.family)}
              </SwTitle>
            </SwColumn>
            <SwColumn width="10" widthS="12">
              <div className="vl-u-table-overflow">
                <SwDataTable>
                  <thead data-testid={dataTest.wallet.vouchersTable}>
                    <tr>
                      <th>{t(i18nKeys.general.label.amount)}</th>
                      <th>{t(i18nKeys.wallet.expiresDateVouchers)}</th>
                    </tr>
                  </thead>
                  <tbody data-testid={dataTest.myorders.tableBody}>
                    {selectPage(
                      concatedMaternityBundles,
                      selectedPage,
                      WALLET_PAGE_SIZE,
                    ).map((concatedBundle, index) => (
                      <WalletScreenTableRow
                        key={index}
                        concatedBundle={concatedBundle}
                        testId={dataTest.wallet.vouchersTable}
                      />
                    ))}
                  </tbody>
                </SwDataTable>
              </div>
            </SwColumn>
            {concatedStandardBundles.length >= WALLET_PAGE_SIZE && (
              <SwColumn width="10" widthS="12">
                <SwPaginator
                  itemCount={concatedStandardBundles.length}
                  pageSize={WALLET_PAGE_SIZE}
                  selectedPage={selectedPage}
                  setPage={setSelectedPage}
                />
              </SwColumn>
            )}
          </>
        )}
      </SwGrid>
      <ServicesVouchersModals
        customer={customer}
        route={Routes.SERVICES_VOUCHERS_WALLET as EventLabelsModalsRoutes}
        onSuccess={getCustomerWallet}
      />
    </SwContainer>
  );
};
