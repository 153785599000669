import { CustomerVacancy } from '@kaa/api/customers';
import { connect } from 'formik';
import { useEffect } from 'react';

export const PlanALeaveFormInit = connect<
  { getMaxEndDate: any },
  CustomerVacancy
>(({ formik, getMaxEndDate }) => {
  useEffect(() => {
    if (formik && formik.initialValues.startDate) {
      getMaxEndDate(formik.initialValues.startDate, formik);
    }
  }, [!!formik && formik.initialValues.startDate, getMaxEndDate]);

  return null;
});
