import { useRefMounted } from '@kaa/common/utils';
import Flickity from 'flickity';
import ImagesLoaded from 'imagesloaded';
import React, {
  AllHTMLAttributes,
  createElement,
  ReactNode,
  ReactNodeArray,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import ResizeSensor from 'resize-sensor';
import './helpers/flickity-equal-height';
import './SwSlider.style.scss';
import { i18nKeys } from '@kaa/i18n/common/keys';
import { useTranslation } from 'react-i18next';
import { mergeClassNames } from '../utilities';

const gridClass = 'vl-grid';
const sliderClass = 'flickity-slider';

type SliderProps<T = HTMLDivElement> = AllHTMLAttributes<T> &
  Classes & {
    tagName?: string;
    dots?: boolean;
    keyboardNavigation?: boolean;
    arrows?: boolean;
    bottomChildren?: ReactNode[];
    modGroupCells?: boolean;
  };

type Classes = {
  equalHeight?: boolean;
};

const getClasses = ({ equalHeight }: Classes) => {
  return ['vl-slider', equalHeight ? 'vl-slider--equal-height' : ''].join(' ');
};

type PreviousClasses = {
  previousButtonDisabled?: boolean;
};

const getPreviousClasses = ({ previousButtonDisabled }: PreviousClasses) => {
  return [
    'js-vl-slider__previous',
    previousButtonDisabled ? 'js-vl-slider__previous--disabled' : '',
  ].join(' ');
};

type NextClasses = {
  nextButtonDisabled?: boolean;
};

const getNextClasses = ({ nextButtonDisabled }: NextClasses) => {
  return [
    'js-vl-slider__next',
    nextButtonDisabled ? 'js-vl-slider__next--disabled' : '',
  ].join(' ');
};

export const SwSlider = ({
  tagName,
  children,
  equalHeight,
  arrows,
  modGroupCells,
  keyboardNavigation = true,
  dots = true,
  className,
  bottomChildren,
  ...rest
}: SliderProps) => {
  const { t } = useTranslation();
  const TagName = tagName || 'div';
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [flickity, setFlickity] = useState<Flickity | null>(null);
  const options: Flickity.Options = {
    accessibility: keyboardNavigation,
    contain: true,
    prevNextButtons: false,
    groupCells: modGroupCells,
    pageDots: dots,
    percentPosition: true,
    cellAlign: 'left',
    selectedAttraction: 0.2,
    friction: 0.8,
    draggable: true,
    resize: false,
  };

  const sliderRef = useRef<HTMLElement>(null);
  const slidesRef = useRef<HTMLDivElement>(null);

  const checkNavButtonState = (flickity: Flickity) => {
    setNextButtonDisabled(
      flickity.selectedIndex === flickity.slides.length - 1,
    );
    setPreviousButtonDisabled(flickity.selectedIndex <= 0);
  };

  useEffect(() => {
    // done because the lib need to bestroy before render new children
    if (flickity && slidesRef.current) {
      flickity.reloadCells();
      resizeSlider();
    }
  }, [children && (children as ReactNodeArray).length]);

  const initialize = useCallback(() => {
    if (!slidesRef.current) {
      return;
    }

    const flickity = new Flickity(slidesRef.current, options);
    setFlickity(flickity);

    checkNavButtonState(flickity);
    resizeSlider();
    // Adding vl-grid class to generated flickity-class
    const sliderWrapper = slidesRef.current.querySelector(`.${sliderClass}`);

    if (sliderWrapper) {
      if (!sliderWrapper.classList.contains(gridClass)) {
        sliderWrapper.classList.add(gridClass);
      }
    }

    const checkNavButtonStateWithFlickity = () => checkNavButtonState(flickity);

    flickity.on('change' as any, checkNavButtonStateWithFlickity);
    return () => {
      flickity.off('change' as any, checkNavButtonStateWithFlickity);
    };
  }, [flickity, slidesRef.current, options]);

  const resizeSlider = () => {
    if (flickity) {
      flickity.resize();
    }
  };

  const mount = useRefMounted();

  useEffect(() => {
    if (!flickity) {
      initialize();
    } else {
      flickity.reloadCells();
      resizeSlider();
    }
    return () => {
      if (flickity && !mount.current) {
        (flickity as any).isActive = false;
        flickity.destroy();
      }
    };
  }, [flickity]);

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      const resizeSensor = new ResizeSensor(sliderRef.current, () => {
        resizeSlider();
      });
      ImagesLoaded(sliderRef.current, () => {
        resizeSlider();
      });
    }
  }, [sliderRef.current]);

  const classes = getClasses({
    equalHeight,
  });

  const previousClasses = getPreviousClasses({
    previousButtonDisabled,
  });
  const nextClasses = getNextClasses({
    nextButtonDisabled,
  });

  const previousSlide = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (!flickity) {
      return;
    }
    flickity.previous(true);
  };

  const nextSlide = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (!flickity) {
      return;
    }
    flickity.next(true);
  };

  const renderPortal = () => {
    if (!slidesRef.current || !mount.current) return null;
    const mountNode = slidesRef.current.querySelector(`.${sliderClass}`);
    if (!mountNode) return null;
    return createPortal(children, mountNode);
  };

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest, ref: sliderRef },
    <>
      {arrows && (
        <button
          type="button"
          className={previousClasses}
          onClick={previousSlide}
          disabled={previousButtonDisabled}
          aria-label={t(i18nKeys.general.previous)}
        >
          <span className="vl-u-visually-hidden">
            {t(i18nKeys.general.previous)}
          </span>
        </button>
      )}
      <div className="vl-slider__frame">
        <div
          className="vl-slider__slides"
          style={{ overflow: 'visible' }}
          ref={slidesRef}
        >
          {renderPortal()}
        </div>
      </div>
      {arrows && (
        <button
          type="button"
          className={nextClasses}
          onClick={nextSlide}
          disabled={nextButtonDisabled}
          aria-label={t(i18nKeys.general.next)}
        >
          <span className="vl-u-visually-hidden">
            {t(i18nKeys.general.next)}
          </span>
        </button>
      )}
      {flickity &&
        bottomChildren &&
        bottomChildren[flickity.selectedIndex] &&
        React.createElement(bottomChildren[flickity.selectedIndex] as any, {
          next: nextSlide,
          previous: previousSlide,
          selectedIndex: flickity.selectedIndex,
        })}
    </>,
  );
};
