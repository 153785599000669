import { DropzoneOptions } from 'react-dropzone';
import { SwUploadProps } from './SwUpload.types';

export const MAXFILESIZE = 1000000;
export const FILESIZEBASE = 1000;
enum FileSizeUnits {
  TB = 'TB',
  GB = 'GB',
  MB = 'MB',
  KB = 'KB',
  B = 'B',
}

const FileSizeExponent = {
  [FileSizeUnits.TB]: 0,
  [FileSizeUnits.GB]: 1,
  [FileSizeUnits.MB]: 2,
  [FileSizeUnits.KB]: 3,
  [FileSizeUnits.B]: 4,
};

export const getFileSize = (size: number, base = FILESIZEBASE) => {
  const unit = Object.keys(FileSizeExponent).find(
    (key) => size >= base ** (4 - FileSizeExponent[key as FileSizeUnits]) / 10,
  );
  const value = size / base ** (4 - FileSizeExponent[unit as FileSizeUnits]);
  return {
    value: Math.round(10 * value) / 10,
    unit,
  };
};

export const getDropzoneOptions = ({
  maxFilesize = MAXFILESIZE,
  allowedFileTypes,
  multiple,
  disabled,
}: SwUploadProps): DropzoneOptions => ({
  multiple,
  disabled,
  maxSize: maxFilesize,
  accept: allowedFileTypes,
});
