import { Link as ReachLink } from '@reach/router';
import React, { AllHTMLAttributes, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/common/keys';
import { SwLink } from '../link/SwLink';

type PagerPageProps<T = typeof ReachLink> = AllHTMLAttributes<T> & {
  tagName?: string;
  page: number;
  modActive?: boolean;
};

type Classes = {
  modActive?: boolean;
};

const getClasses = ({ modActive }: Classes) => {
  return [
    'vl-pager__element',
    modActive ? 'vl-pager__element--active' : '',
  ].join(' ');
};

export const SwPagerPage = <T extends HTMLElement>({
  tagName,
  page,
  modActive,
  ...rest
}: PagerPageProps<T>) => {
  const { t } = useTranslation();
  const TagName = tagName || 'li';

  const classes = getClasses({ modActive });

  return createElement(
    TagName,
    { className: classes },
    <>
      <SwLink
        className="vl-pager__element__cta"
        {...rest}
        hiddenText={t(i18nKeys.general.page)}
      >
        {page}
      </SwLink>
    </>,
  );
};
