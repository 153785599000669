import { AuthServiceAbstract } from './auth.service.abstract';

const user = {
  id_token: '',
  session_state: {},
  access_token: '',
  refresh_token: '',
  token_type: '',
  scope: '',
  profile: {
    'http://schemas.sodexo.be/kaa/identity/claims/proxies': JSON.stringify([
      { auid: '123' },
    ]),
  },
  expires_at: 0,
  state: {},
  toStorageString: () => '',
  expires_in: 0,
  expired: false,
  scopes: [],
};

export class AuthServiceMock implements AuthServiceAbstract {
  getSigninRequestUrl = () => '';

  getLogoutRequestUrl = () => '';

  signinRedirect = () => Promise.resolve();

  signinRedirectForOCMW = () => Promise.resolve();

  signinRedirectCallback = () => Promise.resolve(user);

  signinSilent = () => Promise.resolve(user);

  signinSilentCallback = () => Promise.resolve();

  logout = () => Promise.all([{}, (() => undefined)()]);

  signoutRedirectCallback = () => Promise.all([{}, (() => undefined)()]);

  getUser = () => Promise.resolve(user);

  getClientId = () => '';

  isAuthenticated = () => true;

  registerOnUserLoaded = (callback: (...ev: any[]) => void) => undefined;

  unregisterOnUserLoaded = (callback: (...ev: any[]) => void) => undefined;

  getDirectSigninRequestUrlToIdp = () => Promise.resolve('');

  getDirectSigninRequestUrlToIdpLocal = () => Promise.resolve('');

  registerOnUserSignedOut = (callback: (...ev: any[]) => void) => undefined;

  unregisterOnUserSignedOut = (callback: (...ev: any[]) => void) => undefined;

  registerOnAccessTokenExpiring = (callback: (...ev: any[]) => void) =>
    undefined;

  unregisterOnAccessTokenExpiring = (callback: (...ev: any[]) => void) =>
    undefined;
}
