import React, { AllHTMLAttributes } from 'react';
import { useVisuallyHidden } from '../util/hooks/useVisuallyHidden';
import { mergeClassNames } from '../utilities';

type CheckboxProps = AllHTMLAttributes<HTMLInputElement> & {
  modSingle?: boolean;
  modBlock?: boolean;
  modDisabled?: boolean;
  modError?: boolean;
  modSuccess?: boolean;
  modSwitch?: boolean;
  modRequired?: boolean;
};

type Classes = {
  modSingle?: boolean;
  modBlock?: boolean;
  modDisabled?: boolean;
  modError?: boolean;
  modSuccess?: boolean;
  modSwitch?: boolean;
};
const getClasses = ({
  modBlock,
  modDisabled,
  modError,
  modSingle,
  modSwitch,
  modSuccess,
}: Classes) => {
  return [
    'vl-checkbox',
    modBlock ? 'vl-checkbox--block' : '',
    modSingle ? 'vl-checkbox--single' : '',
    modDisabled ? 'vl-checkbox--disabled' : '',
    modError ? 'vl-checkbox--error' : '',
    modSuccess ? 'vl-checkbox--success' : '',
    modSwitch ? 'vl-checkbox--switch' : '',
  ].join(' ');
};

export const SwCheckbox = ({
  modBlock,
  modDisabled,
  modError,
  modSingle,
  modSwitch,
  modSuccess,
  modRequired = false,
  children,
  name,
  className = '',
  ...rest
}: CheckboxProps) => {
  const visuallyHiddenRef = useVisuallyHidden<HTMLSpanElement>();

  const classes = getClasses({
    modBlock,
    modDisabled,
    modError,
    modSingle,
    modSwitch,
    modSuccess,
  });

  const labelClasses = 'vl-checkbox__label';

  const inputClass = mergeClassNames(className, 'vl-checkbox__toggle');

  return (
    <label htmlFor={name} className={classes}>
      <input
        {...rest}
        id={name}
        name={name}
        type="checkbox"
        className={inputClass}
        disabled={modDisabled}
      />
      <div className={labelClasses}>
        <div className="vl-checkbox__box" aria-hidden="true" />
        {modSingle ? (
          <span ref={visuallyHiddenRef}>{children}</span>
        ) : (
          <>
            {children}
            {modRequired && <span>*</span>}
          </>
        )}
      </div>
    </label>
  );
};
