import '@govflanders/vl-widget-polyfill';
import { RegionCode } from '@kaa/api/customers';
import { AuthStorageKeys, useAuthState } from '@kaa/auth/common';
import { useAsync, useAsyncCallback } from '@kaa/common/utils';
import { getConfig } from '@kaa/core-app/common/config';
import { PageHeader } from '@kaa/core-app/customers/components';
import {
  LOGIN_CANT_CONNECT_LINKS,
  LOGIN_CONNECTION_PROBLEMS_LINKS,
} from '@kaa/core-app/customers/constants';
import { dataTest } from '@kaa/core-app/customers/datatest/keys';
import { getRouterPath, Routes } from '@kaa/core-app/customers/routes';
import { AuthContext } from '@kaa/core-app/customers/types';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwBadge,
  SwButton,
  SwColumn,
  SwContainer,
  SwDescriptionDataItem,
  SwGrid,
  SwIcon,
  SwInfoTileSpotlight,
  SwLink,
  useTooltip,
} from '@kaa/ui-flanders/components';
import { navigate, RouteComponentProps } from '@reach/router';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const LandingScreen = (props: RouteComponentProps) => {
  const { getUser, signinRedirect, signinSilent } = useAuthState<AuthContext>();
  const { t } = useTranslation();

  const config = getConfig();

  const { loading, value: isAuthenticated } = useAsync(async () => getUser(), [
    getUser,
    config,
  ]);

  const [
    { value: signinSilentResponse, loading: signinSilentLoading },
    getSigninSilent,
  ] = useAsyncCallback(async () => {
    return signinSilent();
  }, [signinSilent]);

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  useEffect(() => {
    getSigninSilent();
  }, [getSigninSilent]);

  useEffect(() => {
    if (isAuthenticated || signinSilentResponse) {
      const redirectionUri =
        sessionStorage.getItem(AuthStorageKeys.CALLBACK_REDIRECT_URI) ||
        getRouterPath(Routes.DASHBOARD);
      sessionStorage.removeItem(AuthStorageKeys.CALLBACK_REDIRECT_URI);

      navigate(redirectionUri);
    }
  }, [isAuthenticated, signinSilentResponse]);

  const refTooltipSignin = useTooltip<HTMLButtonElement>({
    value: t(i18nKeys.landing.cards.signin.tooltip),
    placement: 'auto',
  });

  if (
    signinSilentLoading ||
    signinSilentResponse ||
    loading ||
    isAuthenticated
  ) {
    return <SwContainer loading />;
  }

  const signUp = () => {
    navigate(getRouterPath(Routes.REGISTER));
  };

  const signIn = () => {
    signinRedirect();
  };

  return (
    <SwContainer>
      <SwGrid modStacked>
        <PageHeader title={t(i18nKeys.landing.title)} />
        <SwColumn width="10" widthS="12">
          <SwGrid>
            <SwColumn width="6" widthM="12" className="vl-u-spacer">
              <SwInfoTileSpotlight
                modVCenter
                modBadge
                modEllipsis
                style={{ height: '100%' }}
                title={t(i18nKeys.landing.cards.signin.title)}
                icon={Icon.BURGER_PROFILE}
                footer={
                  <SwButton
                    data-testid={dataTest.landing.linkSignIn}
                    onClick={signIn}
                  >
                    {t(i18nKeys.landing.cards.signin.cta)}
                  </SwButton>
                }
              >
                <SwBadge
                  style={{ position: 'absolute', top: '1rem', right: '1rem' }}
                  modAction
                  modSmall
                  icon={Icon.INFO}
                  ref={refTooltipSignin}
                />
                <SwDescriptionDataItem
                  subdata={t(i18nKeys.landing.cards.signin.description)}
                  tagName={SwColumn}
                  column={{ width: '12' }}
                  style={{ background: 'none' }}
                />
              </SwInfoTileSpotlight>
            </SwColumn>
            <SwColumn width="6" widthM="12" className="vl-u-spacer">
              <SwInfoTileSpotlight
                modVCenter
                modBadge
                modEllipsis
                style={{ height: '100%' }}
                title={t(i18nKeys.landing.cards.signup.title)}
                icon={Icon.USER_SIGNUP}
                footer={
                  <SwButton
                    data-testid={dataTest.landing.linkSignUp}
                    onClick={signUp}
                  >
                    {t(i18nKeys.landing.cards.signup.cta)}
                  </SwButton>
                }
              >
                <SwDescriptionDataItem
                  subdata={t(i18nKeys.landing.cards.signup.description)}
                  tagName={SwColumn}
                  column={{ width: '12' }}
                  style={{ background: 'none' }}
                />
              </SwInfoTileSpotlight>
            </SwColumn>
            <SwColumn width="10" widthS="12">
              <SwIcon
                icon={Icon.INFO_CIRCLE}
                className="vl-u-text--action"
                style={{ marginRight: '1rem' }}
              />
              <p
                className="vl-u-text--small"
                style={{ display: 'inline-block' }}
              >
                {t(i18nKeys.login.labels.usefullLinks.title)}
              </p>

              <ul style={{ marginLeft: '3rem', listStyle: 'initial' }}>
                <li>
                  <SwLink
                    to={t(LOGIN_CONNECTION_PROBLEMS_LINKS[regionCode])}
                    target="_blank"
                    className="vl-u-text--small"
                  >
                    {t(i18nKeys.login.labels.usefullLinks.howTo)}
                  </SwLink>
                </li>
                <li>
                  <SwLink
                    to={t(LOGIN_CANT_CONNECT_LINKS[regionCode])}
                    target="_blank"
                    className="vl-u-text--small"
                  >
                    {t(i18nKeys.login.labels.usefullLinks.cantConnect)}
                  </SwLink>
                </li>
              </ul>
            </SwColumn>
          </SwGrid>
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
