import { CustomerActionType } from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { AvailableLanguages } from '@kaa/common/enums/AvailableLanguages';
import { useActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AlertType,
  getAlertPropsByType,
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwContainer,
  SwGrid,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../components';
import {
  useApi,
  useDispatchUpdateCustomer,
  useSelectedCustomerState,
} from '../utils';

export const LanguageRequestScreen = ({
  language,
}: RouteComponentProps<{ language: AvailableLanguages }>) => {
  const { t, i18n } = useTranslation();
  const { customers: customersApi } = useApi();
  const selectedCustomer = useSelectedCustomerState();
  const [status, setStatus] = useState<{ msg: string; type: AlertType } | null>(
    null,
  );
  const canRequestSwitchLanguage = useActionsHandler(
    selectedCustomer.resourceId,
    [CustomerActionType.CAN_REQUEST_LANGUAGE_SWITCH],
  );
  const dispatchCustomer = useDispatchUpdateCustomer();
  const dispatchAction = useActionDispatch();

  const canSwitchLanguage = useActionsHandler(selectedCustomer.resourceId, [
    CustomerActionType.CAN_SWITCH_LANGUAGE,
  ]);

  useEffect(() => {
    if (canRequestSwitchLanguage && language) {
      i18n.changeLanguage(language.toLocaleLowerCase());
    }
    return () => {
      i18n.changeLanguage(selectedCustomer.language.toLocaleLowerCase());
    };
  }, [i18n, i18n.language, selectedCustomer]);

  const [
    { value: customerResponse, loading },
    switchCustomerLanguages,
  ] = useAsyncCallback(async () => {
    const [error, response] = await httpTo(
      customersApi.switchCustomerLanguages(selectedCustomer.id),
    );

    return {
      error,
      response,
    };
  }, [selectedCustomer.id]);

  useEffect(() => {
    if (customerResponse) {
      const { response, error } = customerResponse;

      if (!response || error) {
        return;
      }

      setStatus({
        msg: t(i18nKeys.languageRequest.message.success),
        type: AlertType.SUCCESS,
      });

      const {
        data: { data, actions },
      } = response;

      dispatchCustomer(data);
      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }
    }
  }, [customerResponse]);

  return (
    <SwContainer>
      <SwGrid modStacked className="vl-u-flex-v-flex-start">
        <PageHeader
          title={t(i18nKeys.languageRequest.title)}
          introduction={
            canRequestSwitchLanguage
              ? t(i18nKeys.languageRequest.introduction)
              : undefined
          }
        />
        {((canRequestSwitchLanguage && canSwitchLanguage) || status) && (
          <SwColumn>
            <SwAlert icon={Icon.INFO_CIRCLE}>
              <strong>{t(i18nKeys.languageRequest.alert.title)}</strong>
              <ul>
                <li>
                  {t(i18nKeys.languageRequest.alert.list.item.serviceAccount)}
                </li>
                <li>
                  {t(
                    i18nKeys.languageRequest.alert.list.item
                      .serviceCommunication,
                  )}
                </li>
                <li>{t(i18nKeys.languageRequest.alert.list.item.printed)}</li>
                <li>
                  {t(i18nKeys.languageRequest.alert.list.item.taxCertificate)}
                </li>
              </ul>
            </SwAlert>
          </SwColumn>
        )}
        {canRequestSwitchLanguage && !canSwitchLanguage && !status && (
          <SwColumn>
            <SwAlert icon={Icon.ALERT_CIRCLE} modError>
              <strong>
                {t(i18nKeys.languageRequest.message.error.alreadyRequested)}
              </strong>
            </SwAlert>
          </SwColumn>
        )}
        {!canRequestSwitchLanguage && (
          <SwColumn>
            <SwAlert icon={Icon.ALERT_CIRCLE} modError>
              <strong>
                {t(i18nKeys.languageRequest.message.error.notAllowed)}
              </strong>
            </SwAlert>
          </SwColumn>
        )}
        {(canRequestSwitchLanguage || status) && (
          <SwColumn>
            {status && (
              <SwAlert
                {...getAlertPropsByType(status.type)}
                modSmall
                close={() => setStatus(null)}
              >
                {status.msg}
              </SwAlert>
            )}
            <SwActionGroup>
              <SwButton
                modLarge
                modDisabled={!canSwitchLanguage}
                onClick={switchCustomerLanguages}
                modLoading={loading}
              >
                {t(i18nKeys.languageRequest.cta.changeLanguageToFrench)}
              </SwButton>
            </SwActionGroup>
          </SwColumn>
        )}
      </SwGrid>
    </SwContainer>
  );
};
