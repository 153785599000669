import React, { AllHTMLAttributes, ChangeEvent, Ref } from 'react';
import { mergeClassNames } from '../utilities';

type SwInputFieldProps = AllHTMLAttributes<HTMLInputElement> & {
  modError?: boolean;
  modDisabled?: boolean;
  modSmall?: boolean;
  modInline?: boolean;
  modBlock?: boolean;
  modFocus?: boolean;
  modRequired?: boolean;
};

type Classes = {
  modError?: boolean;
  modDisabled?: boolean;
  modSmall?: boolean;
  modInline?: boolean;
  modBlock?: boolean;
};
const getClasses = ({
  modBlock,
  modError,
  modDisabled,
  modSmall,
  modInline,
}: Classes) => {
  return [
    'vl-input-field',
    modError ? 'vl-input-field--error' : '',
    modDisabled ? 'vl-input-field--disabled' : '',
    modSmall ? 'vl-input-field--small' : '',
    modInline ? 'vl-input-field--inline' : '',
    modBlock ? 'vl-input-field--block' : '',
  ].join(' ');
};

export const SwInputField = React.forwardRef(
  (
    {
      className,
      modBlock,
      modDisabled,
      modError,
      modInline,
      modSmall,
      modFocus,
      modRequired = false,
      type = 'text',
      value,
      onChange,
      children,
      ...rest
    }: SwInputFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const classes = getClasses({
      modBlock,
      modDisabled,
      modError,
      modInline,
      modSmall,
    });

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <input
        {...rest}
        type={type}
        value={value}
        className={mergeClassNames(classes, className)}
        ref={ref}
        disabled={modDisabled}
        onChange={changeHandler}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={modFocus}
        aria-required={modRequired}
      />
    );
  },
);
