import { Icon } from '../icon/SwIcon.constants';

export enum AlertType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFORMATION = 'INFORMATION',
}

export const getAlertPropsByType = (type?: AlertType) => {
  switch (type) {
    case AlertType.SUCCESS:
      return {
        icon: Icon.CHECK_CIRCLE,
        modSuccess: true,
      };
    case AlertType.WARNING:
      return {
        icon: Icon.ALERT_CIRCLE,
        modWarning: true,
      };
    case AlertType.ERROR:
      return {
        icon: Icon.ALERT_CIRCLE,
        modError: true,
      };
    case AlertType.INFORMATION:
      return {
        icon: Icon.INFO_CIRCLE,
      };
    default:
      return {
        icon: Icon.CHECK_CIRCLE,
      };
  }
};
