import { ValidationError, ValidatorFunction } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { DateTime } from 'luxon';

export const minEighteenYearOld: ValidatorFunction = (
  value: string,
): ValidationError | false => {
  if (!value) {
    return false;
  }

  const date = DateTime.fromISO(value);

  if (date > DateTime.local().minus({ year: 18 })) {
    return {
      defaultMessage: 'Subscription non authorized under 18 years old',
      id: i18nKeys.register.errors.form.minEighteenYearOld,
    };
  }

  return false;
};
