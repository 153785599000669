import * as React from 'react';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { RouteComponentProps } from '@reach/router';
import { SwButton, SwContainer } from '@kaa/ui-flanders/components';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../components';
import { getPath, Routes } from '../../routes';

export const MaintenanceScreen = (props: RouteComponentProps) => {
  const { t } = useTranslation();

  return (
    <SwContainer error>
      <PageHeader
        title={t(i18nKeys.errors.server.maintenance.title)}
        introduction={t(i18nKeys.errors.server.maintenance.introText)}
        className="vl-u-spacer--medium"
      />
      <SwButton
        onClick={() => {
          // use window.location.href navigate to / to force reload
          window.location.href = getPath(Routes.LANDING);
        }}
      >
        {t(i18nKeys.errors.serviceUnavailableScreen.cta)}
      </SwButton>
    </SwContainer>
  );
};
