import { BankAccount } from '@kaa/api/customers';
import { Format } from '@kaa/common/utils';
import {
  checked,
  isBic,
  isIban,
  isValidForeignIban,
  min,
  onlyIf,
  required,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwCheckboxField,
  SwColumn,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwInputTextField,
  SwLink,
  SwModalRenderProps,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikProps } from 'formik';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MotionDiv } from '../../../../../../components/animation/MotionDiv';
import { createValidatorWithServerErrorHandled } from '../../../../../../utils/validation';
import { RefundPaperForm } from './RefundPaper.utils';

const validate = createValidatorWithServerErrorHandled({
  'beneficiaryBankAccount.iban': [required, isIban],
  'beneficiaryBankAccount.bic': onlyIf(
    (bic, values) => isValidForeignIban(values.beneficiaryBankAccount?.iban),
    [required, isBic],
  ),
  quantity: [required, min(1)],
  readAndApproved: [required, checked],
});

const gridMargin = window.innerWidth < 767 ? '1.5rem' : '3rem';
const bicCodeVariants = {
  close: {
    height: 0,
    overflow: 'hidden',
    marginBottom: 0,
    marginTop: 0,
    minWidth: `calc(100% + ${gridMargin})`,
  },
  open: {
    height: 'auto',
    overflow: 'hidden',
    marginTop: 15,
    transitionEnd: {
      overflow: 'inherit',
    },
  },
};

type RefundModalProps = {
  bankAccountsOptions: Array<{ text: string; value: BankAccount }>;
  children: ReactNode;
  refundablePercentage: number;
  submit: any;
};

export const RefundPaper = ({
  bankAccountsOptions,
  setConfirmCloseModal,
  children,
  refundablePercentage,
  submit,
}: SwModalRenderProps<RefundModalProps>) => {
  const { t } = useTranslation();

  const [
    isNewbeneficiaryBankAccount,
    setIsNewbeneficiaryBankAccount,
  ] = useState(!bankAccountsOptions.length);

  const handleTogglebeneficiaryBankAccountInput = () =>
    setIsNewbeneficiaryBankAccount((value) => !value);

  return (
    <>
      {children}
      <Formik<RefundPaperForm>
        initialValues={{
          beneficiaryBankAccount: bankAccountsOptions.length
            ? bankAccountsOptions[0].value
            : (undefined as any),
          quantity: 1,
          readAndApproved: false,
        }}
        onSubmit={submit}
        validate={validate}
      >
        {({
          values,
          handleSubmit,
          dirty,
          isSubmitting,
          setFieldValue,
        }: FormikProps<RefundPaperForm>) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit} style={{ width: '100%' }}>
              <SwFormGrid modStacked>
                <Field
                  name="quantity"
                  type="number"
                  min="0"
                  parse={(value: string) => (value ? parseInt(value, 10) : 0)}
                  label={t(i18nKeys.refund.numberOfVouchers)}
                  column={{ width: '12' }}
                  labelColumn={{ width: '12' }}
                  component={SwInputTextField}
                  modRequired
                />

                <SwFormColumn>
                  {isNewbeneficiaryBankAccount ? (
                    <>
                      <Field
                        name="beneficiaryBankAccount.iban"
                        label={t(i18nKeys.general.label.bankAccount)}
                        column={{ width: '12' }}
                        labelColumn={{ width: '12' }}
                        component={SwInputTextField}
                        modRequired
                        format={(value: string) =>
                          value ? Format.iban(value) : ''
                        }
                      />
                      <MotionDiv
                        className="vl-grid vl-u-spacer"
                        initial="close"
                        isOpen={isValidForeignIban(
                          values.beneficiaryBankAccount?.iban,
                        )}
                        variants={bicCodeVariants}
                      >
                        <Field
                          name="beneficiaryBankAccount.bic"
                          label={t(i18nKeys.general.label.bic)}
                          column={{ width: '12' }}
                          labelColumn={{ width: '12' }}
                          component={SwInputTextField}
                          modRequired
                        />
                      </MotionDiv>
                    </>
                  ) : (
                    <Field
                      name="beneficiaryBankAccount.iban"
                      label={t(i18nKeys.general.label.bankAccount)}
                      options={bankAccountsOptions.map(({ text, value }) => ({
                        text,
                        value: value.iban,
                      }))}
                      inputOnChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const bic = bankAccountsOptions.find(
                          (option) => option.value.iban === e.target.value,
                        )?.value.bic;
                        setFieldValue('beneficiaryBankAccount.bic', bic);
                      }}
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      component={SwSelectField}
                      modRequired
                    />
                  )}
                  {!!bankAccountsOptions.length && (
                    <SwLink
                      onClick={() => {
                        if (!isNewbeneficiaryBankAccount) {
                          setFieldValue('beneficiaryBankAccount', undefined);
                        } else {
                          setFieldValue(
                            'beneficiaryBankAccount',
                            bankAccountsOptions[0].value,
                          );
                        }
                        handleTogglebeneficiaryBankAccountInput();
                      }}
                    >
                      {isNewbeneficiaryBankAccount
                        ? t(i18nKeys.refund.cta.selectPreviousAccount)
                        : t(i18nKeys.refund.cta.useAnotherAccount)}
                    </SwLink>
                  )}
                </SwFormColumn>

                <p>
                  {t(i18nKeys.refund.paper.disclaimer, {
                    percentage: refundablePercentage,
                  })}
                </p>

                <SwColumn>
                  <Field
                    name="readAndApproved"
                    label={t(i18nKeys.refund.paper.readAndApproved)}
                    component={SwCheckboxField}
                    modRequired
                  />
                </SwColumn>

                <SwActionGroup>
                  <SwButton
                    type="submit"
                    modLarge
                    modDisabled={!dirty}
                    modLoading={isSubmitting}
                  >
                    {t(i18nKeys.general.cta.validate)}
                  </SwButton>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      </Formik>
    </>
  );
};
