/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type ServiceStatus =
  | 'ACTIVE_AWAITING_VALIDATION'
  | 'ACTIVE_CONTESTED'
  | 'ARCHIVED_CANCELLED'
  | 'ACTIVE_AWAITING_PAYMENT'
  | 'ACTIVE_NOT_ENOUGH_VOUCHERS'
  | 'ARCHIVED_PAID'
  | 'ACTIVE_AWAITING_PROVIDER_APPROVAL'
  | 'ACTIVE_PARTIALLY_PAID'
  | 'ARCHIVED_PARTIALLY_PAID'
  | 'ARCHIVED_OUTSIDE_SV';

export const ServiceStatus = {
  ACTIVE_AWAITING_VALIDATION: 'ACTIVE_AWAITING_VALIDATION' as ServiceStatus,
  ACTIVE_CONTESTED: 'ACTIVE_CONTESTED' as ServiceStatus,
  ARCHIVED_CANCELLED: 'ARCHIVED_CANCELLED' as ServiceStatus,
  ACTIVE_AWAITING_PAYMENT: 'ACTIVE_AWAITING_PAYMENT' as ServiceStatus,
  ACTIVE_NOT_ENOUGH_VOUCHERS: 'ACTIVE_NOT_ENOUGH_VOUCHERS' as ServiceStatus,
  ARCHIVED_PAID: 'ARCHIVED_PAID' as ServiceStatus,
  ACTIVE_AWAITING_PROVIDER_APPROVAL: 'ACTIVE_AWAITING_PROVIDER_APPROVAL' as ServiceStatus,
  ACTIVE_PARTIALLY_PAID: 'ACTIVE_PARTIALLY_PAID' as ServiceStatus,
  ARCHIVED_PARTIALLY_PAID: 'ARCHIVED_PARTIALLY_PAID' as ServiceStatus,
  ARCHIVED_OUTSIDE_SV: 'ARCHIVED_OUTSIDE_SV' as ServiceStatus,
};
