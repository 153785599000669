import { FiscalAttestation } from '@kaa/api/customers';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { Icon, SwIcon, SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { dataTest } from '../../../../datatest/keys';
import { CurrentDocument } from '../FiscalAttestationScreen.constants';

type FiscalAttestationHistoryButtonProps = {
  fiscalAttestation: FiscalAttestation;
  openModal: (currentDocument: CurrentDocument) => void;
};

export const FiscalAttestationHistoryButton = ({
  fiscalAttestation,
  openModal,
}: FiscalAttestationHistoryButtonProps) => {
  if (!fiscalAttestation.fiscalAttestationData) {
    return null;
  }

  const {
    fiscalYear,
    fiscalAttestationData: { documentId },
  } = fiscalAttestation;

  if (!documentId) {
    return null;
  }

  return (
    <li style={{ marginRight: '1rem' }}>
      <SwLink
        tagName="button"
        onClick={(e) => {
          e.preventDefault();
          openModal({
            fiscalYear,
            documentId,
          });
        }}
        data-testid={dynamicDataTest(dataTest.fiscalAttestation.attestation, {
          file: fiscalYear,
        })}
      >
        <SwIcon
          icon={Icon.FILE_DOWNLOAD}
          style={{ marginRight: '0.5rem' }}
          modLarge
        />
        {fiscalYear}
      </SwLink>
    </li>
  );
};
