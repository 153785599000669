import { i18nKeys } from '@kaa/i18n/common/keys';
import { TFunction } from 'i18next';
import React, { AllHTMLAttributes, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../icon/SwIcon.constants';
import { SwLink } from '../link/SwLink';

export enum PagerItemType {
  FIRST = 'first',
  PREVIOUS = 'previous',
  NEXT = 'next',
  LAST = 'last',
}

type PagerItemProps<T = HTMLLIElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  type: PagerItemType;
  label?: string;
  onClick?: (e: MouseEvent) => void;
};

type Config = {
  icon: Icon;
  text: string;
  iconBefore?: boolean;
  iconAfter?: boolean;
};

const getConfig = ({
  type,
  label,
  t,
}: {
  type: PagerItemType;
  label?: string;
  t: TFunction;
}): Config => {
  const config = {
    [PagerItemType.FIRST]: {
      icon: Icon.ARROW_LEFT,
      iconBefore: true,
      text: label || t(i18nKeys.general.first) || PagerItemType.FIRST,
    },
    [PagerItemType.PREVIOUS]: {
      icon: Icon.ARROW_LEFT,
      iconBefore: true,
      text: label || t(i18nKeys.general.previous) || PagerItemType.PREVIOUS,
    },
    [PagerItemType.NEXT]: {
      icon: Icon.ARROW_RIGHT,
      iconAfter: true,
      text: label || t(i18nKeys.general.next) || PagerItemType.NEXT,
    },
    [PagerItemType.LAST]: {
      icon: Icon.ARROW_RIGHT,
      iconAfter: true,
      text: label || t(i18nKeys.general.last) || PagerItemType.LAST,
    },
  };

  return config[type];
};

const getClasses = () => {
  return ['vl-pager__element'].join(' ');
};

export const SwPagerItem = <T extends HTMLElement>({
  tagName,
  label,
  type,
  ...rest
}: PagerItemProps<T>) => {
  const { t } = useTranslation();
  const TagName = tagName || 'li';

  const classes = getClasses();
  const config = getConfig({ type, label, t });

  return createElement(
    TagName,
    { className: classes },
    <>
      <SwLink
        className="vl-pager__element__cta"
        icon={config.icon}
        modBold
        modIconBefore={config.iconBefore === true}
        modIconAfter={config.iconAfter === true}
        hiddenText={t(i18nKeys.general.page)}
        {...rest}
      >
        {config.text}
      </SwLink>
    </>,
  );
};
