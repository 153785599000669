import { locales } from '@kaa/i18n/customers';
import i18n from 'i18next';
import ICU from 'i18next-icu';
import de from 'i18next-icu/locale-data/de';
import en from 'i18next-icu/locale-data/en';
import fr from 'i18next-icu/locale-data/fr';
import nl from 'i18next-icu/locale-data/nl';
import get from 'lodash.get';
import { initReactI18next } from 'react-i18next';
import { getConfig } from '../../common/config';

export const getI18nInstance = () => {
  i18n
    .use(initReactI18next)
    .use(
      new ICU({
        localeData: [de, nl, fr, en],
      }),
    )
    .init({
      resources: get(
        getConfig(),
        'buildConfig.i18n.availableLanguages',
        [],
      ).reduce(
        (acc: { [key: string]: { [key: string]: any } }, language: string) => ({
          ...acc,
          [language]: {
            translation: {
              ...(locales as { [key: string]: any })[language],
            },
          },
        }),
        {},
      ),
      lng: get(getConfig(), 'buildConfig.i18n.defaultLanguage'),
      debug: process.env.NODE_ENV !== 'production',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });

  return i18n;
};
