import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { FormikHandlers, FormikProps } from 'formik';
import React, { AllHTMLAttributes, ChangeEvent } from 'react';
import { dataTest } from '../../../../datatest/keys';
import { SwFormColumn, SwFormGrid } from '../../../core';
import { Column } from '../../../core/SwColumn';
import { SwFormInput } from '../../../form-input/SwFormInput';
import { SwFormMessageLabel } from '../../../form-message/SwFormMessageLabel';
import { SwTextArea } from '../../../textarea/SwTextArea';
import { useFormError } from '../../../util/hooks/useFormError';
import { FieldPropsClean } from '../../../utilities';
import { SwErrorMessageForField } from '../error-message-for-field/SwErrorMessageForField';

type SwTextAreaFieldProps = AllHTMLAttributes<HTMLTextAreaElement> &
  FieldPropsClean & {
    label?: string;
    description?: string;
    column?: Column;
    labelColumn?: Column;
    modError?: boolean;
    modSmall?: boolean;
    modInline?: boolean;
    modBlock?: boolean;
    modDisabled?: boolean;
    modRequired?: boolean;
    inputOnChange?: (
      event: ChangeEvent<HTMLTextAreaElement>,
      field: {
        onChange: FormikHandlers['handleChange'];
        onBlur: FormikHandlers['handleBlur'];
        value: any;
        name: string;
      },
      form: FormikProps<any>,
    ) => void;
    format?: (value: string) => string;
    autoUnsetServerError?: boolean;
    maxLength: string;
  };

export const SwTextAreaField = ({
  field,
  form,
  label = '',
  description,
  column = { width: '9', widthS: '12' },
  labelColumn = { width: '3', widthS: '12' },
  inputOnChange,
  modBlock = true,
  format,
  autoUnsetServerError = true,
  modRequired,
  maxLength,
  ...props
}: SwTextAreaFieldProps) => {
  const errorMessage = useFormError({ field, form });

  return (
    <SwFormColumn>
      <SwFormGrid>
        {maxLength ? (
          <div style={{ width: '100%', position: 'relative' }}>
            <SwFormColumn>
              <SwFormMessageLabel
                modRequired={modRequired}
                htmlFor={field.name}
              >
                {label}
              </SwFormMessageLabel>
              {description && (
                <p className="vl-u-text--small vl-u-spacer--xsmall">
                  {description}
                </p>
              )}
            </SwFormColumn>
            <div
              className="vl-u-text--small"
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              {`${field.value ? field.value.length : 0}/${maxLength}`}
            </div>
          </div>
        ) : (
          <SwFormColumn>
            <SwFormMessageLabel modRequired={modRequired} htmlFor={field.name}>
              {label}
            </SwFormMessageLabel>
          </SwFormColumn>
        )}

        <SwFormColumn {...column}>
          <SwFormInput>
            <SwTextArea
              id={field.name}
              data-testid={dynamicDataTest(dataTest.SwInputTextField, {
                fieldName: field.name,
              })}
              {...props}
              value={field.value || ''}
              modBlock={modBlock}
              maxLength={maxLength}
              onBlur={(e) => {
                /* To fix the case where the click is trigger after the error css rendering
                (if you want to reproduce the bug go into a SwModal where you can click into an input and  then directly click to the close button) */
                if (
                  !e.relatedTarget ||
                  (e.relatedTarget as HTMLButtonElement).type !== 'button'
                ) {
                  field.onBlur(e);
                }
              }}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                // We have to do mutation for formik
                e.target.value = format
                  ? format(e.target.value)
                  : e.target.value;

                field.onChange(e);
                if (inputOnChange) {
                  inputOnChange(e, field, form);
                }
              }}
              modError={!!errorMessage}
              modRequired={modRequired}
            />
            <SwErrorMessageForField
              field={field}
              form={form}
              autoUnsetServerError={autoUnsetServerError}
              testId={dynamicDataTest(dataTest.SwInputTextFieldError, {
                fieldName: field.name,
              })}
            />
          </SwFormInput>
        </SwFormColumn>
      </SwFormGrid>
    </SwFormColumn>
  );
};
