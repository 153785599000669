import { ChangeEvent, MutableRefObject, useLayoutEffect, useRef } from 'react';
import { createTextMaskInputElement } from 'text-mask-core';

/*
  input. Required. A reference created by React.createRef or useRef to the input element rendered by React.
  mask. Required. An array or a function that defines how the user input is going to be masked. See more.
  onChange. A function to be called when the input changes.
  guide. A boolean that tells the component whether to be in guide or no guide mode. See more.
  keepCharPositions. A boolean that when true, adding or deleting characters affects won't affect the position of other characters, if false, it pushes them. Defaults to false. See more.
  pipe. A function to modify the conformed value before it is displayed on the screen. See more.
  placeholderChar. A string representing the fillable spot in the mask. Defaults to an underscore (_). See more.
  showMask. A boolean that tells the component to display the mask as a placeholder in place of the regular placeholder when the input element value is empty. Defaults to false. See more.
  value. A string with the value. Defaults to ``.
*/

type MaskedInputProps<T = HTMLInputElement> = {
  guide?: boolean;
  input: MutableRefObject<T | undefined | null>;
  keepCharPositions?: boolean;
  mask?: Array<string | RegExp> | ((value: string) => Array<string | RegExp>);
  onChange: (event: ChangeEvent<T>) => void;
  pipe?: (value: string) => void;
  placeholderChar?: string;
  showMask?: boolean;
  value?: string;
};

export const useMaskedInput = <T extends HTMLInputElement>({
  guide = false,
  input,
  keepCharPositions,
  mask,
  onChange,
  pipe,
  placeholderChar = '\u2000',
  showMask,
  value = '',
}: MaskedInputProps<T>) => {
  const textMask = useRef<T & { update: (value?: string) => void }>();

  function init() {
    if (!input.current) {
      return;
    }

    if (!mask) {
      return;
    }

    textMask.current = createTextMaskInputElement({
      guide,
      inputElement: input.current,
      keepCharPositions,
      mask,
      pipe,
      placeholderChar,
      showMask,
    }) as T & { update: (value?: string) => void };

    textMask.current.update(value);
  }

  useLayoutEffect(init, [
    guide,
    keepCharPositions,
    mask,
    pipe,
    placeholderChar,
    showMask,
  ]);

  useLayoutEffect(() => {
    if (input.current && value === input.current.value) {
      return;
    }

    init();
  }, [value]);

  return (event: ChangeEvent<T>) => {
    if (textMask.current) {
      textMask.current.update();
    }

    if (typeof onChange === 'function') {
      onChange(event);
    }
  };
};
