import { FieldValidationErrorCode } from '@kaa/api/customers';

export const mapServerArrayErrors = <T>(
  errors: { [x in keyof T]: FieldValidationErrorCode[] },
) => {
  return Object.entries(errors).reduce(
    (acc, [fieldName, error]) => ({
      ...acc,
      [fieldName
        .replace('[', '.')
        .replace(']', '')
        .toLocaleLowerCase()]: error, // TODO: remove lowercase when bff fix fieldName
    }),
    {} as any,
  );
};
