import React, { AllHTMLAttributes, createElement, Ref } from 'react';
import { mergeClassNames } from '../utilities';

type InputGroupProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
};

export const SwInputGroup = React.forwardRef(
  <T extends HTMLElement>(
    { className, tagName, children, ...rest }: InputGroupProps,
    ref: Ref<T>,
  ) => {
    const TagName = tagName || 'div';
    const classes = 'vl-input-group';

    return createElement(
      TagName,
      {
        className: mergeClassNames(classes, className),
        ref,
        ...rest,
      },
      children,
    );
  },
);
