import { CustomerCreate } from '@kaa/api/customers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwActionLabel,
  SwAlert,
  SwColumn,
  SwContainer,
  SwFormGrid,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../components';
import { getRouterPath, Routes } from '../../../routes';

export const RegisterSummaryScreen = ({
  navigate,
  location,
}: RouteComponentProps) => {
  const { t } = useTranslation();

  const state =
    location && (location.state as { data: CustomerCreate; eid: boolean });

  if (!state) {
    if (navigate) {
      navigate(getRouterPath(Routes.REGISTER));
    }
    return null;
  }

  const { data: customer, eid } = state;

  const {
    emailAddress,
    firstName,
    lastName,
    birthDate,
    niss,
    mainAddress,
    deliveryAddress,
    preferredSupportType,
    phoneNumber,
    mobilePhoneNumber,
    language,
    refundBankAccount,
  } = customer;

  return (
    <SwContainer>
      <SwGrid modStacked>
        <PageHeader
          title={
            !eid
              ? t(i18nKeys.register.manually.title)
              : t(i18nKeys.register.eid.title)
          }
          className="push-pageHeader"
        />
        <SwColumn width="9" widthS="12">
          <SwAlert
            icon={Icon.CHECK_CIRCLE}
            modSuccess
            title={t(i18nKeys.register.summary.alert.title)}
          >
            {t(i18nKeys.register.summary.alert.content, {
              emailAddress,
            })}
          </SwAlert>
        </SwColumn>
        <SwColumn width="10" widthS="12">
          <SwTitle tagName="h2" tagStyle="h5" className="vl-u-spacer--xsmall">
            {t(i18nKeys.profile.personalInformation.title)}
          </SwTitle>
          <SwFormGrid modStacked>
            <SwActionLabel
              label={t(i18nKeys.general.label.firstName)}
              value={firstName}
            />
            <SwActionLabel
              label={t(i18nKeys.general.label.name)}
              value={lastName}
            />
            <SwActionLabel
              label={t(i18nKeys.profile.dateOfBirth)}
              value={Format.date(birthDate)}
            />
            <SwActionLabel
              label={t(i18nKeys.profile.nationalNumber)}
              value={Format.nationalNumber(niss)}
            />
            <SwActionLabel
              label={t(i18nKeys.general.label.postcode)}
              value={mainAddress.postcode}
            />
            <SwActionLabel
              label={t(i18nKeys.general.label.city)}
              value={mainAddress.city}
            />
            <SwActionLabel
              label={t(i18nKeys.general.label.street)}
              value={mainAddress.street}
            />
            <SwActionLabel
              label={t(i18nKeys.general.label.number)}
              value={mainAddress.houseNumber}
            />
          </SwFormGrid>
        </SwColumn>
        {deliveryAddress && (
          <SwColumn width="10" widthS="12">
            <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--small">
              {t(i18nKeys.profile.deliveryAddress.title)}
            </SwTitle>
            <SwFormGrid modStacked>
              <SwActionLabel
                label={t(i18nKeys.general.label.postcode)}
                value={deliveryAddress.postcode}
              />
              <SwActionLabel
                label={t(i18nKeys.general.label.city)}
                value={deliveryAddress.city}
              />
              <SwActionLabel
                label={t(i18nKeys.general.label.street)}
                value={deliveryAddress.street}
              />
              <SwActionLabel
                label={t(i18nKeys.general.label.number)}
                value={deliveryAddress.houseNumber}
              />
            </SwFormGrid>
          </SwColumn>
        )}
        <SwColumn width="10" widthS="12">
          <SwFormGrid modStacked>
            <SwTitle tagName="h3" tagStyle="h5">
              {t(i18nKeys.register.summarySection.preference.title)}
            </SwTitle>
            <SwActionLabel
              label="Type"
              value={t(i18nKeys.general.supportType[preferredSupportType])}
            />
          </SwFormGrid>
        </SwColumn>
        <SwColumn width="10" widthS="12">
          <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--small">
            {t(i18nKeys.register.summarySection.contact.title)}
          </SwTitle>
          <SwFormGrid modStacked>
            <SwActionLabel
              label={t(i18nKeys.general.label.phoneNumber)}
              value={phoneNumber}
            />
            <SwActionLabel
              label={t(i18nKeys.general.label.mobilePhoneNumber)}
              value={mobilePhoneNumber}
            />

            <SwActionLabel
              label={t(i18nKeys.general.label.emailAddress)}
              value={emailAddress}
            />

            <SwActionLabel
              label={t(i18nKeys.profile.communicationLanguage)}
              value={language}
            />
          </SwFormGrid>
        </SwColumn>
        <SwColumn width="10" widthS="12">
          <SwFormGrid modStacked>
            <SwTitle tagName="h3" tagStyle="h5">
              {t(i18nKeys.profile.bankDetails.title)}
            </SwTitle>

            <SwActionLabel
              label={t(i18nKeys.register.form.bankAccount.label)}
              value={Format.iban(refundBankAccount)}
            />
          </SwFormGrid>
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
