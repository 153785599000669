import { Log, UserManager, UserManagerSettings } from 'oidc-client';
import {
  AuthStorageKeys,
  CLIENT_ID_CITIZEN_VL,
  CLIENT_ID_CITIZEN_VL_OCMW,
  CLIENT_ID_PROVIDER_VL,
  CLIENT_ID_PROVIDER_VL_LOCAL,
} from './auth.constant';
import { AuthServiceAbstract } from './auth.service.abstract';
import { AuthConfig, AuthUser } from './auth.type';

// Logger
if (process.env.NODE_ENV === 'development') {
  Log.logger = console;
  Log.level = Log.DEBUG;
}

const { logger } = Log;

// rename file
export class AuthService implements AuthServiceAbstract {
  private userManager: UserManager;

  constructor(
    private settings: UserManagerSettings,
    private config: AuthConfig,
  ) {
    if (
      sessionStorage.getItem(AuthStorageKeys.OCMW_LOGIN) &&
      settings.client_id === CLIENT_ID_CITIZEN_VL
    ) {
      // eslint-disable-next-line
      settings.client_id = CLIENT_ID_CITIZEN_VL_OCMW;
    } else {
      sessionStorage.removeItem(AuthStorageKeys.OCMW_LOGIN);
    }

    if (
      sessionStorage.getItem(AuthStorageKeys.LOCAL_LOGIN) &&
      settings.client_id === CLIENT_ID_PROVIDER_VL
    ) {
      // eslint-disable-next-line
      settings.client_id = CLIENT_ID_PROVIDER_VL_LOCAL;
    } else {
      sessionStorage.removeItem(AuthStorageKeys.LOCAL_LOGIN);
    }

    this.userManager = new UserManager(settings);
  }

  get extraQueryParams() {
    return {
      'localize-key': this.config.lokaliseKey,
    };
  }

  getDirectSigninRequestUrlToIdp = (): Promise<string> => {
    return this.userManager
      .createSigninRequest({
        extraQueryParams: this.extraQueryParams,
      })
      .then((request) => request.url);
  };

  getDirectSigninRequestUrlToIdpLocal = (): Promise<string> => {
    sessionStorage.setItem(AuthStorageKeys.LOCAL_LOGIN, 'true');

    this.userManager = new UserManager({
      ...this.settings,
      client_id: CLIENT_ID_PROVIDER_VL_LOCAL,
    });

    return this.userManager
      .createSigninRequest({
        extraQueryParams: this.extraQueryParams,
      })
      .then((request) => request.url);
  };

  getSigninRequestUrl = () => {
    return this.config.loginUrl;
  };

  getLogoutRequestUrl = () => {
    return this.config.logoutUrl;
  };

  signinRedirect = (callback?: () => void) => {
    return this.userManager.signinRedirect({
      extraQueryParams: this.extraQueryParams,
    });
  };

  signinRedirectForOCMW = (callback?: () => void) => {
    sessionStorage.setItem(AuthStorageKeys.OCMW_LOGIN, 'true');

    this.userManager = new UserManager({
      ...this.settings,
      client_id: CLIENT_ID_CITIZEN_VL_OCMW,
    });

    return this.userManager.signinRedirect({
      extraQueryParams: this.extraQueryParams,
    });
  };

  signinRedirectCallback = () => {
    logger.info('signinRedirectCallback');

    return this.userManager.signinRedirectCallback().then((user: AuthUser) => {
      logger.info('signinRedirectCallback::USER', user);
      return user;
    });
  };

  signinSilent = () => {
    // oidc-client doesn't take the client_secret so we have to add it manually
    const { client_secret } = this.settings;
    return this.userManager
      .signinSilent({
        client_secret,
        extraQueryParams: this.extraQueryParams,
      })
      .then((user: AuthUser) => {
        logger.info('signinSilent::USER', user);
        return user;
      })
      .catch((error: any) => {
        logger.info('signinSilent::ERROR', error);
        throw error;
      });
  };

  signinSilentCallback = () => {
    logger.info('signinSilentCallback::start');
    return this.userManager
      .signinSilentCallback()
      .then((user: any) => {
        logger.info('signinSilentCallback::OK', user);
      })
      .catch((err: any) => {
        logger.info('signinSilentCallback::error', err);
      });
  };

  logout = () => {
    return Promise.all([
      this.userManager.signoutRedirect({
        id_token_hint: localStorage.getItem(AuthStorageKeys.ID_TOKEN),
        extraQueryParams: this.extraQueryParams,
      }),
      this.userManager
        .clearStaleState()
        .then(() => sessionStorage.removeItem(AuthStorageKeys.OCMW_LOGIN)),
    ]);
  };

  signoutRedirectCallback = () => {
    return Promise.all([
      this.userManager
        .signoutRedirectCallback()
        .then(() => window.location.replace(this.config.origin)),
      this.userManager
        .clearStaleState()
        .then(() => sessionStorage.removeItem(AuthStorageKeys.OCMW_LOGIN)),
    ]);
  };

  getUser = () => {
    return this.userManager.getUser();
  };

  getClientId = () => {
    return this.settings.client_id;
  };

  registerOnUserLoaded = (callback: (...ev: any[]) => void) => {
    this.userManager.events.addUserLoaded(callback);
  };

  unregisterOnUserLoaded = (callback: (...ev: any[]) => void) => {
    this.userManager.events.removeUserLoaded(callback);
  };

  registerOnUserSignedOut = (callback: (...ev: any[]) => void): void => {
    this.userManager.events.addUserSignedOut(callback);
  };

  unregisterOnUserSignedOut = (callback: (...ev: any[]) => void): void => {
    this.userManager.events.removeUserSignedOut(callback);
  };

  registerOnAccessTokenExpiring = (callback: (...ev: any[]) => void): void => {
    this.userManager.events.addAccessTokenExpiring(callback);
  };

  unregisterOnAccessTokenExpiring = (
    callback: (...ev: any[]) => void,
  ): void => {
    this.userManager.events.removeAccessTokenExpiring(callback);
  };
}
