import { RegionCode } from '@kaa/api/customers';
import { Format, useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwAlert,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
} from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../common/config';
import {
  ContainerLoadingPageHeader,
  Onboarding,
  PageHeader,
} from '../../components';
import { dataTest } from '../../datatest/keys';
import { getPath, Routes } from '../../routes';
import { useApi, useSelectedCustomerState } from '../../utils';
import { EventAction } from '../../utils/google-analytics';
import { DashboardAlertFiscalAttestation } from './DashboardAlertFiscalAttestation';
import { DashboardAlertNotEnoughVouchers } from './DashboardAlertNotEnoughVouchers';
import { DashboardAlertSwitchFromPaper } from './DashboardAlertSwitchFromPaper';
import { DashboardCard } from './DashboardCard';

export const DashboardScreen = () => {
  const { t } = useTranslation();

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const customer = useSelectedCustomerState();
  const { customers } = useApi();

  const [{ value: dashboard, loading, error }, getDashboard] = useAsyncCallback(
    async () => {
      const [
        dashboardResponse,
        customerPreferencesResponse,
      ] = await Promise.all([
        customers.getDashboard(customer.id).then(({ data: { data } }) => data),
        customers
          .getCustomerPreferences(customer.id)
          .then(({ data: { data } }) => data),
      ]);

      return { ...dashboardResponse, ...customerPreferencesResponse };
    },
    [customers],
    { loading: true },
  );

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  if (!dashboard && loading) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.navigation.dashboard)}
        introduction={t(i18nKeys.dashboard.introduction)}
        modFullwidth
      />
    );
  }

  if (error || !dashboard) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getDashboard} />
      </SwContainer>
    );
  }

  const {
    pendingServicesCount,
    closestVouchersToExpiryQuantity,
    closestVouchersToExpiryExpirationDate,
    availableVouchersQuantity,
    latestAvailableFiscalAttestationYear,
    notEnoughVouchers,
    supportTypePreference,
  } = dashboard;

  return (
    <SwContainer>
      <Onboarding activeRoute={Routes.DASHBOARD} />
      <SwGrid modStacked className="vl-u-flex-v-flex-start">
        <PageHeader
          title={t(i18nKeys.navigation.dashboard)}
          introduction={t(i18nKeys.dashboard.introduction)}
          alert={
            <>
              <DashboardAlertFiscalAttestation
                availableYear={latestAvailableFiscalAttestationYear}
              />
              <DashboardAlertNotEnoughVouchers
                enoughVouchers={!notEnoughVouchers}
              />
              <DashboardAlertSwitchFromPaper
                supportType={supportTypePreference}
              />
            </>
          }
        />
        <SwColumn>
          <SwGrid className="vl-u-flex-v-flex-start">
            {Boolean(pendingServicesCount) && (
              // TODO : improve the way we define the icon depending of the region
              <DashboardCard
                icon={
                  regionCode === 'BE_VLG' ? Icon.HOURGLASS : Icon.WORKSPENDING
                }
                title={t(i18nKeys.dashboard.cards.workPending.title)}
                description={t(
                  i18nKeys.dashboard.cards.workPending.description,
                  {
                    count: pendingServicesCount,
                  },
                )}
                subDescription={t(
                  i18nKeys.dashboard.cards.workPending.subDescription,
                )}
                to={getPath(Routes.PRESTATIONS_WORKS_MANAGEMENT)}
                cta={t(i18nKeys.dashboard.cta.PRESTATIONS_WORKS_MANAGEMENT)}
                ctaTagManagerId={EventAction.GO_WORKS_MANAGEMENT}
                data-testid={dataTest.dashboard.cards.workPending}
              />
            )}
            {Boolean(closestVouchersToExpiryQuantity) && (
              <DashboardCard
                icon={Icon.VOUCHER_CHECK}
                title={t(i18nKeys.dashboard.cards.vouchersStatus.title)}
                to={getPath(Routes.SERVICES_VOUCHERS_WALLET)}
                cta={t(i18nKeys.dashboard.cta.SERVICES_VOUCHERS_WALLET)}
                ctaTagManagerId={EventAction.GO_WALLET}
                data-testid={dataTest.dashboard.cards.vouchersStatus}
              >
                <SwAlert
                  className="vl-u-spacer--none"
                  icon={Icon.INFO_CIRCLE}
                  modWarning
                  modSmall
                  style={{ textAlign: 'left' }}
                  title={t(
                    i18nKeys.dashboard.cards.vouchersStatus.description,
                    {
                      count: closestVouchersToExpiryQuantity,
                    },
                  )}
                >
                  {t(i18nKeys.dashboard.cards.vouchersStatus.subDescription, {
                    date: Format.date(closestVouchersToExpiryExpirationDate),
                    count: closestVouchersToExpiryQuantity,
                  })}
                </SwAlert>
              </DashboardCard>
            )}
            <DashboardCard
              icon={Icon.WALLET}
              title={t(i18nKeys.dashboard.cards.wallet.title)}
              description={t(i18nKeys.dashboard.cards.wallet.description, {
                count: availableVouchersQuantity,
              })}
              to={getPath(Routes.SERVICES_VOUCHERS_WALLET)}
              cta={t(i18nKeys.dashboard.cta.SERVICES_VOUCHERS_WALLET)}
              ctaTagManagerId={EventAction.GO_WALLET}
              data-testid={dataTest.dashboard.cards.wallet}
            />
            {/* Note: "Paper order status" is missing for now */}
          </SwGrid>
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
