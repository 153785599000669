import React, { AllHTMLAttributes } from 'react';
import { SwColumn } from '../../core';
import { mergeClassNames, Omit } from '../../utilities';

type SliderSlideProps<T = HTMLDivElement> = Omit<
  AllHTMLAttributes<T>,
  'cols'
> & {
  width?: string;
  widthL?: string;
  widthM?: string;
  widthS?: string;
  widthXs?: string;
};

const getClasses = () => ['vl-slide'].join(' ');

export const SwSliderSlide = ({
  className,
  children,
  ...rest
}: SliderSlideProps) => {
  const classes = getClasses();
  return (
    <SwColumn className={mergeClassNames(classes, className)} {...rest}>
      {children}
    </SwColumn>
  );
};
