import React, { AllHTMLAttributes, createElement, Ref } from 'react';
import { SwIcon } from '../icon/SwIcon';
import { useVisuallyHidden } from '../util/hooks/useVisuallyHidden';
import { mergeClassNames } from '../utilities';

type BadgeProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  icon?: string;
  initials?: string;
  hiddenLabel?: string;
  modXxsmall?: boolean;
  modXsmall?: boolean;
  modSmall?: boolean;
  modMedium?: boolean;
  modLarge?: boolean;
  modXlarge?: boolean;
  modAlt?: boolean;
  modWhite?: boolean;
  modAccent?: boolean;
  modAction?: boolean;
  modSuccess?: boolean;
  modWarning?: boolean;
  modError?: boolean;
  modBorder?: boolean;
  modBlock?: boolean;
};

type Classes = {
  icon?: string;
  initials?: string;
  modXxsmall?: boolean;
  modXsmall?: boolean;
  modSmall?: boolean;
  modMedium?: boolean;
  modLarge?: boolean;
  modXlarge?: boolean;
  modAlt?: boolean;
  modWhite?: boolean;
  modAccent?: boolean;
  modAction?: boolean;
  modSuccess?: boolean;
  modWarning?: boolean;
  modError?: boolean;
  modBorder?: boolean;
  modBlock?: boolean;
};
const getClasses = ({
  icon,
  initials,
  modSmall,
  modSuccess,
  modWarning,
  modError,
  modLarge,
  modAccent,
  modAction,
  modAlt,
  modBlock,
  modBorder,
  modMedium,
  modWhite,
  modXlarge,
  modXsmall,
  modXxsmall,
}: Classes) => {
  return [
    'vl-badge',
    modXxsmall ? 'vl-badge--xxsmall' : '',
    modXsmall ? 'vl-badge--xsmall' : '',
    modSmall ? 'vl-badge--small' : '',
    modMedium ? 'vl-badge--medium' : '',
    modLarge ? 'vl-badge--large' : '',
    modXlarge ? 'vl-badge--xlarge' : '',
    modAlt ? 'vl-badge--alt' : '',
    modWhite ? 'vl-badge--white' : '',
    modAccent ? 'vl-badge--accent' : '',
    modAction ? 'vl-badge--action' : '',
    modSuccess ? 'vl-badge--success' : '',
    modWarning ? 'vl-badge--warning' : '',
    modError ? 'vl-badge--error' : '',
    modBorder ? 'vl-badge--border' : '',
    modBlock ? 'vl-badge--block' : '',
    icon ? 'vl-badge--icon' : '',
    initials ? 'vl-badge--initials' : '',
  ]
    .join(' ')
    .trim();
};

export const SwBadge = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      className,
      icon,
      initials,
      hiddenLabel,
      modAccent,
      modAction,
      modAlt,
      modBlock,
      modBorder,
      modError,
      modLarge,
      modMedium,
      modSmall,
      modSuccess,
      modWarning,
      modWhite,
      modXlarge,
      modXsmall,
      modXxsmall,
      children,
      ...rest
    }: BadgeProps<T>,
    ref: Ref<T>,
  ) => {
    const TagName = tagName || 'div';
    const visuallyHiddenRef = useVisuallyHidden<HTMLSpanElement>();

    const classes = getClasses({
      initials,
      icon,
      modXxsmall,
      modXsmall,
      modXlarge,
      modWhite,
      modMedium,
      modBorder,
      modBlock,
      modAlt,
      modAction,
      modAccent,
      modLarge,
      modSmall,
      modWarning,
      modSuccess,
      modError,
    });

    const iconClasses = 'vl-badge__icon';

    return createElement(
      TagName,
      { className: mergeClassNames(classes, className), ref, ...rest },
      initials ? <span>{initials}</span> : null,
      icon ? <SwIcon icon={icon} className={iconClasses} ref={ref} /> : null,
      hiddenLabel ? <span ref={visuallyHiddenRef}>{hiddenLabel}</span> : null,
      children,
    );
  },
);
