/* eslint react/no-array-index-key: "off" */
import {
  useAsyncCallback,
  useDebounce,
  useEventListener,
  useMaskedInput,
  useOnClickOutside,
  useWindowSize,
} from '@kaa/common/utils';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { setError, unsetError } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/common/keys';
import { FormikHandlers, FormikProps } from 'formik';
import React, {
  AllHTMLAttributes,
  ChangeEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { SwFormColumn, SwFormGrid } from '../../../core';
import { Column } from '../../../core/SwColumn';
import { SwFormInput } from '../../../form-input/SwFormInput';
import { SwFormMessageLabel } from '../../../form-message/SwFormMessageLabel';
import { SwInputField } from '../../../input-field/SwInputField';
import { useFormError } from '../../../util/hooks/useFormError';
import { FieldPropsClean } from '../../../utilities';
import { SwErrorMessageForField } from '../error-message-for-field/SwErrorMessageForField';

// might need some tweaking, did some typing tests and it felt right
const DEFAULT_DEBOUNCE_TIME = 300;

enum optionBoxPosition {
  ABOVE = 'above',
  BELOW = 'below',
}

export type AutocompleteInputFieldProps<
  value = string,
  option = any,
  values = any
> = AllHTMLAttributes<HTMLInputElement> &
  FieldPropsClean & {
    // TODO: try to change typings with formik (issue with final form everything can be undefined)
    type?: string;
    label?: string;
    column?: Column;
    labelColumn?: Column;
    placeholder?: string;
    controlled?: boolean;
    inputOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    getOptions?: (
      query: string,
      field: {
        onChange: FormikHandlers['handleChange'];
        onBlur: FormikHandlers['handleBlur'];
        value: value;
        name: string;
      },
      form: FormikProps<values>,
    ) => Promise<option[]>;
    menuOptionFormat?: (option: option) => any;
    onSelectOption?: (
      choice: option,
      field: {
        onChange: FormikHandlers['handleChange'];
        onBlur: FormikHandlers['handleBlur'];
        value: value;
        name: string;
      },
      form: FormikProps<values>,
    ) => void;
    valueExtractor?: (option: any) => string;
    modError?: boolean;
    modSmall?: boolean;
    modInline?: boolean;
    modBlock?: boolean;
    modDisabled?: boolean;
    modWithoutDropdown?: boolean;
    modRequired?: boolean;
    minLength?: number;
    autoUnsetServerError?: boolean;
    mask: Array<string | RegExp> | ((value: string) => Array<string | RegExp>);
    parse?: (value: string) => string;
    format?: (value: string) => string;
    id?: string;
  };

export const AutocompleteInputField = <value, option, values>({
  field,
  form,
  type = 'text',
  label,
  placeholder,
  controlled = true,
  inputOnChange,
  getOptions,
  menuOptionFormat,
  onSelectOption,
  valueExtractor,
  modBlock = true,
  modRequired,
  column = { width: '9', widthS: '12' },
  labelColumn = { width: '3', widthS: '12' },
  autoUnsetServerError = true,
  minLength = 0,
  mask,
  parse,
  format,
  id,
  modWithoutDropdown,
  ...props
}: AutocompleteInputFieldProps<value, option, values>) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [value, setValue] = useState(controlled ? field.value : '');
  const inputElement = useRef<HTMLInputElement>(null);
  const optionsList = useRef<HTMLDivElement>(null);
  const [optionsBoxPosition, setOptionsBoxPosition] = useState<
    optionBoxPosition
  >(optionBoxPosition.BELOW);

  const updateFormValue = (option?: any) => {
    setIsDropdownOpen(false);
    let actualOption = option;
    if (controlled) {
      if (!actualOption && options) {
        actualOption = options.find(
          (o) => (valueExtractor ? valueExtractor(o) : o) === field.value,
        );
      }
      const { setFieldValue, values, errors } = form;
      if (!actualOption) {
        setError({
          setFieldValue,
          name: `${field.name}.autocomplete.invalid`,
        });
      } else {
        if (errors[field.name]) {
          unsetError({
            setFieldValue,
            values,
            name: `${field.name}.autocomplete.invalid`,
          });
        }
        setFieldValue(
          field.name,
          valueExtractor ? valueExtractor(actualOption) : actualOption,
        );
        if (onSelectOption) onSelectOption(actualOption, field, form);
      }
    } else {
      setValue('');
      if (actualOption && onSelectOption) {
        onSelectOption(actualOption, field, form);
      }
    }
  };

  useOnClickOutside(optionsList, () => {
    updateFormValue();
  });

  const { height } = useWindowSize();

  const determineOptionBoxPosition = useCallback(() => {
    if (
      inputElement &&
      inputElement.current &&
      optionsList &&
      optionsList.current
    ) {
      const inputBounds = inputElement.current.getBoundingClientRect();
      const optionsBounds = optionsList.current.getBoundingClientRect();

      if (height - inputBounds.bottom < optionsBounds.height) {
        setOptionsBoxPosition(optionBoxPosition.ABOVE);
      } else {
        setOptionsBoxPosition(optionBoxPosition.BELOW);
      }
    }
  }, [inputElement, optionsList, setOptionsBoxPosition, height]);

  useEventListener('scroll', determineOptionBoxPosition);

  const [{ value: options, loading, error }, handleOptions] = useAsyncCallback(
    async (query: string) => {
      if (!getOptions) {
        return;
      }

      if (!query) {
        setIsDropdownOpen(false);
        return;
      }

      if (minLength > query.length) {
        const minLengthError = t(
          i18nKeys.general.errors.form.autocomplete.minLength,
          {
            num: minLength,
          },
        );
        throw minLengthError; // TODO: i18nKeys
      }

      determineOptionBoxPosition();
      return getOptions(query, field, form);
    },
    [setIsDropdownOpen, determineOptionBoxPosition, getOptions, field, form],
  );

  useDebounce(
    () => {
      handleOptions(value);
    },
    DEFAULT_DEBOUNCE_TIME,
    [value],
  );

  const errorMessage = useFormError({ field, form });

  const currentValue = format ? format(field.value) : field.value;

  const controlledCurrentValue = controlled ? currentValue : value;

  const onChange = useMaskedInput({
    input: inputElement,
    value: controlledCurrentValue,
    mask,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      e.target.value = parse ? parse(e.target.value) : e.target.value;
      if (inputOnChange) {
        inputOnChange(e);
      }
      if (controlled) {
        field.onChange(e);
        if (!e.target.value && form.errors[field.name]) {
          unsetError({
            setFieldValue: form.setFieldValue,
            values: form.values,
            name: `${field.name}.autocomplete.invalid`,
          });
        }
      }
      setValue(e.target.value);

      if (!modWithoutDropdown) {
        setIsDropdownOpen(true);
      }
    },
  });

  return (
    <SwFormColumn>
      <SwFormGrid>
        <SwFormColumn {...labelColumn}>
          <SwFormMessageLabel
            modRequired={modRequired}
            htmlFor={id || field.name}
          >
            {label}
          </SwFormMessageLabel>
        </SwFormColumn>
        <SwFormColumn {...column}>
          <div className="js-vl-autocomplete">
            <span className="vl-u-visually-hidden" />
            <SwFormInput>
              <SwInputField
                id={id || field.name}
                name={field.name}
                type={type}
                data-testid={dynamicDataTest(dataTest.SwAutocompleteField, {
                  fieldName: id || field.name,
                })}
                modBlock={modBlock}
                value={!mask ? controlledCurrentValue : undefined}
                ref={inputElement}
                placeholder={placeholder}
                aria-label={placeholder}
                onBlur={() => form.setFieldTouched(field.name, true, true)}
                onChange={onChange}
                modError={!!errorMessage}
                modRequired={modRequired}
                {...props}
              />
              <SwErrorMessageForField
                field={field}
                form={form}
                autoUnsetServerError={autoUnsetServerError}
                testId={dynamicDataTest(dataTest.SwAutocompleteFieldError, {
                  fieldName: field.name,
                })}
              />
            </SwFormInput>

            {isDropdownOpen && (
              <div
                className="vl-autocomplete"
                ref={optionsList}
                data-position={optionsBoxPosition}
              >
                <div className="vl-autocomplete__list">
                  <div className="vl-autocomplete__list-wrapper">
                    <ul
                      role="listbox"
                      data-testid={dataTest.SwAutocompleteListBox}
                    >
                      <li className="vl-autocomplete__item">
                        {loading && (
                          <>
                            <div className="vl-autocomplete__loader" />
                            <span
                              className="vl-autocomplete__cta__title"
                              style={{ padding: '1rem' }}
                            >
                              {t(i18nKeys.general.autoCompletedataLoading)}
                            </span>
                          </>
                        )}
                        {!loading && options && !options.length && (
                          <span
                            className="vl-autocomplete__cta__title"
                            style={{ padding: '1rem' }}
                          >
                            {t(i18nKeys.general.noResults)}
                          </span>
                        )}
                        {!loading && !options && error && (
                          <span
                            className="vl-autocomplete__cta__title"
                            style={{ padding: '1rem' }}
                          >
                            {error}
                          </span>
                        )}
                        {!loading &&
                          options &&
                          !!options.length &&
                          options
                            .slice(0, 5)
                            .map((option, index, { length }) => (
                              <button
                                type="button"
                                className="vl-autocomplete__cta"
                                style={{
                                  fontSize: '1.6rem',
                                  textAlign: 'left',
                                  width: '100%',
                                }}
                                key={`autocomplete-option-${index}`}
                                data-testid={dynamicDataTest(
                                  dataTest.SwAutoCompleteOption,
                                  { index },
                                )}
                                aria-label={
                                  menuOptionFormat &&
                                  option &&
                                  menuOptionFormat(option)
                                }
                                onBlur={() => {
                                  if (length - 1 === index) {
                                    updateFormValue();
                                  }
                                }}
                                onClick={() => {
                                  updateFormValue(option);
                                }}
                              >
                                {menuOptionFormat &&
                                  option &&
                                  menuOptionFormat(option)}
                              </button>
                            ))}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </SwFormColumn>
      </SwFormGrid>
    </SwFormColumn>
  );
};
