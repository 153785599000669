import { ServiceDelivery } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  selectPage,
  SwAlert,
  SwColumn,
  SwDataTable,
  SwPaginator,
} from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { PrestationsWorkHistoryTableRow } from './PrestationsWorkHistoryTableRow';

const PRESTATION_PAGE_SIZE = 20;

type PrestationsWorkHistoryTableProps = {
  works: ServiceDelivery[];
  seeDetails?: (work: ServiceDelivery) => void;
};

export const PrestationsWorkHistoryTable = ({
  works,
  seeDetails = () => undefined,
}: PrestationsWorkHistoryTableProps) => {
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState(1);

  if (!works.length) {
    return (
      <SwColumn width="12" widthS="12">
        <SwAlert
          icon={Icon.INFO_CIRCLE}
          content={t(i18nKeys.worksManagement.history.alert.noWorksContent)}
          title={t(i18nKeys.worksManagement.history.alert.noWorksTitle)}
        />
      </SwColumn>
    );
  }

  return (
    <>
      <SwColumn width="12">
        <div className="vl-u-table-overflow">
          <SwDataTable modCollapsedM>
            <thead data-testid={dataTest.worksManagement.history.tableHead}>
              <tr>
                <th>{t(i18nKeys.worksManagement.history.table.date)}</th>
                <th>{t(i18nKeys.worksManagement.history.table.activity)}</th>
                <th>{t(i18nKeys.worksManagement.history.table.time)}</th>
                <th>{t(i18nKeys.worksManagement.history.table.provider)}</th>
                <th>{t(i18nKeys.worksManagement.history.table.status)}</th>
              </tr>
            </thead>
            <tbody data-testid={dataTest.worksManagement.history.tableBody}>
              {selectPage(works, selectedPage, PRESTATION_PAGE_SIZE).map(
                (work) => (
                  <PrestationsWorkHistoryTableRow
                    key={work.id}
                    work={work}
                    testId={dataTest.worksManagement.history.table}
                    seeDetails={() => seeDetails(work)}
                  />
                ),
              )}
            </tbody>
          </SwDataTable>
        </div>
      </SwColumn>
      {works.length >= PRESTATION_PAGE_SIZE && (
        <SwColumn width="12">
          <SwPaginator
            itemCount={works.length}
            pageSize={PRESTATION_PAGE_SIZE}
            selectedPage={selectedPage}
            setPage={setSelectedPage}
          />
        </SwColumn>
      )}
    </>
  );
};
