import { createElement, useState } from 'react';
import { mergeClassNames } from '../utilities';
import { ColumnProps, getClasses } from './SwColumn';

export const SwFormColumn = <T extends HTMLElement>({
  tagName,
  className,
  width,
  widthL,
  widthM,
  widthS,
  widthXs,
  push,
  pushL,
  pushM,
  pushS,
  pushXs,
  cols,
  pushers,
  children,
  ...rest
}: ColumnProps<T>) => {
  const TagName = tagName || 'div';
  const [formGridPrefix] = useState('form-');
  const classes = getClasses({
    formGridPrefix,
    width,
    widthL,
    widthM,
    widthS,
    widthXs,
    push,
    pushL,
    pushM,
    pushS,
    pushXs,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
