import { useEventListener, useOnClickOutside } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/common/keys';
import Popper from 'popper.js';
import React, {
  AllHTMLAttributes,
  cloneElement,
  createElement,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SwIcon } from '../icon/SwIcon';
import { Icon } from '../icon/SwIcon.constants';
import { useBreakpoint } from '../util/hooks/useBreakpoint';
import { mergeClassNames, Omit } from '../utilities';

type LabelProps = {
  text?: string;
  tag?: string;
  url?: string;
  hidden?: boolean;
};

export type SwPopverRenderProps = {
  close: () => void;
  [key: string]: unknown;
};

type RenderReactElement = (props: SwPopverRenderProps) => ReactElement;

type AllHTMLAttributesWithoutChildren<T> = Omit<
  AllHTMLAttributes<T>,
  'children'
>;

type SwPopoverProps<T = HTMLDivElement> = AllHTMLAttributesWithoutChildren<
  T
> & {
  tagName?: string;
  basicLabel?: LabelProps;
  advancedLabel?: string | ReactElement;
  labelCloseAria?: string;
  offsetX?: number | string;
  offsetY?: number | string;
  icon?: string;
  modLeft?: boolean;
  modRight?: boolean;
  modCenter?: boolean;
  modClosable?: boolean;
  modIconRotated?: boolean;
  modAlt?: boolean;
  children: ReactElement | RenderReactElement;
  action?: string;
};

type Classes = {
  modAlt?: boolean;
  modLeft?: boolean;
  modRight?: boolean;
  modCenter?: boolean;
  modClosable?: boolean;
  isClosing?: boolean;
  isOpen?: boolean;
};

const getClasses = ({
  modAlt,
  modLeft,
  modRight,
  modCenter,
  modClosable,
  isClosing,
  isOpen,
}: Classes) => {
  return [
    'vl-popover',
    modAlt ? 'vl-popover--alt' : '',
    modLeft ? 'vl-popover--align-left' : '',
    modRight ? 'vl-popover--align-right' : '',
    modCenter ? 'vl-popover--align-center' : '',
    modClosable ? 'vl-popover--closable' : '',
    isClosing ? 'js-vl-popover--closing' : '',
  ].join(' ');
};

const toggleClasses = 'vl-popover__toggle ';
const popoverContentClasses = (isOpen: boolean) => {
  return [
    'vl-popover__content',
    !isOpen ? 'vl-popover__content--close' : '',
  ].join(' ');
};

export const SwPopover = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      children,
      className,
      icon,
      modAlt,
      modIconRotated,
      modLeft,
      modRight,
      modCenter,
      modClosable,
      onPointerUpCapture,
      advancedLabel,
      offsetX = 0,
      offsetY = 0,
      basicLabel = {
        tag: 'button',
        text: 'Open popover',
      },
      action,
      ...rest
    }: SwPopoverProps<T>,
    ref: Ref<T>,
  ) => {
    const TagName = tagName || 'div';
    // TODO: Improve typing
    const forwardRef = ref as any; // Disable typing to assign the current

    const { t } = useTranslation();

    const [latestPopoverZIndex, setLatestPopoverZIndex] = useState(804);
    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [popper, setPopper] = useState<Popper | null>(null);
    const [breakpoint, setBreakpoint] = useState<string | null>(null);
    const popoverReference = useRef<HTMLElement | null>(null);
    const popoverContentReference = useRef<HTMLDivElement | null>(null);
    const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);

    const backdropClass = 'vl-backdrop';
    const backdropActiveClass = 'vl-backdrop--active';

    const classes = getClasses({
      modAlt,
      modLeft,
      modRight,
      modCenter,
      modClosable,
      isClosing,
      isOpen,
    });

    const popoverAdvancedLabel = () => {
      return !!advancedLabel;
    };
    const popoverToggleLabel = basicLabel;
    const popoverToggleLabelHidden = () => {
      if (basicLabel) {
        return basicLabel.hidden;
      }
      return false;
    };

    const popoverToggleIcon = () => {
      return {
        name: icon || Icon.ARROW_RIGHT,
        default: !icon,
        rotation: icon ? modIconRotated : true,
        classes: icon ? '' : 'vl-vi-u-badge vl-vi-u-badge--small',
      };
    };

    const createElementsForMobile = useCallback(() => {
      const backdropCreated = document.querySelector(`.${backdropClass}`);

      if (backdropCreated) {
        setBackdrop(backdropCreated as HTMLDivElement);
        backdropCreated.classList.add(backdropActiveClass);
        return;
      }

      const backdropElement = document.createElement('div');

      backdropElement.classList.add(backdropClass);
      backdropElement.classList.add(backdropActiveClass);

      document.body.appendChild(backdropElement);

      setBackdrop(backdropElement);
    }, [backdropClass, setBackdrop]);

    const initiatePopper = useCallback((): Popper | null => {
      const options = {
        placement: 'bottom-end',
        offset: 10,
        modifiers: {
          offset: {
            offset: '0,10',
          },
          flip: {
            behavior: ['bottom', 'top'],
          },
        },
      };

      if (modRight) {
        options.modifiers.offset.offset = '12, 10';
      }

      if (offsetX && offsetX) {
        options.modifiers.offset.offset = `${offsetX}, ${offsetY}`;
      } else if (offsetX) {
        options.modifiers.offset.offset = `${offsetX}, 10`;
      } else if (offsetY) {
        options.modifiers.offset.offset = `0, ${offsetY}`;
      }

      if (modLeft) {
        options.placement = 'bottom-start';
      } else if (modCenter) {
        options.placement = 'bottom';
      }

      if (popoverReference.current && popoverContentReference.current) {
        const popperInstance: Popper = new Popper(
          popoverReference.current,
          popoverContentReference.current,
          options as any,
        );

        // TODO: maybe this line is wrong but they have it in flanders
        popperInstance.options.onUpdate = (popperInstance as any).state.updateBound;
        popperInstance.options.onCreate = popperInstance.options.onUpdate;
        popperInstance.disableEventListeners();

        return popperInstance;
      }

      return popper;
    }, [modRight, offsetX, offsetY, popoverReference, popoverContentReference]);

    const updateZIndex = useCallback(
      (element: HTMLElement | null) => {
        if (!element) {
          return;
        }
        setLatestPopoverZIndex((index) => {
          const latestPopoverZIndexValue = element.style.zIndex
            ? parseInt(element.style.zIndex, 10)
            : index;

          const zIndex = latestPopoverZIndexValue + 1;

          const el = element;
          el.style.zIndex = zIndex.toString();
          return latestPopoverZIndex;
        });
      },
      [setLatestPopoverZIndex],
    );

    const closePopover = useCallback(() => {
      setIsClosing(true);

      if (backdrop) {
        backdrop.classList.remove(backdropActiveClass);
      }

      if (popoverReference.current) {
        popoverReference.current.setAttribute('aria-expanded', 'false');
        popoverReference.current.classList.remove('js-vl-popover--open');
      }

      if (popper) {
        popper.destroy();
        setPopper(null);
      }

      setIsClosing(false);
    }, [backdrop, setIsClosing]);

    const createPopover = useCallback(() => {
      if (breakpoint === 'xsmall' || breakpoint === 'small') {
        createElementsForMobile();
      } else if (!popper) {
        setPopper(initiatePopper());
      }

      if (popoverReference.current) {
        popoverReference.current.setAttribute('aria-expanded', 'true');
        popoverReference.current.classList.add('js-vl-popover--open');
      }

      if (breakpoint && popoverContentReference.current) {
        if (breakpoint !== 'small' && breakpoint !== 'xsmall') {
          updateZIndex(popoverContentReference.current);
        } else {
          popoverContentReference.current.style.zIndex = '';
        }
      }
    }, [breakpoint, createElementsForMobile, setPopper, popper]);

    const togglePopover = useCallback(() => {
      setIsOpen((isOpen) => !isOpen);
    }, [setIsOpen]);

    // will resize when windows size change, will see if get performance
    useBreakpoint({
      value: setBreakpoint,
    });

    useEffect(() => {
      if (isOpen) {
        createPopover();
      } else {
        closePopover();
      }
    }, [createPopover, closePopover, isOpen]);

    useEffect(() => {
      if (breakpoint && popper) {
        popper.destroy();
        setPopper(null);
        createPopover();
      }
    }, [breakpoint]);

    const setClose = () => {
      setIsOpen(false);
    };

    useEventListener('keydown', (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setClose();
      }
    });

    useOnClickOutside(popoverReference, setClose);

    useEffect(() => {
      return () => {
        if (popper) {
          popper.destroy();
          setPopper(null);
        }
      };
    }, [popper]);

    return createElement(
      TagName,
      {
        className: mergeClassNames(classes, className),
        ref: (element: HTMLElement) => {
          popoverReference.current = element;
          if (forwardRef) {
            forwardRef.current = element;
          }
        },
        ...rest,
      },
      <>
        {!popoverAdvancedLabel() ? (
          <button
            type="button"
            className={toggleClasses}
            onClick={togglePopover}
            aria-expanded={isOpen}
            data-testid={
              action ? `button-popover-${action}` : 'button-popover-toggle'
            }
          >
            {popoverToggleLabelHidden() && ( // TODO: i18n
              <span className="vl-u-visually-hidden">
                {t(i18nKeys.a11y.breadcrumb.toggleArrow)}
              </span>
            )}
            {!popoverToggleLabelHidden() &&
            !popoverAdvancedLabel() &&
            popoverToggleLabel
              ? basicLabel.text
              : ''}
            {popoverToggleIcon() && (
              <SwIcon
                icon={popoverToggleIcon().name}
                modRotatedHalf={popoverToggleIcon().rotation}
                className={popoverToggleIcon().classes}
              />
            )}
          </button>
        ) : (
          // TODO: @Gilles a11y
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <span className={toggleClasses} onClick={togglePopover}>
            {advancedLabel}
          </span>
        )}

        <div
          className={popoverContentClasses(isOpen)}
          ref={popoverContentReference}
        >
          {typeof children === 'function'
            ? children({ close: setClose })
            : cloneElement(children, { onClick: setClose })}
          <button
            className="vl-popover__close-btn"
            tabIndex={0}
            type="button"
            onClick={(event) => {
              event.preventDefault();
              setClose();
            }}
          >
            <SwIcon icon={Icon.CLOSE} />
            <span className="vl-u-visually-hidden">
              {t(i18nKeys.general.close)}
            </span>
          </button>
        </div>
      </>,
    );
  },
);
