import React, {
  AllHTMLAttributes,
  createElement,
  FunctionComponent,
  ReactNode,
} from 'react';
import { Column, SwColumn } from '../core/SwColumn';
import { SwIcon } from '../icon/SwIcon';
import { mergeClassNames, Omit } from '../utilities';

type DescriptionDataItemProps<T = HTMLDivElement> = Omit<
  AllHTMLAttributes<T>,
  'label'
> & {
  tagName?: string | FunctionComponent<any>;
  icon?: string;
  label?: string | ReactNode;
  subdata?: string;
  inverted?: boolean;
  modBordered?: boolean;
  modIconTop?: boolean;
  column?: Column;
};

type Classes = {
  icon?: string;
  label?: string;
  subdata?: string;
  modBordered?: boolean;
  modIconTop?: boolean;
};

const getClasses = ({ icon, modIconTop, modBordered }: Classes) => {
  return [
    icon ? 'vl-description-data__icon-wrapper' : '',
    modIconTop ? 'vl-description-data__icon-wrapper--top' : '',
    modBordered ? 'vl-description-data--bordered' : '',
  ].join(' ');
};

export const SwDescriptionDataItem = <T extends HTMLElement>({
  tagName,
  label,
  icon,
  inverted,
  subdata,
  modBordered,
  children,
  className,
  column = { width: '6', widthS: '12' },
  modIconTop,
  ...rest
}: DescriptionDataItemProps<T>) => {
  const TagName = tagName || SwColumn;

  const classes = getClasses({ modBordered, icon, modIconTop });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest, ...column },
    <>
      {icon && <SwIcon icon={icon} className="vl-description-data__icon" />}
      {label && inverted && (
        <span
          className="vl-description-data__data"
          style={{ wordWrap: 'break-word' }}
        >
          {label}
        </span>
      )}
      {subdata && (
        <span className="vl-description-data__subdata">{subdata}</span>
      )}
      <span
        className="vl-description-data__label"
        style={{ marginBottom: '5px' }}
      >
        {children}
      </span>
      {label && !inverted && (
        <span
          className="vl-description-data__data"
          style={{ wordWrap: 'break-word' }}
        >
          {label}
        </span>
      )}
    </>,
  );
};
