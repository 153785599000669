import faker from 'faker';
import { DateTime } from 'luxon';
import {
  CustomerVacanciesResponse,
  CustomerVacancy,
  CustomerVacancyDeletedResponse,
  CustomerVacancyEndDateResponse,
  CustomerVacancyEndDateResponseData,
  CustomerVacancyResponse,
  GetCustomerVacancyEndDateParams,
  Vacancy,
  VacancyActionType,
} from '../model';
import { MAX_VACANCY_DAYS } from './region';

const getEndDateBetween = (start: string, length?: number) =>
  DateTime.fromISO(
    faker.date
      .between(
        DateTime.fromISO(start).toJSDate(),
        DateTime.fromISO(start)
          .plus({ days: length || faker.random.number({ min: 1, max: 15 }) })
          .startOf('day')
          .toJSDate(),
      )
      .toISOString(),
  )
    .startOf('day')
    .toISO();

const getDaysBetweenDates = (start: string, end: string) => {
  const startDate = DateTime.fromISO(start).startOf('day');
  const endDate = DateTime.fromISO(end).startOf('day');
  return Math.ceil(endDate.diff(startDate, 'days').days) + 1;
};

const getVacancyEndDate = (
  startDate = DateTime.fromJSDate(new Date()).toISODate(),
): CustomerVacancyEndDateResponseData => ({
  endDate: getEndDateBetween(
    DateTime.fromISO(startDate)
      .startOf('day')
      .toISODate(),
    30,
  ),
});

export const getCustomerVacancyEndDateResponse = (
  params?: GetCustomerVacancyEndDateParams,
): CustomerVacancyEndDateResponse => ({
  data: getVacancyEndDate(params?.startDate),
});

export const getVacancy = (customerVacancy?: CustomerVacancy): Vacancy => {
  const vacancy = {
    id: faker.random.uuid(),
    resourceId: faker.random.uuid(),
  };

  if (customerVacancy) {
    const { startDate, endDate } = customerVacancy;

    return {
      ...vacancy,
      workDays: getDaysBetweenDates(startDate, endDate),
      startDate: DateTime.fromISO(startDate)
        .startOf('day')
        .toISO(),
      endDate: DateTime.fromISO(endDate)
        .startOf('day')
        .toISO(),
    };
  }

  const startDate = DateTime.fromISO(faker.date.recent(20).toISOString())
    .startOf('day')
    .toISO();

  const endDate = DateTime.fromISO(getEndDateBetween(startDate))
    .startOf('day')
    .toISO();

  return {
    ...vacancy,
    workDays: getDaysBetweenDates(startDate, endDate),
    startDate,
    endDate,
  };
};

const deleteRessourceId = faker.random.uuid();

const now = new Date();
const vacancies = [...Array(faker.random.number(8))]
  .map((e) => getVacancy())
  .map((e) => {
    if (new Date(e.startDate) < new Date()) {
      return { ...e, resourceId: deleteRessourceId };
    }
    return e;
  });

const remainingVacancyDaysForCurrentYear = vacancies
  .filter((e) => new Date(e.startDate).getFullYear() === now.getFullYear())
  .reduce((acc, e) => acc - e.workDays, MAX_VACANCY_DAYS);

const remainingVacancyDaysForNextYear = vacancies
  .filter((e) => new Date(e.startDate).getFullYear() > now.getFullYear())
  .reduce((acc, e) => acc - e.workDays, MAX_VACANCY_DAYS);

export const getCustomerVacanciesResponse = (): CustomerVacanciesResponse => ({
  actions: {
    [deleteRessourceId]: {
      [VacancyActionType.CAN_DELETE]: false,
    },
  },
  data: {
    remainingVacancyDaysForCurrentYear,
    remainingVacancyDaysForNextYear,
    vacancies: [
      ...vacancies,
      {
        ...getVacancy({
          startDate: '2017-12-22',
          endDate: '2018-01-06',
        }),
        resourceId: deleteRessourceId,
      },
    ].sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    }),
  },
});
export const createCustomerVacancyResponse = (
  customerVacancy: CustomerVacancy,
): CustomerVacancyResponse => ({
  data: {
    remainingVacancyDaysForCurrentYear,
    remainingVacancyDaysForNextYear,
    vacancy: getVacancy(customerVacancy),
  },
});
export const deleteCustomerVacancyResponse = (
  vacancyId: string,
): CustomerVacancyDeletedResponse => ({
  data: {
    remainingVacancyDaysForCurrentYear,
    remainingVacancyDaysForNextYear,
  },
});
