import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type PagerProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  tagNameList?: string;
  modAlign?: string;
  modAlignL?: string;
  modAlignM?: string;
  modAlignS?: string;
  modAlignXs?: string;
};

type Classes = {
  modAlign?: string;
  modAlignL?: string;
  modAlignM?: string;
  modAlignS?: string;
  modAlignXs?: string;
};

const getClasses = ({
  modAlign,
  modAlignL,
  modAlignM,
  modAlignS,
  modAlignXs,
}: Classes) => {
  const breakpoints = [
    { key: '', align: modAlign },
    { key: 'l', align: modAlignL },
    { key: 'm', align: modAlignM },
    { key: 's', align: modAlignS },
    { key: 'xs', align: modAlignXs },
  ];

  const breakpointsClass = breakpoints.map((breakpoint) => {
    if (
      breakpoint.align &&
      ['left', 'center', 'right'].includes(breakpoint.align)
    ) {
      return `vl-pager--align-${breakpoint.align}${
        breakpoint.key ? `--${breakpoint.key}` : ''
      }`;
    }
    return '';
  });

  return ['vl-pager', ...breakpointsClass].join(' ');
};

export const SwPager = <T extends HTMLElement>({
  tagName,
  tagNameList,
  className,
  children,
  modAlign,
  modAlignL,
  modAlignM,
  modAlignS,
  modAlignXs,
  ...rest
}: PagerProps<T>) => {
  const TagName = tagName || 'div';
  const TagNameList = tagNameList || 'ul';

  const classes = getClasses({
    modAlign,
    modAlignL,
    modAlignM,
    modAlignS,
    modAlignXs,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    createElement(TagNameList, { className: 'vl-pager__list' }, children),
  );
};
