import { RouteActive } from '@kaa/common/types';
import useTitle from '@kaa/common/utils/useTitle';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

type ChangeTitleRouteProps<T extends string> = {
  activeRoute: RouteActive<T> | null;
  titleRoutes: { [key: string]: (t: TFunction) => string };
};

export const ChangeTitleRoute = <T extends string>({
  activeRoute,
  titleRoutes,
}: ChangeTitleRouteProps<T>) => {
  const { t } = useTranslation();

  useTitle(
    activeRoute && titleRoutes[activeRoute.route.id]
      ? `${titleRoutes[activeRoute.route.id](t)} | ${t(
          i18nKeys.pageTitle.general,
        )}`
      : t(i18nKeys.pageTitle.general),
  );

  return null;
};
