import TagManager from 'react-gtm-module';

export enum EventCategory {
  MAIN_NAVIGATION = 'main navigation',
  BREADCRUMB = 'breadcrumb',
  FOOTER = 'footer',
  CTA = 'CTA',
  POP_UP_CLOSE = 'pop up close',
  MODAL_INTERACTION = 'modal interaction',
  OUTBOUND_TRAFFIC = 'outbound traffic',
  CONTACT = 'contact',
  CONTACT_FORM = 'contact form',
  REGISTER = 'subscription',
  DOWNLOAD = 'download',
  DASHBOARD = 'dashboard',
  INFO_BUBBLE = 'info bubble',
  ORDER_VOUCHERS_CTA = 'order vouchers CTA',
  ORDER_VOUCHERS_ONLINE = 'order request online',
  ORDER_VOUCHERS_OFFLINE = 'order request offline',
  REFUND_REQUEST_CTA = 'refund request CTA',
  EXCHANGE_REQUEST_CTA = 'exchange request CTA',
  REFUND_REQUEST = 'refund request',
  EXCHANGE = 'exchange request',
  PLAN_LEAVE = 'plan leave',
  CONFIRM_PRESTATION = 'confirm prestation',
  CONTEST_PRESTATION = 'contest prestation',
}

export enum EventAction {
  CLICK = 'click',
  BACK = 'click back',
  DELETE = 'delete',
  SUBMIT = 'submit',
  CREATION = 'creation',
  OPEN_MODAL = 'open modal',
  CLOSE_MODAL = 'close modal',
  CLOSE_MODAL_BACK_ORDER = 'close modal & back to order screen',
  GO_ORDER_DETAILS = 'go to Order details',
  GO_WORKS_MANAGEMENT = 'go to works management',
  GO_WALLET = 'go to wallet',
  NEXT = 'next',
  PREVIOUS = 'previous',
  GET_DOCUMENT = 'Get Document',
  LOG_OUT = 'Log out',
  SWITCH_USER = 'Switch user',
  ORDER_VOUCHERS = 'order vouchers',
  DOWNLOAD = 'download',
}

export enum EventLabel {
  BACK_TO_HOMEPAGE = 'back to homepage',
  ORDER_SCREEN = 'Order Screen',
  ORDER_EXCHANGE_MODAL = 'Order | Exchange modal',
  ORDER_REFUND_MODAL = 'Order | Refund modal',
  ORDER_ORDER_MODAL = 'Order | Order modal',
  ORDER_ORDER_MODAL_ORDER = 'Order modal | 1-order',
  ORDER_ORDER_MODAL_DETAILS = 'Order modal | 2-details',
  ORDER_ORDER_MODAL_PAYMENT = 'Order modal | 3-payment',
  ORDER_ORDER_MODAL_CONFIRMATION = 'Order modal | 4-confirmation',
  WALLET_SCREEN = 'Wallet Screen',
  WALLET_EXCHANGE_MODAL = 'Wallet | Exchange modal',
  WALLET_REFUND_MODAL = 'Wallet | Refund modal',
  WALLET_ORDER_MODAL = 'Wallet | Order modal',
  ELECTRONIC = 'electronic',
  PAPER = 'paper',
  EXCHANGE_CONFIRMATION = 'Exchange confirmation',
  REFUND_PAPER_LINK = 'Refund Paper link',
  NOT_ENOUGH_VOUCHER = 'not enough vouchers',
  SWITCH_FROM_PAPER = 'Switch from paper',
  SWITCH_FROM_PAPER_INFO = 'Info about switch from paper',
  WORKS_CONTEST_MODAL = 'Prestation contest modal',
  WORKS_DETAILS_MODAL = 'Prestation Details Modal',
  WORKS_FOLLOW_UP = 'Work Follow-up',
  REGISTER_COMPLETE = 'Register Complete',
  REGISTER_EID = 'Register with eID',
  REGISTER_MANUALLY = 'Register manually',
  CONDITIONS = 'Terms & Conditions',
  PRIVACY = 'Privacy',
  PROFILE_CHANGE_EMAIL_MODAL = 'Change Email Address Modal',
  PROFILE_CHANGE_PASSWORD_MODAL = 'Change Password Modal',
}

export enum EventPathname {
  ORDER_ORDER_MODAL_ORDER = '/order-modal/1-order',
  ORDER_ORDER_MODAL_DETAILS = '/order-modal/2-details',
  ORDER_ORDER_MODAL_PAYMENT = '/order-modal/3-payment',
  ORDER_ORDER_MODAL_CONFIRMATION = '/order-modal/4-confirmation',
  ONBOARDING_MODAL = '/onboarding-modal',
  REFUND_MODAL = '/refund-modal',
  EXCHANGE_MODAL = '/exchange-modal',
}

export const initAnalytics = (googleTagManagerId: string) => {
  TagManager.initialize({
    gtmId: googleTagManagerId,
  });
};

export const sentPageViewEvent = ({
  pathname,
  modDefaultPathname = true,
  status,
}: {
  pathname?: string;
  modDefaultPathname?: boolean;
  status?: 'logged in' | 'logged out';
} = {}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'VirtualPageview',
      virtualPageURL:
        (modDefaultPathname ? window.location.pathname : '') + pathname,
      status,
    },
  });
};

export const sendMainNavigationEventToGTM = (
  parent: string,
  child?: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.MAIN_NAVIGATION,
      eventAction: parent,
      eventLabel: child,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendBackToHPEventToGTM = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.MAIN_NAVIGATION,
      eventAction: EventAction.CLICK,
      eventLabel: EventLabel.BACK_TO_HOMEPAGE,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendCTAEventToGTM = (
  buttonValue: string,
  destinationUrl: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.CTA,
      eventAction: buttonValue,
      eventLabel: destinationUrl,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export enum DashboardActionEvent {
  ALERT = 'alert',
  CARD = 'card',
}

export const sendDashboardActionEventToGTM = (
  action: DashboardActionEvent,
  elementClicked: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.DASHBOARD,
      eventAction: action,
      eventLabel: elementClicked,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendConfirmServiceManagementEventToGTM = (
  serviceManagementType: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.CONFIRM_PRESTATION,
      eventAction: EventAction.CLICK,
      eventLabel: serviceManagementType,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export enum ContestServiceManagementActionEvent {
  OK = 'prestation happened',
  NOK = 'prestation did not happened',
}
export const sendContestServiceManagementEventToGTM = (
  action: ContestServiceManagementActionEvent,
  serviceManagementType: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.CONTEST_PRESTATION,
      eventAction: action,
      eventLabel: serviceManagementType,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export enum AccountCreationActionEvent {
  EID = 'eid',
  EMAIL_PASSWORD = 'email-password',
}

export const sendAccountCreationEventToGTM = (
  label: AccountCreationActionEvent,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.REGISTER,
      eventAction: EventAction.CREATION,
      eventLabel: label,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendAccountSubscirptionEventToGTM = (
  eventCategory: EventCategory,
  eventAction: string,
  eventLabel: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendModalInteractionToGTM = (
  modalName: string, // Name of the modal | Page/step
  actionTaken: string, // action taken (in English) including close
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.MODAL_INTERACTION,
      eventAction: modalName,
      eventLabel: actionTaken,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};

export const sendCustomInteractionToGTM = (
  eventCategory: EventCategory,
  eventAction: string,
  eventLabel: string,
  eventValue?: number,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue: eventValue || 0,
      eventNonInteraction: false,
    },
  });
};

export const sendDownloadEventToGTM = (
  clickedLabel: string,
  downloadLink: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GA Tracking',
      eventCategory: EventCategory.DOWNLOAD,
      eventAction: clickedLabel,
      eventLabel: downloadLink,
      eventValue: 0,
      eventNonInteraction: false,
    },
  });
};
