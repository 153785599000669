import { ServiceDelivery } from '@kaa/api/customers';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwAlert,
  SwBadge,
  SwButton,
  SwColumn,
  SwDescriptionData,
  SwDescriptionDataItem,
  SwInfoTileSpotlight,
  SwLink,
  useTooltip,
} from '@kaa/ui-flanders/components';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTIVITY_TYPE_ICON } from '../../PrestationsWorksManagementScreen.constants';
import { formatActivityDuration } from '../../PrestationsWorksManagementScreen.utils';

type PrestationsPendingWorkCardProps = {
  work: ServiceDelivery;
  contestAction: () => void;
  confirmAction: () => Promise<[Error | undefined, any | undefined]>;
  seeDetails: () => void;
};

export const PrestationsPendingWorkCard = ({
  work,
  confirmAction,
  contestAction,
  seeDetails,
}: PrestationsPendingWorkCardProps) => {
  const { t } = useTranslation();
  const { providerName } = work;

  const {
    automaticValidationRemainingDays,
    serviceDate,
    activityType,
    previousActivityType,
    durationInHours,
    previousDurationInHours,
    modificationDate,
  } = work;

  const refTooltipInfo = useTooltip({
    value: t(
      i18nKeys.worksManagement.awaitingConfirmation.alert
        .automaticValidationRemainingDays.toolTip,
    ),
    placement: 'auto',
  });

  const [{ loading }, confirm] = useAsyncCallback(confirmAction);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const transitions = { ease: 'easeOut', duration: 0.4 };
  const animateDiv = isConfirmed ? { scale: 0, opacity: 0 } : {};

  const { DateTime } = useLuxon();

  return (
    <motion.div
      className="vl-info-tile"
      style={{ display: 'flex', width: 'auto' }}
      transition={transitions}
      animate={animateDiv}
      onAnimationComplete={confirm}
    >
      <SwInfoTileSpotlight
        modBadge
        modEllipsis
        style={{
          border: 'none',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
        title={
          serviceDate &&
          DateTime.fromISO(serviceDate).toLocaleString({
            weekday: 'long',
            month: 'short',
            day: '2-digit',
            year: 'numeric',
          })
        }
        subTitle={
          <>
            <span className="vl-u-text--small vl-u-text--ellipse">
              {t(i18nKeys.worksManagement.awaitingConfirmation.by)}{' '}
              <strong>{providerName}</strong>
            </span>
          </>
        }
        icon={ACTIVITY_TYPE_ICON[activityType]}
        footer={
          <>
            <div className="vl-u-display-flex vl-u-flex-align-center">
              <SwColumn width="6">
                <SwButton
                  onClick={() => setIsConfirmed(true)}
                  modLoading={loading}
                  modNarrow
                  modBlock
                >
                  {t(i18nKeys.worksManagement.awaitingConfirmation.cta.confirm)}
                </SwButton>
              </SwColumn>
              <SwColumn width="6">
                <SwLink
                  tagName="button"
                  onClick={contestAction}
                  style={{ fontSize: '1.6rem' }}
                >
                  {t(i18nKeys.worksManagement.awaitingConfirmation.cta.contest)}
                </SwLink>
              </SwColumn>
            </div>
          </>
        }
      >
        <SwDescriptionData className="vl-u-bg-alt vl-u-spacer--small">
          <SwDescriptionDataItem
            label={
              <>
                {!!previousActivityType && (
                  <>
                    <span
                      style={{
                        textDecoration: 'line-through',
                        fontWeight: 100,
                        color: '#b3b8bd',
                        fontSize: '1.4rem',
                      }}
                    >
                      {t(
                        i18nKeys.worksManagement.activities[
                          previousActivityType
                        ],
                      )}
                    </span>
                  </>
                )}{' '}
                {t(i18nKeys.worksManagement.activities[activityType])}
              </>
            }
            tagName={SwColumn}
            column={{ width: '12' }}
            style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
              margin: 0,
              fontSize: '1.6rem',
              lineHeight: '1.8rem',
              overflow: 'hidden',
            }}
          >
            {t(i18nKeys.worksManagement.awaitingConfirmation.activity.label)}
          </SwDescriptionDataItem>
        </SwDescriptionData>

        <SwDescriptionData className="vl-u-bg-alt vl-u-spacer--small">
          <SwDescriptionDataItem
            label={
              <>
                {!!previousDurationInHours && (
                  <span
                    style={{
                      textDecoration: 'line-through',
                      fontWeight: 100,
                      color: '#b3b8bd',
                      fontSize: '1.4rem',
                    }}
                  >
                    {formatActivityDuration(previousDurationInHours)}
                  </span>
                )}{' '}
                {formatActivityDuration(durationInHours)}
              </>
            }
            tagName={SwColumn}
            column={{ width: '12' }}
            style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
              margin: 0,
              lineHeight: '1.8rem',
              fontSize: '1.6rem',
            }}
          >
            {t(i18nKeys.worksManagement.awaitingConfirmation.workTime)}
          </SwDescriptionDataItem>
        </SwDescriptionData>
        {!!modificationDate && (
          <SwAlert
            className="vl-u-spacer--none"
            icon={Icon.INFO_CIRCLE}
            modWarning
            modSmall
            title={t(
              i18nKeys.worksManagement.awaitingConfirmation.alert.toValidate
                .title,
            )}
            titleTagName="h3"
            style={{ textAlign: 'left' }}
          >
            {t(
              i18nKeys.worksManagement.awaitingConfirmation.alert.toValidate
                .body,
            )}
            <SwLink tagName="button" modBlock onClick={seeDetails}>
              {t(i18nKeys.worksManagement.cta.details)}
            </SwLink>
          </SwAlert>
        )}
        {!!automaticValidationRemainingDays && (
          <SwAlert
            className="vl-u-spacer--none"
            icon={Icon.INFO_CIRCLE}
            modWarning
            modSmall
            title={t(
              i18nKeys.worksManagement.awaitingConfirmation.alert.toConfirm
                .title,
            )}
            titleTagName="h3"
            style={{ textAlign: 'left' }}
          >
            {t(
              i18nKeys.worksManagement.awaitingConfirmation.alert.toConfirm
                .body,
              {
                count: automaticValidationRemainingDays,
              },
            )}{' '}
            <SwBadge
              modAction
              modXsmall
              icon={Icon.INFO}
              style={{ verticalAlign: 'middle' }}
              ref={refTooltipInfo}
            />
          </SwAlert>
        )}
      </SwInfoTileSpotlight>
    </motion.div>
  );
};
