/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type FieldValidationErrorCode =
  | 'INVALID_FORMAT'
  | 'VALUE_TOO_LONG'
  | 'VALUE_TOO_SHORT'
  | 'OUT_OF_ENUM'
  | 'MISSING_VALUE'
  | 'DATE_IN_FUTURE'
  | 'INVALID'
  | 'POSTAL_CODE_IN_WRONG_REGION'
  | 'START_DATE_GREATER_THAN_END_DATE'
  | 'VALUE_ALREADY_EXISTS'
  | 'DATA_LIMIT_EXCEEDED'
  | 'NISS_ALREADY_USED'
  | 'NISS_MISMATCH'
  | 'CONTACT_SUPPORT'
  | 'UNKNOWN_VALUE'
  | 'MINIMUM_AGE_NOT_REACHED';

export const FieldValidationErrorCode = {
  INVALID_FORMAT: 'INVALID_FORMAT' as FieldValidationErrorCode,
  VALUE_TOO_LONG: 'VALUE_TOO_LONG' as FieldValidationErrorCode,
  VALUE_TOO_SHORT: 'VALUE_TOO_SHORT' as FieldValidationErrorCode,
  OUT_OF_ENUM: 'OUT_OF_ENUM' as FieldValidationErrorCode,
  MISSING_VALUE: 'MISSING_VALUE' as FieldValidationErrorCode,
  DATE_IN_FUTURE: 'DATE_IN_FUTURE' as FieldValidationErrorCode,
  INVALID: 'INVALID' as FieldValidationErrorCode,
  POSTAL_CODE_IN_WRONG_REGION: 'POSTAL_CODE_IN_WRONG_REGION' as FieldValidationErrorCode,
  START_DATE_GREATER_THAN_END_DATE: 'START_DATE_GREATER_THAN_END_DATE' as FieldValidationErrorCode,
  VALUE_ALREADY_EXISTS: 'VALUE_ALREADY_EXISTS' as FieldValidationErrorCode,
  DATA_LIMIT_EXCEEDED: 'DATA_LIMIT_EXCEEDED' as FieldValidationErrorCode,
  NISS_ALREADY_USED: 'NISS_ALREADY_USED' as FieldValidationErrorCode,
  NISS_MISMATCH: 'NISS_MISMATCH' as FieldValidationErrorCode,
  CONTACT_SUPPORT: 'CONTACT_SUPPORT' as FieldValidationErrorCode,
  UNKNOWN_VALUE: 'UNKNOWN_VALUE' as FieldValidationErrorCode,
  MINIMUM_AGE_NOT_REACHED: 'MINIMUM_AGE_NOT_REACHED' as FieldValidationErrorCode,
};
