import { KlarnaAuthorizeData } from '@kaa/api/customers';
import { KlarnaAuthorizeModel } from './klarna.type';

/**
 * If you have issue with klarna model, this mapping may be wrong
 * @param authorizeData
 */
export const toKlarnaAuthorizeModel = (
  authorizeData: KlarnaAuthorizeData,
): KlarnaAuthorizeModel => {
  return {
    purchase_country: authorizeData.purchaseCountry,
    purchase_currency: authorizeData.purchaseCurrency,
    locale: authorizeData.locale,
    billing_address: {
      family_name: authorizeData.billingAddress.familyName,
      given_name: authorizeData.billingAddress.givenName,
      email: authorizeData.billingAddress.email,
      title: authorizeData.billingAddress.title,
      street_address: authorizeData.billingAddress.streetAddress,
      postal_code: authorizeData.billingAddress.postalCode,
      city: authorizeData.billingAddress.city,
      region: authorizeData.billingAddress.region as string,
      phone: authorizeData.billingAddress.phone,
      country: authorizeData.billingAddress.country,
    },
    customer: {
      date_of_birth: authorizeData.customer.dateOfBirth,
      national_identification_number:
        authorizeData.customer.nationalIdentificationNumber,
    },
  };
};
