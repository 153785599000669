import { AllHTMLAttributes, createElement } from 'react';

type FunctionalHeaderActionsProps<T = HTMLUListElement> = AllHTMLAttributes<
  T
> & {
  tagName?: string;
};

export const SwFunctionalHeaderActions = <T extends HTMLElement>({
  tagName,
  className,
  children,
  ...rest
}: FunctionalHeaderActionsProps<T>) => {
  const TagName = tagName || 'ul';

  return createElement(TagName, { className, ...rest }, children);
};
