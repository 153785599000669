import { ServiceDelivery } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwColumn, SwLink, SwTitle } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { getPath, Routes } from '../../../../routes';
import { PrestationsWorkHistoryTable } from './history-table/PrestationsWorkHistoryTable';

type PrestationsWorkHistorySectionProps = {
  works: ServiceDelivery[];
  seeDetails: (work: ServiceDelivery) => void;
};

export const PrestationsWorkHistorySection = ({
  works,
  seeDetails,
}: PrestationsWorkHistorySectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SwColumn width="12" widthS="12">
        <SwTitle tagName="h2" className="vl-u-spacer--xsmall">
          {t(i18nKeys.worksManagement.history.title)}
        </SwTitle>
      </SwColumn>
      <PrestationsWorkHistoryTable works={works} seeDetails={seeDetails} />

      {!!works.length && (
        <SwColumn width="12" widthS="12">
          <SwLink
            data-testid={dataTest.worksManagement.showHistory}
            to={getPath(Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY)}
            icon={Icon.ARROW_RIGHT}
            modIconAfter
            className="vl-u-spacer--medium"
          >
            {t(i18nKeys.worksManagement.cta.history)}
          </SwLink>
        </SwColumn>
      )}
    </>
  );
};
