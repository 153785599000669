import { AxiosInstance } from 'axios';
import { ApiMode } from '../common/constants';
import { getIdpApi, getIdpMock, IdpApi } from './idp';
import { getHTTPInstance } from './utilities';

interface ApiByMode {
  mock: () => IdpApi;
  api: (instance: AxiosInstance) => IdpApi;
}

const apiByMode: ApiByMode = {
  mock: () => getIdpMock(),
  api: (instance) => getIdpApi(instance),
};

export interface ApiParameters {
  headers?: { [key: string]: string | undefined };
  baseUrl?: string;
  mode?: ApiMode;
}

export const getApi = ({
  headers = {},
  baseUrl,
  mode = ApiMode.API,
}: ApiParameters): IdpApi => {
  const instance = getHTTPInstance(baseUrl, headers);
  return apiByMode[mode](instance);
};
