import { isIframe } from '@kaa/common/utils';
import React, { PropsWithChildren } from 'react';

type RemoveIfInIframeProps = {};

export const RemoveIfInIframe = ({
  children,
}: PropsWithChildren<RemoveIfInIframeProps>) => {
  return <>{!isIframe() ? children : null}</>;
};
