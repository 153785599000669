import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwColumn, SwGrid, SwTitle } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPath, Routes } from '../../../routes';
import {
  useCustomersState,
  useDispatchUpdateSelectedCustomer,
  useSelectedCustomerState,
} from '../../../utils';
import { BlockedCustomerContractsListCard } from './BlockedCustomerContractsListCard';

export const BlockedCustomerContractsList = () => {
  const { t } = useTranslation();
  const customers = useCustomersState();
  const selectedCustomer = useSelectedCustomerState();
  const dispatchUpdateSelectedCustomer = useDispatchUpdateSelectedCustomer();

  if (customers.length <= 1) {
    return null;
  }

  return (
    <>
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--medium">
        {t(i18nKeys.blocked.contractList.title)}
      </SwTitle>
      <SwGrid modStacked className="vl-u-flex-v-flex-start vl-u-spacer">
        {customers.map((customer) => (
          <SwColumn width="4" widthM="6" widthS="12" key={customer.id}>
            <BlockedCustomerContractsListCard
              customer={customer}
              current={selectedCustomer}
              action={(id) => {
                dispatchUpdateSelectedCustomer(id);
                window.location.href = getPath(Routes.DASHBOARD);
              }}
            />
          </SwColumn>
        ))}
      </SwGrid>
    </>
  );
};
