import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { throttle } from 'throttle-debounce';

const on = (obj: any, ...args: any[]) => obj.addEventListener(...args);

const off = (obj: any, ...args: any[]) => obj.removeEventListener(...args);

const defaultEvents = [
  'mousemove',
  'mousedown',
  'resize',
  'keydown',
  'touchstart',
  'wheel',
];
const oneMinute = 60e3;

type onCheck = (datetime: string) => void;

const isExpire = (date: string, ms: number, onCheck?: onCheck): boolean => {
  const datetime = DateTime.fromISO(date);

  if (!datetime.isValid) {
    return false;
  }

  const { milliseconds } = DateTime.local().diff(datetime, 'milliseconds');

  return milliseconds > ms;
};

export const useInactivity = ({
  ms = oneMinute,
  intervalMs = oneMinute,
  events = defaultEvents,
  onCheck,
}: {
  ms?: number;
  intervalMs?: number;
  events?: string[];
  onCheck?: onCheck;
}): [boolean, () => void] => {
  const [state, setState] = useState<boolean>(false);
  const lastActivity = useRef<string | null>(null);

  const reset = useCallback(() => {
    setState(false);
  }, [setState]);

  useEffect(() => {
    if (state) {
      return;
    }

    const intervalId = setInterval(() => {
      if (lastActivity.current) {
        let localState = false;

        const expired = isExpire(lastActivity.current, ms, onCheck);

        if (onCheck) {
          onCheck(lastActivity.current);
        }

        if (localState !== expired) {
          localState = expired;
          setState(expired);
        }
      }
    }, intervalMs);

    return () => {
      clearInterval(intervalId);
    };
  }, [state]);

  useEffect(() => {
    if (state) {
      return;
    }

    let mounted = true;
    let localState = false;
    const set = (newState: boolean) => {
      if (mounted && localState !== newState) {
        localState = newState;
        setState(newState);
      }
    };

    const onEvent = throttle(50, () => {
      if (localState) {
        set(false);
      }

      if (lastActivity.current) {
        set(isExpire(lastActivity.current, ms, onCheck));
      }
      lastActivity.current = new Date().toISOString();
    });

    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };

    events.forEach((event) => on(window, event, onEvent));
    on(document, 'visibilitychange', onVisibility);

    onEvent();

    return () => {
      mounted = false;
      events.forEach((event) => off(window, event, onEvent));
      off(document, 'visibilitychange', onVisibility);
      lastActivity.current = null;
    };
  }, [ms, events, lastActivity, state]);

  return [state, reset];
};
