import {
  ServiceContestationRequest,
  ServiceDelivery,
} from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { differenceDeep, useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwAlert,
  SwColumn,
  SwLink,
  SwModal,
  SwSlider,
  SwSliderSlide,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modals,
  SERVICES_VOUCHERS_ORDER_START_BUY,
} from '../../../../../constants';
import { useApi } from '../../../../../utils';
import {
  ContestServiceManagementActionEvent,
  EventAction,
  EventCategory,
  EventLabel,
  sendConfirmServiceManagementEventToGTM,
  sendContestServiceManagementEventToGTM,
  sendCustomInteractionToGTM,
  sendModalInteractionToGTM,
  sentPageViewEvent,
} from '../../../../../utils/google-analytics';
import { handleApiError } from '../../../../../utils/validation';
import { PrestationsPendingWorkCard } from './PrestationsPendingWorkCard';
import { PrestationsPendingWorkContestModal } from './PrestationsPendingWorkContestModal';

type PrestationsPendingWorksSectionProps = {
  seeDetails: (work: ServiceDelivery) => void;
  overrideWorks: (workId: number, service: ServiceDelivery) => void;
  works: ServiceDelivery[];
  customerId: number;
};

export const PrestationsPendingWorksSection = ({
  seeDetails,
  overrideWorks,
  works,
  customerId,
}: PrestationsPendingWorksSectionProps) => {
  const { t } = useTranslation();
  const { customers: customersApi } = useApi();
  const [selectedWork, setSelectedWork] = useState<ServiceDelivery | null>(
    null,
  );
  const [alert, setAlert] = useState<{
    icon: Icon;
    modSuccess?: boolean;
    modError?: boolean;
    title: string;
    content?: ReactNode;
  } | null>(null);

  const contest = (work: ServiceDelivery) => {
    setSelectedWork(work);
    toggleModalById(Modals.PRESTATIONS_CONTEST_MODAL_ID);
    sendCustomInteractionToGTM(
      EventCategory.CONTEST_PRESTATION,
      EventAction.CLICK,
      EventAction.OPEN_MODAL,
    );
    sentPageViewEvent({
      pathname: '/contest-modal',
    });
  };

  const [{ value: submitedConfirmForm }, confirm] = useAsyncCallback(
    async (work: ServiceDelivery) => {
      const [error, response] = await httpTo(
        customersApi.confirmService(customerId, work.id),
      );

      return {
        error,
        response,
        work,
      };
    },
    [customersApi],
  );

  useEffect(() => {
    if (submitedConfirmForm) {
      const { work, response, error } = submitedConfirmForm;

      if (!response || error) {
        setAlert({
          title: t(i18nKeys.errors.server.form.DEFAULT),
          icon: Icon.ALERT_CIRCLE,
          modError: true,
        });
        return;
      }

      sendConfirmServiceManagementEventToGTM(work.activityType);

      const updatedWork = response.data.data;

      setAlert({
        title: t(
          i18nKeys.worksManagement.awaitingConfirmation.alert.confirmed.title,
        ),
        icon: Icon.CHECK_CIRCLE,
        modSuccess: true,
        content: (
          <>
            {t(
              i18nKeys.worksManagement.awaitingConfirmation.alert.confirmed
                .body,
              {
                count: work.durationInHours,
              },
            )}
            <br />
            <SwLink to={SERVICES_VOUCHERS_ORDER_START_BUY}>
              {t(
                i18nKeys.worksManagement.awaitingConfirmation.alert.confirmed
                  .cta,
              )}
            </SwLink>
          </>
        ),
      });

      overrideWorks(work.id, {
        ...work,
        ...updatedWork,
      });
    }
  }, [submitedConfirmForm]);

  const [{ value: submitedContestForm }, submit] = useAsyncCallback(
    async (
      formikData: ServiceContestationRequest,
      formikActions: FormikActions<ServiceContestationRequest>,
    ) => {
      if (!selectedWork) {
        return undefined;
      }

      const [error, response] = await httpTo(
        customersApi.contestService(customerId, selectedWork.id, formikData),
      );

      return {
        error,
        response,
        formikData,
        formikActions,
        work: selectedWork,
      };
    },
    [customersApi, selectedWork],
  );

  useEffect(() => {
    if (submitedContestForm) {
      const {
        response,
        error,
        formikData,
        formikActions,
        work,
      } = submitedContestForm;

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response || error) {
        setAlert({
          title: t(i18nKeys.errors.server.form.DEFAULT),
          icon: Icon.ALERT_CIRCLE,
          modError: true,
        });
        return;
      }

      const updatedWork = response.data.data;

      const { neverHappened } = formikData;

      toggleModalById(Modals.PRESTATIONS_CONTEST_MODAL_ID);

      overrideWorks(work.id, {
        ...work,
        ...updatedWork,
      });

      setAlert({
        title: t(
          i18nKeys.worksManagement.awaitingConfirmation.alert
            .contestConfirmation.title,
        ),
        icon: Icon.CHECK_CIRCLE,
        modSuccess: true,
        content: t(
          i18nKeys.worksManagement.awaitingConfirmation.alert
            .contestConfirmation.body,
          {
            count: work.durationInHours,
          },
        ),
      });

      sendContestServiceManagementEventToGTM(
        neverHappened
          ? ContestServiceManagementActionEvent.NOK
          : ContestServiceManagementActionEvent.OK,
        JSON.stringify(Object.keys(differenceDeep(formikData, work))),
      );
      sentPageViewEvent();
    }
  }, [submitedContestForm]);

  if (!works.length) {
    return (
      <>
        <SwColumn width="12" widthS="12">
          <SwTitle tagName="h2" className="vl-u-spacer">
            {t(i18nKeys.worksManagement.awaitingConfirmation.title)}
          </SwTitle>
        </SwColumn>
        <SwColumn width="9" widthS="12">
          <SwAlert
            icon={Icon.INFO_CIRCLE}
            content={t(i18nKeys.worksManagement.pending.alert.noWorksContent)}
            title={t(i18nKeys.worksManagement.pending.alert.noWorksTitle)}
            titleTagName="h3"
          />
        </SwColumn>
      </>
    );
  }

  return (
    <SwColumn width="12" widthS="12">
      <SwTitle tagName="h2" className="vl-u-spacer">
        {t(i18nKeys.worksManagement.awaitingConfirmation.title)}
      </SwTitle>
      {works.length === 1 ? (
        <SwColumn width="4" widthM="6" widthS="12" className="vl-u-spacer">
          <PrestationsPendingWorkCard
            work={works[0]}
            contestAction={() => contest(works[0])}
            confirmAction={() => confirm(works[0])}
            seeDetails={() => seeDetails(works[0])}
          />
        </SwColumn>
      ) : (
        <SwSlider dots keyboardNavigation arrows equalHeight modGroupCells>
          {works.map((work) => (
            <SwSliderSlide width="3" widthM="6" widthS="9" key={work.id}>
              <PrestationsPendingWorkCard
                work={work}
                contestAction={() => contest(work)}
                confirmAction={() => confirm(work)}
                seeDetails={() => seeDetails(work)}
              />
            </SwSliderSlide>
          ))}
        </SwSlider>
      )}
      {alert && (
        <SwAlert
          icon={alert.icon}
          closable
          close={() => setAlert(null)}
          modSuccess={alert.modSuccess}
          modError={alert.modError}
          title={alert.title}
          titleTagName="h3"
        >
          {alert.content}
        </SwAlert>
      )}
      {selectedWork && (
        <SwModal
          id={Modals.PRESTATIONS_CONTEST_MODAL_ID}
          closable
          onClose={() => {
            sendModalInteractionToGTM(
              EventLabel.WORKS_CONTEST_MODAL,
              EventAction.CLOSE_MODAL,
            );
            sentPageViewEvent();
          }}
          component={PrestationsPendingWorkContestModal}
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          work={selectedWork}
          submit={submit}
        />
      )}
    </SwColumn>
  );
};
