import React, { AllHTMLAttributes, createElement, Ref } from 'react';
import { useVisuallyHidden } from '../util/hooks/useVisuallyHidden';
import { mergeClassNames } from '../utilities';

type RadioProps<T = HTMLLabelElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  id: string;
  modSingle?: boolean;
  modBlock?: boolean;
  modDisabled?: boolean;
  modError?: boolean;
  modSuccess?: boolean;
  modRequired?: boolean;
};

type Classes = {
  modSingle?: boolean;
  modBlock?: boolean;
  modDisabled?: boolean;
  modError?: boolean;
  modSuccess?: boolean;
};
const getClasses = ({
  modBlock,
  modDisabled,
  modError,
  modSingle,
  modSuccess,
}: Classes) => {
  return [
    'vl-radio',
    modBlock ? 'vl-radio--block' : '',
    modSingle ? 'vl-radio--single' : '',
    modDisabled ? 'vl-radio--disabled' : '',
    modError ? 'vl-radio--error' : '',
    modSuccess ? 'vl-radio--success' : '',
  ].join(' ');
};

export const SwRadio = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      id,
      className,
      modBlock,
      modDisabled,
      modError,
      modSingle,
      modSuccess,
      modRequired = false,
      children,
      ...rest
    }: RadioProps<HTMLInputElement>,
    ref: Ref<T>,
  ) => {
    const TagName = tagName || 'label';
    const visuallyHiddenRef = useVisuallyHidden<HTMLSpanElement>();

    const classes = getClasses({
      modError,
      modSingle,
      modDisabled,
      modBlock,
      modSuccess,
    });
    const labelClasses = 'vl-radio__label';

    return createElement(
      TagName,
      {
        className: mergeClassNames(classes, className),
        htmlFor: id,
        ref,
      },
      <input
        type="radio"
        className="vl-radio__toggle"
        disabled={modDisabled}
        id={id}
        {...rest}
      />,
      <div className={labelClasses}>
        {modSingle ? <span ref={visuallyHiddenRef}>{children}</span> : children}
      </div>,
    );
  },
);
