import { ApiMode } from '@kaa/api/common';
import {
  CustomerActionType,
  MessageCode,
  MessageType,
} from '@kaa/api/customers';
import { AuthAction, useAuthDispatch, useAuthState } from '@kaa/auth/common';
import {
  ActionType,
  useActionDispatch,
  useActionState,
} from '@kaa/common/context';
import { AvailableLanguages } from '@kaa/common/enums';
import { useLuxon, useOnClickOutside } from '@kaa/common/utils';
import { SwButton, SwCheckbox } from '@kaa/ui-flanders/components';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../common/config';
import { AuthContext } from '../../types';

const DeveloperConsoleVisible = () => {
  const [open, setOpen] = useState(false);
  const { customers, selectedCustomerId, messages } = useAuthState<
    AuthContext
  >();
  const actions = useActionState();
  const dispatchAction = useActionDispatch();
  const dispathAuth = useAuthDispatch();
  const { i18n } = useTranslation();
  const { Settings } = useLuxon();
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpen(false));
  const selectedCustmer = customers?.find(
    (customer) => customer.id.toString() === selectedCustomerId,
  );

  if (!selectedCustmer) {
    return null;
  }

  const selectedCustomerActions = actions[selectedCustmer?.resourceId];
  const selectedCustomerMessages =
    messages?.[selectedCustmer?.resourceId] || [];

  if (!open) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '20rem',
          zIndex: 9999,
          background: 'pink',
        }}
      >
        <button
          type="button"
          onClick={() => setOpen((open) => !open)}
        >{`>`}</button>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        top: '20rem',
        zIndex: 9999,
        background: 'pink',
        width: '500px',
      }}
    >
      <button
        type="button"
        onClick={() => setOpen((open) => !open)}
      >{`<`}</button>
      <h2>Customer actions</h2>
      <SwButton
        onClick={() =>
          dispatchAction({
            type: ActionType.ADD,
            payload: {
              [selectedCustmer.resourceId]: Object.values(
                CustomerActionType,
              ).reduce((acc, it) => ({ ...acc, [it]: true }), {}),
            },
          })
        }
      >
        Select All
      </SwButton>
      <SwButton
        onClick={() =>
          dispatchAction({
            type: ActionType.ADD,
            payload: {
              [selectedCustmer.resourceId]: Object.values(
                CustomerActionType,
              ).reduce((acc, it) => ({ ...acc, [it]: false }), {}),
            },
          })
        }
      >
        Unselect All
      </SwButton>
      {Object.values(CustomerActionType).map((action) => (
        <SwCheckbox
          key={action}
          name={action}
          checked={selectedCustomerActions?.[action]}
          onClick={() =>
            dispatchAction({
              type: ActionType.ADD,
              payload: {
                [selectedCustmer.resourceId]: {
                  ...selectedCustomerActions,
                  [action]: !selectedCustomerActions?.[action],
                },
              },
            })
          }
        >
          {action}
        </SwCheckbox>
      ))}
      <h2>Customer languages</h2>
      {Object.values(AvailableLanguages).map((language) => (
        <SwCheckbox
          key={language}
          name={language}
          checked={i18n.language === language.toLocaleLowerCase()}
          onClick={() => {
            i18n.changeLanguage(language.toLocaleLowerCase());
            Settings.defaultLocale = language.toLocaleLowerCase();
          }}
        >
          {language}
        </SwCheckbox>
      ))}
      <h2>Customer Messages</h2>
      {Object.values(MessageCode).map((message) => (
        <SwCheckbox
          key={message}
          name={message}
          checked={selectedCustomerMessages.some(
            ({ code }) => code === message,
          )}
          onClick={() => {
            dispathAuth({
              type: AuthAction.UPDATE,
              payload: {
                messages: {
                  ...messages,
                  [selectedCustmer.resourceId]: [
                    { code: message, type: MessageType.WARNING },
                  ],
                },
              },
            });
          }}
        >
          {message}
        </SwCheckbox>
      ))}
    </div>
  );
};

export const DeveloperConsole = () => {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  const config = getConfig();

  if (config?.app.mode !== ApiMode.MOCK) {
    return null;
  }

  return <DeveloperConsoleVisible />;
};
