import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwColumn,
  SwGrid,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { sendCTAEventToGTM } from '../../../../utils/google-analytics';

type ProfileConfirmChangeRegionModalProps = {
  onSubmit: () => void;
};

export const ProfileConfirmChangeRegionModal = ({
  onSubmit,
  onClose,
}: SwModalRenderProps<ProfileConfirmChangeRegionModalProps>) => {
  const { t } = useTranslation();
  return (
    <SwGrid modStacked className="vl-u-flex-v-flex-start">
      <SwColumn width="12">
        <SwTitle tagName="h2" className="vl-u-spacer--medium">
          {t(i18nKeys.profile.changeRegionModal.title)}
        </SwTitle>

        <SwActionGroup modCollapseS>
          <SwButton
            data-testid={
              dataTest.profile.changeRegionModal.confirmChangeEmailModal
            }
            type="button"
            onClick={() => {
              onSubmit();
              sendCTAEventToGTM('Confirm Change Region Modal', 'submit');
            }}
            modNarrow
          >
            {t(i18nKeys.profile.changeRegionModal.cta.confirm)}
          </SwButton>
          <SwButton
            data-testid={
              dataTest.profile.changeRegionModal.cancelChangeEmailModal
            }
            type="button"
            onClick={() => {
              if (onClose) {
                onClose();
              }
              sendCTAEventToGTM('Cancel Change Region Modal', 'cancel');
            }}
            modSecondary
            modNarrow
          >
            {t(i18nKeys.profile.changeRegionModal.cta.cancel)}
          </SwButton>
        </SwActionGroup>
      </SwColumn>

      <SwColumn width="10" widthS="12">
        <p className="vl-u-text--small">
          {t(i18nKeys.profile.changeRegionModal.disclaimer)}
        </p>
      </SwColumn>
    </SwGrid>
  );
};
