import React, { AllHTMLAttributes, Ref } from 'react';

type SelectProps = AllHTMLAttributes<HTMLDivElement> & {
  tagName?: string;
  checked?: boolean;
};

export const SwShowOnCheckedContent = React.forwardRef(
  (
    { tagName, className, checked, children, ...rest }: SelectProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    if (!checked) {
      return null;
    }
    return (
      <div {...rest} ref={ref}>
        {children}
      </div>
    );
  },
);
