const MAX_PAGES = 3;

export const selectPage: <T>(
  data: T[],
  selectedPage: number,
  pageSize: number,
) => T[] = (data, selectedPage, pageSize = 20) => {
  return selectedPage > 0
    ? data.slice((selectedPage - 1) * pageSize, selectedPage * pageSize)
    : [];
};

export const getFromIndex = (selectedPage: number, pageSize: number): number =>
  pageSize * (selectedPage - 1) + 1;

export const getToIndex = (
  selectedPage: number,
  pageSize: number,
  itemCount: number,
) => Math.min(pageSize * selectedPage, itemCount);

export const getTotalPages = (itemCount: number, pageSize: number) =>
  Math.ceil(itemCount / pageSize);

export const getPages = (selectedPage: number, totalPages: number) => {
  let startPage: number;
  let endPage: number;

  const maxPagesBeforeSelectedPage = Math.floor(MAX_PAGES / 2);
  const maxPagesAfterSelectedPage = Math.ceil(MAX_PAGES / 2) - 1;

  if (totalPages <= MAX_PAGES) {
    startPage = 1;
    endPage = totalPages;
  } else if (selectedPage <= maxPagesBeforeSelectedPage) {
    startPage = 1;
    endPage = MAX_PAGES;
  } else if (selectedPage + maxPagesAfterSelectedPage >= totalPages) {
    startPage = totalPages - MAX_PAGES + 1;
    endPage = totalPages;
  } else {
    startPage = selectedPage - maxPagesBeforeSelectedPage;
    endPage = selectedPage + maxPagesAfterSelectedPage;
  }

  return Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i,
  );
};
