import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type FormMessageErrorProps<T = HTMLParagraphElement> = AllHTMLAttributes<T> & {
  tagName?: string;
};

export const SwFormMessageError = <T extends HTMLElement>({
  tagName,
  children,
  className,
  ...rest
}: FormMessageErrorProps<T>) => {
  const TagName = tagName || 'p';

  const classes = 'vl-form__error';

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
