import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type FunctionalHeaderActionProps<T = HTMLLIElement> = AllHTMLAttributes<T> & {
  tagName?: string;
};

const getClasses = () => {
  return ['vl-functional-header__action'].join(' ');
};

export const SwFunctionalHeaderAction = <T extends HTMLElement>({
  tagName,
  className,
  children,
  ...rest
}: FunctionalHeaderActionProps<T>) => {
  const TagName = tagName || 'li';

  const classes = getClasses();

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
