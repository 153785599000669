import React, { AllHTMLAttributes, Ref } from 'react';
import { mergeClassNames } from '../utilities';

type FormProps = AllHTMLAttributes<HTMLFormElement>;

const getClasses = () => {
  return ['vl-form'].join(' ');
};

export const SwForm = React.forwardRef(
  ({ className, children, ...rest }: FormProps, ref: Ref<HTMLFormElement>) => {
    const classes = getClasses();

    return (
      <form {...rest} ref={ref} className={mergeClassNames(classes, className)}>
        {children}
      </form>
    );
  },
);
