import React, { ReactElement } from 'react';
import { useActionsHandler } from './useActionsHandler';

type RenderReactElement = (props: {
  isAllowed: boolean;
  [key: string]: unknown;
}) => JSX.Element | null;

type ActionsHandlerProps<T> = {
  children: ReactElement | ReactElement[] | RenderReactElement;
  resourceId: string | undefined;
  actions: T[] | undefined;
  [key: string]: unknown;
};

export const ActionsHandler = <T extends string>({
  children,
  resourceId,
  actions,
  ...rest
}: ActionsHandlerProps<T>): ReactElement | null => {
  const isAllowed = useActionsHandler(resourceId, actions);

  if (typeof children === 'function') {
    return children({ ...rest, isAllowed });
  }

  return isAllowed ? (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { ...rest }),
      )}
    </>
  ) : null;
};
