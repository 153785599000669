import React, { AllHTMLAttributes, createElement, ReactNode } from 'react';
import { SwBadge } from '../badge/SwBadge';
import { mergeClassNames } from '../utilities';
import { SwTitle } from '../title/SwTitle';

type Connections = { src: string; alt: string };

type SwInfoTileDefaultProps<T = HTMLAnchorElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  connections?: Connections[];
  modAlt?: boolean;
  href?: string;
  children?: ReactNode;
  badge?: ReactNode;
  badgeColor?: string;
  modSubTitleBig?: boolean;
};

type Classes = {
  modAlt?: boolean;
  href?: string;
  connections?: Connections[];
};

const getClasses = ({ modAlt, href, connections }: Classes) => {
  return [
    'vl-info-tile',
    modAlt ? 'vl-info-tile--alt' : '',
    href ? 'vl-info-tile--clickable' : '',
    connections ? 'vl-info-tile--badge-bottom' : '',
  ].join(' ');
};

export const SwInfoTileDefault = <T extends HTMLElement>({
  tagName,
  title,
  subtitle,
  connections,
  modAlt,
  className,
  children,
  modSubTitleBig,
  href,
  badge,
  badgeColor = 'inherit',
  ...rest
}: SwInfoTileDefaultProps<T>) => {
  const TagName = tagName || 'a';

  const classes = getClasses({
    href,
    connections,
    modAlt,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest, href },
    <>
      <div className="vl-info-tile__header" role="presentation">
        {badge && (
          <div
            className="vl-info-tile__badge__wrapper"
            style={{ color: badgeColor }}
          >
            {badge}
          </div>
        )}
        <div className="vl-info-tile__header__wrapper">
          <SwTitle
            tagName="h2" // tagName could come from props if needed
            tagStyle="h3"
            className="vl-info-tile__header__title"
          >
            {title}
          </SwTitle>
          {!modSubTitleBig && (
            <p className="vl-info-tile__header__subtitle">{subtitle}</p>
          )}
          {modSubTitleBig && (
            <p className="vl-info-tile__tile__content">{subtitle}</p>
          )}
        </div>
      </div>
      {(connections || children) && (
        <div className="vl-info-tile__content">
          {children && (
            <div className="vl-info-tile__content__wrapper">{children}</div>
          )}
          {connections && (
            <div className="vl-info-tile__badges">
              {connections.map((connection) => (
                <SwBadge
                  key={connection.alt}
                  modSmall
                  className="vl-info-tile__badges__badge"
                >
                  <img src={connection.src} alt={connection.alt} />
                </SwBadge>
              ))}
            </div>
          )}
        </div>
      )}
    </>,
  );
};
