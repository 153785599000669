import { useEffect, useState } from 'react';
import { useActionByResource, useActionState } from '../context';

const isActionsAllowed = <T extends string>(
  requiredActions: T[],
  actions: { [key: string]: boolean },
) => {
  return requiredActions.some((action) => {
    const isAction = actions[action];
    return isAction === undefined || isAction;
  });
};

export const useActionsHandler = <T extends string>(
  resourceId?: string,
  actions?: T[],
): boolean => {
  const actionsByResource = useActionByResource(resourceId);
  const [isAllowed, setAllowed] = useState(
    actionsByResource && actions?.length
      ? isActionsAllowed(actions, actionsByResource)
      : true,
  );

  useEffect(() => {
    if (!actionsByResource || !actions?.length) {
      setAllowed(true);
      return;
    }

    setAllowed(isActionsAllowed(actions, actionsByResource));
  }, [resourceId, actions]);

  return isAllowed;
};

export const useMultipleActionsHandler = <T extends string>(objActions: {
  [key: string]: { resourceId: string; actions: T[] };
}): { [key: string]: boolean } => {
  const actionsContext = useActionState();

  return Object.entries(objActions).reduce(
    (acc, [key, { resourceId, actions }]) => {
      const actionsByResource = actionsContext[resourceId];

      if (!actionsByResource || !actions) {
        return { ...acc, [key]: true };
      }

      return { ...acc, [key]: isActionsAllowed(actions, actionsByResource) };
    },
    {},
  );
};
