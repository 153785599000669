export const MINFIN_URL = 'https://eservices.minfin.fgov.be/myminfin-web/';

export type CurrentDocument = {
  fiscalYear: number;
  documentId: string;
};

export enum FiscalAttestationCodes {
  TOTAL_AMOUNT_PRIMARY = '3364',
  TOTAL_AMOUNT_SECONDARY = '4364',
  TOTAL_VOUCHERS_PRIMARY = '3366',
  TOTAL_VOUCHERS_SECONDARY = '4366',
}
