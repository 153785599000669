import { City, CustomerActionType, Street } from '@kaa/api/customers';
import { ActionsHandler } from '@kaa/common/handlers';
import { Mask } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AddressFieldNames,
  SwActionGroup,
  SwButton,
  SwCheckboxField,
  SwFormAddress,
  SwFormGrid,
  SwInputCalendarField,
  SwInputTextField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { sendCTAEventToGTM } from '../../../../utils/google-analytics';
import { ProfileScreenFieldNames } from '../ProfileScreen.constants';
import { getRedirectUrlToInitiateEid } from '../ProfileScreen.utils';

export const mappingCustomerActionsToAddressActions = {
  [AddressFieldNames.POSTCODE]: CustomerActionType.CAN_EDIT_ADDRESS_POSTCODE,
  [AddressFieldNames.STREET]: CustomerActionType.CAN_EDIT_ADDRESS_STREET,
  [AddressFieldNames.HOUSE_NUMBER]:
    CustomerActionType.CAN_EDIT_ADDRESS_HOUSE_NUMBER,
};

type ProfileFormCustomerMainInfoProps = {
  resourceId?: string;
  availableCities?: City[];
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
};

export const ProfileCustomerMainInfoForm = ({
  resourceId,
  availableCities = [],
  getStreets,
}: ProfileFormCustomerMainInfoProps) => {
  const { t } = useTranslation();

  return (
    <SwFormGrid modStacked>
      <ActionsHandler
        resourceId={resourceId}
        actions={[CustomerActionType.CAN_EDIT_FIRSTNAME]}
      >
        {({ isAllowed }) => (
          <Field
            name={ProfileScreenFieldNames.FIRSTNAME}
            label={t(i18nKeys.general.label.firstName)}
            component={SwInputTextField}
            modDisabled={!isAllowed}
            modRequired
          />
        )}
      </ActionsHandler>

      <ActionsHandler
        resourceId={resourceId}
        actions={[CustomerActionType.CAN_EDIT_LASTNAME]}
      >
        {({ isAllowed }) => (
          <Field
            name={ProfileScreenFieldNames.LASTNAME}
            label={t(i18nKeys.general.label.name)}
            component={SwInputTextField}
            modDisabled={!isAllowed}
            modRequired
          />
        )}
      </ActionsHandler>

      <ActionsHandler
        resourceId={resourceId}
        actions={[CustomerActionType.CAN_EDIT_BIRTHDATE]}
      >
        {({ isAllowed }) => (
          <Field
            name={ProfileScreenFieldNames.BIRTHDATE}
            label={t(i18nKeys.profile.dateOfBirth)}
            component={SwInputCalendarField}
            modDisabled={!isAllowed}
            modRequired
            visualFormat="d/m/Y"
            arial-label={`${t(i18nKeys.profile.dateOfBirth)} (${t(
              i18nKeys.general.label.dateFormat,
            )})`}
            maxDate={DateTime.local().toJSDate()}
            format={(date: string) =>
              date ? [DateTime.fromISO(date).toJSDate()] : undefined
            }
            parse={(dates: Date[]) =>
              dates && dates.length
                ? DateTime.fromJSDate(dates[0])
                    .startOf('day')
                    .toISO()
                : undefined
            }
          />
        )}
      </ActionsHandler>

      <ActionsHandler
        resourceId={resourceId}
        actions={[CustomerActionType.CAN_EDIT_NISS]}
      >
        {({ isAllowed }) => (
          <Field
            name={ProfileScreenFieldNames.NISS}
            label={t(i18nKeys.profile.nationalNumber)}
            component={SwInputTextField}
            modDisabled={!isAllowed}
            modRequired
            mask={Mask.nationalNumber}
          />
        )}
      </ActionsHandler>

      <SwFormAddress
        fieldNamePrefix={ProfileScreenFieldNames.MAIN_ADDRESS}
        availableCities={availableCities}
        resourceId={resourceId}
        actions={mappingCustomerActionsToAddressActions}
        getStreets={getStreets}
        modRequired={[
          AddressFieldNames.POSTCODE,
          AddressFieldNames.CITY,
          AddressFieldNames.STREET,
          AddressFieldNames.HOUSE_NUMBER,
        ]}
      />

      <Field
        name={
          ProfileScreenFieldNames.USE_MAIN_ADDRESS_FOR_PAPER_VOUCHER_DELIVERY
        }
        onClick={() => {
          sendCTAEventToGTM('This is my delivery address', 'toggle');
        }}
        label={t(i18nKeys.general.label.itIsMyDeliveryAddress)}
        component={SwCheckboxField}
        column={{ pushS: '0', widthS: '12', push: '3', width: '9' }}
      />

      <SwActionGroup style={{ width: '100%' }}>
        <SwButton
          type="button"
          modSecondary
          modLarge
          data-testid={dataTest.profile.adjustInfoEReader}
          onClick={() => {
            window.location.href = getRedirectUrlToInitiateEid();
            sendCTAEventToGTM('Initiate Eid', 'redirect URL to initiate EID');
          }}
        >
          {t(i18nKeys.profile.infoFromEidReader)}
        </SwButton>
      </SwActionGroup>
    </SwFormGrid>
  );
};
