import { ORDERS } from './MyOrderScreen.constants';
import { Order, OrderWithQuantityAndTotal } from './MyOrderScreen.types';

export const countTotal = (arr: number[] = []): number => {
  return arr.reduce((acc: number, num: number) => acc + num, 0);
};

export const getCustomerVoucherSetQuantityAndTotal = <T extends Order>(
  order: T,
): OrderWithQuantityAndTotal<T> => {
  return {
    ...order,
    quantity: countTotal(order.voucherSets.map(({ quantity }) => quantity)),
    total: countTotal(
      order.voucherSets.map(({ faceValue, quantity }) => faceValue * quantity),
    ),
  };
};

export const orderByDate = <T>(
  arr: T[],
  target: keyof T,
  type = ORDERS.DESC,
): T[] =>
  arr.sort((a, b) => {
    if (!a[target] || !b[target]) {
      return 0;
    }

    const aProp = (a[target] as unknown) as number | string | Date;
    const bProp = (b[target] as unknown) as number | string | Date;

    switch (type) {
      case ORDERS.ASC:
        if (new Date(aProp) > new Date(bProp)) {
          return 1;
        }
        if (new Date(aProp) < new Date(bProp)) {
          return -1;
        }
        return 0;
      default:
        if (new Date(aProp) > new Date(bProp)) {
          return -1;
        }
        if (new Date(aProp) < new Date(bProp)) {
          return 1;
        }
        return 0;
    }
  });
