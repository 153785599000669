import { ApiError, CodeError } from '@kaa/api/customers';
import { Icon, SwAlert, SwColumn } from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KlarnInternalWidgetError } from './klarna.type';

type AlertProps = {
  close?: () => void;
  closable?: boolean;
  text: string;
  modSuccess?: boolean;
  modError?: boolean;
  icon: string;
};

function isApiError(
  error: CodeError | KlarnInternalWidgetError | ApiError,
): error is ApiError {
  return (error as ApiError).code !== undefined;
}

export const getAlertTranslationKeyFromCode = (
  code: CodeError | KlarnInternalWidgetError,
  t: TFunction,
) => {
  switch (code) {
    case CodeError.KLARNA_ORDER_FAILED:
      return t('newOrder.stepPayment.directPayment.orderDeclined');
    case CodeError.QUOTA_EXCEEDED:
      return t('newOrder.stepPayment.directPayment.noQuota');
    case CodeError.VOUCHER_ORDER_FAILED:
      return t('newOrder.stepPayment.directPayment.paidWithNoVoucher', {
        phoneNumber: '+3245555555', // TODO @gilles get from CONFIG
      });

    case CodeError.KLARNA_SESSION_FAILED:
    case KlarnInternalWidgetError.InvalidClientTokenError:
    case KlarnInternalWidgetError.PaymentLoadError:
    case KlarnInternalWidgetError.AuthorizeError:
    default:
      return t('newOrder.stepPayment.directPayment.general.error');
  }
};

const Alert = ({ text, ...rest }: AlertProps) => {
  return (
    <SwColumn>
      <SwAlert {...rest} modSmall>
        {text}
      </SwAlert>
    </SwColumn>
  );
};

export const KlarnaErrorMessage = ({
  error,
}: {
  error: CodeError | KlarnInternalWidgetError | ApiError | null | undefined;
}) => {
  const { t } = useTranslation();

  if (!error) {
    return null;
  }

  if (isApiError(error)) {
    return (
      <Alert
        icon={Icon.ALERT_CIRCLE}
        modError
        text={t(getAlertTranslationKeyFromCode(error.code, t))}
      />
    );
  }

  return (
    <Alert
      icon={Icon.ALERT_CIRCLE}
      modError
      text={t(getAlertTranslationKeyFromCode(error, t))}
    />
  );
};
