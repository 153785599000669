import {
  ActivityType,
  ServiceContestationRequest,
  ServiceDelivery,
} from '@kaa/api/customers';
import { useLuxon } from '@kaa/common/utils';
import { maxLength, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwCheckboxField,
  SwColumn,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwGrid,
  SwLink,
  SwModalRenderProps,
  SwSelectField,
  SwTextAreaField,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../../constants';
import { dataTest } from '../../../../../datatest/keys';
import {
  EventAction,
  EventLabel,
  sendModalInteractionToGTM,
  sentPageViewEvent,
} from '../../../../../utils/google-analytics';
import { createValidatorWithServerErrorHandled } from '../../../../../utils/validation';
import { PrestationsPendingWorkContestFieldNames } from '../../PrestationsWorksManagementScreen.utils';

const validate = createValidatorWithServerErrorHandled<
  ServiceContestationRequest
>({
  activityType: [required],
  durationInHours: [required],
  neverHappened: [required],
  comment: [required, maxLength(255)],
});

type PrestationsPendingWorkContestModalProps = {
  work: ServiceDelivery;
  submit: (
    data: ServiceContestationRequest,
    formikActions: FormikActions<ServiceContestationRequest>,
  ) => Promise<[Error | undefined, any | undefined]>;
};

export const PrestationsPendingWorkContestModal = ({
  work,
  submit,
  setConfirmCloseModal,
}: SwModalRenderProps<PrestationsPendingWorkContestModalProps>) => {
  const { t } = useTranslation();
  const activityOptions = Object.values(ActivityType).map((aggrement) => {
    return {
      value: aggrement,
      text: t(i18nKeys.worksManagement.activities[aggrement]),
    };
  });

  const durationOptions = Array.from(Array(9).keys()).map((hour) => {
    return {
      value: hour + 1,
      text: `0${hour + 1}h00`,
    };
  });

  const { DateTime } = useLuxon();

  return (
    <SwGrid>
      <SwColumn width="12">
        <SwTitle tagName="h2">
          {t(i18nKeys.worksManagement.awaitingConfirmation.contestModal.title)}
        </SwTitle>
        <p className="vl-u-spacer--xsmall">
          {t(
            i18nKeys.worksManagement.awaitingConfirmation.contestModal
              .description,
            {
              workDay: work.serviceDate
                ? DateTime.fromISO(work.serviceDate).toLocaleString({
                    weekday: 'long',
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  })
                : '',
              companyName: work.providerName,
            },
          )}
        </p>
      </SwColumn>
      <Formik
        onSubmit={submit}
        initialValues={{
          activityType: work.activityType,
          durationInHours: work.durationInHours,
          neverHappened: false,
          comment: '',
        }}
        validate={validate}
      >
        {({ handleSubmit, isSubmitting, dirty, values }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwColumn width="12">
              <SwForm onSubmit={handleSubmit}>
                <SwFormGrid modStacked>
                  <SwFormColumn width="6">
                    <Field
                      name={
                        PrestationsPendingWorkContestFieldNames.ACTIVITY_TYPE
                      }
                      label={t(
                        i18nKeys.worksManagement.awaitingConfirmation.activity
                          .label,
                      )}
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      options={activityOptions}
                      component={SwSelectField}
                      modDisabled={values.neverHappened}
                    />
                  </SwFormColumn>
                  <SwFormColumn width="6">
                    <Field
                      name={
                        PrestationsPendingWorkContestFieldNames.DURATION_IN_HOURS
                      }
                      label={t(
                        i18nKeys.worksManagement.awaitingConfirmation.workTime,
                      )}
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      options={durationOptions}
                      component={SwSelectField}
                      modDisabled={values.neverHappened}
                    />
                  </SwFormColumn>
                  <Field
                    name={
                      PrestationsPendingWorkContestFieldNames.NEVER_HAPPENED
                    }
                    label={t(
                      i18nKeys.worksManagement.awaitingConfirmation.contestModal
                        .neverHappened,
                    )}
                    column={{ width: '12' }}
                    component={SwCheckboxField}
                  />
                  <Field
                    rows="4"
                    name={PrestationsPendingWorkContestFieldNames.COMMENT}
                    label={t(
                      i18nKeys.worksManagement.awaitingConfirmation.contestModal
                        .comment,
                    )}
                    /* BETS-7237 - Disabled until next release - check ticket comments */
                    /* description={t(
                                          i18nKeys.worksManagement.awaitingConfirmation.contestModal
                                            .commentDescription,
                                        )} */
                    column={{ width: '12' }}
                    style={{ resize: 'none' }}
                    component={SwTextAreaField}
                    maxLength="255"
                    modRequired
                  />
                </SwFormGrid>
                <SwActionGroup>
                  <SwButton
                    modDisabled={!dirty}
                    modLoading={isSubmitting}
                    type="submit"
                    data-testid={dataTest.worksManagement}
                  >
                    {t(i18nKeys.general.cta.validate)}
                  </SwButton>
                  <SwLink
                    tagName="button"
                    type="button"
                    onClick={() => {
                      toggleModalById(Modals.PRESTATIONS_CONTEST_MODAL_ID);
                      sendModalInteractionToGTM(
                        EventLabel.WORKS_CONTEST_MODAL,
                        EventAction.CLOSE_MODAL,
                      );
                      sentPageViewEvent();
                    }}
                  >
                    {t(i18nKeys.general.cta.cancel)}
                  </SwLink>
                </SwActionGroup>
              </SwForm>
            </SwColumn>
          );
        }}
      </Formik>
    </SwGrid>
  );
};
