import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

export type GridProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  modStacked?: boolean;
  modStart?: boolean;
  modCenter?: boolean;
  modEnd?: boolean;
  modSpaceBetween?: boolean;
  modSpaceAround?: boolean;
  modNoGutter?: boolean;
  modVStretched?: boolean;
  modVTop?: boolean;
  modVCenter?: boolean;
  modVBottom?: boolean;
  modWide?: boolean;
};

export type Classes = {
  modStacked?: boolean;
  modStart?: boolean;
  modCenter?: boolean;
  modEnd?: boolean;
  modSpaceBetween?: boolean;
  modSpaceAround?: boolean;
  modNoGutter?: boolean;
  modVStretched?: boolean;
  modVTop?: boolean;
  modVCenter?: boolean;
  modVBottom?: boolean;
  modWide?: boolean;
};

export const getClasses = ({
  modWide,
  modCenter,
  modEnd,
  modNoGutter,
  modSpaceAround,
  modSpaceBetween,
  modStacked,
  modStart,
  modVBottom,
  modVCenter,
  modVStretched,
  modVTop,
}: Classes) => {
  return [
    'vl-grid',
    modStacked ? 'vl-grid--is-stacked' : '',
    modNoGutter ? 'vl-grid--no-gutter' : '',
    modStart ? 'vl-grid--align-start' : '',
    modCenter ? 'vl-grid--align-center' : '',
    modEnd ? 'vl-grid--align-end' : '',
    modSpaceBetween ? 'vl-grid--align-space-between' : '',
    modSpaceAround ? 'vl-grid--align-space-around' : '',
    modVStretched ? 'vl-grid--v-stretched' : '',
    modVCenter ? 'vl-grid--v-center' : '',
    modVTop ? 'vl-grid--v-top' : '',
    modVBottom ? 'vl-grid--v-bottom' : '',
    modWide ? 'vl-grid--wide' : '',
  ].join(' ');
};

export const SwGrid = <T extends HTMLElement>({
  tagName,
  className,
  modCenter,
  modEnd,
  modNoGutter,
  modSpaceAround,
  modSpaceBetween,
  modStacked,
  modStart,
  modVBottom,
  modVCenter,
  modVStretched,
  modVTop,
  modWide,
  children,
  ...rest
}: GridProps<T>) => {
  const TagName = tagName || 'div';
  const classes = getClasses({
    modCenter,
    modEnd,
    modNoGutter,
    modSpaceAround,
    modSpaceBetween,
    modStacked,
    modStart,
    modVBottom,
    modVCenter,
    modVStretched,
    modVTop,
    modWide,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
