import {
  ServiceDelivery,
  ServicesManagementResponseDataData,
  SupportType,
} from '@kaa/api/customers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwModal,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContainerLoadingPageHeader,
  Onboarding,
  PageHeader,
} from '../../../components';
import { Modals } from '../../../constants';
import { Routes } from '../../../routes';
import { useApi, useSelectedCustomerState } from '../../../utils';
import { sendModalInteractionToGTM } from '../../../utils/google-analytics';
import { PrestationsPendingWorksSection } from './components/pending-section/PrestationsPendingWorksSection';
import { PrestationsWorkDetailsModal } from './components/PrestationsWorkDetailsModal';
import { PrestationsWorkFollowUpSection } from './components/PrestationsWorkFollowUpSection';
import { PrestationsWorkHistorySection } from './components/PrestationsWorkHistorySection';

export const WorksManagementScreen = () => {
  const { t } = useTranslation();

  const customer = useSelectedCustomerState();
  const { customers } = useApi();
  const [
    servicesManagement,
    setServicesManagement,
  ] = useState<ServicesManagementResponseDataData | null>(null);
  const [selectedWork, setSelectedWork] = useState<ServiceDelivery | null>(
    null,
  );

  const [
    { value: servicesManagementData, loading, error },
    getServicesManagementData,
  ] = useAsyncCallback(
    async () => {
      const [
        customersServicesMangement,
        customerPreferences,
      ] = await Promise.all([
        customers
          .getServicesForDashboard(customer.id)
          .then(({ data: { data } }) => data),
        customers
          .getCustomerPreferences(customer.id)
          .then(({ data: { data } }) => data),
      ]);

      return { customersServicesMangement, customerPreferences };
    },
    [customers],
    { loading: true },
  );

  useEffect(() => {
    getServicesManagementData();
  }, [getServicesManagementData]);

  useEffect(() => {
    if (servicesManagementData) {
      setServicesManagement(servicesManagementData.customersServicesMangement);
    }
  }, [servicesManagementData]);

  const seeDetails = (work: ServiceDelivery) => {
    setSelectedWork(work);
    toggleModalById(Modals.PRESTATIONS_DETAILS_MODAL_ID);
    sendModalInteractionToGTM('Prestation Details Modal', 'Open Modal');
  };

  const overrideWorks = useCallback(
    (workId: number, service: ServiceDelivery) => {
      setServicesManagement((servicesManagement) => {
        if (!servicesManagement) {
          return servicesManagement;
        }
        const { pending, recent } = servicesManagement;

        return {
          pending: pending.filter(({ id }) => workId !== id),
          recent: [service, ...recent],
        };
      });
    },
    [setServicesManagement],
  );

  if (!servicesManagement && loading) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.navigation.worksManagement)}
        introduction={t(i18nKeys.worksManagement.introduction)}
        modFullwidth
      />
    );
  }

  if (error || !servicesManagement || !servicesManagementData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getServicesManagementData} />
      </SwContainer>
    );
  }

  const { recent, pending } = servicesManagement;
  const { customerPreferences } = servicesManagementData;

  return (
    <>
      <SwContainer>
        <Onboarding activeRoute={Routes.PRESTATIONS_WORKS_MANAGEMENT} />
        <SwGrid modStacked className="vl-u-flex-v-flex-start">
          <PageHeader
            title={t(i18nKeys.navigation.worksManagement)}
            introduction={t(i18nKeys.worksManagement.introduction)}
          />

          <PrestationsPendingWorksSection
            works={pending}
            customerId={customer.id}
            seeDetails={seeDetails}
            overrideWorks={overrideWorks}
          />

          <PrestationsWorkHistorySection
            works={recent}
            seeDetails={seeDetails}
          />

          {customerPreferences.supportTypePreference === SupportType.PAPER && (
            <PrestationsWorkFollowUpSection />
          )}
        </SwGrid>
      </SwContainer>
      {selectedWork && (
        <SwModal
          id={Modals.PRESTATIONS_DETAILS_MODAL_ID}
          closable
          component={PrestationsWorkDetailsModal}
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          work={selectedWork}
          customerId={customer.id}
          onClose={() => {
            setSelectedWork(null);
            sendModalInteractionToGTM(
              'Prestation Details Modal',
              'Close Modal',
            );
          }}
        />
      )}
    </>
  );
};
