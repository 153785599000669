export const getAppConfigResponse = () => {
  return {
    data: {
      mobileMinimumVersion: '1.0.0',
      mobileCurrentVersion: '1.0.1',
      isKlarnaEnabledOnAndroid: false,
      isKlarnaEnabledOnIos: false,
    },
  };
};
