import { AuthAction, useAuthDispatch, useAuthState } from '@kaa/auth/common';
import { navigate } from '@reach/router';
import { useEffect } from 'react';
import { AuthContext } from '../../types';

export const UserSignedOutListener = ({
  redirectUrl,
}: {
  redirectUrl: string;
}) => {
  const dispatch = useAuthDispatch();
  const { registerOnUserSignedOut, unregisterOnUserSignedOut } = useAuthState<
    AuthContext
  >();

  const resetAuthContext = () => {
    // We use the same logic inside the useApi hook
    sessionStorage.clear();
    dispatch({
      type: AuthAction.RESET,
    });
    navigate(redirectUrl);
    window.location.reload();
  };

  useEffect(() => {
    registerOnUserSignedOut(resetAuthContext);
    return () => unregisterOnUserSignedOut(resetAuthContext);
  }, [registerOnUserSignedOut, unregisterOnUserSignedOut, resetAuthContext]);

  return null;
};
