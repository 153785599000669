import { unsetError } from '@kaa/common/validation';
import React, { AllHTMLAttributes, useEffect } from 'react';
import { SwFormMessageError } from '../../../form-message/SwFormMessageError';
import { useFormError } from '../../../util/hooks/useFormError';
import { FieldPropsClean } from '../../../utilities';

type SwErrorMessageForFieldProps = AllHTMLAttributes<HTMLInputElement> &
  FieldPropsClean & {
    testId?: string;
    autoUnsetServerError: boolean;
  };

export const SwErrorMessageForField = ({
  field,
  form,
  autoUnsetServerError,
  testId = '',
}: SwErrorMessageForFieldProps) => {
  const errorMessage = useFormError({ field, form });
  const { values, setFieldValue, dirty } = form;
  useEffect(() => {
    if (autoUnsetServerError && dirty && values.errors) {
      if (values.errors.server) {
        unsetError({
          values,
          name: `server.${field.name}`, // this key is based on getServerErrorPathInFormValuesError
          setFieldValue,
        });
        return;
      }

      unsetError({
        values,
        name: field.name, // this key is based on getServerErrorPathInFormValuesError
        setFieldValue,
      });
    }
  }, [field.value]);

  return errorMessage ? (
    <SwFormMessageError data-testid={testId}>{errorMessage}</SwFormMessageError>
  ) : null;
};
