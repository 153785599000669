import {
  CustomerVoucherSet,
  SupportType,
  VouchersOrderItemCreate,
} from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwAlert,
  SwCheckboxField,
  SwColumn,
  SwFormGrid,
  SwInputTextField,
  SwLink,
  SwRadioField,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { getAvailableVouchers } from '../../../ServicesVouchers.utils';
import { supportTypeTranslate } from '../OrderModal.constants';
import { getOrderItems } from '../OrderModal.utils';
import { OrderTable } from './OrderTable';

type OrderStepOrderProps = {
  buyableVouchers: CustomerVoucherSet[];
  minQuantity: number;
  maxQuantity: number;
  orderItems: VouchersOrderItemCreate[];
  supportType: string;
  saveSupportType: boolean;
};

export const OrderStepOrder = ({
  buyableVouchers,
  orderItems,
  supportType,
  minQuantity,
  maxQuantity,
  saveSupportType = false,
}: OrderStepOrderProps) => {
  const { t } = useTranslation();
  const [viewContractType, setviewContractType] = useState(saveSupportType);
  const availableVouchers = getAvailableVouchers(buyableVouchers);

  useEffect(() => {
    if (!viewContractType && supportType === SupportType.PAPER) {
      setviewContractType(true);
    }
  }, [supportType, viewContractType, setviewContractType]);

  return (
    <SwFormGrid modStacked>
      <SwColumn>
        <SwAlert
          icon={Icon.INFO_CIRCLE}
          title={t(i18nKeys.newOrder.stepOrder.alert, {
            availableVouchers,
            count: availableVouchers,
          })}
          titleTagName="h3"
        />
      </SwColumn>
      {viewContractType && (
        <SwColumn role="radiogroup" aria-labelledby="supportTypeSet">
          <fieldset>
            <legend
              id="supportTypeSet"
              className="vl-form__label vl-u-spacer--xsmall"
            >
              {t(i18nKeys.general.label.voucherType)}
            </legend>
            <Field
              role="radio"
              name="supportType"
              id={SupportType.ELECTRONIC}
              arial-label={t(i18nKeys.general.supportType.ELECTRONIC)}
              label={t(i18nKeys.general.supportType.ELECTRONIC)}
              component={SwRadioField}
              inputOnChange={(
                event: ChangeEvent<HTMLInputElement>,
                field: FieldProps['field'],
                form: FieldProps['form'],
              ) => {
                const quantity = form.touched.quantity
                  ? Math.max(form.values.quantity, minQuantity)
                  : minQuantity;
                form.setFieldValue('quantity', quantity);
                form.setFieldValue(
                  'orderItems',
                  getOrderItems({
                    buyableVouchers,
                    quantity,
                  }),
                );
              }}
            />
            <Field
              role="radio"
              name="supportType"
              id={SupportType.PAPER}
              arial-label={t(i18nKeys.general.supportType.PAPER)}
              label={t(i18nKeys.general.supportType.PAPER)}
              component={SwRadioField}
              inputOnChange={(
                event: ChangeEvent<HTMLInputElement>,
                field: FieldProps['field'],
                form: FieldProps['form'],
              ) => {
                const quantity = form.touched.quantity
                  ? Math.max(form.values.quantity, 10)
                  : 10;
                form.setFieldValue('quantity', quantity);
                form.setFieldValue(
                  'orderItems',
                  getOrderItems({
                    buyableVouchers,
                    quantity,
                  }),
                );
              }}
              modSpacer
            />
          </fieldset>
          <Field
            name="saveSupportType"
            component={SwCheckboxField}
            label={t(i18nKeys.newOrder.stepOrder.label.adaptVoucherType)}
            data-testid={
              dataTest.wallet.order.order.adaptContractTypeInSettings
            }
          />
        </SwColumn>
      )}
      {!viewContractType && (
        <>
          <SwColumn>{t(supportTypeTranslate[supportType])}</SwColumn>
          <SwColumn>
            <SwLink
              data-testid={
                dataTest.wallet.order.order.selectAnotherContractType
              }
              tagName="button"
              type="button"
              onClick={() => setviewContractType(true)}
            >
              {t(i18nKeys.newOrder.stepOrder.cta.selectOtherType)}
            </SwLink>
          </SwColumn>
        </>
      )}

      <Field
        name="quantity"
        type="number"
        label={t(i18nKeys.newOrder.stepOrder.form.voucherAmount.label)}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        component={SwInputTextField}
        modRequired
        min={minQuantity}
        max={maxQuantity}
        parse={(value: string) => (value ? parseInt(value, 10) : value)}
        inputOnChange={(
          event: ChangeEvent<HTMLInputElement>,
          field: FieldProps['field'],
          form: FieldProps['form'],
        ) => {
          form.setFieldValue(
            'orderItems',
            getOrderItems({
              buyableVouchers,
              quantity: parseInt(event.target.value, 10),
            }),
          );
        }}
      />

      <OrderTable orderItems={orderItems} />
    </SwFormGrid>
  );
};
