import { ConfigLoadFailedFallback } from '@kaa/core-app/common/components';
import React from 'react';

export const ConfigLoadFailedFallbackWithTranslation: React.FC = () => {
  return (
    <ConfigLoadFailedFallback
      mainTitle="Oeps! We hebben een probleem"
      introduction="We werken hard om de site te herstellen..."
      actionButton="Probeer het nog eens"
    />
  );
};
