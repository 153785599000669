import faker from 'faker';
import { CustomerLanguages } from '../model';

export const getLanguage = (): CustomerLanguages =>
  faker.helpers.randomize([
    CustomerLanguages.NL,
    CustomerLanguages.DE,
    CustomerLanguages.FR,
  ]);

export const getLanguages = () =>
  Array.from(
    new Set(
      [
        ...Array(
          faker.random.number({
            min: 1,
            max: Object.values(CustomerLanguages).length,
          }),
        ),
      ].map(getLanguage),
    ),
  );
