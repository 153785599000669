import { VouchersRefundCreate, VouchersRefundCreatedResponse } from '../model';

export const confirmVouchersRefundResponse = (
  refundInfo: VouchersRefundCreate,
): VouchersRefundCreatedResponse => ({
  data: refundInfo.requests.reduce(
    ({ quantity, amount }, request) => ({
      quantity: quantity + request.quantity,
      amount: amount + request.quantity * request.faceValue,
    }),
    { quantity: 0, amount: 0 },
  ),
});
