import { CustomerVacancy, Vacancy } from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AlertType,
  Icon,
  SwAlert,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLoadingPageHeader, PageHeader } from '../../../components';
import { useApi, useSelectedCustomerState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  sendCustomInteractionToGTM,
} from '../../../utils/google-analytics';
import { handleApiError } from '../../../utils/validation';
import { PlanALeaveForm } from './components/PrestationsPlanALeaveForm';
import { PlanALeaveTable } from './components/PrestationsPlanALeaveTable';
import {
  getExcludeDates,
  getFirstValidDateFromVacancies,
} from './PrestationsPlanALeaveScreen.utils';

type Variables = {
  maxVacancyDays: number;
  servicesAutomaticValidationInDays: number;
  remainingVacancyDaysForCurrentYear: number;
  remainingVacancyDaysForNextYear: number;
};

export const PlanALeaveScreen = () => {
  const { t } = useTranslation();

  const dispatchAction = useActionDispatch();
  const customer = useSelectedCustomerState();
  const { customers } = useApi();

  const [maxEndDate, setMaxEndDate] = useState<Date | undefined>();
  const [vacancies, setVacancies] = useState<Vacancy[] | undefined>();
  const [variables, setVariables] = useState<Variables>({
    maxVacancyDays: 0,
    servicesAutomaticValidationInDays: 0,
    remainingVacancyDaysForCurrentYear: 0,
    remainingVacancyDaysForNextYear: 0,
  });

  const [
    { value: planALeaveData, loading, error },
    getPlanALeaveData,
  ] = useAsyncCallback(
    async () => {
      const [globalRegionContext, customerVacancies] = await Promise.all([
        customers.getGlobalRegionContext().then(({ data: { data } }) => data),
        customers
          .getCustomerVacancies(customer.id)
          .then(({ data: { data, actions } }) => {
            if (actions) {
              dispatchAction({ type: ActionType.ADD, payload: actions });
            }
            return data;
          }),
      ]);

      const {
        maxVacancyDays,
        servicesAutomaticValidationInDays,
      } = globalRegionContext;

      return {
        ...customerVacancies,
        maxVacancyDays,
        servicesAutomaticValidationInDays,
      };
    },
    [customers],
    { loading: true },
  );

  const [{ value: vacancyCreatedResponse }, submitVacancy] = useAsyncCallback(
    async (
      customerVacancy: CustomerVacancy,
      formikActions: FormikActions<CustomerVacancy>,
    ) => {
      const [error, response] = await httpTo(
        customers.createCustomerVacancy(customer.id, customerVacancy),
      );

      sendCustomInteractionToGTM(
        EventCategory.PLAN_LEAVE,
        EventAction.CLICK,
        EventAction.SUBMIT,
      );

      return {
        error,
        response,
        formikActions,
      };
    },
    [customers],
  );

  const [{ value: deletedVacancyResponse }, deleteVacancy] = useAsyncCallback(
    async (vacancy: Vacancy) => {
      const [error, response] = await httpTo(
        customers.deleteCustomerVacancy(customer.id, vacancy.id),
      );

      return {
        error,
        response,
        vacancy,
      };
    },
    [customers],
  );

  useEffect(() => {
    if (vacancyCreatedResponse) {
      const { error, response, formikActions } = vacancyCreatedResponse;
      const { setSubmitting, resetForm, setStatus } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      setSubmitting(false);

      if (!response) {
        setStatus({
          msg: t(i18nKeys.planALeave.alert.error),
          type: AlertType.ERROR,
        });
        return;
      }

      setMaxEndDate(undefined);

      const { servicesAutomaticValidationInDays } = variables;

      const {
        data: {
          data: {
            remainingVacancyDaysForCurrentYear,
            remainingVacancyDaysForNextYear,
            vacancy,
          },
        },
      } = response;

      resetForm({
        startDate: getFirstValidDateFromVacancies(
          DateTime.local().startOf('day'),
          [vacancy, ...(vacancies || [])],
        ).toISODate(),
        endDate: '',
      });

      setVariables((variables) => ({
        ...variables,
        remainingVacancyDaysForCurrentYear,
        remainingVacancyDaysForNextYear,
      }));

      setVacancies((vacancies = []) => [vacancy, ...vacancies]);

      setStatus({
        title: t(i18nKeys.planALeave.alert.title),
        msg: t(i18nKeys.planALeave.alert.body, {
          days: servicesAutomaticValidationInDays,
        }),
        type: AlertType.SUCCESS,
        modSmall: false,
      });
    }
  }, [vacancyCreatedResponse]);

  useEffect(() => {
    if (deletedVacancyResponse) {
      const {
        error,
        response,
        vacancy: { id },
      } = deletedVacancyResponse;

      if (error) {
        return;
      }

      setVacancies((vacancies = []) =>
        vacancies.filter((vacancy) => vacancy.id !== id),
      );

      if (response) {
        const {
          data: {
            data: {
              remainingVacancyDaysForCurrentYear,
              remainingVacancyDaysForNextYear,
            },
          },
        } = response;

        setVariables({
          ...variables,
          remainingVacancyDaysForCurrentYear,
          remainingVacancyDaysForNextYear,
        });
      }
    }
  }, [deletedVacancyResponse]);

  useEffect(() => {
    getPlanALeaveData();
  }, [getPlanALeaveData]);

  useEffect(() => {
    if (planALeaveData) {
      const { vacancies, ...rest } = planALeaveData;
      setVariables(rest);
      setVacancies(vacancies);
    }
  }, [planALeaveData]);

  if (loading && !planALeaveData) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.navigation.planALeave)}
        introduction={t(i18nKeys.planALeave.introduction)}
        modFullwidth
      />
    );
  }

  if (error || !planALeaveData || !vacancies) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getPlanALeaveData} />
      </SwContainer>
    );
  }

  const {
    remainingVacancyDaysForNextYear,
    remainingVacancyDaysForCurrentYear,
    maxVacancyDays,
  } = variables;

  const excludeDates = getExcludeDates({
    vacancies,
    currentYear: !remainingVacancyDaysForCurrentYear,
    nextYear: !remainingVacancyDaysForNextYear,
  });

  return (
    <SwContainer>
      <SwGrid modStacked className="vl-u-flex-v-flex-start">
        <PageHeader
          title={t(i18nKeys.navigation.planALeave)}
          introduction={t(i18nKeys.planALeave.introduction)}
        />
        <SwColumn width="12">
          <PlanALeaveForm
            initialValues={{
              startDate: getFirstValidDateFromVacancies(
                DateTime.local().startOf('day'),
                vacancies,
              ).toISODate(),
              endDate: '',
            }}
            maxEndDate={maxEndDate}
            setMaxEndDate={setMaxEndDate}
            excludeDates={excludeDates}
            submitVacancy={submitVacancy}
          />
        </SwColumn>
        <SwColumn width="12">
          <SwTitle tagName="h2">{t(i18nKeys.planALeave.myAbsences)}</SwTitle>
          <SwAlert
            icon={Icon.INFO_CIRCLE}
            title={t(i18nKeys.planALeave.infoTile.title)}
            titleTagName="h3"
          >
            {remainingVacancyDaysForCurrentYear === 0
              ? t(i18nKeys.planALeave.infoTile.body.noDayAvailable)
              : t(i18nKeys.planALeave.infoTile.body.currentYear, {
                  remaining: remainingVacancyDaysForCurrentYear,
                  total: maxVacancyDays,
                })}
            {!!remainingVacancyDaysForNextYear &&
              remainingVacancyDaysForNextYear < maxVacancyDays && (
                <>
                  <br />
                  {t(i18nKeys.planALeave.infoTile.body.noDayAvailableNextYear)}
                </>
              )}
          </SwAlert>
        </SwColumn>

        <SwColumn width="12">
          <PlanALeaveTable
            deleteVacancy={deleteVacancy}
            vacancies={vacancies}
          />
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
