import { Bundle, ContractType } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { TFunction } from 'i18next';
import groupBy from 'lodash.groupby';
import { DateTime, Duration } from 'luxon';

export type ConcatedBundle = {
  expirationDate: string;
  remainingQuantity: number;
  contractType: ContractType;
};

export const groupByExpirationDate = <T>(
  array: Array<
    T & {
      expirationDate: string;
    }
  >,
) => groupBy(array, ({ expirationDate }) => expirationDate);

export const groupByContractType = <T>(
  array: Array<
    T & {
      contractType: ContractType;
      expirationDate: string;
    }
  >,
) => groupBy(array, ({ contractType }) => contractType);

export const getBundlesConcatByRemainingQuantity = (bundles: Bundle[]) => {
  return bundles.reduce(
    (obj: ConcatedBundle, voucher: Bundle) => ({
      contractType: voucher.contractType,
      expirationDate: voucher.expirationDate,
      remainingQuantity:
        (obj.remainingQuantity || 0) + voucher.remainingQuantity,
    }),
    ({} as any) as ConcatedBundle,
  );
};

export const getBundlesGroupedByExpirationDateAndConcatByRemainingQuantity = (
  bundles: Bundle[],
) => {
  const groupedByExpirationDate = Object.values(groupByExpirationDate(bundles));

  return groupedByExpirationDate.reduce(
    (acc: ConcatedBundle[], groupedBundles: Bundle[]) => [
      ...acc,
      getBundlesConcatByRemainingQuantity(groupedBundles),
    ],
    [],
  );
};

export const getBundlesGroupedByContractTypeExpirationDateAndConcatByRemainingQuantity = (
  bundles: Bundle[],
) => {
  const { STANDARD, MATERNITY } = groupByContractType(bundles);

  return {
    standard: getBundlesGroupedByExpirationDateAndConcatByRemainingQuantity(
      STANDARD,
    ),
    maternity: getBundlesGroupedByExpirationDateAndConcatByRemainingQuantity(
      MATERNITY,
    ),
  };
};

const getExpirationDuration = (dateString: string): Duration => {
  const olderExpirationDate = DateTime.fromISO(dateString);

  return olderExpirationDate.diffNow(['days', 'months']);
};

/**
 * Return the localised label for the proper time unit according to expiration
 * date duration.
 * ie: 1 day, 3 months, 4 days
 */
export const getTimeUnitLabel = (
  expirationDuration: Duration,
  t: TFunction,
) => {
  if (!expirationDuration) return '';

  return !expirationDuration.months
    ? t(i18nKeys.general.daysCount, {
        count: Math.floor(expirationDuration.days) + 1,
      })
    : t(i18nKeys.general.monthCount, {
        count: Math.floor(expirationDuration.months),
      });
};

/**
 * Return ExpireDateCount
 * @param expiringBundles
 * @param t
 */
export const getInfoTitleLabel = <T>(
  expiringBundle:
    | (T & {
        remainingQuantity: number;
        expirationDate: string;
      })
    | undefined,
  canOrder: boolean,
  t: TFunction,
) => {
  if (!expiringBundle) {
    if (!canOrder) {
      return;
    }
    return t(i18nKeys.wallet.askNewVoucher);
  }

  const countLabel = t(i18nKeys.general.vouchersCount, {
    count: expiringBundle.remainingQuantity,
  });

  const expirationDuration = getExpirationDuration(
    expiringBundle.expirationDate,
  );

  const shouldDisplayTimeUnit =
    expirationDuration.months > 0 || expirationDuration.days > 0;

  const expireLabelKey = shouldDisplayTimeUnit
    ? i18nKeys.wallet.expireIn
    : i18nKeys.wallet.expireToday;

  let expireLabel = t(expireLabelKey, {
    count: expiringBundle.remainingQuantity,
  });

  if (shouldDisplayTimeUnit) {
    expireLabel += ` ${getTimeUnitLabel(expirationDuration, t)}`;
  }

  return `${countLabel} ${expireLabel}`;
};

const getTotalRemainingQuantity = <T>(
  array: Array<
    T & {
      remainingQuantity: number;
    }
  >,
) => {
  return array
    .map(({ remainingQuantity }) => remainingQuantity)
    .reduce((sum, quantity) => sum + quantity);
};

/**
 * Return birthAssistanceCount
 *
 * @param birthAssistanceBundles
 * @param t
 */
export const getBirthAssistanceLabel = <T>(
  array: Array<
    T & {
      remainingQuantity: number;
    }
  >,
  t: TFunction,
) => {
  if (!array || !array.length) {
    return;
  }
  const birthAssistanceCount = getTotalRemainingQuantity(array);
  const birthAssistanceLabel = t(i18nKeys.wallet.includingBirthAssistance, {
    count: birthAssistanceCount,
  });

  return birthAssistanceLabel;
};
