import { useAsync } from '@kaa/common/utils';
import { SwContainer } from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { AuthServiceAbstract, useAuthState } from '@kaa/auth/common';

export const Logout: React.FC<RouteComponentProps> = () => {
  const { logout } = useAuthState<AuthServiceAbstract>();

  const { loading } = useAsync(() => logout(), [logout]);

  if (loading) {
    return <SwContainer loading />;
  }

  return null;
};
