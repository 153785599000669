import React, { ReactNode } from 'react';
import { dataTest } from '../../datatest/keys';
import { SwLink } from '../link/SwLink';
import { mergeClassNames } from '../utilities';

type BreadcrumbProps = {
  children?: ReactNode;
  to?: string;
  className?: string;
  modActive?: boolean;
  useSeparator?: boolean;
  linkTestid?: string;
  linkText?: string;
  linkTagName?: string;
  onClick?: (e: any) => void;
};

const getClasses = () => {
  return ['vl-breadcrumb__list__item'].join(' ');
};

export const SwBreadcrumbItem = ({
  modActive,
  className,
  useSeparator = false,
  children,
  linkTestid,
  linkText,
  to,
  onClick = () => undefined,
}: BreadcrumbProps) => {
  const classes = getClasses();
  const page = modActive ? 'page' : undefined;

  return (
    <li
      data-testid={dataTest.breadcrumbItem}
      className={mergeClassNames(classes, className)}
      aria-current={page}
    >
      {useSeparator ? (
        <span
          data-testid={dataTest.breadcrumbItemSeparator}
          className="vl-breadcrumb__list__item__separator"
          aria-hidden="true"
        />
      ) : null}
      {to && (
        <SwLink
          className="vl-breadcrumb__list__item__cta"
          to={to}
          testid={linkTestid}
          onClick={onClick}
        >
          {linkText}
        </SwLink>
      )}
      {children}
    </li>
  );
};
