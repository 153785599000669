import React, { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';
import { SwGrid } from '../core';

type DescriptionDataProps = AllHTMLAttributes<HTMLDivElement> & {
  tagName?: string;
  modBordered?: boolean;
  modStacked?: boolean;
};

type Classes = {
  modBordered?: boolean;
};

const getClasses = ({ modBordered }: Classes) => {
  return [
    'vl-description-data',
    modBordered ? 'vl-description-data--bordered' : '',
  ].join(' ');
};

export const SwDescriptionData = <T extends HTMLElement>({
  tagName,
  modBordered,
  modStacked,
  children,
  className,
  ...rest
}: DescriptionDataProps) => {
  const TagName = tagName || 'div';

  const classes = getClasses({ modBordered });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    <SwGrid
      modStacked={modStacked}
      style={{ justifyContent: 'flex-start' }}
      className="vl-grid--align-start"
    >
      {children}
    </SwGrid>,
  );
};
