import { toCurrencyFormat } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwColumn, SwGrid, SwTitle } from '@kaa/ui-flanders/components';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../../datatest/keys';
import {
  calculateRefundSummary,
  RefundSummaryType,
} from './RefundElectronicSummary.utils';

export const RefundElectronicSummary: React.FC<RefundSummaryType> = ({
  bundles,
  requests,
}) => {
  const { t } = useTranslation();
  const { total, totalsPerRefundPrice } = useMemo(
    () => calculateRefundSummary({ bundles, requests }),
    [bundles, requests],
  );

  const shouldDisplayDisclaimer = useMemo(
    () =>
      totalsPerRefundPrice.some(
        ({ refundableUnitPrice, faceValue }) =>
          refundableUnitPrice !== faceValue,
      ),
    [totalsPerRefundPrice],
  );

  return (
    <>
      <SwColumn className="vl-u-spacer--xsmall">
        <SwTitle tagStyle="h6" tagName="h3" className="vl-u-spacer--xsmall">
          <span>{t(i18nKeys.refund.summary.title)}</span>
        </SwTitle>
        <SwGrid modSpaceBetween>
          {totalsPerRefundPrice.map(
            ({ faceValue, refundableUnitPrice, quantity, sum }) => {
              return (
                <Fragment key={`summary-${faceValue}-${refundableUnitPrice}`}>
                  <SwColumn
                    className="vl-u-spacer--xsmall"
                    width="6"
                    data-testid={dataTest.wallet.refund.summaryLineInfo}
                  >
                    {`${quantity} × ${toCurrencyFormat(refundableUnitPrice)}`}
                    {faceValue !== refundableUnitPrice && <sup>*</sup>} =
                  </SwColumn>
                  <SwColumn
                    width="6"
                    className="vl-u-align-right vl-u-spacer--xsmall"
                    data-testid={dataTest.wallet.refund.summaryLineSum}
                  >
                    <strong>{toCurrencyFormat(sum)}</strong>
                  </SwColumn>
                </Fragment>
              );
            },
          )}
          <SwColumn width="12" className="vl-u-spacer--xsmall">
            <div className="vl-u-hr" />
          </SwColumn>
          <SwColumn width="6">
            <strong>{t(i18nKeys.general.label.total)}</strong>
          </SwColumn>
          <SwColumn width="6" className="vl-u-align-right">
            <strong>{toCurrencyFormat(total)}</strong>
          </SwColumn>
        </SwGrid>
      </SwColumn>
      <SwColumn className="vl-u-spacer--xsmall vl-u-text--xsmall">
        {shouldDisplayDisclaimer && (
          <p>{t(i18nKeys.refund.summary.disclaimer)}</p>
        )}
      </SwColumn>
    </>
  );
};
