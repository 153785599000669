import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { FormikHandlers, FormikProps } from 'formik';
import React, { AllHTMLAttributes } from 'react';
import { dataTest } from '../../../../datatest/keys';
import { SwFormColumn, SwFormGrid } from '../../../core';
import { Column } from '../../../core/SwColumn';
import { SwDatePicker } from '../../../datepicker/SwDatePicker';
import { SwDatePickerProps } from '../../../datepicker/SwDatePicker.types';
import { SwFormInput } from '../../../form-input/SwFormInput';
import { SwFormMessageLabel } from '../../../form-message/SwFormMessageLabel';
import { useFormError } from '../../../util/hooks/useFormError';
import { FieldPropsClean, Omit } from '../../../utilities';
import { SwErrorMessageForField } from '../error-message-for-field/SwErrorMessageForField';
import './SwInputCalendarField.style.scss';

type SwInputCalendarProps = AllHTMLAttributes<HTMLInputElement> &
  FieldPropsClean &
  Omit<SwDatePickerProps, 'onChange'> & {
    type?: string;
    label?: string;
    ariaLabel?: string;
    column?: Column;
    labelColumn?: Column;
    modError?: boolean;
    modSmall?: boolean;
    modInline?: boolean;
    modBlock?: boolean;
    modDisabled?: boolean;
    modRequired?: boolean;
    excludeDates?: Array<{ from: Date | string; to: Date | string }>;
    inputOnChange?: (
      dates: Date[],
      field: {
        onChange: FormikHandlers['handleChange'];
        onBlur: FormikHandlers['handleBlur'];
        value: any;
        name: string;
      },
      form: FormikProps<any>,
    ) => void;
    parse?: (value?: Date[]) => any;
    format?: (value?: string) => Date[];
    autoUnsetServerError?: boolean;
    id?: string;
  };

export const SwInputCalendarField = ({
  field,
  form,
  type = 'text',
  label = '',
  ariaLabel = '',
  column = { width: '9', widthS: '12' },
  labelColumn = { width: '3', widthS: '12' },
  inputOnChange,
  modRequired,
  autoUnsetServerError = true,
  format,
  modDisabled,
  parse,
  id,
  ...props
}: SwInputCalendarProps) => {
  const errorMessage = useFormError({ field, form });
  return (
    <SwFormColumn>
      <SwFormGrid>
        <SwFormColumn {...labelColumn}>
          <SwFormMessageLabel
            modRequired={modRequired}
            htmlFor={id || field.name}
          >
            {label}
          </SwFormMessageLabel>
        </SwFormColumn>
        <SwFormColumn {...column}>
          <SwFormInput>
            <SwDatePicker
              id={id || field.name}
              name={field.name}
              type={type}
              data-testid={dynamicDataTest(dataTest.SwCalendar, {
                fieldName: id || field.name,
              })}
              onBlur={field.onBlur}
              {...props}
              value={format ? format(field.value) : field.value}
              onChange={(dates) => {
                form.setFieldValue(field.name, parse ? parse(dates) : dates);
                if (inputOnChange) {
                  inputOnChange(dates, field, form);
                }
              }}
              aria-label={ariaLabel}
              disableDate={modDisabled}
              modError={!!errorMessage}
            />
            <SwErrorMessageForField
              field={field}
              form={form}
              autoUnsetServerError={autoUnsetServerError}
              testId={dynamicDataTest(dataTest.SwInputTextFieldError, {
                fieldName: field.name,
              })}
            />
          </SwFormInput>
        </SwFormColumn>
      </SwFormGrid>
    </SwFormColumn>
  );
};
