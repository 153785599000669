import { useRef } from 'react';
import { useDeepCompareEffect } from './useDeepCompareEffect';

export const useDeepCompareCallback = <T extends (...args: any[]) => any>(
  callback: T,
  deps: any[],
): T => {
  const ref = useRef(callback);

  useDeepCompareEffect(() => {
    ref.current = callback;
  }, deps);

  return ref.current;
};
