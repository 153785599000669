import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

export type LayoutProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
};

export const SwLayout = <T extends HTMLElement>({
  children,
  tagName,
  className,
  ...rest
}: LayoutProps<T>) => {
  const TagName = tagName || 'div';

  return createElement(
    TagName,
    { className: mergeClassNames('vl-layout', className), ...rest },
    children,
  );
};
