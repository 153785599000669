import { ServiceContestationRequest } from '@kaa/api/customers';
import padStart from 'lodash.padstart';

export const PrestationsPendingWorkContestFieldNames: {
  ACTIVITY_TYPE: keyof ServiceContestationRequest;
  DURATION_IN_HOURS: keyof ServiceContestationRequest;
  NEVER_HAPPENED: keyof ServiceContestationRequest;
  COMMENT: keyof ServiceContestationRequest;
} = {
  ACTIVITY_TYPE: 'activityType',
  DURATION_IN_HOURS: 'durationInHours',
  NEVER_HAPPENED: 'neverHappened',
  COMMENT: 'comment',
};

export const formatActivityDuration = (num: number | string): string =>
  `${padStart(String(num), 2, '0')}h00`;
