import { Address, SupportType } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon, SwAlert,
  SwButton,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type KlarnaConfirmationAlertProps = {
    deliveryAddress?: Address;
    supportType?: SupportType,
    requestedQuantity?: number
};

const REDIRECT_URL_CONFIRMATION_KLARNA = `${window.location.origin}/services-vouchers/order`;

export const KlarnaConfirmationAlert = ({
  deliveryAddress,
  supportType,
  requestedQuantity
}: KlarnaConfirmationAlertProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <SwTitle tagName="h2">
        {t(i18nKeys.newOrder.stepPayment.klarna.confirmationOrder.title)}
      </SwTitle>
          {supportType === SupportType.PAPER && deliveryAddress ? (
              <SwAlert
                  modSuccess
                  className="vl-u-spacer"
                  icon={Icon.CHECK_CIRCLE}
                  content={t(
                      i18nKeys.newOrder.stepPayment.klarna.confirmationPayment
                          .alertPaperMessage,
                      {
                          amount: requestedQuantity,
                          deliveryDelay: 6,
                          address: `${deliveryAddress.houseNumber} ${deliveryAddress.street} - ${deliveryAddress.postcode} ${deliveryAddress.city}`,
                      },
                  )}
              />
          ) : (
              <SwAlert
                  modSuccess
                  className="vl-u-spacer"
                  icon={Icon.CHECK_CIRCLE}
                  content={t(
                      i18nKeys.newOrder.stepPayment.klarna.confirmationPayment
                          .alertMessage,
                      { amount: requestedQuantity },
                  )}
              />
          )}
      <SwButton
        type="button"
        onClick={() => {
          window.location.href = REDIRECT_URL_CONFIRMATION_KLARNA as string;
        }}
      >
        {t(i18nKeys.newOrder.stepPayment.klarna.confirmationOrder.cta)}
      </SwButton>
    </div>
  );
};
