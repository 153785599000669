import { useFocusTrap } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/common/keys';
import React, {
  ComponentType,
  createElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { dynamicDataTest } from '../../../common/utils/dataTest';
import { dataTest } from '../../datatest/keys';
import { SwIcon } from '../icon/SwIcon';
import { Icon } from '../icon/SwIcon.constants';
import { SwTitle } from '../title/SwTitle';
import { mergeClassNames } from '../utilities';
import './SwModal.style.scss';
import { useEventListener } from './utils/useEventListener';
import { useLockBodyScroll } from './utils/useLockBodyScroll';

const MESSAGE_TYPE = 'sw-modal-toggle';
const SHOW_MESSAGE_TYPE = 'sw-modal-show';

export const toggleModalById = (id: string) => {
  window.postMessage({ type: MESSAGE_TYPE, id }, '*');
};

export const showModalById = (id: string) => {
  window.postMessage({ type: SHOW_MESSAGE_TYPE, id }, '*');
};

const getClasses = () => {
  return ['vl-modal'].join(' ');
};

const getModalDialogClasses = (
  modMedium?: boolean,
  modLarge?: boolean,
  modFullScreen?: boolean,
) => {
  return [
    'vl-modal-dialog',
    modMedium ? 'vl-modal-dialog--medium' : '',
    modLarge ? 'vl-modal-dialog--large' : '',
    modFullScreen ? 'vl-modal-dialog--fullscreen' : '',
  ].join(' ');
};

export type SwModalRenderProps<T = {}> = T & {
  setConfirmCloseModal: (bool: boolean) => void;
  onClose?: () => void;
};

type ModalProps<T = any> = T & {
  footer?: ReactNode;
  className?: string;
  tagName?: string;
  closable?: boolean;
  open?: boolean;
  id: string;
  modLocked?: boolean;
  modLarge?: boolean;
  modMedium?: boolean;
  modFullScreen?: boolean;
  title?: string;
  confirmCloseMessage?: string;
  onClose?: () => void;
  component: ComponentType<SwModalRenderProps<T>>;
};

type VisibleModalProps<T = any> = ModalProps<T> & {
  modalOpened: boolean;
  changeModalState: (visible: boolean, disallowClosing?: boolean) => void;
};

const SwVisibleModal = <T extends any>({
  tagName,
  className,
  open,
  footer,
  title,
  closable,
  id,
  modLocked = true,
  confirmCloseMessage = '',
  onClose,
  modalOpened,
  changeModalState,
  component,
  modLarge,
  modMedium,
  modFullScreen, // customised for Provider - Vouchers declaration
  ...rest
}: VisibleModalProps<T>) => {
  const [focusLock, setFocusLock] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const TagName = tagName || 'div';
  const describedId = `${id}-description`;
  const dataTestid = dynamicDataTest(dataTest.SwModal, { id });
  const labelId = title ? `${id}-label` : '';

  const lastActiveElement = useRef(document.activeElement as HTMLElement);

  const { t } = useTranslation();
  useLockBodyScroll(focusLock);

  useEffect(() => {
    setFocusLock(modalOpened && modLocked);
  }, [modalOpened, modLocked]);

  const classes = getClasses();
  const modalDialogClasses = getModalDialogClasses(
    modMedium,
    modLarge,
    modFullScreen,
  );

  const handleClose = () => {
    if (confirmClose && !confirm(confirmCloseMessage)) {
      return;
    }
    // fix to solve a problem of concurrency with useFocusTrap
    setTimeout(() => {
      if (lastActiveElement.current) {
        // eslint-disable-next-line
        lastActiveElement.current?.focus?.();
      }
    });
    if (onClose) {
      onClose();
    }
    changeModalState(false, true);
  };

  const keyupHandler = (event: any) => {
    switch (event.keyCode) {
      case 27: {
        handleClose();
        break;
      }
      default:
    }
  };

  const setRef = useFocusTrap();

  return ReactDOM.createPortal(
    createElement(
      TagName,
      {
        className: mergeClassNames(classes, className),
        onKeyUp: keyupHandler,
        ref: setRef,
      },
      <>
        <div
          className="vl-modal__backdrop"
          role="presentation"
          onClick={handleClose}
        />
        <dialog
          data-testid={dataTestid}
          className={modalDialogClasses}
          aria-hidden={!modalOpened}
          aria-labelledby={labelId}
          aria-describedby={describedId}
          aria-modal="true"
          open={modalOpened}
        >
          {closable && (
            <button
              data-testid={dataTest.SwModalClose}
              type="button"
              className="vl-modal-dialog__close"
              onClick={handleClose}
            >
              <SwIcon
                icon={Icon.CLOSE}
                className="vl-modal-dialog__close__icon"
              />
              <span className="vl-u-visually-hidden">
                {t(i18nKeys.general.close)}
              </span>
            </button>
          )}
          {title && (
            <SwTitle
              tag-name="h2"
              id="labelId"
              className="vl-modal-dialog__title"
              v-if="title"
            >
              {title}
            </SwTitle>
          )}
          <div id="describedId" className="vl-modal-dialog__content">
            {createElement(component, {
              setConfirmCloseModal: setConfirmClose,
              onClose: handleClose,
              ...rest,
            })}
          </div>
          {footer}
        </dialog>
      </>,
    ),
    document.body,
  );
};

export const SwModal = <T extends any>(props: ModalProps<T>) => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const { closable, id, open } = props;

  const changeModalState = (
    visible: boolean,
    disallowClosing = false,
  ): void => {
    if ((disallowClosing && closable) || visible) {
      setModalOpened((isOpened) => !isOpened);
    }
  };

  const showModalState = (): void => {
    setModalOpened(true);
  };

  useEffect(() => {
    if (open) {
      setModalOpened(open);
    }
  }, [open]);

  useEventListener(
    'message',
    (message) => {
      if (
        message.data &&
        message.data.type === MESSAGE_TYPE &&
        message.data.id === id
      ) {
        changeModalState(true);
      }

      if (
        message.data &&
        message.data.type === SHOW_MESSAGE_TYPE &&
        message.data.id === id
      ) {
        showModalState();
      }
    },
    window,
  );

  if (!modalOpened) {
    return null;
  }

  return (
    <SwVisibleModal<T>
      changeModalState={changeModalState}
      modalOpened={modalOpened}
      {...props}
    />
  );
};
