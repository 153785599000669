import { RouteComponentProps } from '@reach/router';
import React, { ReactNode } from 'react';

type AccountScreenProps = {
  children?: ReactNode;
};

export const AccountScreen = ({
  children,
}: RouteComponentProps<AccountScreenProps>) => {
  return <>{children}</>;
};
