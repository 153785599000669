import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  getAlertPropsByType,
  SwAlert,
  SwColumn,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalCustomerMessagesState } from '../../utils';
import {
  getAlertChildrenByCode,
  MAP_MESSAGE_TO_ALERT_TYPE,
} from './AlertManager.utils';

export const AlertManager = () => {
  const messages = useGlobalCustomerMessagesState();
  const { t } = useTranslation();

  if (!messages) {
    return null;
  }

  return (
    <>
      {messages.map(({ type, code }, index) => {
        const alertType = MAP_MESSAGE_TO_ALERT_TYPE[type];
        const children = getAlertChildrenByCode(code, t);

        return (
          <SwColumn width="9" widthM="12" key={index}>
            <SwAlert
              title={t(i18nKeys.alert.frozen.title)}
              {...getAlertPropsByType(alertType)}
            >
              {children}
            </SwAlert>
          </SwColumn>
        );
      })}
    </>
  );
};
