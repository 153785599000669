import React, { AllHTMLAttributes, createElement, Ref } from 'react';
import { SwIcon } from '../icon/SwIcon';
import { useTooltip } from '../tooltip/useTooltip';
import { useVisuallyHidden } from '../util/hooks/useVisuallyHidden';
import { mergeClassNames } from '../utilities';

type InputAddonProps<T = HTMLParagraphElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  tooltip: string;
  text: string;
  icon?: string;
};

export const SwInputAddon = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      className,
      text,
      icon,
      tooltip,
      children,
      ...rest
    }: InputAddonProps<T>,
    ref: Ref<T>,
  ) => {
    const TagName = tagName || 'p';
    const classes = 'vl-input-addon';

    const visuallyHiddenRef = useVisuallyHidden<HTMLSpanElement>();
    const tooltipRef = useTooltip({ value: tooltip, placement: 'left' });

    // TODO: Improve typing
    const forwardRef = ref as any; // Disable typing to assign the current

    return createElement(
      TagName,
      {
        className: mergeClassNames(classes, className),
        ref: (element: HTMLElement) => {
          tooltipRef.current = element;
          if (forwardRef) {
            forwardRef.current = element;
          }
        },
        ...rest,
      },
      icon ? (
        <>
          <SwIcon icon={icon} /> <span ref={visuallyHiddenRef}>{text}</span>
        </>
      ) : (
        <>{text}</>
      ),
    );
  },
);
