import {
  ApiErrorField,
  CodeError,
  FieldValidationErrorCode,
  RegionCode,
  SupportType,
  TitleCode,
} from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { getApi } from '@kaa/api/idp';
import { getQueryParam, useAsync, useAsyncCallback } from '@kaa/common/utils';
import {
  email,
  isIban,
  maxLength,
  notIf,
  required,
  ValidationError,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AddressFieldNames,
  AlertType,
  getDefaultAddressValidation,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwForm,
  SwFormSubmitMessage,
  SwFormValidateOnMount,
  SwGrid,
  SwLink,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import { Formik, FormikActions, FormikProps } from 'formik';
import i18n, { TFunction } from 'i18next';
import get from 'lodash.get';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getConfig } from '../../../../common/config';
import { PageHeader } from '../../../components';
import {
  REGION_HELP_CENTER_NUMBER,
  SERVICE_VOUCHERS_WEBSITE_LINKS,
} from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { getPath, getRouterPath, Routes } from '../../../routes';
import { useApi } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  sendAccountSubscirptionEventToGTM,
} from '../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../utils/validation';
import { getRedirectUrlToInitiateEid } from '../RegisterScreen.utils';
import { RegisterBankInfoForm } from './components/RegisterBankInfofForm';
import { RegisterContactInfoForm } from './components/RegisterContactInfoForm';
import { RegisterDeliveryAddress } from './components/RegisterDeliveryAddress';
import { RegisterMainInfoForm } from './components/RegisterMainInfoForm';
import { RegisterSupportTypeForm } from './components/RegisterSupportTypeForm';
import { RegisterCompleteScreenFieldNames } from './RegisterCompleteScreen.constants';
import { RegisterFormState } from './RegisterCompleteScreen.types';
import { minEighteenYearOld } from './RegisterCompleteScreen.utils';

const defaultMainAddressValidation = getDefaultAddressValidation(
  RegisterCompleteScreenFieldNames.MAIN_ADDRESS,
);
const defaultDeliveryAddressValidation = getDefaultAddressValidation(
  RegisterCompleteScreenFieldNames.DELIVERY_ADDRESS,
);
export const RegisterCompleteScreen = ({ navigate }: RouteComponentProps) => {
  const { t } = useTranslation();
  const idpBaseUrl = get(getConfig(), 'app.auth.oidc.authority');
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const idpApi = useMemo(() => getApi({ baseUrl: idpBaseUrl }), [idpBaseUrl]);
  const { customers: customersApi } = useApi();

  const token = getQueryParam('token');
  const emailAddress = getQueryParam('state');

  const { loading: loadingEidData, value: eidData } = useAsync(async () => {
    if (!token) {
      return;
    }

    const { data } = await idpApi.readEidData(token);

    const {
      firstName,
      lastName,
      birthDate,
      gender,
      niss,
      street,
      city,
      postCode,
      houseNumber,
      rawData,
    } = data;

    const GENDER = {
      '1': TitleCode.MR,
      '2': TitleCode.MRS,
    };

    return {
      firstName,
      lastName,
      mainAddress: {
        street,
        city,
        streetId: 0,
        postcode: postCode,
        houseNumber,
      },
      birthDate,
      title: GENDER[gender as '1' | '2'],
      niss,
      eidData: rawData,
    };
  }, [idpApi, token]);

  const [
    {
      value: registerData,
      loading: loadingRegisterData,
      error: registerDataError,
    },
    getRegisterData,
  ] = useAsyncCallback(
    async () => {
      const [{ cities }, { availableLanguages }] = await Promise.all([
        customersApi.getGlobalLocations().then(({ data: { data } }) => data),
        customersApi
          .getGlobalRegionContext()
          .then(({ data: { data } }) => data),
      ]);
      return { availableCities: cities, availableLanguages };
    },
    [customersApi],
    { loading: true },
  );

  useEffect(() => {
    getRegisterData();
  }, [getRegisterData]);

  const [, getStreets] = useAsyncCallback(
    async (postcode: string, query: string) => {
      const keyword = query.trim();
      if (!keyword) {
        return [];
      }
      try {
        const {
          data: { data },
        } = await customersApi.getLocationsSuggestions({ postcode, keyword });
        return data;
      } catch (e) {
        return [];
      }
    },
    [customersApi],
  );

  const { value: street, loading: loadingStreet } = useAsync(async () => {
    if (!eidData) {
      return;
    }
    const [, streets = []] = await getStreets(
      eidData.mainAddress.postcode,
      eidData.mainAddress.street,
    );

    if (streets.length > 1) {
      return;
    }
    const street = streets[0];

    if (!street) {
      return;
    }

    return {
      street: street.name,
      city: street.city,
      streetId: street.id,
      postcode: street.postcode,
      country: street.country,
    };
  }, [eidData]);

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: RegisterFormState,
      formikActions: FormikActions<RegisterFormState>,
    ) => {
      if (eidData) {
        sendAccountSubscirptionEventToGTM(
          EventCategory.REGISTER,
          EventLabel.REGISTER_EID,
          EventAction.CREATION,
        );
      } else {
        sendAccountSubscirptionEventToGTM(
          EventCategory.REGISTER,
          EventLabel.REGISTER_MANUALLY,
          EventAction.CREATION,
        );
      }

      const {
        title,
        deliveryAddress,
        useMainAddressForDeliveryAddress,
        ...data
      } = formikData;

      const [error, response] = await httpTo(
        customersApi.registerCustomer({
          ...data,
          title: title as TitleCode,
          ...(!useMainAddressForDeliveryAddress && { deliveryAddress }),
        }),
      );

      return { error, response, formikData, formikActions };
    },
    [customersApi],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;

      const { resetForm, setStatus, setSubmitting } = formikActions;

      /**
       * Viper will throw some validation error but for the niss, we don't want
       * to display the error below the input because we have to contact the support
       * and make sure that the user see this error and not think that it is fault
       */
      if (
        error?.code === CodeError.VALIDATION_EXCEPTION &&
        error.validations
          ?.find((validation: ApiErrorField) => validation.fieldName === 'niss')
          ?.errors?.includes(FieldValidationErrorCode.CONTACT_SUPPORT)
      ) {
        setSubmitting(false);
        setStatus({
          msg: i18n.t(
            i18nKeys.errors.server.form[
              FieldValidationErrorCode.CONTACT_SUPPORT
            ] || i18nKeys.errors.server.DEFAULT,
            {
              phoneNumber: t(REGION_HELP_CENTER_NUMBER[regionCode]),
            },
          ),
          type: AlertType.ERROR,
        });

        return;
      }

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        return;
      }

      resetForm(formikData);
      setStatus({
        msg: t('profile.successMessage'),
        type: AlertType.SUCCESS,
      });
      setSubmitting(false);

      if (navigate) {
        navigate(getRouterPath(Routes.REGISTER_SUMMARY), {
          state: { data: formikData, eid: !!eidData },
        });
      }
    }
  }, [submitedForm, navigate]);

  const isInCorrectRegion = useCallback(
    (value: string): ValidationError | false => {
      if (!value) {
        return false;
      }

      if (!registerData) {
        return false;
      }

      const city = registerData.availableCities.find(
        (city) => value === city.postcode,
      );

      if (!city) {
        return false;
      }

      if (regionCode !== city.regionCode) {
        return {
          defaultMessage:
            'Please subscribe yourselves to the service voucher platform corresponding to the region you are domiciled.',
          id: (t: TFunction) => (
            <Trans i18nKey={i18nKeys.register.errors.form.wrongRegion}>
              Please subscribe yourselves to the service voucher platform
              corresponding to the region you are domiciled.
              <SwLink
                tagName="a"
                href={t(SERVICE_VOUCHERS_WEBSITE_LINKS[city.regionCode])}
              >
                Go to this portal
              </SwLink>
            </Trans>
          ),
          displayFocussed: !!eidData,
        };
      }

      return false;
    },
    [registerData, eidData],
  );

  const isEidStreet = useCallback((): ValidationError | false => {
    if (!eidData || street) {
      return false;
    }

    return {
      id: (t: TFunction) => (
        <Trans
          i18nKey={i18nKeys.register.errors.form.eidStreetNotFound}
          values={{ phoneNumber: t(i18nKeys.bl.general.helpCenter.phone) }}
        >
          Something went wrong please try to
          <SwLink to={getPath(Routes.REGISTER)}>subscribe manually</SwLink>
          or call our help center on {t(i18nKeys.bl.general.helpCenter.phone)}
        </Trans>
      ),
      defaultMessage:
        'Something went wrong please try to subscribe manually or call our help center',
      displayFocussed: !!eidData,
    };
  }, [street, eidData]);

  const validate = useMemo(
    () =>
      createValidatorWithServerErrorHandled<Partial<RegisterFormState>>({
        title: [required],
        firstName: [required],
        lastName: [required],
        birthDate: [required, minEighteenYearOld],
        phoneNumber: [maxLength(20)],
        mobilePhoneNumber: [maxLength(20)],
        refundBankAccount: [required, isIban],
        emailAddress: [required, email],
        language: [required],
        preferredSupportType: [required],
        niss: [required],
        ...Object.entries(defaultDeliveryAddressValidation).reduce(
          (acc, [key, validators]) => ({
            ...acc,
            [key]: notIf(
              RegisterCompleteScreenFieldNames.USE_MAIN_ADDRESS_FOR_DELIVERY_ADDRESS,
              validators,
            ),
          }),
          {},
        ),
        ...defaultMainAddressValidation,
        [`mainAddress.${AddressFieldNames.POSTCODE}`]: [
          ...defaultMainAddressValidation[
            `mainAddress.${AddressFieldNames.POSTCODE}`
          ],
          isInCorrectRegion,
        ],
        [`mainAddress.${AddressFieldNames.STREET}`]: [
          ...defaultMainAddressValidation[
            `mainAddress.${AddressFieldNames.STREET}`
          ],
          isEidStreet,
        ],
      }),
    [isInCorrectRegion, isEidStreet],
  );

  if (!emailAddress) {
    if (navigate) {
      navigate(getRouterPath(Routes.REGISTER));
    }
    return <SwContainer loading />;
  }

  if (loadingRegisterData || loadingEidData || loadingStreet) {
    return <SwContainer loading />;
  }

  if (registerDataError || !registerData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getRegisterData} />
      </SwContainer>
    );
  }

  const { availableCities, availableLanguages } = registerData;

  return (
    <SwContainer>
      <SwGrid modStacked className="vl-u-flex-v-flex-start">
        <PageHeader
          title={
            !eidData
              ? t(i18nKeys.register.manually.title)
              : t(i18nKeys.register.eid.title)
          }
          introduction={
            !eidData && (
              <>
                {t(i18nKeys.register.step2.introduction.text)}{' '}
                <SwLink
                  tagName="a"
                  href={getRedirectUrlToInitiateEid(emailAddress)}
                >
                  {t(i18nKeys.register.step2.introduction.link.label)}
                </SwLink>
                <br />
                {t(i18nKeys.register.step2.introduction.warning)}{' '}
              </>
            )
          }
        />
        <SwColumn width="10" widthS="12">
          <Formik
            initialValues={{
              useMainAddressForDeliveryAddress: true,
              language:
                availableLanguages.length === 1 ? availableLanguages[0] : '',
              emailAddress,
              title: '',
              firstName: '',
              lastName: '',
              birthDate: '',
              niss: '',
              preferredSupportType: SupportType.ELECTRONIC,
              ...eidData,
              mainAddress: {
                street: '',
                city: '',
                streetId: 0,
                postcode: '',
                houseNumber: '',
                ...(eidData && eidData.mainAddress),
                ...street,
              },
              deliveryAddress: {
                street: '',
                city: '',
                streetId: 0,
                postcode: '',
                houseNumber: '',
              },
              refundBankAccount: '',
            }}
            onSubmit={submit}
            validate={validate}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              dirty,
            }: FormikProps<Partial<RegisterFormState>>) => (
              <SwForm onSubmit={handleSubmit}>
                <SwGrid modStacked className="vl-u-flex-v-flex-start">
                  <SwColumn>
                    <RegisterMainInfoForm
                      getStreets={getStreets}
                      availableCities={availableCities}
                      modDisabled={!!eidData}
                      isEidTitle={!!eidData && !!eidData.title}
                    />
                  </SwColumn>
                  <RegisterDeliveryAddress
                    getStreets={getStreets}
                    availableCities={availableCities}
                    values={values}
                  />
                  <SwColumn>
                    <RegisterSupportTypeForm />
                  </SwColumn>
                  <SwColumn>
                    <RegisterContactInfoForm
                      availableLanguages={availableLanguages}
                    />
                  </SwColumn>
                  <SwColumn>
                    <RegisterBankInfoForm />
                  </SwColumn>
                  <SwActionGroup modCollapseS>
                    <SwButton
                      type="submit"
                      modLoading={isSubmitting}
                      modLarge
                      modDisabled={!dirty}
                      data-testid={dataTest.profile.saveProfileButton}
                    >
                      {t(i18nKeys.register.cta.sendRequest)}
                    </SwButton>
                  </SwActionGroup>
                  <SwFormValidateOnMount />
                  <SwFormSubmitMessage />
                </SwGrid>
              </SwForm>
            )}
          </Formik>
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
