import {
  ApiError,
  CodeError,
  SupportType,
  VouchersOrderItemCreate,
} from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwAlert,
  SwBadge,
  SwColumn,
  SwLoader,
  SwRadioField,
  SwTitle,
  useTooltip,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PaymentType } from '../OrderModal.constants';
import {
  KlarnaLoadCallbackResponse,
  KlarnInternalWidgetError,
} from './klarna/klarna.type';
import { OrderTable } from './OrderTable';

type OrderStepPaymentProps = {
  orderItems: VouchersOrderItemCreate[];
  paymentType?: PaymentType;
  supportType: SupportType;
  klarnaWidget: KlarnaLoadCallbackResponse | null;
  canPayWithKlarna: boolean;
  pendingOrderAlreadyExists: boolean;
  klarnaError:
    | CodeError
    | KlarnInternalWidgetError
    | ApiError
    | null
    | undefined;
  isInitialized: boolean;
};

export const OrderStepPayment = ({
  orderItems,
  paymentType,
  klarnaWidget,
  pendingOrderAlreadyExists,
  canPayWithKlarna,
  klarnaError,
  isInitialized,
}: OrderStepPaymentProps) => {
  const { t } = useTranslation();

  const refTooltipInfo = useTooltip({
    value: t(i18nKeys.newOrder.stepPayment.klarna.infoBanks),
    placement: 'right',
  });

  return (
    <>
      <SwColumn className="vl-u-spacer">
        <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--xsmall">
          {t(i18nKeys.newOrder.stepPayment.OrderSummary)}
        </SwTitle>
        <OrderTable orderItems={orderItems} />
        <SwTitle
          tagName="h3"
          tagStyle="h5"
          className="vl-u-spacer--xsmall"
          id="paymentTypeSet"
        >
          {t(i18nKeys.general.label.paymentMethod)}
        </SwTitle>
        {canPayWithKlarna ? (
          <>
            <SwColumn
              className="vl-u-spacer"
              role="radiogroup"
              aria-labelledby="paymentTypeSet"
            >
              <Field
                role="radio"
                name="paymentType"
                id={PaymentType.BANK_TRANSFER}
                label={t(i18nKeys.general.label.paymentType.bankTransfer)}
                component={SwRadioField}
              />
              <Field
                role="radio"
                name="paymentType"
                id={PaymentType.KLARNA}
                label={t(i18nKeys.general.label.paymentType.klarna)}
                component={SwRadioField}
                modSpacer
              />
            </SwColumn>
            <SwColumn
              hidden={
                paymentType !== PaymentType.KLARNA ||
                !klarnaWidget
              }
            >
              <div id="klarna-payments-container" />
            </SwColumn>
            {paymentType === PaymentType.KLARNA &&
              !klarnaWidget &&
              klarnaError !== CodeError.KLARNA_SESSION_FAILED && (
                <SwColumn className="vl-u-spacer">
                  <SwLoader
                    message={t(i18nKeys.newOrder.stepPayment.klarna.loading)}
                  />
                </SwColumn>
              )}
          </>
        ) : (
          <SwColumn className="vl-u-spacer">
            {t(i18nKeys.general.label.paymentType.bankTransfer)}
          </SwColumn>
        )}
        {paymentType === PaymentType.KLARNA && (
          <SwColumn className="vl-u-spacer">
            <div className="vl-u-text--xsmall">
              <Trans i18nKey={i18nKeys.newOrder.stepPayment.klarna.info}>
                We are currently seeing transactions that fail when this payment
                method is used with <strong>some banks</strong>
              </Trans>
              <SwBadge
                modAction
                modXxsmall
                icon={Icon.INFO}
                style={{ verticalAlign: 'middle', marginLeft: '.5rem' }}
                ref={refTooltipInfo}
              />
            </div>
          </SwColumn>
        )}
        {paymentType === PaymentType.BANK_TRANSFER &&
          pendingOrderAlreadyExists && (
            <SwColumn>
              <SwAlert
                icon={Icon.ALERT_CIRCLE}
                modWarning
                title={t(
                  i18nKeys.newOrder.stepPayment.alert.pendingOrderAlreadyExists
                    .body,
                )}
              />
            </SwColumn>
          )}
      </SwColumn>
    </>
  );
};
