import React, { AllHTMLAttributes, createElement, Ref } from 'react';
import { SwButton } from '../button/SwButton';
import { SwLink } from '../link/SwLink';
import { mergeClassNames } from '../utilities';

type TitleProps = AllHTMLAttributes<HTMLDivElement> & {
  tagName?: string;
  tagStyle?: string;
  sublink?: string;
  sublinkText?: string;
  sublinkIcon?: string;
  cta?: string;
  ctaText?: string;
  modBorder?: boolean;
  modAlt?: boolean;
  modSans?: boolean;
};

type Classes = {
  semanticTagStyle?: string;
  modBorder?: boolean;
  modAlt?: boolean;
  modSans?: boolean;
};
const getClasses = ({
  semanticTagStyle,
  modAlt,
  modBorder,
  modSans,
}: Classes) => {
  return [
    'vl-title',
    `vl-title--${semanticTagStyle}`,
    modBorder ? 'vl-title--has-border' : '',
    modAlt ? 'vl-title--alt' : '',
    modSans ? 'vl-title--sans' : '',
  ].join(' ');
};

type WrapperClasses = {
  semanticTagStyle?: string;
  sublink?: string;
  cta?: string;
};
const getWrapperClasses = ({
  semanticTagStyle,
  sublink,
  cta,
}: WrapperClasses) => {
  return [
    'vl-title-wrapper',
    `vl-title-wrapper--${semanticTagStyle}`,
    sublink ? 'vl-title-wrapper--sublink' : '',
    cta ? 'vl-title-wrapper--cta' : '',
  ].join(' ');
};

export const SwTitle = React.forwardRef(
  (
    {
      tagName,
      modAlt,
      modBorder,
      modSans,
      sublink,
      sublinkIcon,
      sublinkText,
      cta,
      ctaText,
      tagStyle,
      className,
      children,
      ...rest
    }: TitleProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const TagName = tagName || 'h1';

    const semanticTagStyle = tagStyle || tagName;
    const isWrapped = !!sublink || !!cta;
    const linkText = cta ? ctaText : sublinkText;
    const linkUrl = cta || sublink;
    const LinkType: any = cta ? SwButton : SwLink;

    const classes = getClasses({
      semanticTagStyle,
      modSans,
      modBorder,
      modAlt,
    });

    const wrapperClasses = getWrapperClasses({
      semanticTagStyle,
      sublink,
      cta,
    });

    const sublinkIconWithDefault = sublinkIcon || '';

    if (isWrapped) {
      return (
        <div className={mergeClassNames(wrapperClasses, className)} {...rest}>
          {createElement(
            TagName,
            { className: mergeClassNames(classes), ref },
            children,
          )}
          {createElement(
            LinkType,
            {
              href: linkUrl,
              icon: sublinkIconWithDefault,
              modIconBefore: true,
            },
            linkText,
          )}
        </div>
      );
    }

    return createElement(
      TagName,
      { className: mergeClassNames(classes, className), ...rest, ref },
      children,
    );
  },
);
