import {
  b64toBlob,
  getIbanMock,
  getResourceIdMock,
  samplePdf,
} from '@kaa/api/common/mocks';
import faker from 'faker';
import { DateTime } from 'luxon';
import {
  Bundle,
  ContractType,
  CustomerBankAccountsResponse,
  CustomerWallet,
  CustomerWalletResponse,
  GetCustomerWalletParams,
  StringValueFileCreatedResponse,
} from '../model';

export const getDateString = (date: Date) => date.toISOString().split('T')[0];

export const getOrder = (
  remainingQuantity = faker.random.number({ min: 50, max: 99 }),
  refundableQuantity = faker.random.number({ min: 1, max: 50 }),
  faceValue = 9,
  refundableUnitPrice = 9,
  minDate = getDateString(faker.date.recent()),
  contractType: ContractType = faker.helpers.randomize(
    Object.values(ContractType),
  ),
): Bundle => ({
  id: faker.random.number(999),
  remainingQuantity,
  refundableQuantity,
  faceValue,
  refundableUnitPrice,
  expirationDate: getDateString(
    faker.date.between(
      minDate,
      DateTime.fromISO(minDate)
        .plus({ days: faker.random.number({ min: 1, max: 45 }) })
        .toISODate(),
    ),
  ),
  contractType,
});

export const getOrders = (availableVouchers = faker.random.number(99)) => {
  let voucherBundles: Bundle[] = [];
  let currentAvailableVouchers = availableVouchers;
  let actualBundle: Bundle | undefined;

  while (currentAvailableVouchers) {
    const remainingQuantity = faker.random.number({
      min: 1,
      max: currentAvailableVouchers,
    });
    const faceValue = faker.random.arrayElement([9, 10]);
    const type = faker.helpers.randomize(Object.values(ContractType));
    actualBundle = getOrder(
      remainingQuantity,
      type === ContractType.MATERNITY
        ? 0
        : faker.random.number(remainingQuantity),
      faceValue,
      faker.random.arrayElement([faceValue, faceValue * 0.9]),
      actualBundle && actualBundle.expirationDate,
      type,
    );
    voucherBundles = [...voucherBundles, actualBundle];
    currentAvailableVouchers -= remainingQuantity;
  }

  return voucherBundles;
};

export const getWallet = (): CustomerWallet => {
  const availableVouchers = faker.random.number({ min: 0, max: 99 });

  return {
    availableVouchers,
    resourceId: getResourceIdMock('wallet'),
    voucherBundles: getOrders(availableVouchers),
  };
};

export const getCustomerWalletResponse = (
  params?: GetCustomerWalletParams,
): CustomerWalletResponse => {
  return {
    data: getWallet(),
  };
};

export const getCustomerBankAccountsResponse = (): CustomerBankAccountsResponse => {
  return {
    data: faker.helpers.randomize([
      {
        refundBankAccount: faker.random.arrayElement([
          { iban: getIbanMock() },
          { iban: 'FR14 2004 1010 0505 0001 3M02 606', bic: 'BICCODE1' },
        ]),
        latestOrderBankAccount: { iban: getIbanMock() },
      },
      {},
    ]),
  };
};

export const getRefundOrExchangeDocument = (
  sendResultInStringFormat?: boolean,
): Blob | StringValueFileCreatedResponse => {
  return sendResultInStringFormat
    ? { data: { stringValueFile: samplePdf, fileName: 'samplePdf' } }
    : b64toBlob(samplePdf);
};
