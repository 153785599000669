import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwLoader } from '@kaa/ui-flanders/components';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const KlarnaMessageOrderProcessing = ({
  hide = false,
  children,
}: {
  hide: boolean;
  children: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {hide ? (
        <SwLoader
          message={t(i18nKeys.newOrder.stepPayment.klarna.processingOrder)}
        />
      ) : null}
      <div hidden={hide}>{children}</div>
    </div>
  );
};
