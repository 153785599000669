import { HandiwebRequestBeneficiary } from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { checked, maxLength, required } from '@kaa/common/validation';
import { PageHeader } from '@kaa/core-app/customers/components';
import {
  useApi,
  useSelectedCustomerState,
} from '@kaa/core-app/customers/utils';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '@kaa/core-app/customers/utils/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AlertType,
  SwActionGroup,
  SwButton,
  SwCheckboxField,
  SwColumn,
  SwContainer,
  SwForm,
  SwFormSubmitMessage,
  SwGrid,
  SwInputTextField,
  SwRadioField,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps, Formik, FormikActions } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HandiwebRequestForm } from './HandiwebRequest.types';
import { getLabel, isMyself } from './HandiwebRequest.utils';

export const HandiwebRequestScreen = () => {
  const { t } = useTranslation();
  const { customers: customersApi } = useApi();
  const customer = useSelectedCustomerState();

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: HandiwebRequestForm,
      formikActions: FormikActions<HandiwebRequestForm>,
    ) => {
      const [error, response] = await httpTo(
        customersApi.switchCustomerHandiweb(customer.id, formikData),
      );

      return { error, response, formikData, formikActions };
    },
    [customersApi],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions, formikData } = submitedForm;
      const { setSubmitting, resetForm, setStatus } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      resetForm(formikData);

      setStatus({
        title: t(i18nKeys.handiweb.form.alert.success.title),
        msg: t(i18nKeys.handiweb.form.alert.success.message),
        type: AlertType.SUCCESS,
      });

      setSubmitting(false);
    }
  }, [submitedForm, t]);

  const initialValues = {
    beneficiary: HandiwebRequestBeneficiary.SELF,
    firstName: customer.firstName,
    lastName: customer.lastName,
    niss: customer.niss,
    sworn: false,
  } as HandiwebRequestForm;

  const switchTarget = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FieldProps<HandiwebRequestForm>['field'],
    form: FieldProps<HandiwebRequestForm>['form'],
  ): void => {
    const beneficiary = e.target.value as HandiwebRequestBeneficiary;
    form.resetForm({
      ...initialValues,
      beneficiary,
      firstName: isMyself(beneficiary) ? customer.firstName : '',
      lastName: isMyself(beneficiary) ? customer.lastName : '',
      niss: isMyself(beneficiary) ? customer.niss : '',
    });
  };

  const validate = createValidatorWithServerErrorHandled<HandiwebRequestForm>({
    beneficiary: [required],
    firstName: [required, maxLength(48)],
    lastName: [required, maxLength(48)],
    niss: [required, maxLength(11)],
    sworn: [checked],
  });

  return (
    <SwContainer>
      <SwGrid modStacked className="vl-u-flex-v-flex-start">
        <PageHeader
          title={t(i18nKeys.handiweb.title)}
          introduction={t(i18nKeys.handiweb.introduction)}
        />
        <SwColumn width="8" widthS="12">
          <p className="vl-u-spacer--small">
            {t(i18nKeys.handiweb.explaination)}
          </p>
          <p className="vl-u-spacer--medium">
            {t(i18nKeys.handiweb.form.introduction)}
          </p>

          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit, dirty, isSubmitting, values }) => (
              <SwForm onSubmit={handleSubmit}>
                <SwGrid modStacked className="vl-u-flex-v-flex-start">
                  <fieldset>
                    <legend className="vl-form__label vl-u-spacer--xsmall">
                      {t(i18nKeys.handiweb.form.requestTarget.legend)}
                    </legend>
                    <Field
                      name="beneficiary"
                      component={SwRadioField}
                      id={HandiwebRequestBeneficiary.SELF}
                      label={t(
                        i18nKeys.handiweb.form.requestTarget.labels.myself,
                      )}
                      inputOnChange={switchTarget}
                    />
                    <Field
                      name="beneficiary"
                      component={SwRadioField}
                      id={HandiwebRequestBeneficiary.DEPENDENT}
                      label={t(
                        i18nKeys.handiweb.form.requestTarget.label.dependent,
                      )}
                      inputOnChange={switchTarget}
                    />
                  </fieldset>
                  <Field
                    name="firstName"
                    component={SwInputTextField}
                    label={getLabel(
                      i18nKeys.general.label.firstName,
                      values.beneficiary,
                      t,
                    )}
                    modDisabled={isMyself(values.beneficiary)}
                  />
                  <Field
                    name="lastName"
                    component={SwInputTextField}
                    label={getLabel(
                      i18nKeys.general.label.name,
                      values.beneficiary,
                      t,
                    )}
                    modDisabled={isMyself(values.beneficiary)}
                  />
                  <Field
                    name="niss"
                    component={SwInputTextField}
                    label={getLabel(
                      i18nKeys.general.label.niss,
                      values.beneficiary,
                      t,
                    )}
                    modDisabled={isMyself(values.beneficiary)}
                  />
                  <Field
                    name="sworn"
                    component={SwCheckboxField}
                    label={t(i18nKeys.handiweb.form.optin)}
                  />

                  <SwActionGroup modCollapseS>
                    <SwButton
                      type="submit"
                      modLarge
                      modLoading={isSubmitting}
                      modDisabled={!dirty}
                    >
                      {t(i18nKeys.general.cta.save)}
                    </SwButton>
                  </SwActionGroup>
                  <SwFormSubmitMessage />
                </SwGrid>
              </SwForm>
            )}
          />
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
