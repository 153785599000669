import {
  SwButton,
  SwContainer,
  SwIntroduction,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';

type ConfigLoadFailedFallbackProps = {
  mainTitle: string;
  introduction: string;
  actionButton: string;
};

export const ConfigLoadFailedFallback = ({
  mainTitle,
  introduction,
  actionButton,
}: ConfigLoadFailedFallbackProps) => (
  <SwContainer error>
    <SwTitle tagName="h1" className="vl-u-spacer--medium">
      {mainTitle}
    </SwTitle>
    <SwIntroduction className="vl-u-spacer--large">
      {introduction}
    </SwIntroduction>
    <SwButton
      onClick={() => {
        window.location.reload();
      }}
    >
      {actionButton}
    </SwButton>
  </SwContainer>
);
