import { i18nKeys } from '@kaa/i18n/common/keys';
import React, {
  AllHTMLAttributes,
  createElement,
  ReactNode,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { SwIcon } from '../icon/SwIcon';
import { Icon } from '../icon/SwIcon.constants';
import { mergeClassNames } from '../utilities';
import './SwAlert.style.scss';

export type SwAlertProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  actions?: ReactNode;
  tagName?: string;
  titleTagName?: string;
  icon?: string;
  title?: string;
  image?: string;
  closeText?: string;
  content?: string;
  closable?: boolean;
  modSmall?: boolean;
  modSuccess?: boolean;
  modWarning?: boolean;
  modError?: boolean;
  modCta?: boolean;
  modLarge?: boolean;
  modNaked?: boolean;
  modFadeOut?: boolean;
  close?: () => void;
};

type Classes = {
  modSmall?: boolean;
  modSuccess?: boolean;
  modWarning?: boolean;
  modError?: boolean;
  modCta?: boolean;
  modLarge?: boolean;
  modNaked?: boolean;
  modFadeOut?: boolean;
};
const getClasses = ({
  modSmall,
  modSuccess,
  modWarning,
  modError,
  modCta,
  modLarge,
  modNaked,
  modFadeOut,
}: Classes) => {
  return [
    'vl-alert',
    modSmall ? 'vl-alert--small' : '',
    modSuccess ? 'vl-alert--success' : '',
    modWarning ? 'vl-alert--warning' : '',
    modError ? 'vl-alert--error' : '',
    modCta ? 'vl-alert--cta' : '',
    modFadeOut ? 'vl-alert--fade-out' : '',
    modLarge ? 'vl-alert--large' : '',
    modNaked ? 'vl-alert--naked' : '',
  ].join(' ');
};

type TitleContentClasses = {
  modSuccess?: boolean;
  modWarning?: boolean;
  modError?: boolean;
  modNaked?: boolean;
};
const getTitleContentClasses = ({
  modSuccess,
  modWarning,
  modError,
  modNaked,
}: TitleContentClasses) => {
  return [
    'vl-alert__title',
    modSuccess && modNaked ? 'vl-u-mark--success' : '',
    modWarning && modNaked ? 'vl-u-mark--warning' : '',
    modError && modNaked ? 'vl-u-mark--error' : '',
  ].join(' ');
};

const getMessageClasses = () => {
  return ['vl-alert__message', 'vl-typography'].join(' ');
};

type NakedClasses = {
  modSuccess?: boolean;
  modWarning?: boolean;
  modError?: boolean;
  modNaked?: boolean;
};
const getNakedClasses = ({
  modSuccess,
  modWarning,
  modError,
  modNaked,
}: NakedClasses) => {
  return [
    modSuccess && modNaked ? 'vl-u-mark--success' : '',
    modWarning && modNaked ? 'vl-u-mark--warning' : '',
    modError && modNaked ? 'vl-u-mark--error' : '',
  ].join(' ');
};

export const SwAlert = <T extends HTMLElement>({
  tagName,
  titleTagName,
  className,
  icon,
  image,
  closeText,
  closable,
  close,
  modNaked,
  modCta,
  modError,
  modLarge,
  modSmall,
  modSuccess,
  modWarning,
  title,
  children,
  content,
  actions,
  ...rest
}: SwAlertProps<T>) => {
  const { t } = useTranslation();
  const [visible] = useState(true);
  const [modFadeOut] = useState(false);

  const TagName = tagName || 'div';
  const TitleTagName = titleTagName || 'h2';
  const defaultCloseFn = () => undefined;

  const classes = getClasses({
    modFadeOut,
    modCta,
    modError,
    modLarge,
    modNaked,
    modSmall,
    modSuccess,
    modWarning,
  });

  const titleContentClasses = getTitleContentClasses({
    modError,
    modNaked,
    modSuccess,
    modWarning,
  });
  const messageClasses = getMessageClasses();
  const nakedClasses = getNakedClasses({
    modError,
    modNaked,
    modSuccess,
    modWarning,
  });

  if (!visible) {
    return null;
  }

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), role: 'alert', ...rest },
    icon ? (
      <div className="vl-alert__icon">
        <SwIcon icon={icon} />
      </div>
    ) : null,
    image ? (
      <figure className="vl-alert__image">
        <img src={image} alt="" role="presentation" />
      </figure>
    ) : null,
    closable ? (
      <button
        data-testid={dataTest.SwAlert}
        className="vl-alert__close"
        type="button"
        onClick={close || defaultCloseFn}
      >
        <SwIcon icon={Icon.CLOSE} />
        <span className="vl-u-visually-hidden">
          {closeText || t(i18nKeys.general.close)}
        </span>
      </button>
    ) : null,
    <div className="vl-alert__content">
      {title && !modNaked
        ? createElement(TitleTagName, { className: 'vl-alert__title' }, title)
        : null}
      {title && modNaked ? (
        <div className="vl-alert__title">
          {createElement(
            TitleTagName,
            { className: titleContentClasses },
            title,
          )}
        </div>
      ) : null}
      <div className={messageClasses}>
        {content ? <p className={nakedClasses}>{content}</p> : null}

        {children}
      </div>
      {actions ? <div className="vl-alert__actions">{actions}</div> : null}
    </div>,
  );
};
