import { BankAccount } from '@kaa/api/customers';
import { Mask } from '@kaa/common/utils';
import { isValidForeignIban } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwFormGrid,
  SwInputTextField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MotionDiv } from '../../../../components/animation/MotionDiv';
import { ProfileScreenFieldNames } from '../ProfileScreen.constants';

const gridMargin = window.innerWidth < 767 ? '1.5rem' : '3rem';
const bicCodeVariants = {
  close: {
    height: 0,
    overflow: 'hidden',
    marginBottom: 0,
    marginTop: 0,
    minWidth: `calc(100% + ${gridMargin})`,
  },
  open: {
    height: 'auto',
    overflow: 'hidden',
    marginTop: 15,
    transitionEnd: {
      overflow: 'inherit',
    },
  },
};

export const ProfileCustomerBankInfoForm = ({
  values,
}: {
  values: {
    refundBankAccount?: BankAccount;
  };
}) => {
  const { t } = useTranslation();

  return (
    <SwFormGrid modStacked>
      <SwTitle tagName="h3" tagStyle="h5">
        {t(i18nKeys.profile.bankDetails.title)}
      </SwTitle>

      <Field
        name={ProfileScreenFieldNames.REFUND_BANK_ACCOUNT.IBAN}
        label={t(i18nKeys.general.label.iban)}
        mask={Mask.iban}
        component={SwInputTextField}
      />

      <MotionDiv
        className="vl-grid vl-u-spacer"
        initial="close"
        isOpen={isValidForeignIban(
          get(values, ProfileScreenFieldNames.REFUND_BANK_ACCOUNT.IBAN),
        )}
        variants={bicCodeVariants}
      >
        <Field
          name={ProfileScreenFieldNames.REFUND_BANK_ACCOUNT.BIC}
          label={t(i18nKeys.general.label.bic)}
          component={SwInputTextField}
        />
      </MotionDiv>
    </SwFormGrid>
  );
};
