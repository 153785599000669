/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type VacancyActionType = 'CAN_DELETE';

export const VacancyActionType = {
  CAN_DELETE: 'CAN_DELETE' as VacancyActionType,
};
