import React, { AllHTMLAttributes, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/common/keys';
import { dataTest } from '../../../datatest/keys';
import { SwActionGroup } from '../../action-group/SwActionGroup';
import { SwButton } from '../../button/SwButton';
import { SwColumn, SwGrid } from '../../core';
import { Icon } from '../../icon/SwIcon.constants';
import { SwLink } from '../../link/SwLink';
import { mergeClassNames } from '../../utilities';

type SwWizarePaneProps<T = HTMLElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  modDisabled?: boolean;
  modLoading?: boolean;
  modSubmit?: boolean;
  buttonText?: string;
  next?: (selectStep: (step: number) => void, activeStep: number) => void;
  previous?: (selectStep: (step: number) => void, activeStep: number) => void;
  isActive?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
};

const getClasses = () => {
  return ['vl-wizard__pane'].join(' ');
};

export const SwWizardPane = <T extends HTMLElement>({
  tagName,
  modDisabled,
  modLoading,
  modSubmit,
  className,
  next,
  previous,
  isActive,
  isFirst,
  isLast,
  children,
  buttonText,
  ...rest
}: SwWizarePaneProps<T>) => {
  const TagName = tagName || 'section';
  const { t } = useTranslation();

  const classes = getClasses();

  if (!isActive) {
    return null;
  }

  return createElement(
    TagName,
    {
      ...rest,
      className: mergeClassNames(classes, className),
      'aria-selected': isActive,
      tabIndex: isActive ? 0 : -1,
    },
    <>
      <SwGrid modStacked>
        <SwColumn data-testid={dataTest.SwWizard}>{children}</SwColumn>
        <SwActionGroup>
          {!isFirst && (
            <SwLink
              tagName="button"
              modIconBefore
              data-testid={dataTest.SwWizardPrev}
              icon={Icon.ARROW_LEFT}
              onClick={previous as () => void}
            >
              <span>{t(i18nKeys.general.previous)}</span>
            </SwLink>
          )}
          {!isLast && (
            <SwButton
              data-testid={dataTest.SwWizardNext}
              type={modSubmit ? 'submit' : 'button'}
              onClick={next as () => void}
              modDisabled={modDisabled}
              modLoading={modLoading}
            >
              <span>{buttonText || t(i18nKeys.general.next)}</span>
            </SwButton>
          )}
        </SwActionGroup>
      </SwGrid>
    </>,
  );
};
