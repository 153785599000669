import { useEffect, useRef } from 'react';

export const useVisuallyHidden = <T extends HTMLElement>() => {
  const domRef = useRef<T>(null);
  useEffect(() => {
    if (!domRef.current) {
      return;
    }
    const baseClass = 'vl-u-visually-hidden';
    domRef.current.classList.add(baseClass);
  }, []);

  return domRef;
};
