import {
  ApiError,
  CodeError,
  Customer,
  getApi,
  Message,
  User,
} from '@kaa/api/customers';
import { createApiError } from '@kaa/api/customers/utilities';
import { AuthAction, useAuthDispatch, useAuthState } from '@kaa/auth/common';
import { StorageKeys } from '@kaa/common/enums';
import { AvailableLanguages } from '@kaa/common/enums/AvailableLanguages';
import { useDeepCompareCallback, useRefMounted } from '@kaa/common/utils';
import { navigate } from '@reach/router';
import { AxiosError } from 'axios';
import get from 'lodash.get';
import { Settings } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../common/config';
import { getPath, pickRoute, Routes } from '../routes';
import { AuthContext } from '../types';
import {
  getAppIdHeader,
  getAuthHeader,
  getCacheHeader,
  getLanguageHeader,
} from './headers';
import { setRedirectUriInSessionStorage } from './setRedirectUriInSessionStorage';

export function useUserState(): User {
  const { user } = useAuthState<AuthContext>();

  if (!user) {
    throw new Error('A user must be define');
  }

  return user;
}

export function useCustomersState(): Customer[] {
  const { customers } = useAuthState<AuthContext>();

  if (!customers) {
    throw new Error('A customers must be define');
  }

  return customers;
}

export function useSelectedCustomerIdState(): string {
  const { selectedCustomerId } = useAuthState<AuthContext>();

  if (!selectedCustomerId) {
    throw new Error('A customer must be selected');
  }

  return selectedCustomerId;
}

export function useSelectedCustomerState(): Customer {
  const { selectedCustomerId, customers } = useAuthState<AuthContext>();

  const selectedCustomer = customers.find(
    ({ id }) => id.toString() === selectedCustomerId,
  );

  if (!selectedCustomer) {
    throw new Error('A customer must be selected');
  }

  return selectedCustomer;
}

export function useSelectedCustomerResourceIdState(): string {
  const selectedCustomer = useSelectedCustomerState();

  return selectedCustomer.resourceId;
}

export function useGlobalMessagesState(): { [key: string]: Message[] } {
  const { messages } = useAuthState<AuthContext>();

  if (!messages) {
    return {};
  }

  return messages;
}

export function useGlobalCustomerMessagesState(): Message[] | undefined {
  const messages = useGlobalMessagesState();
  const selectedCustomerResourceId = useSelectedCustomerResourceIdState();

  return messages[selectedCustomerResourceId];
}

export function useDispatchUpdateCustomer() {
  const customers = useCustomersState();
  const dispatchAuth = useAuthDispatch<AuthContext>();

  return useDeepCompareCallback(
    (customer: Customer) =>
      dispatchAuth({
        type: AuthAction.UPDATE,
        payload: {
          customers: [
            ...customers.filter(({ id }) => customer.id !== id),
            customer,
          ],
        },
      }),
    [customers, dispatchAuth],
  );
}

export function useDispatchUpdateUserEmail() {
  const dispatchAuth = useAuthDispatch<AuthContext>();
  const user = useUserState();

  return (emailAddress: string) =>
    dispatchAuth({
      type: AuthAction.UPDATE,
      payload: { user: { ...user, emailAddress } },
    });
}

export function useDispatchUpdateSelectedCustomer() {
  const dispatchAuth = useAuthDispatch<AuthContext>();

  return (customerId: string | number) => {
    localStorage.setItem(
      StorageKeys.LAST_SELECTED_CUSTOMER_ID,
      customerId.toString(),
    );
    dispatchAuth({
      type: AuthAction.UPDATE,
      payload: {
        selectedCustomerId: customerId.toString(),
      },
    });
  };
}

export function useDispatchUpdateMessages() {
  const dispatchAuth = useAuthDispatch<AuthContext>();
  const currentMessages = useGlobalMessagesState();

  return useDeepCompareCallback(
    (messages: { [key: string]: Message[] }) =>
      dispatchAuth({
        type: AuthAction.UPDATE,
        payload: {
          messages: {
            ...currentMessages,
            ...messages,
          },
        },
      }),
    [currentMessages, dispatchAuth],
  );
}

export const useApi = () => {
  const { authUser } = useAuthState<AuthContext>();
  const { language } = useLanguage();
  const dispatch = useAuthDispatch();
  const mounted = useRefMounted();

  const headers = useMemo(
    () => ({
      ...getAuthHeader(authUser),
      ...getLanguageHeader(language),
      ...getAppIdHeader(),
      ...getCacheHeader(),
    }),
    [authUser, language],
  );

  const unauthorizedAndErrorInterceptor = useMemo(
    () => ({
      response: {
        onRejected(error: AxiosError): Promise<unknown> | void {
          if (!mounted.current) {
            return;
          }
          const { response } = error;

          if (!response) {
            navigate(getPath(Routes.MAINTENANCE));
            return Promise.reject(createApiError());
          }

          if (response.status === 401) {
            sessionStorage.clear();
            dispatch({
              type: AuthAction.RESET,
            });
            setRedirectUriInSessionStorage();
            navigate(get(getConfig(), 'buildConfig.unauthorizedRedirectUrl'));
            return Promise.reject(createApiError());
          }

          if (response.status === 502) {
            getApi({
              headers,
              baseUrl,
            })
              .customers.liveness()
              .catch((resp) => {
                if (!resp || resp.status === 502) {
                  navigate(getPath(Routes.MAINTENANCE));
                }
              });
            return Promise.reject(createApiError());
          }

          const { data } = response;

          if (data.code === CodeError.NO_VALID_CONTRACT) {
            navigate(getPath(Routes.FORBIDDEN_WRONG_REGION));
            return Promise.reject(createApiError());
          }

          if (data.code === CodeError.ENTITY_NOT_FOUND) {
            const activeRoute = pickRoute(location.pathname);
            if (activeRoute?.route.parent?.id) {
              navigate(getPath(activeRoute?.route.parent?.id));
            }
            return Promise.reject(createApiError());
          }

          if (data.code === CodeError.INVALID_REQUEST_SYNTAX) {
            navigate(getPath(Routes.SERVICE_UNAVAILABLE));
            return Promise.reject(createApiError());
          }

          if (
            data.code === CodeError.QUOTA_EXCEEDED &&
            response.status === 500
          ) {
            return Promise.reject(createApiError());
          }

          if (response.status >= 500) {
            dispatch({
              type: AuthAction.RESET,
            });
            navigate(getPath(Routes.SERVICE_UNAVAILABLE));
            return Promise.reject(createApiError());
          }

          if (
            data.code === CodeError.VALIDATION_EXCEPTION &&
            !(data as ApiError).validations
          ) {
            return Promise.reject(createApiError());
          }

          if (!data) {
            return Promise.reject(createApiError());
          }

          const ApiError = data;

          if (!ApiError.code && !ApiError.message) {
            return Promise.reject(createApiError());
          }

          return Promise.reject(data);
        },
      },
    }),
    [],
  );

  const kaaConfig = getConfig();
  const baseUrl = get(kaaConfig, 'app.apiUrl');
  const apiMode = get(kaaConfig, 'app.mode');

  return useMemo(
    () =>
      getApi({
        headers,
        interceptor: unauthorizedAndErrorInterceptor,
        baseUrl,
        mode: apiMode,
      }),
    [headers, unauthorizedAndErrorInterceptor, baseUrl, apiMode],
  );
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<AvailableLanguages>(
    i18n.language as AvailableLanguages,
  );
  const { selectedCustomerId, customers } = useAuthState<AuthContext>();

  const changeLanguage = useCallback(
    (lng: AvailableLanguages) => {
      i18n.changeLanguage(lng);
      Settings.defaultLocale = lng;
      if (customers && selectedCustomerId) {
        localStorage.setItem(StorageKeys.LAST_SELECTED_LANGUAGE, lng);
      }
    },
    [i18n, selectedCustomerId, customers],
  );

  useEffect(() => {
    setLanguage(i18n.language as AvailableLanguages);
  }, [i18n.language]);

  useEffect(() => {
    document.documentElement.setAttribute('lang', language);
  }, [language]);

  return { language, changeLanguage };
};
