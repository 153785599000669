import { SwColumn, SwIntroduction, SwTitle } from '@kaa/ui-flanders/components';
import React, { ReactNode } from 'react';
import './PageHeaderIntro.style.scss';

type PageHeaderIntroProps = {
  title?: string;
  className?: string;
  introduction?: string | ReactNode;
  alert?: ReactNode;
};

export const PageHeaderIntro = ({
  title,
  introduction,
  className,
}: PageHeaderIntroProps) => {
  return (
    <>
      {(title || introduction) && (
        <SwColumn width="10" widthS="12" className={className}>
          {title && (
            <SwTitle tagName="h1" className="vl-u-spacer--medium">
              {title}
            </SwTitle>
          )}
          {introduction && (
            <SwIntroduction tagName="p">{introduction}</SwIntroduction>
          )}
        </SwColumn>
      )}
    </>
  );
};
