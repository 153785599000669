import React, { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type IconListProps = AllHTMLAttributes<HTMLDivElement> & {
  tagName?: string;
  modSmall?: boolean;
};

type Classes = {
  modSmall?: boolean;
};

const getClasses = ({ modSmall }: Classes) => {
  return ['vl-icon-list', modSmall ? 'vl-icon-list--small' : ''].join(' ');
};

export const SwIconList = <T extends HTMLElement>({
  tagName,
  modSmall,
  children,
  className,
  ...rest
}: IconListProps) => {
  const TagName = tagName || 'ul';

  const classes = getClasses({ modSmall });
  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    <>{children}</>,
  );
};
