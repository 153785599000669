export const POSTCODES_CITIES = [
  { postcode: '0612', names: ['Saint-Nicolas'] },
  { postcode: '1000', names: ['Bruxelles'] },
  {
    postcode: '1005',
    names: ['Assemblée Réunie de la Commission Communautaire'],
  },
  { postcode: '1006', names: ['Raad van de Vlaamse Gemeenschapscommissie'] },
  {
    postcode: '1007',
    names: ['Assemblée de la Commission Communautaire Française'],
  },
  { postcode: '1008', names: ['Chambre des Représentants'] },
  { postcode: '1009', names: ['Senat de Belgique'] },
  { postcode: '1011', names: ['Vlaams parlement'] },
  { postcode: '1012', names: ['Parlement de la Communauté française'] },
  { postcode: '1020', names: ['Laeken'] },
  { postcode: '1030', names: ['Schaerbeek'] },
  { postcode: '1031', names: ['Organisations Sociales Chrétiennes'] },
  { postcode: '1033', names: ['RTL-TVI'] },
  {
    postcode: '1035',
    names: ['Ministère de la Région de Bruxelles Capitale'],
  },
  { postcode: '1040', names: ['Etterbeek'] },
  { postcode: '1041', names: ['International Press Center'] },
  { postcode: '1043', names: ['VRT'] },
  { postcode: '1044', names: ['RTBF'] },
  { postcode: '1046', names: ['European External Action Service'] },
  { postcode: '1047', names: ['Parlement Européen'] },
  { postcode: '1048', names: ['Union Européenne - Conseil'] },
  { postcode: '1049', names: ['Union Européenne - Commission'] },
  { postcode: '1050', names: ['Ixelles'] },
  { postcode: '1060', names: ['Saint-Gilles'] },
  { postcode: '1070', names: ['Anderlecht'] },
  { postcode: '1080', names: ['Molenbeek-Saint-Jean'] },
  { postcode: '1081', names: ['Koekelberg'] },
  { postcode: '1082', names: ['Berchem-Sainte-Agathe'] },
  { postcode: '1083', names: ['Ganshoren'] },
  { postcode: '1090', names: ['Jette'] },
  { postcode: '1099', names: ['Bruxelles X'] },
  { postcode: '1100', names: ['Postcheque'] },
  { postcode: '1101', names: ['Scanning'] },
  { postcode: '1105', names: ['ACTISOC'] },
  { postcode: '1110', names: ['OTAN'] },
  { postcode: '1120', names: ['Neder-Over-Heembeek'] },
  { postcode: '1130', names: ['Haren'] },
  { postcode: '1140', names: ['Evere'] },
  { postcode: '1150', names: ['Woluwe-Saint-Pierre'] },
  { postcode: '1160', names: ['Auderghem'] },
  { postcode: '1170', names: ['Watermael-Boitsfort'] },
  { postcode: '1180', names: ['Uccle'] },
  { postcode: '1190', names: ['Forest'] },
  { postcode: '1200', names: ['Woluwe-Saint-Lambert'] },
  { postcode: '1210', names: ['Saint-Josse-Ten-Noode'] },
  { postcode: '1212', names: ['SPF Mobilité'] },
  { postcode: '1300', names: ['Limal'] },
  { postcode: '1300', names: ['Wavre'] },
  { postcode: '1301', names: ['Bierges'] },
  { postcode: '1310', names: ['La Hulpe'] },
  { postcode: '1315', names: ['Glimes'] },
  { postcode: '1315', names: ['Incourt'] },
  { postcode: '1315', names: ['Opprebais'] },
  { postcode: '1315', names: ['Piétrebais'] },
  { postcode: '1315', names: ['Roux-Miroir'] },
  { postcode: '1320', names: ['Beauvechain'] },
  { postcode: '1320', names: ['Hamme-Mille'] },
  { postcode: '1320', names: ["L'Ecluse"] },
  { postcode: '1320', names: ['Nodebais'] },
  { postcode: '1320', names: ['Tourinnes-La-Grosse'] },
  { postcode: '1325', names: ['Bonlez'] },
  { postcode: '1325', names: ['Chaumont-Gistoux'] },
  { postcode: '1325', names: ['Corroy-Le-Grand'] },
  { postcode: '1325', names: ['Dion-Valmont'] },
  { postcode: '1325', names: ['Longueville'] },
  { postcode: '1330', names: ['Rixensart'] },
  { postcode: '1331', names: ['Rosières'] },
  { postcode: '1332', names: ['Genval'] },
  { postcode: '1340', names: ['Ottignies'] },
  { postcode: '1341', names: ['Céroux-Mousty'] },
  { postcode: '1342', names: ['Limelette'] },
  { postcode: '1348', names: ['Louvain-La-Neuve'] },
  { postcode: '1350', names: ['Enines'] },
  { postcode: '1350', names: ['Folx-Les-Caves'] },
  { postcode: '1350', names: ['Jandrain-Jandrenouille'] },
  { postcode: '1350', names: ['Jauche'] },
  { postcode: '1350', names: ['Marilles'] },
  { postcode: '1350', names: ['Noduwez'] },
  { postcode: '1350', names: ['Orp-Le-Grand'] },
  { postcode: '1357', names: ['Linsmeau'] },
  { postcode: '1357', names: ['Neerheylissem'] },
  { postcode: '1357', names: ['Opheylissem'] },
  { postcode: '1360', names: ['Malèves-Sainte-Marie-Wastinnes'] },
  { postcode: '1360', names: ['Orbais'] },
  { postcode: '1360', names: ['Perwez'] },
  { postcode: '1360', names: ['Thorembais-Les-Béguines'] },
  { postcode: '1360', names: ['Thorembais-Saint-Trond'] },
  { postcode: '1367', names: ['Autre-Eglise'] },
  { postcode: '1367', names: ['Bomal'] },
  { postcode: '1367', names: ['Geest-Gérompont-Petit-Rosière'] },
  { postcode: '1367', names: ['Gérompont'] },
  { postcode: '1367', names: ['Grand-Rosière-Hottomont'] },
  { postcode: '1367', names: ['Huppaye'] },
  { postcode: '1367', names: ['Mont-Saint-André'] },
  { postcode: '1367', names: ['Ramillies'] },
  { postcode: '1370', names: ['Dongelberg'] },
  { postcode: '1370', names: ['Jauchelette'] },
  { postcode: '1370', names: ['Jodoigne'] },
  { postcode: '1370', names: ['Jodoigne-Souveraine'] },
  { postcode: '1370', names: ['Lathuy'] },
  { postcode: '1370', names: ['Mélin'] },
  { postcode: '1370', names: ['Piétrain'] },
  { postcode: '1370', names: ['Saint-Jean-Geest'] },
  { postcode: '1370', names: ['Saint-Remy-Geest'] },
  { postcode: '1370', names: ['Zétrud-Lumay'] },
  { postcode: '1380', names: ['Couture-Saint-Germain'] },
  { postcode: '1380', names: ['Lasne-Chapelle-Saint-Lambert'] },
  { postcode: '1380', names: ['Maransart'] },
  { postcode: '1380', names: ['Ohain'] },
  { postcode: '1380', names: ['Plancenoit'] },
  { postcode: '1390', names: ['Archennes'] },
  { postcode: '1390', names: ['Biez'] },
  { postcode: '1390', names: ['Bossut-Gottechain'] },
  { postcode: '1390', names: ['Grez-Doiceau'] },
  { postcode: '1390', names: ['Nethen'] },
  { postcode: '1400', names: ['Monstreux'] },
  { postcode: '1400', names: ['Nivelles'] },
  { postcode: '1401', names: ['Baulers'] },
  { postcode: '1402', names: ['Thines'] },
  { postcode: '1404', names: ['Bornival'] },
  { postcode: '1410', names: ['Waterloo'] },
  { postcode: '1420', names: ["Braine-L'Alleud"] },
  { postcode: '1421', names: ['Ophain-Bois-Seigneur-Isaac'] },
  { postcode: '1428', names: ['Lillois-Witterzée'] },
  { postcode: '1430', names: ['Bierghes'] },
  { postcode: '1430', names: ['Quenast'] },
  { postcode: '1430', names: ['Rebecq-Rognon'] },
  { postcode: '1435', names: ['Corbais'] },
  { postcode: '1435', names: ['Hévillers'] },
  { postcode: '1435', names: ['Mont-Saint-Guibert'] },
  { postcode: '1440', names: ['Braine-Le-Château'] },
  { postcode: '1440', names: ['Wauthier-Braine'] },
  { postcode: '1450', names: ['Chastre-Villeroux-Blanmont'] },
  { postcode: '1450', names: ['Cortil-Noirmont'] },
  { postcode: '1450', names: ['Gentinnes'] },
  { postcode: '1450', names: ['Saint-Géry'] },
  { postcode: '1457', names: ['Nil-Saint-Vincent-Saint-Martin'] },
  { postcode: '1457', names: ['Tourinnes-Saint-Lambert'] },
  { postcode: '1457', names: ['Walhain-Saint-Paul'] },
  { postcode: '1460', names: ['Ittre'] },
  { postcode: '1460', names: ['Virginal-Samme'] },
  { postcode: '1461', names: ['Haut-Ittre'] },
  { postcode: '1470', names: ['Baisy-Thy'] },
  { postcode: '1470', names: ['Bousval'] },
  { postcode: '1470', names: ['Genappe'] },
  { postcode: '1471', names: ['Loupoigne'] },
  { postcode: '1472', names: ['Vieux-Genappe'] },
  { postcode: '1473', names: ['Glabais'] },
  { postcode: '1474', names: ['Ways'] },
  { postcode: '1476', names: ['Houtain-Le-Val'] },
  { postcode: '1480', names: ['Clabecq'] },
  { postcode: '1480', names: ['Oisquercq'] },
  { postcode: '1480', names: ['Saintes'] },
  { postcode: '1480', names: ['Tubize'] },
  { postcode: '1490', names: ['Court-Saint-Etienne'] },
  { postcode: '1495', names: ['Marbais'] },
  { postcode: '1495', names: ['Mellery'] },
  { postcode: '1495', names: ['Sart-Dames-Avelines'] },
  { postcode: '1495', names: ['Tilly'] },
  { postcode: '1495', names: ['Villers-La-Ville'] },
  { postcode: '1500', names: ['Halle'] },
  { postcode: '1501', names: ['Buizingen'] },
  { postcode: '1502', names: ['Lembeek'] },
  { postcode: '1540', names: ['Herfelingen'] },
  { postcode: '1540', names: ['Herne'] },
  { postcode: '1541', names: ['Sint-Pieters-Kapelle'] },
  { postcode: '1547', names: ['Biévène'] },
  { postcode: '1560', names: ['Hoeilaart'] },
  { postcode: '1570', names: ['Galmaarden'] },
  { postcode: '1570', names: ['Tollembeek'] },
  { postcode: '1570', names: ['Vollezele'] },
  { postcode: '1600', names: ['Oudenaken'] },
  { postcode: '1600', names: ['Sint-Laureins-Berchem'] },
  { postcode: '1600', names: ['Sint-Pieters-Leeuw'] },
  { postcode: '1601', names: ['Ruisbroek'] },
  { postcode: '1602', names: ['Vlezenbeek'] },
  { postcode: '1620', names: ['Drogenbos'] },
  { postcode: '1630', names: ['Linkebeek'] },
  { postcode: '1640', names: ['Rhode-Saint-Genèse'] },
  { postcode: '1650', names: ['Beersel'] },
  { postcode: '1651', names: ['Lot'] },
  { postcode: '1652', names: ['Alsemberg'] },
  { postcode: '1653', names: ['Dworp'] },
  { postcode: '1654', names: ['Huizingen'] },
  { postcode: '1670', names: ['Bogaarden'] },
  { postcode: '1670', names: ['Heikruis'] },
  { postcode: '1670', names: ['Pepingen'] },
  { postcode: '1671', names: ['Elingen'] },
  { postcode: '1673', names: ['Beert'] },
  { postcode: '1674', names: ['Bellingen'] },
  { postcode: '1700', names: ['Dilbeek'] },
  { postcode: '1700', names: ['Sint-Martens-Bodegem'] },
  { postcode: '1700', names: ['Sint-Ulriks-Kapelle'] },
  { postcode: '1701', names: ['Itterbeek'] },
  { postcode: '1702', names: ['Groot-Bijgaarden'] },
  { postcode: '1703', names: ['Schepdaal'] },
  { postcode: '1730', names: ['Asse'] },
  { postcode: '1730', names: ['Bekkerzeel'] },
  { postcode: '1730', names: ['Kobbegem'] },
  { postcode: '1730', names: ['Mollem'] },
  { postcode: '1731', names: ['Relegem'] },
  { postcode: '1731', names: ['Zellik'] },
  { postcode: '1733', names: ['HighCo DATA'] },
  { postcode: '1740', names: ['Ternat'] },
  { postcode: '1741', names: ['Wambeek'] },
  { postcode: '1742', names: ['Sint-Katherina-Lombeek'] },
  { postcode: '1745', names: ['Mazenzele'] },
  { postcode: '1745', names: ['Opwijk'] },
  { postcode: '1750', names: ['Gaasbeek'] },
  { postcode: '1750', names: ['Sint-Kwintens-Lennik'] },
  { postcode: '1750', names: ['Sint-Martens-Lennik'] },
  { postcode: '1755', names: ['Gooik'] },
  { postcode: '1755', names: ['Kester'] },
  { postcode: '1755', names: ['Leerbeek'] },
  { postcode: '1755', names: ['Oetingen'] },
  { postcode: '1760', names: ['Onze-Lieve-Vrouw-Lombeek'] },
  { postcode: '1760', names: ['Pamel'] },
  { postcode: '1760', names: ['Roosdaal'] },
  { postcode: '1760', names: ['Strijtem'] },
  { postcode: '1761', names: ['Borchtlombeek'] },
  { postcode: '1770', names: ['Liedekerke'] },
  { postcode: '1780', names: ['Wemmel'] },
  { postcode: '1785', names: ['Brussegem'] },
  { postcode: '1785', names: ['Hamme'] },
  { postcode: '1785', names: ['Merchtem'] },
  { postcode: '1790', names: ['Affligem'] },
  { postcode: '1790', names: ['Essene'] },
  { postcode: '1790', names: ['Hekelgem'] },
  { postcode: '1790', names: ['Teralfene'] },
  { postcode: '1800', names: ['Peutie'] },
  { postcode: '1800', names: ['Vilvoorde'] },
  { postcode: '1804', names: ['Cargovil'] },
  { postcode: '1818', names: ['VTM'] },
  { postcode: '1820', names: ['Melsbroek'] },
  { postcode: '1820', names: ['Perk'] },
  { postcode: '1820', names: ['Steenokkerzeel'] },
  { postcode: '1830', names: ['Machelen'] },
  { postcode: '1831', names: ['Diegem'] },
  { postcode: '1840', names: ['Londerzeel'] },
  { postcode: '1840', names: ['Malderen'] },
  { postcode: '1840', names: ['Steenhuffel'] },
  { postcode: '1850', names: ['Grimbergen'] },
  { postcode: '1851', names: ['Humbeek'] },
  { postcode: '1852', names: ['Beigem'] },
  { postcode: '1853', names: ['Strombeek-Bever'] },
  { postcode: '1860', names: ['Meise'] },
  { postcode: '1861', names: ['Wolvertem'] },
  { postcode: '1880', names: ['Kapelle-Op-Den-Bos'] },
  { postcode: '1880', names: ['Nieuwenrode'] },
  { postcode: '1880', names: ['Ramsdonk'] },
  { postcode: '1910', names: ['Berg'] },
  { postcode: '1910', names: ['Buken'] },
  { postcode: '1910', names: ['Kampenhout'] },
  { postcode: '1910', names: ['Nederokkerzeel'] },
  { postcode: '1930', names: ['Nossegem'] },
  { postcode: '1930', names: ['Zaventem'] },
  { postcode: '1931', names: ['Brucargo'] },
  { postcode: '1932', names: ['Sint-Stevens-Woluwe'] },
  { postcode: '1933', names: ['Sterrebeek'] },
  { postcode: '1934', names: ['Office Exchange Brussels Airport Remailing'] },
  { postcode: '1935', names: ['Corporate Village'] },
  { postcode: '1950', names: ['Kraainem'] },
  { postcode: '1970', names: ['Wezembeek-Oppem'] },
  { postcode: '1980', names: ['Eppegem'] },
  { postcode: '1980', names: ['Zemst'] },
  { postcode: '1981', names: ['Hofstade'] },
  { postcode: '1982', names: ['Elewijt'] },
  { postcode: '1982', names: ['Weerde'] },
  { postcode: '2000', names: ['Antwerpen'] },
  { postcode: '2018', names: ['Antwerpen'] },
  { postcode: '2020', names: ['Antwerpen'] },
  { postcode: '2030', names: ['Antwerpen'] },
  { postcode: '2040', names: ['Antwerpen'] },
  { postcode: '2040', names: ['Berendrecht'] },
  { postcode: '2040', names: ['Lillo'] },
  { postcode: '2040', names: ['Zandvliet'] },
  { postcode: '2050', names: ['Antwerpen'] },
  { postcode: '2060', names: ['Antwerpen'] },
  { postcode: '2070', names: ['Burcht'] },
  { postcode: '2070', names: ['Zwijndrecht'] },
  { postcode: '2099', names: ['Antwerpen x'] },
  { postcode: '2100', names: ['Deurne'] },
  { postcode: '2110', names: ['Wijnegem'] },
  { postcode: '2140', names: ['Borgerhout'] },
  { postcode: '2150', names: ['Borsbeek'] },
  { postcode: '2160', names: ['Wommelgem'] },
  { postcode: '2170', names: ['Merksem'] },
  { postcode: '2180', names: ['Ekeren'] },
  { postcode: '2200', names: ['Herentals'] },
  { postcode: '2200', names: ['Morkhoven'] },
  { postcode: '2200', names: ['Noorderwijk'] },
  { postcode: '2220', names: ['Hallaar'] },
  { postcode: '2220', names: ['Heist-Op-Den-Berg'] },
  { postcode: '2221', names: ['Booischot'] },
  { postcode: '2222', names: ['Itegem'] },
  { postcode: '2222', names: ['Wiekevorst'] },
  { postcode: '2223', names: ['Schriek'] },
  { postcode: '2230', names: ['Herselt'] },
  { postcode: '2230', names: ['Ramsel'] },
  { postcode: '2235', names: ['Houtvenne'] },
  { postcode: '2235', names: ['Hulshout'] },
  { postcode: '2235', names: ['Westmeerbeek'] },
  { postcode: '2240', names: ['Massenhoven'] },
  { postcode: '2240', names: ['Viersel'] },
  { postcode: '2240', names: ['Zandhoven'] },
  { postcode: '2242', names: ['Pulderbos'] },
  { postcode: '2243', names: ['Pulle'] },
  { postcode: '2250', names: ['Olen'] },
  { postcode: '2260', names: ['Oevel'] },
  { postcode: '2260', names: ['Tongerlo'] },
  { postcode: '2260', names: ['Westerlo'] },
  { postcode: '2260', names: ['Zoerle-Parwijs'] },
  { postcode: '2270', names: ['Herenthout'] },
  { postcode: '2275', names: ['Gierle'] },
  { postcode: '2275', names: ['Lille'] },
  { postcode: '2275', names: ['Poederlee'] },
  { postcode: '2275', names: ['Wechelderzande'] },
  { postcode: '2280', names: ['Grobbendonk'] },
  { postcode: '2288', names: ['Bouwel'] },
  { postcode: '2290', names: ['Vorselaar'] },
  { postcode: '2300', names: ['Turnhout'] },
  { postcode: '2310', names: ['Rijkevorsel'] },
  { postcode: '2320', names: ['Hoogstraten'] },
  { postcode: '2321', names: ['Meer'] },
  { postcode: '2322', names: ['Minderhout'] },
  { postcode: '2323', names: ['Wortel'] },
  { postcode: '2328', names: ['Meerle'] },
  { postcode: '2330', names: ['Merksplas'] },
  { postcode: '2340', names: ['Beerse'] },
  { postcode: '2340', names: ['Vlimmeren'] },
  { postcode: '2350', names: ['Vosselaar'] },
  { postcode: '2360', names: ['Oud-Turnhout'] },
  { postcode: '2370', names: ['Arendonk'] },
  { postcode: '2380', names: ['Ravels'] },
  { postcode: '2381', names: ['Weelde'] },
  { postcode: '2382', names: ['Poppel'] },
  { postcode: '2387', names: ['Baarle-Hertog'] },
  { postcode: '2390', names: ['Malle'] },
  { postcode: '2390', names: ['Oostmalle'] },
  { postcode: '2390', names: ['Westmalle'] },
  { postcode: '2400', names: ['Mol'] },
  { postcode: '2430', names: ['Eindhout'] },
  { postcode: '2430', names: ['Vorst'] },
  { postcode: '2431', names: ['Varendonk'] },
  { postcode: '2431', names: ['Veerle'] },
  { postcode: '2440', names: ['Geel'] },
  { postcode: '2450', names: ['Meerhout'] },
  { postcode: '2460', names: ['Kasterlee'] },
  { postcode: '2460', names: ['Lichtaart'] },
  { postcode: '2460', names: ['Tielen'] },
  { postcode: '2470', names: ['Retie'] },
  { postcode: '2480', names: ['Dessel'] },
  { postcode: '2490', names: ['Balen'] },
  { postcode: '2491', names: ['Olmen'] },
  { postcode: '2500', names: ['Koningshooikt'] },
  { postcode: '2500', names: ['Lier'] },
  { postcode: '2520', names: ['Broechem'] },
  { postcode: '2520', names: ['Emblem'] },
  { postcode: '2520', names: ['Oelegem'] },
  { postcode: '2520', names: ['Ranst'] },
  { postcode: '2530', names: ['Boechout'] },
  { postcode: '2531', names: ['Vremde'] },
  { postcode: '2540', names: ['Hove'] },
  { postcode: '2547', names: ['Lint'] },
  { postcode: '2550', names: ['Kontich'] },
  { postcode: '2550', names: ['Waarloos'] },
  { postcode: '2560', names: ['Bevel'] },
  { postcode: '2560', names: ['Kessel'] },
  { postcode: '2560', names: ['Nijlen'] },
  { postcode: '2570', names: ['Duffel'] },
  { postcode: '2580', names: ['Beerzel'] },
  { postcode: '2580', names: ['Putte'] },
  { postcode: '2590', names: ['Berlaar'] },
  { postcode: '2590', names: ['Gestel'] },
  { postcode: '2600', names: ['Berchem'] },
  { postcode: '2610', names: ['Wilrijk'] },
  { postcode: '2620', names: ['Hemiksem'] },
  { postcode: '2627', names: ['Schelle'] },
  { postcode: '2630', names: ['Aartselaar'] },
  { postcode: '2640', names: ['Mortsel'] },
  { postcode: '2650', names: ['Edegem'] },
  { postcode: '2660', names: ['Hoboken'] },
  { postcode: '2800', names: ['Mechelen'] },
  { postcode: '2800', names: ['Walem'] },
  { postcode: '2801', names: ['Heffen'] },
  { postcode: '2811', names: ['Hombeek'] },
  { postcode: '2811', names: ['Leest'] },
  { postcode: '2812', names: ['Muizen'] },
  { postcode: '2820', names: ['Bonheiden'] },
  { postcode: '2820', names: ['Rijmenam'] },
  { postcode: '2830', names: ['Blaasveld'] },
  { postcode: '2830', names: ['Heindonk'] },
  { postcode: '2830', names: ['Tisselt'] },
  { postcode: '2830', names: ['Willebroek'] },
  { postcode: '2840', names: ['Reet'] },
  { postcode: '2840', names: ['Rumst'] },
  { postcode: '2840', names: ['Terhagen'] },
  { postcode: '2845', names: ['Niel'] },
  { postcode: '2850', names: ['Boom'] },
  { postcode: '2860', names: ['Sint-Katelijne-Waver'] },
  { postcode: '2861', names: ['Onze-Lieve-Vrouw-Waver'] },
  { postcode: '2870', names: ['Breendonk'] },
  { postcode: '2870', names: ['Liezele'] },
  { postcode: '2870', names: ['Puurs'] },
  { postcode: '2870', names: ['Ruisbroek'] },
  { postcode: '2880', names: ['Bornem'] },
  { postcode: '2880', names: ['Hingene'] },
  { postcode: '2880', names: ['Mariekerke'] },
  { postcode: '2880', names: ['Weert'] },
  { postcode: '2890', names: ['Lippelo'] },
  { postcode: '2890', names: ['Oppuurs'] },
  { postcode: '2890', names: ['Sint-Amands'] },
  { postcode: '2900', names: ['Schoten'] },
  { postcode: '2910', names: ['Essen'] },
  { postcode: '2920', names: ['Kalmthout'] },
  { postcode: '2930', names: ['Brasschaat'] },
  { postcode: '2940', names: ['Hoevenen'] },
  { postcode: '2940', names: ['Stabroek'] },
  { postcode: '2950', names: ['Kapellen'] },
  { postcode: '2960', names: ['Brecht'] },
  { postcode: '2960', names: ["Sint-Job-In-'T-Goor"] },
  { postcode: '2960', names: ['Sint-Lenaarts'] },
  { postcode: '2970', names: ["'S Gravenwezel"] },
  { postcode: '2970', names: ['Schilde'] },
  { postcode: '2980', names: ['Halle'] },
  { postcode: '2980', names: ['Zoersel'] },
  { postcode: '2990', names: ['Loenhout'] },
  { postcode: '2990', names: ['Wuustwezel'] },
  { postcode: '3000', names: ['Leuven'] },
  { postcode: '3001', names: ['Heverlee'] },
  { postcode: '3010', names: ['Kessel Lo'] },
  { postcode: '3012', names: ['Wilsele'] },
  { postcode: '3018', names: ['Wijgmaal'] },
  { postcode: '3020', names: ['Herent'] },
  { postcode: '3020', names: ['Veltem-Beisem'] },
  { postcode: '3020', names: ['Winksele'] },
  { postcode: '3040', names: ['Huldenberg'] },
  { postcode: '3040', names: ['Loonbeek'] },
  { postcode: '3040', names: ['Neerijse'] },
  { postcode: '3040', names: ['Ottenburg'] },
  { postcode: '3040', names: ['Sint-Agatha-Rode'] },
  { postcode: '3050', names: ['Oud-Heverlee'] },
  { postcode: '3051', names: ['Sint-Joris-Weert'] },
  { postcode: '3052', names: ['Blanden'] },
  { postcode: '3053', names: ['Haasrode'] },
  { postcode: '3054', names: ['Vaalbeek'] },
  { postcode: '3060', names: ['Bertem'] },
  { postcode: '3060', names: ['Korbeek-Dijle'] },
  { postcode: '3061', names: ['Leefdaal'] },
  { postcode: '3070', names: ['Kortenberg'] },
  { postcode: '3071', names: ['Erps-Kwerps'] },
  { postcode: '3078', names: ['Everberg'] },
  { postcode: '3078', names: ['Meerbeek'] },
  { postcode: '3080', names: ['Duisburg'] },
  { postcode: '3080', names: ['Tervuren'] },
  { postcode: '3080', names: ['Vossem'] },
  { postcode: '3090', names: ['Overijse'] },
  { postcode: '3110', names: ['Rotselaar'] },
  { postcode: '3111', names: ['Wezemaal'] },
  { postcode: '3118', names: ['Werchter'] },
  { postcode: '3120', names: ['Tremelo'] },
  { postcode: '3128', names: ['Baal'] },
  { postcode: '3130', names: ['Begijnendijk'] },
  { postcode: '3130', names: ['Betekom'] },
  { postcode: '3140', names: ['Keerbergen'] },
  { postcode: '3150', names: ['Haacht'] },
  { postcode: '3150', names: ['Tildonk'] },
  { postcode: '3150', names: ['Wespelaar'] },
  { postcode: '3190', names: ['Boortmeerbeek'] },
  { postcode: '3191', names: ['Hever'] },
  { postcode: '3200', names: ['Aarschot'] },
  { postcode: '3200', names: ['Gelrode'] },
  { postcode: '3201', names: ['Langdorp'] },
  { postcode: '3202', names: ['Rillaar'] },
  { postcode: '3210', names: ['Linden'] },
  { postcode: '3210', names: ['Lubbeek'] },
  { postcode: '3211', names: ['Binkom'] },
  { postcode: '3212', names: ['Pellenberg'] },
  { postcode: '3220', names: ['Holsbeek'] },
  { postcode: '3220', names: ['Kortrijk-Dutsel'] },
  { postcode: '3220', names: ['Sint-Pieters-Rode'] },
  { postcode: '3221', names: ['Nieuwrode'] },
  { postcode: '3270', names: ['Scherpenheuvel'] },
  { postcode: '3271', names: ['Averbode'] },
  { postcode: '3271', names: ['Zichem'] },
  { postcode: '3272', names: ['Messelbroek'] },
  { postcode: '3272', names: ['Testelt'] },
  { postcode: '3290', names: ['Deurne'] },
  { postcode: '3290', names: ['Diest'] },
  { postcode: '3290', names: ['Schaffen'] },
  { postcode: '3290', names: ['Webbekom'] },
  { postcode: '3293', names: ['Kaggevinne'] },
  { postcode: '3294', names: ['Molenstede'] },
  { postcode: '3300', names: ['Bost'] },
  { postcode: '3300', names: ['Goetsenhoven'] },
  { postcode: '3300', names: ['Hakendover'] },
  { postcode: '3300', names: ['Kumtich'] },
  { postcode: '3300', names: ['Oorbeek'] },
  { postcode: '3300', names: ['Oplinter'] },
  { postcode: '3300', names: ['Sint-Margriete-Houtem'] },
  { postcode: '3300', names: ['Tienen'] },
  { postcode: '3300', names: ['Vissenaken'] },
  { postcode: '3320', names: ['Hoegaarden'] },
  { postcode: '3320', names: ['Meldert'] },
  { postcode: '3321', names: ['Outgaarden'] },
  { postcode: '3350', names: ['Drieslinter'] },
  { postcode: '3350', names: ['Linter'] },
  { postcode: '3350', names: ['Melkwezer'] },
  { postcode: '3350', names: ['Neerhespen'] },
  { postcode: '3350', names: ['Neerlinter'] },
  { postcode: '3350', names: ['Orsmaal-Gussenhoven'] },
  { postcode: '3350', names: ['Overhespen'] },
  { postcode: '3350', names: ['Wommersom'] },
  { postcode: '3360', names: ['Bierbeek'] },
  { postcode: '3360', names: ['Korbeek-Lo'] },
  { postcode: '3360', names: ['Lovenjoel'] },
  { postcode: '3360', names: ['Opvelp'] },
  { postcode: '3370', names: ['Boutersem'] },
  { postcode: '3370', names: ['Kerkom'] },
  { postcode: '3370', names: ['Neervelp'] },
  { postcode: '3370', names: ['Roosbeek'] },
  { postcode: '3370', names: ['Vertrijk'] },
  { postcode: '3370', names: ['Willebringen'] },
  { postcode: '3380', names: ['Bunsbeek'] },
  { postcode: '3380', names: ['Glabbeek'] },
  { postcode: '3381', names: ['Kapellen'] },
  { postcode: '3384', names: ['Attenrode'] },
  { postcode: '3390', names: ['Houwaart'] },
  { postcode: '3390', names: ['Sint-Joris-Winge'] },
  { postcode: '3390', names: ['Tielt'] },
  { postcode: '3391', names: ['Meensel-Kiezegem'] },
  { postcode: '3400', names: ['Eliksem'] },
  { postcode: '3400', names: ['Ezemaal'] },
  { postcode: '3400', names: ['Laar'] },
  { postcode: '3400', names: ['Landen'] },
  { postcode: '3400', names: ['Neerwinden'] },
  { postcode: '3400', names: ['Overwinden'] },
  { postcode: '3400', names: ['Rumsdorp'] },
  { postcode: '3400', names: ['Wange'] },
  { postcode: '3401', names: ['Waasmont'] },
  { postcode: '3401', names: ['Walsbets'] },
  { postcode: '3401', names: ['Walshoutem'] },
  { postcode: '3401', names: ['Wezeren'] },
  { postcode: '3404', names: ['Attenhoven'] },
  { postcode: '3404', names: ['Neerlanden'] },
  { postcode: '3440', names: ['Budingen'] },
  { postcode: '3440', names: ['Dormaal'] },
  { postcode: '3440', names: ['Halle-Booienhoven'] },
  { postcode: '3440', names: ['Helen-Bos'] },
  { postcode: '3440', names: ['Zoutleeuw'] },
  { postcode: '3450', names: ['Geetbets'] },
  { postcode: '3450', names: ['Grazen'] },
  { postcode: '3454', names: ['Rummen'] },
  { postcode: '3460', names: ['Assent'] },
  { postcode: '3460', names: ['Bekkevoort'] },
  { postcode: '3461', names: ['Molenbeek-Wersbeek'] },
  { postcode: '3470', names: ['Kortenaken'] },
  { postcode: '3470', names: ['Ransberg'] },
  { postcode: '3470', names: ['Sint-Margriete-Houtem'] },
  { postcode: '3471', names: ['Hoeleden'] },
  { postcode: '3472', names: ['Kersbeek-Miskom'] },
  { postcode: '3473', names: ['Waanrode'] },
  { postcode: '3500', names: ['Hasselt'] },
  { postcode: '3500', names: ['Sint-Lambrechts-Herk'] },
  { postcode: '3501', names: ['Wimmertingen'] },
  { postcode: '3510', names: ['Kermt'] },
  { postcode: '3510', names: ['Spalbeek'] },
  { postcode: '3511', names: ['Kuringen'] },
  { postcode: '3511', names: ['Stokrooie'] },
  { postcode: '3512', names: ['Stevoort'] },
  { postcode: '3520', names: ['Zonhoven'] },
  { postcode: '3530', names: ['Helchteren'] },
  { postcode: '3530', names: ['Houthalen'] },
  { postcode: '3540', names: ['Berbroek'] },
  { postcode: '3540', names: ['Donk'] },
  { postcode: '3540', names: ['Herk-De-Stad'] },
  { postcode: '3540', names: ['Schulen'] },
  { postcode: '3545', names: ['Halen'] },
  { postcode: '3545', names: ['Loksbergen'] },
  { postcode: '3545', names: ['Zelem'] },
  { postcode: '3550', names: ['Heusden'] },
  { postcode: '3550', names: ['Heusden-Zolder'] },
  { postcode: '3550', names: ['Zolder'] },
  { postcode: '3560', names: ['Linkhout'] },
  { postcode: '3560', names: ['Lummen'] },
  { postcode: '3560', names: ['Meldert'] },
  { postcode: '3570', names: ['Alken'] },
  { postcode: '3580', names: ['Beringen'] },
  { postcode: '3581', names: ['Beverlo'] },
  { postcode: '3582', names: ['Koersel'] },
  { postcode: '3583', names: ['Paal'] },
  { postcode: '3590', names: ['Diepenbeek'] },
  { postcode: '3600', names: ['Genk'] },
  { postcode: '3620', names: ['Gellik'] },
  { postcode: '3620', names: ['Lanaken'] },
  { postcode: '3620', names: ['Neerharen'] },
  { postcode: '3620', names: ['Veldwezelt'] },
  { postcode: '3621', names: ['Rekem'] },
  { postcode: '3630', names: ['Eisden'] },
  { postcode: '3630', names: ['Leut'] },
  { postcode: '3630', names: ['Maasmechelen'] },
  { postcode: '3630', names: ['Mechelen-Aan-De-Maas'] },
  { postcode: '3630', names: ['Meeswijk'] },
  { postcode: '3630', names: ['Opgrimbie'] },
  { postcode: '3630', names: ['Vucht'] },
  { postcode: '3631', names: ['Boorsem'] },
  { postcode: '3631', names: ['Uikhoven'] },
  { postcode: '3640', names: ['Kessenich'] },
  { postcode: '3640', names: ['Kinrooi'] },
  { postcode: '3640', names: ['Molenbeersel'] },
  { postcode: '3640', names: ['Ophoven'] },
  { postcode: '3650', names: ['Dilsen'] },
  { postcode: '3650', names: ['Dilsen-Stokkem'] },
  { postcode: '3650', names: ['Elen'] },
  { postcode: '3650', names: ['Lanklaar'] },
  { postcode: '3650', names: ['Rotem'] },
  { postcode: '3650', names: ['Stokkem'] },
  { postcode: '3660', names: ['Opglabbeek'] },
  { postcode: '3665', names: ['As'] },
  { postcode: '3668', names: ['Niel-Bij-As'] },
  { postcode: '3670', names: ['Ellikom'] },
  { postcode: '3670', names: ['Gruitrode'] },
  { postcode: '3670', names: ['Meeuwen'] },
  { postcode: '3670', names: ['Neerglabbeek'] },
  { postcode: '3670', names: ['Wijshagen'] },
  { postcode: '3680', names: ['Maaseik'] },
  { postcode: '3680', names: ['Neeroeteren'] },
  { postcode: '3680', names: ['Opoeteren'] },
  { postcode: '3690', names: ['Zutendaal'] },
  { postcode: '3700', names: ["'S Herenelderen"] },
  { postcode: '3700', names: ['Berg'] },
  { postcode: '3700', names: ['Diets-Heur'] },
  { postcode: '3700', names: ['Haren'] },
  { postcode: '3700', names: ['Henis'] },
  { postcode: '3700', names: ['Kolmont'] },
  { postcode: '3700', names: ['Koninksem'] },
  { postcode: '3700', names: ['Lauw'] },
  { postcode: '3700', names: ['Mal'] },
  { postcode: '3700', names: ['Neerrepen'] },
  { postcode: '3700', names: ['Nerem'] },
  { postcode: '3700', names: ['Overrepen'] },
  { postcode: '3700', names: ['Piringen'] },
  { postcode: '3700', names: ['Riksingen'] },
  { postcode: '3700', names: ['Rutten'] },
  { postcode: '3700', names: ['Sluizen'] },
  { postcode: '3700', names: ['Tongeren'] },
  { postcode: '3700', names: ['Vreren'] },
  { postcode: '3700', names: ['Widooie'] },
  { postcode: '3717', names: ['Herstappe'] },
  { postcode: '3720', names: ['Kortessem'] },
  { postcode: '3721', names: ['Vliermaalroot'] },
  { postcode: '3722', names: ['Wintershoven'] },
  { postcode: '3723', names: ['Guigoven'] },
  { postcode: '3724', names: ['Vliermaal'] },
  { postcode: '3730', names: ['Hoeselt'] },
  { postcode: '3730', names: ['Romershoven'] },
  { postcode: '3730', names: ['Sint-Huibrechts-Hern'] },
  { postcode: '3730', names: ['Werm'] },
  { postcode: '3732', names: ['Schalkhoven'] },
  { postcode: '3740', names: ['Beverst'] },
  { postcode: '3740', names: ['Bilzen'] },
  { postcode: '3740', names: ['Eigenbilzen'] },
  { postcode: '3740', names: ['Grote-Spouwen'] },
  { postcode: '3740', names: ['Hees'] },
  { postcode: '3740', names: ['Kleine-Spouwen'] },
  { postcode: '3740', names: ['Mopertingen'] },
  { postcode: '3740', names: ['Munsterbilzen'] },
  { postcode: '3740', names: ['Rijkhoven'] },
  { postcode: '3740', names: ['Rosmeer'] },
  { postcode: '3740', names: ['Waltwilder'] },
  { postcode: '3742', names: ['Martenslinde'] },
  { postcode: '3746', names: ['Hoelbeek'] },
  { postcode: '3770', names: ['Genoelselderen'] },
  { postcode: '3770', names: ['Herderen'] },
  { postcode: '3770', names: ['Kanne'] },
  { postcode: '3770', names: ['Membruggen'] },
  { postcode: '3770', names: ['Millen'] },
  { postcode: '3770', names: ['Riemst'] },
  { postcode: '3770', names: ['Val-Meer'] },
  { postcode: '3770', names: ['Vlijtingen'] },
  { postcode: '3770', names: ['Vroenhoven'] },
  { postcode: '3770', names: ['Zichen-Zussen-Bolder'] },
  { postcode: '3790', names: ['Fouron-Saint-Martin'] },
  { postcode: '3790', names: ['Mouland'] },
  { postcode: '3791', names: ['Remersdaal'] },
  { postcode: '3792', names: ['Fouron-Saint-Pierre'] },
  { postcode: '3793', names: ['Teuven'] },
  { postcode: '3798', names: ['Fouron-Le-Comte'] },
  { postcode: '3800', names: ['Aalst'] },
  { postcode: '3800', names: ['Brustem'] },
  { postcode: '3800', names: ['Engelmanshoven'] },
  { postcode: '3800', names: ['Gelinden'] },
  { postcode: '3800', names: ['Groot-Gelmen'] },
  { postcode: '3800', names: ['Halmaal'] },
  { postcode: '3800', names: ['Kerkom-Bij-Sint-Truiden'] },
  { postcode: '3800', names: ['Ordingen'] },
  { postcode: '3800', names: ['Sint-Truiden'] },
  { postcode: '3800', names: ['Zepperen'] },
  { postcode: '3803', names: ['Duras'] },
  { postcode: '3803', names: ['Gorsem'] },
  { postcode: '3803', names: ['Runkelen'] },
  { postcode: '3803', names: ['Wilderen'] },
  { postcode: '3806', names: ['Velm'] },
  { postcode: '3830', names: ['Berlingen'] },
  { postcode: '3830', names: ['Wellen'] },
  { postcode: '3831', names: ['Herten'] },
  { postcode: '3832', names: ['Ulbeek'] },
  { postcode: '3840', names: ['Bommershoven'] },
  { postcode: '3840', names: ['Borgloon'] },
  { postcode: '3840', names: ['Broekom'] },
  { postcode: '3840', names: ['Gors-Opleeuw'] },
  { postcode: '3840', names: ['Gotem'] },
  { postcode: '3840', names: ['Groot-Loon'] },
  { postcode: '3840', names: ['Haren'] },
  { postcode: '3840', names: ['Hendrieken'] },
  { postcode: '3840', names: ['Hoepertingen'] },
  { postcode: '3840', names: ['Jesseren'] },
  { postcode: '3840', names: ['Kerniel'] },
  { postcode: '3840', names: ['Kolmont'] },
  { postcode: '3840', names: ['Kuttekoven'] },
  { postcode: '3840', names: ['Rijkel'] },
  { postcode: '3840', names: ['Voort'] },
  { postcode: '3850', names: ['Binderveld'] },
  { postcode: '3850', names: ['Kozen'] },
  { postcode: '3850', names: ['Nieuwerkerken'] },
  { postcode: '3850', names: ['Wijer'] },
  { postcode: '3870', names: ['Batsheers'] },
  { postcode: '3870', names: ['Bovelingen'] },
  { postcode: '3870', names: ['Gutshoven'] },
  { postcode: '3870', names: ['Heers'] },
  { postcode: '3870', names: ['Heks'] },
  { postcode: '3870', names: ['Horpmaal'] },
  { postcode: '3870', names: ['Klein-Gelmen'] },
  { postcode: '3870', names: ['Mechelen-Bovelingen'] },
  { postcode: '3870', names: ['Mettekoven'] },
  { postcode: '3870', names: ['Opheers'] },
  { postcode: '3870', names: ['Rukkelingen-Loon'] },
  { postcode: '3870', names: ['Vechmaal'] },
  { postcode: '3870', names: ['Veulen'] },
  { postcode: '3890', names: ['Boekhout'] },
  { postcode: '3890', names: ['Gingelom'] },
  { postcode: '3890', names: ['Jeuk'] },
  { postcode: '3890', names: ['Kortijs'] },
  { postcode: '3890', names: ['Montenaken'] },
  { postcode: '3890', names: ['Niel-Bij-Sint-Truiden'] },
  { postcode: '3890', names: ['Vorsen'] },
  { postcode: '3891', names: ['Borlo'] },
  { postcode: '3891', names: ['Buvingen'] },
  { postcode: '3891', names: ['Mielen-Boven-Aalst'] },
  { postcode: '3891', names: ['Muizen'] },
  { postcode: '3900', names: ['Overpelt'] },
  { postcode: '3910', names: ['Neerpelt'] },
  { postcode: '3910', names: ['Sint-Huibrechts-Lille'] },
  { postcode: '3920', names: ['Lommel'] },
  { postcode: '3930', names: ['Achel'] },
  { postcode: '3930', names: ['Hamont'] },
  { postcode: '3940', names: ['Hechtel'] },
  { postcode: '3941', names: ['Eksel'] },
  { postcode: '3945', names: ['Kwaadmechelen'] },
  { postcode: '3945', names: ['Oostham'] },
  { postcode: '3950', names: ['Bocholt'] },
  { postcode: '3950', names: ['Kaulille'] },
  { postcode: '3950', names: ['Reppel'] },
  { postcode: '3960', names: ['Beek'] },
  { postcode: '3960', names: ['Bree'] },
  { postcode: '3960', names: ['Gerdingen'] },
  { postcode: '3960', names: ['Opitter'] },
  { postcode: '3960', names: ['Tongerlo'] },
  { postcode: '3970', names: ['Leopoldsburg'] },
  { postcode: '3971', names: ['Heppen'] },
  { postcode: '3980', names: ['Tessenderlo'] },
  { postcode: '3990', names: ['Grote-Brogel'] },
  { postcode: '3990', names: ['Kleine-Brogel'] },
  { postcode: '3990', names: ['Peer'] },
  { postcode: '3990', names: ['Wijchmaal'] },
  { postcode: '4000', names: ['Glain'] },
  { postcode: '4000', names: ['Liège'] },
  { postcode: '4000', names: ['Rocourt'] },
  { postcode: '4020', names: ['Bressoux'] },
  { postcode: '4020', names: ['Jupille-Sur-Meuse'] },
  { postcode: '4020', names: ['Liège'] },
  { postcode: '4020', names: ['Wandre'] },
  { postcode: '4030', names: ['Grivegnée'] },
  { postcode: '4031', names: ['Angleur'] },
  { postcode: '4032', names: ['Chênée'] },
  { postcode: '4040', names: ['Herstal'] },
  { postcode: '4041', names: ['Milmort'] },
  { postcode: '4041', names: ['Vottem'] },
  { postcode: '4042', names: ['Liers'] },
  { postcode: '4050', names: ['Chaudfontaine'] },
  { postcode: '4051', names: ['Vaux-Sous-Chèvremont'] },
  { postcode: '4052', names: ['Beaufays'] },
  { postcode: '4053', names: ['Embourg'] },
  { postcode: '4099', names: ['Liège X'] },
  { postcode: '4100', names: ['Boncelles'] },
  { postcode: '4100', names: ['Seraing'] },
  { postcode: '4101', names: ['Jemeppe-Sur-Meuse'] },
  { postcode: '4102', names: ['Ougrée'] },
  { postcode: '4120', names: ['Ehein'] },
  { postcode: '4120', names: ['Rotheux-Rimière'] },
  { postcode: '4121', names: ['Neuville-En-Condroz'] },
  { postcode: '4122', names: ['Plainevaux'] },
  { postcode: '4130', names: ['Esneux'] },
  { postcode: '4130', names: ['Tilff'] },
  { postcode: '4140', names: ['Dolembreux'] },
  { postcode: '4140', names: ['Gomzé-Andoumont'] },
  { postcode: '4140', names: ['Rouvreux'] },
  { postcode: '4140', names: ['Sprimont'] },
  { postcode: '4141', names: ['Louveigné'] },
  { postcode: '4160', names: ['Anthisnes'] },
  { postcode: '4161', names: ['Villers-Aux-Tours'] },
  { postcode: '4162', names: ['Hody'] },
  { postcode: '4163', names: ['Tavier'] },
  { postcode: '4170', names: ['Comblain-Au-Pont'] },
  { postcode: '4171', names: ['Poulseur'] },
  { postcode: '4180', names: ['Comblain-Fairon'] },
  { postcode: '4180', names: ['Comblain-La-Tour'] },
  { postcode: '4180', names: ['Hamoir'] },
  { postcode: '4181', names: ['Filot'] },
  { postcode: '4190', names: ['Ferrières'] },
  { postcode: '4190', names: ['My'] },
  { postcode: '4190', names: ['Vieuxville'] },
  { postcode: '4190', names: ['Werbomont'] },
  { postcode: '4190', names: ['Xhoris'] },
  { postcode: '4210', names: ['Burdinne'] },
  { postcode: '4210', names: ['Hannêche'] },
  { postcode: '4210', names: ['Lamontzée'] },
  { postcode: '4210', names: ['Marneffe'] },
  { postcode: '4210', names: ['Oteppe'] },
  { postcode: '4217', names: ['Héron'] },
  { postcode: '4217', names: ['Lavoir'] },
  { postcode: '4217', names: ["Waret-L'Evêque"] },
  { postcode: '4218', names: ['Couthuin'] },
  { postcode: '4219', names: ['Acosse'] },
  { postcode: '4219', names: ['Ambresin'] },
  { postcode: '4219', names: ['Meeffe'] },
  { postcode: '4219', names: ['Wasseiges'] },
  { postcode: '4250', names: ['BoÃ«lhe'] },
  { postcode: '4250', names: ['Geer'] },
  { postcode: '4250', names: ['Hollogne-Sur-Geer'] },
  { postcode: '4250', names: ['Lens-Saint-Servais'] },
  { postcode: '4252', names: ['Omal'] },
  { postcode: '4253', names: ['Darion'] },
  { postcode: '4254', names: ['Ligney'] },
  { postcode: '4257', names: ['Berloz'] },
  { postcode: '4257', names: ['Corswarem'] },
  { postcode: '4257', names: ['Rosoux-Crenwick'] },
  { postcode: '4260', names: ['Avennes'] },
  { postcode: '4260', names: ['Braives'] },
  { postcode: '4260', names: ['Ciplet'] },
  { postcode: '4260', names: ['Fallais'] },
  { postcode: '4260', names: ['Fumal'] },
  { postcode: '4260', names: ['Ville-En-Hesbaye'] },
  { postcode: '4261', names: ['Latinne'] },
  { postcode: '4263', names: ['Tourinne'] },
  { postcode: '4280', names: ['Abolens'] },
  { postcode: '4280', names: ['Avernas-Le-Bauduin'] },
  { postcode: '4280', names: ['Avin'] },
  { postcode: '4280', names: ['Bertrée'] },
  { postcode: '4280', names: ['Blehen'] },
  { postcode: '4280', names: ['Cras-Avernas'] },
  { postcode: '4280', names: ['Crehen'] },
  { postcode: '4280', names: ['Grand-Hallet'] },
  { postcode: '4280', names: ['Hannut'] },
  { postcode: '4280', names: ['Lens-Saint-Remy'] },
  { postcode: '4280', names: ['Merdorp'] },
  { postcode: '4280', names: ['Moxhe'] },
  { postcode: '4280', names: ['Petit-Hallet'] },
  { postcode: '4280', names: ['Poucet'] },
  { postcode: '4280', names: ['Thisnes'] },
  { postcode: '4280', names: ['Trognée'] },
  { postcode: '4280', names: ['Villers-Le-Peuplier'] },
  { postcode: '4280', names: ['Wansin'] },
  { postcode: '4287', names: ['Lincent'] },
  { postcode: '4287', names: ['Pellaines'] },
  { postcode: '4287', names: ['Racour'] },
  { postcode: '4300', names: ['Bettincourt'] },
  { postcode: '4300', names: ['Bleret'] },
  { postcode: '4300', names: ['Bovenistier'] },
  { postcode: '4300', names: ['Grand-Axhe'] },
  { postcode: '4300', names: ['Lantremange'] },
  { postcode: '4300', names: ['Oleye'] },
  { postcode: '4300', names: ['Waremme'] },
  { postcode: '4317', names: ['Aineffe'] },
  { postcode: '4317', names: ['Borlez'] },
  { postcode: '4317', names: ['Celles'] },
  { postcode: '4317', names: ['Faimes'] },
  { postcode: '4317', names: ['Les Waleffes'] },
  { postcode: '4317', names: ['Viemme'] },
  { postcode: '4340', names: ['Awans'] },
  { postcode: '4340', names: ['Fooz'] },
  { postcode: '4340', names: ['Othée'] },
  { postcode: '4340', names: ["Villers-L'Evêque"] },
  { postcode: '4342', names: ['Hognoul'] },
  { postcode: '4347', names: ['Fexhe-Le-Haut-Clocher'] },
  { postcode: '4347', names: ['Freloux'] },
  { postcode: '4347', names: ['Noville'] },
  { postcode: '4347', names: ['Roloux'] },
  { postcode: '4347', names: ['Voroux-Goreux'] },
  { postcode: '4350', names: ['Lamine'] },
  { postcode: '4350', names: ['Momalle'] },
  { postcode: '4350', names: ['Pousset'] },
  { postcode: '4350', names: ['Remicourt'] },
  { postcode: '4351', names: ['Hodeige'] },
  { postcode: '4357', names: ['Donceel'] },
  { postcode: '4357', names: ['Haneffe'] },
  { postcode: '4357', names: ['Jeneffe'] },
  { postcode: '4357', names: ['Limont'] },
  { postcode: '4360', names: ['Bergilers'] },
  { postcode: '4360', names: ['Grandville'] },
  { postcode: '4360', names: ['Lens-Sur-Geer'] },
  { postcode: '4360', names: ['Oreye'] },
  { postcode: '4360', names: ['Otrange'] },
  { postcode: '4367', names: ['Crisnée'] },
  { postcode: '4367', names: ['Fize-Le-Marsal'] },
  { postcode: '4367', names: ['Kemexhe'] },
  { postcode: '4367', names: ['Odeur'] },
  { postcode: '4367', names: ['Thys'] },
  { postcode: '4400', names: ['Awirs'] },
  { postcode: '4400', names: ['Chokier'] },
  { postcode: '4400', names: ['Flémalle-Grande'] },
  { postcode: '4400', names: ['Flémalle-Haute'] },
  { postcode: '4400', names: ['Gleixhe'] },
  { postcode: '4400', names: ['Ivoz-Ramet'] },
  { postcode: '4400', names: ['Mons-Lez-Liège'] },
  { postcode: '4420', names: ['Montegnée'] },
  { postcode: '4420', names: ['Saint-Nicolas'] },
  { postcode: '4420', names: ['Tilleur'] },
  { postcode: '4430', names: ['Ans'] },
  { postcode: '4431', names: ['Loncin'] },
  { postcode: '4432', names: ['Alleur'] },
  { postcode: '4432', names: ['Xhendremael'] },
  { postcode: '4450', names: ['Juprelle'] },
  { postcode: '4450', names: ['Lantin'] },
  { postcode: '4450', names: ['Slins'] },
  { postcode: '4451', names: ['Voroux-Lez-Liers'] },
  { postcode: '4452', names: ['Paifve'] },
  { postcode: '4452', names: ['Wihogne'] },
  { postcode: '4453', names: ['Villers-Saint-Siméon'] },
  { postcode: '4458', names: ['Fexhe-Slins'] },
  { postcode: '4460', names: ['Bierset'] },
  { postcode: '4460', names: ['Grâce-Berleur'] },
  { postcode: '4460', names: ['Grâce-Hollogne'] },
  { postcode: '4460', names: ['Hollogne-Aux-Pierres'] },
  { postcode: '4460', names: ['Horion-Hozémont'] },
  { postcode: '4460', names: ['Velroux'] },
  { postcode: '4470', names: ['Saint-Georges-Sur-Meuse'] },
  { postcode: '4480', names: ['Clermont-Sous-Huy'] },
  { postcode: '4480', names: ['Ehein'] },
  { postcode: '4480', names: ['Engis'] },
  { postcode: '4480', names: ['Hermalle-Sous-Huy'] },
  { postcode: '4500', names: ['Ben-Ahin'] },
  { postcode: '4500', names: ['Huy'] },
  { postcode: '4500', names: ['Tihange'] },
  { postcode: '4520', names: ['Antheit'] },
  { postcode: '4520', names: ['Bas-Oha'] },
  { postcode: '4520', names: ['Huccorgne'] },
  { postcode: '4520', names: ['Moha'] },
  { postcode: '4520', names: ['Vinalmont'] },
  { postcode: '4520', names: ['Wanze'] },
  { postcode: '4530', names: ['Fize-Fontaine'] },
  { postcode: '4530', names: ['Vaux-Et-Borset'] },
  { postcode: '4530', names: ['Vieux-Waleffe'] },
  { postcode: '4530', names: ['Villers-Le-Bouillet'] },
  { postcode: '4530', names: ['Warnant-Dreye'] },
  { postcode: '4537', names: ['Bodegnée'] },
  { postcode: '4537', names: ['Chapon-Seraing'] },
  { postcode: '4537', names: ['Seraing-Le-Château'] },
  { postcode: '4537', names: ['Verlaine'] },
  { postcode: '4540', names: ['Amay'] },
  { postcode: '4540', names: ['Ampsin'] },
  { postcode: '4540', names: ['Flône'] },
  { postcode: '4540', names: ['Jehay'] },
  { postcode: '4540', names: ['Ombret'] },
  { postcode: '4550', names: ['Nandrin'] },
  { postcode: '4550', names: ['Saint-Séverin'] },
  { postcode: '4550', names: ['Villers-Le-Temple'] },
  { postcode: '4550', names: ['Yernée-Fraineux'] },
  { postcode: '4557', names: ['Abée'] },
  { postcode: '4557', names: ['Fraiture'] },
  { postcode: '4557', names: ['Ramelot'] },
  { postcode: '4557', names: ['Seny'] },
  { postcode: '4557', names: ['Soheit-Tinlot'] },
  { postcode: '4557', names: ['Tinlot'] },
  { postcode: '4560', names: ['Bois-Et-Borsu'] },
  { postcode: '4560', names: ['Clavier'] },
  { postcode: '4560', names: ['Les Avins'] },
  { postcode: '4560', names: ['Ocquier'] },
  { postcode: '4560', names: ['Pailhe'] },
  { postcode: '4560', names: ['Terwagne'] },
  { postcode: '4570', names: ['Marchin'] },
  { postcode: '4570', names: ['Vyle-Et-Tharoul'] },
  { postcode: '4577', names: ['Modave'] },
  { postcode: '4577', names: ['Outrelouxhe'] },
  { postcode: '4577', names: ['Strée-Lez-Huy'] },
  { postcode: '4577', names: ['Vierset-Barse'] },
  { postcode: '4590', names: ['Ellemelle'] },
  { postcode: '4590', names: ['Ouffet'] },
  { postcode: '4590', names: ['Warzée'] },
  { postcode: '4600', names: ['Lanaye'] },
  { postcode: '4600', names: ['Lixhe'] },
  { postcode: '4600', names: ['Richelle'] },
  { postcode: '4600', names: ['Visé'] },
  { postcode: '4601', names: ['Argenteau'] },
  { postcode: '4602', names: ['Cheratte'] },
  { postcode: '4606', names: ['Saint-André'] },
  { postcode: '4607', names: ['Berneau'] },
  { postcode: '4607', names: ['Bombaye'] },
  { postcode: '4607', names: ['Dalhem'] },
  { postcode: '4607', names: ['Feneur'] },
  { postcode: '4607', names: ['Mortroux'] },
  { postcode: '4608', names: ['Neufchâteau'] },
  { postcode: '4608', names: ['Warsage'] },
  { postcode: '4610', names: ['Bellaire'] },
  { postcode: '4610', names: ['Beyne-Heusay'] },
  { postcode: '4610', names: ['Queue-Du-Bois'] },
  { postcode: '4620', names: ['Fléron'] },
  { postcode: '4621', names: ['Retinne'] },
  { postcode: '4623', names: ['Magnée'] },
  { postcode: '4624', names: ['Romsée'] },
  { postcode: '4630', names: ['Ayeneux'] },
  { postcode: '4630', names: ['Micheroux'] },
  { postcode: '4630', names: ['Soumagne'] },
  { postcode: '4630', names: ['Tignée'] },
  { postcode: '4631', names: ['Evegnée'] },
  { postcode: '4632', names: ['Cérexhe-Heuseux'] },
  { postcode: '4633', names: ['Melen'] },
  { postcode: '4650', names: ['Chaineux'] },
  { postcode: '4650', names: ['Grand-Rechain'] },
  { postcode: '4650', names: ['Herve'] },
  { postcode: '4650', names: ['Julémont'] },
  { postcode: '4651', names: ['Battice'] },
  { postcode: '4652', names: ['Xhendelesse'] },
  { postcode: '4653', names: ['Bolland'] },
  { postcode: '4654', names: ['Charneux'] },
  { postcode: '4670', names: ['Blégny'] },
  { postcode: '4670', names: ['Mortier'] },
  { postcode: '4670', names: ['Trembleur'] },
  { postcode: '4671', names: ['Barchon'] },
  { postcode: '4671', names: ['Housse'] },
  { postcode: '4671', names: ['Saive'] },
  { postcode: '4672', names: ['Saint-Remy'] },
  { postcode: '4680', names: ['Hermée'] },
  { postcode: '4680', names: ['Oupeye'] },
  { postcode: '4681', names: ['Hermalle-Sous-Argenteau'] },
  { postcode: '4682', names: ['Heure-Le-Romain'] },
  { postcode: '4682', names: ['Houtain-Saint-Siméon'] },
  { postcode: '4683', names: ['Vivegnis'] },
  { postcode: '4684', names: ['Haccourt'] },
  { postcode: '4690', names: ['Bassenge'] },
  { postcode: '4690', names: ['Boirs'] },
  { postcode: '4690', names: ['Eben-Emael'] },
  { postcode: '4690', names: ['Glons'] },
  { postcode: '4690', names: ['Roclenge-Sur-Geer'] },
  { postcode: '4690', names: ['Wonck'] },
  { postcode: '4700', names: ['Eupen'] },
  { postcode: '4701', names: ['Kettenis'] },
  { postcode: '4710', names: ['Lontzen'] },
  { postcode: '4711', names: ['Walhorn'] },
  { postcode: '4720', names: ['La Calamine'] },
  { postcode: '4721', names: ['Neu-Moresnet'] },
  { postcode: '4728', names: ['Hergenrath'] },
  { postcode: '4730', names: ['Hauset'] },
  { postcode: '4730', names: ['Raeren'] },
  { postcode: '4731', names: ['Eynatten'] },
  { postcode: '4750', names: ['Butgenbach'] },
  { postcode: '4750', names: ['Elsenborn'] },
  { postcode: '4760', names: ['Bullange'] },
  { postcode: '4760', names: ['Manderfeld'] },
  { postcode: '4761', names: ['Rocherath'] },
  { postcode: '4770', names: ['Amblève'] },
  { postcode: '4770', names: ['Meyerode'] },
  { postcode: '4771', names: ['Heppenbach'] },
  { postcode: '4780', names: ['Recht'] },
  { postcode: '4780', names: ['Saint-Vith'] },
  { postcode: '4782', names: ['Schoenberg'] },
  { postcode: '4783', names: ['Lommersweiler'] },
  { postcode: '4784', names: ['Crombach'] },
  { postcode: '4790', names: ['Reuland'] },
  { postcode: '4791', names: ['Thommen'] },
  { postcode: '4800', names: ['Ensival'] },
  { postcode: '4800', names: ['Lambermont'] },
  { postcode: '4800', names: ['Petit-Rechain'] },
  { postcode: '4800', names: ['Polleur'] },
  { postcode: '4800', names: ['Verviers'] },
  { postcode: '4801', names: ['Stembert'] },
  { postcode: '4802', names: ['Heusy'] },
  { postcode: '4820', names: ['Dison'] },
  { postcode: '4821', names: ['Andrimont'] },
  { postcode: '4830', names: ['Limbourg'] },
  { postcode: '4831', names: ['Bilstain'] },
  { postcode: '4834', names: ['Goé'] },
  { postcode: '4837', names: ['Baelen'] },
  { postcode: '4837', names: ['Membach'] },
  { postcode: '4840', names: ['Welkenraedt'] },
  { postcode: '4841', names: ['Henri-Chapelle'] },
  { postcode: '4845', names: ['Jalhay'] },
  { postcode: '4845', names: ['Sart-Lez-Spa'] },
  { postcode: '4850', names: ['Montzen'] },
  { postcode: '4850', names: ['Moresnet'] },
  { postcode: '4851', names: ['Gemmenich'] },
  { postcode: '4851', names: ['Sippenaeken'] },
  { postcode: '4852', names: ['Hombourg'] },
  { postcode: '4860', names: ['Cornesse'] },
  { postcode: '4860', names: ['Pepinster'] },
  { postcode: '4860', names: ['Wegnez'] },
  { postcode: '4861', names: ['Soiron'] },
  { postcode: '4870', names: ['Forêt'] },
  { postcode: '4870', names: ['Fraipont'] },
  { postcode: '4870', names: ['Nessonvaux'] },
  { postcode: '4877', names: ['Olne'] },
  { postcode: '4880', names: ['Aubel'] },
  { postcode: '4890', names: ['Clermont'] },
  { postcode: '4890', names: ['Thimister'] },
  { postcode: '4900', names: ['Spa'] },
  { postcode: '4910', names: ['La Reid'] },
  { postcode: '4910', names: ['Polleur'] },
  { postcode: '4910', names: ['Theux'] },
  { postcode: '4920', names: ['Aywaille'] },
  { postcode: '4920', names: ['Ernonheid'] },
  { postcode: '4920', names: ['Harzé'] },
  { postcode: '4920', names: ['Louveigné'] },
  { postcode: '4920', names: ['Sougné-Remouchamps'] },
  { postcode: '4950', names: ['Faymonville'] },
  { postcode: '4950', names: ['Robertville'] },
  { postcode: '4950', names: ['Sourbrodt'] },
  { postcode: '4950', names: ['Waimes'] },
  { postcode: '4960', names: ['Bellevaux-Ligneuville'] },
  { postcode: '4960', names: ['Bevercé'] },
  { postcode: '4960', names: ['Malmedy'] },
  { postcode: '4970', names: ['Francorchamps'] },
  { postcode: '4970', names: ['Stavelot'] },
  { postcode: '4980', names: ['Fosse'] },
  { postcode: '4980', names: ['Trois-Ponts'] },
  { postcode: '4980', names: ['Wanne'] },
  { postcode: '4983', names: ['Basse-Bodeux'] },
  { postcode: '4987', names: ['Chevron'] },
  { postcode: '4987', names: ['La Gleize'] },
  { postcode: '4987', names: ['Lorcé'] },
  { postcode: '4987', names: ['Rahier'] },
  { postcode: '4987', names: ['Stoumont'] },
  { postcode: '4990', names: ['Arbrefontaine'] },
  { postcode: '4990', names: ['Bra'] },
  { postcode: '4990', names: ['Lierneux'] },
  { postcode: '5000', names: ['Beez'] },
  { postcode: '5000', names: ['Namur'] },
  { postcode: '5001', names: ['Belgrade'] },
  { postcode: '5002', names: ['Saint-Servais'] },
  { postcode: '5003', names: ['Saint-Marc'] },
  { postcode: '5004', names: ['Bouge'] },
  { postcode: '5010', names: ['SA SudPresse'] },
  { postcode: '5012', names: ['Parlement Wallon'] },
  { postcode: '5020', names: ['Champion'] },
  { postcode: '5020', names: ['Daussoulx'] },
  { postcode: '5020', names: ['Flawinne'] },
  { postcode: '5020', names: ['Malonne'] },
  { postcode: '5020', names: ['Suarlée'] },
  { postcode: '5020', names: ['Temploux'] },
  { postcode: '5020', names: ['Vedrin'] },
  { postcode: '5021', names: ['Boninne'] },
  { postcode: '5022', names: ['Cognelée'] },
  { postcode: '5024', names: ['Gelbressée'] },
  { postcode: '5024', names: ['Marche-Les-Dames'] },
  { postcode: '5030', names: ['Beuzet'] },
  { postcode: '5030', names: ['Ernage'] },
  { postcode: '5030', names: ['Gembloux'] },
  { postcode: '5030', names: ['Grand-Manil'] },
  { postcode: '5030', names: ['Lonzée'] },
  { postcode: '5030', names: ['Sauvenière'] },
  { postcode: '5031', names: ['Grand-Leez'] },
  { postcode: '5032', names: ['Bossière'] },
  { postcode: '5032', names: ['Bothey'] },
  { postcode: '5032', names: ['Corroy-Le-Château'] },
  { postcode: '5032', names: ['Isnes'] },
  { postcode: '5032', names: ['Mazy'] },
  { postcode: '5060', names: ['Arsimont'] },
  { postcode: '5060', names: ['Auvelais'] },
  { postcode: '5060', names: ['Falisolle'] },
  { postcode: '5060', names: ['Keumiée'] },
  { postcode: '5060', names: ['Moignelée'] },
  { postcode: '5060', names: ['Tamines'] },
  { postcode: '5060', names: ['Velaine-Sur-Sambre'] },
  { postcode: '5070', names: ['Aisemont'] },
  { postcode: '5070', names: ['Fosses-La-Ville'] },
  { postcode: '5070', names: ['Le Roux'] },
  { postcode: '5070', names: ['Sart-Eustache'] },
  { postcode: '5070', names: ['Sart-Saint-Laurent'] },
  { postcode: '5070', names: ['Vitrival'] },
  { postcode: '5080', names: ['Emines'] },
  { postcode: '5080', names: ['Rhisnes'] },
  { postcode: '5080', names: ['Villers-Lez-Heest'] },
  { postcode: '5080', names: ['Warisoulx'] },
  { postcode: '5081', names: ['Bovesse'] },
  { postcode: '5081', names: ['Meux'] },
  { postcode: '5081', names: ['Saint-Denis-Bovesse'] },
  { postcode: '5100', names: ['Dave'] },
  { postcode: '5100', names: ['Jambes'] },
  { postcode: '5100', names: ['Naninne'] },
  { postcode: '5100', names: ['Wépion'] },
  { postcode: '5100', names: ['Wierde'] },
  { postcode: '5101', names: ['Erpent'] },
  { postcode: '5101', names: ['Lives-Sur-Meuse'] },
  { postcode: '5101', names: ['Loyers'] },
  { postcode: '5140', names: ['Boignée'] },
  { postcode: '5140', names: ['Ligny'] },
  { postcode: '5140', names: ['Sombreffe'] },
  { postcode: '5140', names: ['Tongrinne'] },
  { postcode: '5150', names: ['Floreffe'] },
  { postcode: '5150', names: ['Floriffoux'] },
  { postcode: '5150', names: ['Franière'] },
  { postcode: '5150', names: ['Soye'] },
  { postcode: '5170', names: ['Arbre'] },
  { postcode: '5170', names: ['Bois-De-Villers'] },
  { postcode: '5170', names: ['Lesve'] },
  { postcode: '5170', names: ['Lustin'] },
  { postcode: '5170', names: ['Profondeville'] },
  { postcode: '5170', names: ['Rivière'] },
  { postcode: '5190', names: ['Balâtre'] },
  { postcode: '5190', names: ['Ham-Sur-Sambre'] },
  { postcode: '5190', names: ['Jemeppe-Sur-Sambre'] },
  { postcode: '5190', names: ['Mornimont'] },
  { postcode: '5190', names: ['Moustier-Sur-Sambre'] },
  { postcode: '5190', names: ['Onoz'] },
  { postcode: '5190', names: ['Saint-Martin'] },
  { postcode: '5190', names: ['Spy'] },
  { postcode: '5300', names: ['Andenne'] },
  { postcode: '5300', names: ['Bonneville'] },
  { postcode: '5300', names: ['Coutisse'] },
  { postcode: '5300', names: ['Landenne'] },
  { postcode: '5300', names: ['Maizeret'] },
  { postcode: '5300', names: ['Namêche'] },
  { postcode: '5300', names: ['Sclayn'] },
  { postcode: '5300', names: ['Seilles'] },
  { postcode: '5300', names: ['Thon'] },
  { postcode: '5300', names: ['Vezin'] },
  { postcode: '5310', names: ['Aische-En-Refail'] },
  { postcode: '5310', names: ['Bolinne'] },
  { postcode: '5310', names: ['Boneffe'] },
  { postcode: '5310', names: ['Branchon'] },
  { postcode: '5310', names: ['Dhuy'] },
  { postcode: '5310', names: ['Eghezée'] },
  { postcode: '5310', names: ['Hanret'] },
  { postcode: '5310', names: ['Leuze'] },
  { postcode: '5310', names: ['Liernu'] },
  { postcode: '5310', names: ['Longchamps'] },
  { postcode: '5310', names: ['Mehaigne'] },
  { postcode: '5310', names: ['Noville-Sur-Mehaigne'] },
  { postcode: '5310', names: ['Saint-Germain'] },
  { postcode: '5310', names: ['Taviers'] },
  { postcode: '5310', names: ['Upigny'] },
  { postcode: '5310', names: ['Waret-La-Chaussée'] },
  { postcode: '5330', names: ['Assesse'] },
  { postcode: '5330', names: ['Maillen'] },
  { postcode: '5330', names: ['Sart-Bernard'] },
  { postcode: '5332', names: ['Crupet'] },
  { postcode: '5333', names: ['Sorinne-La-Longue'] },
  { postcode: '5334', names: ['Florée'] },
  { postcode: '5336', names: ['Courrière'] },
  { postcode: '5340', names: ['Faulx-Les-Tombes'] },
  { postcode: '5340', names: ['Gesves'] },
  { postcode: '5340', names: ['Haltinne'] },
  { postcode: '5340', names: ['Mozet'] },
  { postcode: '5340', names: ['Sorée'] },
  { postcode: '5350', names: ['Evelette'] },
  { postcode: '5350', names: ['Ohey'] },
  { postcode: '5351', names: ['Haillot'] },
  { postcode: '5352', names: ['Perwez-Haillot'] },
  { postcode: '5353', names: ['Goesnes'] },
  { postcode: '5354', names: ['Jallet'] },
  { postcode: '5360', names: ['Hamois'] },
  { postcode: '5360', names: ['Natoye'] },
  { postcode: '5361', names: ['Mohiville'] },
  { postcode: '5361', names: ['Scy'] },
  { postcode: '5362', names: ['Achet'] },
  { postcode: '5363', names: ['Emptinne'] },
  { postcode: '5364', names: ['Schaltin'] },
  { postcode: '5370', names: ['Barvaux-Condroz'] },
  { postcode: '5370', names: ['Flostoy'] },
  { postcode: '5370', names: ['Havelange'] },
  { postcode: '5370', names: ['Jeneffe'] },
  { postcode: '5370', names: ['Porcheresse'] },
  { postcode: '5370', names: ['Verlée'] },
  { postcode: '5372', names: ['Méan'] },
  { postcode: '5374', names: ['Maffe'] },
  { postcode: '5376', names: ['Miécret'] },
  { postcode: '5377', names: ['Baillonville'] },
  { postcode: '5377', names: ['Bonsin'] },
  { postcode: '5377', names: ['Heure'] },
  { postcode: '5377', names: ['Hogne'] },
  { postcode: '5377', names: ['Nettinne'] },
  { postcode: '5377', names: ['Noiseux'] },
  { postcode: '5377', names: ['Sinsin'] },
  { postcode: '5377', names: ['Somme-Leuze'] },
  { postcode: '5377', names: ['Waillet'] },
  { postcode: '5380', names: ['Bierwart'] },
  { postcode: '5380', names: ['Cortil-Wodon'] },
  { postcode: '5380', names: ['Forville'] },
  { postcode: '5380', names: ['Franc-Waret'] },
  { postcode: '5380', names: ['Hemptinne'] },
  { postcode: '5380', names: ['Hingeon'] },
  { postcode: '5380', names: ['Marchovelette'] },
  { postcode: '5380', names: ['Noville-Les-Bois'] },
  { postcode: '5380', names: ['Pontillas'] },
  { postcode: '5380', names: ['Tillier'] },
  { postcode: '5500', names: ['Anseremme'] },
  { postcode: '5500', names: ['Bouvignes-Sur-Meuse'] },
  { postcode: '5500', names: ['Dinant'] },
  { postcode: '5500', names: ['Dréhance'] },
  { postcode: '5500', names: ['Falmagne'] },
  { postcode: '5500', names: ['Falmignoul'] },
  { postcode: '5500', names: ['Furfooz'] },
  { postcode: '5501', names: ['Lisogne'] },
  { postcode: '5502', names: ['Thynes'] },
  { postcode: '5503', names: ['Sorinnes'] },
  { postcode: '5504', names: ['Foy-Notre-Dame'] },
  { postcode: '5520', names: ['Anthée'] },
  { postcode: '5520', names: ['Onhaye'] },
  { postcode: '5521', names: ['Serville'] },
  { postcode: '5522', names: ['FalaÃ«n'] },
  { postcode: '5523', names: ['Sommière'] },
  { postcode: '5523', names: ['Weillen'] },
  { postcode: '5524', names: ['Gérin'] },
  { postcode: '5530', names: ['Dorinne'] },
  { postcode: '5530', names: ['Durnal'] },
  { postcode: '5530', names: ['Evrehailles'] },
  { postcode: '5530', names: ['Godinne'] },
  { postcode: '5530', names: ['Houx'] },
  { postcode: '5530', names: ['Mont'] },
  { postcode: '5530', names: ['Purnode'] },
  { postcode: '5530', names: ['Spontin'] },
  { postcode: '5530', names: ['Yvoir'] },
  { postcode: '5537', names: ['Anhée'] },
  { postcode: '5537', names: ['Annevoie-Rouillon'] },
  { postcode: '5537', names: ['Bioul'] },
  { postcode: '5537', names: ['Denée'] },
  { postcode: '5537', names: ['Haut-Le-Wastia'] },
  { postcode: '5537', names: ['Sosoye'] },
  { postcode: '5537', names: ['Warnant'] },
  { postcode: '5540', names: ['Hastière-Lavaux'] },
  { postcode: '5540', names: ['Hermeton-Sur-Meuse'] },
  { postcode: '5540', names: ['Waulsort'] },
  { postcode: '5541', names: ['Hastière-Par-Delà'] },
  { postcode: '5542', names: ['Blaimont'] },
  { postcode: '5543', names: ['Heer'] },
  { postcode: '5544', names: ['Agimont'] },
  { postcode: '5550', names: ['Alle'] },
  { postcode: '5550', names: ['Bagimont'] },
  { postcode: '5550', names: ['Bohan'] },
  { postcode: '5550', names: ['Chairière'] },
  { postcode: '5550', names: ['Laforêt'] },
  { postcode: '5550', names: ['Membre'] },
  { postcode: '5550', names: ['Mouzaive'] },
  { postcode: '5550', names: ['Nafraiture'] },
  { postcode: '5550', names: ['Orchimont'] },
  { postcode: '5550', names: ['Pussemange'] },
  { postcode: '5550', names: ['Sugny'] },
  { postcode: '5550', names: ['Vresse-Sur-Semois'] },
  { postcode: '5555', names: ['Baillamont'] },
  { postcode: '5555', names: ['Bellefontaine'] },
  { postcode: '5555', names: ['Bièvre'] },
  { postcode: '5555', names: ['Cornimont'] },
  { postcode: '5555', names: ['Graide'] },
  { postcode: '5555', names: ['Gros-Fays'] },
  { postcode: '5555', names: ['Monceau-En-Ardenne'] },
  { postcode: '5555', names: ['Naomé'] },
  { postcode: '5555', names: ['Oizy'] },
  { postcode: '5555', names: ['Petit-Fays'] },
  { postcode: '5560', names: ['Ciergnon'] },
  { postcode: '5560', names: ['Finnevaux'] },
  { postcode: '5560', names: ['Houyet'] },
  { postcode: '5560', names: ['Hulsonniaux'] },
  { postcode: '5560', names: ['Mesnil-Eglise'] },
  { postcode: '5560', names: ['Mesnil-Saint-Blaise'] },
  { postcode: '5561', names: ['Celles'] },
  { postcode: '5562', names: ['Custinne'] },
  { postcode: '5563', names: ['Hour'] },
  { postcode: '5564', names: ['Wanlin'] },
  { postcode: '5570', names: ['Baronville'] },
  { postcode: '5570', names: ['Beauraing'] },
  { postcode: '5570', names: ['Dion'] },
  { postcode: '5570', names: ['Felenne'] },
  { postcode: '5570', names: ['Feschaux'] },
  { postcode: '5570', names: ['Honnay'] },
  { postcode: '5570', names: ['Javingue'] },
  { postcode: '5570', names: ['Vonêche'] },
  { postcode: '5570', names: ['Wancennes'] },
  { postcode: '5570', names: ['Winenne'] },
  { postcode: '5571', names: ['Wiesme'] },
  { postcode: '5572', names: ['Focant'] },
  { postcode: '5573', names: ['Martouzin-Neuville'] },
  { postcode: '5574', names: ['Pondrôme'] },
  { postcode: '5575', names: ['Bourseigne-Neuve'] },
  { postcode: '5575', names: ['Bourseigne-Vieille'] },
  { postcode: '5575', names: ['Gedinne'] },
  { postcode: '5575', names: ['Houdremont'] },
  { postcode: '5575', names: ['Louette-Saint-Denis'] },
  { postcode: '5575', names: ['Louette-Saint-Pierre'] },
  { postcode: '5575', names: ['Malvoisin'] },
  { postcode: '5575', names: ['Patignies'] },
  { postcode: '5575', names: ['Rienne'] },
  { postcode: '5575', names: ['Sart-Custinne'] },
  { postcode: '5575', names: ['Vencimont'] },
  { postcode: '5575', names: ['Willerzie'] },
  { postcode: '5576', names: ['Froidfontaine'] },
  { postcode: '5580', names: ['Ave-Et-Auffe'] },
  { postcode: '5580', names: ['Buissonville'] },
  { postcode: '5580', names: ['Eprave'] },
  { postcode: '5580', names: ['Han-Sur-Lesse'] },
  { postcode: '5580', names: ['Jemelle'] },
  { postcode: '5580', names: ['Lavaux-Sainte-Anne'] },
  { postcode: '5580', names: ['Lessive'] },
  { postcode: '5580', names: ['Mont-Gauthier'] },
  { postcode: '5580', names: ['Rochefort'] },
  { postcode: '5580', names: ['Villers-Sur-Lesse'] },
  { postcode: '5580', names: ['Wavreille'] },
  { postcode: '5589', names: ['Jemelle'] },
  { postcode: '5590', names: ['Achêne'] },
  { postcode: '5590', names: ['Braibant'] },
  { postcode: '5590', names: ['Chevetogne'] },
  { postcode: '5590', names: ['Ciney'] },
  { postcode: '5590', names: ['Conneux'] },
  { postcode: '5590', names: ['Haversin'] },
  { postcode: '5590', names: ['Leignon'] },
  { postcode: '5590', names: ['Pessoux'] },
  { postcode: '5590', names: ['Serinchamps'] },
  { postcode: '5590', names: ['Sovet'] },
  { postcode: '5600', names: ['Fagnolle'] },
  { postcode: '5600', names: ['Franchimont'] },
  { postcode: '5600', names: ['Jamagne'] },
  { postcode: '5600', names: ['Jamiolle'] },
  { postcode: '5600', names: ['Merlemont'] },
  { postcode: '5600', names: ['Neuville'] },
  { postcode: '5600', names: ['Omezée'] },
  { postcode: '5600', names: ['Philippeville'] },
  { postcode: '5600', names: ['Roly'] },
  { postcode: '5600', names: ['Romedenne'] },
  { postcode: '5600', names: ['Samart'] },
  { postcode: '5600', names: ['Sart-En-Fagne'] },
  { postcode: '5600', names: ['Sautour'] },
  { postcode: '5600', names: ['Surice'] },
  { postcode: '5600', names: ['Villers-En-Fagne'] },
  { postcode: '5600', names: ['Villers-Le-Gambon'] },
  { postcode: '5600', names: ['Vodecée'] },
  { postcode: '5620', names: ['Corenne'] },
  { postcode: '5620', names: ['Flavion'] },
  { postcode: '5620', names: ['Florennes'] },
  { postcode: '5620', names: ['Hemptinne-Lez-Florennes'] },
  { postcode: '5620', names: ['Morville'] },
  { postcode: '5620', names: ['Rosée'] },
  { postcode: '5620', names: ['Saint-Aubin'] },
  { postcode: '5621', names: ['Hanzinelle'] },
  { postcode: '5621', names: ['Hanzinne'] },
  { postcode: '5621', names: ['Morialmé'] },
  { postcode: '5621', names: ['Thy-Le-Baudouin'] },
  { postcode: '5630', names: ['Cerfontaine'] },
  { postcode: '5630', names: ['Daussois'] },
  { postcode: '5630', names: ['Senzeilles'] },
  { postcode: '5630', names: ['Silenrieux'] },
  { postcode: '5630', names: ['Soumoy'] },
  { postcode: '5630', names: ['Villers-Deux-Eglises'] },
  { postcode: '5640', names: ['Biesme'] },
  { postcode: '5640', names: ['Biesmerée'] },
  { postcode: '5640', names: ['Graux'] },
  { postcode: '5640', names: ['Mettet'] },
  { postcode: '5640', names: ['Oret'] },
  { postcode: '5640', names: ['Saint-Gérard'] },
  { postcode: '5641', names: ['Furnaux'] },
  { postcode: '5644', names: ['Ermeton-Sur-Biert'] },
  { postcode: '5646', names: ['Stave'] },
  { postcode: '5650', names: ['Castillon'] },
  { postcode: '5650', names: ['Chastrès'] },
  { postcode: '5650', names: ['Clermont'] },
  { postcode: '5650', names: ['Fontenelle'] },
  { postcode: '5650', names: ['Fraire'] },
  { postcode: '5650', names: ['Pry'] },
  { postcode: '5650', names: ['Vogenée'] },
  { postcode: '5650', names: ['Walcourt'] },
  { postcode: '5650', names: ['Yves-Gomezée'] },
  { postcode: '5651', names: ['Berzée'] },
  { postcode: '5651', names: ['Gourdinne'] },
  { postcode: '5651', names: ['Laneffe'] },
  { postcode: '5651', names: ['Rognée'] },
  { postcode: '5651', names: ['Somzée'] },
  { postcode: '5651', names: ['Tarcienne'] },
  { postcode: '5651', names: ['Thy-Le-Château'] },
  { postcode: '5660', names: ['Aublain'] },
  { postcode: '5660', names: ['Boussu-En-Fagne'] },
  { postcode: '5660', names: ['Brûly'] },
  { postcode: '5660', names: ['Brûly-De-Pesche'] },
  { postcode: '5660', names: ['Couvin'] },
  { postcode: '5660', names: ['Cul-Des-Sarts'] },
  { postcode: '5660', names: ['Dailly'] },
  { postcode: '5660', names: ['Frasnes'] },
  { postcode: '5660', names: ['Gonrieux'] },
  { postcode: '5660', names: ['Mariembourg'] },
  { postcode: '5660', names: ['Pesche'] },
  { postcode: '5660', names: ['Pétigny'] },
  { postcode: '5660', names: ['Petite-Chapelle'] },
  { postcode: '5660', names: ['Presgaux'] },
  { postcode: '5670', names: ['Dourbes'] },
  { postcode: '5670', names: ['Le Mesnil'] },
  { postcode: '5670', names: ['Mazée'] },
  { postcode: '5670', names: ['Nismes'] },
  { postcode: '5670', names: ['Oignies-En-Thiérache'] },
  { postcode: '5670', names: ['Olloy-Sur-Viroin'] },
  { postcode: '5670', names: ['Treignes'] },
  { postcode: '5670', names: ['Vierves-Sur-Viroin'] },
  { postcode: '5680', names: ['Doische'] },
  { postcode: '5680', names: ['Gimnée'] },
  { postcode: '5680', names: ['Gochenée'] },
  { postcode: '5680', names: ['Matagne-La-Grande'] },
  { postcode: '5680', names: ['Matagne-La-Petite'] },
  { postcode: '5680', names: ['Niverlée'] },
  { postcode: '5680', names: ['Romerée'] },
  { postcode: '5680', names: ['Soulme'] },
  { postcode: '5680', names: ['Vaucelles'] },
  { postcode: '5680', names: ['Vodelée'] },
  { postcode: '6000', names: ['Charleroi'] },
  { postcode: '6001', names: ['Marcinelle'] },
  { postcode: '6010', names: ['Couillet'] },
  { postcode: '6020', names: ['Dampremy'] },
  { postcode: '6030', names: ['Goutroux'] },
  { postcode: '6030', names: ['Marchienne-Au-Pont'] },
  { postcode: '6031', names: ['Monceau-Sur-Sambre'] },
  { postcode: '6032', names: ['Mont-Sur-Marchienne'] },
  { postcode: '6040', names: ['Jumet'] },
  { postcode: '6041', names: ['Gosselies'] },
  { postcode: '6042', names: ['Lodelinsart'] },
  { postcode: '6043', names: ['Ransart'] },
  { postcode: '6044', names: ['Roux'] },
  { postcode: '6060', names: ['Gilly'] },
  { postcode: '6061', names: ['Montignies-Sur-Sambre'] },
  { postcode: '6075', names: ['CSM Charleroi X'] },
  { postcode: '6099', names: ['Charleroi X'] },
  { postcode: '6110', names: ['Montigny-Le-Tilleul'] },
  { postcode: '6111', names: ['Landelies'] },
  { postcode: '6120', names: ['Cour-Sur-Heure'] },
  { postcode: '6120', names: ['Ham-Sur-Heure'] },
  { postcode: '6120', names: ['Jamioulx'] },
  { postcode: '6120', names: ['Marbaix'] },
  { postcode: '6120', names: ['Nalinnes'] },
  { postcode: '6140', names: ["Fontaine-L'Evêque"] },
  { postcode: '6141', names: ['Forchies-La-Marche'] },
  { postcode: '6142', names: ['Leernes'] },
  { postcode: '6150', names: ['Anderlues'] },
  { postcode: '6180', names: ['Courcelles'] },
  { postcode: '6181', names: ['Gouy-Lez-Piéton'] },
  { postcode: '6182', names: ['Souvret'] },
  { postcode: '6183', names: ['Trazegnies'] },
  { postcode: '6200', names: ['Bouffioulx'] },
  { postcode: '6200', names: ['Châtelet'] },
  { postcode: '6200', names: ['Châtelineau'] },
  { postcode: '6210', names: ['Frasnes-Lez-Gosselies'] },
  { postcode: '6210', names: ['Rèves'] },
  { postcode: '6210', names: ['Villers-Perwin'] },
  { postcode: '6210', names: ['Wayaux'] },
  { postcode: '6211', names: ['Mellet'] },
  { postcode: '6220', names: ['Fleurus'] },
  { postcode: '6220', names: ['Heppignies'] },
  { postcode: '6220', names: ['Lambusart'] },
  { postcode: '6220', names: ['Wangenies'] },
  { postcode: '6221', names: ['Saint-Amand'] },
  { postcode: '6222', names: ['Brye'] },
  { postcode: '6223', names: ['Wagnelée'] },
  { postcode: '6224', names: ['Wanfercée-Baulet'] },
  { postcode: '6230', names: ['Buzet'] },
  { postcode: '6230', names: ['Obaix'] },
  { postcode: '6230', names: ['Pont-À-Celles'] },
  { postcode: '6230', names: ['Thiméon'] },
  { postcode: '6230', names: ['Viesville'] },
  { postcode: '6238', names: ['Liberchies'] },
  { postcode: '6238', names: ['Luttre'] },
  { postcode: '6240', names: ['Farciennes'] },
  { postcode: '6240', names: ['Pironchamps'] },
  { postcode: '6250', names: ['Aiseau'] },
  { postcode: '6250', names: ['Pont-De-Loup'] },
  { postcode: '6250', names: ['Presles'] },
  { postcode: '6250', names: ['Roselies'] },
  { postcode: '6280', names: ['Acoz'] },
  { postcode: '6280', names: ['Gerpinnes'] },
  { postcode: '6280', names: ['Gougnies'] },
  { postcode: '6280', names: ['Joncret'] },
  { postcode: '6280', names: ['Loverval'] },
  { postcode: '6280', names: ['Villers-Poterie'] },
  { postcode: '6440', names: ['Boussu-Lez-Walcourt'] },
  { postcode: '6440', names: ['Fourbechies'] },
  { postcode: '6440', names: ['Froidchapelle'] },
  { postcode: '6440', names: ['Vergnies'] },
  { postcode: '6441', names: ['Erpion'] },
  { postcode: '6460', names: ['Bailièvre'] },
  { postcode: '6460', names: ['Chimay'] },
  { postcode: '6460', names: ['Robechies'] },
  { postcode: '6460', names: ['Saint-Remy'] },
  { postcode: '6460', names: ['Salles'] },
  { postcode: '6460', names: ['Villers-La-Tour'] },
  { postcode: '6461', names: ['Virelles'] },
  { postcode: '6462', names: ['Vaulx-Lez-Chimay'] },
  { postcode: '6463', names: ['Lompret'] },
  { postcode: '6464', names: ['Baileux'] },
  { postcode: '6464', names: ['Bourlers'] },
  { postcode: '6464', names: ['Forges'] },
  { postcode: '6464', names: ["L'Escaillère"] },
  { postcode: '6464', names: ['Rièzes'] },
  { postcode: '6470', names: ['Grandrieu'] },
  { postcode: '6470', names: ['Montbliart'] },
  { postcode: '6470', names: ['Rance'] },
  { postcode: '6470', names: ['Sautin'] },
  { postcode: '6470', names: ['Sivry'] },
  { postcode: '6500', names: ['Barbençon'] },
  { postcode: '6500', names: ['Beaumont'] },
  { postcode: '6500', names: ['Leugnies'] },
  { postcode: '6500', names: ['Leval-Chaudeville'] },
  { postcode: '6500', names: ['Renlies'] },
  { postcode: '6500', names: ['Solre-Saint-Géry'] },
  { postcode: '6500', names: ['Thirimont'] },
  { postcode: '6511', names: ['Strée'] },
  { postcode: '6530', names: ['Leers-Et-Fosteau'] },
  { postcode: '6530', names: ['Thuin'] },
  { postcode: '6531', names: ['Biesme-Sous-Thuin'] },
  { postcode: '6532', names: ['Ragnies'] },
  { postcode: '6533', names: ['Biercée'] },
  { postcode: '6534', names: ['Gozée'] },
  { postcode: '6536', names: ['Donstiennes'] },
  { postcode: '6536', names: ['Thuillies'] },
  { postcode: '6540', names: ['Lobbes'] },
  { postcode: '6540', names: ['Mont-Sainte-Geneviève'] },
  { postcode: '6542', names: ['Sars-La-Buissière'] },
  { postcode: '6543', names: ['Bienne-Lez-Happart'] },
  { postcode: '6560', names: ["Bersillies-L'Abbaye"] },
  { postcode: '6560', names: ['Erquelinnes'] },
  { postcode: '6560', names: ['Grand-Reng'] },
  { postcode: '6560', names: ['Hantes-Wihéries'] },
  { postcode: '6560', names: ['Montignies-Saint-Christophe'] },
  { postcode: '6560', names: ['Solre-Sur-Sambre'] },
  { postcode: '6567', names: ['Fontaine-Valmont'] },
  { postcode: '6567', names: ['Labuissière'] },
  { postcode: '6567', names: ['Merbes-Le-Château'] },
  { postcode: '6567', names: ['Merbes-Sainte-Marie'] },
  { postcode: '6590', names: ['Momignies'] },
  { postcode: '6591', names: ['Macon'] },
  { postcode: '6592', names: ['Monceau-Imbrechies'] },
  { postcode: '6593', names: ['Macquenoise'] },
  { postcode: '6594', names: ['Beauwelz'] },
  { postcode: '6596', names: ['Forge-Philippe'] },
  { postcode: '6596', names: ['Seloignes'] },
  { postcode: '6600', names: ['Bastogne'] },
  { postcode: '6600', names: ['Longvilly'] },
  { postcode: '6600', names: ['Noville'] },
  { postcode: '6600', names: ['Villers-La-Bonne-Eau'] },
  { postcode: '6600', names: ['Wardin'] },
  { postcode: '6630', names: ['Martelange'] },
  { postcode: '6637', names: ['Fauvillers'] },
  { postcode: '6637', names: ['Hollange'] },
  { postcode: '6637', names: ['Tintange'] },
  { postcode: '6640', names: ['Hompré'] },
  { postcode: '6640', names: ['Morhet'] },
  { postcode: '6640', names: ['Nives'] },
  { postcode: '6640', names: ['Sibret'] },
  { postcode: '6640', names: ['Vaux-Lez-Rosières'] },
  { postcode: '6640', names: ['Vaux-Sur-Sûre'] },
  { postcode: '6642', names: ['Juseret'] },
  { postcode: '6660', names: ['Houffalize'] },
  { postcode: '6660', names: ['Nadrin'] },
  { postcode: '6661', names: ['Mont'] },
  { postcode: '6661', names: ['Tailles'] },
  { postcode: '6662', names: ['Tavigny'] },
  { postcode: '6663', names: ['Mabompré'] },
  { postcode: '6666', names: ['Wibrin'] },
  { postcode: '6670', names: ['Gouvy'] },
  { postcode: '6670', names: ['Limerlé'] },
  { postcode: '6671', names: ['Bovigny'] },
  { postcode: '6672', names: ['Beho'] },
  { postcode: '6673', names: ['Cherain'] },
  { postcode: '6674', names: ['Montleban'] },
  { postcode: '6680', names: ['Amberloup'] },
  { postcode: '6680', names: ['Tillet'] },
  { postcode: '6681', names: ['Lavacherie'] },
  { postcode: '6686', names: ['Flamierge'] },
  { postcode: '6687', names: ['Bertogne'] },
  { postcode: '6688', names: ['Longchamps'] },
  { postcode: '6690', names: ['Bihain'] },
  { postcode: '6690', names: ['Vielsalm'] },
  { postcode: '6692', names: ['Petit-Thier'] },
  { postcode: '6698', names: ['Grand-Halleux'] },
  { postcode: '6700', names: ['Arlon'] },
  { postcode: '6700', names: ['Bonnert'] },
  { postcode: '6700', names: ['Heinsch'] },
  { postcode: '6700', names: ['Toernich'] },
  { postcode: '6704', names: ['Guirsch'] },
  { postcode: '6706', names: ['Autelbas'] },
  { postcode: '6717', names: ['Attert'] },
  { postcode: '6717', names: ['Nobressart'] },
  { postcode: '6717', names: ['Nothomb'] },
  { postcode: '6717', names: ['Thiaumont'] },
  { postcode: '6717', names: ['Tontelange'] },
  { postcode: '6720', names: ['Habay-La-Neuve'] },
  { postcode: '6720', names: ['Hachy'] },
  { postcode: '6721', names: ['Anlier'] },
  { postcode: '6723', names: ['Habay-La-Vieille'] },
  { postcode: '6724', names: ['Houdemont'] },
  { postcode: '6724', names: ['Rulles'] },
  { postcode: '6730', names: ['Bellefontaine'] },
  { postcode: '6730', names: ['Rossignol'] },
  { postcode: '6730', names: ['Saint-Vincent'] },
  { postcode: '6730', names: ['Tintigny'] },
  { postcode: '6740', names: ['Etalle'] },
  { postcode: '6740', names: ['Sainte-Marie-Sur-Semois'] },
  { postcode: '6740', names: ['Villers-Sur-Semois'] },
  { postcode: '6741', names: ['Vance'] },
  { postcode: '6742', names: ['Chantemelle'] },
  { postcode: '6743', names: ['Buzenol'] },
  { postcode: '6747', names: ['Châtillon'] },
  { postcode: '6747', names: ['Meix-Le-Tige'] },
  { postcode: '6747', names: ['Saint-Léger'] },
  { postcode: '6750', names: ['Musson'] },
  { postcode: '6750', names: ['Mussy-La-Ville'] },
  { postcode: '6750', names: ['Signeulx'] },
  { postcode: '6760', names: ['Bleid'] },
  { postcode: '6760', names: ['Ethe'] },
  { postcode: '6760', names: ['Ruette'] },
  { postcode: '6760', names: ['Virton'] },
  { postcode: '6761', names: ['Latour'] },
  { postcode: '6762', names: ['Saint-Mard'] },
  { postcode: '6767', names: ['Dampicourt'] },
  { postcode: '6767', names: ['Harnoncourt'] },
  { postcode: '6767', names: ['Lamorteau'] },
  { postcode: '6767', names: ['Torgny'] },
  { postcode: '6769', names: ['Gérouville'] },
  { postcode: '6769', names: ['Meix-Devant-Virton'] },
  { postcode: '6769', names: ['Robelmont'] },
  { postcode: '6769', names: ['Sommethonne'] },
  { postcode: '6769', names: ['Villers-La-Loue'] },
  { postcode: '6780', names: ['Hondelange'] },
  { postcode: '6780', names: ['Messancy'] },
  { postcode: '6780', names: ['Wolkrange'] },
  { postcode: '6781', names: ['Sélange'] },
  { postcode: '6782', names: ['Habergy'] },
  { postcode: '6790', names: ['Aubange'] },
  { postcode: '6791', names: ['Athus'] },
  { postcode: '6792', names: ['Halanzy'] },
  { postcode: '6792', names: ['Rachecourt'] },
  { postcode: '6800', names: ['Bras'] },
  { postcode: '6800', names: ['Freux'] },
  { postcode: '6800', names: ['Libramont-Chevigny'] },
  { postcode: '6800', names: ['Moircy'] },
  { postcode: '6800', names: ['Recogne'] },
  { postcode: '6800', names: ['Remagne'] },
  { postcode: '6800', names: ['Saint-Pierre'] },
  { postcode: '6800', names: ['Sainte-Marie-Chevigny'] },
  { postcode: '6810', names: ['Chiny'] },
  { postcode: '6810', names: ['Izel'] },
  { postcode: '6810', names: ['Jamoigne'] },
  { postcode: '6811', names: ['Les Bulles'] },
  { postcode: '6812', names: ['Suxy'] },
  { postcode: '6813', names: ['Termes'] },
  { postcode: '6820', names: ['Florenville'] },
  { postcode: '6820', names: ['Fontenoille'] },
  { postcode: '6820', names: ['Muno'] },
  { postcode: '6820', names: ['Sainte-Cécile'] },
  { postcode: '6821', names: ['Lacuisine'] },
  { postcode: '6823', names: ['Villers-Devant-Orval'] },
  { postcode: '6824', names: ['Chassepierre'] },
  { postcode: '6830', names: ['Bouillon'] },
  { postcode: '6830', names: ['Les Hayons'] },
  { postcode: '6830', names: ['Poupehan'] },
  { postcode: '6830', names: ['Rochehaut'] },
  { postcode: '6831', names: ['Noirfontaine'] },
  { postcode: '6832', names: ['Sensenruth'] },
  { postcode: '6833', names: ['Ucimont'] },
  { postcode: '6833', names: ['Vivy'] },
  { postcode: '6834', names: ['Bellevaux'] },
  { postcode: '6836', names: ['Dohan'] },
  { postcode: '6838', names: ['Corbion'] },
  { postcode: '6840', names: ['Grandvoir'] },
  { postcode: '6840', names: ['Grapfontaine'] },
  { postcode: '6840', names: ['Hamipré'] },
  { postcode: '6840', names: ['Longlier'] },
  { postcode: '6840', names: ['Neufchâteau'] },
  { postcode: '6840', names: ['Tournay'] },
  { postcode: '6850', names: ['Carlsbourg'] },
  { postcode: '6850', names: ['Offagne'] },
  { postcode: '6850', names: ['Paliseul'] },
  { postcode: '6851', names: ['Nollevaux'] },
  { postcode: '6852', names: ['Maissin'] },
  { postcode: '6852', names: ['Opont'] },
  { postcode: '6853', names: ['Framont'] },
  { postcode: '6856', names: ['Fays-Les-Veneurs'] },
  { postcode: '6860', names: ['Assenois'] },
  { postcode: '6860', names: ['Ebly'] },
  { postcode: '6860', names: ['Léglise'] },
  { postcode: '6860', names: ['Mellier'] },
  { postcode: '6860', names: ['Witry'] },
  { postcode: '6870', names: ['Arville'] },
  { postcode: '6870', names: ['Awenne'] },
  { postcode: '6870', names: ['Hatrival'] },
  { postcode: '6870', names: ['Mirwart'] },
  { postcode: '6870', names: ['Saint-Hubert'] },
  { postcode: '6870', names: ['Vesqueville'] },
  { postcode: '6880', names: ['Auby-Sur-Semois'] },
  { postcode: '6880', names: ['Bertrix'] },
  { postcode: '6880', names: ['Cugnon'] },
  { postcode: '6880', names: ['Jehonville'] },
  { postcode: '6880', names: ['Orgeo'] },
  { postcode: '6887', names: ['Herbeumont'] },
  { postcode: '6887', names: ['Saint-Médard'] },
  { postcode: '6887', names: ['Straimont'] },
  { postcode: '6890', names: ['Anloy'] },
  { postcode: '6890', names: ['Libin'] },
  { postcode: '6890', names: ['Ochamps'] },
  { postcode: '6890', names: ['Redu'] },
  { postcode: '6890', names: ['Smuid'] },
  { postcode: '6890', names: ['Transinne'] },
  { postcode: '6890', names: ['Villance'] },
  { postcode: '6900', names: ['Aye'] },
  { postcode: '6900', names: ['Hargimont'] },
  { postcode: '6900', names: ['Humain'] },
  { postcode: '6900', names: ['Marche-En-Famenne'] },
  { postcode: '6900', names: ['On'] },
  { postcode: '6900', names: ['Roy'] },
  { postcode: '6900', names: ['Waha'] },
  { postcode: '6920', names: ['Sohier'] },
  { postcode: '6920', names: ['Wellin'] },
  { postcode: '6921', names: ['Chanly'] },
  { postcode: '6922', names: ['Halma'] },
  { postcode: '6924', names: ['Lomprez'] },
  { postcode: '6927', names: ['Bure'] },
  { postcode: '6927', names: ['Grupont'] },
  { postcode: '6927', names: ['Resteigne'] },
  { postcode: '6927', names: ['Tellin'] },
  { postcode: '6929', names: ['Daverdisse'] },
  { postcode: '6929', names: ['Gembes'] },
  { postcode: '6929', names: ['Haut-Fays'] },
  { postcode: '6929', names: ['Porcheresse'] },
  { postcode: '6940', names: ['Barvaux-Sur-Ourthe'] },
  { postcode: '6940', names: ['Durbuy'] },
  { postcode: '6940', names: ['Grandhan'] },
  { postcode: '6940', names: ['Septon'] },
  { postcode: '6940', names: ['Wéris'] },
  { postcode: '6941', names: ['Bende'] },
  { postcode: '6941', names: ['Bomal-Sur-Ourthe'] },
  { postcode: '6941', names: ['Borlon'] },
  { postcode: '6941', names: ['Heyd'] },
  { postcode: '6941', names: ['Izier'] },
  { postcode: '6941', names: ['Tohogne'] },
  { postcode: '6941', names: ['Villers-Sainte-Gertrude'] },
  { postcode: '6950', names: ['Harsin'] },
  { postcode: '6950', names: ['Nassogne'] },
  { postcode: '6951', names: ['Bande'] },
  { postcode: '6952', names: ['Grune'] },
  { postcode: '6953', names: ['Ambly'] },
  { postcode: '6953', names: ['Forrières'] },
  { postcode: '6953', names: ['Lesterny'] },
  { postcode: '6953', names: ['Masbourg'] },
  { postcode: '6960', names: ['Dochamps'] },
  { postcode: '6960', names: ['Grandménil'] },
  { postcode: '6960', names: ['Harre'] },
  { postcode: '6960', names: ['Malempré'] },
  { postcode: '6960', names: ['Odeigne'] },
  { postcode: '6960', names: ['Vaux-Chavanne'] },
  { postcode: '6970', names: ['Tenneville'] },
  { postcode: '6971', names: ['Champlon'] },
  { postcode: '6972', names: ['Erneuville'] },
  { postcode: '6980', names: ['Beausaint'] },
  { postcode: '6980', names: ['La Roche-En-Ardenne'] },
  { postcode: '6982', names: ['Samrée'] },
  { postcode: '6983', names: ['Ortho'] },
  { postcode: '6984', names: ['Hives'] },
  { postcode: '6986', names: ['Halleux'] },
  { postcode: '6987', names: ['Beffe'] },
  { postcode: '6987', names: ['Hodister'] },
  { postcode: '6987', names: ['Marcourt'] },
  { postcode: '6987', names: ['Rendeux'] },
  { postcode: '6990', names: ['Fronville'] },
  { postcode: '6990', names: ['Hampteau'] },
  { postcode: '6990', names: ['Hotton'] },
  { postcode: '6990', names: ['Marenne'] },
  { postcode: '6997', names: ['Amonines'] },
  { postcode: '6997', names: ['Erezée'] },
  { postcode: '6997', names: ['Mormont'] },
  { postcode: '6997', names: ['Soy'] },
  { postcode: '7000', names: ['Mons'] },
  { postcode: '7010', names: ['SHAPE'] },
  { postcode: '7011', names: ['Ghlin'] },
  { postcode: '7012', names: ['Flénu'] },
  { postcode: '7012', names: ['Jemappes'] },
  { postcode: '7020', names: ['Maisières'] },
  { postcode: '7020', names: ['Nimy'] },
  { postcode: '7021', names: ['Havré'] },
  { postcode: '7022', names: ['Harmignies'] },
  { postcode: '7022', names: ['Harveng'] },
  { postcode: '7022', names: ['Hyon'] },
  { postcode: '7022', names: ['Mesvin'] },
  { postcode: '7022', names: ['Nouvelles'] },
  { postcode: '7024', names: ['Ciply'] },
  { postcode: '7030', names: ['Saint-Symphorien'] },
  { postcode: '7031', names: ['Villers-Saint-Ghislain'] },
  { postcode: '7032', names: ['Spiennes'] },
  { postcode: '7033', names: ['Cuesmes'] },
  { postcode: '7034', names: ['Obourg'] },
  { postcode: '7034', names: ['Saint-Denis'] },
  { postcode: '7040', names: ['Asquillies'] },
  { postcode: '7040', names: ['Aulnois'] },
  { postcode: '7040', names: ['Blaregnies'] },
  { postcode: '7040', names: ['Bougnies'] },
  { postcode: '7040', names: ['Genly'] },
  { postcode: '7040', names: ['Goegnies-Chaussée'] },
  { postcode: '7040', names: ['Quévy-Le-Grand'] },
  { postcode: '7040', names: ['Quévy-Le-Petit'] },
  { postcode: '7041', names: ['Givry'] },
  { postcode: '7041', names: ['Havay'] },
  { postcode: '7050', names: ['Erbaut'] },
  { postcode: '7050', names: ['Erbisoeul'] },
  { postcode: '7050', names: ['Herchies'] },
  { postcode: '7050', names: ['Jurbise'] },
  { postcode: '7050', names: ['Masnuy-Saint-Jean'] },
  { postcode: '7050', names: ['Masnuy-Saint-Pierre'] },
  { postcode: '7060', names: ['Horrues'] },
  { postcode: '7060', names: ['Soignies'] },
  { postcode: '7061', names: ['Casteau'] },
  { postcode: '7061', names: ['Thieusies'] },
  { postcode: '7062', names: ['Naast'] },
  { postcode: '7063', names: ['Chaussée-Notre-Dame-Louvignies'] },
  { postcode: '7063', names: ['Neufvilles'] },
  { postcode: '7070', names: ['Gottignies'] },
  { postcode: '7070', names: ['Le Roeulx'] },
  { postcode: '7070', names: ['Mignault'] },
  { postcode: '7070', names: ['Thieu'] },
  { postcode: '7070', names: ['Ville-Sur-Haine'] },
  { postcode: '7080', names: ['Eugies'] },
  { postcode: '7080', names: ['Frameries'] },
  { postcode: '7080', names: ['La Bouverie'] },
  { postcode: '7080', names: ['Noirchain'] },
  { postcode: '7080', names: ['Sars-La-Bruyère'] },
  { postcode: '7090', names: ['Braine-Le-Comte'] },
  { postcode: '7090', names: ['Hennuyères'] },
  { postcode: '7090', names: ['Henripont'] },
  { postcode: '7090', names: ['Petit-Roeulx-Lez-Braine'] },
  { postcode: '7090', names: ['Ronquières'] },
  { postcode: '7090', names: ['Steenkerque'] },
  { postcode: '7100', names: ['Haine-Saint-Paul'] },
  { postcode: '7100', names: ['Haine-Saint-Pierre'] },
  { postcode: '7100', names: ['La Louvière'] },
  { postcode: '7100', names: ['Saint-Vaast'] },
  { postcode: '7100', names: ['Trivières'] },
  { postcode: '7110', names: ['Boussoit'] },
  { postcode: '7110', names: ['Houdeng-Aimeries'] },
  { postcode: '7110', names: ['Houdeng-Goegnies'] },
  { postcode: '7110', names: ['Maurage'] },
  { postcode: '7110', names: ['Strépy-Bracquegnies'] },
  { postcode: '7120', names: ['Croix-Lez-Rouveroy'] },
  { postcode: '7120', names: ['Estinnes-Au-Mont'] },
  { postcode: '7120', names: ['Estinnes-Au-Val'] },
  { postcode: '7120', names: ['Fauroeulx'] },
  { postcode: '7120', names: ['Haulchin'] },
  { postcode: '7120', names: ['Peissant'] },
  { postcode: '7120', names: ['Rouveroy'] },
  { postcode: '7120', names: ['Vellereille-Le-Sec'] },
  { postcode: '7120', names: ['Vellereille-Les-Brayeux'] },
  { postcode: '7130', names: ['Battignies'] },
  { postcode: '7130', names: ['Binche'] },
  { postcode: '7130', names: ['Bray'] },
  { postcode: '7131', names: ['Waudrez'] },
  { postcode: '7133', names: ['Buvrinnes'] },
  { postcode: '7134', names: ['Epinois'] },
  { postcode: '7134', names: ['Leval-Trahegnies'] },
  { postcode: '7134', names: ['Péronnes-Lez-Binche'] },
  { postcode: '7134', names: ['Ressaix'] },
  { postcode: '7140', names: ['Morlanwelz-Mariemont'] },
  { postcode: '7141', names: ['Carnières'] },
  { postcode: '7141', names: ['Mont-Sainte-Aldegonde'] },
  { postcode: '7160', names: ['Chapelle-Lez-Herlaimont'] },
  { postcode: '7160', names: ['Godarville'] },
  { postcode: '7160', names: ['Piéton'] },
  { postcode: '7170', names: ['Bellecourt'] },
  { postcode: '7170', names: ["Bois-D'Haine"] },
  { postcode: '7170', names: ['Fayt-Lez-Manage'] },
  { postcode: '7170', names: ['La Hestre'] },
  { postcode: '7170', names: ['Manage'] },
  { postcode: '7180', names: ['Seneffe'] },
  { postcode: '7181', names: ['Arquennes'] },
  { postcode: '7181', names: ['Familleureux'] },
  { postcode: '7181', names: ['Feluy'] },
  { postcode: '7181', names: ['Petit-Roeulx-Lez-Nivelles'] },
  { postcode: '7190', names: ["Ecaussinnes-D'Enghien"] },
  { postcode: '7190', names: ['Marche-Lez-Ecaussinnes'] },
  { postcode: '7191', names: ['Ecaussinnes-Lalaing'] },
  { postcode: '7300', names: ['Boussu'] },
  { postcode: '7301', names: ['Hornu'] },
  { postcode: '7320', names: ['Bernissart'] },
  { postcode: '7321', names: ['Blaton'] },
  { postcode: '7321', names: ['Harchies'] },
  { postcode: '7322', names: ['Pommeroeul'] },
  { postcode: '7322', names: ['Ville-Pommeroeul'] },
  { postcode: '7330', names: ['Saint-Ghislain'] },
  { postcode: '7331', names: ['Baudour'] },
  { postcode: '7332', names: ['Neufmaison'] },
  { postcode: '7332', names: ['Sirault'] },
  { postcode: '7333', names: ['Tertre'] },
  { postcode: '7334', names: ['Hautrage'] },
  { postcode: '7334', names: ['Villerot'] },
  { postcode: '7340', names: ['Colfontaine'] },
  { postcode: '7340', names: ['Pâturages'] },
  { postcode: '7340', names: ['Warquignies'] },
  { postcode: '7340', names: ['Wasmes'] },
  { postcode: '7350', names: ['Hainin'] },
  { postcode: '7350', names: ['Hensies'] },
  { postcode: '7350', names: ['Montroeul-Sur-Haine'] },
  { postcode: '7350', names: ['Thulin'] },
  { postcode: '7370', names: ['Blaugies'] },
  { postcode: '7370', names: ['Dour'] },
  { postcode: '7370', names: ['Elouges'] },
  { postcode: '7370', names: ['Wihéries'] },
  { postcode: '7380', names: ['Baisieux'] },
  { postcode: '7380', names: ['Quiévrain'] },
  { postcode: '7382', names: ['Audregnies'] },
  { postcode: '7387', names: ['Angre'] },
  { postcode: '7387', names: ['Angreau'] },
  { postcode: '7387', names: ['Athis'] },
  { postcode: '7387', names: ['Autreppe'] },
  { postcode: '7387', names: ['Erquennes'] },
  { postcode: '7387', names: ['Fayt-Le-Franc'] },
  { postcode: '7387', names: ['Marchipont'] },
  { postcode: '7387', names: ['Montignies-Sur-Roc'] },
  { postcode: '7387', names: ['Onnezies'] },
  { postcode: '7387', names: ['Roisin'] },
  { postcode: '7390', names: ['Quaregnon'] },
  { postcode: '7390', names: ['Wasmuel'] },
  { postcode: '7500', names: ['Ere'] },
  { postcode: '7500', names: ['Saint-Maur'] },
  { postcode: '7500', names: ['Tournai'] },
  { postcode: '7501', names: ['Orcq'] },
  { postcode: '7502', names: ['Esplechin'] },
  { postcode: '7503', names: ['Froyennes'] },
  { postcode: '7504', names: ['Froidmont'] },
  { postcode: '7506', names: ['Willemeau'] },
  { postcode: '7510', names: ['3 Suisses'] },
  { postcode: '7511', names: ['Vitrine Magique'] },
  { postcode: '7512', names: ['DANIEL JOUVANCE'] },
  { postcode: '7513', names: ['Yves Rocher'] },
  { postcode: '7520', names: ['Ramegnies-Chin'] },
  { postcode: '7520', names: ['Templeuve'] },
  { postcode: '7521', names: ['Chercq'] },
  { postcode: '7522', names: ['Blandain'] },
  { postcode: '7522', names: ['Hertain'] },
  { postcode: '7522', names: ['Lamain'] },
  { postcode: '7522', names: ['Marquain'] },
  { postcode: '7530', names: ['Gaurain-Ramecroix'] },
  { postcode: '7531', names: ['Havinnes'] },
  { postcode: '7532', names: ['Béclers'] },
  { postcode: '7533', names: ['Thimougies'] },
  { postcode: '7534', names: ['Barry'] },
  { postcode: '7534', names: ['Maulde'] },
  { postcode: '7536', names: ['Vaulx'] },
  { postcode: '7538', names: ['Vezon'] },
  { postcode: '7540', names: ['Kain'] },
  { postcode: '7540', names: ['Melles'] },
  { postcode: '7540', names: ['Quartes'] },
  { postcode: '7540', names: ['Rumillies'] },
  { postcode: '7542', names: ['Mont-Saint-Aubert'] },
  { postcode: '7543', names: ['Mourcourt'] },
  { postcode: '7548', names: ['Warchin'] },
  { postcode: '7600', names: ['Péruwelz'] },
  { postcode: '7601', names: ['Roucourt'] },
  { postcode: '7602', names: ['Bury'] },
  { postcode: '7603', names: ['Bon-Secours'] },
  { postcode: '7604', names: ['Baugnies'] },
  { postcode: '7604', names: ['Braffe'] },
  { postcode: '7604', names: ['Brasménil'] },
  { postcode: '7604', names: ['Callenelle'] },
  { postcode: '7604', names: ['Wasmes-Audemez-Briffoeil'] },
  { postcode: '7608', names: ['Wiers'] },
  { postcode: '7610', names: ['Rumes'] },
  { postcode: '7611', names: ['La Glanerie'] },
  { postcode: '7618', names: ['Taintignies'] },
  { postcode: '7620', names: ['Bléharies'] },
  { postcode: '7620', names: ['Guignies'] },
  { postcode: '7620', names: ['Hollain'] },
  { postcode: '7620', names: ['Jollain-Merlin'] },
  { postcode: '7620', names: ['Wez-Velvain'] },
  { postcode: '7621', names: ['Lesdain'] },
  { postcode: '7622', names: ['Laplaigne'] },
  { postcode: '7623', names: ['Rongy'] },
  { postcode: '7624', names: ['Howardries'] },
  { postcode: '7640', names: ['Antoing'] },
  { postcode: '7640', names: ['Maubray'] },
  { postcode: '7640', names: ['Péronnes-Lez-Antoing'] },
  { postcode: '7641', names: ['Bruyelle'] },
  { postcode: '7642', names: ['Calonne'] },
  { postcode: '7643', names: ['Fontenoy'] },
  { postcode: '7700', names: ['Luingne'] },
  { postcode: '7700', names: ['Mouscron'] },
  { postcode: '7711', names: ['Dottignies'] },
  { postcode: '7712', names: ['Herseaux'] },
  { postcode: '7730', names: ['Bailleul'] },
  { postcode: '7730', names: ['Estaimbourg'] },
  { postcode: '7730', names: ['Estaimpuis'] },
  { postcode: '7730', names: ['Evregnies'] },
  { postcode: '7730', names: ['Leers-Nord'] },
  { postcode: '7730', names: ['Néchin'] },
  { postcode: '7730', names: ['Saint-Léger'] },
  { postcode: '7740', names: ['Pecq'] },
  { postcode: '7740', names: ['Warcoing'] },
  { postcode: '7742', names: ['Hérinnes-Lez-Pecq'] },
  { postcode: '7743', names: ['Esquelmes'] },
  { postcode: '7743', names: ['Obigies'] },
  { postcode: '7750', names: ['Amougies'] },
  { postcode: '7750', names: ['Anseroeul'] },
  { postcode: '7750', names: ['Orroir'] },
  { postcode: '7750', names: ['Russeignies'] },
  { postcode: '7760', names: ['Celles'] },
  { postcode: '7760', names: ['Escanaffles'] },
  { postcode: '7760', names: ['Molenbaix'] },
  { postcode: '7760', names: ['Popuelles'] },
  { postcode: '7760', names: ['Pottes'] },
  { postcode: '7760', names: ['Velaines'] },
  { postcode: '7780', names: ['Comines'] },
  { postcode: '7780', names: ['Comines-Warneton'] },
  { postcode: '7781', names: ['Houthem'] },
  { postcode: '7782', names: ['Ploegsteert'] },
  { postcode: '7783', names: ['Bizet'] },
  { postcode: '7784', names: ['Bas-Warneton'] },
  { postcode: '7784', names: ['Warneton'] },
  { postcode: '7800', names: ['Ath'] },
  { postcode: '7800', names: ['Lanquesaint'] },
  { postcode: '7801', names: ['Irchonwelz'] },
  { postcode: '7802', names: ['Ormeignies'] },
  { postcode: '7803', names: ['Bouvignies'] },
  { postcode: '7804', names: ['Ostiches'] },
  { postcode: '7804', names: ['Rebaix'] },
  { postcode: '7810', names: ['Maffle'] },
  { postcode: '7811', names: ['Arbre'] },
  { postcode: '7812', names: ['Houtaing'] },
  { postcode: '7812', names: ['Ligne'] },
  { postcode: '7812', names: ['Mainvault'] },
  { postcode: '7812', names: ['Moulbaix'] },
  { postcode: '7812', names: ['Villers-Notre-Dame'] },
  { postcode: '7812', names: ['Villers-Saint-Amand'] },
  { postcode: '7822', names: ['Ghislenghien'] },
  { postcode: '7822', names: ['Isières'] },
  { postcode: '7822', names: ["Meslin-L'Evêque"] },
  { postcode: '7823', names: ['Gibecq'] },
  { postcode: '7830', names: ['Bassilly'] },
  { postcode: '7830', names: ['Fouleng'] },
  { postcode: '7830', names: ['Gondregnies'] },
  { postcode: '7830', names: ['Graty'] },
  { postcode: '7830', names: ['Hellebecq'] },
  { postcode: '7830', names: ['Hoves'] },
  { postcode: '7830', names: ['Silly'] },
  { postcode: '7830', names: ['Thoricourt'] },
  { postcode: '7850', names: ['Enghien'] },
  { postcode: '7850', names: ['Marcq'] },
  { postcode: '7850', names: ['Petit-Enghien'] },
  { postcode: '7860', names: ['Lessines'] },
  { postcode: '7861', names: ['Papignies'] },
  { postcode: '7861', names: ['Wannebecq'] },
  { postcode: '7862', names: ['Ogy'] },
  { postcode: '7863', names: ['Ghoy'] },
  { postcode: '7864', names: ['Deux-Acren'] },
  { postcode: '7866', names: ['Bois-De-Lessines'] },
  { postcode: '7866', names: ['Ollignies'] },
  { postcode: '7870', names: ['Bauffe'] },
  { postcode: '7870', names: ['Cambron-Saint-Vincent'] },
  { postcode: '7870', names: ['Lens'] },
  { postcode: '7870', names: ['Lombise'] },
  { postcode: '7870', names: ['Montignies-Lez-Lens'] },
  { postcode: '7880', names: ['Flobecq'] },
  { postcode: '7890', names: ['Ellezelles'] },
  { postcode: '7890', names: ['Lahamaide'] },
  { postcode: '7890', names: ['Wodecq'] },
  { postcode: '7900', names: ['Grandmetz'] },
  { postcode: '7900', names: ['Leuze-En-Hainaut'] },
  { postcode: '7901', names: ['Thieulain'] },
  { postcode: '7903', names: ['Blicquy'] },
  { postcode: '7903', names: ['Chapelle-À-Oie'] },
  { postcode: '7903', names: ['Chapelle-À-Wattines'] },
  { postcode: '7904', names: ['Pipaix'] },
  { postcode: '7904', names: ['Tourpes'] },
  { postcode: '7904', names: ['Willaupuis'] },
  { postcode: '7906', names: ['Gallaix'] },
  { postcode: '7910', names: ['Anvaing'] },
  { postcode: '7910', names: ['Arc-Ainières'] },
  { postcode: '7910', names: ['Arc-Wattripont'] },
  { postcode: '7910', names: ['Cordes'] },
  { postcode: '7910', names: ['Ellignies-Lez-Frasnes'] },
  { postcode: '7910', names: ['Forest'] },
  { postcode: '7910', names: ['Wattripont'] },
  { postcode: '7911', names: ['Buissenal'] },
  { postcode: '7911', names: ['Frasnes-Lez-Buissenal'] },
  { postcode: '7911', names: ['Hacquegnies'] },
  { postcode: '7911', names: ['Herquegies'] },
  { postcode: '7911', names: ['Montroeul-Au-Bois'] },
  { postcode: '7911', names: ['Moustier'] },
  { postcode: '7911', names: ['Oeudeghien'] },
  { postcode: '7912', names: ['Dergneau'] },
  { postcode: '7912', names: ['Saint-Sauveur'] },
  { postcode: '7940', names: ['Brugelette'] },
  { postcode: '7940', names: ['Cambron-Casteau'] },
  { postcode: '7941', names: ['Attre'] },
  { postcode: '7942', names: ['Mévergnies-Lez-Lens'] },
  { postcode: '7943', names: ['Gages'] },
  { postcode: '7950', names: ['Chièvres'] },
  { postcode: '7950', names: ['Grosage'] },
  { postcode: '7950', names: ['Huissignies'] },
  { postcode: '7950', names: ['Ladeuze'] },
  { postcode: '7950', names: ['Tongre-Saint-Martin'] },
  { postcode: '7951', names: ['Tongre-Notre-Dame'] },
  { postcode: '7970', names: ['Beloeil'] },
  { postcode: '7971', names: ['Basècles'] },
  { postcode: '7971', names: ['Ramegnies'] },
  { postcode: '7971', names: ['Thumaide'] },
  { postcode: '7971', names: ['Wadelincourt'] },
  { postcode: '7972', names: ['Aubechies'] },
  { postcode: '7972', names: ['Ellignies-Sainte-Anne'] },
  { postcode: '7972', names: ['Quevaucamps'] },
  { postcode: '7973', names: ['Grandglise'] },
  { postcode: '7973', names: ['Stambruges'] },
  { postcode: '8000', names: ['Brugge'] },
  { postcode: '8000', names: ['Koolkerke'] },
  { postcode: '8020', names: ['Hertsberge'] },
  { postcode: '8020', names: ['Oostkamp'] },
  { postcode: '8020', names: ['Ruddervoorde'] },
  { postcode: '8020', names: ['Waardamme'] },
  { postcode: '8200', names: ['Sint-Andries'] },
  { postcode: '8200', names: ['Sint-Michiels'] },
  { postcode: '8210', names: ['Loppem'] },
  { postcode: '8210', names: ['Veldegem'] },
  { postcode: '8210', names: ['Zedelgem'] },
  { postcode: '8211', names: ['Aartrijke'] },
  { postcode: '8300', names: ['Knokke'] },
  { postcode: '8300', names: ['Westkapelle'] },
  { postcode: '8301', names: ['Heist-Aan-Zee'] },
  { postcode: '8301', names: ['Ramskapelle'] },
  { postcode: '8310', names: ['Assebroek'] },
  { postcode: '8310', names: ['Sint-Kruis'] },
  { postcode: '8340', names: ['Damme'] },
  { postcode: '8340', names: ['Hoeke'] },
  { postcode: '8340', names: ['Lapscheure'] },
  { postcode: '8340', names: ['Moerkerke'] },
  { postcode: '8340', names: ['Oostkerke'] },
  { postcode: '8340', names: ['Sijsele'] },
  { postcode: '8370', names: ['Blankenberge'] },
  { postcode: '8370', names: ['Uitkerke'] },
  { postcode: '8377', names: ['Houtave'] },
  { postcode: '8377', names: ['Meetkerke'] },
  { postcode: '8377', names: ['Nieuwmunster'] },
  { postcode: '8377', names: ['Zuienkerke'] },
  { postcode: '8380', names: ['Dudzele'] },
  { postcode: '8380', names: ['Lissewege'] },
  { postcode: '8380', names: ['Zeebrugge'] },
  { postcode: '8400', names: ['Oostende'] },
  { postcode: '8400', names: ['Stene'] },
  { postcode: '8400', names: ['Zandvoorde'] },
  { postcode: '8420', names: ['De Haan'] },
  { postcode: '8420', names: ['Klemskerke'] },
  { postcode: '8420', names: ['Wenduine'] },
  { postcode: '8421', names: ['Vlissegem'] },
  { postcode: '8430', names: ['Middelkerke'] },
  { postcode: '8431', names: ['Wilskerke'] },
  { postcode: '8432', names: ['Leffinge'] },
  { postcode: '8433', names: ['Mannekensvere'] },
  { postcode: '8433', names: ['Schore'] },
  { postcode: '8433', names: ['Sint-Pieters-Kapelle'] },
  { postcode: '8433', names: ['Slijpe'] },
  { postcode: '8434', names: ['Lombardsijde'] },
  { postcode: '8434', names: ['Westende'] },
  { postcode: '8450', names: ['Bredene'] },
  { postcode: '8460', names: ['Ettelgem'] },
  { postcode: '8460', names: ['Oudenburg'] },
  { postcode: '8460', names: ['Roksem'] },
  { postcode: '8460', names: ['Westkerke'] },
  { postcode: '8470', names: ['Gistel'] },
  { postcode: '8470', names: ['Moere'] },
  { postcode: '8470', names: ['Snaaskerke'] },
  { postcode: '8470', names: ['Zevekote'] },
  { postcode: '8480', names: ['Bekegem'] },
  { postcode: '8480', names: ['Eernegem'] },
  { postcode: '8480', names: ['Ichtegem'] },
  { postcode: '8490', names: ['Jabbeke'] },
  { postcode: '8490', names: ['Snellegem'] },
  { postcode: '8490', names: ['Stalhille'] },
  { postcode: '8490', names: ['Varsenare'] },
  { postcode: '8490', names: ['Zerkegem'] },
  { postcode: '8500', names: ['Kortrijk'] },
  { postcode: '8501', names: ['Bissegem'] },
  { postcode: '8501', names: ['Heule'] },
  { postcode: '8510', names: ['Bellegem'] },
  { postcode: '8510', names: ['Kooigem'] },
  { postcode: '8510', names: ['Marke'] },
  { postcode: '8510', names: ['Rollegem'] },
  { postcode: '8511', names: ['Aalbeke'] },
  { postcode: '8520', names: ['Kuurne'] },
  { postcode: '8530', names: ['Harelbeke'] },
  { postcode: '8531', names: ['Bavikhove'] },
  { postcode: '8531', names: ['Hulste'] },
  { postcode: '8540', names: ['Deerlijk'] },
  { postcode: '8550', names: ['Zwevegem'] },
  { postcode: '8551', names: ['Heestert'] },
  { postcode: '8552', names: ['Moen'] },
  { postcode: '8553', names: ['Otegem'] },
  { postcode: '8554', names: ['Sint-Denijs'] },
  { postcode: '8560', names: ['Gullegem'] },
  { postcode: '8560', names: ['Moorsele'] },
  { postcode: '8560', names: ['Wevelgem'] },
  { postcode: '8570', names: ['Anzegem'] },
  { postcode: '8570', names: ['Gijzelbrechtegem'] },
  { postcode: '8570', names: ['Ingooigem'] },
  { postcode: '8570', names: ['Vichte'] },
  { postcode: '8572', names: ['Kaster'] },
  { postcode: '8573', names: ['Tiegem'] },
  { postcode: '8580', names: ['Avelgem'] },
  { postcode: '8581', names: ['Kerkhove'] },
  { postcode: '8581', names: ['Waarmaarde'] },
  { postcode: '8582', names: ['Outrijve'] },
  { postcode: '8583', names: ['Bossuit'] },
  { postcode: '8587', names: ['Espierres'] },
  { postcode: '8587', names: ['Helchin'] },
  { postcode: '8600', names: ['Beerst'] },
  { postcode: '8600', names: ['Diksmuide'] },
  { postcode: '8600', names: ['Driekapellen'] },
  { postcode: '8600', names: ['Esen'] },
  { postcode: '8600', names: ['Kaaskerke'] },
  { postcode: '8600', names: ['Keiem'] },
  { postcode: '8600', names: ['Lampernisse'] },
  { postcode: '8600', names: ['Leke'] },
  { postcode: '8600', names: ['Nieuwkapelle'] },
  { postcode: '8600', names: ['Oostkerke'] },
  { postcode: '8600', names: ['Oudekapelle'] },
  { postcode: '8600', names: ['Pervijze'] },
  { postcode: '8600', names: ['Sint-Jacobs-Kapelle'] },
  { postcode: '8600', names: ['Stuivekenskerke'] },
  { postcode: '8600', names: ['Vladslo'] },
  { postcode: '8600', names: ['Woumen'] },
  { postcode: '8610', names: ['Handzame'] },
  { postcode: '8610', names: ['Kortemark'] },
  { postcode: '8610', names: ['Werken'] },
  { postcode: '8610', names: ['Zarren'] },
  { postcode: '8620', names: ['Nieuwpoort'] },
  { postcode: '8620', names: ['Ramskapelle'] },
  { postcode: '8620', names: ['Sint-Joris'] },
  { postcode: '8630', names: ['Avekapelle'] },
  { postcode: '8630', names: ['Booitshoeke'] },
  { postcode: '8630', names: ['Bulskamp'] },
  { postcode: '8630', names: ['De Moeren'] },
  { postcode: '8630', names: ['Eggewaartskapelle'] },
  { postcode: '8630', names: ['Houtem'] },
  { postcode: '8630', names: ['Steenkerke'] },
  { postcode: '8630', names: ['Veurne'] },
  { postcode: '8630', names: ['Vinkem'] },
  { postcode: '8630', names: ['Wulveringem'] },
  { postcode: '8630', names: ['Zoutenaaie'] },
  { postcode: '8640', names: ['Oostvleteren'] },
  { postcode: '8640', names: ['Westvleteren'] },
  { postcode: '8640', names: ['Woesten'] },
  { postcode: '8647', names: ['Lo'] },
  { postcode: '8647', names: ['Noordschote'] },
  { postcode: '8647', names: ['Pollinkhove'] },
  { postcode: '8647', names: ['Reninge'] },
  { postcode: '8650', names: ['Houthulst'] },
  { postcode: '8650', names: ['Klerken'] },
  { postcode: '8650', names: ['Merkem'] },
  { postcode: '8660', names: ['Adinkerke'] },
  { postcode: '8660', names: ['De Panne'] },
  { postcode: '8670', names: ['Koksijde'] },
  { postcode: '8670', names: ['Oostduinkerke'] },
  { postcode: '8670', names: ['Wulpen'] },
  { postcode: '8680', names: ['Bovekerke'] },
  { postcode: '8680', names: ['Koekelare'] },
  { postcode: '8680', names: ['Zande'] },
  { postcode: '8690', names: ['Alveringem'] },
  { postcode: '8690', names: ['Hoogstade'] },
  { postcode: '8690', names: ['Oeren'] },
  { postcode: '8690', names: ['Sint-Rijkers'] },
  { postcode: '8691', names: ['Beveren-Aan-De-Ijzer'] },
  { postcode: '8691', names: ['Gijverinkhove'] },
  { postcode: '8691', names: ['Izenberge'] },
  { postcode: '8691', names: ['Leisele'] },
  { postcode: '8691', names: ['Stavele'] },
  { postcode: '8700', names: ['Aarsele'] },
  { postcode: '8700', names: ['Kanegem'] },
  { postcode: '8700', names: ['Schuiferskapelle'] },
  { postcode: '8700', names: ['Tielt'] },
  { postcode: '8710', names: ['Ooigem'] },
  { postcode: '8710', names: ['Sint-Baafs-Vijve'] },
  { postcode: '8710', names: ['Wielsbeke'] },
  { postcode: '8720', names: ['Dentergem'] },
  { postcode: '8720', names: ['Markegem'] },
  { postcode: '8720', names: ['Oeselgem'] },
  { postcode: '8720', names: ['Wakken'] },
  { postcode: '8730', names: ['Beernem'] },
  { postcode: '8730', names: ['Oedelem'] },
  { postcode: '8730', names: ['Sint-Joris'] },
  { postcode: '8740', names: ['Egem'] },
  { postcode: '8740', names: ['Pittem'] },
  { postcode: '8750', names: ['Wingene'] },
  { postcode: '8750', names: ['Zwevezele'] },
  { postcode: '8755', names: ['Ruiselede'] },
  { postcode: '8760', names: ['Meulebeke'] },
  { postcode: '8770', names: ['Ingelmunster'] },
  { postcode: '8780', names: ['Oostrozebeke'] },
  { postcode: '8790', names: ['Waregem'] },
  { postcode: '8791', names: ['Beveren'] },
  { postcode: '8792', names: ['Desselgem'] },
  { postcode: '8793', names: ['Sint-Eloois-Vijve'] },
  { postcode: '8800', names: ['Beveren'] },
  { postcode: '8800', names: ['Oekene'] },
  { postcode: '8800', names: ['Roeselare'] },
  { postcode: '8800', names: ['Rumbeke'] },
  { postcode: '8810', names: ['Lichtervelde'] },
  { postcode: '8820', names: ['Torhout'] },
  { postcode: '8830', names: ['Gits'] },
  { postcode: '8830', names: ['Hooglede'] },
  { postcode: '8840', names: ['Oostnieuwkerke'] },
  { postcode: '8840', names: ['Staden'] },
  { postcode: '8840', names: ['Westrozebeke'] },
  { postcode: '8850', names: ['Ardooie'] },
  { postcode: '8851', names: ['Koolskamp'] },
  { postcode: '8860', names: ['Lendelede'] },
  { postcode: '8870', names: ['Emelgem'] },
  { postcode: '8870', names: ['Izegem'] },
  { postcode: '8870', names: ['Kachtem'] },
  { postcode: '8880', names: ['Ledegem'] },
  { postcode: '8880', names: ['Rollegem-Kapelle'] },
  { postcode: '8880', names: ['Sint-Eloois-Winkel'] },
  { postcode: '8890', names: ['Dadizele'] },
  { postcode: '8890', names: ['Moorslede'] },
  { postcode: '8900', names: ['Brielen'] },
  { postcode: '8900', names: ['Dikkebus'] },
  { postcode: '8900', names: ['Ieper'] },
  { postcode: '8900', names: ['Sint-Jan'] },
  { postcode: '8902', names: ['Hollebeke'] },
  { postcode: '8902', names: ['Voormezele'] },
  { postcode: '8902', names: ['Zillebeke'] },
  { postcode: '8904', names: ['Boezinge'] },
  { postcode: '8904', names: ['Zuidschote'] },
  { postcode: '8906', names: ['Elverdinge'] },
  { postcode: '8908', names: ['Vlamertinge'] },
  { postcode: '8920', names: ['Bikschote'] },
  { postcode: '8920', names: ['Langemark'] },
  { postcode: '8920', names: ['Poelkapelle'] },
  { postcode: '8930', names: ['Lauwe'] },
  { postcode: '8930', names: ['Menen'] },
  { postcode: '8930', names: ['Rekkem'] },
  { postcode: '8940', names: ['Geluwe'] },
  { postcode: '8940', names: ['Wervik'] },
  { postcode: '8950', names: ['Nieuwkerke'] },
  { postcode: '8951', names: ['Dranouter'] },
  { postcode: '8952', names: ['Wulvergem'] },
  { postcode: '8953', names: ['Wijtschate'] },
  { postcode: '8954', names: ['Westouter'] },
  { postcode: '8956', names: ['Kemmel'] },
  { postcode: '8957', names: ['Messines'] },
  { postcode: '8958', names: ['Loker'] },
  { postcode: '8970', names: ['Poperinge'] },
  { postcode: '8970', names: ['Reningelst'] },
  { postcode: '8972', names: ['Krombeke'] },
  { postcode: '8972', names: ['Proven'] },
  { postcode: '8972', names: ['Roesbrugge-Haringe'] },
  { postcode: '8978', names: ['Watou'] },
  { postcode: '8980', names: ['Beselare'] },
  { postcode: '8980', names: ['Geluveld'] },
  { postcode: '8980', names: ['Passendale'] },
  { postcode: '8980', names: ['Zandvoorde'] },
  { postcode: '8980', names: ['Zonnebeke'] },
  { postcode: '9000', names: ['Gent'] },
  { postcode: '9030', names: ['Mariakerke'] },
  { postcode: '9031', names: ['Drongen'] },
  { postcode: '9032', names: ['Wondelgem'] },
  { postcode: '9040', names: ['Sint-Amandsberg'] },
  { postcode: '9041', names: ['Oostakker'] },
  { postcode: '9042', names: ['Desteldonk'] },
  { postcode: '9042', names: ['Mendonk'] },
  { postcode: '9042', names: ['Sint-Kruis-Winkel'] },
  { postcode: '9050', names: ['Gentbrugge'] },
  { postcode: '9050', names: ['Ledeberg'] },
  { postcode: '9051', names: ['Afsnee'] },
  { postcode: '9051', names: ['Sint-Denijs-Westrem'] },
  { postcode: '9052', names: ['Zwijnaarde'] },
  { postcode: '9060', names: ['Zelzate'] },
  { postcode: '9070', names: ['Destelbergen'] },
  { postcode: '9070', names: ['Heusden'] },
  { postcode: '9075', names: ['CSM Gent X'] },
  { postcode: '9080', names: ['Beervelde'] },
  { postcode: '9080', names: ['Lochristi'] },
  { postcode: '9080', names: ['Zaffelare'] },
  { postcode: '9080', names: ['Zeveneken'] },
  { postcode: '9090', names: ['Gontrode'] },
  { postcode: '9090', names: ['Melle'] },
  { postcode: '9099', names: ['Gent X'] },
  { postcode: '9100', names: ['Nieuwkerken-Waas'] },
  { postcode: '9100', names: ['Sint-Niklaas'] },
  { postcode: '9111', names: ['Belsele'] },
  { postcode: '9112', names: ['Sinaai-Waas'] },
  { postcode: '9120', names: ['Beveren-Waas'] },
  { postcode: '9120', names: ['Haasdonk'] },
  { postcode: '9120', names: ['Kallo'] },
  { postcode: '9120', names: ['Melsele'] },
  { postcode: '9120', names: ['Vrasene'] },
  { postcode: '9130', names: ['Doel'] },
  { postcode: '9130', names: ['Kallo'] },
  { postcode: '9130', names: ['Kieldrecht'] },
  { postcode: '9130', names: ['Verrebroek'] },
  { postcode: '9140', names: ['Elversele'] },
  { postcode: '9140', names: ['Steendorp'] },
  { postcode: '9140', names: ['Temse'] },
  { postcode: '9140', names: ['Tielrode'] },
  { postcode: '9150', names: ['Bazel'] },
  { postcode: '9150', names: ['Kruibeke'] },
  { postcode: '9150', names: ['Rupelmonde'] },
  { postcode: '9160', names: ['Daknam'] },
  { postcode: '9160', names: ['Eksaarde'] },
  { postcode: '9160', names: ['Lokeren'] },
  { postcode: '9170', names: ['De Klinge'] },
  { postcode: '9170', names: ['Meerdonk'] },
  { postcode: '9170', names: ['Sint-Gillis-Waas'] },
  { postcode: '9170', names: ['Sint-Pauwels'] },
  { postcode: '9180', names: ['Moerbeke-Waas'] },
  { postcode: '9185', names: ['Wachtebeke'] },
  { postcode: '9190', names: ['Kemzeke'] },
  { postcode: '9190', names: ['Stekene'] },
  { postcode: '9200', names: ['Appels'] },
  { postcode: '9200', names: ['Baasrode'] },
  { postcode: '9200', names: ['Dendermonde'] },
  { postcode: '9200', names: ['Grembergen'] },
  { postcode: '9200', names: ['Mespelare'] },
  { postcode: '9200', names: ['Oudegem'] },
  { postcode: '9200', names: ['Schoonaarde'] },
  { postcode: '9200', names: ['Sint-Gillis-Dendermonde'] },
  { postcode: '9220', names: ['Hamme'] },
  { postcode: '9220', names: ['Moerzeke'] },
  { postcode: '9230', names: ['Massemen'] },
  { postcode: '9230', names: ['Westrem'] },
  { postcode: '9230', names: ['Wetteren'] },
  { postcode: '9240', names: ['Zele'] },
  { postcode: '9250', names: ['Waasmunster'] },
  { postcode: '9255', names: ['Buggenhout'] },
  { postcode: '9255', names: ['Opdorp'] },
  { postcode: '9260', names: ['Schellebelle'] },
  { postcode: '9260', names: ['Serskamp'] },
  { postcode: '9260', names: ['Wichelen'] },
  { postcode: '9270', names: ['Kalken'] },
  { postcode: '9270', names: ['Laarne'] },
  { postcode: '9280', names: ['Denderbelle'] },
  { postcode: '9280', names: ['Lebbeke'] },
  { postcode: '9280', names: ['Wieze'] },
  { postcode: '9290', names: ['Berlare'] },
  { postcode: '9290', names: ['Overmere'] },
  { postcode: '9290', names: ['Uitbergen'] },
  { postcode: '9300', names: ['Aalst'] },
  { postcode: '9308', names: ['Gijzegem'] },
  { postcode: '9308', names: ['Hofstade'] },
  { postcode: '9310', names: ['Baardegem'] },
  { postcode: '9310', names: ['Herdersem'] },
  { postcode: '9310', names: ['Meldert'] },
  { postcode: '9310', names: ['Moorsel'] },
  { postcode: '9320', names: ['Erembodegem'] },
  { postcode: '9320', names: ['Nieuwerkerken'] },
  { postcode: '9340', names: ['Impe'] },
  { postcode: '9340', names: ['Lede'] },
  { postcode: '9340', names: ['Oordegem'] },
  { postcode: '9340', names: ['Smetlede'] },
  { postcode: '9340', names: ['Wanzele'] },
  { postcode: '9400', names: ['Appelterre-Eichem'] },
  { postcode: '9400', names: ['Denderwindeke'] },
  { postcode: '9400', names: ['Lieferinge'] },
  { postcode: '9400', names: ['Nederhasselt'] },
  { postcode: '9400', names: ['Ninove'] },
  { postcode: '9400', names: ['Okegem'] },
  { postcode: '9400', names: ['Voorde'] },
  { postcode: '9401', names: ['Pollare'] },
  { postcode: '9402', names: ['Meerbeke'] },
  { postcode: '9403', names: ['Neigem'] },
  { postcode: '9404', names: ['Aspelare'] },
  { postcode: '9406', names: ['Outer'] },
  { postcode: '9420', names: ['Aaigem'] },
  { postcode: '9420', names: ['Bambrugge'] },
  { postcode: '9420', names: ['Burst'] },
  { postcode: '9420', names: ['Erondegem'] },
  { postcode: '9420', names: ['Erpe'] },
  { postcode: '9420', names: ['Mere'] },
  { postcode: '9420', names: ['Ottergem'] },
  { postcode: '9420', names: ['Vlekkem'] },
  { postcode: '9450', names: ['Denderhoutem'] },
  { postcode: '9450', names: ['Haaltert'] },
  { postcode: '9450', names: ['Heldergem'] },
  { postcode: '9451', names: ['Kerksken'] },
  { postcode: '9470', names: ['Denderleeuw'] },
  { postcode: '9472', names: ['Iddergem'] },
  { postcode: '9473', names: ['Welle'] },
  { postcode: '9500', names: ['Geraardsbergen'] },
  { postcode: '9500', names: ['Goeferdinge'] },
  { postcode: '9500', names: ['Moerbeke'] },
  { postcode: '9500', names: ['Nederboelare'] },
  { postcode: '9500', names: ['Onkerzele'] },
  { postcode: '9500', names: ['Ophasselt'] },
  { postcode: '9500', names: ['Overboelare'] },
  { postcode: '9500', names: ['Viane'] },
  { postcode: '9500', names: ['Zarlardinge'] },
  { postcode: '9506', names: ['Grimminge'] },
  { postcode: '9506', names: ['Idegem'] },
  { postcode: '9506', names: ['Nieuwenhove'] },
  { postcode: '9506', names: ['Schendelbeke'] },
  { postcode: '9506', names: ['Smeerebbe-Vloerzegem'] },
  { postcode: '9506', names: ['Waarbeke'] },
  { postcode: '9506', names: ['Zandbergen'] },
  { postcode: '9520', names: ['Bavegem'] },
  { postcode: '9520', names: ['Oombergen'] },
  { postcode: '9520', names: ['Sint-Lievens-Houtem'] },
  { postcode: '9520', names: ['Vlierzele'] },
  { postcode: '9520', names: ['Zonnegem'] },
  { postcode: '9521', names: ['Letterhoutem'] },
  { postcode: '9550', names: ['Herzele'] },
  { postcode: '9550', names: ['Hillegem'] },
  { postcode: '9550', names: ['Sint-Antelinks'] },
  { postcode: '9550', names: ['Sint-Lievens-Esse'] },
  { postcode: '9550', names: ['Steenhuize-Wijnhuize'] },
  { postcode: '9550', names: ['Woubrechtegem'] },
  { postcode: '9551', names: ['Ressegem'] },
  { postcode: '9552', names: ['Borsbeke'] },
  { postcode: '9570', names: ['Deftinge'] },
  { postcode: '9570', names: ['Sint-Maria-Lierde'] },
  { postcode: '9571', names: ['Hemelveerdegem'] },
  { postcode: '9572', names: ['Sint-Martens-Lierde'] },
  { postcode: '9600', names: ['Renaix'] },
  { postcode: '9620', names: ['Elene'] },
  { postcode: '9620', names: ['Erwetegem'] },
  { postcode: '9620', names: ['Godveerdegem'] },
  { postcode: '9620', names: ['Grotenberge'] },
  { postcode: '9620', names: ['Leeuwergem'] },
  { postcode: '9620', names: ['Oombergen'] },
  { postcode: '9620', names: ['Sint-Goriks-Oudenhove'] },
  { postcode: '9620', names: ['Sint-Maria-Oudenhove'] },
  { postcode: '9620', names: ['Strijpen'] },
  { postcode: '9620', names: ['Velzeke-Ruddershove'] },
  { postcode: '9620', names: ['Zottegem'] },
  { postcode: '9630', names: ['Beerlegem'] },
  { postcode: '9630', names: ['Dikkele'] },
  { postcode: '9630', names: ['Hundelgem'] },
  { postcode: '9630', names: ['Meilegem'] },
  { postcode: '9630', names: ['Munkzwalm'] },
  { postcode: '9630', names: ['Paulatem'] },
  { postcode: '9630', names: ['Roborst'] },
  { postcode: '9630', names: ['Rozebeke'] },
  { postcode: '9630', names: ['Sint-Blasius-Boekel'] },
  { postcode: '9630', names: ['Sint-Denijs-Boekel'] },
  { postcode: '9630', names: ['Sint-Maria-Latem'] },
  { postcode: '9636', names: ['Nederzwalm-Hermelgem'] },
  { postcode: '9660', names: ['Brakel'] },
  { postcode: '9660', names: ['Elst'] },
  { postcode: '9660', names: ['Everbeek'] },
  { postcode: '9660', names: ['Michelbeke'] },
  { postcode: '9660', names: ['Nederbrakel'] },
  { postcode: '9660', names: ['Opbrakel'] },
  { postcode: '9660', names: ['Sint-Maria-Oudenhove'] },
  { postcode: '9660', names: ['Zegelsem'] },
  { postcode: '9661', names: ['Parike'] },
  { postcode: '9667', names: ['Sint-Kornelis-Horebeke'] },
  { postcode: '9667', names: ['Sint-Maria-Horebeke'] },
  { postcode: '9680', names: ['Etikhove'] },
  { postcode: '9680', names: ['Maarke-Kerkem'] },
  { postcode: '9681', names: ['Nukerke'] },
  { postcode: '9688', names: ['Schorisse'] },
  { postcode: '9690', names: ['Berchem'] },
  { postcode: '9690', names: ['Kwaremont'] },
  { postcode: '9690', names: ['Ruien'] },
  { postcode: '9690', names: ['Zulzeke'] },
  { postcode: '9700', names: ['Bevere'] },
  { postcode: '9700', names: ['Edelare'] },
  { postcode: '9700', names: ['Eine'] },
  { postcode: '9700', names: ['Ename'] },
  { postcode: '9700', names: ['Heurne'] },
  { postcode: '9700', names: ['Leupegem'] },
  { postcode: '9700', names: ['Mater'] },
  { postcode: '9700', names: ['Melden'] },
  { postcode: '9700', names: ['Mullem'] },
  { postcode: '9700', names: ['Nederename'] },
  { postcode: '9700', names: ['Ooike'] },
  { postcode: '9700', names: ['Oudenaarde'] },
  { postcode: '9700', names: ['Volkegem'] },
  { postcode: '9700', names: ['Welden'] },
  { postcode: '9750', names: ['Huise'] },
  { postcode: '9750', names: ['Ouwegem'] },
  { postcode: '9750', names: ['Zingem'] },
  { postcode: '9770', names: ['Kruishoutem'] },
  { postcode: '9771', names: ['Nokere'] },
  { postcode: '9772', names: ['Wannegem-Lede'] },
  { postcode: '9790', names: ['Elsegem'] },
  { postcode: '9790', names: ['Moregem'] },
  { postcode: '9790', names: ['Ooike'] },
  { postcode: '9790', names: ['Petegem-Aan-De-Schelde'] },
  { postcode: '9790', names: ['Wortegem'] },
  { postcode: '9800', names: ['Astene'] },
  { postcode: '9800', names: ['Bachte-Maria-Leerne'] },
  { postcode: '9800', names: ['Deinze'] },
  { postcode: '9800', names: ['Gottem'] },
  { postcode: '9800', names: ['Grammene'] },
  { postcode: '9800', names: ['Meigem'] },
  { postcode: '9800', names: ['Petegem-Aan-De-Leie'] },
  { postcode: '9800', names: ['Sint-Martens-Leerne'] },
  { postcode: '9800', names: ['Vinkt'] },
  { postcode: '9800', names: ['Wontergem'] },
  { postcode: '9800', names: ['Zeveren'] },
  { postcode: '9810', names: ['Eke'] },
  { postcode: '9810', names: ['Nazareth'] },
  { postcode: '9820', names: ['Bottelare'] },
  { postcode: '9820', names: ['Lemberge'] },
  { postcode: '9820', names: ['Melsen'] },
  { postcode: '9820', names: ['Merelbeke'] },
  { postcode: '9820', names: ['Munte'] },
  { postcode: '9820', names: ['Schelderode'] },
  { postcode: '9830', names: ['Sint-Martens-Latem'] },
  { postcode: '9831', names: ['Deurle'] },
  { postcode: '9840', names: ['De Pinte'] },
  { postcode: '9840', names: ['Zevergem'] },
  { postcode: '9850', names: ['Hansbeke'] },
  { postcode: '9850', names: ['Landegem'] },
  { postcode: '9850', names: ['Merendree'] },
  { postcode: '9850', names: ['Nevele'] },
  { postcode: '9850', names: ['Poesele'] },
  { postcode: '9850', names: ['Vosselare'] },
  { postcode: '9860', names: ['Balegem'] },
  { postcode: '9860', names: ['Gijzenzele'] },
  { postcode: '9860', names: ['Landskouter'] },
  { postcode: '9860', names: ['Moortsele'] },
  { postcode: '9860', names: ['Oosterzele'] },
  { postcode: '9860', names: ['Scheldewindeke'] },
  { postcode: '9870', names: ['Machelen'] },
  { postcode: '9870', names: ['Olsene'] },
  { postcode: '9870', names: ['Zulte'] },
  { postcode: '9880', names: ['Aalter'] },
  { postcode: '9880', names: ['Lotenhulle'] },
  { postcode: '9880', names: ['Poeke'] },
  { postcode: '9881', names: ['Bellem'] },
  { postcode: '9890', names: ['Asper'] },
  { postcode: '9890', names: ['Baaigem'] },
  { postcode: '9890', names: ['Dikkelvenne'] },
  { postcode: '9890', names: ['Gavere'] },
  { postcode: '9890', names: ['Semmerzake'] },
  { postcode: '9890', names: ['Vurste'] },
  { postcode: '9900', names: ['Eeklo'] },
  { postcode: '9910', names: ['Knesselare'] },
  { postcode: '9910', names: ['Ursel'] },
  { postcode: '9920', names: ['Lovendegem'] },
  { postcode: '9921', names: ['Vinderhoute'] },
  { postcode: '9930', names: ['Zomergem'] },
  { postcode: '9931', names: ['Oostwinkel'] },
  { postcode: '9932', names: ['Ronsele'] },
  { postcode: '9940', names: ['Ertvelde'] },
  { postcode: '9940', names: ['Evergem'] },
  { postcode: '9940', names: ['Kluizen'] },
  { postcode: '9940', names: ['Sleidinge'] },
  { postcode: '9950', names: ['Waarschoot'] },
  { postcode: '9960', names: ['Assenede'] },
  { postcode: '9961', names: ['Boekhoute'] },
  { postcode: '9968', names: ['Bassevelde'] },
  { postcode: '9968', names: ['Oosteeklo'] },
  { postcode: '9970', names: ['Kaprijke'] },
  { postcode: '9971', names: ['Lembeke'] },
  { postcode: '9980', names: ['Sint-Laureins'] },
  { postcode: '9981', names: ['Sint-Margriete'] },
  { postcode: '9982', names: ['Sint-Jan-In-Eremo'] },
  { postcode: '9988', names: ['Waterland-Oudeman'] },
  { postcode: '9988', names: ['Watervliet'] },
  { postcode: '9990', names: ['Maldegem'] },
  { postcode: '9991', names: ['Adegem'] },
  { postcode: '9992', names: ['Middelburg'] },
];
