import { i18nKeys } from '@kaa/i18n/common/keys';
import { connect, FormikProps } from 'formik';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SwAlert } from '../../alert/SwAlert';
import { AlertType, getAlertPropsByType } from '../../alert/SwAlert.utils';
import { SwColumn } from '../../core';

type AlertProps = {
  close?: () => void;
  closable?: boolean;
  text: string;
  modSuccess?: boolean;
  modError?: boolean;
  icon: string;
  link?: any;
};

const Alert = ({ text, link, ...rest }: AlertProps) => {
  return (
    <SwColumn>
      <SwAlert modSmall {...rest}>
        <>
          {text}
          {link}
        </>
      </SwAlert>
    </SwColumn>
  );
};

const FormSubmitMessageWithoutConnect = ({
  formik,
}: {
  formik?: FormikProps<any>;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!formik) {
      return;
    }

    const { status, dirty, setStatus, errors } = formik;
    if (status) {
      const isError = !!Object.keys(errors).length;
      const { type } = status;
      const isSuccessStatus = !dirty && type === AlertType.SUCCESS;
      const isErrorStatus = dirty && type === AlertType.ERROR;

      if (isSuccessStatus || isErrorStatus || isError) {
        return;
      }

      setStatus(null);
    }
  }, [formik]);

  if (!formik) {
    return null;
  }

  const { status, errors, touched } = formik;
  if (status && status.type && AlertType[status.type as AlertType]) {
    const { type, msg, msgWithLink, ...rest } = status;

    return (
      <Alert
        {...getAlertPropsByType(type)}
        text={msgWithLink || msg}
        {...rest}
      />
    );
  }

  const errorsFieldname = Object.keys(errors);
  if (errorsFieldname.some((error) => !!get(touched, error))) {
    return (
      <Alert
        {...getAlertPropsByType(AlertType.ERROR)}
        text={t(i18nKeys.general.form.validationErrorMessage, {
          errors: errorsFieldname.length,
        })}
      />
    );
  }

  return null;
};

export const SwFormSubmitMessage = connect(FormSubmitMessageWithoutConnect);
