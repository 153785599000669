import { Customer } from '@kaa/api/customers';
import { useAuthState } from '@kaa/auth/common';
import { AvailableLanguages } from '@kaa/common/enums';
import { getConfig } from '@kaa/core-app/common/config';
import { AuthContext } from '@kaa/core-app/customers/types';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Settings } from 'luxon';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const getLanguage = (selectedCustomer?: Customer): AvailableLanguages => {
  const config = getConfig();
  const availableLanguages =
    config?.buildConfig.i18n.availableLanguages ||
    Object.values(AvailableLanguages);

  if (
    selectedCustomer &&
    availableLanguages.includes(
      selectedCustomer.language.toLowerCase() as AvailableLanguages,
    )
  ) {
    return selectedCustomer.language.toLowerCase() as AvailableLanguages;
  }

  return config?.buildConfig.i18n.fallbackLanguage || AvailableLanguages.DUTCH;
};

export const ChangeLanguage = () => {
  const { i18n, t } = useTranslation();
  const config = getConfig();
  const { selectedCustomerId, customers } = useAuthState<AuthContext>();

  const lngByWebsite = config?.buildConfig.i18n.availableLanguages.find(
    (lng) =>
      t(i18nKeys.vl.general.serviceVouchers.website.url, { lng }) ===
      window.location.origin,
  );

  useEffect(() => {
    if (!customers || !selectedCustomerId) {
      /* /!\ if no customers change lng with the origin /!\ */
      /* /!\ we have a deep link with the lokalise key /!\ */
      if (lngByWebsite) {
        i18n.changeLanguage(lngByWebsite);
        Settings.defaultLocale = lngByWebsite;
      }
      return;
    }

    const selectedCustomer = customers.find(
      ({ id }) => id.toString() === selectedCustomerId,
    );

    const language = getLanguage(selectedCustomer);

    i18n.changeLanguage(language);
    Settings.defaultLocale = language;
  }, [selectedCustomerId, customers, i18n, i18n.language, lngByWebsite]);

  return null;
};
