import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiMode } from '../common/constants';
import { BFFCustomersApi, getBFFCustomersApi } from './customers';
import { getBFFCustomersMock } from './customers.mock';
import { getPublicApi, getPublicMock, PublicApi } from './public';
import { getUsersApi, getUsersMock, UsersApi } from './user';
import { getHTTPInstance } from './utilities';

export interface Api {
  customers: BFFCustomersApi;
  users: UsersApi;
  public: PublicApi;
}

interface ApiByMode {
  mock: () => Api;
  api: (instance: AxiosInstance) => Api;
}

interface ApiParameters {
  headers?: { [key: string]: string | undefined };
  baseUrl?: string;
  interceptor?: {
    request?: {
      onFulfilled?: (
        value: AxiosRequestConfig,
      ) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
      onRejected?: (error: any) => any;
    };
    response?: {
      onFulfilled?: (
        value: AxiosResponse,
      ) => AxiosResponse | Promise<AxiosResponse>;
      onRejected?: (error: any) => any;
    };
  };
  mode?: ApiMode;
}

const apiByMode: ApiByMode = {
  mock: () => ({
    customers: getBFFCustomersMock(),
    users: getUsersMock(),
    public: getPublicMock(),
  }),
  api: (instance: AxiosInstance) => ({
    customers: getBFFCustomersApi(instance),
    users: getUsersApi(instance),
    public: getPublicApi(instance),
  }),
};

/**
 * It returns an Api object that is configured according to the ApiMode
 * @param {ApiParameters}  - headers: The headers to be used in the request.
 * @returns An Api object.
 */
export const getApi = ({
  headers = {},
  interceptor,
  baseUrl,
  mode = ApiMode.API,
}: ApiParameters): Api => {
  const instance = getHTTPInstance(baseUrl, headers, interceptor);

  return apiByMode[mode](instance);
};
