import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwIcon } from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import React from 'react';
import { dataTest } from '../../datatest/keys';
import { Routes } from '../../routes';
import { VlFunctionHeaderTab } from './VlFunctionalHeader.types';

const getTabsDashboard = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    className: 'vl-link vl-u-display-inline-flex',
    header: t(i18nKeys.navigation.dashboard),
    uid: '1',
  },
  {
    id: Routes.SERVICES_VOUCHERS,
    header: t(i18nKeys.navigation.serviceVouchers),
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS,
    header: t(i18nKeys.navigation.prestations),
    uid: '3',
  },
  {
    id: Routes.ACCOUNT,
    header: t(i18nKeys.navigation.account),
    uid: '4',
  },
];

const getTabsAccount = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    'data-testid': dataTest.header.backHome,
    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToHomepage)}
      </>
    ),
    uid: '1',
  },
  {
    id: Routes.ACCOUNT_PROFILE,
    'data-testid': dataTest.header.tabAccount,
    header: t(i18nKeys.navigation.profile),
    uid: '2',
  },
  {
    id: Routes.ACCOUNT_PREFERENCES,
    'data-testid': dataTest.header.tabPreferences,
    header: t(i18nKeys.navigation.preferences),
    uid: '3',
  },
  {
    id: Routes.ACCOUNT_FISCAL_ATTESTATION,
    'data-testid': dataTest.header.tabFiscalAttestation,
    header: t(i18nKeys.navigation.fiscalAttestation),
    uid: '4',
  },
];

const getTabsPrestations = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    'data-testid': dataTest.header.backHome,
    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToHomepage)}
      </>
    ),
    uid: '1',
  },
  {
    id: Routes.PRESTATIONS_WORKS_MANAGEMENT,
    'data-testid': dataTest.header.tabPrestationManagement,
    header: t(i18nKeys.navigation.worksManagement),
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY,
    'data-testid': dataTest.header.tabPrestationManagementHistory,
    header: t(i18nKeys.navigation.worksHistory),
    uid: '3',
  },
  {
    id: Routes.PRESTATIONS_PLAN_A_LEAVE,
    'data-testid': dataTest.header.tabLeave,
    header: t(i18nKeys.navigation.planALeave),
    uid: '4',
  },
];

const getTabsServicesVouchers = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToHomepage)}
      </>
    ),
    'data-testid': dataTest.header.backHome,
    uid: '1',
  },
  {
    id: Routes.SERVICES_VOUCHERS_WALLET,
    header: t(i18nKeys.navigation.wallet),
    uid: '2',
    'data-testid': dataTest.header.tabWallet,
  },
  {
    id: Routes.SERVICES_VOUCHERS_ORDER,
    header: t(i18nKeys.navigation.orders),
    uid: '3',
    'data-testid': dataTest.header.tabMyOrder,
  },
];

const getTabsLanguageRequest = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    'data-testid': dataTest.header.backHome,

    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToHomepage)}
      </>
    ),
    uid: '1',
  },
  {
    id: Routes.LANGUAGE_REQUEST,
    'data-testid': dataTest.header.tabLanguageRequest,
    header: t(i18nKeys.navigation.languageRequest),
    uid: '2',
  },
];

const getTabsHandiwebRequest = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    'data-testid': dataTest.header.backHome,

    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backToHomepage)}
      </>
    ),
    uid: '1',
  },
  {
    id: Routes.HANDIWEB_REQUEST,
    'data-testid': dataTest.header.tabHandiwebRequest,
    header: t(i18nKeys.navigation.handiwebRequest),
    uid: '2',
  },
];

const getTabsRegister = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.LANDING,
    className: 'vl-link vl-u-display-inline-flex',
    header: (
      <>
        <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
        {t(i18nKeys.navigation.backServiceVouchersLogin)}
      </>
    ),
    'data-testid': dataTest.header.backToServiceVouchersLogin,
    uid: '1',
  },
  {
    id: Routes.REGISTER,
    header: t(i18nKeys.navigation.register),
    active: true,
    uid: '2',
    'data-testid': dataTest.header.tabRegister,
  },
];

const getTabsLanding = (t: TFunction): VlFunctionHeaderTab[] => [
  {
    id: Routes.LANDING,
    header: t(i18nKeys.navigation.landing),
    active: true,
    uid: '2',
    'data-testid': dataTest.header.tabRegister,
  },
];
export const getTabs = (
  route: Routes,
  t: TFunction,
): VlFunctionHeaderTab[] | undefined => {
  const TABS: Partial<
    { [key in Routes]: (t: TFunction) => VlFunctionHeaderTab[] }
  > = {
    [Routes.DASHBOARD]: getTabsDashboard,
    [Routes.ACCOUNT]: getTabsAccount,
    [Routes.ACCOUNT_PROFILE]: getTabsAccount,
    [Routes.ACCOUNT_PREFERENCES]: getTabsAccount,
    [Routes.ACCOUNT_FISCAL_ATTESTATION]: getTabsAccount,
    [Routes.PRESTATIONS]: getTabsPrestations,
    [Routes.PRESTATIONS_WORKS_MANAGEMENT]: getTabsPrestations,
    [Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY]: getTabsPrestations,
    [Routes.PRESTATIONS_PLAN_A_LEAVE]: getTabsPrestations,
    [Routes.SERVICES_VOUCHERS]: getTabsServicesVouchers,
    [Routes.SERVICES_VOUCHERS_WALLET]: getTabsServicesVouchers,
    [Routes.SERVICES_VOUCHERS_ORDER]: getTabsServicesVouchers,
    [Routes.SERVICES_VOUCHERS_ORDER_DETAILS]: getTabsServicesVouchers,
    [Routes.SERVICES_VOUCHERS_ORDER_PENDING]: getTabsServicesVouchers,
    [Routes.LANGUAGE_REQUEST]: getTabsLanguageRequest,
    [Routes.HANDIWEB_REQUEST]: getTabsHandiwebRequest,
    [Routes.REGISTER]: getTabsRegister,
    [Routes.REGISTER_COMPLETE]: getTabsRegister,
    [Routes.REGISTER_SUMMARY]: getTabsRegister,
    [Routes.LANDING]: getTabsLanding,
    [Routes.ROOT]: getTabsLanding,
  };

  const tab = TABS[route];

  return tab ? tab(t) : tab;
};
