import { ContractType, Customer } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwIcon, SwInfoTile, SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sendCTAEventToGTM } from '../../../../utils/google-analytics';
import './ProfileCustomerContractsListCard.style.scss';

type ProfileCustomerContractsListCardProps = {
  customer: Customer;
  current: Customer;
  action: (customerId: number) => void;
  linkText?: string;
};

export const ProfileCustomerContractsListCard = ({
  customer,
  current,
  action,
  linkText = i18nKeys.profile.contractList.goProfile,
}: ProfileCustomerContractsListCardProps) => {
  const { t } = useTranslation();

  const getSelectedClass = () =>
    customer.id === current.id
      ? 'profile-contractList-card profile-contractList-card--active'
      : 'profile-contractList-card';

  const getSelectedIcon = (defaultIcon: string) =>
    customer.id === current.id ? Icon.USER_ACTIF : defaultIcon;

  const customerContracts = [
    ...customer.contracts.filter((e) => e.type !== ContractType.MATERNITY),
    ...customer.contracts.filter((e) => e.type === ContractType.MATERNITY),
  ];

  return (
    <>
      <SwInfoTile
        key={customer.id}
        badge={<SwIcon icon={getSelectedIcon(Icon.USER)} />}
        className={getSelectedClass()}
        tagName="div"
        itemProp="Person"
        itemType="https://schema.org/Person"
        title={`${customer.firstName} ${customer.lastName}`}
      >
        {customerContracts.map((contract) => (
          <p
            className="vl-u-display-block"
            key={`${customer.id}-${contract.authorizationId}`}
          >
            {contract.type === ContractType.MATERNITY
              ? `${t(i18nKeys.profile.contractList.profileMaternityID)}: ${
                  contract.authorizationId
                }`
              : `${t(i18nKeys.profile.contractList.profileID)}: ${
                  contract.authorizationId
                }`}
          </p>
        ))}
        {action && customer.id !== current.id && (
          <SwLink
            modBlock
            tagName="button"
            type="button"
            onClick={() => {
              action(customer.id);
              sendCTAEventToGTM(
                'Change profile from Profile page',
                `Goto profile ${customer.id}`,
              );
            }}
          >
            {t(linkText)}
          </SwLink>
        )}
      </SwInfoTile>
    </>
  );
};
