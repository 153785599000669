import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwColumn, SwGrid, SwTitle } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCustomersState,
  useDispatchUpdateSelectedCustomer,
  useSelectedCustomerState,
} from '../../../../utils';
import { ProfileCustomerContractsListCard } from './ProfileCustomerContractsListCard';

export const ProfileCustomerContractsList = () => {
  const selectedCustomer = useSelectedCustomerState();
  const dispatchUpdateSelectedCustomer = useDispatchUpdateSelectedCustomer();
  const availableCustomers = useCustomersState();
  const { t } = useTranslation();
  return (
    <div>
      <SwTitle tagName="h2">
        {t(i18nKeys.profile.contractList.title, {
          count: availableCustomers.length,
        })}
      </SwTitle>
      {!!availableCustomers.length && (
        <div>
          {availableCustomers.length > 1 && (
            <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--small">
              {t(i18nKeys.profile.contractList.subtitle)}
            </SwTitle>
          )}
          <SwGrid modStacked className="vl-u-flex-v-flex-start vl-u-spacer">
            {availableCustomers.map((customer) => (
              <SwColumn
                width="4"
                widthM="6"
                widthS="12"
                key={`${customer.id}-${customer.firstName}`}
              >
                <ProfileCustomerContractsListCard
                  customer={customer}
                  current={selectedCustomer}
                  action={(id) => {
                    dispatchUpdateSelectedCustomer(id);
                    document.documentElement.scrollTop = 0;
                    window.location.reload();
                  }}
                />
              </SwColumn>
            ))}
          </SwGrid>

          {/*

          NOT IN MVP

          <SwActionGroup modCollapseS>
              <SwButton
                data-testid={dataTest.profile.requestMandateBtn}
                type="button"
                modSecondary
                modLarge
              >
                {t(i18nKeys.profile.requestMandate)}
              </SwButton>
            </SwActionGroup>
          */}
        </div>
      )}
    </div>
  );
};
