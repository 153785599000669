/* eslint max-classes-per-file: ["error", 2] */
import { useAuthState } from '@kaa/auth/common';
import { useActionState } from '@kaa/common/context';
import { SwContainer, SwFetchErrorMessage } from '@kaa/ui-flanders/components';
import { IConfig } from '@microsoft/applicationinsights-common';
import { IConfiguration } from '@microsoft/applicationinsights-core-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import React, { Component, FunctionComponent, ReactNode } from 'react';

type ApplicationInsightsConfig = IConfiguration & IConfig;
// Documentation on: https://docs.microsoft.com/fr-be/azure/azure-monitor/app/javascript

export const initErrorTracking = (
  instrumentationKey: string,
  releaseId?: string, // convention: kaa-environment-releaseId
) => {
  const reactPlugin = new ReactPlugin();

  const browserHistory = createBrowserHistory({
    basename: releaseId ? `${releaseId}-` : '',
  });

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      disableTelemetry: false,
      disableAjaxTracking: true,
      disableFetchTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    } as ApplicationInsightsConfig,
  });

  appInsights.loadAppInsights();

  return { reactPlugin, appInsights };
};

interface RootProps {
  appId: string;
  regionCode: string;
  config: { [key: string]: any } | null;
  errorComponent: FunctionComponent<any> | null;
  [key: string]: any;
}

interface State {
  hasError: boolean;
  [key: string]: any;
}

export class RootErrorCrashTrackingBoundary extends Component<
  RootProps,
  State
> {
  constructor(props: RootProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: { [key: string]: any }) {
    const { appId, regionCode, config } = this.props;
    /* eslint-disable */
    if (process.env.NODE_ENV === 'development') {
      console.group('Sentry error');
      console.log(appId, regionCode, config);
      console.log(error, errorInfo);
      console.groupEnd();
    }
    // TODO: Will be replaced by AppInsight
    // Sentry.withScope((scope: any) => {
    //   scope.setTag('APP_ID', appId);
    //   scope.setTag('REGION_CODE', regionCode);
    //   scope.setExtras({
    //     errorInfo,
    //     config,
    //   });
    //   Sentry.captureException(error);
    // });
    /* eslint-enable */
  }

  render() {
    const { hasError } = this.state;
    const { children, errorComponent: ErrorComponent } = this.props;

    if (hasError && ErrorComponent) {
      return <ErrorComponent />;
    }
    return children;
  }
}

interface Props extends RootProps {
  user: { [key: string]: any } | null;
  actions: { [key: string]: any } | null;
}

class ChildErrorCrashTrackingBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: { [key: string]: any }) {
    const { appId, regionCode, user, actions, config } = this.props;
    /* eslint-disable */
    if (process.env.NODE_ENV === 'development') {
      console.group('Sentry error');
      console.log(appId, regionCode, user, actions, config);
      console.log(error, errorInfo);
      console.groupEnd();
    }
    // TODO: Will be replaced by AppInsight
    // Sentry.withScope((scope: any) => {
    //   scope.setTag('APP_ID', appId);
    //   scope.setTag('REGION_CODE', regionCode);
    //   scope.setUser({
    //     ...(user && user.user ? user.user : {}),
    //     authUser: user && user.authUser,
    //     customers: user && user.customers,
    //     messages: user && user.messages,
    //     selectedCustomerId: user && user.selectedCustomerId,
    //     settings: user && user.settings,
    //     user: user && user.user,
    //   });
    //   scope.setExtras({
    //     errorInfo,
    //     actions,
    //     config,
    //   });
    //   Sentry.captureException(error);
    // });
    /* eslint-enable */
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <SwContainer error>
          <SwFetchErrorMessage
            onClick={() => {
              window.location.reload();
            }}
          />
        </SwContainer>
      );
    }
    const { children } = this.props;
    return children;
  }
}

export const ErrorCrashTrackingBoundary = ({
  appId,
  regionCode,
  config,
  children,
}: {
  appId: string;
  regionCode: string;
  config: { [key: string]: any } | null;
  children: ReactNode;
}) => {
  const authState = useAuthState();
  const actionState = useActionState();
  return (
    <ChildErrorCrashTrackingBoundary
      user={authState}
      actions={actionState as { [key: string]: any }}
      appId={appId}
      regionCode={regionCode}
      config={config}
      errorComponent={null}
    >
      {children}
    </ChildErrorCrashTrackingBoundary>
  );
};
