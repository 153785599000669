import {
  VLWidgetErrorConstant,
  VLWidgetSessionEvent,
} from '@kaa/common/constants';
import { useEventListener } from '@kaa/common/utils';
import { getRouterPath, Routes } from '@kaa/core-app/customers/routes';
import { navigate } from '@reach/router';

/**
 * Because we are using the ACM of Flanders, the user use his eid and is logged in flanders
 * but he could be not a client of Sodexo. So the IDP redirects to a page (unknown-acm-user.html) that will trigger a close
 * of the bugerprofile of the modal and  push a message that this listener will catch and redirected to the register page
 * @constructor
 */
export const UnknownUserListener = () => {
  useEventListener(
    'message',
    (event: any) => {
      if (!event) {
        return;
      }

      const { data } = event;

      try {
        const message = JSON.parse(data);
        if (
          message.type === VLWidgetSessionEvent.SESSION_LOGIN_FAILURE &&
          message.payload &&
          message.payload.error === VLWidgetErrorConstant.UNKNOWN_USER
        ) {
          navigate(getRouterPath(Routes.REGISTER));
        }
      } catch (e) {
        // ignored
      }
    },
    window,
  );

  return null;
};
