import { ServiceDelivery, ServiceStatus } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PrestationsWorkHistoryTableRowStatusContentProps = {
  work: ServiceDelivery;
};

export const PrestationsWorkHistoryTableRowStatusContent = ({
  work,
}: PrestationsWorkHistoryTableRowStatusContentProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { durationInHours, status, vouchersAttributed } = work;

  switch (status) {
    case ServiceStatus.ARCHIVED_PAID:
      return (
        <div>
          {t(i18nKeys.worksManagement.history.vouchersAssigned, {
            count: durationInHours,
          })}
        </div>
      );
    case ServiceStatus.ACTIVE_PARTIALLY_PAID:
      return (
        <div>
          {t(i18nKeys.worksManagement.history.vouchersPartiallyAssigned, {
            count: vouchersAttributed,
            total: durationInHours,
          })}
        </div>
      );
    case ServiceStatus.ARCHIVED_CANCELLED:
    case ServiceStatus.ACTIVE_CONTESTED:
    case ServiceStatus.ACTIVE_NOT_ENOUGH_VOUCHERS:
      return <div>{t(i18nKeys.worksManagement.status[status])}</div>;
    default:
      return <span>{t(i18nKeys.worksManagement.status[status])}</span>;
  }
};
