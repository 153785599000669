export const configureSession: (
  loginUrl: string,
  logoutUrl: string,
  active: boolean,
  session: any,
) => void = (
  loginUrl: string,
  logoutUrl: string,
  active = false,
  session: any,
) => {
  session.configure({
    active,
    endpoints: {
      loginUrl,
      logoutUrl,
    },
  });
};

export const onLogoutRequest = (logoutRequest: any) => {
  // Check whether the logout request should be handled.
  // Acknowledge the logout request to prevent the session extension from performing default
  // action due to response timeout (5 seconds).
  logoutRequest.acknowledge();

  // Evaluate the logout reason.
  switch (logoutRequest.getRequest().getReason()) {
    case 'inactivity':
    case 'expired':
      // Because we do a silent renew to the session
      logoutRequest.reject();
      break;
    case 'user': // Accept the application logout request.
      logoutRequest.accept();
      break;
    default:
      // Reject the application logout request.
      logoutRequest.reject();
  }
};
