import { Mask } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwFormColumn,
  SwFormGrid,
  SwInputTextField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterCompleteScreenFieldNames } from '../RegisterCompleteScreen.constants';

export const RegisterBankInfoForm = () => {
  const { t } = useTranslation();

  return (
    <SwFormGrid modStacked>
      <SwTitle tagName="h3" tagStyle="h5">
        {t(i18nKeys.register.formSection.bankAccount.title)}
      </SwTitle>

      <SwFormColumn>
        <p>{t(i18nKeys.register.formSection.bankAccount.description)}</p>
      </SwFormColumn>

      <Field
        name={RegisterCompleteScreenFieldNames.REFUND_BANK_ACCOUNT}
        label={t(i18nKeys.register.form.bankAccount.label)}
        component={SwInputTextField}
        mask={Mask.iban}
        modRequired
      />
    </SwFormGrid>
  );
};
