import { FiscalAttestation } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwTitle } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentDocument } from '../FiscalAttestationScreen.constants';
import { FiscalAttestationHistoryButton } from './FiscalAttestationHistoryButton';

type FiscalAttestationDetailsProps = {
  historicalFiscalsAttestations: FiscalAttestation[];
  openModal: (currentDocument: CurrentDocument) => void;
};

export const FiscalAttestationHistory = ({
  historicalFiscalsAttestations,
  ...rest
}: FiscalAttestationDetailsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--small">
        {t(i18nKeys.fiscalAttestation.history.title)}
      </SwTitle>
      <p className="vl-u-spacer--small">
        {t(i18nKeys.fiscalAttestation.history.introduction)}
      </p>
      <ul className="vl-u-display-flex">
        {historicalFiscalsAttestations.map((fiscalAttestation) => (
          <FiscalAttestationHistoryButton
            key={fiscalAttestation.taxationYear}
            fiscalAttestation={fiscalAttestation}
            {...rest}
          />
        ))}
      </ul>
    </>
  );
};
