import { Link } from '@reach/router';
import React, { AllHTMLAttributes, createElement, Ref } from 'react';
import { SwIcon } from '../icon/SwIcon';
import { mergeClassNames } from '../utilities';

type ButtonProps<T = HTMLButtonElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  icon?: string;
  to?: string;
  href?: string;
  modDisabled?: boolean;
  modError?: boolean;
  modIcon?: boolean;
  modIconAfter?: boolean;
  modIconBefore?: boolean;
  modNarrow?: boolean;
  modBlock?: boolean;
  modLarge?: boolean;
  modLoading?: boolean;
  modWide?: boolean;
  modSecondary?: boolean;
  modTertiary?: boolean;
  modNaked?: boolean;
};

type Classes = {
  modDisabled?: boolean;
  modError?: boolean;
  modIcon?: boolean;
  modIconAfter?: boolean;
  modIconBefore?: boolean;
  modNarrow?: boolean;
  modBlock?: boolean;
  modLarge?: boolean;
  modLoading?: boolean;
  modWide?: boolean;
  modSecondary?: boolean;
  modTertiary?: boolean;
  modNaked?: boolean;
};
const getClasses = ({
  modError,
  modLarge,
  modBlock,
  modDisabled,
  modIcon,
  modIconAfter,
  modIconBefore,
  modLoading,
  modNarrow,
  modSecondary,
  modTertiary,
  modWide,
  modNaked,
}: Classes) => {
  return [
    'vl-button',
    modDisabled ? 'vl-button--disabled' : '',
    modError ? 'vl-button--error' : '',
    modIcon ? 'vl-button--icon' : '',
    modIconAfter ? 'vl-button--icon-after' : '',
    modIconBefore ? 'vl-button--icon-before' : '',
    modNarrow ? 'vl-button--narrow' : '',
    modBlock ? 'vl-button--block' : '',
    modLarge ? 'vl-button--large' : '',
    modLoading ? 'vl-button--loading' : '',
    modWide ? 'vl-button--wide' : '',
    modSecondary ? 'vl-button--secondary' : '',
    modTertiary ? 'vl-button--tertiary' : '',
    modNaked ? 'vl-button--naked' : '',
  ].join(' ');
};

type Tag = {
  to?: string;
  defaultTagName: string;
  tagName?: string;
};
const getTag = ({ tagName, defaultTagName, to }: Tag) => {
  if (tagName !== defaultTagName) {
    return tagName;
  }
  if (to && to.match(/^(http(s)?):\/\/|ftp:|mailto:|tel:/)) {
    return 'a';
  }

  if (to) {
    // TODO: reach router or react router
    return Link;
  }
  return defaultTagName;
};

type Url = {
  to?: string;
  href?: string;
};
const getUrl = ({ to, href }: Url) => {
  if (to && to.match(/^(http(s)?):\/\/|ftp:|mailto:|tel:/)) {
    return {
      urlHref: href || to,
      urlTo: null,
    };
  }

  return {
    urlHref: href,
    urlTo: to,
  };
};

export const SwButton = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      icon,
      className,
      modBlock,
      modDisabled,
      modError,
      modIcon,
      modIconAfter,
      modIconBefore,
      modLarge,
      modLoading,
      modNarrow,
      modSecondary,
      modTertiary,
      modWide,
      modNaked,
      href,
      to,
      children,
      ...rest
    }: ButtonProps<T>,
    ref: Ref<T>,
  ) => {
    const defaultTagName = 'button';
    const TagName = getTag({
      tagName: tagName || defaultTagName,
      defaultTagName,
      to,
    }) as any;

    const { urlHref, urlTo } = getUrl({ href, to });

    const classes = getClasses({
      modWide,
      modError,
      modTertiary,
      modSecondary,
      modNarrow,
      modLoading,
      modIconBefore,
      modIconAfter,
      modIcon,
      modDisabled,
      modLarge,
      modBlock,
      modNaked,
    });

    return createElement(
      TagName,
      {
        className: mergeClassNames(classes, className),
        disabled: modDisabled || modLoading,
        to: urlTo,
        href: urlHref,
        ref,
        ...rest,
      },
      modIcon ? <SwIcon icon={icon} className="vl-button__icon" /> : null,
      modIconBefore ? (
        <SwIcon
          icon={icon}
          className="vl-button__icon vl-button__icon--before"
        />
      ) : null,
      children,
      modIconAfter ? (
        <SwIcon
          icon={icon}
          className="vl-button__icon vl-button__icon--after"
        />
      ) : null,
    );
  },
);
