import { connect, FormikProps } from 'formik';
import { useEffect } from 'react';

// TODO: change with validateOnMount boolean when pass to formik v2
export const SwFormValidateOnMount = connect(
  ({ formik }: { formik?: FormikProps<any> }) => {
    useEffect(() => {
      if (formik) {
        formik.validateForm();
      }
    }, [formik && formik.validateForm]);

    return null;
  },
);
