import { DependencyList, useCallback, useState } from 'react';
import { debugLog } from './debugLog';
import { useRefMounted } from './useRefMounted';

export type AsyncState<T> =
  | {
      loading: boolean;
      error?: undefined;
      value?: T;
    }
  | {
      loading: false;
      error: Error;
      value?: undefined;
    }
  | {
      loading: boolean;
      error?: undefined;
      value: T;
    };

export type AsyncCallback<Result = any, Args extends any[] = any> = [
  AsyncState<Result>,
  (...args: Args | any[]) => Promise<[Error | undefined, Result | undefined]>,
];

export function useAsyncCallback<Result = any, Args extends any[] = any>(
  fn: (...args: Args | any) => Promise<Result>,
  deps: DependencyList = [],
  initialState: AsyncState<Result> = { loading: false },
): AsyncCallback<Result, Args> {
  const [state, setState] = useState<AsyncState<Result>>(initialState);

  const mounted = useRefMounted();

  const callback = useCallback<
    (...args: Args | any) => Promise<[Error | undefined, Result | undefined]>
  >(async (...args: Args | any) => {
    setState(({ value }) => ({ loading: true, ...(value ? { value } : {}) }));

    try {
      const value = await fn(...args);
      if (mounted.current) {
        setState({ value, loading: false });
      }
      return [undefined, value];
    } catch (error) {
      debugLog('useAsyncCallback', error);
      if (mounted.current) {
        setState({ error, loading: false });
      }
      return [error, undefined];
    }
  }, deps);

  return [state, callback];
}
