import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { FormikHandlers, FormikProps } from 'formik';
import React, { AllHTMLAttributes, ChangeEvent, ReactNode } from 'react';
import { dataTest } from '../../../../datatest/keys';
import { SwFormColumn } from '../../../core';
import { Column } from '../../../core/SwColumn';
import { SwFormInput } from '../../../form-input/SwFormInput';
import { SwRadio } from '../../../radio/SwRadio';
import { useFormError } from '../../../util/hooks/useFormError';
import { FieldPropsClean } from '../../../utilities';

type SwRadioFieldProps = AllHTMLAttributes<HTMLInputElement> &
  FieldPropsClean & {
    label?: string | ReactNode;
    value?: string;
    modError?: boolean;
    modSmall?: boolean;
    modInline?: boolean;
    modBlock?: boolean;
    modDisabled?: boolean;
    modSpacer?: boolean;
    inputOnChange?: (
      event: ChangeEvent<HTMLInputElement>,
      field: {
        onChange: FormikHandlers['handleChange'];
        onBlur: FormikHandlers['handleBlur'];
        value: any;
        name: string;
      },
      form: FormikProps<any>,
    ) => void;
    column?: Column;
    autoUnsetServerError?: boolean;
    parse?: (value: string) => string;
    format?: (value: string) => string;
  };

// TODO @victor, we should handle error message -> radio group
export const SwRadioField = ({
  field,
  form,
  label = '',
  id = '',
  modBlock = true,
  column,
  inputOnChange,
  modSpacer,
  autoUnsetServerError = true,
  parse,
  format,
  value,
  ...props
}: SwRadioFieldProps) => {
  const errorMessage = useFormError({ field, form });
  const { name, value: fieldValue, onChange, onBlur } = field;
  return (
    <SwFormColumn {...column} className={modSpacer ? 'vl-u-spacer' : ''}>
      <SwFormInput>
        <SwRadio
          data-testid={dynamicDataTest(dataTest.SwRadioField, { id })}
          {...props}
          name={name}
          id={id}
          value={value || id}
          checked={(value || id) === (format ? format(fieldValue) : fieldValue)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            // We have to do mutation for formik
            e.target.value = parse ? parse(e.target.value) : e.target.value;

            onChange(e);
            if (inputOnChange) {
              inputOnChange(e, field, form);
            }
          }}
          onBlur={onBlur}
          modBlock={modBlock}
          modError={!!errorMessage}
        >
          {label}
        </SwRadio>
      </SwFormInput>
    </SwFormColumn>
  );
};
