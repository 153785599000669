import { MessageCode, MessageType, RegionCode } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { AlertType, SwLink } from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import get from 'lodash.get';
import React from 'react';
import { getConfig } from '../../../common/config';
import {
  REGION_HELP_CENTER_NUMBER,
  REGION_NAME,
  SERVICE_VOUCHERS_WEBSITE_LINKS,
} from '../../constants';
import { getPath, Routes } from '../../routes';

export const MAP_MESSAGE_TO_ALERT_TYPE = {
  [MessageType.WARNING]: AlertType.WARNING,
  [MessageType.ERROR]: AlertType.ERROR,
  [MessageType.INFO]: AlertType.INFORMATION,
  [MessageType.CONFIRMATION]: AlertType.SUCCESS,
};

export const getAlertChildrenByCode = (code: string, t: TFunction) => {
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const currentPath = window.location.pathname;
  const accountPath = getPath(Routes.ACCOUNT_PROFILE);
  const ALERT_CHILDREN_BY_CODE: { [key: string]: JSX.Element } = {
    [MessageCode.CUSTOMER_FROZEN]: <div />,
    [MessageCode.CUSTOMER_FROZEN_AWAITING_CORRECTION]: (
      <>
        {t(i18nKeys.alert.frozen.awaitingCorrection.body)}{' '}
        {currentPath !== accountPath && (
          <SwLink to={getPath(Routes.ACCOUNT_PROFILE)}>
            {t(i18nKeys.alert.frozen.cta.account)}
          </SwLink>
        )}
      </>
    ),
    [MessageCode.CUSTOMER_FROZEN_AWAITING_REGION_VALIDATION]: (
      <>{t(i18nKeys.alert.frozen.awaitingRegionValidation.body)}</>
    ),
    [MessageCode.CUSTOMER_FROZEN_DEAD]: (
      <>
        {t(i18nKeys.alert.frozen.dead.body)}{' '}
        {t(i18nKeys.alert.frozen.cta.ourHelpCenter, {
          phoneNumber: t(REGION_HELP_CENTER_NUMBER[regionCode]),
        })}
      </>
    ),
    [MessageCode.CUSTOMER_FROZEN_DEPOSED]: (
      <>{t(i18nKeys.alert.frozen.deposed.body)}</>
    ),
    [MessageCode.CUSTOMER_FROZEN_MOVE_REGION]: (
      <>
        {t(i18nKeys.alert.frozen.moveRegion.body.web)}{' '}
        {Object.entries(SERVICE_VOUCHERS_WEBSITE_LINKS)
          .filter(([code]) => code !== regionCode)
          .map(([code, url], index) => (
            <React.Fragment key={code}>
              {index ? ' - ' : <br />}
              <SwLink tagName="a" href={t(url)}>
                {t(REGION_NAME[code as RegionCode])}
              </SwLink>
            </React.Fragment>
          ))}
      </>
    ),
    [MessageCode.CUSTOMER_FROZEN_MOVE_UNRESOLVED_LOCATION]: (
      <>{t(i18nKeys.alert.frozen.moveUnresolvedLocation.body)}</>
    ),
    [MessageCode.CUSTOMER_FROZEN_REFUSED_OTHER_REGION]: (
      <>
        {t(i18nKeys.alert.frozen.refused.otherRegion.body.web)}{' '}
        {Object.entries(SERVICE_VOUCHERS_WEBSITE_LINKS)
          .filter(([code]) => code !== regionCode)
          .map(([code, url], index) => (
            <React.Fragment key={code}>
              {index ? ' - ' : <br />}
              <SwLink tagName="a" href={t(url)}>
                {t(REGION_NAME[code as RegionCode])}
              </SwLink>
            </React.Fragment>
          ))}
      </>
    ),
    [MessageCode.CUSTOMER_FROZEN_REFUSED_SAME_REGION]: (
      <>
        <p>
          {t(i18nKeys.alert.frozen.refused.notActive.first.body)}{' '}
          <SwLink to={getPath(Routes.ACCOUNT_PROFILE)}>
            {t(i18nKeys.alert.frozen.cta.account)}
          </SwLink>
        </p>
        <p>
          {t(i18nKeys.alert.frozen.refused.notActive.second.body)}{' '}
          {t(i18nKeys.alert.frozen.cta.ourHelpCenter, {
            phoneNumber: t(REGION_HELP_CENTER_NUMBER[regionCode]),
          })}
        </p>
      </>
    ),
  };

  return ALERT_CHILDREN_BY_CODE[code];
};
