import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { FieldProps } from 'formik';
import React, { AllHTMLAttributes, ChangeEvent } from 'react';
import { dataTest } from '../../../../datatest/keys';
import { SwCheckbox } from '../../../checkbox/SwCheckbox';
import { SwFormColumn } from '../../../core';
import { Column } from '../../../core/SwColumn';
import { SwFormInput } from '../../../form-input/SwFormInput';
import { useFormError } from '../../../util/hooks/useFormError';
import { FieldPropsClean } from '../../../utilities';
import { SwErrorMessageForField } from '../error-message-for-field/SwErrorMessageForField';

type SwCheckBoxFieldProps = AllHTMLAttributes<HTMLInputElement> &
  FieldPropsClean & {
    label?: string;
    modError?: boolean;
    modSmall?: boolean;
    modInline?: boolean;
    modBlock?: boolean;
    modDisabled?: boolean;
    modRequired?: boolean;
    modVisualChecked?: boolean;
    inputOnChange?: (
      event: ChangeEvent<HTMLInputElement>,
      field: FieldProps['field'],
      form: FieldProps['form'],
    ) => void;
    column?: Column;
    autoUnsetServerError?: boolean;
    parse?: (value: string) => string;
    format?: (value: string) => string;
  };

export const SwCheckboxField = ({
  field,
  form,
  label = '',
  modBlock = true,
  column,
  inputOnChange,
  autoUnsetServerError = true,
  modRequired,
  parse,
  format,
  modVisualChecked,
  ...props
}: SwCheckBoxFieldProps) => {
  const errorMessage = useFormError({ field, form });
  const currentValue = format ? format(field.value) : field.value;

  return (
    <>
      <SwFormColumn {...column}>
        <SwFormInput>
          <SwCheckbox
            data-testid={dynamicDataTest(dataTest.SwCheckboxField, {
              fieldName: field.name,
            })}
            checked={modVisualChecked || currentValue || false}
            {...props}
            name={field.name}
            onBlur={field.onBlur}
            modBlock={modBlock}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              // We have to do mutation for formik
              e.target.value = parse ? parse(e.target.value) : e.target.value;

              field.onChange(e);
              if (inputOnChange) {
                inputOnChange(e, field, form);
              }
            }}
            modError={!!errorMessage}
            modRequired={modRequired}
          >
            {label}
          </SwCheckbox>
          <SwErrorMessageForField
            field={field}
            form={form}
            autoUnsetServerError={autoUnsetServerError}
            testId={dynamicDataTest(dataTest.SwCheckboxFieldError, {
              fieldName: field.name,
            })}
          />
        </SwFormInput>
      </SwFormColumn>
    </>
  );
};
