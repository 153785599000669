import { PaymentMethod, PurchaseStatus, SupportType } from '@kaa/api/customers';
import { Format, toCurrencyFormat } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { OrderTypes } from '../../MyOrderScreen.constants';
import { Order, OrderWithQuantityAndTotal } from '../../MyOrderScreen.types';

export type OrderDetailsSteps =
  | 'PENDING'
  | 'PAID'
  | 'PROCESSING'
  | 'SENT'
  | 'RECEIVED';

export const OrderDetailsSteps = {
  PENDING: 'PENDING' as OrderDetailsSteps,
  PAID: 'PAID' as OrderDetailsSteps,
  PROCESSING: 'PROCESSING' as OrderDetailsSteps,
  SENT: 'SENT' as OrderDetailsSteps,
  RECEIVED: 'RECEIVED' as OrderDetailsSteps,
};

const STEPS_PAPER_BANK_TRANSFER = {
  [OrderDetailsSteps.PENDING]: (order: Order) =>
    order.type === OrderTypes.PENDING,
  [OrderDetailsSteps.PROCESSING]: (order: Order) =>
    order.type === OrderTypes.PURCHASE &&
    (order.status === PurchaseStatus.PROCESSING ||
      order.status === PurchaseStatus.PAID),
  [OrderDetailsSteps.SENT]: (order: Order) =>
    order.type === OrderTypes.PURCHASE && order.status === PurchaseStatus.SENT,
};

const STEPS_PAPER_ONLINE_PAYMENT = {
  [OrderDetailsSteps.PENDING]: (order: Order) =>
    order.type === OrderTypes.PURCHASE && 
    order.status === PurchaseStatus.PENDING,
  [OrderDetailsSteps.PAID]: (order: Order) =>
    order.type === OrderTypes.PURCHASE && order.status === PurchaseStatus.PAID,
  [OrderDetailsSteps.PROCESSING]: (order: Order) =>
    order.type === OrderTypes.PURCHASE &&
    order.status === PurchaseStatus.PROCESSING,
  [OrderDetailsSteps.SENT]: (order: Order) =>
    order.type === OrderTypes.PURCHASE && order.status === PurchaseStatus.SENT,
};

const STEPS_ELECTRONIC_ONLINE_PAYMENT = {
  [OrderDetailsSteps.PENDING]: (order: Order) =>
    order.type === OrderTypes.PURCHASE && 
    order.status === PurchaseStatus.PENDING,
  [OrderDetailsSteps.PAID]: (order: Order) =>
    order.type === OrderTypes.PURCHASE && 
    order.status === PurchaseStatus.PAID,
  [OrderDetailsSteps.RECEIVED]: (order: Order) =>
    order.type === OrderTypes.PURCHASE &&
    order.status === PurchaseStatus.RECEIVED
};

const STEPS_ELECTRONIC_BANK_TRANSFER = {
  [OrderDetailsSteps.PENDING]: (order: Order) =>
    order.type === OrderTypes.PENDING ||
    (order.type === OrderTypes.PURCHASE &&
      order.status === PurchaseStatus.PAID),
  [OrderDetailsSteps.RECEIVED]: (order: Order) =>
    order.type === OrderTypes.PURCHASE &&
    order.status === PurchaseStatus.RECEIVED,
};

const STEPS = {
  [SupportType.PAPER]: {
    [PaymentMethod.BANK_TRANSFER]: STEPS_PAPER_BANK_TRANSFER,
    [PaymentMethod.ONLINE_PAYMENT]: STEPS_PAPER_ONLINE_PAYMENT,
    [PaymentMethod.INSTANT_PAYMENT]: STEPS_PAPER_ONLINE_PAYMENT,
    [PaymentMethod.VISA]: STEPS_PAPER_ONLINE_PAYMENT,
    [PaymentMethod.MASTERCARD]: STEPS_PAPER_ONLINE_PAYMENT,
    [PaymentMethod.BANCONTACT]: STEPS_PAPER_ONLINE_PAYMENT,
  },
  [SupportType.ELECTRONIC]: {
    [PaymentMethod.BANK_TRANSFER]: STEPS_ELECTRONIC_BANK_TRANSFER,
    [PaymentMethod.ONLINE_PAYMENT]: STEPS_ELECTRONIC_ONLINE_PAYMENT,
    [PaymentMethod.INSTANT_PAYMENT]: STEPS_ELECTRONIC_ONLINE_PAYMENT,
    [PaymentMethod.VISA]: STEPS_ELECTRONIC_ONLINE_PAYMENT,
    [PaymentMethod.MASTERCARD]: STEPS_ELECTRONIC_ONLINE_PAYMENT,
    [PaymentMethod.BANCONTACT]: STEPS_ELECTRONIC_ONLINE_PAYMENT,
  },
};

export const getOrderDetailsSteps = (
  order: Order,
  t = (str: string) => str,
) => {
  const steps = Object.entries(
    STEPS[order.supportType][order.paymentMethod],
  ).map(([type, checkIsActive]) => {
    return {
      type,
      label: t(i18nKeys.orders.details.steps.labels[type as OrderDetailsSteps]),
      modActive: checkIsActive(order),
    };
  });

  return {
    step: steps.find(({ modActive }) => modActive),
    steps,
  };
};

export const getOrderDetailsStepsSubTitle = (
  order: Order,
  step: string,
  t = (str: string, obj?: any) => str,
): string | ReactNode | undefined => {
  // Step 1 - pending
  if (order.type === OrderTypes.PENDING) {
    return (
      <span>
        <Trans
          i18nKey={i18nKeys.orders.details.pending.alert.subTitle}
          values={{
            totalPrice: toCurrencyFormat(order.totalPrice),
            beneficiaryBankAccount: Format.iban(order.beneficiaryBankAccount),
            beneficiaryName: order.beneficiaryName,
            structuredMessage: order.structuredMessage,
          }}
        >
          Info for your bank transfer payment.
          <br />
          pay a total of <strong>[%s:totalPrice] €</strong> to{' '}
          <strong>[%s:beneficiaryBankAccount]</strong>{' '}
          <strong>[%s:beneficiaryName]</strong> with structured communication{' '}
          <strong>[%s:structuredMessage]</strong>or pay direct online.
        </Trans>
      </span>
    );
  }
  // Always pending or done for electronic
  if (
    order.supportType === SupportType.ELECTRONIC &&
    (step === OrderDetailsSteps.PROCESSING ||
      step === OrderDetailsSteps.RECEIVED)
  ) {
    return t(i18nKeys.orders.details.electronic.pendingOrDone.alert.subTitle);
  }
  // Step 2 in progress for paper
  if (
    order.supportType === SupportType.PAPER &&
    step === OrderDetailsSteps.PROCESSING
  ) {
    return t(i18nKeys.orders.details.paper.inProgress.alert.subTitle, {
      estimatedDaysToDelivery: order.estimatedDaysToDelivery,
    });
  }
  // Step 3 set as done for paper
  if (
    order.supportType === SupportType.PAPER &&
    step === OrderDetailsSteps.RECEIVED
  ) {
    return (
      <span>
        <Trans
          i18nKey={i18nKeys.orders.details.paper.done.alert.subTitle}
          values={{
            shippingDate: Format.date(order.shippingDate),
          }}
        >
          sent on [%s:shippingDate] via Bpost
          <br />
          You receive them within 3 working days.
        </Trans>
      </span>
    );
  }
  // Default
  return undefined;
};

export const getFormattedAmount = (order: OrderWithQuantityAndTotal) =>
  `${order.voucherSets.reduce(
    (acc, set) =>
      acc
        ? `${acc} + ${set.quantity} x ${toCurrencyFormat(set.faceValue)}`
        : `${set.quantity} x ${toCurrencyFormat(set.faceValue)}`,
    '',
  )} = ${toCurrencyFormat(order.total)}`;
