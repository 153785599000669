import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../constants';

export const IdleUserTimeoutModal = () => {
  const { t } = useTranslation();

  return (
    <>
      <SwTitle tagName="h2">{t(i18nKeys.dialog.expireConfirm.title)}</SwTitle>
      <SwActionGroup style={{ marginTop: '3rem' }}>
        <SwButton
          modLarge
          onClick={() => {
            toggleModalById(Modals.IDLE_USER_TIMEOUT_MODAL_ID);
          }}
        >
          {t(i18nKeys.dialog.expireConfirm.cta)}
        </SwButton>
      </SwActionGroup>
    </>
  );
};
