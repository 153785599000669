import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import {
  SwFormColumn,
  SwFormGrid,
  SwFormInput,
  SwFormMessageLabel,
  SwInputField,
} from '@kaa/ui-flanders/components';
import { dataTest } from '@kaa/ui-flanders/datatest/keys';
import React from 'react';
import { RequiredStar } from '@kaa/ui-flanders/components/form-message/SwFormRequiredStar';

type ProfilUserInfoFormInputProps = {
  value: string;
  name: string;
  label: string;
  modRequired?: boolean;
};

export const ProfilUserInfoFormInput = ({
  name,
  label,
  value,
  modRequired = false,
}: ProfilUserInfoFormInputProps) => {
  return (
    <SwFormColumn>
      <SwFormGrid>
        <SwFormColumn width="3" widthS="12">
          <SwFormMessageLabel htmlFor={name}>
            {label}
            {modRequired && <RequiredStar />}
          </SwFormMessageLabel>
        </SwFormColumn>
        <SwFormColumn width="9" widthS="12">
          <SwFormInput>
            <SwInputField
              id={name}
              data-testid={dynamicDataTest(dataTest.SwInputTextField, {
                fieldName: name,
              })}
              value={value}
              modBlock
              modDisabled
              modRequired={modRequired}
            />
          </SwFormInput>
        </SwFormColumn>
      </SwFormGrid>
    </SwFormColumn>
  );
};
