import { FieldProps, FormikProps } from 'formik';

export const mergeClassNames = (...classes: Array<string | undefined>) => {
  return classes.reduce((className, selectors) => {
    return selectors ? `${className} ${selectors}` : className;
  }, '');
};

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type FormikPropsClean<Values> = Omit<FormikProps<Values>, 'errors'> & {
  errors: any;
};
export type FieldPropsClean<Values = any> = Omit<FieldProps<Values>, 'form'> & {
  form: FormikPropsClean<Values>;
};
