export enum Icon {
  ADD = 'add',
  ALERT_CIRCLE = 'alert-circle',
  ALERT_TRIANGLE_FILLED = 'alert-triangle-filled',
  ARROW_DOWN = 'arrow-down-fat',
  ARROW_LEFT = 'arrow-left-fat',
  ARROW_RIGHT = 'arrow-right-fat',
  ARROW_UP = 'arrow-up-fat',
  BIN = 'bin',
  BURGER_PROFILE = 'burgerprofiel',

  EXPAND = 'expand-horizontal-alt',
  CALENDAR = 'calendar',
  CHAT = 'chat-help',
  CHECK_CIRCLE = 'check-circle',
  CHECK_SMALL = 'check-small',
  CLOSE = 'close',
  DATA_TRANSFER = 'data-transfer',
  DATA_DOWNLOAD = 'data-download',
  DATA_UPLOAD = 'data-upload',
  DOCUMENT = 'document',
  EMAIL = 'email',
  FIELD = 'field',
  FILE_DOWNLOAD = 'file-download',
  FILE_EDIT = 'file-edit',
  FILE_NEW = 'file-new',
  FILE_UPLOAD = 'file-upload',
  EDIT = 'edit',
  ENVELOPE = 'envelope',
  EXTERNAL = 'external',
  INFO = 'info',
  INFO_CIRCLE = 'info-circle',
  INFO_SMALL = 'info-small',
  HOURGLASS = 'hourglass',
  IRONING = 'ironing',
  LIGHT_BULB = 'lightbulb',
  LOCATION = 'location',
  LOCK = 'lock',
  LOGOUT = 'logout',
  NAV_SHOW_MORE_HORIZONTAL = 'nav-show-more-horizontal',
  PAPER_CLIP = 'paperclip',
  PICK_UP = 'pick-up',
  PHONE = 'phone',
  PLAY = 'play',
  PAUSE = 'pause',
  PLUS_CIRCLE = 'plus-circle',
  PRINT = 'printer',
  PROGRAMMING_BUG = 'programming-bug',
  QUESTION_MARK = 'question-mark',
  QUESTION_MARK_FILLED = 'question-mark-filled',
  SAVE = 'save',
  SCAN = 'scan',
  SCISSORS = 'voucher-scissors',
  SEARCH = 'search',
  SEND_DOCUMENT = 'send-document', // Doesn't not exist in VL
  SHOPPING = 'shopping',
  TRUCK = 'shipping-truck',
  UNDO = 'text-undo',
  GROCERY_SHOPPING = 'shopping',
  SYNCHRONIZE = 'synchronize',
  TIMELINE = 'timeline',
  TRANSPORT = 'car',
  DISABLED_PERSON_TRANSPORTATION = 'car',
  UP_DOWN_ARROWS = 'up-down-arrows',
  USER = 'user',
  USER_ACTIF = 'profile-active',
  USER_CIRCLE = 'user-circle', // Doesn't not exist in VL
  USER_CIRCLE_ACTIF = 'user-circle-active', // Doesn't not exist in VL
  USER_SIGNUP = 'user-signup',
  VACCUM_CLEANER = 'vaccum-cleaner',
  HOUSEKEEPING = 'vaccum-cleaner',
  VOUCHER_CHECK = 'voucher-check',
  VOUCHERS_LIST = 'vouchers-list',
  VOUCHER_DOWNLOAD = 'voucher-download',
  WALLET = 'wallet',
  SHOW = 'view-add',
  HIDE = 'hide',
  SYNCHRONISE = 'synchronize',
  WORKSPENDING = 'workspending', // Doesn't not exist in VL
  WORKSADD = 'works-add', // Doesn't not exist in VL
  DOWNLOAD_LISTFILE = 'download-list-file', // Doesn't not exist in VL
  UPLOAD_LISTFILE = 'upload-list-file', // Doesn't not exist in VL
  AUTOFILE = 'auto-file',
  STAR = 'vote-star',
  FILM = 'film',
  NEWSPAPER = 'newspaper',
}
