import { SwContainer, SwFetchErrorMessage } from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { getPath, Routes } from '../routes';

export const NotFoundScreen = (props: RouteComponentProps) => {
  return (
    <SwContainer error>
      <SwFetchErrorMessage redirectTo={getPath(Routes.LANDING)} />
    </SwContainer>
  );
};
