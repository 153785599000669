import { VouchersOrderItemCreate } from '@kaa/api/customers';
import { toCurrencyFormat } from '@kaa/common/utils';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwColumn, SwDataTable } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { getOrderTotal } from '../OrderModal.utils';

export const OrderTable = ({
  orderItems,
}: {
  orderItems: VouchersOrderItemCreate[];
}) => {
  const { t } = useTranslation();
  const total = getOrderTotal(orderItems);
  return (
    <SwColumn>
      <SwDataTable className="vl-u-spacer--xsmall">
        <thead>
          <tr data-testid={dataTest.wallet.order.table.tableHeader}>
            <th
              style={{ width: '60%' }}
              data-testid={dataTest.wallet.order.table.tableHeaderProduct}
            >
              {t(i18nKeys.general.label.product)}
            </th>
            <th
              className="vl-u-align-center"
              data-testid={dataTest.wallet.order.table.tableQuantityLabel}
            >
              {t(i18nKeys.newOrder.stepOrder.form.tableHeader.quantity)}
            </th>
            <th
              className="vl-u-align-right"
              data-testid={dataTest.wallet.order.table.tableValueLabel}
            >
              {t(i18nKeys.newOrder.stepOrder.form.tableHeader.value)}
            </th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map(({ faceValue, quantity }, index) => {
            return (
              <tr
                key={faceValue + quantity}
                data-testid={dynamicDataTest(
                  dataTest.wallet.order.table.tableRow,
                  { index },
                )}
              >
                <td>
                  {t(i18nKeys.newOrder.stepOrder.form.tableRow.vouchers, {
                    count: quantity,
                    quantity,
                  })}
                </td>
                <td
                  data-testid={dynamicDataTest(
                    dataTest.wallet.order.table.tableRowQuantity,
                    { index },
                  )}
                  className="vl-u-align-center"
                >
                  {quantity}
                </td>
                <td
                  data-testid={dynamicDataTest(
                    dataTest.wallet.order.table.tableRowValue,
                    { index },
                  )}
                  className="vl-u-align-right"
                >
                  {toCurrencyFormat(faceValue)}
                </td>
              </tr>
            );
          })}
          <tr style={{ border: 'none' }}>
            <td colSpan={3} className="vl-u-align-right vl-u-text--bold">
              {t(i18nKeys.general.label.total)} {toCurrencyFormat(total)}
            </td>
          </tr>
        </tbody>
      </SwDataTable>

      <SwColumn className="vl-u-spacer--medium vl-u-text--xsmall">
        <p>{t(i18nKeys.newOrder.stepOrder.form.tableFooter.legend)}</p>
      </SwColumn>
    </SwColumn>
  );
};
