import { CustomerActionType, CustomerLanguages } from '@kaa/api/customers';
import { ActionsHandler } from '@kaa/common/handlers';
import { Mask } from '@kaa/common/utils';
import { setError } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
    Icon,
    SwAlert,
    SwColumn,
    SwFormColumn,
    SwFormGrid,
    SwInputTextField,
    SwLink,
    SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MotionDiv } from '../../../../components/animation/MotionDiv';
import { ProfileScreenFieldNames } from '../ProfileScreen.constants';
import { ProfileCustomerEmailInfoForm } from './ProfileCustomerEmailInfoForm';

type ProfileFormCustomerContactInfoProps = {
    isConfirmEmailAddress: boolean;
    setFieldValue: (field: string, value: any) => void;
    userEmailAddress: string;
    customerAvailableLanguages: CustomerLanguages[];
    customerResourceId: string;
};

const gridMargin = window.innerWidth < 767 ? '1.5rem' : '3rem';
const toggleEmailVariants = {
    close: {
        height: 0,
        overflow: 'hidden',
        marginBottom: 0,
        marginTop: 0,
        minWidth: `calc(100% + ${gridMargin})`,
    },
    open: {
        height: 'auto',
        overflow: 'hidden',
        marginTop: 15,
        transitionEnd: {
            overflow: 'inherit',
        },
    },
};

export const ProfileCustomerContactInfoForm = ({
    isConfirmEmailAddress,
    setFieldValue,
    userEmailAddress,
    customerResourceId,
    customerAvailableLanguages,
}: ProfileFormCustomerContactInfoProps) => {
    const { t } = useTranslation();
    const languageOption = customerAvailableLanguages.map((language) => ({
        value: language,
        text: t(i18nKeys.general.language[language]),
    }));

    return (
        <SwFormGrid modStacked>
            <Field
                name={ProfileScreenFieldNames.PHONE_NUMBER}
                label={t(i18nKeys.general.label.phoneNumber)}
                component={SwInputTextField}
                mask={Mask.phoneNumber}
                type="tel"
                autoComplete="tel"
            />
            <Field
                name={ProfileScreenFieldNames.MOBILE_PHONE_NUMBER}
                label={t(i18nKeys.general.label.mobilePhoneNumber)}
                component={SwInputTextField}
                mask={Mask.mobilePhoneNumber}
                type="tel"
                autoComplete="tel"
            />

            <ProfileCustomerEmailInfoForm
                isConfirmEmailAddress={isConfirmEmailAddress}
                userEmailAddress={userEmailAddress}
                setFieldValue={setFieldValue}
                customerResourceId={customerResourceId}
                customerAvailableLanguages={
                    customerAvailableLanguages
                }
            />

            <ActionsHandler
                resourceId={customerResourceId}
                actions={[CustomerActionType.CAN_CHANGE_LANGUAGE_PROFILE]}
            >
                {({ isAllowed }) => (
                    <Field
                        name={ProfileScreenFieldNames.LANGUAGE}
                        label={t(i18nKeys.profile.communicationLanguage)}
                        component={SwSelectField}
                        options={languageOption}
                        modDisabled={!isAllowed}
                        modRequired
                    />
                )}
            </ActionsHandler>
        </SwFormGrid>
    );
};
