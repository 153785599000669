import { RegionCode } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwColumn,
  SwSlider,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../common/config';
import {
  EventPathname,
  sendModalInteractionToGTM,
  sentPageViewEvent,
} from '../../utils/google-analytics';
import './OnboardingModal.style.scss';
import { Slide } from './OnBoardingModal.type';
import { getServiceVoucherSlideLokaliseKeyPerRegion } from './OnboardingModal.utils';
import { OnboardingModalSlide } from './OnboardingModalSlide';

type OnboardingModalProps = {
  modalId: string;
  slides: Slide[];
};

export const OnboardingModal = ({ slides, modalId }: OnboardingModalProps) => {
  const { t } = useTranslation();

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  useEffect(() => {
    sentPageViewEvent({
      pathname: `${EventPathname.ONBOARDING_MODAL}/step-1`,
    });
  }, []);

  return (
    <>
      <SwSlider
        className="onboarding"
        dots
        equalHeight
        keyboardNavigation
        arrows={false}
        bottomChildren={slides.map(
          (slide) => ({ next, previous, selectedIndex }: any) => (
            <SwColumn
              className="vl-slider__frame onboarding-description"
              key={selectedIndex}
            >
              <p style={{ minHeight: '10rem' }}>
                {t(
                  getServiceVoucherSlideLokaliseKeyPerRegion(slide, regionCode),
                )}
              </p>
              {slides.length > 1 && (
                <SwActionGroup>
                  <SwButton
                    onClick={(event) => {
                      previous(event);
                      sendModalInteractionToGTM(
                        `OnboardingModal ${
                          slide.modalName
                        } step${selectedIndex + 1}`,
                        `Goto step${selectedIndex}`,
                      );
                      sentPageViewEvent({
                        pathname: `${
                          EventPathname.ONBOARDING_MODAL
                        }/step-${selectedIndex - 1}`,
                      });
                    }}
                    modDisabled={!selectedIndex}
                  >
                    {t(i18nKeys.general.previous)}
                  </SwButton>
                  {selectedIndex === slides.length - 1 ? (
                    <SwButton
                      onClick={() => {
                        toggleModalById(modalId);
                        sendModalInteractionToGTM(
                          `OnboardingModal ${slide.modalName}`,
                          'Close Modal after next Button',
                        );
                      }}
                    >
                      {t(i18nKeys.general.close)}
                    </SwButton>
                  ) : (
                    <SwButton
                      onClick={(event) => {
                        next(event);
                        sendModalInteractionToGTM(
                          `OnboardingModal ${
                            slide.modalName
                          } step${selectedIndex + 1}`,
                          `Goto step${selectedIndex + 2}`,
                        );
                        sentPageViewEvent({
                          pathname: `${
                            EventPathname.ONBOARDING_MODAL
                          }/step-${selectedIndex + 1}`,
                        });
                      }}
                      modDisabled={selectedIndex === slides.length - 1}
                    >
                      {t(i18nKeys.general.next)}
                    </SwButton>
                  )}
                </SwActionGroup>
              )}
            </SwColumn>
          ),
        )}
      >
        {slides.map((slide, index) => (
          <OnboardingModalSlide slide={slide} key={slide.content.title} />
        ))}
      </SwSlider>
    </>
  );
};
