import { mockedDelay, toAxiosResponse } from '@kaa/api/common';
import { AxiosPromise } from 'axios';
import { BFFCustomersApi } from './customers';
import {
  confirmVouchersExchangeResponse,
  confirmVouchersRefundResponse,
  createCustomerVacancyResponse,
  createVouchersOrderResponse,
  deleteCustomerVacancyResponse,
  getBuyableVouchersResponse,
  getCustomerBankAccountsResponse,
  getCustomerExchangeableVouchersResponse,
  getCustomerPreferencesResponse,
  getCustomerResponse,
  getCustomerVacanciesResponse,
  getCustomerVacancyEndDateResponse,
  getCustomerWalletResponse,
  getDashboardResponse,
  getFiscalAttestationDocumentResponse,
  getFiscalAttestationResponse,
  getGlobalLocationsResponse,
  getGlobalRegionContextResponse,
  getLocationsSuggestionsResponse,
  getPendingOrderResponse,
  getRefundOrExchangeDocument,
  getServiceDeliveryResponse,
  getServiceDetailResponse,
  getServicesForDashboardResponse,
  getServicesHistoryResponse,
  getVoucherOrdersHistoryResponse,
  getVoucherPurchaseResponse,
  supportMessageResponse,
  updateCustomerResponse,
} from './mocks';
import {
  CustomerBankAccountsResponse,
  CustomerBuyableVouchersResponse,
  CustomerCreate,
  CustomerExchangeableVouchersResponse,
  CustomerFiscalAttestationResponse,
  CustomerPreferences,
  CustomerPreferencesResponse,
  CustomerResponse,
  CustomerResponseWithMessages,
  CustomerSupportMessageCreate,
  CustomerSupportMessageCreatedResponse,
  CustomerUpdate,
  CustomerVacanciesResponse,
  CustomerVacancy,
  CustomerVacancyDeletedResponse,
  CustomerVacancyEndDateResponse,
  CustomerVacancyResponse,
  CustomerVoucherPurchasesResponse,
  CustomerWalletResponse,
  DashboardResponse,
  FiscalAttestationDocumentParams,
  FiscalAttestationParams,
  GetCustomerVacancyEndDateParams,
  GetCustomerWalletParams,
  GetLocationsSuggestionsParams,
  GetVoucherOrdersHistoryParams,
  GlobalConfigurationContextReponse,
  GlobalRegionContextReponse,
  GlobalUserContextReponse,
  HandiwebRequest,
  KlarnaSessionCreate,
  KlarnaSessionCreateV2,
  KlarnaSessionCreatedResponse,
  KlarnaSessionInitiate,
  KlarnaSessionInitiatedResponse,
  KlarnaVouchersPurchaseCreate,
  KlarnaVouchersPurchaseCreatedResponse,
  LocationsResponse,
  NoContentResponse,
  PaperVouchersExchangeCreate,
  PaperVouchersRefundCreate,
  ServiceContestationRequest,
  ServiceDeliveryDetailsResponse,
  ServiceDeliveryResponse,
  ServiceHistoryResponse,
  ServicesManagementResponse,
  StreetsResponse,
  StringValueFileCreatedResponse,
  UserEmailUpdate,
  UserPasswordUpdate,
  VoucherOrderResponse,
  VoucherPurchaseResponse,
  VouchersExchangeCreate,
  VouchersExchangeCreatedResponse,
  VouchersOrderCreate,
  VouchersOrderCreatedResponse,
  VouchersRefundCreate,
  VouchersRefundCreatedResponse,
} from './model';

export const getBFFCustomersMock = (): BFFCustomersApi => {
  return {
    liveness(): AxiosPromise<unknown> {
      return toAxiosResponse(mockedDelay('healthy'));
    },
    // Get settings related to the current region.
    getGlobalRegionContext(
      version?: number,
    ): AxiosPromise<GlobalRegionContextReponse> {
      return toAxiosResponse(mockedDelay(getGlobalRegionContextResponse()));
    },
    // Get global settings related to the mobile application.
    getGlobalConfiguration(
      version?: number,
    ): AxiosPromise<GlobalConfigurationContextReponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get logged in user related data. Allowed actions, login details, linked customers and their global messages
    getGlobalUserContext(
      version?: number,
    ): AxiosPromise<GlobalUserContextReponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Update logged in user email address
    updateUserEmail(
      userEmailUpdate: UserEmailUpdate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Update logged in user password
    updateUserPassword(
      userPasswordUpdate: UserPasswordUpdate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get all locations
    getGlobalLocations(version?: number): AxiosPromise<LocationsResponse> {
      return toAxiosResponse(mockedDelay(getGlobalLocationsResponse()));
    },
    // Search streets by keyword and postcode
    getLocationsSuggestions(
      params?: GetLocationsSuggestionsParams,
      version?: number,
    ): AxiosPromise<StreetsResponse> {
      return toAxiosResponse(
        mockedDelay(getLocationsSuggestionsResponse(params)),
      );
    },
    // Get customer
    getCustomer(
      customerId: number,
      version?: number,
    ): AxiosPromise<CustomerResponse> {
      return toAxiosResponse(
        mockedDelay(getCustomerResponse({ data: { id: customerId } })),
      );
    },
    // Update customer
    updateCustomer(
      customerId: number,
      customerUpdate: CustomerUpdate,
      version?: number,
    ): AxiosPromise<CustomerResponseWithMessages> {
      return toAxiosResponse(
        mockedDelay(updateCustomerResponse(customerUpdate)),
      );
    },
    // Register a new customer
    registerCustomer(
      customerCreate: CustomerCreate,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Switch customer language for people living in town with linguistic facilities. Default change language is done in customer profile
    switchCustomerLanguages(
      customerId: number,
      version?: number,
    ): AxiosPromise<CustomerResponse> {
      return toAxiosResponse(
        mockedDelay(getCustomerResponse({ data: { id: customerId } })),
      );
    },
    // Submit a request for quota 2000 - A User may benefit from a 2000 quota for his own name or for a dependent person, for a total of 2000 service vouchers
    switchCustomerHandiweb(
      customerId: number,
      handiwebRequest: HandiwebRequest,
      version?: number,
    ): AxiosPromise<NoContentResponse> {
      return toAxiosResponse(mockedDelay());
    },
    // Get customer preferences
    getCustomerPreferences(
      customerId: number,
      version?: number,
    ): AxiosPromise<CustomerPreferencesResponse> {
      return toAxiosResponse(mockedDelay(getCustomerPreferencesResponse()));
    },
    // Update customer preferences
    updateCustomerPreferences(
      customerId: number,
      customerPreferences: CustomerPreferences,
      version?: number,
    ): AxiosPromise<CustomerResponseWithMessages> {
      return toAxiosResponse(mockedDelay());
    },
    // Get customer vacancy max end date
    getCustomerVacancyEndDate(
      customerId: number,
      params?: GetCustomerVacancyEndDateParams,
      version?: number,
    ): AxiosPromise<CustomerVacancyEndDateResponse> {
      return toAxiosResponse(
        mockedDelay(getCustomerVacancyEndDateResponse(params)),
      );
    },
    // Get customer vacancies
    getCustomerVacancies(
      customerId: number,
      version?: number,
    ): AxiosPromise<CustomerVacanciesResponse> {
      return toAxiosResponse(mockedDelay(getCustomerVacanciesResponse()));
    },
    // Create customer vacancy
    createCustomerVacancy(
      customerId: number,
      customerVacancy: CustomerVacancy,
      version?: number,
    ): AxiosPromise<CustomerVacancyResponse> {
      return toAxiosResponse(
        mockedDelay(createCustomerVacancyResponse(customerVacancy)),
      );
    },
    // Delete a customer vacancy
    deleteCustomerVacancy(
      customerId: number,
      vacancyId: string,
      version?: number,
    ): AxiosPromise<CustomerVacancyDeletedResponse> {
      return toAxiosResponse(
        mockedDelay(deleteCustomerVacancyResponse(vacancyId)),
      );
    },
    // Get customer wallet
    getCustomerWallet(
      customerId: number,
      params?: GetCustomerWalletParams,
      version?: number,
    ): AxiosPromise<CustomerWalletResponse> {
      return toAxiosResponse(mockedDelay(getCustomerWalletResponse(params)));
    },
    // Get customer exchangeable vouchers
    getCustomerExchangeableVouchers(
      customerId: number,
      version?: number,
    ): AxiosPromise<CustomerExchangeableVouchersResponse> {
      return toAxiosResponse(
        mockedDelay(getCustomerExchangeableVouchersResponse(customerId)),
      );
    },
    // Get customer bank accounts
    getCustomerBankAccounts(
      customerId: number,
      version?: number,
    ): AxiosPromise<CustomerBankAccountsResponse> {
      return toAxiosResponse(mockedDelay(getCustomerBankAccountsResponse()));
    },
    // Get the summary of the vouchers you can order
    getBuyableVouchers(
      customerId: number,
      version?: number,
    ): AxiosPromise<CustomerBuyableVouchersResponse> {
      return toAxiosResponse(mockedDelay(getBuyableVouchersResponse()));
    },
    // Get customer vouchers orders history
    getVoucherOrdersHistory(
      customerId: number,
      params?: GetVoucherOrdersHistoryParams,
      version?: number,
    ): AxiosPromise<CustomerVoucherPurchasesResponse> {
      return toAxiosResponse(
        mockedDelay(getVoucherOrdersHistoryResponse(params)),
      );
    },
    // Get customer voucher purchase details (actually paid)
    getVoucherPurchase(
      customerId: number,
      purchaseId: number,
      version?: number,
    ): AxiosPromise<VoucherPurchaseResponse> {
      return toAxiosResponse(mockedDelay(getVoucherPurchaseResponse()));
    },
    // Submit a new pending order of vouchers. Will be converted to a purchase once paid
    createVouchersOrder(
      customerId: number,
      vouchersOrderCreate: VouchersOrderCreate,
      version?: number,
    ): AxiosPromise<VouchersOrderCreatedResponse> {
      return toAxiosResponse(
        mockedDelay(createVouchersOrderResponse(vouchersOrderCreate)),
      );
    },
    // Get pending customer voucher order (to be paid)
    getPendingOrder(
      customerId: number,
      version?: number,
    ): AxiosPromise<VoucherOrderResponse> {
      return toAxiosResponse(mockedDelay(getPendingOrderResponse()));
    },
    // Request a refund for service vouchers
    confirmVouchersRefund(
      customerId: number,
      vouchersRefundCreate: VouchersRefundCreate,
      version?: number,
    ): AxiosPromise<VouchersRefundCreatedResponse> {
      return toAxiosResponse(
        mockedDelay(confirmVouchersRefundResponse(vouchersRefundCreate)),
      );
    },
    // Request a refund for service vouchers
    createPaperVouchersRefundDocument(
      customerId: number,
      { sendResultInStringFormat }: PaperVouchersRefundCreate,
      version?: number,
    ): AxiosPromise<Blob | StringValueFileCreatedResponse> {
      return toAxiosResponse(
        mockedDelay(getRefundOrExchangeDocument(sendResultInStringFormat)),
      );
    },
    // Request an exchange for service vouchers
    confirmVouchersExchange(
      customerId: number,
      vouchersExchangeCreate: VouchersExchangeCreate,
      version?: number,
    ): AxiosPromise<VouchersExchangeCreatedResponse> {
      return toAxiosResponse(
        mockedDelay(confirmVouchersExchangeResponse(vouchersExchangeCreate)),
      );
    },
    // Request an exchange for paper service vouchers
    confirmPaperVouchersExchange(
      customerId: number,
      { sendResultInStringFormat }: PaperVouchersExchangeCreate,
      version?: number,
    ): AxiosPromise<Blob | StringValueFileCreatedResponse> {
      return toAxiosResponse(
        mockedDelay(getRefundOrExchangeDocument(sendResultInStringFormat)),
      );
    },
    // Create a new session with klarna
    createKlarnaSession(
      customerId: number,
      klarnaSessionCreate: KlarnaSessionCreate,
      version?: number,
    ): AxiosPromise<KlarnaSessionCreatedResponse> {
      // TODO @victor do we mock this ? because it is linked to klarna
      return toAxiosResponse(Promise.reject(new Error('Not Implemented')));
    },
    // Create a new session with klarna V2
    createSession(
      customerId: number,
      klarnaSessionCreateV2: KlarnaSessionCreateV2,
      version?: number,
    ): AxiosPromise<KlarnaSessionCreatedResponse> {
      // TODO @victor do we mock this ? because it is linked to klarna
      return toAxiosResponse(Promise.reject(new Error('Not Implemented')));
    },
    // Initiate a new session with klarna V2
    initiateSession(
      customerId: number,
      klarnaSessionInitiate: KlarnaSessionInitiate,
      version?: number,
    ): AxiosPromise<KlarnaSessionInitiatedResponse> {
      // TODO @victor do we mock this ? because it is linked to klarna
      return toAxiosResponse(Promise.reject(new Error('Not Implemented')));
    },
    // Submit a new purchase of vouchers using Klarna
    createKlarnaPurchase(
      customerId: number,
      klarnaVouchersPurchaseCreate: KlarnaVouchersPurchaseCreate,
      version?: number,
    ): AxiosPromise<KlarnaVouchersPurchaseCreatedResponse> {
      // TODO @victor do we mock this ? because it is linked to klarna
      return toAxiosResponse(Promise.reject(new Error('Not Implemented')));
    },
    // Get services for dashboard
    getServicesForDashboard(
      customerId: number,
      version?: number,
    ): AxiosPromise<ServicesManagementResponse> {
      return toAxiosResponse(mockedDelay(getServicesForDashboardResponse()));
    },
    // Get services history
    getServicesHistory(
      customerId: number,
      version?: number,
    ): AxiosPromise<ServiceHistoryResponse> {
      return toAxiosResponse(mockedDelay(getServicesHistoryResponse()));
    },
    // Get service detail
    getServiceDetail(
      customerId: number,
      serviceId: number,
      version?: number,
    ): AxiosPromise<ServiceDeliveryDetailsResponse> {
      return toAxiosResponse(mockedDelay(getServiceDetailResponse()));
    },
    // Confirm a service
    confirmService(
      customerId: number,
      serviceId: number,
      version?: number,
    ): AxiosPromise<ServiceDeliveryResponse> {
      return toAxiosResponse(
        mockedDelay(getServiceDeliveryResponse(serviceId)),
      );
    },
    // Contest a service
    contestService(
      customerId: number,
      serviceId: number,
      serviceContestationRequest: ServiceContestationRequest,
      version?: number,
    ): AxiosPromise<ServiceDeliveryResponse> {
      return toAxiosResponse(
        mockedDelay(
          getServiceDeliveryResponse(serviceId, serviceContestationRequest),
        ),
      );
    },
    // Get dashboard information
    getDashboard(
      customerId: number,
      version?: number,
    ): AxiosPromise<DashboardResponse> {
      return toAxiosResponse(mockedDelay(getDashboardResponse()));
    },
    // Send an email to support
    supportMessage(
      customerId: number,
      customerSupportMessageCreate: CustomerSupportMessageCreate,
      version?: number,
    ): AxiosPromise<CustomerSupportMessageCreatedResponse> {
      return toAxiosResponse(
        mockedDelay(supportMessageResponse(customerSupportMessageCreate)),
      );
    },
    // Get fiscal attestation
    fiscalAttestation(
      customerId: number,
      params?: FiscalAttestationParams,
      version?: number,
    ): AxiosPromise<CustomerFiscalAttestationResponse> {
      return toAxiosResponse(mockedDelay(getFiscalAttestationResponse(params)));
    },
    // Get fiscal attestation document
    fiscalAttestationDocument(
      customerId: number,
      fileId: string,
      params?: FiscalAttestationDocumentParams,
      version?: number,
    ): AxiosPromise<Blob | StringValueFileCreatedResponse> {
      return toAxiosResponse(
        mockedDelay(getFiscalAttestationDocumentResponse(params)),
      );
    },
    // Get fiscal attestation document for Flanders
    fiscalAttestationDocumentForFlanders(
      niss: number,
      fileId: number,
      language: string,
      version?: number,
    ): AxiosPromise<Blob> {
      return toAxiosResponse(mockedDelay());
    },
  };
};
