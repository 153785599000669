/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type ActivityType =
  | 'HOUSEKEEPING'
  | 'GROCERY_SHOPPING'
  | 'IRONING'
  | 'DISABLED_PERSON_TRANSPORTATION';

export const ActivityType = {
  HOUSEKEEPING: 'HOUSEKEEPING' as ActivityType,
  GROCERY_SHOPPING: 'GROCERY_SHOPPING' as ActivityType,
  IRONING: 'IRONING' as ActivityType,
  DISABLED_PERSON_TRANSPORTATION: 'DISABLED_PERSON_TRANSPORTATION' as ActivityType,
};
