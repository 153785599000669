import { useAuthState } from '@kaa/auth/common';
import { SwColumn } from '@kaa/ui-flanders/components';
import React, { ReactNode } from 'react';
import { AuthContext } from '../../types';
import { AlertManager } from '../alertManager/AlertManager';
import { PageHeaderIntro } from './PageHeaderIntro';

export type PageHeaderProps = {
  title?: string;
  className?: string;
  introduction?: string | ReactNode;
  alert?: ReactNode;
};

export const PageHeader = ({ alert, ...rest }: PageHeaderProps) => {
  const { customers, selectedCustomerId, messages } = useAuthState<
    AuthContext
  >();

  if (!customers || !selectedCustomerId) {
    return <PageHeaderIntro {...rest} />;
  }

  const selectedCustomer = customers.find(
    ({ id }) => id.toString() === selectedCustomerId,
  );

  const customerMessages =
    messages && selectedCustomer && messages[selectedCustomer.resourceId];

  return (
    <>
      <PageHeaderIntro {...rest} />
      {((customerMessages && customerMessages.length) || alert) && (
        // <div className="vl-alert-wrapper"> ??
        <SwColumn width="12">
          <AlertManager />
          {alert}
        </SwColumn>
        // </div> ??
      )}
    </>
  );
};
