import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwContainer,
  SwButton,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../components';

export const ForbiddenWrongRegion = ({
  links,
}: RouteComponentProps<{
  links: Array<{ id: string | number; href: string; text: string }>;
}>) => {
  const { t } = useTranslation();

  return (
    <SwContainer error>
      <PageHeader
        title={t(i18nKeys.forbiddenWrongRegion.title)}
        introduction={t(i18nKeys.forbiddenWrongRegion.introduction)}
        className="vl-u-spacer--medium"
      />

      {links && (
        <SwActionGroup>
          {links.map(({ id, href, text }) => (
            <SwButton key={id} href={t(href)} tagName="a" modLarge>
              {t(text)}
            </SwButton>
          ))}
        </SwActionGroup>
      )}
    </SwContainer>
  );
};
