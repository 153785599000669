import React, { AllHTMLAttributes, createElement, ReactNode } from 'react';
import { SwBadge } from '../badge/SwBadge';
import { SwIcon } from '../icon/SwIcon';
import { mergeClassNames } from '../utilities';
import { SwTitle } from '../title/SwTitle';

type SwInfoTileSpotlightProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  modEllipsis?: boolean;
  modBadge?: boolean;
  modLarge?: boolean;
  modMedium?: boolean;
  modVCenter?: boolean;
  footer?: ReactNode;
  icon?: string;
};

type Classes = {
  modLarge?: boolean;
  modMedium?: boolean;
  modVCenter?: boolean;
};

const getClasses = ({ modLarge, modMedium, modVCenter }: Classes) => {
  return [
    'vl-info-tile',
    'vl-info-tile--center',
    modLarge ? 'vl-info-tile--l' : '',
    modMedium ? 'vl-info-tile--m' : '',
    modVCenter ? 'vl-info-tile--v-center' : '',
  ].join(' ');
};

export const SwInfoTileSpotlight = <T extends HTMLElement>({
  tagName,
  title,
  subTitle,
  modBadge = false,
  modEllipsis,
  modLarge,
  modMedium,
  modVCenter,
  className,
  children,
  href,
  footer,
  icon,
  ...rest
}: SwInfoTileSpotlightProps<T>) => {
  const TagName = tagName || 'div';

  const classes = getClasses({
    modLarge,
    modMedium,
    modVCenter,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    <>
      {(icon || title) && (
        <header
          className="vl-info-tile__header vl-u-spacer--xsmall"
          role="presentation"
          style={{ flex: 'none' }}
        >
          {icon && !modBadge && (
            <SwIcon className="vl-info-tile__header__icon" icon={icon} />
          )}
          {icon && modBadge && (
            <SwBadge
              modAccent
              modMedium
              className="vl-info-tile__header__icon"
              icon={icon}
            />
          )}
          {title && (
            <div className="vl-info-tile__header__wrapper">
              <SwTitle
                tagStyle="h3"
                tagName="h3"
                className={`vl-info-tile__header__title ${
                  modEllipsis ? 'vl-u-text--ellipse' : ''
                }`}
              >
                {title}
              </SwTitle>
              {subTitle && (
                <SwTitle
                  tagName="h4"
                  tagStyle="h4"
                  className={modEllipsis ? 'vl-u-text--ellipse' : ''}
                >
                  {subTitle}
                </SwTitle>
              )}
            </div>
          )}
        </header>
      )}
      {children && (
        // TOTO @GILLES <div className="vl-info-tile__content" style={{ flex: 'none' }}> I remove this fix (flex: 'none')
        <div
          className="vl-info-tile__content"
          style={{ flex: '0 0 auto', flexGrow: 1 }}
        >
          {children}
        </div>
      )}
      {footer && <footer className="vl-info-tile__footer">{footer}</footer>}
    </>,
  );
};
