import { useAuthState } from '@kaa/auth/common';
import { useInactivity } from '@kaa/common/utils';
import { showModalById, SwModal } from '@kaa/ui-flanders/components';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Modals } from '../../constants';
import { AuthContext } from '../../types';
import { IdleUserTimeoutModal } from './IdleUserTimeoutModal';

const INACTIVITY_MAX = 900000; // 900000 = 15 min
const INACTIVITY_ALERT = INACTIVITY_MAX - 60000; // 60000 = 1 min

export const IdleUserTimeout = () => {
  const { authUser, logout } = useAuthState<AuthContext>();

  const onCheck = (date: string): void => {
    const datetime = DateTime.fromISO(date);

    if (!datetime.isValid) {
      return;
    }

    const { milliseconds } = DateTime.local().diff(datetime, 'milliseconds');

    if (milliseconds > INACTIVITY_ALERT) {
      showModalById(Modals.IDLE_USER_TIMEOUT_MODAL_ID);
    }
  };

  const [isInactive, reset] = useInactivity({
    ms: INACTIVITY_MAX,
    onCheck,
  });

  useEffect(() => {
    if (!isInactive) {
      return;
    }

    if (!authUser) {
      reset();
      return;
    }
    logout();
  }, [isInactive]);

  return (
    <SwModal
      id={Modals.IDLE_USER_TIMEOUT_MODAL_ID}
      component={IdleUserTimeoutModal}
    />
  );
};
