import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwBadge } from '@kaa/ui-flanders/components';
import { ConcatedBundle } from '../WalletScreen.utils';

type WalletScreenTableRowProps = {
  concatedBundle: ConcatedBundle;
  testId: string;
};

export const WalletScreenTableRow = ({
  concatedBundle,
  testId,
}: WalletScreenTableRowProps) => {
  const { t } = useTranslation();

  const { remainingQuantity, expirationDate } = concatedBundle;
  const expiredDate = DateTime.fromISO(expirationDate);

  const expiredSoon =
    expiredDate.isValid && !expiredDate.diffNow(['days', 'months']).months;

  const formatedDate = expiredDate.isValid
    ? expiredDate.toFormat('dd/LL/yyyy')
    : expirationDate;

  return (
    <tr
      data-testid={testId}
      style={{
        ...(expiredSoon && { background: '#fef9e5' }),
      }}
    >
      <td>
        {t(i18nKeys.general.vouchersCount, {
          count: remainingQuantity,
        })}
      </td>
      <td>
        {expiredSoon ? (
          <div className="vl-u-display-flex">
            <SwBadge
              modWarning
              modXsmall
              style={{ verticalAlign: 'middle' }}
              icon={Icon.INFO_CIRCLE}
            />
            &nbsp;
            <p>
              <strong>{formatedDate}</strong>
            </p>
          </div>
        ) : (
          formatedDate
        )}
      </td>
    </tr>
  );
};
