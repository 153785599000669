/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type CodeError =
  | 'ENTITY_NOT_FOUND'
  | 'INVALID_REQUEST_SYNTAX'
  | 'VALIDATION_EXCEPTION'
  | 'CLIENT_NOT_AUTHENTICATED'
  | 'INTERNAL_SERVER_ERROR'
  | 'EXTERNAL_SERVICE_ERROR'
  | 'KLARNA_SESSION_FAILED'
  | 'KLARNA_ORDER_FAILED'
  | 'QUOTA_EXCEEDED'
  | 'VOUCHER_ORDER_FAILED'
  | 'INVALID_FILE_FORMAT'
  | 'NO_VALID_CONTRACT'
  | 'NOT_ADULT_PROSPECT'
  | 'BUSINESS_SERVICE_ERROR'
  | 'ACTION_UNAVAILABLE_IN_REGION';

export const CodeError = {
  ENTITY_NOT_FOUND: 'ENTITY_NOT_FOUND' as CodeError,
  INVALID_REQUEST_SYNTAX: 'INVALID_REQUEST_SYNTAX' as CodeError,
  VALIDATION_EXCEPTION: 'VALIDATION_EXCEPTION' as CodeError,
  CLIENT_NOT_AUTHENTICATED: 'CLIENT_NOT_AUTHENTICATED' as CodeError,
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR' as CodeError,
  EXTERNAL_SERVICE_ERROR: 'EXTERNAL_SERVICE_ERROR' as CodeError,
  KLARNA_SESSION_FAILED: 'KLARNA_SESSION_FAILED' as CodeError,
  KLARNA_ORDER_FAILED: 'KLARNA_ORDER_FAILED' as CodeError,
  QUOTA_EXCEEDED: 'QUOTA_EXCEEDED' as CodeError,
  VOUCHER_ORDER_FAILED: 'VOUCHER_ORDER_FAILED' as CodeError,
  INVALID_FILE_FORMAT: 'INVALID_FILE_FORMAT' as CodeError,
  NO_VALID_CONTRACT: 'NO_VALID_CONTRACT' as CodeError,
  NOT_ADULT_PROSPECT: 'NOT_ADULT_PROSPECT' as CodeError,
  BUSINESS_SERVICE_ERROR: 'BUSINESS_SERVICE_ERROR' as CodeError,
  ACTION_UNAVAILABLE_IN_REGION: 'ACTION_UNAVAILABLE_IN_REGION' as CodeError,
};
