import {
  Icon,
  SwButton,
  SwColumn,
  SwDescriptionDataItem,
  SwInfoTileSpotlight,
} from '@kaa/ui-flanders/components';
import React, { ReactNode } from 'react';
import {
  DashboardActionEvent,
  sendDashboardActionEventToGTM,
} from '../../utils/google-analytics';

type DashboardCard = {
  children?: ReactNode;
  icon: Icon;
  title: string;
  description?: string;
  subDescription?: string;
  to: string;
  cta: string;
  ctaTagManagerId?: string;
};

export const DashboardCard = ({
  icon,
  title,
  description,
  subDescription,
  to,
  cta,
  ctaTagManagerId,
  children,
}: DashboardCard) => {
  return (
    <SwColumn width="4" widthM="6" widthS="12" className="vl-u-spacer">
      <SwInfoTileSpotlight
        modVCenter
        modBadge
        modEllipsis
        style={{ height: '100%' }}
        title={title}
        icon={icon}
        footer={
          <SwButton
            modBlock
            to={to}
            onClick={() => {
              sendDashboardActionEventToGTM(
                DashboardActionEvent.CARD,
                ctaTagManagerId as string,
              );
            }}
          >
            {cta}
          </SwButton>
        }
      >
        {description && (
          <SwDescriptionDataItem
            inverted
            label={description}
            subdata={subDescription}
            tagName={SwColumn}
            column={{ width: '12' }}
            style={{ background: 'none' }}
          />
        )}
        {children}
      </SwInfoTileSpotlight>
    </SwColumn>
  );
};
