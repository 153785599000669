import get from 'lodash.get';

const regex = /#{[^{]+}/g;

export const dynamicDataTest = (template: string, variables?: any) => {
  return template.replace(regex, (match: string) => {
    const path = match.slice(2, -1).trim();
    return variables ? get(variables, path) : '';
  });
};
