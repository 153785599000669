/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type MessageType = 'INFO' | 'WARNING' | 'ERROR' | 'CONFIRMATION';

export const MessageType = {
  INFO: 'INFO' as MessageType,
  WARNING: 'WARNING' as MessageType,
  ERROR: 'ERROR' as MessageType,
  CONFIRMATION: 'CONFIRMATION' as MessageType,
};
