import { DateTime } from 'luxon';
import { formatStringByPattern } from '../validation';
import { AvailableLanguages } from '../enums';

enum Patterns {
  NATIONAL_NUMBER = 'XX.XX.XX-XXX.XX',

  PREFIX_PHONE_NUMBER_TWO = 'XXXX',
  PHONE_NUMBER_ONE = 'XXX XX XX XX',
  PHONE_NUMBER_ONE_WITH_COUNTRY_PREFIX_ONE = 'XXX XX XX XX XX',
  PHONE_NUMBER_ONE_WITH_COUNTRY_PREFIX_TWO = 'XXXX XX XX XX XX',
  PHONE_NUMBER_TWO = 'XX XXX XX XX',
  PHONE_NUMBER_TWO_WITH_COUNTRY_PREFIX_ONE = 'XXX X XX XX XX',
  PHONE_NUMBER_TWO_WITH_COUNTRY_PREFIX_TWO = 'XXXX X XX XX XX',
  MOBILE_PHONE = 'XXXX XX XX XX',
  MOBILE_PHONE_WITH_COUNTRY_PREFIX_ONE = 'XXX XXX XX XX XX',
  MOBILE_PHONE_WITH_COUNTRY_PREFIX_TWO = 'XXXX XXX XX XX XX',
  DATE = 'XX/XX/XXXX',
}

const PHONE_NUMBER_TWO_PREFIX = ['2', '3', '4', '9'];

export const Format = {
  nationalNumber: (nationalNumber: string, slice = true) => {
    return formatStringByPattern(
      Patterns.NATIONAL_NUMBER,
      nationalNumber,
      slice,
    );
  },
  phoneNumber: (phoneNumber: string) => {
    if (phoneNumber.length < 5) {
      return phoneNumber; // for special phone numbers return unformated
    }
    if (phoneNumber[0] === '+') {
      if (PHONE_NUMBER_TWO_PREFIX.includes(phoneNumber[4])) {
        return formatStringByPattern(
          Patterns.PHONE_NUMBER_TWO_WITH_COUNTRY_PREFIX_ONE,
          phoneNumber,
        );
      }
      return formatStringByPattern(
        Patterns.PHONE_NUMBER_ONE_WITH_COUNTRY_PREFIX_ONE,
        phoneNumber,
      );
    }

    if (phoneNumber.slice(0, 2) === '00') {
      if (PHONE_NUMBER_TWO_PREFIX.includes(phoneNumber[4])) {
        return formatStringByPattern(
          Patterns.PHONE_NUMBER_TWO_WITH_COUNTRY_PREFIX_TWO,
          phoneNumber,
        );
      }
      return formatStringByPattern(
        Patterns.PHONE_NUMBER_ONE_WITH_COUNTRY_PREFIX_TWO,
        phoneNumber,
      );
    }

    if (PHONE_NUMBER_TWO_PREFIX.includes(phoneNumber[1])) {
      return formatStringByPattern(Patterns.PHONE_NUMBER_TWO, phoneNumber);
    }

    return formatStringByPattern(Patterns.PHONE_NUMBER_ONE, phoneNumber);
  },
  mobilePhoneNumber: (phoneNumber: string) => {
    if (phoneNumber[0] === '+') {
      return formatStringByPattern(
        Patterns.MOBILE_PHONE_WITH_COUNTRY_PREFIX_ONE,
        phoneNumber,
      );
    }

    if (phoneNumber.slice(0, 2) === '00') {
      return formatStringByPattern(
        Patterns.MOBILE_PHONE_WITH_COUNTRY_PREFIX_TWO,
        phoneNumber,
      );
    }

    return formatStringByPattern(Patterns.MOBILE_PHONE, phoneNumber);
  },

  iban: (iban?: string) => {
    if (!iban) return iban;
    return (
      iban
        .split('')
        // Don't refactor! Fixes a bug on the IBAN field for certain devices (i.e. Huawei Y7)
        .map((c) => c.toUpperCase())
        .join('')
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
    );
  },

  /**
   * Format a date using Patterns.DATE or by swapping the date part of a
   * zulu timed UTC string to match Belgium locale.
   */
  date: (date?: Date | string | undefined) => {
    if (!date) return date;

    const dateString = date.toString();

    if (!dateString.includes('T') && dateString.includes('/')) {
      return formatStringByPattern(Patterns.DATE, date.toString());
    }

    const wholeSplit = dateString.split('T');
    const dateSplit = wholeSplit[0].split('-');
    return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
  },
  dateMonthYear: (d: string, language: AvailableLanguages) => {
    const dateStr = DateTime.fromISO(d)
      .setZone('Europe/Brussels')
      .setLocale(language)
      .toFormat('MMM yyyy');
    return dateStr;
  },
  dateLong: (d: string, language: AvailableLanguages) => {
    const dateStr = DateTime.fromISO(d)
      .setZone('Europe/Brussels')
      .setLocale(language)
      .toFormat('EEE. d MMM yyyy');
    return dateStr.replace(/^./, dateStr[0].toUpperCase());
  },
  dateForComments: (d: string, language: AvailableLanguages) => {
    return DateTime.fromISO(d)
      .setZone('Europe/Brussels')
      .setLocale(language)
      .toFormat('dd/MM/yyyy HH:mm');
  },
  duration: (duration?: number | string): string => {
    return duration !== undefined ? `${duration}:00` : '';
  },
};
