import { AllHTMLAttributes, createElement, ReactNode } from 'react';
import { ValueOf } from '../types';
import { SwInfoTileDefault } from './SwInfoTileDefault';
import { SwInfoTileSpotlight } from './SwInfoTileSpotlight';

type Connections = { src: string; alt: string };

export enum SwInfoTileType {
  DEFAULT = 'default',
  CALENDAR = 'calendar',
  SPOTLIGHT = 'spotlight',
}

const COMPONENT_BY_TYPE: {
  [key in ValueOf<typeof SwInfoTileType>]: ReactNode;
} = {
  [SwInfoTileType.DEFAULT]: SwInfoTileDefault,
  [SwInfoTileType.SPOTLIGHT]: SwInfoTileSpotlight,
  [SwInfoTileType.CALENDAR]: SwInfoTileDefault,
};

type InfoTileProps<T = HTMLElement> = AllHTMLAttributes<T> & {
  type?: SwInfoTileType;
  tagName?: string;
  title?: string | ReactNode; // this is different from Flanders component due the design requirement
  subtitle?: string | ReactNode; // this is different from Flanders component due the design requirement
  connections?: Connections[];
  modAlt?: boolean;
  modLarge?: boolean;
  modMedium?: boolean;
  modVCenter?: boolean;
  modSubTitleBig?: boolean;
  href?: string;
  badge?: ReactNode;
  badgeColor?: string;
  footer?: ReactNode;
};

export const SwInfoTile = <T extends HTMLElement>({
  type = SwInfoTileType.DEFAULT,
  ...rest
}: InfoTileProps<T>) => {
  return createElement(COMPONENT_BY_TYPE[type] as any, rest);
};
