import {
  Bundle,
  Customer,
  RegionCode,
  SupportType,
  VouchersExchangeCreate,
  VouchersExchangeCreatedResponseDataData,
} from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormSubmitMessage,
  SwLink,
  SwModalRenderProps,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import get from 'lodash.get';
import React, { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../../../common/config';
import { MotionDiv } from '../../../../../../components/animation/MotionDiv';
import { REGION_EXCHANGE_URL } from '../../../../../../constants';
import { dataTest } from '../../../../../../datatest/keys';
import { useApi } from '../../../../../../utils';
import {
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
  sentPageViewEvent,
} from '../../../../../../utils/google-analytics';
import { handleApiError } from '../../../../../../utils/validation';
import { ExchangeModalElectronicPurchases } from './ExchangeModalElectronicPurchases';
import { ExchangeModalElectronicSuccess } from './ExchangeModalElectronicSuccess';

const togglePaperVariants = {
  close: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    width: '100%',
  },
  open: {
    height: 'auto',
    overflow: 'hidden',
    width: '100%',
    opacity: 1,
  },
};

type ExchangeModalElectronicProps = {
  customer: Customer;
  children: ReactNode;
  exchangeableVouchers: Bundle[];
  onSuccess?: () => void;
  targetSupportType: SupportType;
};

type SubmitedFormProps = {
  purchaseId: string;
};

export const ExchangeModalElectronic = ({
  customer,
  onSuccess,
  targetSupportType,
  exchangeableVouchers,
  setConfirmCloseModal,
  children,
}: SwModalRenderProps<ExchangeModalElectronicProps>) => {
  const { t } = useTranslation();
  const { customers } = useApi();
  const dispatchAction = useActionDispatch();

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const [confirmation, setConfirmation] = useState<
    VouchersExchangeCreatedResponseDataData
  >();

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: SubmitedFormProps,
      formikActions: FormikActions<VouchersExchangeCreate>,
    ) => {
      const { purchaseId } = formikData;

      const [error, response] = await httpTo(
        customers.confirmVouchersExchange(customer.id, {
          purchaseId: parseInt(purchaseId, 10),
        }),
      );

      return { error, response, formikData, formikActions };
    },
    [customers, customer],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikActions } = submitedForm;
      setConfirmCloseModal(false);

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (response) {
        const {
          data: { data, actions },
        } = response;
        setConfirmation(data);

        if (actions) {
          dispatchAction({ type: ActionType.ADD, payload: actions });
        }
        sendCustomInteractionToGTM(
          EventCategory.EXCHANGE,
          EventLabel.ELECTRONIC,
          EventLabel.EXCHANGE_CONFIRMATION,
        );
        sentPageViewEvent({
          pathname: `/exchange-modal/electronic/confirmation`,
        });
      }

      if (onSuccess) {
        onSuccess();
      }
    }
  }, [submitedForm]);

  if (confirmation) {
    return <ExchangeModalElectronicSuccess confirmation={confirmation} />;
  }

  return (
    <>
      {children}
      <MotionDiv
        isOpen={targetSupportType === SupportType.PAPER}
        transition={{ ease: 'easeInOut' }}
        variants={togglePaperVariants}
      >
        <SwColumn>
          <p style={{ color: '#333332' }} className="vl-u-text--small">
            <Trans i18nKey={i18nKeys.exchange.remarks.electronic}>
              To exchange your electronic vouchers for paper vouchers please
              fill and send
              <SwLink
                target="_blank"
                modIconAfter
                icon={Icon.EXTERNAL}
                to={t(REGION_EXCHANGE_URL[regionCode])}
              >
                this form
              </SwLink>
              to complete to Sodexo.
            </Trans>
          </p>
        </SwColumn>
      </MotionDiv>
      <Formik
        onSubmit={submit}
        initialValues={{
          purchaseId: '',
        }}
      >
        {({ handleSubmit, dirty, isValid, isSubmitting }) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit} style={{ width: '100%' }}>
              <MotionDiv
                isOpen={targetSupportType === SupportType.ELECTRONIC}
                transition={{ ease: 'easeInOut' }}
                variants={togglePaperVariants}
              >
                <ExchangeModalElectronicPurchases
                  vouchers={exchangeableVouchers}
                />
              </MotionDiv>
              <SwActionGroup>
                <SwButton
                  data-testid={dataTest.wallet.exchange.electronic.submit}
                  type="submit"
                  modLarge
                  modDisabled={
                    !dirty ||
                    !isValid ||
                    targetSupportType === SupportType.PAPER
                  }
                  modLoading={isSubmitting}
                >
                  {t(i18nKeys.general.cta.validate)}
                </SwButton>
              </SwActionGroup>
              <SwFormSubmitMessage />
            </SwForm>
          );
        }}
      </Formik>
    </>
  );
};
