import { Link as ReachLink } from '@reach/router';
import React, { AllHTMLAttributes, createElement } from 'react';
import { SwIcon } from '../icon/SwIcon';
import { useVisuallyHidden } from '../util/hooks/useVisuallyHidden';
import { mergeClassNames } from '../utilities';
import './SwLink.style.scss';

export type LinkProps<T = typeof ReachLink> = AllHTMLAttributes<T> & {
  tagName?: string;
  to?: string;
  testid?: string;
  state?: any;
  href?: string;
  icon?: string;
  hiddenText?: string;
  exact?: boolean;
  modIconBefore?: boolean;
  modIconAfter?: boolean;
  modIconOnly?: boolean;
  modIconLight?: boolean;
  modIconRotatedHalf?: boolean;
  modIconRotatedFull?: boolean;
  modBlock?: boolean;
  modBold?: boolean;
  modNoClass?: boolean;
  modSmall?: boolean;
  modLarge?: boolean;
  modDisabled?: boolean;
};

type Classes = {
  modNoClass?: boolean;
  modBlock?: boolean;
  modBold?: boolean;
  modSmall?: boolean;
  modLarge?: boolean;
  modDisabled?: boolean;
};

const getClasses = ({
  modBold,
  modLarge,
  modSmall,
  modBlock,
  modNoClass,
  modDisabled,
}: Classes) => {
  return [
    !modNoClass ? 'vl-link' : '',
    modBlock ? 'vl-link--block' : '',
    modBold ? 'vl-link--bold' : '',
    modSmall ? 'vl-link--small' : '',
    modLarge ? 'vl-link--large' : '',
    modDisabled ? 'vl-link--disabled' : '',
  ].join(' ');
};

type IconClasses = {
  modIconBefore?: boolean;
  modIconAfter?: boolean;
  modIconLight?: boolean;
};

const getIconClasses = ({
  modIconAfter,
  modIconBefore,
  modIconLight,
}: IconClasses) => {
  return [
    'vl-link__icon',
    modIconBefore ? 'vl-link__icon--before' : '',
    modIconAfter ? 'vl-link__icon--after' : '',
    modIconLight ? 'vl-link__icon--light' : '',
  ].join(' ');
};

type Tag = {
  to?: string;
  defaultTagName: typeof ReachLink;
  tagName?: string | typeof ReachLink;
};
const getTag = ({ tagName, defaultTagName, to }: Tag) => {
  if (tagName !== defaultTagName) {
    return tagName;
  }
  if (to && to.match(/^(http(s)?):\/\/|ftp:|mailto:|tel:/)) {
    return 'a';
  }

  return defaultTagName;
};

type Url = {
  to?: string;
  href?: string;
};
const getUrl = ({ to, href }: Url) => {
  if (to && to.match(/^(http(s)?):\/\/|ftp:|mailto:|tel:/)) {
    return {
      urlHref: href || to,
      urlTo: null,
    };
  }

  return {
    urlHref: href,
    // set '' to avoid that reach-router crash because 'to' props is required and the defaultTagName is reach router
    urlTo: to || '',
  };
};

export const SwLink = <T extends HTMLElement>({
  tagName,
  icon,
  className,
  modBlock,
  modDisabled,
  modIconAfter,
  modIconBefore,
  modLarge,
  modBold,
  modIconLight,
  modIconOnly,
  modIconRotatedFull,
  modIconRotatedHalf,
  modNoClass,
  modSmall,
  exact,
  hiddenText,
  href,
  to,
  children,
  ...rest
}: LinkProps<T>) => {
  const defaultTagName = ReachLink; // TODO react router or reach router

  const TagName = getTag({
    tagName: tagName || defaultTagName,
    defaultTagName,
    to,
  }) as any;

  const visuallyHiddenRef = useVisuallyHidden<HTMLSpanElement>();

  const { urlHref, urlTo } = getUrl({ href, to });

  const classes = getClasses({
    modNoClass,
    modBlock,
    modSmall,
    modLarge,
    modBold,
    modDisabled,
  });

  const iconClasses = getIconClasses({
    modIconLight,
    modIconBefore,
    modIconAfter,
  });

  return createElement(
    TagName,
    {
      className: mergeClassNames(className, classes),
      to: urlTo,
      href: urlHref,
      exact,
      disabled: modDisabled,
      ...rest,
    },
    modIconBefore || modIconOnly ? (
      <SwIcon
        icon={icon}
        className={iconClasses}
        modRotatedHalf={modIconRotatedHalf}
        modRotatedFull={modIconRotatedFull}
      />
    ) : null,
    children,
    modIconOnly ? <span ref={visuallyHiddenRef}>{hiddenText}</span> : null,
    modIconAfter ? (
      <SwIcon
        icon={icon}
        className={iconClasses}
        modRotatedHalf={modIconRotatedHalf}
        modRotatedFull={modIconRotatedFull}
      />
    ) : null,
  );
};
