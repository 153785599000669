import { AvailableLanguages } from '@kaa/common/enums/AvailableLanguages';
import { isIe } from '@kaa/common/utils';
import get from 'lodash.get';
import { getConfig } from '../../common/config';

export const getAppIdHeader = () => ({
  'X-KAA-APP-ID': get(getConfig(), 'buildConfig.id'),
});

export const getLanguageHeader = (language?: AvailableLanguages) => {
  if (!language) {
    return {};
  }
  return {
    'X-KAA-LANGUAGE': language,
  };
};

export const getAuthHeader = (authUser: any) => {
  const accessToken = authUser && authUser.access_token;
  return accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : {};
};

export const getCacheHeader = () =>
  isIe()
    ? {
        'Cache-Control': 'no-store, no-cache, must-revalidate',
        Pragma: 'no-cache',
      }
    : {};
