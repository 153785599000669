import React, {
  AllHTMLAttributes,
  ChangeEvent,
  createElement,
  ReactNode,
  useState,
} from 'react';
import logo from '../../themes/_shared/images/Pluxee_Logo_DarkBlue_RGB.png';
import { SwColumn, SwGrid, SwLayout } from '../core';
import { Icon } from '../icon/SwIcon.constants';
import { SwSearch } from '../search/SwSearch';
import { SwTitle } from '../title/SwTitle';
import { mergeClassNames } from '../utilities';
// @ts-ignore
import { SwLink } from '../link/SwLink';

type FunctionalHeaderProps<T = HTMLHeadingElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  title: string;
  titleLink?: string;
  tabs?: Array<{
    active: boolean;
    name: string;
    external?: string;
    link: string;
  }>;
  modSearch?: boolean;
  searchPlaceholder?: string;
  actions?: ReactNode;
  modBorder?: boolean;
  subActions?: ReactNode;
  onSearch?: (value: string) => void;
};

type Classes = {
  actions?: boolean;
};
const getClasses = ({ actions }: Classes) => {
  return [
    'vl-functional-header',
    actions ? 'vl-functional-header--has-actions' : '',
  ].join(' ');
};

export const SwFunctionalHeader = <T extends HTMLElement>({
  tagName,
  className,
  actions,
  title,
  titleLink,
  modSearch,
  tabs,
  searchPlaceholder,
  onSearch,
  subActions,
  modBorder,
}: FunctionalHeaderProps<T>) => {
  const TagName = tagName || 'header';

  const [searchValue, setSearchValue] = useState<string>('');

  const hasActions = !!actions;
  const classes = getClasses({ actions: modBorder || hasActions });
  const hasTabs = !!tabs;

  const handleSearch = (value: any) => {
    if (onSearch) {
      onSearch(searchValue || value.target.value);
    }
  };

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), role: 'banner' },
    <SwLayout>
      <div className="vl-functional-header__row">
        <div className="vl-functional-header__content">
          <SwTitle className="vl-functional-header__title">
            {titleLink ? (
              <a className="vl-functional-header__title" href={titleLink}>
                {title}
              </a>
            ) : (
              <span className="vl-functional-header__title">{title}</span>
            )}{' '}
            -
            <img
              src={logo}
              alt={title}
              style={{
                marginLeft: '0.5rem',
                height: '2rem',
                width: '6rem',
                verticalAlign: 'bottom',
              }}
              className="vl-u-display-inline-block"
            />
          </SwTitle>
        </div>
        {hasActions ? (
          <div className="vl-functional-header__actions">{actions}</div>
        ) : null}
      </div>
      <div className="vl-functional-header__sub">
        {hasTabs ? (
          <SwGrid>
            <SwColumn tagName="nav" widthL="9" widthM="8" widthS="12">
              <ul
                className="vl-tabs vl-tabs--block"
                data-vl-tabs-list
                role="tablist"
              >
                {(tabs || []).map((tab, key) => {
                  return (
                    <li
                      key={`tab-${key}`}
                      className={`vl-tab ${tab.active ? 'vl-tab--active' : ''}`}
                    >
                      {tab.external ? (
                        <SwLink
                          href={tab.link}
                          className="vl-tab__link"
                          icon={Icon.EXTERNAL}
                          modIconBefore
                        >
                          {tab.name}
                        </SwLink>
                      ) : (
                        <SwLink to={tab.link} className="vl-tab__link">
                          {tab.name}
                        </SwLink>
                      )}
                    </li>
                  );
                })}
              </ul>
            </SwColumn>
            {modSearch ? (
              <SwColumn tagName="nav" widthL="3" widthM="4" widthS="12">
                <SwSearch
                  modInline
                  id="headerSearch"
                  name="headerSearch"
                  className="vl-functional-header__search"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSearchValue(event.target.value)
                  }
                  placeholder={searchPlaceholder}
                  onSubmit={handleSearch}
                />
              </SwColumn>
            ) : null}
          </SwGrid>
        ) : (
          <>{subActions}</>
        )}
      </div>
    </SwLayout>,
  );
};
