import {
  Address,
  City,
  Customer,
  Street,
  SupportType,
} from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AddressFieldNames,
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwCheckboxField,
  SwColumn,
  SwFormAddress,
  SwFormColumn,
  SwFormGrid,
  SwFormMessageLabel,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../../datatest/keys';
import { ExchangeModalPaperFormKeys } from '../../ExchangeModal.constants';

type ExchangeModalPaperDeliveryProps = {
  customer: Customer;
  address: Address;
  supportType: SupportType;
  isSubmitting: boolean;
  availableCities: City[];
  useMainAddress: boolean;
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
};

const toggleDeliveryVariants = {
  close: { height: 0, overflow: 'hidden' },
  open: {
    height: 'auto',
    overflow: 'hidden',
    transitionEnd: {
      overflow: 'inherit',
    },
  },
};

export const ExchangeModalPaperDelivery = ({
  customer,
  address,
  isSubmitting,
  availableCities,
  getStreets,
  useMainAddress,
  supportType = SupportType.ELECTRONIC,
}: ExchangeModalPaperDeliveryProps) => {
  const { t } = useTranslation();

  const { firstName, lastName, phoneNumber, mobilePhoneNumber } = customer;
  const { houseNumber, street, postcode, city } = address;

  return (
    <>
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--xsmall">
        {t(i18nKeys.general.personalDetails)}
      </SwTitle>
      <SwGrid className="vl-u-spacer">
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.general.label.firstName)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {firstName}
        </SwColumn>
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.general.label.name)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {lastName}
        </SwColumn>
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.general.label.phoneNumber)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {phoneNumber}
        </SwColumn>
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.general.label.mobilePhoneNumber)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {mobilePhoneNumber}
        </SwColumn>
      </SwGrid>
      <hr className="vl-u-spacer--medium" />
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--xsmall">
        {t(i18nKeys.general.mainAddress)}
      </SwTitle>
      <SwGrid className="vl-u-spacer">
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.general.label.postcode)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {postcode}
        </SwColumn>
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.general.label.city)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {city}
        </SwColumn>
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.general.label.street)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {street}
        </SwColumn>
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.general.label.number)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {houseNumber}
        </SwColumn>
        {supportType === SupportType.PAPER && (
          <SwColumn>
            <SwFormColumn className="vl-u-spacer">
              <Field
                name={ExchangeModalPaperFormKeys.USE_MAIN_ADDRESS}
                label={t(i18nKeys.general.label.itIsMyDeliveryAddress)}
                component={SwCheckboxField}
                column={{
                  pushS: '0',
                  widthS: '12',
                  push: '4',
                  width: '8',
                }}
              />
            </SwFormColumn>
            <motion.div
              initial="close"
              animate={useMainAddress ? 'close' : 'open'}
              variants={toggleDeliveryVariants}
            >
              <SwTitle
                tagStyle="h5"
                tagName="h3"
                className="vl-u-spacer--xsmall"
              >
                {t(i18nKeys.newOrder.stepDetails.deliveryAddress)}
              </SwTitle>
              <SwFormGrid modStacked style={{ marginTop: 0 }}>
                <SwFormColumn className="vl-u-spacer--xsmall">
                  <SwAlert
                    icon={Icon.ALERT_CIRCLE}
                    title={t(i18nKeys.newOrder.stepDetails.alert.title)}
                    titleTagName="h4"
                    content={t(i18nKeys.newOrder.stepDetails.alert.body)}
                  />
                </SwFormColumn>
                <SwFormAddress
                  fieldNamePrefix={ExchangeModalPaperFormKeys.DELIVERY_ADDRESS}
                  availableCities={availableCities}
                  getStreets={getStreets}
                  modRequired={[
                    AddressFieldNames.POSTCODE,
                    AddressFieldNames.CITY,
                    AddressFieldNames.STREET,
                    AddressFieldNames.HOUSE_NUMBER,
                  ]}
                />
              </SwFormGrid>
            </motion.div>
          </SwColumn>
        )}
      </SwGrid>
      <SwActionGroup>
        <SwButton
          data-testid={dataTest.wallet.exchange.paper.submit}
          type="submit"
          modLarge
          modLoading={isSubmitting}
        >
          {t(i18nKeys.general.next)}
        </SwButton>
      </SwActionGroup>
    </>
  );
};
