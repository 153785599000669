import { ServiceDelivery } from '@kaa/api/customers';
import { Format } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwIcon } from '@kaa/ui-flanders/components';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ACTIVITY_TYPE_ICON } from '../../PrestationsWorksManagementScreen.constants';
import { PrestationsWorkHistoryTableRowStatus } from './PrestationsWorkHistoryTableRowStatus';

type PrestationsWorkHistoryTableRowProps = {
  work: ServiceDelivery;
  testId: string;
  seeDetails: () => void;
};

export const PrestationsWorkHistoryTableRow = ({
  work,
  testId,
  seeDetails,
}: PrestationsWorkHistoryTableRowProps) => {
  const { t } = useTranslation();

  const { activityType, durationInHours, providerName, serviceDate } = work;

  return (
    <tr data-testid={testId}>
      <td data-title={t(i18nKeys.worksManagement.history.table.date)}>
        {serviceDate && DateTime.fromISO(serviceDate).toFormat('dd/LL/yyyy')}
      </td>

      <td data-title={t(i18nKeys.worksManagement.history.table.activity)}>
        <div className="vl-u-display-flex">
          <SwIcon
            icon={ACTIVITY_TYPE_ICON[activityType]}
            style={{ marginRight: '1rem' }}
          />
          {t(i18nKeys.worksManagement.activities[activityType])}
        </div>
      </td>

      <td data-title={t(i18nKeys.worksManagement.history.table.time)}>
        {Format.duration(durationInHours)}
      </td>

      <td data-title={t(i18nKeys.worksManagement.history.table.provider)}>
        {providerName}
      </td>

      <td data-title={t(i18nKeys.worksManagement.history.table.status)}>
        <PrestationsWorkHistoryTableRowStatus
          work={work}
          seeDetails={seeDetails}
        />
      </td>
    </tr>
  );
};
