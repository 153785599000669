import {
  Customer,
  CustomerActionType,
  SupportType,
  VouchersRefundCreate,
} from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { isValidForeignIban } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AlertType,
  SwFetchErrorMessage,
  SwFormGrid,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../utils';
import {
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
  sentPageViewEvent,
} from '../../../../utils/google-analytics';
import { mapServerArrayErrors } from '../../../../utils/mapServerArrayErrors';
import { handleApiError } from '../../../../utils/validation';
import { RefundElectronic } from './components/electronic/RefundElectronic';
import { RefundElectronicConfirmation } from './components/electronic/RefundElectronicConfirmation';
import { RefundPaper } from './components/paper/RefundPaper';
import { RefundPaperForm } from './components/paper/RefundPaper.utils';
import { RefundPaperConfirm } from './components/paper/RefundPaperConfirm';
import { RefundSupportType } from './components/RefundSupportType';
import { RefundFormData } from './RefundModal.types';
import { BankAccountOptions } from './RefundModal.utils';

type RefundModalProps = {
  customer: Customer;
  onSuccess?: () => void;
};

export const RefundModal = ({
  customer,
  onSuccess,
  setConfirmCloseModal,
}: SwModalRenderProps<RefundModalProps>) => {
  const { t } = useTranslation();
  const { customers: customersApi } = useApi();
  const dispatchAction = useActionDispatch();
  const [supportType, setSupportType] = useState(SupportType.ELECTRONIC);

  useEffect(() => {
    sentPageViewEvent({
      pathname: `/refund-modal/${
        supportType === SupportType.ELECTRONIC ? 'electronic' : 'paper'
      }`,
    });
  }, [supportType]);

  const [
    { value: refundData, loading, error },
    getRefundData,
  ] = useAsyncCallback(
    async () => {
      const [
        walletResponse,
        bankAccountsResponse,
        regionResponse,
      ] = await Promise.all([
        customersApi.getCustomerWallet(customer.id),
        customersApi.getCustomerBankAccounts(customer.id),
        customersApi.getGlobalRegionContext(),
      ]);

      const {
        data: { data: wallet, actions },
      } = walletResponse;

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      const {
        data: { data: bankAccounts },
      } = bankAccountsResponse;

      const bankAccountsOptions = Object.entries(bankAccounts).reduce<
        BankAccountOptions
      >((acc, [, bankAccount]) => {
        return [...acc, { value: bankAccount, text: bankAccount.iban }];
      }, []);

      const {
        data: { data: regionData },
      } = regionResponse;

      return {
        wallet: {
          ...wallet,
          voucherBundles: wallet.voucherBundles.filter(
            // filter maternity vouchers
            (voucherBundle) => voucherBundle.refundableQuantity,
          ),
        },
        bankAccounts,
        bankAccountsOptions,
        ...regionData,
      };
    },
    [customer.id, customersApi],
    { loading: true },
  );

  useEffect(() => {
    getRefundData();
  }, [getRefundData]);

  const [
    { value: submitedRefundElectronicData },
    submitRefundElectronic,
  ] = useAsyncCallback(
    async (
      formikData: RefundFormData,
      formikActions: FormikActions<VouchersRefundCreate>,
    ) => {
      const { beneficiaryBankAccount, requests } = formikData;
      const [error, response] = await httpTo(
        customersApi.confirmVouchersRefund(customer.id, {
          beneficiaryBankAccount: {
            iban: beneficiaryBankAccount.iban,
            bic: isValidForeignIban(beneficiaryBankAccount.iban)
              ? beneficiaryBankAccount.bic
              : undefined,
          },
          requests: requests.filter(({ quantity }) => quantity > 0),
        }),
      );

      return { error, response, formikData, formikActions };
    },
    [customersApi, customer.id],
  );

  useEffect(() => {
    if (submitedRefundElectronicData) {
      const { error, formikActions, response } = submitedRefundElectronicData;

      if (handleApiError(error, formikActions, mapServerArrayErrors)) {
        return;
      }

      const { setSubmitting, setStatus } = formikActions;

      if (!response) {
        setSubmitting(false);
        setStatus({
          msg: t(i18nKeys.profile.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      const {
        data: { quantity, amount },
      } = response.data;

      sentPageViewEvent({
        pathname: '/refund-modal/electronic/confirmation',
      });

      sendCustomInteractionToGTM(
        EventCategory.REFUND_REQUEST,
        EventLabel.ELECTRONIC,
        String(quantity),
        amount,
      );

      setConfirmCloseModal(false);

      if (onSuccess) {
        onSuccess();
      }
    }
  }, [submitedRefundElectronicData]);

  const [
    { value: submitedRefundPaperData },
    submitRefundPaper,
  ] = useAsyncCallback(
    async (
      formikData: RefundPaperForm,
      formikActions: FormikActions<RefundPaperForm>,
    ) => {
      const { beneficiaryBankAccount, quantity } = formikData;
      const [error, response] = await httpTo(
        customersApi.createPaperVouchersRefundDocument(customer.id, {
          beneficiaryBankAccount: {
            iban: beneficiaryBankAccount.iban,
            bic: isValidForeignIban(beneficiaryBankAccount.iban)
              ? beneficiaryBankAccount.bic
              : undefined,
          },
          quantity,
        }),
      );

      return { error, response, formikData, formikActions };
    },
    [customersApi, customer.id],
  );

  useEffect(() => {
    if (submitedRefundPaperData) {
      const {
        formikActions,
        formikData,
        error,
        response,
      } = submitedRefundPaperData;

      if (handleApiError(error, formikActions, mapServerArrayErrors)) {
        return;
      }

      const { setSubmitting, setStatus } = formikActions;

      if (!response) {
        setSubmitting(false);
        setStatus({
          msg: t(i18nKeys.profile.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      const { quantity } = formikData;

      sentPageViewEvent({
        pathname: '/refund-modal/paper/confirmation',
      });
      sendCustomInteractionToGTM(
        EventCategory.REFUND_REQUEST,
        EventLabel.PAPER,
        String(quantity),
      );

      setConfirmCloseModal(false);
    }
  }, [submitedRefundPaperData]);

  const canRefundElectronic = useActionsHandler<CustomerActionType>(
    customer.resourceId,
    [CustomerActionType.CAN_ASK_REFUND_ELECTRONIC],
  );

  useEffect(() => {
    if (!canRefundElectronic) {
      setSupportType(SupportType.PAPER);
    }
  }, [canRefundElectronic]);

  if (!refundData && loading) {
    return <SwLoader />;
  }

  if (error || !refundData) {
    return <SwFetchErrorMessage onClick={getRefundData} />;
  }
  const {
    wallet,
    bankAccountsOptions,
    sodexoPostBox,
    refundablePercentage,
  } = refundData;

  if (submitedRefundPaperData && submitedRefundPaperData.response) {
    const blob = new Blob([submitedRefundPaperData.response.data as BlobPart], {
      type: 'application/pdf',
    });

    return (
      <RefundPaperConfirm documentBlob={blob} sodexoPostBox={sodexoPostBox} />
    );
  }

  if (submitedRefundElectronicData?.response?.data) {
    const {
      data: { quantity, amount },
    } = submitedRefundElectronicData.response.data;

    return (
      <RefundElectronicConfirmation
        quantity={quantity}
        amount={amount}
        confirmedBankDetails={
          submitedRefundElectronicData.formikData.beneficiaryBankAccount
        }
      />
    );
  }

  return (
    <>
      <SwTitle tagName="h2">{t(i18nKeys.refund.title)}</SwTitle>

      <SwFormGrid modStacked>
        {supportType === SupportType.ELECTRONIC ? (
          <RefundElectronic
            wallet={wallet}
            bankAccountsOptions={bankAccountsOptions}
            setConfirmCloseModal={setConfirmCloseModal}
            submit={submitRefundElectronic}
          >
            <RefundSupportType
              canRefundElectronic={canRefundElectronic}
              setSupportType={setSupportType}
              supportType={supportType}
            />
          </RefundElectronic>
        ) : (
          <RefundPaper
            bankAccountsOptions={bankAccountsOptions}
            setConfirmCloseModal={setConfirmCloseModal}
            refundablePercentage={refundablePercentage}
            submit={submitRefundPaper}
          >
            <RefundSupportType
              canRefundElectronic={canRefundElectronic}
              setSupportType={setSupportType}
              supportType={supportType}
            />
          </RefundPaper>
        )}
      </SwFormGrid>
    </>
  );
};
