import {
  ContractType,
  Customer,
  CustomerActionType,
  RegionCode,
} from '@kaa/api/customers';
import { useActionsHandler } from '@kaa/common/handlers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwIcon, SwInfoTile, SwLink } from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../common/config';
import { sendCTAEventToGTM } from '../../../utils/google-analytics';
import './BlockedCustomerContractsListCard.style.scss';

type BlockedCustomerContractsListCardProps = {
  customer: Customer;
  current: Customer;
  action: (customerId: number) => void;
};

export const BlockedCustomerContractsListCard = ({
  customer,
  current,
  action,
}: BlockedCustomerContractsListCardProps) => {
  const { t } = useTranslation();

  const isAllowed = useActionsHandler(customer.resourceId, [
    CustomerActionType.CAN_NAVIGATE,
  ]);

  const getSelectedClass = () =>
    customer.id === current.id
      ? 'contractList-card contractList-card--active '
      : 'contractList-card';

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const regionalIconActive =
    regionCode === RegionCode.BE_VLG ? Icon.USER_ACTIF : Icon.USER_CIRCLE_ACTIF;
  const regionalIconDefault =
    regionCode === RegionCode.BE_VLG ? Icon.USER : Icon.USER_CIRCLE;

  const getSelectedIcon = () =>
    customer.id === current.id ? regionalIconActive : regionalIconDefault;

  const customerContracts = [
    ...customer.contracts.filter((e) => e.type !== ContractType.MATERNITY),
    ...customer.contracts.filter((e) => e.type === ContractType.MATERNITY),
  ];

  return (
    <>
      <SwInfoTile
        key={customer.id}
        badge={<SwIcon icon={getSelectedIcon()} />}
        className={getSelectedClass()}
        tagName="div"
        itemProp="Person"
        itemType="https://schema.org/Person"
        title={`${customer.firstName} ${customer.lastName}`}
        style={{
          height: '100%',
        }}
      >
        {customerContracts.map((contract) => (
          <p
            className="vl-u-display-block"
            key={`${customer.id}-${contract.authorizationId}`}
          >
            {contract.type === ContractType.MATERNITY
              ? `${t(i18nKeys.profile.contractList.profileMaternityID)}: ${
                  contract.authorizationId
                }`
              : `${t(i18nKeys.profile.contractList.profileID)}: ${
                  contract.authorizationId
                }`}
          </p>
        ))}
        {action && customer.id !== current.id && isAllowed && (
          <SwLink
            modBlock
            tagName="button"
            type="button"
            onClick={() => {
              action(customer.id);
              sendCTAEventToGTM(
                'Change profile from Blocked page',
                `Goto profile ${customer.id}`,
              );
            }}
          >
            {t(i18nKeys.blocked.contractList.goDashboard)}
          </SwLink>
        )}
      </SwInfoTile>
    </>
  );
};
