import { AllHTMLAttributes, createElement, useState } from 'react';
import { mergeClassNames } from '../utilities';

export type Column = {
  width?: string;
  widthL?: string;
  widthM?: string;
  widthS?: string;
  widthXs?: string;
  push?: string;
  pushL?: string;
  pushM?: string;
  pushS?: string;
  pushXs?: string;
};

export type ColumnProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  cols?: unknown[];
  pushers?: unknown[];
} & Column;

export type Classes = {
  formGridPrefix: string;
} & Column;

export const getClasses = ({
  formGridPrefix,
  push,
  pushL,
  pushM,
  pushS,
  pushXs,
  width,
  widthL,
  widthM,
  widthS,
  widthXs,
}: Classes) => {
  // In Flanders, they use 'this' to access the props, we send them as params
  const widthAndPush: any = {
    push,
    pushL,
    pushM,
    pushS,
    pushXs,
    width,
    widthL,
    widthM,
    widthS,
    widthXs,
  };
  const classArray = [];
  const colCount = 12;
  const sizes = ['', 'l', 'm', 's', 'xs'];
  let hasColumns = false;

  const isNumerical = (value: any) => !Number.isNaN(parseInt(value, 10));

  const getComputedWidth = (widthArray: any) => {
    let gridValue = colCount;
    let nom;
    let denom;

    if (isNumerical(widthArray) && isNumerical(widthArray[1])) {
      [nom, denom] = [...widthArray];
    }

    gridValue = (colCount / denom) * nom;

    return gridValue < colCount ? gridValue : colCount;
  };

  const formatClass = (
    widthClass: any,
    modifier: string | null = null,
    isPush = false,
  ) => {
    let classWidth;
    let calculatedWidth;
    let widthOfColumn: any = colCount;

    const gridType = isPush ? 'push' : 'col';
    const formattedModifier = modifier ? `--${modifier}` : '';

    if (isNumerical(widthClass)) {
      widthOfColumn = widthClass.split(/\//); // Split on /

      calculatedWidth = widthOfColumn[1]
        ? getComputedWidth(widthOfColumn)
        : widthClass;

      classWidth = `vl-${formGridPrefix}${gridType}--${calculatedWidth}-${colCount}${formattedModifier}`;
    } else {
      widthOfColumn = widthClass.split(/-/); // Split on -

      if (!widthOfColumn[1]) {
        switch (widthOfColumn[0]) {
          case 'full':
            calculatedWidth = colCount;
            break;
          case 'half':
            calculatedWidth = colCount / 2;
            break;
          case 'reset':
            calculatedWidth = 0;
            break;
          default:
            calculatedWidth = colCount;
            break;
        }
      } else {
        calculatedWidth = isNumerical(getComputedWidth(widthOfColumn))
          ? getComputedWidth(widthOfColumn)
          : colCount;
      }

      classWidth = `vl-${formGridPrefix}${gridType}--${calculatedWidth}-${colCount}${formattedModifier}`;
    }

    if (gridType === 'push' && parseInt(calculatedWidth, 10) === 0) {
      classWidth = formattedModifier
        ? `vl-${formGridPrefix}push--reset${formattedModifier}`
        : `vl-${formGridPrefix}push--reset`;
    }

    if (classWidth) {
      classArray.push(classWidth);
    }
  };

  // eslint-disable-next-line
  for (const size of sizes) {
    const sizeCapitalized = size.replace(/^\w/, (c) => c.toUpperCase());

    if (widthAndPush[`width${sizeCapitalized}`]) {
      formatClass(widthAndPush[`width${sizeCapitalized}`], size);
      hasColumns = true;
    }

    if (widthAndPush[`push${sizeCapitalized}`]) {
      formatClass(widthAndPush[`push${sizeCapitalized}`], size, true);
      hasColumns = true;
    }

    if (!hasColumns) {
      classArray.push(`vl-${formGridPrefix}col--${colCount}-${colCount}`);
    }
  }

  const removeDuplicate = (classNames: string[]) =>
    classNames.filter((v: string, i: number) => classNames.indexOf(v) === i);
  return removeDuplicate(classArray).join(' ');
};

export const SwColumn = <T extends HTMLElement>({
  tagName,
  className,
  width,
  widthL,
  widthM,
  widthS,
  widthXs,
  push,
  pushL,
  pushM,
  pushS,
  pushXs,
  cols,
  pushers,
  children,
  ...rest
}: ColumnProps<T>) => {
  const TagName = tagName || 'div';
  const [formGridPrefix] = useState('');
  const classes = getClasses({
    formGridPrefix,
    width,
    widthL,
    widthM,
    widthS,
    widthXs,
    push,
    pushL,
    pushM,
    pushS,
    pushXs,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
