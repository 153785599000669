import { RegionCode } from '@kaa/api/customers';
import { email, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwActionGroup,
  SwBadge,
  SwButton,
  SwColumn,
  SwContainer,
  SwForm,
  SwFormColumn,
  SwGrid,
  SwIcon,
  SwInfoTile,
  SwInputTextField,
  SwLink,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import { Field, Formik } from 'formik';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../common/config';
import { PageHeader } from '../../components';
import {
  REGION_CONDITIONS_URL,
  REGION_NAME,
  REGION_NAME_LIVING,
  REGION_PRIVACY_URL,
} from '../../constants';
import { getPath, Routes } from '../../routes';
import {
  EventAction,
  EventLabel,
  sendCTAEventToGTM,
} from '../../utils/google-analytics';
import { createValidatorWithServerErrorHandled } from '../../utils/validation';
import { getRedirectUrlToInitiateEid } from './RegisterScreen.utils';

type RegisterFormState = { state: string };

export const RegisterScreen = ({ navigate }: RouteComponentProps) => {
  const { t } = useTranslation();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const initialValuesForm: RegisterFormState = {
    state: '',
  };
  const validate = createValidatorWithServerErrorHandled({
    state: [required, email],
  });

  const submit = (data: RegisterFormState) => {
    window.location.href = getRedirectUrlToInitiateEid(data.state);
  };

  return (
    <SwContainer>
      <SwGrid modStacked>
        <PageHeader
          title={t(i18nKeys.register.title)}
          introduction={t(i18nKeys.register.step1.introduction)}
          className="push-pageHeader"
        />

        <SwColumn width="10" widthS="12">
          <SwInfoTile
            className="vl-u-bg-alt vl-alert--small"
            tagName="div"
            badge={<SwBadge modWhite modSmall icon={Icon.INFO} />}
            title={t(i18nKeys.register.alert.title)}
            style={{ border: '0 none' }}
          >
            <div
              className="vl-typography vl-alert--small"
              style={{ padding: '0', marginTop: '-2rem' }}
            >
              <ul>
                <li>{t(i18nKeys.register.alert.list.minEighteenYearOld)}</li>
                <li>{t(i18nKeys.register.alert.list.onlyBelgium)}</li>
                <li>
                  {t(REGION_NAME_LIVING[regionCode], {
                    regionName: t(REGION_NAME[regionCode]),
                  })}
                </li>
              </ul>
            </div>
          </SwInfoTile>
        </SwColumn>

        <SwColumn width="9" widthS="12">
          <Formik
            initialValues={initialValuesForm}
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit, values, isValid, setFieldTouched }) => {
              return (
                <SwForm onSubmit={handleSubmit}>
                  <SwGrid>
                    <SwColumn
                      width="6"
                      widthM="12"
                      className="vl-u-spacer--small"
                    >
                      <Field
                        name="state"
                        label={t(i18nKeys.general.label.emailAddress)}
                        labelColumn={{ width: '4', widthM: '12' }}
                        column={{ width: '8', widthM: '12' }}
                        component={SwInputTextField}
                        autoComplete="email"
                        modRequired
                      />
                    </SwColumn>

                    <SwFormColumn className="vl-u-spacer--medium">
                      <p>
                        {t(i18nKeys.register.step1.TAC.text)}{' '}
                        <SwLink
                          onClick={() =>
                            sendCTAEventToGTM(
                              EventLabel.CONDITIONS,
                              t(REGION_CONDITIONS_URL[regionCode]),
                            )
                          }
                          to={t(REGION_CONDITIONS_URL[regionCode])}
                        >
                          {t(i18nKeys.register.step1.TAC.link.label.conditions)}
                        </SwLink>{' '}
                        {t(i18nKeys.general.and)}{' '}
                        <SwLink
                          onClick={() =>
                            sendCTAEventToGTM(
                              EventLabel.PRIVACY,
                              t(REGION_PRIVACY_URL[regionCode]),
                            )
                          }
                          to={t(REGION_PRIVACY_URL[regionCode])}
                        >
                          {t(i18nKeys.register.step1.TAC.link.label.privacy)}
                        </SwLink>{' '}
                        {t(i18nKeys.register.step1.TAC.textEnd)}
                      </p>
                    </SwFormColumn>

                    <SwFormColumn
                      width="12"
                      widthM="10"
                      className="vl-u-display-flex"
                    >
                      <SwIcon
                        icon={Icon.INFO_CIRCLE}
                        className="vl-u-text--action"
                      />
                      <span
                        className="vl-u-text--small"
                        style={{ marginLeft: '1rem' }}
                      >
                        {t(i18nKeys.register.eid.info)}
                      </span>
                    </SwFormColumn>

                    <SwFormColumn>
                      <SwActionGroup modCollapseM>
                        <SwButton
                          onClick={() =>
                            sendCTAEventToGTM(
                              EventLabel.REGISTER_EID,
                              EventAction.SUBMIT,
                            )
                          }
                          type="submit"
                          modLarge
                          data-testid="SwButton-button-register-with-eid"
                        >
                          {t(i18nKeys.register.cta.eid)}
                        </SwButton>
                        <SwButton
                          type="button"
                          data-testid="SwButton-button-register-manually"
                          onClick={() => {
                            if (!navigate || !isValid) {
                              setFieldTouched('state', true);
                              return;
                            }

                            navigate(
                              getPath(Routes.REGISTER_COMPLETE, {
                                queryParams: {
                                  state: values.state,
                                },
                              }),
                            );
                            sendCTAEventToGTM(
                              EventLabel.REGISTER_MANUALLY,
                              EventAction.SUBMIT,
                            );
                          }}
                          modLarge
                          modSecondary
                        >
                          {t(i18nKeys.register.cta.manually)}
                        </SwButton>
                      </SwActionGroup>
                    </SwFormColumn>
                  </SwGrid>
                </SwForm>
              );
            }}
          />
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
