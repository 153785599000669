import React, {
  AllHTMLAttributes,
  cloneElement,
  createElement,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { SwProgressBarStep } from '../progress-bar/components/SwProgressBarStep';
import { SwProgressBar } from '../progress-bar/SwProgressBar';
import { SwTitle } from '../title/SwTitle';
import { Omit } from '../utilities';
import { SwWizardPane } from './components/SwWizardPane';
import './SwWizard.style.scss';

export type SwWizardStep = {
  label: string;
  value: string;
  modDisabled?: boolean;
};

type SwWizardProps = Omit<AllHTMLAttributes<HTMLDivElement>, 'children'> & {
  tagName?: string;
  title?: string;
  subtitle?: string;
  steps: SwWizardStep[];
  children: ReactElement | ReactElement[];
  defaultActive?: number;
  modProgressBar?: boolean;
  onActiveStepChange?: (step: number) => void;
};

export const SwWizard = ({
  tagName,
  title,
  subtitle,
  children,
  steps,
  defaultActive = 0,
  modProgressBar = true,
  onActiveStepChange,
  ...rest
}: SwWizardProps) => {
  const TagName = tagName || 'div';
  const [activeStep, setActiveStep] = useState<number>(defaultActive);

  useEffect(() => {
    if (onActiveStepChange) {
      onActiveStepChange(activeStep);
    }
  }, [activeStep]);

  const nextStep = () =>
    setActiveStep((activeStep) =>
      activeStep + 1 < React.Children.count(children)
        ? activeStep + 1
        : activeStep,
    );
  const previousStep = () =>
    setActiveStep((activeStep) =>
      activeStep - 1 >= 0 ? activeStep - 1 : activeStep,
    );

  return createElement(
    TagName,
    rest,
    <>
      {(title || subtitle) && (
        <header className="vl-wizard__heading" role="none">
          {title && <SwTitle tagName="h2">{title}</SwTitle>}
          {subtitle && <p>{subtitle}</p>}
        </header>
      )}

      {!!modProgressBar && (
        <SwProgressBar modNumeric>
          {steps.map((step: SwWizardStep, index: number) => (
            <SwProgressBarStep
              key={step.value}
              label={step.label}
              modActive={activeStep === index}
              onClick={() => !step.modDisabled && setActiveStep(index)}
              placement="top"
            />
          ))}
        </SwProgressBar>
      )}

      <div className="vl-wizard__panes">
        {React.Children.map(children, (child, index) => {
          const { previous, next, ...childProps } = child.props;
          return cloneElement(child, {
            next: next ? () => next(setActiveStep, activeStep) : nextStep,
            previous: previous
              ? () => previous(setActiveStep, activeStep)
              : previousStep,
            isActive: index === activeStep,
            isFirst: activeStep === 0,
            isLast: activeStep === steps.length - 1,
            modDisabled: steps[index].modDisabled,
            ...childProps,
          });
        })}
      </div>
    </>,
  );
};

SwWizard.SwWizardStep = SwWizardPane;
