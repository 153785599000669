import { CommonRoutes, COMMON_ROUTES } from '@kaa/common/constants';
import { RoutesDefinition } from '@kaa/common/types';
import {
  getPathForRoutes,
  getRouteForRoutes,
  getRouterPathForRoutes,
  pickRouteForRoutes,
} from '@kaa/common/utils';

export type Routes =
  | CommonRoutes
  | 'LOGIN_OCMW'
  | 'ACCOUNT'
  | 'ACCOUNT_PROFILE'
  | 'ACCOUNT_PREFERENCES'
  | 'ACCOUNT_FISCAL_ATTESTATION'
  | 'PRESTATIONS'
  | 'PRESTATIONS_WORKS_MANAGEMENT'
  | 'PRESTATIONS_WORKS_MANAGEMENT_HISTORY'
  | 'PRESTATIONS_PLAN_A_LEAVE'
  | 'SERVICES_VOUCHERS'
  | 'SERVICES_VOUCHERS_WALLET'
  | 'SERVICES_VOUCHERS_ORDER'
  | 'SERVICES_VOUCHERS_ORDER_DETAILS'
  | 'SERVICES_VOUCHERS_ORDER_PENDING'
  | 'FORBIDDEN_WRONG_REGION'
  | 'LANGUAGE_REQUEST'
  | 'HANDIWEB_REQUEST'
  | 'SUPPORT'
  | 'REGISTER'
  | 'REGISTER_COMPLETE'
  | 'REGISTER_SUMMARY'
  | 'BLOCKED'
  | 'PREFERENCES';

export const Routes = {
  ...CommonRoutes,
  LOGIN_OCMW: 'LOGIN_OCMW' as Routes,
  ACCOUNT: 'ACCOUNT' as Routes,
  ACCOUNT_PROFILE: 'ACCOUNT_PROFILE' as Routes,
  ACCOUNT_PREFERENCES: 'ACCOUNT_PREFERENCES' as Routes,
  ACCOUNT_FISCAL_ATTESTATION: 'ACCOUNT_FISCAL_ATTESTATION' as Routes,
  PRESTATIONS: 'PRESTATIONS' as Routes,
  PRESTATIONS_WORKS_MANAGEMENT: 'PRESTATIONS_WORKS_MANAGEMENT' as Routes,
  PRESTATIONS_WORKS_MANAGEMENT_HISTORY: 'PRESTATIONS_WORKS_MANAGEMENT_HISTORY' as Routes,
  PRESTATIONS_PLAN_A_LEAVE: 'PRESTATIONS_PLAN_A_LEAVE' as Routes,
  SERVICES_VOUCHERS: 'SERVICES_VOUCHERS' as Routes,
  SERVICES_VOUCHERS_WALLET: 'SERVICES_VOUCHERS_WALLET' as Routes,
  SERVICES_VOUCHERS_ORDER: 'SERVICES_VOUCHERS_ORDER' as Routes,
  SERVICES_VOUCHERS_ORDER_DETAILS: 'SERVICES_VOUCHERS_ORDER_DETAILS' as Routes,
  SERVICES_VOUCHERS_ORDER_PENDING: 'SERVICES_VOUCHERS_ORDER_PENDING' as Routes,
  FORBIDDEN_WRONG_REGION: 'FORBIDDEN_WRONG_REGION' as Routes,
  LANGUAGE_REQUEST: 'LANGUAGE_REQUEST' as Routes,
  HANDIWEB_REQUEST: 'HANDIWEB_REQUEST' as Routes,
  SUPPORT: 'SUPPORT' as Routes,
  REGISTER: 'REGISTER' as Routes,
  REGISTER_COMPLETE: 'REGISTER_COMPLETE' as Routes,
  REGISTER_SUMMARY: 'REGISTER_SUMMARY' as Routes,
  BLOCKED: 'BLOCKED' as Routes,
  PREFERENCES: 'PREFERENCES' as Routes,
};

export const ROUTES = {
  ...COMMON_ROUTES,
  [Routes.LOGIN_OCMW]: {
    title: Routes.LOGIN_OCMW,
    path: '/auth/login-ocmw',
  },
  [Routes.ACCOUNT]: {
    title: Routes.ACCOUNT,
    path: '/account',
  },
  [Routes.ACCOUNT_PROFILE]: {
    title: Routes.ACCOUNT_PROFILE,
    path: '/profile',
    parent: Routes.ACCOUNT,
  },
  [Routes.ACCOUNT_PREFERENCES]: {
    title: Routes.ACCOUNT_PREFERENCES,
    path: '/preferences',
    parent: Routes.ACCOUNT,
  },
  [Routes.ACCOUNT_FISCAL_ATTESTATION]: {
    title: Routes.ACCOUNT_FISCAL_ATTESTATION,
    path: '/fiscal-attestation',
    parent: Routes.ACCOUNT,
  },
  [Routes.PRESTATIONS]: {
    title: Routes.PRESTATIONS,
    path: '/prestations',
  },
  [Routes.PRESTATIONS_WORKS_MANAGEMENT]: {
    title: Routes.PRESTATIONS_WORKS_MANAGEMENT,
    path: '/works-management',
    parent: Routes.PRESTATIONS,
  },
  [Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY]: {
    title: Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY,
    path: '/works-management/history',
    parent: Routes.PRESTATIONS,
  },
  [Routes.PRESTATIONS_PLAN_A_LEAVE]: {
    title: Routes.PRESTATIONS_PLAN_A_LEAVE,
    path: '/plan-a-leave',
    parent: Routes.PRESTATIONS,
  },
  [Routes.SERVICES_VOUCHERS]: {
    title: Routes.SERVICES_VOUCHERS,
    path: '/services-vouchers',
  },
  [Routes.SERVICES_VOUCHERS_WALLET]: {
    title: Routes.SERVICES_VOUCHERS_WALLET,
    path: '/wallet',
    parent: Routes.SERVICES_VOUCHERS,
  },
  [Routes.SERVICES_VOUCHERS_ORDER]: {
    title: Routes.SERVICES_VOUCHERS_ORDER,
    path: '/order',
    parent: Routes.SERVICES_VOUCHERS,
  },
  [Routes.SERVICES_VOUCHERS_ORDER_DETAILS]: {
    title: Routes.SERVICES_VOUCHERS_ORDER_DETAILS,
    path: '/order/:orderId',
    parent: Routes.SERVICES_VOUCHERS,
  },
  [Routes.SERVICES_VOUCHERS_ORDER_PENDING]: {
    title: Routes.SERVICES_VOUCHERS_ORDER_PENDING,
    path: '/order/pending',
    parent: Routes.SERVICES_VOUCHERS,
  },
  [Routes.FORBIDDEN_WRONG_REGION]: {
    title: Routes.FORBIDDEN_WRONG_REGION,
    path: '/forbidden-wrong-region',
  },
  [Routes.LANGUAGE_REQUEST]: {
    title: Routes.LANGUAGE_REQUEST,
    path: '/language-request',
  },
  [Routes.HANDIWEB_REQUEST]: {
    title: Routes.HANDIWEB_REQUEST,
    path: '/extended-quota-request',
  },
  [Routes.SUPPORT]: {
    title: Routes.SUPPORT,
    path: '/support',
  },
  [Routes.REGISTER]: {
    title: Routes.REGISTER,
    path: '/register',
  },
  [Routes.REGISTER_COMPLETE]: {
    title: Routes.REGISTER_COMPLETE,
    path: '/register/complete',
  },
  [Routes.REGISTER_SUMMARY]: {
    title: Routes.REGISTER_SUMMARY,
    path: '/register/summary',
  },
  [Routes.BLOCKED]: {
    title: Routes.BLOCKED,
    path: '/blocked',
  },
  [Routes.MAINTENANCE]: {
    title: Routes.MAINTENANCE,
    path: '/maintenance',
  },
  [Routes.PREFERENCES]: {
    title: Routes.PREFERENCES,
    path: '/account/preferences',
  },
} as RoutesDefinition<Routes>;

export const getRoute = getRouteForRoutes(ROUTES);
export const getRouterPath = getRouterPathForRoutes(ROUTES);
export const getPath = getPathForRoutes(ROUTES);
export const pickRoute = pickRouteForRoutes(ROUTES);
