import { CustomerLanguages } from '@kaa/api/customers';
import { Mask } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwFormGrid,
  SwInputTextField,
  SwSelectField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterCompleteScreenFieldNames } from '../RegisterCompleteScreen.constants';

type RegisterContactInfoFormProps = {
  availableLanguages: CustomerLanguages[];
};

export const RegisterContactInfoForm = ({
  availableLanguages,
}: RegisterContactInfoFormProps) => {
  const { t } = useTranslation();

  const languageOption = availableLanguages.map((language) => ({
    value: language,
    text: t(i18nKeys.general.language[language]),
  }));

  return (
    <>
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--small">
        {t(i18nKeys.register.formSection.contact.title)}
      </SwTitle>
      <SwFormGrid modStacked>
        <Field
          name={RegisterCompleteScreenFieldNames.PHONE_NUMBER}
          label={t(i18nKeys.general.label.phoneNumber)}
          component={SwInputTextField}
          mask={Mask.phoneNumber}
          type="tel"
          autoComplete="tel"
        />
        <Field
          name={RegisterCompleteScreenFieldNames.MOBILE_PHONE_NUMBER}
          label={t(i18nKeys.general.label.mobilePhoneNumber)}
          component={SwInputTextField}
          mask={Mask.mobilePhoneNumber}
          type="tel"
          autoComplete="tel"
        />

        <Field
          name={RegisterCompleteScreenFieldNames.EMAIL_ADDRESS}
          label={t(i18nKeys.general.label.emailAddress)}
          component={SwInputTextField}
          type="email"
          modDisabled
          modRequired
        />

        <Field
          name={RegisterCompleteScreenFieldNames.LANGUAGE}
          label={t(i18nKeys.profile.communicationLanguage)}
          component={SwSelectField}
          options={languageOption}
          modDisabled={availableLanguages.length <= 1}
          modRequired
        />
      </SwFormGrid>
    </>
  );
};
