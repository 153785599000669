import { AxiosInstance, AxiosResponse } from 'axios';
import { mockedDelay, toAxiosResponse } from '../common';
import { getAppConfigResponse } from './mocks/globalConfig';
import { GlobalConfigurationContextReponse } from './model';

export interface PublicApi {
  getGlobalConfig(
    version?: number,
  ): Promise<AxiosResponse<GlobalConfigurationContextReponse>>;
}

/**
 * It returns an object that has a method that returns a promise that resolves to an AxiosResponse
 * @param {AxiosInstance} axios - AxiosInstance
 * @returns A promise of an AxiosResponse.
 */
export const getPublicApi = (axios: AxiosInstance): PublicApi => {
  return {
    getGlobalConfig(
      version = 5,
    ): Promise<AxiosResponse<GlobalConfigurationContextReponse>> {
      return axios.get(`/v${version}/global-configuration`);
    },
  };
};

/**
 * It returns a mock of the public API
 * @returns A promise that resolves to an AxiosResponse.
 */
export const getPublicMock = (): PublicApi => {
  return {
    getGlobalConfig(
      version = 5,
    ): Promise<AxiosResponse<GlobalConfigurationContextReponse>> {
      return toAxiosResponse(mockedDelay(getAppConfigResponse()));
    },
  };
};
