import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/common/keys';
import Flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { DateTime } from 'luxon';
import React, { Ref, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { Icon } from '../icon/SwIcon.constants';
import { SwInputAddon } from '../input-addon/SwInputAddon';
import { SwInputField } from '../input-field/SwInputField';
import { SwInputGroup } from '../input-group/SwInputGroup';
import { mergeClassNames } from '../utilities';
import './SwDatePicker.style.scss';
import { SwDatePickerProps } from './SwDatePicker.types';
import {
  isDiffDate,
  LocalizationFlatpickr,
  omitNil,
  usePrevious,
} from './SwDatePicker.utils';

export const SwDatePicker = React.forwardRef(
  (
    {
      className,
      format = 'd-m-Y',
      visualFormat = 'd-m-Y',
      value = [],
      minDate,
      maxDate,
      disableDate,
      disabledDates,
      defaultDate,
      defaultTime,
      enableTime,
      excludeDates,
      timeTwentyfour = true,
      modMonthSelect = false,
      minTime,
      maxTime,
      addonText,
      addonTooltip,
      onChange,
      parseDate,
      modError,
      mode,
      onBlur,
      name,
      id,
      placeholder,
      ...props
    }: SwDatePickerProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const classes = 'vl-datepicker';

    const { t, i18n } = useTranslation();

    const inputRef = useRef<any>(null);
    const addonRef = useRef<HTMLElement | null>(null);

    const [flatpickr, setFlatpickr] = useState<Flatpickr.Instance | null>(null);
    const [selectedDates, setSelectedDates] = useState(value || defaultDate);

    const actualInput = flatpickr?.mobileInput || flatpickr?.altInput;

    const [text] = useState<string>(
      addonText || t(i18nKeys.general.datePicker.addonText),
    );
    const [tooltip] = useState<string>(
      addonTooltip || t(i18nKeys.general.datePicker.addonTooltip),
    );

    const openDatepicker = () => {
      if (flatpickr) {
        flatpickr.open();
      }
    };

    useEffect(() => {
      if (!flatpickr && inputRef.current && addonRef.current) {
        // @ts-ignore because Flatpickr is not correctly typed as Class so we should not do a new for typescript
        const flatpickrInstance: Flatpickr.Instance = new Flatpickr(
          inputRef.current,
          {
            // sanitize options if undefined or null (then defaults get used)
            ...omitNil({
              allowInput: true,
              dateFormat: format,
              altFormat: visualFormat,
              altInput: true,
              defaultDate: value || defaultDate,
              locale: LocalizationFlatpickr[i18n.language],
              clickOpens: false,
              enableTime,
              time_24hr: timeTwentyfour,
              minDate,
              maxDate,
              minTime,
              maxTime,
              defaultTime,
              parseDate,
              mode,
              plugins: modMonthSelect
                ? [
                    monthSelectPlugin({
                      shorthand: true,
                      altFormat: visualFormat,
                    }),
                  ]
                : undefined,
              onChange: (changedSelectedDates: Date[]) => {
                setSelectedDates(changedSelectedDates);
              },
              onValueUpdate: (changedSelectedDates: Date[]) => {
                setSelectedDates(changedSelectedDates);
              },
              onClose: () => {
                if (addonRef.current) {
                  addonRef.current.focus();
                }
              },
            }),
            positionElement: addonRef.current,
          },
        );

        setFlatpickr(flatpickrInstance);

        const input =
          flatpickrInstance.mobileInput || flatpickrInstance.altInput;

        if (input) {
          input.classList.add('vl-datepicker__input-field');

          if (name) {
            input.setAttribute('name', name);
          }
          if (id) {
            input.setAttribute('id', id);
          }
          if (placeholder) {
            input.setAttribute('placeholder', placeholder);
          }
          if (props['aria-label']) {
            input.setAttribute('aria-label', props['aria-label']);
          }
        }
      }

      return () => {
        if (flatpickr) {
          flatpickr.destroy();
          setFlatpickr(null);
        }
      };
    }, [flatpickr]);

    useEffect(() => {
      if (flatpickr) {
        flatpickr.set('disable', excludeDates);
      }
    }, [excludeDates]);

    useEffect(() => {
      if (flatpickr) {
        flatpickr.set('disable', disabledDates);
      }
    }, [disabledDates]);

    const previousSelectedDates = usePrevious(selectedDates);
    useEffect(() => {
      if (!previousSelectedDates) {
        return;
      }

      if (
        previousSelectedDates &&
        selectedDates &&
        selectedDates.length === previousSelectedDates.length
      ) {
        const isDifferent = selectedDates.some((date) =>
          previousSelectedDates.some((previousDate) =>
            isDiffDate(date, previousDate),
          ),
        );

        if (isDifferent) {
          onChange(selectedDates);
        }
      } else {
        onChange(selectedDates);
      }
    }, [selectedDates, previousSelectedDates]);

    const previousValue = usePrevious(value);
    useEffect(() => {
      if (flatpickr) {
        if (previousValue && value && previousValue.length === value.length) {
          const isDifferent = value.some((date) =>
            previousValue.some((previousDate) =>
              isDiffDate(date, previousDate),
            ),
          );

          if (isDifferent) {
            flatpickr.setDate(value, false);
          }
        } else {
          flatpickr.setDate(value, false);
        }
      }
    }, [flatpickr, value, previousValue]);

    const handleBlur = useCallback(
      (e: any) => {
        if (!addonRef.current) {
          return;
        }

        if (onBlur) {
          onBlur(e);
        }

        if (flatpickr && actualInput) {
          const fromFormat = flatpickr.mobileInput
            ? DateTime.fromISO(actualInput.value)
            : DateTime.fromFormat(actualInput.value, 'ddMMyyyy');

          if (fromFormat.isValid) {
            flatpickr.setDate(fromFormat.toJSDate(), true);
          } else {
            flatpickr.setDate(actualInput.value, true, visualFormat);
          }
        }
      },
      [actualInput, addonRef.current],
    );

    useEffect(() => {
      if (actualInput) {
        actualInput.addEventListener('blur', handleBlur, true);
      }
      return () => {
        if (actualInput) {
          actualInput.removeEventListener('blur', handleBlur, true);
        }
      };
    }, [actualInput, handleBlur]);

    useEffect(() => {
      if (actualInput) {
        if (modError) {
          actualInput.classList.add('vl-input-field--error');
        } else {
          actualInput.classList.remove('vl-input-field--error');
        }
      }
    }, [actualInput, modError]);

    useEffect(() => {
      if (actualInput && flatpickr) {
        flatpickr.set('noCalendar', !!disableDate);
        if (disableDate) {
          actualInput.setAttribute('disabled', '');
          actualInput.classList.add('vl-input-field--disabled');
        } else {
          actualInput.removeAttribute('disabled');
          actualInput.classList.remove('vl-input-field--disabled');
        }
      }
    }, [actualInput, disableDate]);

    const setDate = useCallback(
      (
        flatpickr: Flatpickr.Instance | null,
        date: string | Date | undefined,
        key: 'minDate' | 'maxDate',
      ) => {
        if (flatpickr && date && isDiffDate(flatpickr.config[key], date)) {
          flatpickr.set(
            key,
            typeof date === 'string'
              ? date
              : DateTime.fromJSDate(date)
                  .startOf('day')
                  .toJSDate(),
          );
        }
      },
      [flatpickr],
    );

    useEffect(() => {
      setDate(flatpickr, maxDate, 'maxDate');
    }, [flatpickr, maxDate]);

    useEffect(() => {
      setDate(flatpickr, minDate, 'minDate');
    }, [flatpickr, minDate]);

    return (
      <SwInputGroup className={mergeClassNames(classes, className)} ref={ref}>
        <SwInputField
          ref={inputRef}
          data-testid={dynamicDataTest(dataTest.SwCalendar, {
            id,
          })}
          modBlock
          modDisabled={disableDate}
        />
        <SwInputAddon
          type="button"
          tagName="button"
          icon={Icon.CALENDAR}
          className={mergeClassNames('vl-datepicker__input-addon', className)}
          disabled={disableDate}
          tooltip={tooltip}
          text={text}
          ref={addonRef}
          onClick={(event) => {
            event.preventDefault();
            openDatepicker();
          }}
        />
      </SwInputGroup>
    );
  },
);
