/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */
import axios, { AxiosPromise, AxiosInstance } from 'axios';
import {
  ActivityType,
  Author,
  ContractType,
  CustomerBankAccountsResponse,
  CustomerBuyableVouchersResponse,
  CustomerCreate,
  CustomerExchangeableVouchersResponse,
  CustomerFiscalAttestationResponse,
  CustomerLanguages,
  CustomerPreferences,
  CustomerPreferencesResponse,
  CustomerResponse,
  CustomerResponseWithMessages,
  CustomerSupportMessageCreate,
  CustomerSupportMessageCreatedResponse,
  CustomerUpdate,
  CustomerVacanciesResponse,
  CustomerVacancy,
  CustomerVacancyDeletedResponse,
  CustomerVacancyEndDateResponse,
  CustomerVacancyResponse,
  CustomerVoucherPurchasesResponse,
  CustomerWalletResponse,
  DashboardResponse,
  FiscalAttestationDocumentParams,
  FiscalAttestationParams,
  GetCustomerVacancyEndDateParams,
  GetCustomerWalletParams,
  GetLocationsSuggestionsParams,
  GetVoucherOrdersHistoryParams,
  GlobalConfigurationContextReponse,
  GlobalRegionContextReponse,
  GlobalUserContextReponse,
  HandiwebRequest,
  KlarnaSessionCreate,
  KlarnaSessionCreateV2,
  KlarnaSessionCreatedResponse,
  KlarnaSessionInitiate,
  KlarnaSessionInitiatedResponse,
  KlarnaVouchersPurchaseCreate,
  KlarnaVouchersPurchaseCreatedResponse,
  LocationsResponse,
  MessageCode,
  MessageType,
  NoContentResponse,
  PaperVouchersExchangeCreate,
  PaperVouchersRefundCreate,
  PaymentMethod,
  ProvinceCode,
  PurchaseStatus,
  RegionCode,
  ServiceContestationRequest,
  ServiceDeliveryDetailsResponse,
  ServiceDeliveryResponse,
  ServiceHistoryResponse,
  ServiceStatus,
  ServicesManagementResponse,
  StreetsResponse,
  StringValueFileCreatedResponse,
  SupportType,
  TitleCode,
  UserEmailUpdate,
  UserPasswordUpdate,
  VoucherOrderResponse,
  VoucherPurchaseResponse,
  VouchersExchangeCreate,
  VouchersExchangeCreatedResponse,
  VouchersOrderCreate,
  VouchersOrderCreatedResponse,
  VouchersRefundCreate,
  VouchersRefundCreatedResponse,
} from './model';

export interface BFFCustomersApi {
  // Check if server is up and running
  liveness(): AxiosPromise<unknown>;
  // Get settings related to the current region.
  getGlobalRegionContext(
    version?: number,
  ): AxiosPromise<GlobalRegionContextReponse>;
  // Get global settings related to the mobile application.
  getGlobalConfiguration(
    version?: number,
  ): AxiosPromise<GlobalConfigurationContextReponse>;
  // Get logged in user related data. Allowed actions, login details, linked customers and their global messages
  getGlobalUserContext(
    version?: number,
  ): AxiosPromise<GlobalUserContextReponse>;
  // Update logged in user email address
  updateUserEmail(
    userEmailUpdate: UserEmailUpdate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Update logged in user password
  updateUserPassword(
    userPasswordUpdate: UserPasswordUpdate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get all locations
  getGlobalLocations(version?: number): AxiosPromise<LocationsResponse>;
  // Search streets by keyword and postcode
  getLocationsSuggestions(
    params?: GetLocationsSuggestionsParams,
    version?: number,
  ): AxiosPromise<StreetsResponse>;
  // Get customer
  getCustomer(
    customerId: number,
    version?: number,
  ): AxiosPromise<CustomerResponse>;
  // Update customer
  updateCustomer(
    customerId: number,
    customerUpdate: CustomerUpdate,
    version?: number,
  ): AxiosPromise<CustomerResponseWithMessages>;
  // Register a new customer
  registerCustomer(
    customerCreate: CustomerCreate,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Switch customer language for people living in town with linguistic facilities. Default change language is done in customer profile
  switchCustomerLanguages(
    customerId: number,
    version?: number,
  ): AxiosPromise<CustomerResponse>;
  // Submit a request for quota 2000 - A User may benefit from a 2000 quota for his own name or for a dependent person, for a total of 2000 service vouchers
  switchCustomerHandiweb(
    customerId: number,
    handiwebRequest: HandiwebRequest,
    version?: number,
  ): AxiosPromise<NoContentResponse>;
  // Get customer preferences
  getCustomerPreferences(
    customerId: number,
    version?: number,
  ): AxiosPromise<CustomerPreferencesResponse>;
  // Update customer preferences
  updateCustomerPreferences(
    customerId: number,
    customerPreferences: CustomerPreferences,
    version?: number,
  ): AxiosPromise<CustomerResponseWithMessages>;
  // Get customer vacancy max end date
  getCustomerVacancyEndDate(
    customerId: number,
    params?: GetCustomerVacancyEndDateParams,
    version?: number,
  ): AxiosPromise<CustomerVacancyEndDateResponse>;
  // Get customer vacancies
  getCustomerVacancies(
    customerId: number,
    version?: number,
  ): AxiosPromise<CustomerVacanciesResponse>;
  // Create customer vacancy
  createCustomerVacancy(
    customerId: number,
    customerVacancy: CustomerVacancy,
    version?: number,
  ): AxiosPromise<CustomerVacancyResponse>;
  // Delete a customer vacancy
  deleteCustomerVacancy(
    customerId: number,
    vacancyId: string,
    version?: number,
  ): AxiosPromise<CustomerVacancyDeletedResponse>;
  // Get customer wallet
  getCustomerWallet(
    customerId: number,
    params?: GetCustomerWalletParams,
    version?: number,
  ): AxiosPromise<CustomerWalletResponse>;
  // Get customer exchangeable vouchers
  getCustomerExchangeableVouchers(
    customerId: number,
    version?: number,
  ): AxiosPromise<CustomerExchangeableVouchersResponse>;
  // Get customer bank accounts
  getCustomerBankAccounts(
    customerId: number,
    version?: number,
  ): AxiosPromise<CustomerBankAccountsResponse>;
  // Get the summary of the vouchers you can order
  getBuyableVouchers(
    customerId: number,
    version?: number,
  ): AxiosPromise<CustomerBuyableVouchersResponse>;
  // Get customer vouchers orders history
  getVoucherOrdersHistory(
    customerId: number,
    params?: GetVoucherOrdersHistoryParams,
    version?: number,
  ): AxiosPromise<CustomerVoucherPurchasesResponse>;
  // Get customer voucher purchase details (actually paid)
  getVoucherPurchase(
    customerId: number,
    purchaseId: number,
    version?: number,
  ): AxiosPromise<VoucherPurchaseResponse>;
  // Submit a new pending order of vouchers. Will be converted to a purchase once paid
  createVouchersOrder(
    customerId: number,
    vouchersOrderCreate: VouchersOrderCreate,
    version?: number,
  ): AxiosPromise<VouchersOrderCreatedResponse>;
  // Get pending customer voucher order (to be paid)
  getPendingOrder(
    customerId: number,
    version?: number,
  ): AxiosPromise<VoucherOrderResponse>;
  // Request a refund for service vouchers
  confirmVouchersRefund(
    customerId: number,
    vouchersRefundCreate: VouchersRefundCreate,
    version?: number,
  ): AxiosPromise<VouchersRefundCreatedResponse>;
  // Request a refund for service vouchers
  createPaperVouchersRefundDocument(
    customerId: number,
    paperVouchersRefundCreate: PaperVouchersRefundCreate,
    version?: number,
  ): AxiosPromise<Blob | StringValueFileCreatedResponse>;
  // Request an exchange for service vouchers
  confirmVouchersExchange(
    customerId: number,
    vouchersExchangeCreate: VouchersExchangeCreate,
    version?: number,
  ): AxiosPromise<VouchersExchangeCreatedResponse>;
  // Request an exchange for paper service vouchers
  confirmPaperVouchersExchange(
    customerId: number,
    paperVouchersExchangeCreate: PaperVouchersExchangeCreate,
    version?: number,
  ): AxiosPromise<Blob | StringValueFileCreatedResponse>;
  // Initiate session with klarna
  initiateSession(
    customerId: number,
    klarnaSessionInitiate: KlarnaSessionInitiate,
    version?: number,
  ): AxiosPromise<KlarnaSessionInitiatedResponse>;
  // Create a new session with klarna
  createSession(
    customerId: number,
    klarnaSessionCreateV2: KlarnaSessionCreateV2,
    version?: number,
  ): AxiosPromise<KlarnaSessionCreatedResponse>;
  // Create a new session with klarna
  createKlarnaSession(
    customerId: number,
    klarnaSessionCreate: KlarnaSessionCreate,
    version?: number,
  ): AxiosPromise<KlarnaSessionCreatedResponse>;
  // Submit a new purchase of vouchers using Klarna
  createKlarnaPurchase(
    customerId: number,
    klarnaVouchersPurchaseCreate: KlarnaVouchersPurchaseCreate,
    version?: number,
  ): AxiosPromise<KlarnaVouchersPurchaseCreatedResponse>;
  // Get services for dashboard
  getServicesForDashboard(
    customerId: number,
    version?: number,
  ): AxiosPromise<ServicesManagementResponse>;
  // Get services history
  getServicesHistory(
    customerId: number,
    version?: number,
  ): AxiosPromise<ServiceHistoryResponse>;
  // Get service detail
  getServiceDetail(
    customerId: number,
    serviceId: number,
    version?: number,
  ): AxiosPromise<ServiceDeliveryDetailsResponse>;
  // Confirm a service
  confirmService(
    customerId: number,
    serviceId: number,
    version?: number,
  ): AxiosPromise<ServiceDeliveryResponse>;
  // Contest a service
  contestService(
    customerId: number,
    serviceId: number,
    serviceContestationRequest: ServiceContestationRequest,
    version?: number,
  ): AxiosPromise<ServiceDeliveryResponse>;
  // Get dashboard information
  getDashboard(
    customerId: number,
    version?: number,
  ): AxiosPromise<DashboardResponse>;
  // Send an email to support
  supportMessage(
    customerId: number,
    customerSupportMessageCreate: CustomerSupportMessageCreate,
    version?: number,
  ): AxiosPromise<CustomerSupportMessageCreatedResponse>;
  // Get fiscal attestation
  fiscalAttestation(
    customerId: number,
    params?: FiscalAttestationParams,
    version?: number,
  ): AxiosPromise<CustomerFiscalAttestationResponse>;
  // Get fiscal attestation document
  fiscalAttestationDocument(
    customerId: number,
    fileId: string,
    params?: FiscalAttestationDocumentParams,
    version?: number,
  ): AxiosPromise<Blob | StringValueFileCreatedResponse>;
  // Get fiscal attestation document for Flanders
  fiscalAttestationDocumentForFlanders(
    niss: number,
    fileId: number,
    language: string,
    version?: number,
  ): AxiosPromise<Blob>;
}

export const getBFFCustomersApi = (axios: AxiosInstance): BFFCustomersApi => ({
  liveness(): AxiosPromise<unknown> {
    return axios.get(`/liveness`);
  },
  getGlobalRegionContext(
    version = 6,
  ): AxiosPromise<GlobalRegionContextReponse> {
    return axios.get(`/v${version}/region`);
  },
  getGlobalConfiguration(
    version = 6,
  ): AxiosPromise<GlobalConfigurationContextReponse> {
    return axios.get(`/v${version}/global-configuration`);
  },
  getGlobalUserContext(version = 6): AxiosPromise<GlobalUserContextReponse> {
    return axios.get(`/v${version}/me`);
  },
  updateUserEmail(
    userEmailUpdate: UserEmailUpdate,
    version = 6,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(`/v${version}/user/email`, userEmailUpdate);
  },
  updateUserPassword(
    userPasswordUpdate: UserPasswordUpdate,
    version = 6,
  ): AxiosPromise<NoContentResponse> {
    return axios.put(`/v${version}/user/password`, userPasswordUpdate);
  },
  getGlobalLocations(version = 6): AxiosPromise<LocationsResponse> {
    return axios.get(`/v${version}/locations`);
  },
  getLocationsSuggestions(
    params?: GetLocationsSuggestionsParams,
    version = 6,
  ): AxiosPromise<StreetsResponse> {
    return axios.get(`/v${version}/locations/streets`, {
      params,
    });
  },
  getCustomer(customerId: number, version = 6): AxiosPromise<CustomerResponse> {
    return axios.get(`/v${version}/customers/${customerId}`);
  },
  updateCustomer(
    customerId: number,
    customerUpdate: CustomerUpdate,
    version = 6,
  ): AxiosPromise<CustomerResponseWithMessages> {
    return axios.put(`/v${version}/customers/${customerId}`, customerUpdate);
  },
  registerCustomer(
    customerCreate: CustomerCreate,
    version = 6,
  ): AxiosPromise<NoContentResponse> {
    return axios.post(`/v${version}/customers`, customerCreate);
  },
  switchCustomerLanguages(
    customerId: number,
    version = 6,
  ): AxiosPromise<CustomerResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/switch-language`,
      undefined,
    );
  },
  switchCustomerHandiweb(
    customerId: number,
    handiwebRequest: HandiwebRequest,
    version = 6,
  ): AxiosPromise<NoContentResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/handiweb-request`,
      handiwebRequest,
    );
  },
  getCustomerPreferences(
    customerId: number,
    version = 6,
  ): AxiosPromise<CustomerPreferencesResponse> {
    return axios.get(`/v${version}/customers/${customerId}/preferences`);
  },
  updateCustomerPreferences(
    customerId: number,
    customerPreferences: CustomerPreferences,
    version = 6,
  ): AxiosPromise<CustomerResponseWithMessages> {
    return axios.put(
      `/v${version}/customers/${customerId}/preferences`,
      customerPreferences,
    );
  },
  getCustomerVacancyEndDate(
    customerId: number,
    params?: GetCustomerVacancyEndDateParams,
    version = 6,
  ): AxiosPromise<CustomerVacancyEndDateResponse> {
    return axios.get(
      `/v${version}/customers/${customerId}/vacancies/end-date-threshold`,
      {
        params,
      },
    );
  },
  getCustomerVacancies(
    customerId: number,
    version = 6,
  ): AxiosPromise<CustomerVacanciesResponse> {
    return axios.get(`/v${version}/customers/${customerId}/vacancies`);
  },
  createCustomerVacancy(
    customerId: number,
    customerVacancy: CustomerVacancy,
    version = 6,
  ): AxiosPromise<CustomerVacancyResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/vacancies`,
      customerVacancy,
    );
  },
  deleteCustomerVacancy(
    customerId: number,
    vacancyId: string,
    version = 6,
  ): AxiosPromise<CustomerVacancyDeletedResponse> {
    return axios.delete(
      `/v${version}/customers/${customerId}/vacancies/${vacancyId}`,
    );
  },
  getCustomerWallet(
    customerId: number,
    params?: GetCustomerWalletParams,
    version = 6,
  ): AxiosPromise<CustomerWalletResponse> {
    return axios.get(`/v${version}/customers/${customerId}/wallet`, {
      params,
    });
  },
  getCustomerExchangeableVouchers(
    customerId: number,
    version = 6,
  ): AxiosPromise<CustomerExchangeableVouchersResponse> {
    return axios.get(
      `/v${version}/customers/${customerId}/vouchers/exchangeable`,
    );
  },
  getCustomerBankAccounts(
    customerId: number,
    version = 6,
  ): AxiosPromise<CustomerBankAccountsResponse> {
    return axios.get(`/v${version}/customers/${customerId}/bank-accounts`);
  },
  getBuyableVouchers(
    customerId: number,
    version = 6,
  ): AxiosPromise<CustomerBuyableVouchersResponse> {
    return axios.get(`/v${version}/customers/${customerId}/buyable-vouchers`);
  },
  getVoucherOrdersHistory(
    customerId: number,
    params?: GetVoucherOrdersHistoryParams,
    version = 6,
  ): AxiosPromise<CustomerVoucherPurchasesResponse> {
    return axios.get(
      `/v${version}/customers/${customerId}/vouchers/orders/history`,
      {
        params,
      },
    );
  },
  getVoucherPurchase(
    customerId: number,
    purchaseId: number,
    version = 6,
  ): AxiosPromise<VoucherPurchaseResponse> {
    return axios.get(
      `/v${version}/customers/${customerId}/vouchers/purchases/${purchaseId}`,
    );
  },
  createVouchersOrder(
    customerId: number,
    vouchersOrderCreate: VouchersOrderCreate,
    version = 6,
  ): AxiosPromise<VouchersOrderCreatedResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/vouchers/orders/bank-transfer`,
      vouchersOrderCreate,
    );
  },
  getPendingOrder(
    customerId: number,
    version = 6,
  ): AxiosPromise<VoucherOrderResponse> {
    return axios.get(
      `/v${version}/customers/${customerId}/vouchers/orders/pending`,
    );
  },
  confirmVouchersRefund(
    customerId: number,
    vouchersRefundCreate: VouchersRefundCreate,
    version = 6,
  ): AxiosPromise<VouchersRefundCreatedResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/vouchers/refunds`,
      vouchersRefundCreate,
    );
  },
  createPaperVouchersRefundDocument(
    customerId: number,
    paperVouchersRefundCreate: PaperVouchersRefundCreate,
    version = 6,
  ): AxiosPromise<Blob | StringValueFileCreatedResponse> {
    type Mutator = (
      url: string,
      data: PaperVouchersRefundCreate,
      config?: object,
    ) => [string, PaperVouchersRefundCreate | undefined, object | undefined];

    const mutator: Mutator = (url, body, config) => [
      url,
      body,
      body?.sendResultInStringFormat
        ? config
        : {
            ...config,
            responseType: 'blob',
          },
    ];

    return axios.post(
      ...mutator(
        `/v${version}/customers/${customerId}/paper-vouchers/refunds`,
        paperVouchersRefundCreate,
      ),
    );
  },
  confirmVouchersExchange(
    customerId: number,
    vouchersExchangeCreate: VouchersExchangeCreate,
    version = 6,
  ): AxiosPromise<VouchersExchangeCreatedResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/vouchers/exchanges`,
      vouchersExchangeCreate,
    );
  },
  confirmPaperVouchersExchange(
    customerId: number,
    paperVouchersExchangeCreate: PaperVouchersExchangeCreate,
    version = 6,
  ): AxiosPromise<Blob | StringValueFileCreatedResponse> {
    type Mutator = (
      url: string,
      data: PaperVouchersExchangeCreate,
      config?: object,
    ) => [string, PaperVouchersExchangeCreate | undefined, object | undefined];

    const mutator: Mutator = (url, body, config) => [
      url,
      body,
      body?.sendResultInStringFormat
        ? config
        : {
            ...config,
            responseType: 'blob',
          },
    ];

    return axios.post(
      ...mutator(
        `/v${version}/customers/${customerId}/paper-vouchers/exchanges`,
        paperVouchersExchangeCreate,
      ),
    );
  },
  initiateSession(
    customerId,
    klarnaSessionInitiate: KlarnaSessionInitiate,
    version = 6,
  ): AxiosPromise<KlarnaSessionInitiatedResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/vouchers/orders/initiate-online-payment`,
      klarnaSessionInitiate,
    );
  },
  createSession(
    customerId,
    klarnaSessionCreateV2: KlarnaSessionCreateV2,
    version = 6,
  ): AxiosPromise<KlarnaSessionCreatedResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/vouchers/orders/create-session`,
      klarnaSessionCreateV2,
    );
  },
  createKlarnaSession(
    customerId: number,
    klarnaSessionCreate: KlarnaSessionCreate,
    version = 6,
  ): AxiosPromise<KlarnaSessionCreatedResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/vouchers/orders/klarna-sessions`,
      klarnaSessionCreate,
    );
  },
  createKlarnaPurchase(
    customerId: number,
    klarnaVouchersPurchaseCreate: KlarnaVouchersPurchaseCreate,
    version = 6,
  ): AxiosPromise<KlarnaVouchersPurchaseCreatedResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/vouchers/orders/klarna`,
      klarnaVouchersPurchaseCreate,
    );
  },
  getServicesForDashboard(
    customerId: number,
    version = 6,
  ): AxiosPromise<ServicesManagementResponse> {
    return axios.get(
      `/v${version}/customers/${customerId}/services/management`,
    );
  },
  getServicesHistory(
    customerId: number,
    version = 6,
  ): AxiosPromise<ServiceHistoryResponse> {
    return axios.get(`/v${version}/customers/${customerId}/services/history`);
  },
  getServiceDetail(
    customerId: number,
    serviceId: number,
    version = 6,
  ): AxiosPromise<ServiceDeliveryDetailsResponse> {
    return axios.get(
      `/v${version}/customers/${customerId}/services/${serviceId}`,
    );
  },
  confirmService(
    customerId: number,
    serviceId: number,
    version = 6,
  ): AxiosPromise<ServiceDeliveryResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/services/${serviceId}/confirm`,
      undefined,
    );
  },
  contestService(
    customerId: number,
    serviceId: number,
    serviceContestationRequest: ServiceContestationRequest,
    version = 6,
  ): AxiosPromise<ServiceDeliveryResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/services/${serviceId}/contest`,
      serviceContestationRequest,
    );
  },
  getDashboard(
    customerId: number,
    version = 6,
  ): AxiosPromise<DashboardResponse> {
    return axios.get(`/v${version}/customers/${customerId}/dashboard`);
  },
  supportMessage(
    customerId: number,
    customerSupportMessageCreate: CustomerSupportMessageCreate,
    version = 6,
  ): AxiosPromise<CustomerSupportMessageCreatedResponse> {
    return axios.post(
      `/v${version}/customers/${customerId}/support-message`,
      customerSupportMessageCreate,
    );
  },
  fiscalAttestation(
    customerId: number,
    params?: FiscalAttestationParams,
    version = 6,
  ): AxiosPromise<CustomerFiscalAttestationResponse> {
    return axios.get(
      `/v${version}/customers/${customerId}/fiscal-attestation`,
      {
        params,
      },
    );
  },
  fiscalAttestationDocument(
    customerId: number,
    fileId: string,
    params?: FiscalAttestationDocumentParams,
    version = 6,
  ): AxiosPromise<Blob | StringValueFileCreatedResponse> {
    type Mutator = (
      url: string,
      config?: object,
    ) => [string, object | undefined];

    const mutator: Mutator = (url, config) => {
      const initConfig = { params: {}, ...config };
      const initParams = {
        sendResultInStringFormat: false,
        ...initConfig.params,
      };

      return [
        url,
        initParams.sendResultInStringFormat
          ? config
          : {
              ...config,
              responseType: 'blob',
            },
      ];
    };

    return axios.get(
      ...mutator(
        `/v${version}/customers/${customerId}/certificates/${fileId}/download`,
        {
          params,
        },
      ),
    );
  },
  fiscalAttestationDocumentForFlanders(
    niss: number,
    fileId: number,
    language: string,
    version = 6,
  ): AxiosPromise<Blob> {
    return axios.get(
      `/v${version}/certificates/${niss}/${fileId}/${language}/download`,
      {
        responseType: 'blob',
      },
    );
  },
});
