import { SupportType } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwFormGrid,
  SwSelectField,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterCompleteScreenFieldNames } from '../RegisterCompleteScreen.constants';

export const RegisterSupportTypeForm = () => {
  const { t } = useTranslation();

  const supportTypeOptions = Object.values(SupportType).map((supportType) => ({
    text: t(i18nKeys.general.supportType[supportType]),
    value: supportType,
  }));

  return (
    <SwFormGrid modStacked>
      <SwTitle tagName="h3" tagStyle="h5">
        {t(i18nKeys.register.formSection.preference.title)}
      </SwTitle>
      <Field
        name={RegisterCompleteScreenFieldNames.PREFERRED_SUPPORT_TYPE}
        label={t(i18nKeys.register.form.supportType.label)}
        component={SwSelectField}
        options={supportTypeOptions}
        modRequired
      />
    </SwFormGrid>
  );
};
