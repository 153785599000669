import { FieldValidationErrorCode } from '@kaa/api/customers';
import { ValueOf } from '@kaa/common/types';
import { ValidationError } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwLink } from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import get from 'lodash.get';
import React from 'react';
import { getConfig } from '../../common/config';
import { SERVICE_VOUCHERS_WEBSITE_LINKS } from '../constants';

export const FORM_ERROR_BY_CODE = ({
  DEFAULT: {
    id: i18nKeys.errors.server.form.DEFAULT,
    defaultMessage: 'Unexpected error',
  },
  [FieldValidationErrorCode.INVALID]: {
    id: i18nKeys.errors.server.form.INVALID,
    defaultMessage: 'Invalid value',
  },
  [FieldValidationErrorCode.INVALID_FORMAT]: {
    id: i18nKeys.errors.server.form.INVALID_FORMAT,
    defaultMessage: 'Invalid format',
  },
  [FieldValidationErrorCode.VALUE_TOO_LONG]: {
    id: i18nKeys.errors.server.form.VALUE_TOO_LONG,
    defaultMessage: 'Value too long',
  },
  [FieldValidationErrorCode.VALUE_TOO_SHORT]: {
    id: i18nKeys.errors.server.form.VALUE_TOO_SHORT,
    defaultMessage: 'Value too short',
  },
  [FieldValidationErrorCode.OUT_OF_ENUM]: {
    id: i18nKeys.errors.server.form.OUT_OF_ENUM,
    defaultMessage: 'Invalid value',
  },
  [FieldValidationErrorCode.MISSING_VALUE]: {
    id: i18nKeys.errors.server.form.MISSING_VALUE,
    defaultMessage: 'Value required',
  },
  [FieldValidationErrorCode.DATE_IN_FUTURE]: {
    id: i18nKeys.errors.server.form.DATE_IN_FUTURE,
    defaultMessage: 'This date cannot be in the future',
  },
  [FieldValidationErrorCode.POSTAL_CODE_IN_WRONG_REGION]: {
    id: (t: TFunction) => (
      <>
        {t(i18nKeys.errors.server.form.wrongRegion)}
        {Object.entries(SERVICE_VOUCHERS_WEBSITE_LINKS)
          .filter(
            ([code]) => code !== get(getConfig(), 'buildConfig.regionCode'),
          )
          .map(([code, url]) => (
            <React.Fragment key={code}>
              <br />
              <SwLink tagName="a" href={t(url)}>
                {t(url)}
              </SwLink>
            </React.Fragment>
          ))}
      </>
    ),
    defaultMessage:
      'Please subscribe yourselves to the service voucher platform corresponding to the region you are domiciled.',
  },
  [FieldValidationErrorCode.NISS_ALREADY_USED]: {
    id: (t: TFunction) => (
      <>
        {t(i18nKeys.errors.server.form.NISS_ALREADY_USED)}
        {Object.entries(SERVICE_VOUCHERS_WEBSITE_LINKS).map(([code, url]) => (
          <React.Fragment key={code}>
            <br />
            <SwLink tagName="a" href={t(url)}>
              {t(url)}
            </SwLink>
          </React.Fragment>
        ))}
      </>
    ),
    defaultMessage: 'Your already subscribed to service voucher',
  },
  [FieldValidationErrorCode.START_DATE_GREATER_THAN_END_DATE]: {
    id: i18nKeys.errors.server.form.START_DATE_GREATER_THAN_END_DATE,
    defaultMessage: 'The end date must be later than the start date',
  },
  [FieldValidationErrorCode.VALUE_ALREADY_EXISTS]: {
    id: i18nKeys.errors.server.form.VALUE_ALREADY_EXISTS,
    defaultMessage: 'This value already exists',
  },
  [FieldValidationErrorCode.DATA_LIMIT_EXCEEDED]: {
    id: i18nKeys.errors.server.form.DATA_LIMIT_EXCEEDED,
    defaultMessage: 'The request exceeds the limit',
  },
  [FieldValidationErrorCode.NISS_MISMATCH]: {
    id: i18nKeys.errors.server.form.NISS_MISMATCH,
    defaultMessage: 'The niss mismatch',
  },
  [FieldValidationErrorCode.MINIMUM_AGE_NOT_REACHED]: {
    id: i18nKeys.errors.server.form.MINIMUMAGENOTREACHED,
    defaultMessage: 'The minimum age not reached',
  },
} as any) as {
  [key in ValueOf<typeof FieldValidationErrorCode>]: ValidationError;
} & { DEFAULT: ValidationError };
