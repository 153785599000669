import { CustomerVacancy, Vacancy } from '@kaa/api/customers';
import { getStarOfTheDayJsDate } from '@kaa/common/utils';
import { ValidationError, ValidatorFunction } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwInputCalendarFieldGetJsDate } from '@kaa/ui-flanders/components';
import { DateTime } from 'luxon';

const getRangeForYear = ({ year = 0 }) => {
  return {
    from: getStarOfTheDayJsDate(
      DateTime.local()
        .plus({ year })
        .startOf('year'),
    ),
    to: getStarOfTheDayJsDate(
      DateTime.local()
        .plus({ year })
        .endOf('year'),
    ),
  };
};

export type ExcludeDates = Array<{ from: Date | string; to: Date | string }>;

export const getExcludeDates = ({
  vacancies = [],
  currentYear = false,
  nextYear = false,
}: {
  vacancies: Vacancy[];
  currentYear?: boolean;
  nextYear?: boolean;
}): ExcludeDates => [
  ...(currentYear ? [getRangeForYear({})] : []),
  ...(nextYear ? [getRangeForYear({ year: 1 })] : []),
  ...vacancies.map((vacancy) => ({
    from: getStarOfTheDayJsDate(DateTime.fromISO(vacancy.startDate)),
    to: getStarOfTheDayJsDate(DateTime.fromISO(vacancy.endDate)),
  })),
];

export const getFirstValidDateFromVacancies = (
  date: DateTime,
  vacancies: Vacancy[],
): DateTime => {
  const vacancy = vacancies.find(
    ({ startDate, endDate }) =>
      DateTime.fromISO(startDate)
        .startOf('day')
        .diff(date, 'day').days <= 0 &&
      DateTime.fromISO(endDate)
        .startOf('day')
        .diff(date, 'day').days >= 0,
  );

  if (vacancy) {
    return getFirstValidDateFromVacancies(
      DateTime.fromISO(vacancy.endDate)
        .startOf('day')
        .plus({ day: 1 }),
      vacancies,
    );
  }

  return date;
};

export const isValidRange = (
  excludeDates: ExcludeDates,
  message?: ValidationError,
): ValidatorFunction => (endDate: string, { startDate }: CustomerVacancy) => {
  if (
    startDate &&
    excludeDates.length &&
    excludeDates.some(
      (e) =>
        e.from > SwInputCalendarFieldGetJsDate(startDate) &&
        e.to < SwInputCalendarFieldGetJsDate(endDate),
    )
  ) {
    return (
      message || {
        defaultMessage: 'Your period covers a period already recorded',
        id: i18nKeys.planALeave.form.error.range,
      }
    );
  }
  return false;
};
