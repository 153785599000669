/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type CustomerLanguages = 'NL' | 'FR' | 'DE';

export const CustomerLanguages = {
  NL: 'NL' as CustomerLanguages,
  FR: 'FR' as CustomerLanguages,
  DE: 'DE' as CustomerLanguages,
};
