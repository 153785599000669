import { i18nKeys } from '@kaa/i18n/common/keys';
import React, { AllHTMLAttributes, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClassNames } from '../utilities';
import './SwSelect.scss';

type SelectProps = AllHTMLAttributes<HTMLSelectElement> & {
  tagName?: string;
  placeholderText?: string;
  modNoPlaceholder?: boolean;
  id?: string;
  modBlock?: boolean;
  modDisabled?: boolean;
  modRequired?: boolean;
  modFocus?: boolean;
  modError?: boolean;
};

type Classes = {
  modBlock?: boolean;
  modError?: boolean;
  modDisabled?: boolean;
};

const getClasses = ({ modBlock, modError, modDisabled }: Classes) => {
  return [
    'vl-select',
    modBlock ? 'vl-select--block' : '',
    modError ? 'vl-select--error' : '',
    modDisabled ? 'vl-select--disabled' : '',
  ].join(' ');
};

export const SwSelect = React.forwardRef(
  (
    {
      tagName,
      className,
      modBlock,
      placeholderText,
      value,
      modNoPlaceholder = false,
      children,
      modDisabled,
      modRequired,
      modFocus,
      modError,
      ...rest
    }: SelectProps,
    ref: Ref<HTMLSelectElement>,
  ) => {
    const { t } = useTranslation();

    const classes = getClasses({
      modBlock,
      modError,
      modDisabled,
    });

    const placeholderLabel =
      placeholderText || t(i18nKeys.general.optionPlaceholder);
    // TODO: handle $attrs and changeListeners
    return (
      <select
        {...rest}
        ref={ref}
        className={mergeClassNames(classes, className)}
        tabIndex={0}
        value={value}
        disabled={modDisabled}
        aria-required={modRequired}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={modFocus}
      >
        {!modNoPlaceholder ? (
          <option value="">{placeholderLabel}</option>
        ) : null}
        {children}
      </select>
    );
  },
);
