import { RegisterFormState } from './RegisterCompleteScreen.types';

export const RegisterCompleteScreenFieldNames: {
  REFUND_BANK_ACCOUNT: keyof RegisterFormState;
  PHONE_NUMBER: keyof RegisterFormState;
  MOBILE_PHONE_NUMBER: keyof RegisterFormState;
  EMAIL_ADDRESS: keyof RegisterFormState;
  LANGUAGE: keyof RegisterFormState;
  FIRSTNAME: keyof RegisterFormState;
  LASTNAME: keyof RegisterFormState;
  BIRTHDATE: keyof RegisterFormState;
  NISS: keyof RegisterFormState;
  MAIN_ADDRESS: keyof RegisterFormState;
  USE_MAIN_ADDRESS_FOR_DELIVERY_ADDRESS: keyof RegisterFormState;
  DELIVERY_ADDRESS: keyof RegisterFormState;
  PREFERRED_SUPPORT_TYPE: keyof RegisterFormState;
  TITLE: keyof RegisterFormState;
} = {
  TITLE: 'title',
  REFUND_BANK_ACCOUNT: 'refundBankAccount',
  PHONE_NUMBER: 'phoneNumber',
  MOBILE_PHONE_NUMBER: 'mobilePhoneNumber',
  EMAIL_ADDRESS: 'emailAddress',
  LANGUAGE: 'language',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  BIRTHDATE: 'birthDate',
  NISS: 'niss',
  MAIN_ADDRESS: 'mainAddress',
  DELIVERY_ADDRESS: 'deliveryAddress',
  USE_MAIN_ADDRESS_FOR_DELIVERY_ADDRESS: 'useMainAddressForDeliveryAddress',
  PREFERRED_SUPPORT_TYPE: 'preferredSupportType',
};
