import React, { AllHTMLAttributes, Ref } from 'react';

type SelectProps = AllHTMLAttributes<HTMLDivElement> & {
  tagName?: string;
  checked?: boolean;
};

export const SwShowOnChecked = React.forwardRef(
  (
    { tagName, className, children, ...rest }: SelectProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <div {...rest} ref={ref}>
        {children}
      </div>
    );
  },
);
