import { DependencyList } from 'react';
import { useUpdateEffect } from './useUpdateEffect';

export const useDebounce = (
  fn: () => any,
  ms = 0,
  deps: DependencyList = [],
) => {
  useUpdateEffect(() => {
    const timeout = setTimeout(fn, ms);

    return () => {
      clearTimeout(timeout);
    };
  }, [...deps, ms]);
};
