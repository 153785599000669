import { CustomerActionType } from '@kaa/api/customers';
import { useAuthState } from '@kaa/auth/common';
import { useActionsHandler } from '@kaa/common/handlers';
import { RouteActive } from '@kaa/common/types';
import { navigate } from '@reach/router';
import { useEffect } from 'react';
import { getPath, Routes } from '../../routes';
import { AuthContext } from '../../types';

type BlockedProps = {
  activeRoute: RouteActive<Routes> | null;
};

export const Blocked = ({ activeRoute }: BlockedProps) => {
  const { selectedCustomerId, customers } = useAuthState<AuthContext>();

  const selectedCustomer = customers?.find(
    ({ id }) => id.toString() === selectedCustomerId,
  );

  const isAllowed = useActionsHandler(selectedCustomer?.resourceId, [
    CustomerActionType.CAN_NAVIGATE,
  ]);

  useEffect(() => {
    if (!isAllowed && activeRoute?.route.id !== Routes.BLOCKED) {
      navigate(getPath(Routes.BLOCKED));
    }
  }, [isAllowed, activeRoute]);

  return null;
};
