import { AxiosInstance, AxiosResponse } from 'axios';
import { mockedDelay, toAxiosResponse } from '../common';
import { getMe } from './mocks/user';
import { GlobalUserContextReponse, UserPasswordUpdate } from './model';
import { UserEmailUpdate } from './model/userEmailUpdate';

export interface UsersApi {
  getMe(version?: number): Promise<AxiosResponse<GlobalUserContextReponse>>;
  putEmail(
    userEmailUpdate: UserEmailUpdate,
    version?: number,
  ): Promise<AxiosResponse<unknown>>;
  putPassword(
    userPasswordUpdate: UserPasswordUpdate,
    version?: number,
  ): Promise<AxiosResponse<unknown>>;
}

/**
 * It returns an object that has methods that correspond to the API endpoints
 * @param {AxiosInstance} axios - AxiosInstance
 * @returns The AxiosResponse object.
 */
export const getUsersApi = (axios: AxiosInstance): UsersApi => {
  return {
    async getMe(version = 5): Promise<AxiosResponse<GlobalUserContextReponse>> {
      return axios.get(`/v${version}/me`);
    },
    async putEmail(
      userEmailUpdate: UserEmailUpdate,
      version = 5,
    ): Promise<AxiosResponse<unknown>> {
      return axios.put(`/v${version}/user/email`, userEmailUpdate);
    },
    async putPassword(
      userPasswordUpdate: UserPasswordUpdate,
      version = 5,
    ): Promise<AxiosResponse<unknown>> {
      return axios.put(`/v${version}/user/password`, userPasswordUpdate);
    },
  };
};

/**
 * It returns a mock of the UsersApi interface
 * @returns A mock of the UsersApi interface.
 */
export const getUsersMock = (): UsersApi => {
  return {
    getMe(version = 5): Promise<AxiosResponse<GlobalUserContextReponse>> {
      return toAxiosResponse(Promise.resolve(getMe()));
    },
    putEmail(
      userEmailUpdate: UserEmailUpdate,
      version?: number,
    ): Promise<AxiosResponse<unknown>> {
      return toAxiosResponse(mockedDelay());
    },
    putPassword(
      userPasswordUpdate: UserPasswordUpdate,
      version?: number,
    ): Promise<AxiosResponse<unknown>> {
      return toAxiosResponse(mockedDelay());
    },
  };
};
