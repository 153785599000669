import cuid from 'cuid';
import React, {
  createContext,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from 'react';

export enum AlertAction {
  ADD = 'add',
  DELETE = 'delete',
  CLEAR = 'clear',
}

enum AlertType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFORMATION = 'INFORMATION',
}

export type Alert<T> = T & {
  id?: string;
  type: AlertType;
  message: string;
  persistent?: boolean;
};

type Action<T> = { type: AlertAction; payload?: Alert<T> | string };
export type AlertDispatch<T> = (action: Action<T>) => void;
type State<T> = Array<Alert<T>>;

const initialState: Array<Alert<any>> = [];

type AlertProviderProps = { children: ReactNode };

const AlertStateContext = createContext<State<any> | null>(null);
const AlertDispatchContext = createContext<AlertDispatch<any> | null>(null);

const createAlert = <T extends {}>(alert: Alert<T>) => ({
  id: cuid(),
  persistent: false,
  ...alert,
});

function alertReducer<T>(state: State<T>, action: Action<T>): State<T> {
  const { type, payload } = action;
  switch (type) {
    case AlertAction.ADD: {
      return [
        ...state,
        ...(Array.isArray(payload)
          ? (payload as Array<Alert<T>>).map(createAlert)
          : [createAlert(payload as Alert<T>)]),
      ];
    }
    case AlertAction.DELETE: {
      return state.filter(
        (message: Alert<T>) => message.id !== (payload as string),
      );
    }
    case AlertAction.CLEAR: {
      return state.filter((alert) => alert.persistent);
    }
    default: {
      throw new Error(`Unhandled action: ${action}`);
    }
  }
}

export const AlertProvider = <T extends {}>({
  children,
}: AlertProviderProps) => {
  const [state, alertDispatch] = useReducer<Reducer<State<T>, Action<T>>>(
    alertReducer,
    initialState,
  );
  return (
    <AlertStateContext.Provider value={state}>
      <AlertDispatchContext.Provider value={alertDispatch}>
        {children}
      </AlertDispatchContext.Provider>
    </AlertStateContext.Provider>
  );
};

export const useAlertState = <T extends {}>(): State<T> => {
  const context = useContext<State<T> | null>(AlertStateContext);
  if (context === null) {
    throw new Error('useAlertState must be used within a AlertProvider');
  }
  return context;
};

export const useAlertDispatch = <T extends {}>(): AlertDispatch<T> => {
  const context = useContext<AlertDispatch<T> | null>(AlertDispatchContext);

  if (context === null) {
    throw new Error('useAlertDispatch must be used within a AlertProvider');
  }
  return context;
};
