import React, { ReactNode, AllHTMLAttributes, createElement } from 'react';

import { mergeClassNames, Omit } from '../utilities';
import { SwIcon } from '../icon/SwIcon';
import { Icon } from '../icon/SwIcon.constants';
import { SwLink } from '../link/SwLink';
import { dataTest } from '../../datatest/keys';

type DocumentProps<T = HTMLAnchorElement> = Omit<
  AllHTMLAttributes<T>,
  'title'
> & {
  tagName?: string;
  icon?: string;
  metadata?: string;
  external?: boolean;
  externalLink?: string;
  textClampTitle?: number;
  mimeType: string;
  title: string | ReactNode;
};

const getClasses = () => {
  return 'vl-document';
};

export const SwDocument = <T extends HTMLElement>({
  tagName,
  children,
  title,
  icon = Icon.DOCUMENT,
  metadata,
  external,
  externalLink,
  textClampTitle = 2,
  mimeType,
  className,
  ...rest
}: DocumentProps<T>) => {
  const TagName = tagName || 'a';

  const classes = getClasses();
  const target = external ? '_blank' : null;

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), target, ...rest },
    <>
      <div className="vl-document__type">
        <SwIcon icon={icon} />
        <span className="vl-document__type__text">{mimeType}</span>
      </div>
      <div className="vl-document__content">
        {external && (
          <SwLink
            data-testid={dataTest.SwDocumentExternal}
            className="vl-document__title"
            tagName="div"
            icon={Icon.EXTERNAL}
            modIconAfter
            modIconLight
            v-vl-text-clamp={textClampTitle}
            to={externalLink}
          >
            {title}
            <span className="vl-u-visually-hidden"> ({externalLink})</span>
          </SwLink>
        )}
        {!external && (
          <SwLink
            data-testid={dataTest.SwDocument}
            className="vl-document__title"
            tagName="div"
            v-vl-text-clamp={textClampTitle}
          >
            {title}
          </SwLink>
        )}
        {metadata && <div className="vl-document__metadata">{metadata}</div>}
      </div>
    </>,
  );
};
