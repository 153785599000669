import React, { AllHTMLAttributes, createElement } from 'react';
import { SwIcon } from '../icon/SwIcon';
import { mergeClassNames } from '../utilities';

type IconListItemProps = AllHTMLAttributes<HTMLDivElement> & {
  tagName?: string;
  modLight?: boolean;
  modLarge?: boolean;
  icon: string;
};

const getClasses = () => {
  return ['vl-icon-list__item'].join(' ');
};

export const SwIconListItem = <T extends HTMLElement>({
  tagName,
  modLight,
  modLarge,
  icon,
  children,
  className,
  ...rest
}: IconListItemProps) => {
  const TagName = tagName || 'li';

  const classes = getClasses();
  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    <>
      <SwIcon modLarge={modLarge} icon={icon} />
      {modLight ? (
        <span className="vl-annotation">{children}</span>
      ) : (
        <>{children}</>
      )}
    </>,
  );
};
