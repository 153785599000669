/*eslint-disable */
import difference from 'lodash.difference';
import isEqual from 'lodash.isequal';
import isObject from 'lodash.isobject';
import transform from 'lodash.transform';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */

export const differenceDeep = (
  object: { [key: string]: any },
  base: { [key: string]: any },
) =>
  transform(
    object,
    (
      result: { [key: string]: any },
      value: unknown[] | null | undefined,
      key: string,
    ) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? difference(value, base[key])
            : value;
      }
    },
  );
