/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type CustomerActionType =
  | 'CAN_NAVIGATE'
  | 'CAN_EDIT_FIRSTNAME'
  | 'CAN_EDIT_LASTNAME'
  | 'CAN_EDIT_NISS'
  | 'CAN_EDIT_BIRTHDATE'
  | 'CAN_EDIT_ADDRESS_POSTCODE'
  | 'CAN_EDIT_ADDRESS_HOUSE_NUMBER'
  | 'CAN_EDIT_ADDRESS_STREET'
  | 'CAN_ORDER'
  | 'CAN_ORDER_QUOTA'
  | 'CAN_EDIT'
  | 'CAN_ASK_EXCHANGE'
  | 'CAN_ASK_EXCHANGE_ELECTRONIC'
  | 'CAN_ASK_REFUND_ELECTRONIC'
  | 'CAN_ASK_REFUND'
  | 'CAN_PAY_WITH_KLARNA'
  | 'CAN_REQUEST_LANGUAGE_SWITCH'
  | 'CAN_SWITCH_LANGUAGE'
  | 'CAN_CHANGE_LANGUAGE_PROFILE';

export const CustomerActionType = {
  CAN_NAVIGATE: 'CAN_NAVIGATE' as CustomerActionType,
  CAN_EDIT_FIRSTNAME: 'CAN_EDIT_FIRSTNAME' as CustomerActionType,
  CAN_EDIT_LASTNAME: 'CAN_EDIT_LASTNAME' as CustomerActionType,
  CAN_EDIT_NISS: 'CAN_EDIT_NISS' as CustomerActionType,
  CAN_EDIT_BIRTHDATE: 'CAN_EDIT_BIRTHDATE' as CustomerActionType,
  CAN_EDIT_ADDRESS_POSTCODE: 'CAN_EDIT_ADDRESS_POSTCODE' as CustomerActionType,
  CAN_EDIT_ADDRESS_HOUSE_NUMBER: 'CAN_EDIT_ADDRESS_HOUSE_NUMBER' as CustomerActionType,
  CAN_EDIT_ADDRESS_STREET: 'CAN_EDIT_ADDRESS_STREET' as CustomerActionType,
  CAN_ORDER: 'CAN_ORDER' as CustomerActionType,
  CAN_ORDER_QUOTA: 'CAN_ORDER_QUOTA' as CustomerActionType,
  CAN_EDIT: 'CAN_EDIT' as CustomerActionType,
  CAN_ASK_EXCHANGE: 'CAN_ASK_EXCHANGE' as CustomerActionType,
  CAN_ASK_EXCHANGE_ELECTRONIC: 'CAN_ASK_EXCHANGE_ELECTRONIC' as CustomerActionType,
  CAN_ASK_REFUND_ELECTRONIC: 'CAN_ASK_REFUND_ELECTRONIC' as CustomerActionType,
  CAN_ASK_REFUND: 'CAN_ASK_REFUND' as CustomerActionType,
  CAN_PAY_WITH_KLARNA: 'CAN_PAY_WITH_KLARNA' as CustomerActionType,
  CAN_REQUEST_LANGUAGE_SWITCH: 'CAN_REQUEST_LANGUAGE_SWITCH' as CustomerActionType,
  CAN_SWITCH_LANGUAGE: 'CAN_SWITCH_LANGUAGE' as CustomerActionType,
  CAN_CHANGE_LANGUAGE_PROFILE: 'CAN_CHANGE_LANGUAGE_PROFILE' as CustomerActionType,
};
