import { RegionCode } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwIconList,
  SwIconListItem,
  SwTitle,
} from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../../../common/config';
import { REGION_NAME } from '../../../../../../constants';
import { dataTest } from '../../../../../../datatest/keys';

type ExchangeModalPaperSuccessProps = {
  documentBlob: Blob;
  sodexoPostBox: string;
};

export const ExchangeModalPaperSuccess = ({
  documentBlob,
  sodexoPostBox,
}: ExchangeModalPaperSuccessProps) => {
  const { t } = useTranslation();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const document = window.URL.createObjectURL(documentBlob);
  const isIE = !!window.navigator.msSaveOrOpenBlob;

  return (
    <>
      <p className="vl-u-display-block vl-u-spacer--medium">
        {t(i18nKeys.exchange.paper.confirmation.introduction)}
      </p>

      <SwTitle tagStyle="h4" tagName="h3" className="vl-u-spacer">
        {t(i18nKeys.exchange.paper.confirmation.whatHappensNext)}
      </SwTitle>

      <SwIconList>
        <SwIconListItem
          className="vl-u-hr"
          style={{ paddingBottom: '1rem' }}
          icon={Icon.FILE_DOWNLOAD}
          modLarge
        >
          <strong>1.</strong> {t(i18nKeys.exchange.paper.howTo.download)}
        </SwIconListItem>
        <SwIconListItem
          className="vl-u-hr vl-typography"
          style={{ paddingBottom: '1rem' }}
          icon={Icon.PRINT}
          modLarge
        >
          <strong>2.</strong> {t(i18nKeys.exchange.paper.howTo.print)}
        </SwIconListItem>
        <SwIconListItem
          className="vl-u-hr"
          style={{ paddingBottom: '1rem' }}
          icon={Icon.SCISSORS}
          modLarge
        >
          <strong>3.</strong> {t(i18nKeys.exchange.paper.howTo.cut)}
        </SwIconListItem>
        <SwIconListItem
          style={{ paddingBottom: '1rem' }}
          icon={regionCode === 'BE_VLG' ? Icon.ENVELOPE : Icon.SEND_DOCUMENT}
          modLarge
        >
          <strong>4.</strong> {t(i18nKeys.exchange.paper.howTo.send)}
          <strong>
            <br />
            {t(i18nKeys.exchange.paper.howTo.address.name)}
            <br />
            {t(i18nKeys.exchange.paper.howTo.address.street, {
              postalBox: sodexoPostBox,
            })}
            <br />
            {t(i18nKeys.exchange.paper.howTo.address.city)}
          </strong>
        </SwIconListItem>
      </SwIconList>

      <SwColumn>
        <SwAlert
          icon={Icon.INFO_CIRCLE}
          title={t(i18nKeys.exchange.paper.confirmation.infoTile.title)}
          titleTagName="h3"
        >
          {t(i18nKeys.exchange.paper.confirmation.infoTile.message)}
        </SwAlert>
      </SwColumn>
      <SwActionGroup>
        {!isIE ? (
          <SwButton
            tagName="a"
            href={document}
            data-testid={dataTest.wallet.exchange.paper.download}
            download={`${t(i18nKeys.exchange.paper.confirmation.fileName, {
              regionName: t(REGION_NAME[regionCode]),
            })}.pdf`}
            modBlock
            modLarge
          >
            {t(i18nKeys.exchange.paper.confirmation.cta.getMyDocument)}
          </SwButton>
        ) : (
          <SwButton
            data-testid={dataTest.wallet.exchange.paper.download}
            modBlock
            modLarge
            onClick={(e) => {
              e.preventDefault();
              window.navigator.msSaveOrOpenBlob(
                documentBlob,
                `${t(i18nKeys.exchange.paper.confirmation.fileName, {
                  regionName: t(REGION_NAME[regionCode]),
                })}.pdf`,
              );
            }}
          >
            {t(i18nKeys.exchange.paper.confirmation.cta.getMyDocument)}
          </SwButton>
        )}
      </SwActionGroup>
    </>
  );
};
