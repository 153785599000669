import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type SwProgressBarProps<T = HTMLDivElement> = AllHTMLAttributes<T> &
  Classes & {
    tagName?: string;
  };

type Classes = {
  modNumeric?: boolean;
};

const getClasses = ({ modNumeric }: Classes) => {
  return ['vl-progress-bar', modNumeric ? 'vl-progress-bar--numeric' : ''].join(
    ' ',
  );
};

export const SwProgressBar = <T extends HTMLElement>({
  tagName,
  modNumeric,
  children,
  className,
  ...rest
}: SwProgressBarProps<T>) => {
  const TagName = tagName || 'div';

  const classes = getClasses({ modNumeric });

  return createElement(
    TagName,
    { ...rest, className: mergeClassNames(classes, className) },
    children,
  );
};
