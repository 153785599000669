import { Route, RouteActive, RouteOptions, RoutesDefinition } from '../types';

/* eslint-disable */
/* tslint:disable */
// //////////////////////////////////////////////////////////////////////////////
// pick(routes, uri)
//
// Ranks and picks the best route to match. Each segment gets the highest
// amount of points, then the type of segment gets an additional amount of
// points where
//
//     static > dynamic > splat > root
//
// This way we don't have to worry about the order of our routes, let the
// computers do it.
//
// A route looks like this
//
//     { path, default, value }
//
// And a returned match looks like:
//
//     { route, params, uri }
//
// I know, I should use TypeScript not comments for these types.
const pick = <T extends { path: string }>(routes: Array<T>, uri: string) => {
  let match;

  const [uriPathname] = uri.split('?');
  const uriSegments = segmentize(uriPathname);
  const isRootUri = uriSegments[0] === '';
  const ranked = rankRoutes(routes);

  for (let i = 0, l = ranked.length; i < l; i++) {
    let missed = false;
    const { route } = ranked[i];

    const routeSegments = segmentize(route.path);
    const params = {};
    const max = Math.max(uriSegments.length, routeSegments.length);
    let index = 0;

    for (; index < max; index++) {
      const routeSegment = routeSegments[index];
      const uriSegment = uriSegments[index];

      const isSplat = routeSegment === '*';
      if (isSplat) {
        // Hit a splat, just grab the rest, and return a match
        // uri:   /files/documents/work
        // route: /files/*
        // @ts-ignore
        params['*'] = uriSegments
          .slice(index)
          .map(decodeURIComponent)
          .join('/');
        break;
      }

      if (uriSegment === undefined) {
        // URI is shorter than the route, no match
        // uri:   /users
        // route: /users/:userId
        missed = true;
        break;
      }

      const dynamicMatch = paramRe.exec(routeSegment);

      if (dynamicMatch && !isRootUri) {
        const matchIsNotReserved =
          reservedNames.indexOf(dynamicMatch[1]) === -1;
        if (!matchIsNotReserved) {
          const error = `<Router> dynamic segment "${dynamicMatch[1]}" is a reserved name. Please use a different name in path "${route.path}".`;
          throw error;
        }
        const value = decodeURIComponent(uriSegment);
        // @ts-ignore
        params[dynamicMatch[1]] = value;
      } else if (routeSegment !== uriSegment) {
        // Current segments don't match, not dynamic, not splat, so no match
        // uri:   /users/123/settings
        // route: /users/:id/profile
        missed = true;
        break;
      }
    }

    if (!missed) {
      match = {
        route,
        params,
        uri: `/${uriSegments.slice(0, index).join('/')}`,
      };
      break;
    }
  }

  return match || null;
};

// //////////////////////////////////////////////////////////////////////////////
// match(path, uri) - Matches just one path to a uri, also lol
const match = (path: string, uri: string) => pick([{ path }], uri);

// //////////////////////////////////////////////////////////////////////////////
// Junk
const paramRe = /^:(.+)/;

const SEGMENT_POINTS = 4;
const STATIC_POINTS = 3;
const DYNAMIC_POINTS = 2;
const SPLAT_PENALTY = 1;
const ROOT_POINTS = 1;

const isRootSegment = (segment: string) => segment === '';
const isDynamic = (segment: string) => paramRe.test(segment);
const isSplat = (segment: string) => segment === '*';

const rankRoute = (route: { path: string }, index: number) => {
  const score = segmentize(route.path).reduce(
    (score: number, segment: string) => {
      score += SEGMENT_POINTS;
      if (isRootSegment(segment)) score += ROOT_POINTS;
      else if (isDynamic(segment)) score += DYNAMIC_POINTS;
      else if (isSplat(segment)) score -= SEGMENT_POINTS + SPLAT_PENALTY;
      else score += STATIC_POINTS;
      return score;
    },
    0,
  );
  return { route, score, index };
};

const rankRoutes = (routes: Array<{ path: string }>) =>
  routes
    .map(rankRoute)
    .sort((a, b) =>
      a.score < b.score ? 1 : a.score > b.score ? -1 : a.index - b.index,
    );

const segmentize = (uri: string) =>
  uri
    // strip starting/ending slashes
    .replace(/(^\/+|\/+$)/g, '')
    .split('/');

const reservedNames = ['uri', 'path'];

let addQueryParams = (
  pathname: string,
  queryParams: { [key: string]: any } = {},
) =>
  pathname +
  Object.entries(queryParams).reduce(
    (acc, [key, value], i) =>
      acc + (i === 0 ? `?${key}=${value}` : `&${key}=${value}`),
    '',
  );

const getQueryParam = (name: string): string | undefined => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

// //////////////////////////////////////////////////////////////////////////////
export { pick, match, addQueryParams, getQueryParam };

export const getRouteParams = (
  path: string,
  params: {
    [index: string]: string;
  } = {},
): {} =>
  (path.match(/:\w+/g) || [])
    .map((key) => key.replace(':', ''))
    .reduce(
      (acc, key) =>
        params[key]
          ? { ...acc, [key]: params[key] }
          : { ...acc, [key]: undefined },
      {},
    );

export const getRoutePath = (
  path: string,
  params: {
    [index: string]: string;
  } = {},
): string =>
  Object.keys(params).reduce(
    (acc, key) => (params[key] ? acc.replace(`:${key}`, params[key]) : acc),
    path,
  );

export const getRouteForRoutes = <T extends string | symbol>(
  ROUTES: RoutesDefinition<T>,
) => (target: T, options: RouteOptions = {}): Route<T> => {
  const { path: routePath, parent: routeParent, ...rest } = ROUTES[target];

  const params = getRouteParams(routePath, options.params);
  const path = getRoutePath(routePath, params);

  if (routeParent) {
    const parent = getRouteForRoutes(ROUTES)(routeParent as T, options.params);
    return {
      id: target,
      path: addQueryParams(`${parent.path}${path}`, options.queryParams),
      parent,
      params,
      ...rest,
    };
  }

  return {
    id: target,
    path: addQueryParams(path, options.queryParams),
    params,
    ...rest,
  };
};

export const getRouterPathForRoutes = <T extends string | symbol>(
  ROUTES: RoutesDefinition<T>,
) => (target: T): string => ROUTES[target].path;

export const getPathForRoutes = <T extends string | symbol>(
  ROUTES: RoutesDefinition<T>,
) => (target: T, options: RouteOptions = {}): string =>
  getRouteForRoutes(ROUTES)(target, options).path;

export const getAllRoutesForRoutes = <T extends string | symbol>(
  ROUTES: RoutesDefinition<T>,
) => Object.keys(ROUTES).map((route) => getRouteForRoutes(ROUTES)(route as T));

export const pickRouteForRoutes = <T extends string | symbol>(
  ROUTES: RoutesDefinition<T>,
) => (uri: string) =>
  pick(getAllRoutesForRoutes(ROUTES), uri) as RouteActive<T> | null;
