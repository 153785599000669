import { ServiceDelivery } from '@kaa/api/customers';
import { SwBadge } from '@kaa/ui-flanders/components';
import React from 'react';
import { getStatusSwBadgeProps } from './PrestationsWorkHistoryTable.utils';
import { PrestationsWorkHistoryTableRowStatusContent } from './PrestationsWorkHistoryTableRowStatusContent';
import { PrestationsWorkHistoryTableRowStatusContentDetails } from './PrestationsWorkHistoryTableRowStatusContentDetails';

type PrestationsWorkHistoryTableRowStatusProps = {
  work: ServiceDelivery;
  seeDetails: () => void;
};

export const PrestationsWorkHistoryTableRowStatus = ({
  work,
  seeDetails,
}: PrestationsWorkHistoryTableRowStatusProps) => {
  const { status } = work;

  return (
    <>
      <div className="vl-u-display-flex">
        <SwBadge {...getStatusSwBadgeProps(status)} />
        <div style={{ marginLeft: '1rem' }}>
          <PrestationsWorkHistoryTableRowStatusContent work={work} />
          <PrestationsWorkHistoryTableRowStatusContentDetails
            seeDetails={seeDetails}
            work={work}
          />
        </div>
      </div>
    </>
  );
};
