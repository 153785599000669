import { DateTime } from 'luxon';

export const getStarOfTheDayJsDate = (date: Date | DateTime): Date => {
  if (date instanceof DateTime) {
    return date.startOf('day').toJSDate();
  }
  return DateTime.fromJSDate(date)
    .startOf('day')
    .toJSDate();
};
