import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/common/keys';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import React, { Ref, useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { Icon } from '../icon/SwIcon.constants';
import { SwInputAddon } from '../input-addon/SwInputAddon';
import { SwInputField } from '../input-field/SwInputField';
import { SwInputGroup } from '../input-group/SwInputGroup';
import { mergeClassNames } from '../utilities';
import './SwDatePicker.style.scss';
import { SwDatePickerProps } from './SwDatePicker.types';
import {
  isDiffDate,
  LocalizationFlatpickr,
  omitNil,
  usePrevious,
} from './SwDatePicker.utils';

export const SwDatePicker2 = React.forwardRef(
  (
    {
      id,
      className,
      disableDate,
      addonText,
      addonTooltip,
      value = [],
      defaultDate,
      name,
      placeholder,
      format,
      visualFormat,
      timeTwentyfour,
      modMonthSelect = false,
      modError = false,
      onChange,
      onBlur,
      ...props
    }: SwDatePickerProps,
    containerRef: Ref<HTMLDivElement>,
  ) => {
    const { t, i18n } = useTranslation();

    const addonRef = useRef<HTMLElement | null>(null);

    const [fp, setFp] = useState<flatpickr.Instance | null>(null);

    const options = omitNil({
      positionElement: addonRef.current,
      allowInput: true,
      dateFormat: format,
      altFormat: visualFormat,
      altInput: true,
      defaultDate: value || defaultDate,
      locale: LocalizationFlatpickr[i18n.language],
      clickOpens: false,
      time_24hr: timeTwentyfour,
      plugins: modMonthSelect
        ? [
            monthSelectPlugin({
              shorthand: true,
              altFormat: visualFormat,
            }),
          ]
        : undefined,
      ...props,
    });

    const [selectedDates, setSelectedDates] = useState(value || defaultDate);
    const previousSelectedDates = usePrevious(selectedDates);
    const previousValue = usePrevious(value);

    useEffect(() => {
      if (!previousSelectedDates) {
        return;
      }

      if (
        previousSelectedDates &&
        selectedDates &&
        selectedDates.length === previousSelectedDates.length
      ) {
        const isDifferent = selectedDates.some((date) =>
          previousSelectedDates.some((previousDate) =>
            isDiffDate(date, previousDate),
          ),
        );

        if (isDifferent) {
          onChange(selectedDates);
        }
      } else {
        onChange(selectedDates);
      }
    }, [selectedDates, previousSelectedDates]);

    useEffect(() => {
      if (fp) {
        if (previousValue && value && previousValue.length === value.length) {
          const isDifferent = value.some((date) =>
            previousValue.some((previousDate) =>
              isDiffDate(date, previousDate),
            ),
          );

          if (isDifferent) {
            fp.setDate(value, false);
          }
        } else {
          fp.setDate(value, false);
        }
      }
    }, [fp, value, previousValue]);

    return (
      <Flatpickr
        options={options}
        onReady={(dates, currentDateString, fpInstance) => {
          setFp(fpInstance);
        }}
        onClose={() => {
          if (addonRef?.current) {
            addonRef.current.focus();
          }
        }}
        onValueUpdate={(dates) => {
          setSelectedDates(dates);
        }}
        render={(renderProps, inputRef) => (
          <SwInputGroup
            ref={containerRef}
            className={mergeClassNames('vl-datepicker', className)}
          >
            <SwInputField
              ref={inputRef}
              id={id}
              name={name}
              placeholder={placeholder}
              modDisabled={disableDate}
              modError={modError}
              modBlock
              className="vl-datepicker__input-field"
              aria-label={props['aria-label']}
              onBlur={onBlur}
              data-testid={dynamicDataTest(dataTest.SwCalendar, {
                id,
              })}
            />
            <SwInputAddon
              ref={addonRef}
              disabled={disableDate}
              icon={Icon.CALENDAR}
              text={addonText || t(i18nKeys.general.datePicker.addonText)}
              type="button"
              tagName="button"
              className={mergeClassNames(
                'vl-datepicker__input-addon',
                className,
              )}
              tooltip={
                addonTooltip || t(i18nKeys.general.datePicker.addonTooltip)
              }
              onClick={(event) => {
                event.preventDefault();
                if (fp) {
                  fp.open();
                }
              }}
            />
          </SwInputGroup>
        )}
      />
    );
  },
);
