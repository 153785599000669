import { AvailableLanguages, LokaliseKeys } from '@kaa/common/enums';
import axios from 'axios';

export interface AppConfig {
  app: App;
  [key: string]: any;
}

export interface App {
  google: {
    tagManagerId: string;
  };
  applicationInsights: {
    instrumentationKey: string;
  };
  mode: string;
  environment: string;
  apiUrl: string;
  auth: Auth;
}

export interface Auth {
  oidc: Oidc;
  definition: Definition;
}

export interface BaseOidc {
  acr_values: string;
  redirect_uri: string;
  silent_redirect_uri: string;
  post_logout_redirect_uri: string;
}

export interface Oidc extends BaseOidc {
  authority: string;
  client_id: string;
  client_secret: string;
  automaticSilentRenew: boolean;
  loadUserInfo: boolean;
  response_type: string;
  scope: string;
}

export interface Definition {
  header: string;
  footer: string;
}

export interface BuildConfig<T> {
  id: string;
  regionCode: T;
  lokaliseKey: LokaliseKeys;
  buildId: string;
  isDevelopment: boolean;
  origin: string;
  loginUrl: string;
  unauthorizedRedirectUrl: string;
  logoutUrl: string;
  errorPagePath: string;
  forgotPasswordUrl: string;
  i18n: I18N;
  oidc: BaseOidc;
}

export interface BuildConfigIdp<T> {
  regionCode: T;
  lokaliseKey: LokaliseKeys;
  origin: string;
  i18n: I18N;
}

export interface I18N {
  availableLanguages: AvailableLanguages[];
  defaultLanguage: AvailableLanguages;
  fallbackLanguage: AvailableLanguages;
}

class ConfigService<T> {
  config!: (AppConfig & { buildConfig: BuildConfig<T> }) | null;

  fetchConfig(
    buildConfig: BuildConfig<T> | BuildConfigIdp<T>,
    fileName = '/__KAA_CONFIG__.json',
  ) {
    return axios
      .get(fileName)
      .then((response) => {
        if (!response) {
          throw new Error('Fetch kaa config : no response ');
        }
        if (response && !response.data) {
          throw new Error('Fetch kaa config : no data in the response ');
        }

        if (Object.keys(response.data).length === 0) {
          throw new Error('Fetch kaa config : no data in the response ');
        }

        this.config = Object.freeze({
          ...response.data,
          // Merge dynamically static oidc url
          ...((buildConfig as BuildConfig<T>).oidc
            ? {
                app: {
                  ...response.data.app,
                  auth: {
                    ...response.data.app.auth,
                    oidc: {
                      ...response.data.app.auth.oidc,
                      ...(buildConfig as BuildConfig<T>).oidc,
                    },
                  },
                },
              }
            : {}),
          ...(buildConfig ? { buildConfig } : {}),
        });
        return this.config;
      })
      .catch((err) => {
        this.config = null;
        throw err;
      });
  }
}

export const configService = new ConfigService();

export const getConfig = () => configService.config;
