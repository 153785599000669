/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type TitleCode = 'MR' | 'MRS';

export const TitleCode = {
  MR: 'MR' as TitleCode,
  MRS: 'MRS' as TitleCode,
};
