import faker from 'faker';
import {
  ActivityType,
  Author,
  Service,
  ServiceConfirmedResponse,
  ServiceContestationRequest,
  ServiceDelivery,
  ServiceDeliveryComment,
  ServiceDeliveryDetails,
  ServiceDeliveryDetailsResponse,
  ServiceDeliveryResponse,
  ServiceHistoryResponse,
  ServicesManagementResponse,
  ServicesManagementResponseDataData,
  ServiceStatus,
} from '../model';

export const getServicesForDashboardResponse = (
  servicesManagementResponse: Partial<ServicesManagementResponse> = {},
): ServicesManagementResponse => ({
  data: getServiceManagement(servicesManagementResponse.data),
});

export const getServicesHistoryResponse = ({
  data = [...Array(faker.random.number({ min: 10, max: 60 }))],
}: Partial<ServiceHistoryResponse> = {}): ServiceHistoryResponse => ({
  data: data
    .map(() => ({
      status: faker.helpers.randomize([
        ServiceStatus.ARCHIVED_PAID,
        ServiceStatus.ARCHIVED_CANCELLED,
        ServiceStatus.ARCHIVED_OUTSIDE_SV,
        ServiceStatus.ARCHIVED_PARTIALLY_PAID,
      ]),
    }))
    .map(getService),
});

export const getServiceDetailResponse = (
  pendingServiceDetailsResponse: Partial<ServiceDeliveryDetailsResponse> = {},
): ServiceDeliveryDetailsResponse => ({
  data: getServiceDeliveryDetails(pendingServiceDetailsResponse.data),
});

export const getServiceDeliveryDetails = ({
  comments = [...Array(faker.random.number(10))],
  ...rest
}: Partial<ServiceDeliveryDetails> = {}): ServiceDeliveryDetails => {
  return {
    comments: comments.map(getServiceDeliveryComment),
    ...rest,
    ...getServiceDelivery(rest),
  };
};

export const getServiceDeliveryComment = (
  serviceDeliveryComment: Partial<ServiceDeliveryComment> = {},
): ServiceDeliveryComment => {
  return {
    author: faker.helpers.randomize(Object.values(Author)),
    text: faker.lorem.sentence(),
    publicationDate: faker.date.past().toISOString(),
    ...serviceDeliveryComment,
  };
};

export const getServiceManagement = ({
  pending = [...Array(faker.random.number(10))],
  recent = [...Array(faker.random.number(10))],
}: Partial<
  ServicesManagementResponseDataData
> = {}): ServicesManagementResponseDataData => {
  return {
    pending: pending.map(getServiceDelivery),
    recent: recent
      .map(getServiceDelivery)
      .filter(
        (service) =>
          service.status !== ServiceStatus.ACTIVE_AWAITING_VALIDATION,
      ),
  };
};

export const getServiceDelivery = (
  serviceDelivery: Partial<ServiceDelivery> = {},
): ServiceDelivery => {
  const service = getService(serviceDelivery);
  const isModified = faker.random.boolean();
  return {
    neverHappened: faker.random.boolean(),
    previousActivityType: isModified
      ? faker.helpers.randomize(Object.values(ActivityType))
      : undefined,
    previousDurationInHours: isModified
      ? faker.random.number({ min: 1, max: 9 })
      : undefined,
    automaticValidationRemainingDays: faker.random.number({ min: 1, max: 9 }),
    automaticValidationTotalDays: faker.random.number({ min: 1, max: 9 }),
    modificationDate: isModified ? faker.date.past().toISOString() : undefined,
    vouchersAttributed: faker.random.number({
      min: 1,
      max: service.durationInHours - 1,
    }),
    ...serviceDelivery,
    ...service,
  };
};

export const getService = (service: Partial<Service> = {}): Service => {
  return {
    id: faker.random.number(9999999999999999),
    providerName: faker.company.companyName(),
    serviceDate: faker.date.past().toISOString(),
    activityType: faker.helpers.randomize(Object.values(ActivityType)),
    durationInHours: faker.random.number({ min: 1, max: 9 }),
    status: faker.helpers.randomize(Object.values(ServiceStatus)),
    voucherAttributionDate: faker.date.past().toISOString(),
    ...service,
  };
};

export const getServiceDeliveryResponse = (
  serviceId: string | number,
  serviceContestationRequest?: ServiceContestationRequest,
): ServiceDeliveryResponse => ({
  data: getServiceDelivery({
    id: Number(serviceId),
    ...serviceContestationRequest,
  }),
});

export const confirmServiceResponse = (): ServiceConfirmedResponse => {
  return {
    data: {
      numberOfMissingVouchers: faker.random.number(1),
    },
  };
};
