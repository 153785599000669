import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { CodeError } from './model/codeError';
import { HttpError } from './model/httpError';

export const createApiError = (
  code: CodeError = CodeError.INTERNALSERVERERROR,
  message = '',
  correlationId = '',
): HttpError => {
  return {
    code,
    message,
    correlationId,
  };
};

export const getHTTPInstance = (
  baseUrl: string | undefined,
  headers: { [key: string]: string | undefined },
): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseUrl,
  });

  instance.defaults.headers = headers;

  instance.interceptors.response.use(
    (response: AxiosResponse<any>): AxiosResponse<any> => response,
    (error: AxiosError): Promise<unknown> | void => {
      const { response } = error;

      if (!response) {
        return Promise.reject(createApiError());
      }

      // response.data.error is returned by login call so we hijack
      if (response.data.error) {
        return Promise.reject(response.data);
      }

      if (!response.data.errors) {
        return Promise.reject(createApiError());
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export type HttpResponse<T> = [HttpError | null, T | undefined];
/**
 * Handling Promise error for axios
 * @param promise
 */
export function httpTo<T>(promise: Promise<T>): Promise<HttpResponse<T>> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[HttpError, undefined]>((axiosError: AxiosError) => {
      if (!axiosError.response) {
        return [createApiError(), undefined];
      }

      return [axiosError.response.data as HttpError, undefined];
    });
}
