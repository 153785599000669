import { SwModal } from '@kaa/ui-flanders/components';
import React from 'react';
import { Modals } from '../../constants';
import { Routes } from '../../routes';
import {
  sendModalInteractionToGTM,
  sentPageViewEvent,
} from '../../utils/google-analytics';
import { OnboardingModal } from './OnboardingModal';
import { getOnboarding } from './OnboardingModal.utils';

type OnboardingProps = {
  activeRoute: Routes;
};

export const Onboarding = ({ activeRoute }: OnboardingProps) => {
  const { modalViews, modalContent } = getOnboarding(activeRoute);
  const MAX_VIEWS = 1;

  if (!modalContent) {
    return null;
  }

  if (modalViews < MAX_VIEWS) {
    sendModalInteractionToGTM(
      `OnboardingModal ${modalContent[0].modalName} step1`,
      'Open Modal',
    );
  }

  return (
    <SwModal
      open={modalViews < MAX_VIEWS}
      id={Modals.ONBOARDING_MODAL_ID}
      modalId={Modals.ONBOARDING_MODAL_ID}
      slides={modalContent}
      closable
      onClose={() => {
        sendModalInteractionToGTM(
          `OnboardingModal ${modalContent[0].modalName}`,
          'Close Modal',
        );
        sentPageViewEvent();
      }}
      component={OnboardingModal}
    />
  );
};
