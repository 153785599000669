import { getStarOfTheDayJsDate } from '@kaa/common/utils';
import { DateTime } from 'luxon';

export const SwInputCalendarFieldGetJsDate = (date: string) =>
  getStarOfTheDayJsDate(DateTime.fromISO(date));

export const SwInputCalendarFieldFormatDate = (date: string) =>
  date ? [SwInputCalendarFieldGetJsDate(date)] : [];

export const SwInputCalendarFieldParseDate = (dates: Date[]) =>
  dates && dates.length
    ? DateTime.fromJSDate(getStarOfTheDayJsDate(dates[0])).toISODate()
    : '';
