import { Bundle, ContractType } from '@kaa/api/customers';
import { Format, toCurrencyFormat } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwDataTable, SwRadioField } from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ExchangeModalElectronicPurchasesProps = {
  vouchers: Bundle[];
};

export const ExchangeModalElectronicPurchases = ({
  vouchers,
}: ExchangeModalElectronicPurchasesProps) => {
  const { t } = useTranslation();

  return (
    <>
      <fieldset className="vl-u-spacer--small">
        <legend className="vl-form__label vl-u-spacer--xsmall">
          {t(i18nKeys.exchange.label.selectOrder)}
        </legend>
        <div className="vl-u-table-overflow">
          <SwDataTable modCollapsedS>
            <thead>
              <tr>
                <th style={{ paddingLeft: '3.2rem' }}>
                  {t(i18nKeys.exchange.table.vouchersQuantity)}
                </th>
                <th>{t(i18nKeys.exchange.table.date)}</th>
                <th>{t(i18nKeys.exchange.table.total)}</th>
              </tr>
            </thead>
            <tbody>
              {vouchers.map((voucher, i) => {
                const {
                  id,
                  refundableQuantity,
                  expirationDate,
                  faceValue,
                  contractType,
                } = voucher;
                return (
                  <tr key={id}>
                    <td data-title={t(i18nKeys.exchange.table.total)}>
                      <Field
                        name="purchaseId"
                        component={SwRadioField}
                        id={String(id)}
                        label={
                          contractType === ContractType.MATERNITY
                            ? t(i18nKeys.wallet.voucherCount.maternity, {
                                count: refundableQuantity,
                              })
                            : t(i18nKeys.wallet.voucherCount.standard, {
                                count: refundableQuantity,
                              })
                        }
                      />
                    </td>
                    <td data-title={t(i18nKeys.exchange.table.date)}>
                      {Format.date(expirationDate)}
                    </td>
                    <td
                      data-title={t(i18nKeys.exchange.table.vouchersQuantity)}
                    >
                      {toCurrencyFormat(refundableQuantity * faceValue)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </SwDataTable>
        </div>
      </fieldset>
    </>
  );
};
