import { SupportType } from '@kaa/api/customers';
import { CustomerPreferencesForm } from '@kaa/api/customers/model/CustomerPreferences.type';
import { httpTo } from '@kaa/api/customers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
    AlertType,
    SwActionGroup,
    SwButton,
    SwColumn,
    SwContainer,
    SwFetchErrorMessage,
    SwForm,
    SwFormSubmitMessage,
    SwGrid,
    SwRadioField,
    SwTitle,
} from '@kaa/ui-flanders/components';
import {
    isValidForeignIban,
    setError,
    ValidationError,
} from '@kaa/common/validation';

import { Field, Formik, FormikActions, FormikProps } from 'formik';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLoadingPageHeader, PageHeader } from '../../../components';
import { dataTest } from '../../../datatest/keys';
import {
    useApi, useSelectedCustomerState, useUserState,
    useDispatchUpdateCustomer,
    useDispatchUpdateMessages,
} from '../../../utils';
import { sendCTAEventToGTM } from '../../../utils/google-analytics';
import { handleApiError } from '../../../utils/validation';
import { PreferencesScreenFieldNames } from './PreferencesScreen.constants';
import { ProfileCustomerEmailInfoForm } from '../profile/components/ProfileCustomerEmailInfoForm';
import { createValidateEmail } from '../profile/ProfileScreen.utils';

export const PreferencesScreen = () => {
    const { t } = useTranslation();
    const { customers } = useApi();
    const customer = useSelectedCustomerState();
    const user = useUserState();
    const dispatchUpdateCustomer = useDispatchUpdateCustomer();
    const dispatchUpdateMessages = useDispatchUpdateMessages();
    const [
        { value: customerPreferences, loading, error },
        getCustomerPreferences,
    ] = useAsyncCallback(
        async () => (await customers.getCustomerPreferences(customer.id)).data.data,
        [customers],
        { loading: true },
    );

    useEffect(() => {
        getCustomerPreferences();
    }, [getCustomerPreferences]);

    const [{ value: submitedForm }, submit] = useAsyncCallback(
        async (
            formikData: CustomerPreferencesForm,
            formikActions: FormikActions<CustomerPreferencesForm>,
        ) => {
            const [error, response] = await httpTo(
                customers.updateCustomerPreferences(customer.id, formikData),
            );

            return { error, response, formikData, formikActions };
        },
    );

    useEffect(() => {
        if (submitedForm) {
            const { error, formikActions, formikData, response } = submitedForm;
            const { resetForm, setStatus, setSubmitting } = formikActions;

            if (handleApiError(error, formikActions)) {
                return;
            }
            if (!response) {
                setSubmitting(false);
                setStatus({
                    msg: t(i18nKeys.profile.errorMessage),
                    type: AlertType.ERROR,
                });
                return;
            }
            const {
                data: { data, actions, messages },
            } = response;

            dispatchUpdateCustomer(data);
            if (messages) {
                dispatchUpdateMessages(messages);
            }
            resetForm(formikData);
            if (formikData.supportTypePreference === SupportType.ELECTRONIC)
                setStatus({
                    msg: t(i18nKeys.preference.success.electronic),
                    type: AlertType.SUCCESS,
                });
            else
                setStatus({
                    msg: t(i18nKeys.preference.success.paper),
                    type: AlertType.SUCCESS,
                });
            setSubmitting(false);
        }
    }, [submitedForm]);
    const validate = useMemo(() => createValidateEmail(customer), [
        customer
    ]);

    if (loading) {
        return (
            <ContainerLoadingPageHeader
                title={t(i18nKeys.navigation.preferences)}
                introduction={t(i18nKeys.preference.introduction)}
            />
        );
    }

    if (error || !customerPreferences) {
        return (
            <SwContainer error>
                <SwFetchErrorMessage onClick={getCustomerPreferences} />
            </SwContainer>
        );
    }

    return (
        <SwContainer>
            <SwGrid modStacked>
                <SwColumn width="10" widthS="12">
                    <PageHeader
                        title={t(i18nKeys.navigation.preferences)}
                        introduction={t(i18nKeys.preference.introduction)}
                        className="push-pageHeader"
                    />
                </SwColumn>
                <SwColumn width="10" widthS="12">
                    <Formik
                        initialValues={{ ...customerPreferences, emailAddress: customer.emailAddress, confirmEmailAddress: '' }}
                        validate={(values) => values.supportTypePreference === SupportType.ELECTRONIC ? validate(values) : undefined}
                        onSubmit={submit}
                    >
                        {({
                            values,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting,
                            dirty,
                            errors,
                        }: FormikProps<CustomerPreferencesForm>) => (
                            <SwForm onSubmit={handleSubmit}>
                                <SwGrid modStacked className="vl-u-flex-v-flex-start">
                                    <SwColumn className="vl-u-spacer--small">
                                        <SwGrid modVTop className="vl-u-flex-v-flex-start">
                                            <SwColumn className="vl-u-spacer--small">
                                                <SwTitle
                                                    tagName="h2"
                                                    tagStyle="h5"
                                                    className="vl-u-spacer--small"
                                                >
                                                    {t(i18nKeys.preference.voucherManagement)}
                                                </SwTitle>

                                                <fieldset className="vl-u-spacer--xsmall">
                                                    <legend className="vl-form__label vl-u-spacer--xsmall">
                                                        {t(i18nKeys.preference.voucherFormat)}
                                                    </legend>
                                                    <Field
                                                        className="vl-u-spacer--small"
                                                        name={
                                                            PreferencesScreenFieldNames.SUPPORT_TYPE_PREFERENCE
                                                        }
                                                        component={SwRadioField}
                                                        id={SupportType.ELECTRONIC}
                                                        label={t(i18nKeys.general.supportType.ELECTRONIC)}
                                                        data-testid={dynamicDataTest(
                                                            dataTest.preferences.supportType,
                                                            { type: SupportType.ELECTRONIC },
                                                        )}
                                                    />

                                                    <Field
                                                        name={
                                                            PreferencesScreenFieldNames.SUPPORT_TYPE_PREFERENCE
                                                        }
                                                        component={SwRadioField}
                                                        id={SupportType.PAPER}
                                                        label={t(i18nKeys.general.supportType.PAPER)}
                                                        data-testid={dynamicDataTest(
                                                            dataTest.preferences.supportType,
                                                            { type: SupportType.PAPER },
                                                        )}
                                                    />
                                                </fieldset>
                                            </SwColumn>
                                            <SwColumn className="vl-u-spacer--small">
                                                {(!customer.emailAddress && values.supportTypePreference === SupportType.ELECTRONIC) &&
                                                    <div>
                                                    <legend className="vl-form__label vl-u-spacer--small">
                                                        <p className="vl-u-spacer--small">{t(i18nKeys.preference.emailNeeded.info)}</p> 
                                                        <p className="vl-u-spacer--small">{t(i18nKeys.preference.emailNeeded.instruction)}</p>
                                                        </legend>
                                                        <ProfileCustomerEmailInfoForm
                                                            isConfirmEmailAddress={
                                                                !!values.emailAddress &&
                                                                values.emailAddress !== customer.emailAddress
                                                            }
                                                            userEmailAddress={user.emailAddress}
                                                            setFieldValue={setFieldValue}
                                                            customerResourceId={customer.resourceId}
                                                            customerAvailableLanguages={
                                                                customer.availableLanguages
                                                            }
                                                        /></div>}
                                            </SwColumn>

                                        </SwGrid>

                                        <SwActionGroup modCollapseS>
                                            <SwButton
                                                type="submit"
                                                onClick={() =>
                                                    sendCTAEventToGTM('Submit Preferences form', 'submit')
                                                }
                                                modLarge
                                                modLoading={isSubmitting}
                                                modDisabled={!dirty}
                                                data-testid={dataTest.preferences.savePreferencesButton}
                                            >
                                                {t(i18nKeys.general.cta.save)}
                                            </SwButton>
                                        </SwActionGroup>
                                        <SwFormSubmitMessage />
                                    </SwColumn>
                                </SwGrid>
                            </SwForm>
                        )}
                    </Formik>
                </SwColumn>
            </SwGrid>
        </SwContainer>
    );
};
