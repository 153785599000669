import * as client from '@govflanders/vl-widget-client';
import '@govflanders/vl-widget-polyfill';
import { httpTo } from '@kaa/api/customers/utilities';
import { getConfig } from '@kaa/core-app/common/config';
import get from 'lodash.get';
import React, { useEffect, useRef, useState } from 'react';

export const Footer: React.FC = () => {
  const [widget, setWidget] = useState<any>(null);
  const [, setIsWidgetMounted] = useState(false);
  const $el = useRef(null);

  useEffect(() => {
    const getWidget = async () => {
      const [error, bootstrapWidget] = await httpTo(
        client.bootstrap(get(getConfig(), 'app.auth.definition.footer')),
      );
      if (error) {
        setWidget(null);
        return;
      }

      setWidget(bootstrapWidget);
    };

    getWidget();
  }, []);

  useEffect(() => {
    if (widget && $el.current) {
      widget.setMountElement($el.current).mount();
      setIsWidgetMounted(true);
      return () => {
        widget.setMountElement(null).unmount();
        setIsWidgetMounted(false);
      };
    }
  }, [widget]);

  return <div ref={$el} />;
};
