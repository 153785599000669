import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type ActionGroupProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  modBordered?: boolean;
  modAlignRight?: boolean;
  modAlignCenter?: boolean;
  modSpaceBetween?: boolean;
  modCollapseXs?: boolean;
  modCollapseS?: boolean;
  modCollapseM?: boolean;
  tagName?: string;
};

type Classes = {
  modBordered?: boolean;
  modAlignRight?: boolean;
  modAlignCenter?: boolean;
  modSpaceBetween?: boolean;
  modCollapseXs?: boolean;
  modCollapseS?: boolean;
  modCollapseM?: boolean;
};
const getClasses = ({
  modBordered,
  modAlignRight,
  modAlignCenter,
  modSpaceBetween,
  modCollapseXs,
  modCollapseS,
  modCollapseM,
}: Classes) => {
  return [
    'vl-action-group',
    modBordered ? 'vl-action-group--bordered' : '',
    modAlignRight ? 'vl-action-group--align-right' : '',
    modAlignCenter ? 'vl-action-group--align-center' : '',
    modSpaceBetween ? 'vl-action-group--space-between' : '',
    modCollapseXs ? 'vl-action-group--collapse--xs' : '',
    modCollapseS ? 'vl-action-group--collapse--s' : '',
    modCollapseM ? 'vl-action-group--collapse--m' : '',
  ].join(' ');
};

export const SwActionGroup = <T extends HTMLElement>({
  tagName,
  modBordered,
  modAlignRight,
  modAlignCenter,
  modSpaceBetween,
  modCollapseXs,
  modCollapseS,
  modCollapseM,
  children,
  className,
  ...rest
}: ActionGroupProps<T>) => {
  const TagName = tagName || 'div';

  const classes = getClasses({
    modBordered,
    modAlignRight,
    modAlignCenter,
    modSpaceBetween,
    modCollapseXs,
    modCollapseS,
    modCollapseM,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
