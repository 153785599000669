import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwAlert, SwLink, SwColumn } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SERVICES_VOUCHERS_ORDER_START_BUY } from '../../constants';
import { dataTest } from '../../datatest/keys';
import {
  DashboardActionEvent,
  sendDashboardActionEventToGTM,
  EventLabel,
} from '../../utils/google-analytics';

type DashboardAlertNotEnoughVouchersProps = {
  enoughVouchers: boolean;
};

export const DashboardAlertNotEnoughVouchers = ({
  enoughVouchers,
}: DashboardAlertNotEnoughVouchersProps) => {
  const { t } = useTranslation();

  if (enoughVouchers) {
    return null;
  }

  return (
    <SwColumn width="9" widthM="12">
      <SwAlert
        icon={Icon.ALERT_CIRCLE}
        modWarning
        title={t(i18nKeys.alert.warning.notEnoughVouchers.title)}
      >
        {t(i18nKeys.alert.warning.notEnoughVouchers.body)}{' '}
        <SwLink
          data-testid={dataTest.dashboard.alerts.notEnoughVouchers}
          to={SERVICES_VOUCHERS_ORDER_START_BUY}
          onClick={() => {
            sendDashboardActionEventToGTM(
              DashboardActionEvent.ALERT,
              EventLabel.NOT_ENOUGH_VOUCHER,
            );
          }}
        >
          {t(i18nKeys.alert.warning.notEnoughVouchers.cta)}
        </SwLink>
      </SwAlert>
    </SwColumn>
  );
};
