import { ContractType,PurchaseStatus } from '@kaa/api/customers';
import { Format, toCurrencyFormat } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwBadge, SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { getPath, Routes } from '../../../../routes';
import {
  EventAction,
  sendCTAEventToGTM,
} from '../../../../utils/google-analytics';
import { OrderTypes } from '../MyOrderScreen.constants';
import { OrderWithQuantityAndTotal } from '../MyOrderScreen.types';

type MyOrderScreenTableRowProps = {
  order: OrderWithQuantityAndTotal;
  testId?: string | undefined;
};

export const MyOrderScreenTableRow = ({
  order,
  testId,
}: MyOrderScreenTableRowProps) => {
  const { t } = useTranslation();

  return (
    <tr
      data-testid={testId}
      style={{
        ...(order.type === OrderTypes.PENDING && { background: '#fef9e5' }),
      }}
    >
      <td data-title={t(i18nKeys.orders.table.vouchers)}>{order.quantity}</td>
      <td data-title={t(i18nKeys.general.label.product)}>
        {order.contractType === ContractType.MATERNITY
          ? t(i18nKeys.general.contractType.MATERNITY)
          : t(i18nKeys.general.supportType[order.supportType])}
      </td>
      <td data-title={t(i18nKeys.general.label.paymentDate)}>
        {order.type === OrderTypes.PENDING
          ? '-'
          : Format.date(order.paymentDate)}
      </td>
      <td data-title={t(i18nKeys.general.label.amount)}>
        {toCurrencyFormat(order.total)}
      </td>
      <td data-title={t(i18nKeys.orders.table.status)}>
        {order.type === OrderTypes.PENDING || order.status === PurchaseStatus.PENDING ? (
          <span className="vl-u-text--bold" style={{ marginLeft: '-2.5rem' }}>
            <SwBadge
              modWarning
              modXsmall
              style={{ verticalAlign: 'middle' }}
              icon={Icon.INFO_CIRCLE}
            />{' '}
            {t(i18nKeys.general.statusType.PENDING)}
          </span>
        ) : (
          t(i18nKeys.general.statusType[order.status])
        )}
      </td>
      <td>
        <SwLink
          data-testid={dataTest.myorders.showDetail}
          to={
            order.type === OrderTypes.PENDING
              ? getPath(Routes.SERVICES_VOUCHERS_ORDER_PENDING)
              : getPath(Routes.SERVICES_VOUCHERS_ORDER_DETAILS, {
                  params: {
                    orderId: order.id,
                  },
                })
          }
          state =  {order}
          onClick={() =>
            sendCTAEventToGTM(
              EventAction.GO_ORDER_DETAILS,
              order.type === OrderTypes.PENDING
                ? '/services-vouchers/order/pending'
                : '/services-vouchers/order/',
            )
          }
          icon={Icon.ARROW_RIGHT}
          modIconAfter
        >
          {t(i18nKeys.general.cta.details)}
        </SwLink>
      </td>
    </tr>
  );
};
