import { Customer } from '@kaa/api/customers';
import { Routes } from '@kaa/core-app/customers/routes';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwModal } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import {
  EventAction,
  EventLabel,
  sendModalInteractionToGTM,
  sentPageViewEvent,
} from '../../../../utils/google-analytics';
import { ExchangeModal } from '../exchange-modal/ExchangeModal';
import { OrderModal } from '../order-modal/OrderModal';
import { RefundModal } from '../refund-modal/RefundModal';
import {
  EventLabelsModals,
  EventLabelsModalsRoutes,
} from './ServicesVouchersModals.types';

const eventLabels = {
  [Modals.ORDER_MODAL_ID]: {
    [Routes.SERVICES_VOUCHERS_ORDER]: EventLabel.ORDER_ORDER_MODAL,
    [Routes.SERVICES_VOUCHERS_WALLET]: EventLabel.WALLET_ORDER_MODAL,
  },
  [Modals.REFUND_MODAL_ID]: {
    [Routes.SERVICES_VOUCHERS_ORDER]: EventLabel.ORDER_REFUND_MODAL,
    [Routes.SERVICES_VOUCHERS_WALLET]: EventLabel.WALLET_REFUND_MODAL,
  },
  [Modals.EXCHANGE_MODAL_ID]: {
    [Routes.SERVICES_VOUCHERS_ORDER]: EventLabel.ORDER_EXCHANGE_MODAL,
    [Routes.SERVICES_VOUCHERS_WALLET]: EventLabel.WALLET_EXCHANGE_MODAL,
  },
} as Record<EventLabelsModals, Record<EventLabelsModalsRoutes, EventLabel>>;

const getEvents = (
  modal: EventLabelsModals,
  route: EventLabelsModalsRoutes,
) => () => {
  sendModalInteractionToGTM(eventLabels[modal][route], EventAction.CLOSE_MODAL);
  sentPageViewEvent();
};

type ServicesVouchersModalsProps = {
  customer: Customer;
  route: EventLabelsModalsRoutes;
  onSuccess: () => void;
};

export const ServicesVouchersModals = ({
  customer,
  route,
  onSuccess,
}: ServicesVouchersModalsProps) => {
  const { t } = useTranslation();
  const confirmCloseMessage = t(i18nKeys.dialog.confirmClose.message);

  return (
    <>
      <SwModal
        id={Modals.EXCHANGE_MODAL_ID}
        confirmCloseMessage={confirmCloseMessage}
        component={ExchangeModal}
        onSuccess={onSuccess}
        customer={customer}
        closable
        onClose={getEvents(Modals.EXCHANGE_MODAL_ID, route)}
      />
      <SwModal
        id={Modals.REFUND_MODAL_ID}
        confirmCloseMessage={confirmCloseMessage}
        component={RefundModal}
        onSuccess={onSuccess}
        customer={customer}
        closable
        onClose={getEvents(Modals.REFUND_MODAL_ID, route)}
      />
      <SwModal
        id={Modals.ORDER_MODAL_ID}
        confirmCloseMessage={confirmCloseMessage}
        component={OrderModal}
        onSuccess={onSuccess}
        customer={customer}
        closable
        onClose={getEvents(Modals.ORDER_MODAL_ID, route)}
      />
    </>
  );
};
