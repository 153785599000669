import {
  Customer,
  CustomerActionType,
  CustomerBuyableVouchersResponse,
  SupportType,
  VouchersOrderConfirmation,
} from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { StorageKeys } from '@kaa/common/enums';
import { useActionsHandler } from '@kaa/common/handlers';
import { oneOfObj, useAsyncCallback } from '@kaa/common/utils';
import { max, min, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AlertType,
  getDefaultAddressValidation,
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwFetchErrorMessage,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwGrid,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
  SwWizard,
  SwWizardStep,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import { navigate } from '@reach/router';
import { AxiosResponse } from 'axios';
import { Formik, FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { getPath, Routes } from '../../../../routes';
import { useApi, useDispatchUpdateCustomer } from '../../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  EventPathname,
  sendCustomInteractionToGTM,
  sendModalInteractionToGTM,
  sentPageViewEvent,
} from '../../../../utils/google-analytics';
import {
  createValidatorWithServerErrorHandled,
  handleApiError,
} from '../../../../utils/validation';
import { getAvailableVouchers } from '../../ServicesVouchers.utils';
import { KlarnaConfirmationAlert } from './components/klarna/KlarnaConfirmationAlert';
import { KlarnaErrorMessage } from './components/klarna/KlarnaErrorMessage';
import { KlarnaMessageOrderProcessing } from './components/klarna/KlarnaMessageOrderProcessing';
import { useKlarna } from './components/klarna/use-klarna';
import { OrderStepConfirmation } from './components/OrderStepConfirmation';
import { OrderStepDetails } from './components/OrderStepDetails';
import { OrderStepOrder } from './components/OrderStepOrder';
import { OrderStepPayment } from './components/OrderStepPayment';
import { PaymentType } from './OrderModal.constants';
import { OrderModalForm } from './OrderModal.type';
import {
  getOrderItems,
  getOrderPreviousPaymentType,
  validationAddressVoucherDelivery,
} from './OrderModal.utils';

const defaultAddressValidationDeliveryAddress = getDefaultAddressValidation(
  'paperVoucherDeliveryAddress',
);

type OrderModalProps = {
  customer: Customer;
  onSuccess?: () => void;
};

export const OrderModal = ({
  customer,
  onSuccess,
  setConfirmCloseModal,
}: SwModalRenderProps<OrderModalProps>) => {
  const { t } = useTranslation();
  const dispatchUpdateCustomer = useDispatchUpdateCustomer();
  const dispatchAction = useActionDispatch();
  const { customers: customersApi } = useApi();
  const [isInitialized, setIsInitialized] = useState(false);

  const PAYMENT_STEP = 'payment';
  const REDIRECT_URL_CONFIRMATION_KLARNA = `${window.location.origin}/services-vouchers/wallet?klarna-confirmed=true`;

  const canPayWithKlarna = useActionsHandler<CustomerActionType>(
    customer.resourceId,
    [CustomerActionType.CAN_PAY_WITH_KLARNA],
  );

  const {
    init,
    klarnaWidget,
    authorizeKlarna,
    klarnaError,
    klarnaResetError,
    klarnaIsAuthorizing,
    klarnaOrder,
    initiateSession,
    sessionCustomerId,
    createdSessionId,
    inputState,
  } = useKlarna();

  const initKlarna = (
    STEPS: SwWizardStep[] = [],
    values: OrderModalForm,
    setFieldValue: (field: string, value: any) => void,
  ) => (stepIndex: number) => {
    if (!canPayWithKlarna) {
      return;
    }

    const paymentStepIndex = STEPS.findIndex(
      (step) => step.value === PAYMENT_STEP,
    );

    if (!paymentStepIndex || stepIndex < paymentStepIndex) {
      klarnaResetError();
      // Reset PaymentType
      setFieldValue('paymentType', getOrderPreviousPaymentType());
      return;
    }

    init({
      voucherOrderItems: values.orderItems,
      supportType: values.supportType,
      redirectUrl: REDIRECT_URL_CONFIRMATION_KLARNA,
      useMainAddressForPaperVoucherDelivery:
        values.useMainAddressForPaperVoucherDelivery,
      saveSupportType: values.saveSupportType,
      paperVoucherDeliveryAddress: values.paperVoucherDeliveryAddress,
    });
  };

  useEffect(() => {
    sentPageViewEvent({
      pathname: EventPathname.ORDER_ORDER_MODAL_ORDER,
    });
    navigate(window.location.pathname, { replace: true });
  }, []);

  const canOrder = useActionsHandler<CustomerActionType>(customer.resourceId, [
    CustomerActionType.CAN_ORDER_QUOTA,
  ]);

  const [
    { value: buyableVouchersAndLocationsAndCustomerPreference, loading, error },
    loadBuyableVouchersAndLocationsAndCustomerPreferences,
  ] = useAsyncCallback(
    async () => {
      const [
        walletBuyableVouchersResponse,
        customerPreferencesResponse,
        locationsResponse,
        regionResponse,
      ] = await Promise.all([
        customersApi.getBuyableVouchers(customer.id),
        customersApi.getCustomerPreferences(customer.id),
        customersApi.getGlobalLocations(),
        customersApi.getGlobalRegionContext(),
      ]);

      const {
        data: { data, actions },
      } = walletBuyableVouchersResponse as AxiosResponse<
        CustomerBuyableVouchersResponse
      >;

      if (actions) {
        dispatchAction({ type: ActionType.ADD, payload: actions });
      }

      return {
        customerPreferencesResponse: customerPreferencesResponse.data.data,
        buyableVouchersResponse: data,
        availableCities: locationsResponse.data.data.cities,
        minimumQuantity: regionResponse.data.data.minimalOrderQuantity,
      };
    },
    [customersApi, customer.id],
    { loading: true },
  );

  useEffect(() => {
    loadBuyableVouchersAndLocationsAndCustomerPreferences();
  }, [loadBuyableVouchersAndLocationsAndCustomerPreferences]);

  const [, getStreets] = useAsyncCallback(
    async (postcode: string, keyword: string) => {
      try {
        const {
          data: { data },
        } = await customersApi.getLocationsSuggestions({ postcode, keyword });
        return data;
      } catch (e) {
        return [];
      }
    },
    [customersApi],
  );

  const [
    { value: submitedForm, loading: postVouchersOrderLoading },
    submit,
  ] = useAsyncCallback(
    async (
      formikData: OrderModalForm,
      formikActions: FormikActions<OrderModalForm>,
    ) => {
      const {
        orderItems,
        supportType,
        useMainAddressForPaperVoucherDelivery,
        paperVoucherDeliveryAddress,
        saveSupportType,
      } = formikData;

      const data = {
        requests: orderItems,
        saveSupportType,
        supportType,
        ...(supportType === SupportType.PAPER && {
          useMainAddressForPaperVoucherDelivery,
          ...(!useMainAddressForPaperVoucherDelivery && {
            paperVoucherDeliveryAddress,
          }),
        }),
      };
      // Handling Direct Payment with Klarna
      if (canPayWithKlarna && formikData.paymentType === PaymentType.KLARNA) {
        const [, sessionResp] = await initiateSession(
          sessionCustomerId,
          createdSessionId,
          inputState?.supportType,
          inputState?.voucherOrderItems,
        );

        if (sessionResp) {
          setIsInitialized(true);

          const [error, response] = await httpTo(authorizeKlarna(data));

          return {
            error,
            response,
            formikData,
            formikActions,
            data,
          };
        }
      } else {
        // Handling Bank Transfer Payment
        const [error, response] = await httpTo(
          customersApi.createVouchersOrder(customer.id, data),
        );

        return { error, response, formikData, formikActions };
      }
    },
    [customersApi, authorizeKlarna],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, response, formikData, formikActions } = submitedForm;
      const {
        useMainAddressForPaperVoucherDelivery,
        paperVoucherDeliveryAddress,
        paymentType,
        supportType,
      } = formikData;
      const { resetForm, setStatus, setSubmitting } = formikActions;

      if (handleApiError(error, formikActions)) {
        return;
      }

      if (!response) {
        if (formikData.paymentType === PaymentType.KLARNA) {
          return;
        }
        setSubmitting(false);
        setStatus({
          msg: t(i18nKeys.profile.errorMessage),
          type: AlertType.ERROR,
        });
        return;
      }

      sentPageViewEvent({
        pathname: `${EventPathname.ORDER_ORDER_MODAL_CONFIRMATION}-${
          formikData.paymentType === PaymentType.KLARNA ? 'online' : 'offline'
        }`,
      });

      if (typeof response !== 'boolean') {
        const {
          data: { data: newVouchersOrder },
        } = response;

        if (newVouchersOrder) {
          sendCustomInteractionToGTM(
            EventCategory.ORDER_VOUCHERS_OFFLINE,
            supportType,
            String(newVouchersOrder.requestedQuantity),
            newVouchersOrder.totalPrice,
          );
        }
      } else if (klarnaOrder) {
        sendCustomInteractionToGTM(
          EventCategory.ORDER_VOUCHERS_ONLINE,
          supportType,
          String(klarnaOrder.requestedQuantity),
          klarnaOrder.totalPrice,
        );
      }

      dispatchUpdateCustomer({
        ...customer,
        useMainAddressForPaperVoucherDelivery,
        paperVoucherDeliveryAddress: !useMainAddressForPaperVoucherDelivery
          ? paperVoucherDeliveryAddress
          : undefined,
      });

      resetForm(formikData);

      if (onSuccess) {
        onSuccess();
      }

      localStorage.setItem(
        StorageKeys.ORDER_PREVIOUS_PAYMENT_TYPE,
        paymentType,
      );
    }
  }, [submitedForm]);

  if (!canOrder && !submitedForm) {
    return (
      <SwGrid modStacked>
        <SwColumn>
          <SwTitle tagName="h2">{t(i18nKeys.newOrder.title)}</SwTitle>
        </SwColumn>
        <SwColumn>
          <SwAlert
            icon={Icon.ALERT_CIRCLE}
            modWarning
            title={t(i18nKeys.newOrder.noQuota)}
          />
        </SwColumn>
        <SwColumn>
          <SwActionGroup>
            <SwButton
              modLarge
              onClick={() => navigate(getPath(Routes.DASHBOARD))}
            >
              {t(i18nKeys.navigation.backToDashboard)}
            </SwButton>
          </SwActionGroup>
        </SwColumn>
      </SwGrid>
    );
  }

  if (loading) {
    return <SwLoader />;
  }

  if (error || !buyableVouchersAndLocationsAndCustomerPreference) {
    return (
      <SwFetchErrorMessage
        onClick={loadBuyableVouchersAndLocationsAndCustomerPreferences}
      />
    );
  }
  const {
    customerPreferencesResponse,
    buyableVouchersResponse,
    availableCities,
    minimumQuantity,
  } = buyableVouchersAndLocationsAndCustomerPreference;

  const { supportTypePreference } = customerPreferencesResponse;

  const {
    buyableVouchers,
    pendingOrderAlreadyExists,
  } = buyableVouchersResponse;

  const availableQuantity = getAvailableVouchers(buyableVouchers);

  const defaultQuantity =
    availableQuantity > minimumQuantity ? minimumQuantity : availableQuantity;

  const validate = createValidatorWithServerErrorHandled({
    quantity: [
      required,
      (value, { supportType }: OrderModalForm) =>
        min(supportType === SupportType.ELECTRONIC ? defaultQuantity : 10)(
          value,
        ),
      max(availableQuantity),
    ],
    paymentType: [required],
    ...validationAddressVoucherDelivery(
      defaultAddressValidationDeliveryAddress,
    ),
  });

  if (
    submitedForm?.response &&
    typeof submitedForm.response !== 'boolean' &&
    submitedForm.formikData.paymentType === PaymentType.BANK_TRANSFER
  ) {
    setConfirmCloseModal(false);
    return (
      <OrderStepConfirmation
        vouchersOrder={
          submitedForm.response.data.data as VouchersOrderConfirmation
        }
        backTo={() => {
          toggleModalById(Modals.ORDER_MODAL_ID);
          navigate(getPath(Routes.SERVICES_VOUCHERS_ORDER));
          sendModalInteractionToGTM(
            EventLabel.ORDER_ORDER_MODAL_CONFIRMATION,
            EventAction.CLOSE_MODAL_BACK_ORDER,
          );
        }}
      />
    );
  }

  if (
    submitedForm?.response &&
    submitedForm?.formikData.paymentType === PaymentType.KLARNA
  ) {
    setConfirmCloseModal(false);

    return (
      <KlarnaConfirmationAlert
        deliveryAddress={
          customer.useMainAddressForPaperVoucherDelivery
            ? customer.mainAddress
            : customer.paperVoucherDeliveryAddress
        }
        supportType={submitedForm?.formikData.supportType}
        requestedQuantity={submitedForm?.formikData.quantity}
      />
    );
  }

  const actualQuantity =
    supportTypePreference === SupportType.PAPER ? 10 : defaultQuantity;

  return (
    <KlarnaMessageOrderProcessing hide={klarnaIsAuthorizing}>
      <Formik
        initialValues={{
          quantity: actualQuantity,
          supportType: supportTypePreference,
          orderItems: getOrderItems({
            buyableVouchers,
            quantity: actualQuantity,
          }),
          useMainAddressForPaperVoucherDelivery:
            customer.useMainAddressForPaperVoucherDelivery,
          paperVoucherDeliveryAddress: customer.paperVoucherDeliveryAddress,
          paymentType: canPayWithKlarna
            ? getOrderPreviousPaymentType()
            : PaymentType.BANK_TRANSFER,
          saveSupportType: false,
        }}
        onSubmit={submit}
        validate={validate}
        isInitialValid
      >
        {({ handleSubmit, values, setFieldValue, errors, dirty }) => {
          setConfirmCloseModal(dirty);
          const STEPS: SwWizardStep[] = [
            {
              label: t(i18nKeys.newOrder.step.order),
              value: 'order',
            },
            {
              label: t(i18nKeys.newOrder.step.details),
              value: 'details',
              modDisabled: oneOfObj(['quantity'], errors),
            },
            {
              label: t(i18nKeys.newOrder.step.payment),
              value: 'payment',
              modDisabled:
                values.supportType === SupportType.PAPER
                  ? oneOfObj(
                      [
                        'quantity',
                        'paperVoucherDeliveryAddress.street',
                        'paperVoucherDeliveryAddress.postcode',
                        'paperVoucherDeliveryAddress.city',
                        'paperVoucherDeliveryAddress.houseNumber',
                      ],
                      errors,
                    )
                  : oneOfObj(['quantity'], errors),
            },
          ];
          return (
            <SwForm onSubmit={(e) => e.preventDefault()}>
              <SwFormGrid modStacked>
                <SwFormColumn>
                  <SwTitle tagName="h2" className="vl-u-spacer--large">
                    {t(i18nKeys.newOrder.title)}
                  </SwTitle>
                  <SwWizard
                    steps={STEPS}
                    onActiveStepChange={initKlarna(
                      STEPS,
                      values,
                      setFieldValue,
                    )}
                  >
                    {/** *** STEP ORDER **** */}
                    <SwWizard.SwWizardStep
                      next={async (selectStep, activeStep) => {
                        if (!STEPS[1].modDisabled) {
                          selectStep(activeStep + 1);
                          sendModalInteractionToGTM(
                            EventLabel.ORDER_ORDER_MODAL_DETAILS,
                            EventAction.NEXT,
                          );
                          sentPageViewEvent({
                            pathname: EventPathname.ORDER_ORDER_MODAL_DETAILS,
                          });
                        }
                      }}
                    >
                      <OrderStepOrder
                        buyableVouchers={buyableVouchers}
                        minQuantity={defaultQuantity}
                        maxQuantity={availableQuantity}
                        orderItems={values.orderItems}
                        supportType={values.supportType}
                        saveSupportType={values.saveSupportType}
                      />
                    </SwWizard.SwWizardStep>
                    {/** *** STEP DETAILS **** */}
                    <SwWizard.SwWizardStep
                      previous={(selectStep, activeStep) => {
                        selectStep(activeStep - 1);
                        sendModalInteractionToGTM(
                          EventLabel.ORDER_ORDER_MODAL_ORDER,
                          EventAction.PREVIOUS,
                        );
                        sentPageViewEvent({
                          pathname: EventPathname.ORDER_ORDER_MODAL_ORDER,
                        });
                      }}
                      next={async (selectStep, activeStep) => {
                        if (!STEPS[2].modDisabled) {
                          selectStep(activeStep + 1);
                          sendModalInteractionToGTM(
                            EventLabel.ORDER_ORDER_MODAL_PAYMENT,
                            EventAction.NEXT,
                          );
                          sentPageViewEvent({
                            pathname: EventPathname.ORDER_ORDER_MODAL_PAYMENT,
                          });
                        }
                      }}
                    >
                      <OrderStepDetails
                        supportType={values.supportType}
                        availableCities={availableCities}
                        useMainAddressForPaperVoucherDelivery={
                          values.useMainAddressForPaperVoucherDelivery
                        }
                        customer={customer}
                        getStreets={getStreets}
                      />
                    </SwWizard.SwWizardStep>
                    {/** *** STEP PAYMENTS **** */}
                    <SwWizard.SwWizardStep
                      isLast={false}
                      previous={(selectStep, activeStep) => {
                        selectStep(activeStep - 1);
                        sendModalInteractionToGTM(
                          EventLabel.ORDER_ORDER_MODAL_DETAILS,
                          EventAction.PREVIOUS,
                        );
                        sentPageViewEvent({
                          pathname: EventPathname.ORDER_ORDER_MODAL_DETAILS,
                        });
                      }}
                      next={() => handleSubmit()}
                      modLoading={postVouchersOrderLoading}
                      modDisabled={
                        values.paymentType === PaymentType.KLARNA &&
                        !klarnaWidget
                      }
                      {...(values.paymentType === PaymentType.KLARNA
                        ? {
                            buttonText: t(
                              i18nKeys.newOrder.stepPayment.cta.buy,
                            ),
                          }
                        : {})}
                    >
                      <OrderStepPayment
                        klarnaWidget={klarnaWidget}
                        canPayWithKlarna={canPayWithKlarna}
                        orderItems={values.orderItems}
                        paymentType={values.paymentType}
                        pendingOrderAlreadyExists={pendingOrderAlreadyExists}
                        supportType={values.supportType}
                        klarnaError={klarnaError}
                        isInitialized={isInitialized}
                      />
                    </SwWizard.SwWizardStep>
                  </SwWizard>
                </SwFormColumn>
                <SwFormColumn>
                  <SwFormSubmitMessage />
                  {canPayWithKlarna &&
                    values.paymentType === PaymentType.KLARNA &&
                    klarnaError && <KlarnaErrorMessage error={klarnaError} />}
                </SwFormColumn>
              </SwFormGrid>
            </SwForm>
          );
        }}
      </Formik>
    </KlarnaMessageOrderProcessing>
  );
};
