import React from 'react';
import { useTranslation } from 'react-i18next';
import { SwIcon } from '../../icon/SwIcon';
import { Icon } from '../../icon/SwIcon.constants';
import { SwLink } from '../../link/SwLink';
import { getFileSize } from '../SwUpload.utils';

type SwUploadFileProps = {
  file: File;
  error?: string;
  unset: (file: File) => void;
};

const getClasses = ({ error }: { error?: boolean }) => {
  return ['vl-upload__file', error ? 'vl-upload__file--error' : ''].join(' ');
};
export const SwUploadFile = ({ file, error, unset }: SwUploadFileProps) => {
  const { t } = useTranslation();
  const size = getFileSize(file.size);

  return (
    <div className={getClasses({ error: Boolean(error) })}>
      <p className="vl-upload__file__name">
        <SwIcon
          className="vl-upload__file__name__icon"
          icon={Icon.DOCUMENT}
          aria-hidden="true"
        />{' '}
        <span>{file.name}</span>{' '}
        <span className="vl-upload__file__size">
          (<strong>{size.value}</strong> {size.unit})
        </span>
      </p>
      {error && (
        <div>
          <span>{error}</span>
        </div>
      )}
      <SwLink
        title={t('general.upload.remove')}
        tagName="button"
        className="vl-upload__file__close"
        onClick={(e) => {
          e.preventDefault();
          unset(file);
        }}
      >
        <SwIcon icon={Icon.CLOSE} aria-hidden="true" />
      </SwLink>
    </div>
  );
};
