import { i18nKeys } from '@kaa/i18n/customers/keys';
import { TFunction } from 'i18next';
import { CommonRoutes } from '../../../common/constants/routes-constant';
import { Routes } from '../routes';

export const TITLE_ROUTES = {
  [Routes.ACCOUNT]: (t: TFunction) => t(i18nKeys.navigation.account),
  [Routes.ACCOUNT_PROFILE]: (t: TFunction) =>
    `${t(i18nKeys.navigation.account)} - ${t(i18nKeys.navigation.myInfos)}`,
  [Routes.ACCOUNT_PREFERENCES]: (t: TFunction) =>
    `${t(i18nKeys.navigation.account)} - ${t(i18nKeys.navigation.preferences)}`,
  [Routes.ACCOUNT_FISCAL_ATTESTATION]: (t: TFunction) =>
    `${t(i18nKeys.navigation.account)} - ${t(
      i18nKeys.navigation.fiscalAttestation,
    )}`,
  [Routes.PRESTATIONS]: (t: TFunction) => t(i18nKeys.navigation.prestations),
  [Routes.PRESTATIONS_WORKS_MANAGEMENT]: (t: TFunction) =>
    `${t(i18nKeys.navigation.prestations)} - ${t(
      i18nKeys.navigation.worksManagement,
    )}`,
  [Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY]: (t: TFunction) =>
    `${t(i18nKeys.navigation.prestations)} - ${t(
      i18nKeys.navigation.worksHistory,
    )}`,
  [Routes.PRESTATIONS_PLAN_A_LEAVE]: (t: TFunction) =>
    `${t(i18nKeys.navigation.prestations)} - ${t(
      i18nKeys.navigation.planALeave,
    )}`,
  [Routes.SERVICES_VOUCHERS]: (t: TFunction) =>
    t(i18nKeys.navigation.serviceVouchers),
  [Routes.SERVICES_VOUCHERS_WALLET]: (t: TFunction) =>
    `${t(i18nKeys.navigation.serviceVouchers)} - ${t(
      i18nKeys.navigation.wallet,
    )}`,
  [Routes.SERVICES_VOUCHERS_ORDER]: (t: TFunction) =>
    `${t(i18nKeys.navigation.serviceVouchers)} - ${t(
      i18nKeys.navigation.orders,
    )}`,
  [Routes.SERVICES_VOUCHERS_ORDER_DETAILS]: (t: TFunction) =>
    `${t(i18nKeys.navigation.serviceVouchers)} - ${t(
      i18nKeys.navigation.orderDetails,
    )}`,
  [Routes.FORBIDDEN_WRONG_REGION]: (t: TFunction) =>
    t(i18nKeys.navigation.wrongRegion),
  [Routes.LANGUAGE_REQUEST]: (t: TFunction) =>
    t(i18nKeys.navigation.languageRequest),
  [Routes.REGISTER]: (t: TFunction) => t(i18nKeys.navigation.register),
  [Routes.REGISTER_COMPLETE]: (t: TFunction) =>
    t(i18nKeys.navigation.registerComplete),
  [Routes.REGISTER_SUMMARY]: (t: TFunction) =>
    t(i18nKeys.navigation.registerSummary),
  [Routes.BLOCKED]: (t: TFunction) => t(i18nKeys.navigation.blocked),
  [CommonRoutes.DASHBOARD]: (t: TFunction) => t(i18nKeys.navigation.dashboard),
  [CommonRoutes.LOGIN]: (t: TFunction) => t(i18nKeys.navigation.login),
  [CommonRoutes.SERVICE_UNAVAILABLE]: (t: TFunction) =>
    t(i18nKeys.navigation.serviceUnavailable),
  [CommonRoutes.PAGE_404]: (t: TFunction) => t(i18nKeys.navigation.error404),
};
