import {
  useEventListener,
  useOnScreen,
  useRefMounted,
} from '@kaa/common/utils';
import Popper from 'popper.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import Tooltip from 'tooltip.js';

type Binding = {
  value?: string;
  modActive?: boolean; // to handle the trigger manually
  placement?: Popper.Placement;
};

const keyupHandler = (tooltip: Tooltip) => (event: any) => {
  const evt = event || window.event;
  const isEscape =
    'key' in evt
      ? evt.key === 'Escape' || evt.key === 'Esc'
      : evt.keyCode === 27;

  if (isEscape) {
    tooltip.hide();
  }
};

export const useTooltip = <
  T extends HTMLElement & { tooltipInstance?: Tooltip }
>(
  binding: Binding,
) => {
  const domRef = useRef<T | null>(null);

  const handler = useCallback(
    (e) => {
      if (domRef.current && domRef.current.tooltipInstance) {
        keyupHandler(domRef.current.tooltipInstance)(e);
      }
    },
    [domRef],
  );

  useEventListener('keyup', handler);
  const mount = useRefMounted();

  const onScreen = useOnScreen(domRef);

  useEffect(() => {
    if (domRef.current && !domRef.current.tooltipInstance) {
      const title = binding.value;
      const offset = '0,10';
      const template = `
          <div style="z-index:5" class="vl-tooltip vl-typography ${
            title && title.length > 80 ? 'vl-tooltip--large' : ''
          }" role="tooltip" aria-hidden="true">
            <div class="vl-tooltip__arrow"></div>
            <div class="tooltip__inner"></div>
          </div>`;

      const options = {
        placement: binding.placement || 'auto',
        template,
        offset,
        html: true,
        title,
        ...(typeof binding.modActive !== 'undefined' && { trigger: 'manual' }),
      };

      domRef.current.tooltipInstance = new Tooltip(domRef.current, options);
      domRef.current.dataset.vlTooltipTitle = title;
    }

    return () => {
      if (!mount.current && domRef.current && domRef.current.tooltipInstance) {
        domRef.current.tooltipInstance.dispose();
      }
    };
  }, [domRef, binding, mount]);

  const [wasActive, setWasActive] = useState(false);

  useEffect(() => {
    const el = domRef.current;
    if (!el || !el.tooltipInstance) {
      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    if (onScreen && wasActive) {
      el.tooltipInstance.show();
      setWasActive(false);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (!onScreen && el.tooltipInstance._isOpen) {
      el.tooltipInstance.hide();
      setWasActive(true);
    }
  }, [domRef, onScreen]);

  useEffect(() => {
    const el = domRef.current;
    if (!el || !el.tooltipInstance) {
      return;
    }

    if (!onScreen) {
      return;
    }

    if (el.dataset.vlTooltipTitle !== binding.value) {
      el.tooltipInstance.updateTitleContent(binding.value || '');
      el.dataset.vlTooltipTitle = binding.value;
    }

    if (typeof binding.modActive !== 'undefined') {
      if (binding.modActive) {
        el.tooltipInstance.show();
      } else {
        el.tooltipInstance.hide();
      }
    }
  }, [domRef, binding, onScreen]);

  return domRef;
};
