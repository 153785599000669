import { httpTo } from '@kaa/api/customers/utilities';
import { getApi } from '@kaa/api/idp';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import {
    Format,
    getQueryParam,
    useAsync,
    useAsyncCallback,
} from '@kaa/common/utils';
import {
    isValidForeignIban,
    setError,
    ValidationError,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
    AddressFieldNames,
    AlertType,
    SwActionGroup,
    SwButton,
    SwColumn,
    SwContainer,
    SwFetchErrorMessage,
    SwForm,
    SwFormAddress,
    SwFormGrid,
    SwFormSubmitMessage,
    SwFormValidateOnMount,
    SwGrid,
    SwModal,
    SwTitle,
    toggleModalById,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import get from 'lodash.get';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../common/config';
import {
    ContainerLoadingPageHeader,
    Onboarding,
    PageHeader,
} from '../../../components';
import { MotionDiv } from '../../../components/animation/MotionDiv';
import { Modals } from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { Routes } from '../../../routes';
import {
    useApi,
    useDispatchUpdateCustomer,
    useDispatchUpdateMessages,
    useSelectedCustomerState,
    useUserState,
} from '../../../utils';
import { sendCTAEventToGTM } from '../../../utils/google-analytics';
import { handleApiError } from '../../../utils/validation';
import { ProfileConfirmChangeRegionModal } from './components/ProfileConfirmChangeRegionModal';
import { ProfileCustomerBankInfoForm } from './components/ProfileCustomerBankInfofForm';
import { ProfileCustomerContactInfoForm } from './components/ProfileCustomerContactInfoForm';
import { ProfileCustomerContractsList } from './components/ProfileCustomerContractsList';
import { ProfileCustomerMainInfoForm } from './components/ProfileCustomerMainInfoForm';
import { ProfileUserInfoForm } from './components/ProfileUserInfoForm';
import { ProfileScreenFieldNames } from './ProfileScreen.constants';
import { ProfileFormState } from './ProfileScreen.type';
import { createValidate } from './ProfileScreen.utils';

const gridMargin = window.innerWidth < 767 ? '1.5rem' : '3rem';
const toggleDeliveryVariants = {
    close: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
        marginTop: '2px',
        width: '100%',
        transitionEnd: {
            marginTop: `-${gridMargin}`,
            display: 'none',
        },
    },
    open: {
        height: 'auto',
        overflow: 'hidden',
        marginTop: gridMargin,
        width: '100%',
        opacity: 1,
        transitionEnd: {
            overflow: 'inherit',
        },
    },
};
export const ProfileScreen = () => {
    const { t } = useTranslation();
    const { customers: customersApi } = useApi();
    const idpBaseUrl = get(getConfig(), 'app.auth.oidc.authority');
    const regionCode = get(getConfig(), 'buildConfig.regionCode');
    const idpApi = useMemo(() => getApi({ baseUrl: idpBaseUrl }), [idpBaseUrl]);
    const [dataToBeConfirm, setDataToBeConfirm] = useState<{
        formikData: ProfileFormState;
        formikActions: FormikActions<ProfileFormState>;
    } | null>(null);

    const user = useUserState();
    const customer = useSelectedCustomerState();
    const dispatchUpdateCustomer = useDispatchUpdateCustomer();
    const dispatchUpdateMessages = useDispatchUpdateMessages();
    const dispatchAction = useActionDispatch();

    const token = getQueryParam('token');

    const [
        { value: availableCities, loading, error: availableCitiesError },
        getGlobalLocations,
    ] = useAsyncCallback(
        async () => (await customersApi.getGlobalLocations()).data.data.cities,
        [customersApi],
        { loading: true },
    );

    useEffect(() => {
        getGlobalLocations();
    }, [getGlobalLocations]);

    const { loading: loadingEidData, value: eidData } = useAsync(async () => {
        if (!token) {
            return;
        }

        const { data } = await idpApi.readEidData(token);

        const {
            firstName,
            lastName,
            birthDate,
            niss,
            street,
            city,
            postCode,
            houseNumber,
            rawData,
        } = data;

        return {
            firstName,
            lastName,
            mainAddress: {
                street,
                city,
                streetId: 0,
                postcode: postCode,
                houseNumber,
            },
            birthDate,
            niss,
            rawData,
        };
    }, [idpApi, token]);

    const [, getStreets] = useAsyncCallback(
        async (postcode: string, query: string) => {
            const keyword = query.trim();
            if (!keyword) {
                return [];
            }
            try {
                const {
                    data: { data },
                } = await customersApi.getLocationsSuggestions({ postcode, keyword });
                return data;
            } catch (e) {
                return [];
            }
        },
        [customersApi],
    );

    const { value: street, loading: loadingStreet } = useAsync(async () => {
        if (!eidData) {
            return;
        }
        const [, streets = []] = await getStreets(
            eidData.mainAddress.postcode,
            eidData.mainAddress.street,
        );

        if (streets.length > 1) {
            return;
        }
        const street = streets[0];

        if (!street) {
            return;
        }

        return {
            street: street.name,
            city: street.city,
            streetId: street.id,
            postcode: street.postcode,
            country: street.country,
        };
    }, [eidData]);

    const isEidStreet = useCallback((): ValidationError | false => {
        if (!eidData || street) {
            return false;
        }

        return {
            id: (t: TFunction) =>
                t(i18nKeys.profile.errors.form.eidStreetNotFound, {
                    phoneNumber: t(i18nKeys.bl.general.helpCenter.phone),
                }),
            defaultMessage: 'We did not find the address. Call our help center.',
            displayFocussed: true,
        };
    }, [street, eidData]);

    const submit = (
        formikData: ProfileFormState,
        formikActions: FormikActions<ProfileFormState>,
    ) => {
        if (formikData.mainAddress.postcode !== customer.mainAddress.postcode) {
            const city = (availableCities || []).find(
                (city) => formikData.mainAddress.postcode === city.postcode,
            );

            if (!city) {
                const { setFieldValue, setSubmitting } = formikActions;
                setError({
                    setFieldValue,
                    name: `${ProfileScreenFieldNames.MAIN_ADDRESS}.${AddressFieldNames.STREET}.notFound`,
                });
                setSubmitting(false);
                return;
            }

            if (city && regionCode !== city.regionCode) {
                toggleModalById(Modals.CHANGE_REGION_ID);
                setDataToBeConfirm({ formikData, formikActions });
                return;
            }
        }

        submitWithCall(formikData, formikActions);
    };

    const [{ value: submitedForm }, submitWithCall] = useAsyncCallback(
        async (
            formikData: ProfileFormState,
            formikActions: FormikActions<ProfileFormState>,
        ) => {
            const {
                confirmEmailAddress,
                paperVoucherDeliveryAddress,
                useMainAddressForPaperVoucherDelivery,
                refundBankAccount,
                ...rest
            } = formikData;

            const data = {
                ...rest,
                refundBankAccount: {
                    iban: refundBankAccount?.iban as string,
                    bic: isValidForeignIban(refundBankAccount?.iban)
                        ? refundBankAccount?.bic
                        : undefined,
                },
                useMainAddressForPaperVoucherDelivery,
                ...(!useMainAddressForPaperVoucherDelivery && {
                    paperVoucherDeliveryAddress,
                }),
                eidData: eidData && eidData.rawData,
            };
            const [error, response] = await httpTo(
                customersApi.updateCustomer(customer.id, data),
            );

            return { response, error, formikData, formikActions };
        },
        [customersApi, eidData],
    );

    useEffect(() => {
        if (submitedForm) {
            const { error, formikActions, formikData, response } = submitedForm;
            const { resetForm, setStatus, setSubmitting } = formikActions;
            if (handleApiError(error, formikActions)) {
                return;
            }
            if (!response) {
                setSubmitting(false);
                setStatus({
                    msg: t(i18nKeys.profile.errorMessage),
                    type: AlertType.ERROR,
                });
                return;
            }

            const {
                data: { data, actions, messages },
            } = response;

            dispatchUpdateCustomer(data);
            if (actions) {
                dispatchAction({ type: ActionType.ADD, payload: actions });
            }
            if (messages) {
                dispatchUpdateMessages(messages);
            }

            resetForm({
                ...formikData,
                confirmEmailAddress: '',
            });
            setStatus({
                msg: t(i18nKeys.profile.successMessage),
                type: AlertType.SUCCESS,
            });
        }
    }, [submitedForm]);

    const validate = useMemo(() => createValidate(customer, isEidStreet), [
        customer,
        isEidStreet,
    ]);

    if ((!availableCities && loading) || loadingEidData || loadingStreet) {
        return (
            <ContainerLoadingPageHeader
                title={t(i18nKeys.navigation.myInfos)}
                introduction={t(i18nKeys.profile.introduction)}
            />
        );
    }

    if (availableCitiesError || !availableCities) {
        return (
            <SwContainer error>
                <SwFetchErrorMessage onClick={getGlobalLocations} />
            </SwContainer>
        );
    }

    return (
        <>
            <SwContainer>
                <Onboarding activeRoute={Routes.ACCOUNT_PROFILE} />
                <SwGrid modStacked>
                    <SwColumn width="10" widthS="12">
                        <PageHeader
                            title={t(i18nKeys.navigation.myInfos)}
                            introduction={t(i18nKeys.profile.introduction)}
                            className="push-pageHeader"
                        />
                    </SwColumn>
                    <SwColumn width="10" widthS="12">
                        <SwGrid modStacked className="vl-u-flex-v-flex-start">
                            <SwColumn className="vl-u-spacer--small">
                                <SwTitle tagName="h2">
                                    {t(i18nKeys.profile.loginSection.title)}
                                </SwTitle>
                                <SwTitle
                                    tagName="h3"
                                    tagStyle="h5"
                                    className="vl-u-spacer--small"
                                >
                                    {t(i18nKeys.profile.loggedAs.title)}
                                </SwTitle>
                                <ProfileUserInfoForm user={user} />
                            </SwColumn>
                            <Formik
                                initialValues={{
                                    ...customer,
                                    niss: Format.nationalNumber(customer.niss),
                                    mobilePhoneNumber:
                                        customer.mobilePhoneNumber &&
                                        Format.mobilePhoneNumber(customer.mobilePhoneNumber),
                                    phoneNumber:
                                        customer.phoneNumber &&
                                        Format.phoneNumber(customer.phoneNumber),
                                    ...eidData,
                                    mainAddress: {
                                        ...customer.mainAddress,
                                        ...(eidData && eidData.mainAddress),
                                        ...street,
                                    },
                                    confirmEmailAddress: ''
                                }}
                                onSubmit={submit}
                                validate={validate}
                            >
                                {({
                                    values,
                                    setFieldValue,
                                    handleSubmit,
                                    isSubmitting,
                                    dirty,
                                    errors,
                                }: FormikProps<ProfileFormState>) => (
                                    <SwForm onSubmit={handleSubmit} className="vl-col--12-12">
                                        <SwGrid modStacked className="vl-u-flex-v-flex-start">
                                            <SwColumn>
                                                <SwTitle tagName="h2">
                                                    {t(i18nKeys.profile.customerSection.title)}
                                                </SwTitle>
                                                <SwTitle
                                                    tagName="h3"
                                                    tagStyle="h5"
                                                    className="vl-u-spacer--xsmall"
                                                >
                                                    {t(i18nKeys.profile.personalInformation.title)}
                                                </SwTitle>
                                                <p className="vl-u-spacer--small">
                                                    {t(i18nKeys.profile.personalInformation.description)}
                                                </p>

                                                <ProfileCustomerMainInfoForm
                                                    resourceId={customer.resourceId}
                                                    getStreets={getStreets}
                                                    availableCities={availableCities}
                                                />
                                            </SwColumn>
                                            <MotionDiv
                                                className="vl-col-12-12"
                                                testid={dataTest.profile.deliveryAddressCard}
                                                isOpen={!values.useMainAddressForPaperVoucherDelivery}
                                                transition={{ ease: 'easeInOut' }}
                                                variants={toggleDeliveryVariants}
                                            >
                                                <SwTitle
                                                    tagName="h3"
                                                    tagStyle="h5"
                                                    className="vl-u-spacer--small"
                                                >
                                                    {t(i18nKeys.profile.deliveryAddress.title)}
                                                </SwTitle>
                                                <SwFormGrid modStacked>
                                                    <SwFormAddress
                                                        fieldNamePrefix="paperVoucherDeliveryAddress"
                                                        availableCities={availableCities}
                                                        getStreets={getStreets}
                                                        modRequired={[
                                                            AddressFieldNames.POSTCODE,
                                                            AddressFieldNames.CITY,
                                                            AddressFieldNames.STREET,
                                                            AddressFieldNames.HOUSE_NUMBER,
                                                        ]}
                                                    />
                                                </SwFormGrid>
                                            </MotionDiv>
                                            <SwColumn>
                                                <SwTitle
                                                    tagName="h3"
                                                    tagStyle="h5"
                                                    className="vl-u-spacer--small"
                                                >
                                                    {t(i18nKeys.profile.contactMe.title)}
                                                </SwTitle>
                                                <ProfileCustomerContactInfoForm
                                                    isConfirmEmailAddress={
                                                        !!values.emailAddress &&
                                                        values.emailAddress !== customer.emailAddress
                                                    }
                                                    userEmailAddress={user.emailAddress}
                                                    setFieldValue={setFieldValue}
                                                    customerResourceId={customer.resourceId}
                                                    customerAvailableLanguages={
                                                        customer.availableLanguages
                                                    }
                                                />
                                            </SwColumn>
                                            <SwColumn>
                                                <ProfileCustomerBankInfoForm values={values} />
                                            </SwColumn>
                                            <SwActionGroup modCollapseS>
                                                <SwButton
                                                    type="submit"
                                                    modLoading={isSubmitting}
                                                    modLarge
                                                    modDisabled={!eidData && !dirty}
                                                    data-testid={dataTest.profile.saveProfileButton}
                                                    onClick={() =>
                                                        sendCTAEventToGTM('Submit Profile form', 'submit')
                                                    }
                                                >
                                                    {t(i18nKeys.general.cta.save)}
                                                </SwButton>
                                            </SwActionGroup>
                                            <SwFormValidateOnMount />
                                            <SwFormSubmitMessage />
                                        </SwGrid>
                                    </SwForm>
                                )}
                            </Formik>
                        </SwGrid>
                    </SwColumn>
                    <SwColumn width="10" widthS="12">
                        <ProfileCustomerContractsList />
                    </SwColumn>
                </SwGrid>
            </SwContainer>
            <SwModal
                id={Modals.CHANGE_REGION_ID}
                closable
                onSubmit={() => {
                    if (dataToBeConfirm) {
                        submitWithCall(
                            dataToBeConfirm.formikData,
                            dataToBeConfirm.formikActions,
                        );
                    }
                    toggleModalById(Modals.CHANGE_REGION_ID);
                    setDataToBeConfirm(null);
                }}
                onClose={() => {
                    if (dataToBeConfirm) {
                        dataToBeConfirm.formikActions.setSubmitting(false);
                    }
                    setDataToBeConfirm(null);
                }}
                component={ProfileConfirmChangeRegionModal}
            />
        </>
    );
};
