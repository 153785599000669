import React, { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';
import { RequiredStar } from './SwFormRequiredStar';

type FormMessageLabelProps<T = HTMLLabelElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  modBlock?: boolean;
  modLight?: boolean;
  modRequired?: boolean;
};

type Classes = {
  modBlock?: boolean;
  modLight?: boolean;
};

const getClasses = ({ modBlock, modLight }: Classes) => {
  return [
    'vl-form__label',
    modBlock ? 'vl-form__label--block' : '',
    modLight ? 'vl-form__label--light' : '',
  ].join(' ');
};

export const SwFormMessageLabel = <T extends HTMLElement>({
  tagName,
  modBlock,
  modLight,
  modRequired = false,
  className,
  children,
  ...rest
}: FormMessageLabelProps<T>) => {
  const TagName = tagName || 'label';
  const classes = getClasses({
    modLight,
    modBlock,
  });

  return createElement(
    TagName,
    {
      className: mergeClassNames(classes, className),
      ...rest,
    },
    <>
      {children}
      {modRequired && <RequiredStar />}
    </>,
  );
};
