import { httpTo } from '@kaa/api/customers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwFetchErrorMessage,
  SwIcon,
  SwLink,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi, useSelectedCustomerState } from '../../../../utils';
import {
  EventAction,
  sendDownloadEventToGTM,
} from '../../../../utils/google-analytics';
import { CurrentDocument } from '../FiscalAttestationScreen.constants';

type FiscalAttestationDownloadModalProps = {
  currentDocument: CurrentDocument;
};

export const FiscalAttestationDownloadModal = ({
  currentDocument: { documentId, fiscalYear },
}: SwModalRenderProps<FiscalAttestationDownloadModalProps>) => {
  const { t } = useTranslation();
  const { customers } = useApi();
  const customer = useSelectedCustomerState();

  const [
    { value: templateLink, loading, error },
    getFiscalAttestationDocument,
  ] = useAsyncCallback(
    async () => {
      const [, payload] = await httpTo(
        customers.fiscalAttestationDocument(customer.id, documentId),
      );

      if (!payload) {
        return undefined;
      }

      const blob = new Blob([payload.data as BlobPart], {
        type: 'application/pdf',
      });

      const url = window.URL.createObjectURL(blob);

      return { url, blob };
    },
    [customers],
    { loading: true },
  );

  useEffect(() => {
    getFiscalAttestationDocument();
  }, [getFiscalAttestationDocument]);

  if (loading) {
    return <SwLoader />;
  }

  if (error || !templateLink) {
    return <SwFetchErrorMessage onClick={getFiscalAttestationDocument} />;
  }

  const { blob, url } = templateLink;

  const fileName = t(i18nKeys.fiscalAttestation.download.document, {
    taxationYear: fiscalYear,
  });

  const file = `${fileName}.pdf`;

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.fiscalAttestation.download.title, {
          taxationYear: fiscalYear,
        })}
      </SwTitle>
      <SwLink
        tagName="a"
        href={url}
        className="download-link"
        download={file}
        onClick={(e) => {
          if (window.navigator.msSaveOrOpenBlob) {
            e.preventDefault();
            window.navigator.msSaveOrOpenBlob(blob, file);
          }
          sendDownloadEventToGTM(
            EventAction.DOWNLOAD,
            `Fiscal Attestation ${fiscalYear}`,
          );
        }}
      >
        <SwIcon icon={Icon.FILE_DOWNLOAD} />
        {t(i18nKeys.general.cta.download)}
      </SwLink>
    </>
  );
};
