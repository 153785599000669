import { ServiceDelivery, ServiceStatus } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwBadge, SwLink, useTooltip } from '@kaa/ui-flanders/components';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SERVICES_VOUCHERS_ORDER_START_BUY } from '../../../../../constants';

type PrestationsWorkHistoryTableRowStatusContentDetailsProps = {
  work: ServiceDelivery;
  seeDetails: () => void;
};

export const PrestationsWorkHistoryTableRowStatusContentDetails = ({
  work,
  seeDetails,
}: PrestationsWorkHistoryTableRowStatusContentDetailsProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { status, voucherAttributionDate, modificationDate } = work;
  const refTooltipInfo = useTooltip({
    value: t(i18nKeys.worksManagement.history.info.processingVouchers),
    placement: 'auto',
  });
  const refTooltipContactProvider = useTooltip({
    value: t(i18nKeys.worksManagement.history.info.contactProvider),
    placement: 'bottom',
  });
  const refAwaitingPaymentTooltipInfo = useTooltip({
    value: t(i18nKeys.worksManagement.history.info.processingPayment),
    placement: 'auto',
  });

  switch (status) {
    case ServiceStatus.ARCHIVED_PAID:
    case ServiceStatus.ACTIVE_PARTIALLY_PAID:
      return voucherAttributionDate ? (
        <div>
          {t(i18nKeys.worksManagement.history.attributionDate, {
            date: DateTime.fromISO(voucherAttributionDate).toFormat(
              'dd/LL/yyyy',
            ),
          })}
        </div>
      ) : null;
    case ServiceStatus.ARCHIVED_CANCELLED:
      return (
        <div>
          {t(i18nKeys.worksManagement.history.vouchersAssigned, {
            count: 0,
          })}
        </div>
      );
    case ServiceStatus.ACTIVE_CONTESTED:
      return modificationDate ? (
        <div>
          {t(i18nKeys.worksManagement.history.modifiedOn, {
            date: DateTime.fromISO(modificationDate).toFormat('dd/LL/yyyy'),
          })}{' '}
          -{' '}
          <SwLink type="button" onClick={seeDetails}>
            {t(i18nKeys.worksManagement.cta.details)}
          </SwLink>
        </div>
      ) : null;
    case ServiceStatus.ACTIVE_NOT_ENOUGH_VOUCHERS:
      return (
        <div>
          <SwLink to={SERVICES_VOUCHERS_ORDER_START_BUY}>
            {t(
              i18nKeys.worksManagement.awaitingConfirmation.alert.confirmed.cta,
            )}
          </SwLink>
        </div>
      );
    case ServiceStatus.ACTIVE_AWAITING_PAYMENT:
      return (
        <>
          {' '}
          <SwBadge
            ref={refAwaitingPaymentTooltipInfo}
            modAction
            modXsmall
            icon={Icon.INFO}
            style={{ verticalAlign: 'middle' }}
          />
        </>
      );
    case ServiceStatus.ACTIVE_AWAITING_VALIDATION:
    case ServiceStatus.ACTIVE_AWAITING_PROVIDER_APPROVAL:
      return (
        <>
          {' '}
          <SwBadge
            ref={refTooltipInfo}
            modAction
            modXsmall
            icon={Icon.INFO}
            style={{ verticalAlign: 'middle' }}
          />
        </>
      );
    case ServiceStatus.ARCHIVED_PARTIALLY_PAID:
    case ServiceStatus.ARCHIVED_OUTSIDE_SV:
      return (
        <>
          {' '}
          <SwBadge
            ref={refTooltipContactProvider}
            modAction
            modXsmall
            icon={Icon.INFO}
            style={{ verticalAlign: 'middle' }}
          />
        </>
      );
    default:
      return null;
  }
};
