import { Omit } from '@kaa/ui-flanders/components/utilities';
import { motion, MotionProps, useAnimation } from 'framer-motion';
import React, { HTMLAttributes, useEffect } from 'react';

type MotionDivProps = MotionProps &
  Omit<HTMLAttributes<HTMLDivElement>, 'defaultValue'> & {
    testid?: string;
    isOpen: boolean;
  };

export const MotionDiv = ({
  testid,
  isOpen,
  children,
  ...props
}: MotionDivProps) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.stop();
    if (isOpen) {
      controls.start('open');
    } else {
      controls.start('close');
    }

    return () => {
      controls.stop();
    };
  }, [isOpen, controls]);

  return (
    <motion.div
      data-testid={testid}
      initial={isOpen ? 'open' : 'close'}
      animate={controls}
      {...props}
    >
      {children}
    </motion.div>
  );
};
