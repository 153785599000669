import { SwColumn } from '@kaa/ui-flanders/components';
import React, { ReactNode } from 'react';

type MyOrderDetailsRowPros = {
  children: ReactNode[];
};

export const MyOrderDetailsRow = ({ children }: MyOrderDetailsRowPros) => {
  return (
    <>
      <SwColumn width="4" widthS="12" className="vl-u-text--bold">
        {children[0]}
      </SwColumn>
      <SwColumn width="8" widthS="12">
        {children[1]}
      </SwColumn>
    </>
  );
};
