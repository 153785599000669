import { VouchersExchangeCreatedResponseDataData } from '@kaa/api/customers';
import { Format, toCurrencyFormat } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwAlert,
  SwColumn,
  SwFormMessageLabel,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ExchangeModalElectronicSuccessProps = {
  confirmation: VouchersExchangeCreatedResponseDataData;
};

export const ExchangeModalElectronicSuccess = ({
  confirmation,
}: ExchangeModalElectronicSuccessProps) => {
  const { t } = useTranslation();

  const {
    fullName,
    contractNumber,
    purchaseId,
    previousExpirationDate,
    expirationDate,
    quantity,
    faceValue,
  } = confirmation;

  return (
    <>
      <SwAlert
        icon={Icon.CHECK_CIRCLE}
        modSuccess
        title={t(i18nKeys.exchange.alert.success.title)}
        titleTagName="h3"
        className="vl-u-spacer--medium"
      >
        {t(i18nKeys.exchange.alert.success.body)}
      </SwAlert>

      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--xsmall">
        {t(i18nKeys.exchange.summary.title)}
      </SwTitle>

      <SwGrid className="vl-u-spacer">
        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.exchange.summary.fullName)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {fullName}
        </SwColumn>

        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.exchange.contractNumber)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {contractNumber}
        </SwColumn>

        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.exchange.summary.purchaseId)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {purchaseId}
        </SwColumn>

        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.exchange.summary.previousExpirationDate)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {Format.date(previousExpirationDate)}
        </SwColumn>

        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.exchange.summary.expirationDate)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {Format.date(expirationDate)}
        </SwColumn>

        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.exchange.summary.quantity)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {quantity}
        </SwColumn>

        <SwColumn width="4" widthM="12" className="vl-u-text--bold">
          <SwFormMessageLabel>
            {t(i18nKeys.exchange.summary.faceValue)}
          </SwFormMessageLabel>
        </SwColumn>
        <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
          {toCurrencyFormat(faceValue)}
        </SwColumn>
      </SwGrid>
    </>
  );
};
