/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type ContractType = 'STANDARD' | 'MATERNITY';

export const ContractType = {
  STANDARD: 'STANDARD' as ContractType,
  MATERNITY: 'MATERNITY' as ContractType,
};
