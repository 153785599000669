export const CLIENT_ID_CITIZEN_VL = 'citizen-web-vl';
export const CLIENT_ID_PROVIDER_VL = 'provider-web-vl';
export const CLIENT_ID_CITIZEN_VL_OCMW = `${CLIENT_ID_CITIZEN_VL}-ocmw`;
export const CLIENT_ID_PROVIDER_VL_LOCAL = `${CLIENT_ID_PROVIDER_VL}-local`;

export enum AuthStorageKeys {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  ID_TOKEN = 'id_token',
  USER_ID = 'user_id',
  // If you change this routes, please change it signin-callback.html on every project
  CALLBACK_REDIRECT_URI = 'callback_redirect_uri',
  WAS_LOGGED = 'was_logged',
  OCMW_LOGIN = 'citizen_ocmw',
  LOCAL_LOGIN = 'provider_local',
}

export enum Tenant {
  WORKER = 'tenant:worker',
  CUSTOMER = 'tenant:customer',
}
