import { CustomerVacancy } from '@kaa/api/customers';
import { getStarOfTheDayJsDate, useAsyncCallback } from '@kaa/common/utils';
import { required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormSubmitMessage,
  SwGrid,
  SwInputCalendarField,
  SwInputCalendarFieldFormatDate,
  SwInputCalendarFieldGetJsDate,
  SwInputCalendarFieldParseDate,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi, useSelectedCustomerState } from '../../../../utils';
import { createValidatorWithServerErrorHandled } from '../../../../utils/validation';
import {
  ExcludeDates,
  isValidRange,
} from '../PrestationsPlanALeaveScreen.utils';
import { PlanALeaveFormInit } from './PrestationsPlanALeaveFormInit';

type PlanALeaveFormProps = {
  initialValues: {
    startDate: string;
    endDate: string;
  };
  maxEndDate: Date | undefined;
  setMaxEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  excludeDates: ExcludeDates;
  submitVacancy: (
    data: CustomerVacancy,
    formikActions: FormikActions<CustomerVacancy>,
  ) => Promise<[Error | undefined, any | undefined]>;
};
export const PlanALeaveForm = ({
  initialValues,
  maxEndDate,
  setMaxEndDate,
  submitVacancy,
  excludeDates,
}: PlanALeaveFormProps) => {
  const { t } = useTranslation();

  const customer = useSelectedCustomerState();
  const { customers } = useApi();

  const dateFormat = 'd/m/Y';

  const [{ value: response, loading }, getMaxEndDate] = useAsyncCallback(
    async (startDate: string, form: FormikProps<CustomerVacancy>) => {
      const { endDate } = (
        await customers.getCustomerVacancyEndDate(customer.id, { startDate })
      ).data.data;

      return {
        startDate,
        endDate: form.values.endDate,
        maxEndDate: endDate,
        form,
      };
    },
    [customers],
  );

  const startDateChange = (
    dates: Date[],
    field: FieldProps<CustomerVacancy>['field'],
    form: FieldProps<CustomerVacancy>['form'],
  ): void => {
    const date = SwInputCalendarFieldParseDate(dates);
    if (date) {
      getMaxEndDate(date, form);
    }
  };

  useEffect(() => {
    if (response) {
      const { startDate, endDate, maxEndDate, form } = response;
      if (endDate < startDate || endDate > maxEndDate) {
        form.setValues({
          startDate,
          endDate: '',
        });
      }
      setMaxEndDate(SwInputCalendarFieldGetJsDate(maxEndDate));
    }
  }, [response]);

  const { startDate: minDate } = initialValues;

  const validate = createValidatorWithServerErrorHandled({
    startDate: [required],
    endDate: [isValidRange(excludeDates), required],
  });

  const maxEndDateMax = getStarOfTheDayJsDate(
    DateTime.local()
      .plus({ year: 1 })
      .endOf('year'),
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitVacancy}
      validate={validate}
      enableReinitialize
      render={({ handleSubmit, isSubmitting, values, status }) => (
        <>
          {status && (
            <div className="vl-u-spacer--large">
              <SwFormSubmitMessage />
            </div>
          )}
          <PlanALeaveFormInit getMaxEndDate={getMaxEndDate} />
          <SwForm
            onSubmit={handleSubmit}
            className="vl-u-bg-alt"
            style={{ paddingTop: '2rem' }}
          >
            <SwGrid
              className="vl-u-spacer--small"
              modStacked
              style={{ padding: '0 3rem 2rem' }}
            >
              <SwColumn width="3" widthM="12">
                <p>
                  {t(i18nKeys.planALeave.iWantToSuspend)}
                  <span className="vl-u-hidden--m">
                    &nbsp;{t(i18nKeys.planALeave.from)}
                  </span>
                </p>
              </SwColumn>
              <SwColumn width="7" widthM="12">
                <div className="vl-u-display-flex vl-u-flex-align-flex-start">
                  <div
                    className="vl-col--6-12--m"
                    style={{ paddingRight: '1rem', flexBasis: '45%' }}
                  >
                    <p className="vl-u-hidden vl-u-visible--m">
                      {t(i18nKeys.planALeave.from)}
                    </p>
                    <Field
                      ariaLabel={`${t(i18nKeys.planALeave.from)} (${t(
                        i18nKeys.general.label.dateFormat,
                      )})`}
                      name="startDate"
                      column={{ width: '12' }}
                      labelColumn={{ width: '0', widthM: '12' }}
                      excludeDates={excludeDates}
                      inputOnChange={startDateChange}
                      component={SwInputCalendarField}
                      parse={SwInputCalendarFieldParseDate}
                      format={SwInputCalendarFieldFormatDate}
                      visualFormat={dateFormat}
                      modDisabled={loading}
                      minDate={SwInputCalendarFieldGetJsDate(minDate)}
                    />
                  </div>

                  <p
                    className="vl-u-hidden--m"
                    style={{ lineHeight: '3.5rem' }}
                  >
                    &nbsp;{t(i18nKeys.planALeave.to)}&nbsp;
                  </p>
                  <div
                    className="vl-col--6-12--m "
                    style={{ paddingLeft: '1rem', flexBasis: '45%' }}
                  >
                    <p className="vl-u-hidden vl-u-visible--m">
                      {t(i18nKeys.planALeave.to)} (
                      {t(i18nKeys.planALeave.included)})
                    </p>
                    <Field
                      ariaLabel={`${t(i18nKeys.planALeave.to)} (${t(
                        i18nKeys.general.label.dateFormat,
                      )}) ${t(i18nKeys.planALeave.included)}`}
                      name="endDate"
                      column={{ width: '12' }}
                      labelColumn={{ width: '0', widthM: '12' }}
                      excludeDates={excludeDates}
                      component={SwInputCalendarField}
                      parse={SwInputCalendarFieldParseDate}
                      format={SwInputCalendarFieldFormatDate}
                      visualFormat={dateFormat}
                      modDisabled={loading || !maxEndDate}
                      maxDate={
                        maxEndDate && maxEndDate < maxEndDateMax
                          ? maxEndDate
                          : maxEndDateMax
                      }
                      minDate={
                        values.startDate
                          ? SwInputCalendarFieldGetJsDate(values.startDate)
                          : SwInputCalendarFieldGetJsDate(minDate)
                      }
                    />
                  </div>
                  <p
                    className="vl-u-hidden--m"
                    style={{ lineHeight: '3.5rem' }}
                  >
                    &nbsp;{t(i18nKeys.planALeave.included)}
                  </p>
                </div>
              </SwColumn>
              <SwColumn width="2" widthM="12">
                <SwActionGroup modCollapseS>
                  <SwButton
                    type="submit"
                    modLoading={isSubmitting}
                    modDisabled={!(values.startDate && values.endDate)}
                  >
                    {t(i18nKeys.general.cta.save)}
                  </SwButton>
                </SwActionGroup>
              </SwColumn>
            </SwGrid>
          </SwForm>
        </>
      )}
    />
  );
};
