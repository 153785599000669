import { ServiceDelivery } from '@kaa/api/customers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
} from '@kaa/ui-flanders/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLoadingPageHeader, PageHeader } from '../../../../components';
import { useApi, useSelectedCustomerState } from '../../../../utils';
import { PrestationsWorkHistoryTable } from '../components/history-table/PrestationsWorkHistoryTable';

export const PrestationsWorkHistoryScreen = () => {
  const { t } = useTranslation();

  const customer = useSelectedCustomerState();
  const { customers } = useApi();

  const [
    { value: works, loading, error },
    getServicesHistory,
  ] = useAsyncCallback(
    async () => (await customers.getServicesHistory(customer.id)).data.data,
    [customers],
    { loading: true },
  );

  useEffect(() => {
    getServicesHistory();
  }, [getServicesHistory]);

  if (loading) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.navigation.worksHistory)}
        introduction={t(i18nKeys.worksHistory.introduction)}
        modFullwidth
      />
    );
  }

  if (error || !works) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getServicesHistory} />
      </SwContainer>
    );
  }

  return (
    <SwContainer>
      <SwGrid modStacked className="vl-u-flex-v-flex-start">
        <PageHeader
          title={t(i18nKeys.navigation.worksHistory)}
          introduction={t(i18nKeys.worksHistory.introduction)}
        />
        <PrestationsWorkHistoryTable works={works as ServiceDelivery[]} />
      </SwGrid>
    </SwContainer>
  );
};
