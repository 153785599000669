import React, {
  AllHTMLAttributes,
  createElement,
  ReactElement,
  Ref,
} from 'react';
import { mergeClassNames } from '../utilities';
import { SwTitle } from '../title/SwTitle';
import { SwPopover } from '../popover/SwPopover';
import { Icon } from '../icon/SwIcon.constants';

type DropdownNavigationProps<T = HTMLElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  label: string;
  titleSize?: string;
};

const getClasses = () => {
  return 'vl-dropdown-navigation';
};

export const SwDropdownNavigation = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      label,
      titleSize: title,
      className,
      children,
      ...rest
    }: DropdownNavigationProps<T>,
    ref: Ref<T>,
  ) => {
    const TagName = tagName || 'nav';
    const classes = getClasses();
    const titleSize = title || 'h2';

    return createElement(
      TagName,
      {
        className: mergeClassNames(className, classes),
        ref,
        ...rest,
      },
      <SwTitle tagName={titleSize} className="vl-dropdown-navigation__title">
        {label}
      </SwTitle>,
      <SwPopover
        icon={Icon.UP_DOWN_ARROWS}
        modRight
        basicLabel={{ hidden: true }}
      >
        {children as ReactElement}
      </SwPopover>,
    );
  },
);
