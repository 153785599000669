export const MYORDER_PAGE_SIZE = 20;

export enum ORDERS {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum OrderTypes {
  PENDING = 'PENDING',
  PURCHASE = 'PURCHASE',
}
