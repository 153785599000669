import { Customer } from '@kaa/api/customers';
import {
    email,
    equalToField,
    existsInError,
    isBic,
    isIban,
    isValidForeignIban,
    maxLength,
    notIf,
    onlyIf,
    required,
    ValidatorFunction,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
    AddressFieldNames,
    getDefaultAddressValidation,
} from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import get from 'lodash.get';
import { getConfig } from '../../../../common/config';
import { getPath, Routes } from '../../../routes';
import { createValidatorWithServerErrorHandled } from '../../../utils/validation';
import { ProfileScreenFieldNames } from './ProfileScreen.constants';

export const getRedirectUrlToInitiateEid = (): string => {
    const kaaConfig = getConfig();

    const idpBaseUrl = get(kaaConfig, 'app.auth.oidc.authority');
    const returnUrl = window.location.origin + getPath(Routes.ACCOUNT_PROFILE);
    return `${idpBaseUrl}/eid/initiate?returnUrl=${returnUrl}`;
};

const defaultDeliveryAddressValidation = getDefaultAddressValidation(
    'paperVoucherDeliveryAddress',
);

export const createValidate = (
    customer: Customer,
    isEidStreet: ValidatorFunction,
) =>
    createValidatorWithServerErrorHandled({
        birthDate: [],
        niss: [],
        phoneNumber: [maxLength(20)],
        mobilePhoneNumber: [maxLength(20)],
        'refundBankAccount.iban': [isIban],
        'refundBankAccount.bic': onlyIf(
            (bic, values) => isValidForeignIban(values.refundBankAccount?.iban),
            [required, isBic],
        ),
        ...emailValidation(customer),
        ...Object.entries(defaultDeliveryAddressValidation).reduce(
            (acc, [key, validators]) => ({
                ...acc,
                [key]: notIf('useMainAddressForPaperVoucherDelivery', validators),
            }),
            {},
        ),
        ...getDefaultAddressValidation('mainAddress', {
            street: [
                existsInError(
                    `${ProfileScreenFieldNames.MAIN_ADDRESS}.${AddressFieldNames.STREET}.notFound`,
                    {
                        id: (t: TFunction) =>
                            t(i18nKeys.profile.errors.form.eidStreetNotFound, {
                                phoneNumber: t(i18nKeys.bl.general.helpCenter.phone),
                            }),
                        defaultMessage:
                            'We did not find the address. Call our help center.',
                        displayFocussed: true,
                    },
                ),
                isEidStreet,
            ],
        }),
    });



export const createValidateEmail = (
    customer: Customer

) =>
    createValidatorWithServerErrorHandled(emailValidation(customer));

const emailValidation = (customer: Customer) => {
    return {
        emailAddress: [email],
        confirmEmailAddress: notIf(
            (_, values) =>
                values.emailAddress && values.emailAddress !== customer.emailAddress,
            [
                required,
                existsInError(
                    `${ProfileScreenFieldNames.CONFIRM_EMAIL_ADDRESS}.notPasted`,
                    {
                        id: i18nKeys.errors.form.noPaste,
                        defaultMessage: 'You are not allowed to paste into this field',
                        displayFocussed: true,
                    },
                ),
                equalToField(ProfileScreenFieldNames.EMAIL_ADDRESS, {
                    defaultMessage: 'Must be equal',
                    id: i18nKeys.errors.form.equal,
                    displayFocussed: false,
                    values: (t: TFunction) => ({
                        value: t(i18nKeys.general.label.emailAddress),
                    }),
                }),
            ],
        )
    }
}
