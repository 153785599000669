import { DependencyList, useEffect } from 'react';
import { useAsyncCallback } from './useAsyncCallback';

export function useAsync<Result = any>(
  fn: () => Promise<Result>,
  deps: DependencyList = [],
) {
  const [state, callback] = useAsyncCallback<Result>(fn, deps, {
    loading: true,
  });

  useEffect(() => {
    callback();
  }, [callback]);

  return state;
}
