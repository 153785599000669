import React from 'react';
import { SwPager } from '../pager/SwPager';
import { SwPagerBounds } from '../pager/SwPagerBounds';
import { PagerItemType, SwPagerItem } from '../pager/SwPagerItem';
import { SwPagerPage } from '../pager/SwPagerPage';
import {
  getFromIndex,
  getPages,
  getToIndex,
  getTotalPages,
} from './SwPaginator.utils';

export type PaginatorProps = {
  align?: 'left' | 'center' | 'right';
  itemCount?: number;
  pageSize?: number;
  selectedPage?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const SwPaginator = ({
  align = 'right',
  itemCount = 0,
  pageSize = 5,
  selectedPage = 1,
  setPage,
}: PaginatorProps) => {
  if (itemCount <= pageSize) {
    return null;
  }

  const fromItemIndex = getFromIndex(selectedPage, pageSize);
  const toItemIndex = getToIndex(selectedPage, pageSize, itemCount);
  const totalPages = getTotalPages(itemCount, pageSize);
  const pages = getPages(selectedPage, totalPages);
  const firstPage = pages[0];
  const lastPage = pages[pages.length - 1];

  return (
    <SwPager modAlign={align}>
      <SwPagerBounds from={fromItemIndex} to={toItemIndex} total={itemCount} />
      {selectedPage > 1 && (
        <SwPagerItem
          type={PagerItemType.PREVIOUS}
          onClick={() => setPage((page) => page - 1)}
        />
      )}
      {firstPage > 1 && (
        <SwPagerPage
          page={1}
          modActive={selectedPage === 1}
          onClick={() => setPage(() => 1)}
        />
      )}
      {firstPage > 2 && (
        <li className="vl-pager__element">
          <span className="vl-pager__element__cta">...</span>
        </li>
      )}
      {pages.map((page) => (
        <SwPagerPage
          key={`p${page}`}
          page={page}
          modActive={page === selectedPage}
          onClick={() => setPage(() => page)}
        />
      ))}
      {lastPage < totalPages - 1 && (
        <li className="vl-pager__element">
          <span className="vl-pager__element__cta">...</span>
        </li>
      )}
      {lastPage < totalPages && (
        <SwPagerPage
          page={totalPages}
          modActive={totalPages === selectedPage}
          onClick={() => setPage(() => totalPages)}
        />
      )}
      {selectedPage < itemCount / pageSize && (
        <SwPagerItem
          type={PagerItemType.NEXT}
          onClick={() => setPage((page) => page + 1)}
        />
      )}
    </SwPager>
  );
};
