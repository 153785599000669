import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import Popper from 'popper.js';
import React, { AllHTMLAttributes, createElement } from 'react';
import { dataTest } from '../../../datatest/keys';
import { useTooltip } from '../../tooltip/useTooltip';
import { mergeClassNames } from '../../utilities';

type SwProgressBarStepProps = AllHTMLAttributes<HTMLButtonElement> &
  Classes & {
    tagName?: string;
    label: string;
    placement?: Popper.Placement;
  };

type Classes = {
  modActive?: boolean;
  modTooltipActive?: boolean;
};

const getClasses = ({ modActive }: Classes) => {
  return [
    'vl-progress-bar__step',
    modActive ? 'vl-progress-bar__step--active' : '',
  ].join(' ');
};

export const SwProgressBarStep = ({
  tagName,
  modActive,
  modTooltipActive,
  children,
  className,
  label,
  placement,
  ...rest
}: SwProgressBarStepProps) => {
  const TagName = tagName || 'div';

  const tooltipRef = useTooltip<HTMLButtonElement>({
    value: label,
    modActive: modTooltipActive || modActive,
    placement,
  });

  const classes = getClasses({ modActive });

  return createElement(
    TagName,
    { className: mergeClassNames(classes) },
    <button
      {...rest}
      ref={tooltipRef}
      className="vl-progress-bar__bullet"
      data-testid={dynamicDataTest(dataTest.SwProgressBarStep, { label })}
      type="button"
      aria-label={label}
      aria-current={modActive ? 'step' : undefined}
    >
      <span className="vl-u-visually-hidden">{label}</span>
    </button>,
  );
};
