import { Vacancy, VacancyActionType } from '@kaa/api/customers';
import { ActionsHandler } from '@kaa/common/handlers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwAlert, SwDataTable } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { PlanALeaveTableRow } from './PrestationsPlanALeaveTableRow';

type PlanALeaveTableProps = {
  deleteVacancy: (
    vacancy: Vacancy,
  ) => Promise<[Error | undefined, any | undefined]>;
  vacancies: Vacancy[];
};

export const PlanALeaveTable = ({
  vacancies,
  deleteVacancy,
}: PlanALeaveTableProps) => {
  const { t } = useTranslation();

  if (!vacancies.length) {
    return (
      <SwAlert
        icon={Icon.INFO_CIRCLE}
        content={t(i18nKeys.planALeave.noVacanciesContent)}
        title={t(i18nKeys.planALeave.noVacanciesTitle)}
      />
    );
  }

  return (
    <div className="vl-u-table-overflow">
      <SwDataTable modCollapsedS>
        <thead data-testid={dataTest.worksManagement.history.tableHead}>
          <tr>
            <th>{t(i18nKeys.planALeave.table.dates)}</th>
            <th>{t(i18nKeys.planALeave.table.duration)}</th>
            <th />
          </tr>
        </thead>
        <tbody data-testid={dataTest.worksManagement.history.tableBody}>
          {vacancies.map((vacancy, i) => (
            <ActionsHandler
              resourceId={vacancy.resourceId}
              actions={[VacancyActionType.CAN_DELETE]}
              key={`${vacancy.startDate}-${vacancy.endDate}-${i}`}
            >
              {({ isAllowed: deleteIsAllowed }) => (
                <PlanALeaveTableRow
                  deleteVacancy={deleteVacancy}
                  deleteIsAllowed={deleteIsAllowed}
                  vacancy={vacancy}
                />
              )}
            </ActionsHandler>
          ))}
        </tbody>
      </SwDataTable>
    </div>
  );
};
