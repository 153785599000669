import { useEffect } from 'react';

type Binding = {
  value?: any;
  modifiers?: { [key: string]: any };
};

const getBreakpoint = () =>
  window
    .getComputedStyle(document.body, ':before')
    .getPropertyValue('content')
    .replace(/"/g, '');

export const useBreakpoint = (binding: Binding) => {
  useEffect(() => {
    const resizeHandler = (
      points: any,
      bpCallback = (arg?: any) => undefined,
    ) => () => {
      const bp = getBreakpoint();
      if (points.includes(bp)) {
        bpCallback();
      } else if (points.length < 1) {
        bpCallback(bp);
      }
    };

    const points = Object.keys(binding.modifiers || {});
    const handler = resizeHandler(points, binding.value);

    handler();

    window.addEventListener(
      'resize',
      handler,
      50 as any, // Flanders code, not typescript ready
    );

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [binding]);
};
