import { AxiosResponse } from 'axios';

/**
 * It takes a promise and returns a promise
 * @param promise - Promise<T>
 */
export const toAxiosResponse = <T>(promise: Promise<T>) =>
  promise.then((data) => ({
    data,
  })) as Promise<AxiosResponse<T>>;

/**
 * Wait a random amount of time between 100 and 500 milliseconds, then resolve the promise with the
 * given data.
 * @param {T} [data] - The data to be returned by the mocked function.
 */
export const mockedDelay = async <T = unknown>(data?: T): Promise<T> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, Math.random() * 400 + 100);
  });
