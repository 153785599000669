import React, { AllHTMLAttributes } from 'react';
import { SwFetchErrorMessage } from '../fetch-error-message/SwFetchErrorMessage';
import { SwLoader } from '../loader/SwLoader';
import { SwColumn } from './SwColumn';
import { SwGrid } from './SwGrid';
import { SwLayout } from './SwLayout';
import { SwRegion } from './SwRegion';

type ContainerProps<T = HTMLElement> = AllHTMLAttributes<T> & {
  error?: boolean | undefined;
  loading?: boolean | undefined;
  children?: React.ReactNode | undefined;
};

export const SwContainer = <T extends HTMLElement>({
  error,
  loading,
  children,
}: ContainerProps<T>) => {
  if (error) {
    const errorChildren = children || (
      <SwFetchErrorMessage
        onClick={() => {
          window.location.reload();
        }}
      />
    );

    return (
      <SwRegion>
        <SwLayout>
          <SwGrid modStacked modVCenter>
            <SwColumn>
              <SwGrid modStacked>
                <SwColumn width="12" style={{ padding: '15% 3rem' }}>
                  {errorChildren}
                </SwColumn>
              </SwGrid>
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </SwRegion>
    );
  }

  if (loading) {
    const loadingChildren = children || <SwLoader />;

    return (
      <SwRegion>
        <SwLayout>
          <SwGrid modStacked modVCenter>
            <SwColumn>{loadingChildren}</SwColumn>
          </SwGrid>
        </SwLayout>
      </SwRegion>
    );
  }

  return (
    <SwRegion>
      <SwLayout>{children}</SwLayout>
    </SwRegion>
  );
};
