import { AuthAction, useAuthDispatch, useAuthState } from '@kaa/auth/common';
import { useAsync, useAsyncCallback } from '@kaa/common/utils';
import { useEffect } from 'react';
import { AuthContext } from '../../types';

export const LoadAuthUser = () => {
  const dispatch = useAuthDispatch();
  const {
    getUser,
    registerOnUserLoaded,
    unregisterOnUserLoaded,
  } = useAuthState<AuthContext>();

  const [{ value: authUser }, executeGetUser] = useAsyncCallback(getUser, [
    getUser,
  ]);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    dispatch({
      type: AuthAction.UPDATE,
      payload: {
        authUser,
      },
    });
  }, [authUser]);

  useAsync(executeGetUser, [executeGetUser]);

  useEffect(() => {
    registerOnUserLoaded(executeGetUser);
    return () => unregisterOnUserLoaded(executeGetUser);
  }, [registerOnUserLoaded, unregisterOnUserLoaded, executeGetUser]);

  return null;
};
