import faker from 'faker';
import {
  GetLocationsSuggestionsParams,
  Street,
  StreetsResponse,
} from '../model';

export const getStreet = (postcode?: string): Street => ({
  id: faker.random.number(99999999),
  name: faker.address.streetName(),
  postcode: postcode || '1000',
  city: faker.address.city(),
  country: 'Belgium',
});

export const getLocationsSuggestionsResponse = (
  params?: GetLocationsSuggestionsParams,
): StreetsResponse => {
  return { data: [...Array(50)].map(() => getStreet(params?.postcode)) };
};
