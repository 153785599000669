import { SupportType } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwColumn,
  SwFormColumn,
  SwFormInput,
  SwGrid,
  SwRadio,
} from '@kaa/ui-flanders/components';
import { RequiredStar } from '@kaa/ui-flanders/components/form-message/SwFormRequiredStar';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ExchangeModalSwitchProps = {
  supportType: SupportType;
  canExchangeElectronic: boolean;
  setSupportType: (supportType: SupportType) => void;
  setTargetSupportType: (targetSupportType: SupportType) => void;
};

export const ExchangeModalSwitch = ({
  supportType,
  setSupportType,
  setTargetSupportType,
  canExchangeElectronic = true,
}: ExchangeModalSwitchProps) => {
  const { t } = useTranslation();
  return (
    <SwGrid className="vl-u-spacer--medium">
      <SwColumn>
        <p className="vl-form__label">{t(i18nKeys.exchange.label.title)}</p>
      </SwColumn>
      <SwColumn width="6">
        <fieldset className="vl-u-spacer--small">
          <legend
            className="vl-form__label vl-u-spacer--xsmall"
            style={{ width: '100%' }}
          >
            {t(i18nKeys.exchange.label.voucherType)}
            <RequiredStar />
          </legend>

          {canExchangeElectronic ? (
            <>
              <SwFormColumn>
                <SwFormInput>
                  <SwRadio
                    name="supportType"
                    id={`from-${SupportType.ELECTRONIC}`}
                    defaultChecked={supportType === SupportType.ELECTRONIC}
                    onChange={() => {
                      setSupportType(SupportType.ELECTRONIC);
                      setTargetSupportType(SupportType.ELECTRONIC);
                    }}
                  >
                    {t(i18nKeys.exchange.radioLabel.old.electronic)}
                  </SwRadio>
                </SwFormInput>
              </SwFormColumn>
              <SwFormColumn>
                <SwFormInput>
                  <SwRadio
                    name="supportType"
                    id={`from-${SupportType.PAPER}`}
                    defaultChecked={supportType === SupportType.PAPER}
                    onChange={() => setSupportType(SupportType.PAPER)}
                  >
                    {t(i18nKeys.exchange.radioLabel.old.paper)}
                  </SwRadio>
                </SwFormInput>
              </SwFormColumn>
            </>
          ) : (
            <>
              <SwFormColumn>
                <SwFormInput>
                  <SwRadio
                    name="supportType"
                    id={`from-${SupportType.ELECTRONIC}`}
                    className="vl-radio--disabled"
                    disabled
                  >
                    <span style={{ color: '#b2b2b2' }}>
                      {t(i18nKeys.exchange.radioLabel.old.electronic)}
                    </span>
                    <br />
                    <span
                      style={{ color: '#b2b2b2' }}
                      className="vl-u-text--small"
                    >
                      ({t(i18nKeys.exchange.radioLabel.old.noElectronic)})
                    </span>
                  </SwRadio>
                </SwFormInput>
              </SwFormColumn>
              <SwFormColumn>
                <SwFormInput>
                  <SwRadio
                    name="supportType"
                    id={`from-${SupportType.PAPER}`}
                    defaultChecked
                  >
                    {t(i18nKeys.exchange.radioLabel.old.paper)}
                  </SwRadio>
                </SwFormInput>
              </SwFormColumn>
            </>
          )}
        </fieldset>
      </SwColumn>
      <SwColumn width="6">
        <fieldset className="vl-u-spacer--small">
          <legend
            className="vl-form__label vl-u-spacer--xsmall"
            style={{ width: '100%' }}
          >
            {t(i18nKeys.exchange.label.newVoucherType)}
          </legend>
          <SwFormColumn>
            <SwFormInput>
              <SwRadio
                name="toSupportType"
                id={`to-${SupportType.ELECTRONIC}`}
                defaultChecked
                onChange={() => {
                  setTargetSupportType(SupportType.ELECTRONIC);
                }}
              >
                {t(i18nKeys.exchange.radioLabel.new.electronic)}
              </SwRadio>
            </SwFormInput>
          </SwFormColumn>
          <SwFormColumn>
            <SwFormInput>
              <SwRadio
                name="toSupportType"
                id={`to-${SupportType.PAPER}`}
                onChange={() => {
                  setTargetSupportType(SupportType.PAPER);
                }}
              >
                <span>{t(i18nKeys.exchange.radioLabel.new.paper)}</span>
              </SwRadio>
            </SwFormInput>
          </SwFormColumn>
        </fieldset>
      </SwColumn>
    </SwGrid>
  );
};
