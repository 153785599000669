import {
  ProfileFormState,
  ProfileUserInfoChangeEmailFormState,
  ProfileUserInfoChangePasswordFormState,
} from './ProfileScreen.type';

export const ProfileScreenFieldNames: {
  REFUND_BANK_ACCOUNT: {
    IBAN: 'refundBankAccount.iban';
    BIC: 'refundBankAccount.bic';
  };
  PHONE_NUMBER: keyof ProfileFormState;
  MOBILE_PHONE_NUMBER: keyof ProfileFormState;
  EMAIL_ADDRESS: keyof ProfileFormState;
  CONFIRM_EMAIL_ADDRESS: keyof ProfileFormState;
  LANGUAGE: keyof ProfileFormState;
  FIRSTNAME: keyof ProfileFormState;
  LASTNAME: keyof ProfileFormState;
  BIRTHDATE: keyof ProfileFormState;
  NISS: keyof ProfileFormState;
  MAIN_ADDRESS: keyof ProfileFormState;
  USE_MAIN_ADDRESS_FOR_PAPER_VOUCHER_DELIVERY: keyof ProfileFormState;
} = {
  REFUND_BANK_ACCOUNT: {
    IBAN: 'refundBankAccount.iban',
    BIC: 'refundBankAccount.bic',
  },
  PHONE_NUMBER: 'phoneNumber',
  MOBILE_PHONE_NUMBER: 'mobilePhoneNumber',
  EMAIL_ADDRESS: 'emailAddress',
  CONFIRM_EMAIL_ADDRESS: 'confirmEmailAddress',
  LANGUAGE: 'language',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  BIRTHDATE: 'birthDate',
  NISS: 'niss',
  MAIN_ADDRESS: 'mainAddress',
  USE_MAIN_ADDRESS_FOR_PAPER_VOUCHER_DELIVERY:
    'useMainAddressForPaperVoucherDelivery',
};

export const ProfileScreenChangePasswordFieldNames: {
  CONFIRM_NEW_PASSWORD: keyof ProfileUserInfoChangePasswordFormState;
  NEW_PASSWORD: keyof ProfileUserInfoChangePasswordFormState;
  OLD_PASSWORD: keyof ProfileUserInfoChangePasswordFormState;
} = {
  CONFIRM_NEW_PASSWORD: 'confirmNewPassword',
  NEW_PASSWORD: 'newPassword',
  OLD_PASSWORD: 'oldPassword',
};
export const ProfileScreenChangeEmailFieldNames: {
  EMAIL_ADDRESS: keyof ProfileUserInfoChangeEmailFormState;
  CONFIRM_EMAIL_ADDRESS: keyof ProfileUserInfoChangeEmailFormState;
} = {
  EMAIL_ADDRESS: 'emailAddress',
  CONFIRM_EMAIL_ADDRESS: 'confirmEmailAddress',
};
