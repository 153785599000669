import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type IntroductionProps<T = HTMLParagraphElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  modBold?: boolean;
};

type Classes = {
  modBold?: boolean;
};
const getClasses = ({ modBold }: Classes) => {
  return ['vl-introduction', modBold ? 'vl-introduction--bold' : ''].join(' ');
};

export const SwIntroduction = <T extends HTMLElement>({
  tagName,
  modBold,
  className,
  children,
  ...rest
}: IntroductionProps<T>) => {
  const TagName = tagName || 'p';

  const classes = getClasses({
    modBold,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
