import { ActionsHandler } from '@kaa/common/handlers';
import { ValueOf } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/common/keys';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SwFormColumn } from '../../core';
import { Column } from '../../core/SwColumn';
import { AutocompleteInputField } from '../field/autocomplete/AutocompleteField';
import { SwInputTextField } from '../field/input/SwInputField';
import { AddressFieldNames } from './SwFormAddress.constant';
import { City, Street } from './SwFormAddress.type';
import {
  getAutocompletePostcodeOption,
  getAutocompleteStreetOption,
  onSelectPostcodeOption,
  onSelectStreetOption,
} from './SwFormAddress.utils';

type SwFormAddressProps = {
  availableCities?: City[];
  fieldNamePrefix: string;
  resourceId?: string;
  actions?: { [key in ValueOf<typeof AddressFieldNames>]?: string };
  modDisabled?: boolean;
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
  column?: Column;
  labelColumn?: Column;
  modRequired?: AddressFieldNames[];
};

export const SwFormAddress = ({
  availableCities = [],
  fieldNamePrefix,
  resourceId,
  actions = {},
  modDisabled = false,
  getStreets,
  column,
  labelColumn,
  modRequired = [],
}: SwFormAddressProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SwFormColumn>
        <ActionsHandler
          resourceId={resourceId}
          actions={
            actions[AddressFieldNames.POSTCODE]
              ? [actions[AddressFieldNames.POSTCODE] as string]
              : []
          }
        >
          {({ isAllowed }) => (
            <Field
              name={`${fieldNamePrefix}.${AddressFieldNames.POSTCODE}`}
              component={AutocompleteInputField}
              column={column}
              labelColumn={labelColumn}
              label={t(i18nKeys.general.label.postcode)}
              getOptions={getAutocompletePostcodeOption(availableCities)}
              onSelectOption={onSelectPostcodeOption({
                fieldNamePrefix,
                getStreets,
              })}
              menuOptionFormat={({
                postcode,
                names,
              }: {
                postcode: string;
                names: string[];
              }) => `${postcode} ${names.join(', ')}`}
              valueExtractor={({ postcode }: City) => postcode}
              modDisabled={!isAllowed || modDisabled}
              autoComplete="off"
              modRequired={modRequired.includes(AddressFieldNames.POSTCODE)}
            />
          )}
        </ActionsHandler>
      </SwFormColumn>
      <SwFormColumn>
        <ActionsHandler
          resourceId={resourceId}
          actions={
            actions[AddressFieldNames.CITY]
              ? [actions[AddressFieldNames.CITY] as string]
              : []
          }
        >
          {({ isAllowed }) => (
            <Field
              name={`${fieldNamePrefix}.${AddressFieldNames.CITY}`}
              label={t(i18nKeys.general.label.city)}
              component={SwInputTextField}
              column={column}
              labelColumn={labelColumn}
              modDisabled={
                typeof actions[AddressFieldNames.CITY] !== 'undefined'
                  ? !isAllowed
                  : true
              }
              modRequired={modRequired.includes(AddressFieldNames.CITY)}
            />
          )}
        </ActionsHandler>
      </SwFormColumn>
      <SwFormColumn>
        <ActionsHandler
          resourceId={resourceId}
          actions={
            actions[AddressFieldNames.STREET]
              ? [actions[AddressFieldNames.STREET] as string]
              : []
          }
        >
          {({ isAllowed }) => (
            <Field
              name={`${fieldNamePrefix}.${AddressFieldNames.STREET}`}
              component={AutocompleteInputField}
              label={t(i18nKeys.general.label.street)}
              getOptions={getAutocompleteStreetOption({
                fieldNamePrefix,
                getStreets,
              })}
              column={column}
              labelColumn={labelColumn}
              menuOptionFormat={({ name }: Street) => name}
              onSelectOption={onSelectStreetOption(fieldNamePrefix)}
              valueExtractor={({ name }: Street) => name}
              modDisabled={!isAllowed || modDisabled}
              autoComplete="off"
              modRequired={modRequired.includes(AddressFieldNames.STREET)}
            />
          )}
        </ActionsHandler>
      </SwFormColumn>
      <SwFormColumn>
        <ActionsHandler
          resourceId={resourceId}
          actions={
            actions[AddressFieldNames.HOUSE_NUMBER]
              ? [actions[AddressFieldNames.HOUSE_NUMBER] as string]
              : []
          }
        >
          {({ isAllowed }) => (
            <Field
              name={`${fieldNamePrefix}.${AddressFieldNames.HOUSE_NUMBER}`}
              label={t(i18nKeys.general.label.number)}
              component={SwInputTextField}
              column={column}
              labelColumn={labelColumn}
              modDisabled={!isAllowed || modDisabled}
              modRequired={modRequired.includes(AddressFieldNames.HOUSE_NUMBER)}
            />
          )}
        </ActionsHandler>
      </SwFormColumn>
    </>
  );
};
