import { CustomerActionType, CustomerLanguages } from '@kaa/api/customers';
import { ActionsHandler } from '@kaa/common/handlers';
import { Mask } from '@kaa/common/utils';
import { setError } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
    Icon,
    SwAlert,
    SwColumn,
    SwFormColumn,
    SwFormGrid,
    SwInputTextField,
    SwLink,
    SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MotionDiv } from '../../../../components/animation/MotionDiv';
import { ProfileScreenFieldNames } from '../ProfileScreen.constants';

type ProfileFormCustomerContactInfoProps = {
    isConfirmEmailAddress: boolean;
    setFieldValue: (field: string, value: any) => void;
    userEmailAddress: string;
    customerAvailableLanguages: CustomerLanguages[];
    customerResourceId: string;
};

const gridMargin = window.innerWidth < 767 ? '1.5rem' : '3rem';
const toggleEmailVariants = {
    close: {
        height: 0,
        overflow: 'hidden',
        marginBottom: 0,
        marginTop: 0,
        minWidth: `calc(100% + ${gridMargin})`,
    },
    open: {
        height: 'auto',
        overflow: 'hidden',
        marginTop: 15,
        transitionEnd: {
            overflow: 'inherit',
        },
    },
};



export const ProfileCustomerEmailInfoForm = ({
    isConfirmEmailAddress,
    setFieldValue,
    userEmailAddress,
}: ProfileFormCustomerContactInfoProps) => {
    const { t } = useTranslation();
    const [isEqualToUserEmailAddress, setIsEqualToUserEmailAddress] = useState(
        false,
    );

    return (
        <div >
            <Field
                name={ProfileScreenFieldNames.EMAIL_ADDRESS}
                label={t(i18nKeys.general.label.emailAddress)}
                component={SwInputTextField}
                type="email"
                autoComplete="email"
                inputOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === userEmailAddress) {
                        setIsEqualToUserEmailAddress(true);
                    } else {
                        setIsEqualToUserEmailAddress(false);
                    }
                }}
            />

            <MotionDiv
                className="vl-grid vl-u-spacer"
                initial="close"
                isOpen={isConfirmEmailAddress}
                variants={toggleEmailVariants}
            >
                <Field
                    className="vl-u-spacer--xsmall"
                    name={ProfileScreenFieldNames.CONFIRM_EMAIL_ADDRESS}
                    label={t(i18nKeys.profile.emailAddressConfirmation)}
                    component={SwInputTextField}
                    type="email"
                    onPaste={(e: ClipboardEvent) => {
                        e.preventDefault();
                        setError({
                            setFieldValue,
                            name: `${ProfileScreenFieldNames.CONFIRM_EMAIL_ADDRESS}.notPasted`,
                        });
                    }}
                />

                {!isEqualToUserEmailAddress && (
                    <SwFormColumn width="12">
                        <SwFormGrid>
                            <SwColumn width="3" />
                            <SwColumn width="9">
                                <SwAlert
                                    style={{ width: '100%' }}
                                    modNaked
                                    icon={Icon.INFO}
                                    modSmall
                                    title={t(i18nKeys.profile.contactMe.changeEmailInfo.title)}
                                >
                                    <SwLink
                                        onClick={() => {
                                            setFieldValue(
                                                ProfileScreenFieldNames.EMAIL_ADDRESS,
                                                userEmailAddress,
                                            );
                                            setFieldValue(
                                                ProfileScreenFieldNames.CONFIRM_EMAIL_ADDRESS,
                                                userEmailAddress,
                                            );
                                            setIsEqualToUserEmailAddress(true);
                                        }}
                                    >
                                        {t(i18nKeys.profile.contactMe.changeEmailInfo.subtitle, {
                                            userEmailAddress,
                                        })}
                                    </SwLink>
                                </SwAlert>
                            </SwColumn>
                        </SwFormGrid>
                    </SwFormColumn>
                )}
            </MotionDiv>

        </div>
    );
};
