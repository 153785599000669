import { CustomerActionType, RegionCode } from '@kaa/api/customers';
import { useActionsHandler } from '@kaa/common/handlers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwContainer } from '@kaa/ui-flanders/components';
import { navigate, RouteComponentProps } from '@reach/router';
import get from 'lodash.get';
import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../common/config';
import { PageHeader } from '../../components';
import { REGION_HELP_CENTER_NUMBER } from '../../constants';
import { getPath, Routes } from '../../routes';
import { useSelectedCustomerState } from '../../utils';
import { BlockedCustomerContractsList } from './components/BlockedCustomerContractsList';

export const BlockedScreen = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const selectedCustomer = useSelectedCustomerState();

  const isAllowed = useActionsHandler(selectedCustomer.resourceId, [
    CustomerActionType.CAN_NAVIGATE,
  ]);

  useLayoutEffect(() => {
    if (isAllowed) {
      navigate(getPath(Routes.DASHBOARD));
    }
  }, [isAllowed]);

  return (
    <SwContainer error>
      <PageHeader
        title={t(i18nKeys.blocked.title)}
        introduction={t(i18nKeys.blocked.introduction, {
          phoneNumber: t(REGION_HELP_CENTER_NUMBER[regionCode]),
        })}
        className="vl-u-spacer--medium"
      />
      <BlockedCustomerContractsList />
    </SwContainer>
  );
};
