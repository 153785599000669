import { RouteComponentProps } from '@reach/router';
import React, { ReactNode } from 'react';

type PrestationsScreenProps = {
  children?: ReactNode;
};

export const PrestationsScreen = ({
  children,
}: RouteComponentProps<PrestationsScreenProps>) => {
  return <>{children}</>;
};
