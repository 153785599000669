import { StorageKeys } from '@kaa/common/enums';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwAlert, SwLink } from '@kaa/ui-flanders/components';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { getPath, Routes } from '../../routes';

type DashboardAlertFiscalAttestationProps = {
  availableYear: number;
};

export const DashboardAlertFiscalAttestation = ({
  availableYear,
}: DashboardAlertFiscalAttestationProps) => {
  const { t } = useTranslation();

  const [display, setDisplay] = useState(true);

  const currentYear = DateTime.local()
    .startOf('year')
    .get('year');

  const lastSeenYear =
    localStorage.getItem(StorageKeys.LAST_SEEN_TAXATION_YEAR) || '';

  if (
    !display ||
    availableYear !== currentYear ||
    parseInt(lastSeenYear, 10) === availableYear
  ) {
    return null;
  }

  return (
    <SwAlert
      icon={Icon.CHECK_CIRCLE}
      modSuccess
      title={t(i18nKeys.alert.success.fiscalAttestation.title)}
      closable
      close={() => {
        setDisplay(false);
        localStorage.setItem(
          StorageKeys.LAST_SEEN_TAXATION_YEAR,
          String(availableYear),
        );
      }}
    >
      {t(i18nKeys.alert.success.fiscalAttestation.body)}{' '}
      <SwLink
        data-testid={dataTest.dashboard.alerts.fiscalAttestation}
        to={getPath(Routes.ACCOUNT_FISCAL_ATTESTATION)}
      >
        {t(i18nKeys.alert.success.fiscalAttestation.cta)}
      </SwLink>
    </SwAlert>
  );
};
