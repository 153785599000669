/* https://wiki.sodexo.be/display/PROJECTAAH/Data-testid */
/* Nomenclature for data-testid for Sodexo
   The data-testid is splitted in 3 parts: ComponentName-tagName-action or description of the element. */

export const dataTest = {
  header: {
    Dashboard: 'Swlink-a-dashboardLink',
    backHome: 'SwTab-a-backhome',
    backToPublicServiceVouchersLogin: 'SwTab-a-backToServiceVouchers',
    backToServiceVouchersLogin: 'SwTab-a-backToServiceVouchersLogin',
    linkWallet: 'SwTab-a-wallet',
    linkMyOrder: 'SwTab-a-myorder',
    linkAccount: 'SwLink-a-account',
    linkPreferences: 'SwLink-a-preferences',
    linkFiscalAttestation: 'SwLink-a-fiscalAttestation',
    linkLeave: 'SwLink-a-leave',
    linkPrestationManagement: 'SwLink-a-prestationManagement',
    linkPrestationManagementHistory: 'SwLink-a-prestationManagementHistory',
    linkOrderVouchers: 'SwLink-a-orderVouchers',
    linkLogout: 'Swlink-a-logout',
    support: 'SwButton-a-logout',
    tabAccount: 'SwTab-a-account',
    tabPreferences: 'SwTab-a-preferences',
    tabFiscalAttestation: 'SwTab-a-fiscalAttestation',
    tabPrestationManagement: 'SwTab-a-prestationManagement',
    tabPrestationManagementHistory: 'SwTab-a-prestationManagementHistory',
    tabLeave: 'SwTab-a-tabLeave',
    tabWallet: 'SwTab-a-wallet',
    tabMyOrder: 'SwTab-a-myorder',
    tabLanguageRequest: 'SwTab-a-languageRequest',
    tabHandiwebRequest: 'SwTab-a-handiwebRequest',
    tabRegister: 'SwTab-a-register',
    toggleDashboard: 'toggleDashBoard',
    toggleAccount: 'toggleAccount',
    togglePrestation: 'togglePrestation',
    toggleServicesVouchers: 'toggleServicesVouchers',
    toggleProfile: 'toggleProfiles',
  },
  landing: {
    linkSignIn: 'SwButton-a-signIn',
    linkSignUp: 'SwButton-a-signUp',
  },
  dashboard: {
    alerts: {
      fiscalAttestation: 'SwLink-a-fiscalAttestation-seeAttestation',
      notEnoughVouchers: 'SwLink-a-notEnoughVouchers-orderVouchers',
      switchFromPaper: 'SwButton-button-switchFromPaper',
      switchFromPaperInfo: 'SwLink-a-switchFromPaper-info',
    },
    cards: {
      workPending: 'Dashboard-card-workPending',
      vouchersStatus: 'Dashboard-card-vouchersStatus',
      wallet: 'Dashboard-card-wallet',
    },
  },
  profile: {
    changeEmailModal: {
      formChangeEmail: 'SwForm-form-loginEmailForm',
      oldEmailAdress: 'SwFormColumn-div-oldEmailAddress',
      newEmailAddress: 'SwInputTextField-input-loginEmailAddress',
      confirmEmailAddress: 'SwInputTextField-input-loginConfirmEmailAddress',
      submitChangeEmailModal: 'SwButton-button-submitNewLoginEmailAddress',
    },
    changePasswordModal: {
      formChangePassword: 'confirmPasswordModalForm',
      linkForgotPassword: 'SwLink-a-forgotPassword',
      submitChangePasswordModal: 'SwButton-button-comfirmPasswordsubmit',
    },
    changeRegionModal: {
      confirmChangeEmailModal: 'SwButton-button-confirmChangeEmailModal',
      cancelChangeEmailModal: 'SwButton-button-cancelChangeEmailModal',
    },
    deliveryAddressCard: 'card-div-deliveryAddress',
    saveProfileButton: 'SwButton-button-saveProfile',
    requestMandateBtn: 'SwButton-button-requestMandate',
    adjustInfoEReader: 'SwButton-button-AdjustInfoWithEIDReader',
  },
  preferences: {
    supportType: 'SupportType-#{type}',
    savePreferencesButton: 'SwButton-button-savePreferences',
  },
  fiscalAttestation: {
    attestation: 'SwLink-a-fiscalAttestation-attestation-#{file}',
    taxonweb: 'SwLink-a-fiscalAttestation-openTaxOnWeb',
    listDetails: 'list-fiscalAttestation',
  },
  worksManagement: {
    showHistory: 'SwLink-a-showHistory',
    history: {
      tableHead: 'SwDataTable-thead-worksManagement-history',
      tableBody: 'SwDataTable-tbody-worksManagement-history',
      table: 'SwDataTable-table-worksManagement-history',
    },
    workFollowUp: {
      downloadXLSTemplate: 'SwDocument-div-downloadXLSTemplate',
    },
  },
  myorders: {
    myordersTitle: 'SwTitle-h1-myOrdersTitle',
    alertAvailableVouchers: 'SwInfoTile-div-alertAvailableVouchers',
    buyNewVouchers: 'SwButton-button-buyNewVouchers',
    askRefundVouchers: 'SwButton-button-askRefundVouchers',
    askExchangeVouchers: 'SwButton-button-askExchangeVouchers',
    previousPager: 'pager-a-previous',
    nextPager: 'pager-a-next',
    tableHead: 'SwDataTable-th-tableHead',
    tableBody: 'SwDataTable-th-tableBody',
    tableRows: 'SwDataTable-tr-tableRow-#{rowI}',
    tableSort: 'SwDataTable-button-sortTable',
    showDetail: 'SwLink-a-showOrderDetail',
    details: {
      myordersDetailsTitle: 'SwTitle-h1-myOrdersDetailsTitle',
      alert: 'SwAlert-div-alertMessage',
    },
  },
  wallet: {
    buyNewVouchers: 'SwButton-button-buyNewVouchers',
    askRefundVouchers: 'SwButton-button-askRefundVouchers',
    askExchangeVouchers: 'SwButton-button-askExchangeVouchers',
    vouchersTable: 'SwTable-table-vouchersTable',
    availableVouchers: 'SwInfoTile-div-availableVouchers',
    tableCaptionTitle: 'SwDataTable-caption-captionTitle',
    tableHeader: 'SwDataTable-tr-tableHeader',
    tableHeaderLabels: 'SwDataTable-th-tableHeader-#{i}',
    tableRows: 'SwDataTable-tr-tableRow-#{rowI}',
    tableRowsLabels: 'SwDataTable-td-tableRow-#{rowI}-column-#{columnI}',
    exchange: {
      electronic: {
        submit: 'SwButton-exchange-electronic-submit',
      },
      paper: {
        next: 'SwButton-exchange-paper-next',
        submit: 'SwButton-exchange-paper-submit',
        download: 'SwButton-exchange-paper-download',
      },
    },
    refund: {
      summaryLineInfo: 'SwColumn-div-summaryLineInfo',
      summaryLineSum: 'SwColumn-div-summaryLineSum',
    },
    order: {
      table: {
        tableHeader: 'SwDataTable-tr-tableHeader',
        tableHeaderProduct: 'SwDataTable-th-tableHeaderProduct',
        tableQuantityLabel: 'SwDataTable-th-tableHeader-quantity',
        tableValueLabel: 'SwDataTable-th-tableHeader-value',
        tableRow: 'SwDataTable-tr-tableRow-#{index}',
        tableRowQuantity: 'SwDataTable-td-tableRow-#{index}-column-quantity',
        tableRowValue: 'SwDataTable-td-tableRow-#{index}-column-value',
        tableTotalAmount: 'SwColumn-div-totalAmount',
      },
      details: {
        customerFirstName: 'SwColumn-div-customerFirstname',
        customerLastname: 'SwColumn-div-customerLastname',
        customerEmailAddress: 'SwColumn-div-customerEmailAddress',
        customerMainAddressPostcode: 'SwColumn-div-customerMainAddressPostcode',
        customerMainAddressCity: 'SwColumn-div-customerMainAddressCity',
        customerMainAddressStreet: 'SwColumn-div-customerMainAddressStreet',
        customerMainAddressHouseNumber:
          'SwColumn-div-customerMainAddressHouseNumber',
        cardDeliveryAddress: 'MotionDiv-div-cardDeliveryAddress',
        alertDefaultAddress: 'SwAlert-div-defaultAddress',
      },
      order: {
        alertOrder: 'SwAlert-div-orderStepAlert',
        adaptContractTypeInSettings:
          'SwCheckboxField-input-adaptContractTypeInSettings',
        selectAnotherContractType: 'SwLink-button-SelectAnotherContractType',
      },
      payment: {
        termsandconditions: 'Swlink-a-termsAndConditions',
      },
    },
  },
};
