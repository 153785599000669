import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwModal,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLoadingPageHeader, PageHeader } from '../../../components';
import { Modals } from '../../../constants';
import { useApi, useSelectedCustomerState } from '../../../utils';
import { FiscalAttestationDetails } from './components/FiscalAttestationDetails';
import { FiscalAttestationDownloadModal } from './components/FiscalAttestationDownloadModal';
import { FiscalAttestationHistory } from './components/FiscalAttestationHistory';
import { CurrentDocument } from './FiscalAttestationScreen.constants';

export const FiscalAttestationScreen = () => {
  const { t } = useTranslation();

  const { customers } = useApi();
  const customer = useSelectedCustomerState();

  const [
    currentDocument,
    setCurrentDocument,
  ] = useState<CurrentDocument | null>();

  const [
    { value: fiscalAttestationInformation, loading, error },
    getFiscalAttestation,
  ] = useAsyncCallback(
    async () => (await customers.fiscalAttestation(customer.id)).data.data,
    [customers],
    { loading: true },
  );

  useEffect(() => {
    getFiscalAttestation();
  }, [getFiscalAttestation]);

  if (loading && !fiscalAttestationInformation) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.navigation.fiscalAttestation)}
        introduction={t(i18nKeys.fiscalAttestation.introduction)}
      />
    );
  }

  if (error || !fiscalAttestationInformation) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getFiscalAttestation} />
      </SwContainer>
    );
  }

  const {
    activeFiscalAttestation,
    historicalFiscalsAttestations,
  } = fiscalAttestationInformation;

  const openModal = (newDocument: CurrentDocument) => {
    setCurrentDocument(newDocument);
    toggleModalById(Modals.FISCAL_ATTESTATION_DOWNLOAD_MODAL_ID);
  };

  const onCloseModal = () => {
    setCurrentDocument(null);
  };

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn width="10" widthS="12">
          <PageHeader
            title={t(i18nKeys.navigation.fiscalAttestation)}
            introduction={t(i18nKeys.fiscalAttestation.introduction)}
            className="push-pageHeader"
          />
        </SwColumn>
        <SwColumn width="10" widthS="12">
          <FiscalAttestationDetails
            fiscalAttestation={activeFiscalAttestation}
            openModal={openModal}
          />
        </SwColumn>
        <SwColumn width="10" widthS="12">
          {!!historicalFiscalsAttestations.length && (
            <FiscalAttestationHistory
              historicalFiscalsAttestations={historicalFiscalsAttestations}
              openModal={openModal}
            />
          )}
        </SwColumn>
      </SwGrid>
      {currentDocument && (
        <SwModal
          id={Modals.FISCAL_ATTESTATION_DOWNLOAD_MODAL_ID}
          component={FiscalAttestationDownloadModal}
          currentDocument={currentDocument}
          confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
          onClose={onCloseModal}
          closable
        />
      )}
    </SwContainer>
  );
};
