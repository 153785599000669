import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type SwMainProps<T = HTMLElement> = AllHTMLAttributes<T>;

export const SwMain = <T extends HTMLElement>({
  children,
  className,
}: SwMainProps<T>) => {
  return createElement(
    'main',
    {
      id: 'main',
      className: mergeClassNames('vl-main-content', className),
      itemProp: 'mainContentOfPage',
      role: 'main',
      tabIndex: '0',
    },
    children,
  );
};
