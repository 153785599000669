import { useAsyncCallback } from '@kaa/common/utils';
import {
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { useApi, useSelectedCustomerState } from '../../../../utils';
import { OrderTypes } from '../MyOrderScreen.constants';
import { MyOrderDetail } from './components/MyOrderDetail';

export const MyOrderDetailPendingScreen: React.FC<RouteComponentProps> = () => {
  const customer = useSelectedCustomerState();
  const { customers } = useApi();

  const [
    { value: voucherOrder, loading, error },
    getPendingOrder,
  ] = useAsyncCallback(
    async () => (await customers.getPendingOrder(customer.id)).data.data,
    [customers],
    { loading: true },
  );

  useEffect(() => {
    getPendingOrder();
  }, [getPendingOrder]);

  if (loading) {
    return <SwContainer loading />;
  }

  if (error || !voucherOrder) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getPendingOrder} />
      </SwContainer>
    );
  }

  return (
    <SwContainer>
      <SwGrid modStacked>
        <MyOrderDetail
          order={{
            ...voucherOrder,
            type: OrderTypes.PENDING,
          }}
        />
      </SwGrid>
    </SwContainer>
  );
};
