import { i18nKeys } from '@kaa/i18n/common/keys';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwIcon } from '../../../icon/SwIcon';
import { Icon } from '../../../icon/SwIcon.constants';
import { SwInputTextField, SwInputTextFieldProps } from './SwInputField';

export const SwInputPasswordField = (props: SwInputTextFieldProps) => {
  const { t } = useTranslation();

  const [displayPassword, setDisplayPasswors] = useState<boolean>(false);

  return (
    <SwInputTextField
      type={displayPassword ? 'text' : 'password'}
      style={{ paddingRight: '4rem' }}
      {...props}
    >
      <button
        type="button"
        data-js-id="show-password-button"
        style={{
          border: 'none',
          position: 'absolute',
          top: '1.1rem',
          right: '1rem',
        }}
        onTouchStart={() => setDisplayPasswors(true)}
        onTouchEnd={() => setDisplayPasswors(false)}
        onMouseDown={() => setDisplayPasswors(true)}
        onMouseUp={() => setDisplayPasswors(false)}
        title={t(
          displayPassword
            ? i18nKeys.general.hidePassword
            : i18nKeys.general.showPassword,
        )}
      >
        <SwIcon icon={displayPassword ? Icon.HIDE : Icon.SHOW} />
      </button>
    </SwInputTextField>
  );
};
