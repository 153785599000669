import React, { AllHTMLAttributes, createElement, Ref } from 'react';
import { mergeClassNames } from '../utilities';
import { Icon } from './SwIcon.constants';

type IconProps<T = HTMLSpanElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  icon?: Icon | string;
  modSmall?: boolean;
  modLarge?: boolean;
  modLight?: boolean;
  modBefore?: boolean;
  modAfter?: boolean;
  modRotatedHalf?: boolean;
  modRotatedFull?: boolean;
};

type Classes = {
  icon?: Icon | string;
  modSmall?: boolean;
  modLarge?: boolean;
  modLight?: boolean;
  modBefore?: boolean;
  modAfter?: boolean;
  modRotatedHalf?: boolean;
  modRotatedFull?: boolean;
};
const getClasses = ({
  icon,
  modSmall,
  modLarge,
  modLight,
  modBefore,
  modAfter,
  modRotatedHalf,
  modRotatedFull,
}: Classes) => {
  return [
    'vl-icon vl-vi',
    icon ? `vl-vi-${icon}` : '',
    modSmall ? 'vl-icon--small' : '',
    modLarge ? 'vl-icon--large' : '',
    modLight ? 'vl-icon--light' : '',
    modBefore ? 'vl-icon--before' : '',
    modAfter ? 'vl-icon--after' : '',
    modRotatedHalf ? 'vl-vi-u-90deg' : '',
    modRotatedFull ? 'vl-vi-u-180deg' : '',
  ].join(' ');
};

export const SwIcon = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      modAfter,
      icon,
      modBefore,
      modLarge,
      modLight,
      modRotatedFull,
      modRotatedHalf,
      modSmall,
      className,
      children,
      ...rest
    }: IconProps<T>,
    ref: Ref<T>,
  ) => {
    const TagName = tagName || 'span';
    const classes = getClasses({
      icon,
      modSmall,
      modLarge,
      modAfter,
      modBefore,
      modLight,
      modRotatedFull,
      modRotatedHalf,
    });

    return createElement(TagName, {
      className: mergeClassNames(classes, className),
      'aria-hidden': 'true',
      ref,
      ...rest,
    });
  },
);
