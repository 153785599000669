import { useLayoutEffect } from 'react';

// example: https://usehooks.com/useLockBodyScroll
const SCROLL_BOCKED = 'scroll_blocked';
const ARIA_HIDDEN = 'aria-hidden';

export const useLockBodyScroll = (blocked = true) => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body)?.overflow;

    if (!originalStyle) {
      return;
    }

    const root = document.getElementById('root');
    // Prevent scrolling on mount
    if (blocked) {
      document.body.style.overflow = 'hidden';
      document.body.classList.add(SCROLL_BOCKED);
      if (root) {
        root.setAttribute(ARIA_HIDDEN, 'true');
      }
    } else {
      document.body.style.overflow = originalStyle;
      document.body.classList.remove(SCROLL_BOCKED);
      if (root) {
        root.removeAttribute(ARIA_HIDDEN);
      }
    }
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
      document.body.classList.remove(SCROLL_BOCKED);
      if (root) {
        root.removeAttribute(ARIA_HIDDEN);
      }
    };
  }, [blocked]); // Empty array ensures effect is only run on mount and unmount
};
