import { useAsyncCallback } from '@kaa/common/utils';
import {
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { useApi, useSelectedCustomerState } from '../../../../utils';
import { OrderTypes } from '../MyOrderScreen.constants';
import { MyOrderDetail } from './components/MyOrderDetail';

type MyOrderDetailScreenProps = {
  orderId: number | string;
};

export const MyOrderDetailScreen: React.FC<RouteComponentProps<
  MyOrderDetailScreenProps
>> = ({ orderId,location }) => {
  const customer = useSelectedCustomerState();
  const { customers } = useApi();
  const [
    { value: voucherPurchase, loading, error },
    getVoucherPurchase,
  ] = useAsyncCallback(
    async () =>{
      let orderIdValue = Number(orderId);
      return !isNaN(orderIdValue)
        ? (await customers.getVoucherPurchase(customer.id, Number(orderId)))
            .data.data
              : location?.state
    }
,
    [customers, orderId],
    { loading: true },
  );

  useEffect(() => {
    getVoucherPurchase();
  }, [getVoucherPurchase]);

  if (loading) {
    return <SwContainer loading />;
  }

  if (error || !voucherPurchase) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getVoucherPurchase} />
      </SwContainer>
    );
  }

  return (
    <SwContainer>
      <SwGrid modStacked>
        <MyOrderDetail
          order={{
            ...voucherPurchase,
            type: OrderTypes.PURCHASE,
          }}
        />
      </SwGrid>
    </SwContainer>
  );
};
