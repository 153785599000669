import '@kaa/common/polyfill/polyfill';
import { initErrorTracking } from '@kaa/core-app/common/components';
// why? because we want to be sure that the service is instantiated first
/* eslint-disable-next-line import/order */
import { configService } from '@kaa/core-app/common/config';
import { initAnalytics } from '@kaa/core-app/customers/utils/google-analytics';
import '@kaa/ui-flanders/themes/vl/scss/main.scss';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import get from 'lodash.get';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { buildConfig } from './build-config';
import { ConfigLoadFailedFallbackWithTranslation } from './components/ConfigLoadFailedCallbackWithTranslation';

// Loading dynamically the config to be able to update in the release pipeline
configService
  .fetchConfig(buildConfig)
  .then((config) => {
    const google = get(config, 'app.google');
    const applicationInsights = get(config, 'app.applicationInsights');

    if (google?.tagManagerId) {
      initAnalytics(google?.tagManagerId as string);
    }

    if (!buildConfig.isDevelopment && applicationInsights?.instrumentationKey) {
      const { reactPlugin } = initErrorTracking(
        applicationInsights?.instrumentationKey as string,
        buildConfig.buildId,
      );

      ReactDOM.render(
        <AppInsightsContext.Provider value={reactPlugin}>
          <App />
        </AppInsightsContext.Provider>,
        document.getElementById('root'),
      );
    } else {
      ReactDOM.render(<App />, document.getElementById('root'));
    }
  })
  .catch(() => {
    ReactDOM.render(
      <ConfigLoadFailedFallbackWithTranslation />,
      document.getElementById('root'),
    );
  });
