import React, { Ref, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { SwIcon } from '../icon/SwIcon';
import { Icon } from '../icon/SwIcon.constants';
import { SwLink } from '../link/SwLink';
import { mergeClassNames } from '../utilities';
import { SwUploadFile } from './components/SwUploadFile';
import { SwUploadProps } from './SwUpload.types';
import { getDropzoneOptions } from './SwUpload.utils';

const getClasses = ({ isDragging }: { isDragging?: boolean }) => {
  return ['vl-upload', isDragging ? 'vl-upload--dragging' : ''].join(' ');
};

const getUploadContainerClasses = ({ hasFiles }: { hasFiles?: boolean }) => {
  return [
    'vl-upload__files',
    hasFiles ? 'vl-upload__files--has-files' : '',
  ].join(' ');
};
export const SwUpload = React.forwardRef(
  (
    {
      onUpload,
      uploadLabel,
      uploadDragText,
      options,
      className,
      id,
      maxFilesize,
      allowedFileTypes,
      multiple = false,
      ...rest
    }: SwUploadProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();

    const [files, setFiles] = useState([]);
    const [errorFiles, setErrorFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    const unset = useCallback(
      (file: File) => {
        setFiles(files.filter((e) => e !== file));
        setErrorFiles(errorFiles.filter((e) => e !== file));
      },
      [setFiles, setErrorFiles],
    );

    const onDropAccepted = useCallback(
      (files) => {
        setFiles(files);
      },
      [setFiles],
    );

    const onDropRejected = useCallback(
      (files) => {
        setErrorFiles(files);
      },
      [setErrorFiles],
    );

    useEffect(() => {
      if (onUpload) {
        onUpload(files);
      }
    }, [files]);

    const { getRootProps, getInputProps } = useDropzone({
      onDragEnter: () => setIsDragging(true),
      onDragOver: () => setIsDragging(true),
      onDragLeave: () => setIsDragging(false),
      onDrop: () => setIsDragging(false),
      ...getDropzoneOptions({
        multiple,
        maxFilesize,
        allowedFileTypes,
        ...rest,
        ...options,
      }),
      onDropAccepted,
      onDropRejected,
    });

    const label = uploadLabel || t('general.upload.label');
    const dragText = uploadDragText || t('general.upload.labelDrag');

    return (
      <div
        className={mergeClassNames(
          getClasses({
            isDragging,
          }),
          className,
        )}
      >
        <div className="vl-upload__overlay">
          <p className="vl-upload__overlay__text">
            <SwIcon icon={Icon.PAPER_CLIP} aria-hidden="true" />
            {dragText}
          </p>
        </div>
        <div
          className={getUploadContainerClasses({
            hasFiles: Boolean([...files, ...errorFiles].length),
          })}
        >
          <div className="vl-upload__files__container" id={`${id}-container`}>
            {files &&
              files.map((file: File) => (
                <SwUploadFile key={file.name} file={file} unset={unset} />
              ))}
            {errorFiles &&
              errorFiles.map((file: File) => (
                <SwUploadFile
                  key={file.name}
                  file={file}
                  unset={unset}
                  error={t('general.upload.error')}
                />
              ))}
          </div>
          <SwLink
            tagName="button"
            className="vl-upload__files__close"
            onClick={(e) => {
              e.preventDefault();
              setFiles([]);
            }}
          >
            <SwIcon icon={Icon.BIN} aria-hidden="true" />
            {t('general.upload.removeAll')}
          </SwLink>
        </div>
        <div
          id={id}
          className="vl-upload__element"
          {...getRootProps()}
          ref={ref}
          {...rest}
        >
          <input {...getInputProps()} />
          <div className="vl-upload__element__label">
            <SwLink
              tagName="button"
              className="vl-upload__element__button"
              aria-label={`${label} - ${dragText}`}
              onClick={(e) => e.preventDefault()}
            >
              <SwIcon icon={Icon.PAPER_CLIP} aria-hidden="true" />
              <span className="vl-upload__element__button__container">
                {label}
              </span>
            </SwLink>
            <small>{dragText}</small>
          </div>
        </div>
      </div>
    );
  },
);
