/* https://wiki.sodexo.be/display/PROJECTAAH/Data-testid */
/* Nomenclature for data-testid for Sodexo
   The data-testid is splitted in 3 parts: ComponentName-tagName-action or description of the element. */

export const dataTest = {
  SwButton: 'SwButton-button-#{id}',
  SwActionLabel: 'SwLink-button-#{id}',
  SwModal: 'SwModal-dialog-#{id}',
  SwModalClose: 'SwModal-button-modalClose',
  SwAlert: 'SwAlert-button-closeAlert',
  SwAutocompleteField: 'SwInputTextField-input-#{fieldName}',
  SwAutocompleteFieldError: 'SwFormMessageError-p-#{fieldName}-error',
  SwAutocompleteListBox: 'AutocompleteField-ul-listBox',
  SwAutoCompleteOption: 'AutocompleteField-button-autocompleteOption-#{index}',
  breadcrumbItemSeparator: 'breadcrumbItem-a-Separator',
  breadcrumbItem: 'breadcrumbItem-li-breadcrumbItem',
  SwCheckboxField: 'SwCheckboxField-input-#{fieldName}',
  SwCheckboxFieldError: 'SwCheckboxField-p-#{fieldName}-error',
  SwInputTextField: 'SwInputTextField-input-#{fieldName}',
  SwInputTextFieldError: 'SwInputTextField-input-#{fieldName}-error',
  SwCalendar: 'SwCalendar-input-#{id}',
  SwRadioField: 'SwRadioField-input-#{id}',
  SwSelectField: 'SwSelectField-select-#{fieldName}',
  SwSelectFieldError: 'SwSelectField-select-#{fieldName}-error',
  SwPill: 'SwPill-button-closePill-#{id}',
  SwDocument: 'SwLink-a-document',
  SwDocumentExternal: 'SwLink-a-documentExternal',
  SwProgressBarStep: 'SwProgressBarStep-button-#{label}',
  SwWizard: 'SwWizard-div-contentPane',
  SwWizardPrev: 'SwWizardPane-a-previousStep',
  SwWizardNext: 'SwWizardPane-button-nextStep',
  SwMultiselect: 'SwMultiSelect-select-selector',
};
