import { RegionCode } from '@kaa/api/customers';
import { ValueOf } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { getPath, Routes } from '../routes';

export enum Modals {
  CHANGE_EMAIL_MODAL_ID = 'modal-change-email-id',
  CHANGE_PASSWORD_MODAL_ID = 'modal-change-password-id',
  RECOVER_PASSWORD_MODAL_ID = 'modal-recover-password-id',
  CHANGE_REGION_ID = 'modal-change-region-id',
  REFUND_MODAL_ID = 'modal-refund-id',
  ORDER_MODAL_ID = 'modal-order-id',
  EXCHANGE_MODAL_ID = 'exchange-order-id',
  PRESTATIONS_CONTEST_MODAL_ID = 'modal-prestation-contest-id',
  PRESTATIONS_DETAILS_MODAL_ID = 'modal-prestation-details-id',
  HEADER_PROFILE_LIST_MODAL_ID = 'modal-profile-list-id',
  ONBOARDING_MODAL_ID = 'modal-onboarding-id',
  IDLE_USER_TIMEOUT_MODAL_ID = 'idle-user-timeout-modal-id',
  FISCAL_ATTESTATION_DOWNLOAD_MODAL_ID = 'fiscal-attestation-download-modal-id',
}

export enum OnBoardingStorageKeys {
  ONBOARDING_DASHBOARD = 'onboarding-dashboard',
  ONBOARDING_SERVICE_VOUCHERS = 'onboarding-service-vouchers',
  ONBOARDING_WORK_MANAGEMENT = 'onboarding-work-management',
  ONBOARDING_ACCOUNT = 'onboarding-account',
}

export const START_BUY = 'start-buy';
export const START_BUY_QUERY = `${START_BUY}=true`;
export const SERVICES_VOUCHERS_ORDER_START_BUY = getPath(
  Routes.SERVICES_VOUCHERS_ORDER,
  {
    queryParams: {
      [START_BUY]: true,
    },
  },
);

export const LOGIN_CONNECTION_PROBLEMS_LINKS = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.login.url.usefullLinks.howTo,
  [RegionCode.BE_BRU]: i18nKeys.bl.login.url.usefullLinks.howTo,
  [RegionCode.BE_WAL]: i18nKeys.wl.login.url.usefullLinks.howTo,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const LOGIN_CANT_CONNECT_LINKS = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.login.url.usefullLinks.cantConnect,
  [RegionCode.BE_BRU]: i18nKeys.bl.login.url.usefullLinks.cantConnect,
  [RegionCode.BE_WAL]: i18nKeys.wl.login.url.usefullLinks.cantConnect,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const SERVICE_VOUCHERS_WEBSITE_LINKS = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.serviceVouchers.website.url,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.serviceVouchers.website.url,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.serviceVouchers.website.url,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const SERVICE_VOUCHERS_PUBLIC_WEBSITE_LINKS = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.serviceVouchers.publicWebsite.url,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.serviceVouchers.publicWebsite.url,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.serviceVouchers.publicWebsite.url,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// footer top
export const REGION_QUESTION_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.question,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.question,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.question,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_COMPLAINT_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.complaint,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.complaint,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.complaint,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// footer + register
export const REGION_CONDITIONS_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.termsAndConditions,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.termsAndConditions,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.termsAndConditions,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_PRIVACY_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.privacyPolicy,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.privacyPolicy,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.privacyPolicy,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// footer
export const REGION_COOKIES_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.cookies,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.cookies,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.cookies,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_NEWS_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.news,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.news,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.news,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// Support
export const REGION_CONTACT_US_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.contactUs,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.contactUs,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.contactUs,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_FAQ_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.faq,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.faq,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.faq,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const PASS_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_CONTACT_A_PROVIDER_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.contactAProvider,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.contactAProvider,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.contactAProvider,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// dashboard Alert => Switch from paper
export const REGION_ELECTRONICVOUCHER_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.electronicVoucher,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.electronicVoucher,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.electronicVoucher,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// Activities
export const REGION_WORKFOLLOWUP_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.workFollowUp,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.workFollowUp,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.workFollowUp,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// EXCHANGE ELECTRO TO PAPERS
export const REGION_EXCHANGE_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.exchange,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.exchange,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.exchange,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_NAME = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.region.name,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.region.name,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.region.name,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_NAME_LIVING = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.register.alert.list.livingIn,
  [RegionCode.BE_BRU]: i18nKeys.bl.register.alert.list.livingIn,
  [RegionCode.BE_WAL]: i18nKeys.wl.register.alert.list.livingIn,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_HELP_CENTER_NUMBER = ({
  [RegionCode.BE_VLG]:
    i18nKeys.vl.general.serviceVouchers.helpCenter.phoneNumber,
  [RegionCode.BE_WAL]:
    i18nKeys.wl.general.serviceVouchers.helpCenter.phoneNumber,
  [RegionCode.BE_BRU]:
    i18nKeys.bl.general.serviceVouchers.helpCenter.phoneNumber,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const APP_ID = ({
  [RegionCode.BE_VLG]: '74f16989-e18f-4506-8e57-95c12cecadea',
  [RegionCode.BE_WAL]: '45b48eec-95d2-4367-9be0-5d232c99f262',
  [RegionCode.BE_BRU]: '60914c11-f1fb-4285-8e50-37283e727efa',
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

/**
 * Used by AuthService and IDP to block login between customer/provider
 */
export const AUTH_TENANT = 'tenant:customer';

export const LANGUAGE_CHANGE_POST_MESSAGE_EVENT =
  'LANGUAGE_CHANGE_MESSAGE_EVENT';
export const FORGOT_PASSWORD_POST_MESSAGE_EVENT =
  'FORGOT_PASSWORD_POST_MESSAGE_EVENT';
