import {
  BankAccount,
  CustomerWallet,
  VouchersRefundCreate,
} from '@kaa/api/customers';
import { Format, toPriceFormat } from '@kaa/common/utils';
import {
  isBic,
  isIban,
  isValidForeignIban,
  onlyIf,
  required,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwInputTextField,
  SwLink,
  SwModalRenderProps,
  SwSelectField,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikProps } from 'formik';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MotionDiv } from '../../../../../../components/animation/MotionDiv';
import { createValidatorWithServerErrorHandled } from '../../../../../../utils/validation';
import {
  defaultRefundValidation,
  getInitialValuesRefundForm,
  getRefundableQuantityByPrice,
  hasRefundAmount,
  listRefundableVouchersByPrice,
} from '../../RefundModal.utils';
import { RefundElectronicSummary } from './RefundElectronicSummary';

type RefundModalProps = {
  wallet: CustomerWallet;
  bankAccountsOptions: Array<{ text: string; value: BankAccount }>;
  children: ReactNode;
  submit: any;
};

const gridMargin = window.innerWidth < 767 ? '1.5rem' : '3rem';
const bicCodeVariants = {
  close: {
    height: 0,
    overflow: 'hidden',
    marginBottom: 0,
    marginTop: 0,
    minWidth: `calc(100% + ${gridMargin})`,
  },
  open: {
    height: 'auto',
    overflow: 'hidden',
    marginTop: 15,
    transitionEnd: {
      overflow: 'inherit',
    },
  },
};

export const RefundElectronic = ({
  wallet,
  bankAccountsOptions,
  setConfirmCloseModal,
  submit,
  children,
}: SwModalRenderProps<RefundModalProps>) => {
  const { t } = useTranslation();

  const [
    isNewbeneficiaryBankAccount,
    setIsNewbeneficiaryBankAccount,
  ] = useState(!bankAccountsOptions.length);

  const handleTogglebeneficiaryBankAccountInput = () =>
    setIsNewbeneficiaryBankAccount((value) => !value);

  const refundableQuantityByPrice = getRefundableQuantityByPrice(
    wallet.voucherBundles,
  );

  const validate = createValidatorWithServerErrorHandled({
    ...defaultRefundValidation(refundableQuantityByPrice),
    'beneficiaryBankAccount.iban': [required, isIban],
    'beneficiaryBankAccount.bic': onlyIf(
      (bic, values) => isValidForeignIban(values.beneficiaryBankAccount?.iban),
      [required, isBic],
    ),
  });

  return (
    <>
      {children}
      <p className="vl-u-spacer">
        {t(i18nKeys.refund.intro.start)}
        <strong>
          {listRefundableVouchersByPrice(refundableQuantityByPrice, t)}
        </strong>
        {t(i18nKeys.refund.intro.end)}
      </p>

      <Formik<VouchersRefundCreate>
        initialValues={getInitialValuesRefundForm(
          bankAccountsOptions,
          refundableQuantityByPrice,
        )}
        onSubmit={submit}
        validate={validate}
      >
        {({
          values,
          errors,
          handleSubmit,
          dirty,
          isValid,
          isSubmitting,
          setFieldValue,
        }: FormikProps<VouchersRefundCreate>) => {
          setConfirmCloseModal(dirty);
          return (
            <SwForm onSubmit={handleSubmit} style={{ width: '100%' }}>
              <SwFormGrid modStacked>
                {Object.entries(refundableQuantityByPrice).map(
                  ([price], index, { length }) => (
                    <Field
                      key={`requests-${price}`}
                      name={`requests.${index}.quantity`}
                      type="number"
                      min="0"
                      max={refundableQuantityByPrice[price].refundableQuantity}
                      parse={(value: string) =>
                        value ? parseInt(value, 10) : 0
                      }
                      label={
                        length > 1
                          ? t(i18nKeys.refund.numberOfVouchersOfPrice, {
                              price: toPriceFormat(parseFloat(price)),
                            })
                          : t(i18nKeys.refund.numberOfVouchers)
                      }
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      component={SwInputTextField}
                      modRequired
                    />
                  ),
                )}
                <RefundElectronicSummary
                  bundles={wallet.voucherBundles}
                  requests={values.requests}
                />
                <SwFormColumn>
                  {isNewbeneficiaryBankAccount ? (
                    <>
                      <Field
                        name="beneficiaryBankAccount.iban"
                        label={t(i18nKeys.general.label.bankAccount)}
                        column={{ width: '12' }}
                        labelColumn={{ width: '12' }}
                        component={SwInputTextField}
                        modRequired
                        parse={(value: string) =>
                          value ? Format.iban(value) : ''
                        }
                      />
                      <MotionDiv
                        className="vl-grid vl-u-spacer"
                        initial="close"
                        isOpen={isValidForeignIban(
                          values.beneficiaryBankAccount?.iban,
                        )}
                        variants={bicCodeVariants}
                      >
                        <Field
                          name="beneficiaryBankAccount.bic"
                          label={t(i18nKeys.general.label.bic)}
                          column={{ width: '12' }}
                          labelColumn={{ width: '12' }}
                          component={SwInputTextField}
                          modRequired
                        />
                      </MotionDiv>
                    </>
                  ) : (
                    <Field
                      name="beneficiaryBankAccount.iban"
                      label={t(i18nKeys.general.label.bankAccount)}
                      options={bankAccountsOptions.map(({ text, value }) => ({
                        text,
                        value: value.iban,
                      }))}
                      inputOnChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const bic = bankAccountsOptions.find(
                          (option) => option.value.iban === e.target.value,
                        )?.value.bic;
                        setFieldValue('beneficiaryBankAccount.bic', bic);
                      }}
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      component={SwSelectField}
                      modRequired
                    />
                  )}
                  {!!bankAccountsOptions.length && (
                    <SwLink
                      onClick={() => {
                        if (!isNewbeneficiaryBankAccount) {
                          setFieldValue('beneficiaryBankAccount', undefined);
                        } else {
                          setFieldValue(
                            'beneficiaryBankAccount',
                            bankAccountsOptions[0].value,
                          );
                        }
                        handleTogglebeneficiaryBankAccountInput();
                      }}
                    >
                      {isNewbeneficiaryBankAccount
                        ? t(i18nKeys.refund.cta.selectPreviousAccount)
                        : t(i18nKeys.refund.cta.useAnotherAccount)}
                    </SwLink>
                  )}
                </SwFormColumn>

                <SwActionGroup>
                  <SwButton
                    type="submit"
                    modLarge
                    modDisabled={
                      !dirty || !isValid || !hasRefundAmount(values.requests)
                    }
                    modLoading={isSubmitting}
                  >
                    {t(i18nKeys.general.cta.validate)}
                  </SwButton>
                </SwActionGroup>
                <SwFormSubmitMessage />
              </SwFormGrid>
            </SwForm>
          );
        }}
      </Formik>
    </>
  );
};
