import { RegionCode } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { OnBoardingStorageKeys } from '../../constants';
import { Routes } from '../../routes';
import { Slide } from './OnBoardingModal.type';

const getSlides = (routekey: any, keyName: string) =>
  Object.keys(routekey).map((slide) => {
    return {
      visual: `${keyName.toLowerCase()}-${slide}.svg`,
      content: routekey[slide],
      modalName: keyName,
    };
  });

const getModalContent = (route: Routes) => {
  switch (route) {
    case Routes.DASHBOARD:
      return getSlides(
        i18nKeys.onboarding.dashboard,
        OnBoardingStorageKeys.ONBOARDING_DASHBOARD,
      );
    case Routes.SERVICES_VOUCHERS_WALLET:
      return getSlides(
        i18nKeys.onboarding.serviceVouchers,
        OnBoardingStorageKeys.ONBOARDING_SERVICE_VOUCHERS,
      );
    case Routes.SERVICES_VOUCHERS_ORDER:
      return getSlides(
        i18nKeys.onboarding.serviceVouchers,
        OnBoardingStorageKeys.ONBOARDING_SERVICE_VOUCHERS,
      );
    case Routes.PRESTATIONS_WORKS_MANAGEMENT:
      return getSlides(
        i18nKeys.onboarding.workManagement,
        OnBoardingStorageKeys.ONBOARDING_WORK_MANAGEMENT,
      );
    case Routes.ACCOUNT_PROFILE:
      return getSlides(
        i18nKeys.onboarding.account,
        OnBoardingStorageKeys.ONBOARDING_ACCOUNT,
      );
    default:
      return null;
  }
};

const getOnBoardingStorageKeys = (route: Routes) => {
  switch (route) {
    case Routes.DASHBOARD:
      return OnBoardingStorageKeys.ONBOARDING_DASHBOARD;
    case Routes.SERVICES_VOUCHERS_WALLET:
      return OnBoardingStorageKeys.ONBOARDING_SERVICE_VOUCHERS;
    case Routes.SERVICES_VOUCHERS_ORDER:
      return OnBoardingStorageKeys.ONBOARDING_SERVICE_VOUCHERS;
    case Routes.PRESTATIONS_WORKS_MANAGEMENT:
      return OnBoardingStorageKeys.ONBOARDING_WORK_MANAGEMENT;
    case Routes.ACCOUNT_PROFILE:
      return OnBoardingStorageKeys.ONBOARDING_ACCOUNT;
    default:
      return null;
  }
};

const getStorageModalView = (route: Routes) => {
  const storageKey = getOnBoardingStorageKeys(route);
  if (storageKey) {
    return Number(localStorage.getItem(storageKey));
  }
  return 0;
};

export const getOnboarding = (route: Routes) => {
  const modalViews = getStorageModalView(route);
  const modalContent = getModalContent(route);

  if (modalViews < 2) {
    setStorageModalView(route, modalViews);
  }

  return {
    modalViews,
    modalContent,
  };
};

const setStorageModalView = (route: Routes, actualView: number) => {
  const incrView = actualView + 1;
  const storageKey = getOnBoardingStorageKeys(route);
  if (storageKey) {
    localStorage.setItem(storageKey, String(incrView));
  }
};

// Unfortunately, this is an exception
// for Service Vouchers Onboarding Modal
// Description text depends on the Region only
// VL is different of WL & BL
export const getServiceVoucherSlideLokaliseKeyPerRegion = (
  slide: Slide,
  regionCode: RegionCode,
) => {
  if (
    slide.modalName === 'onboarding-service-vouchers' &&
    slide.content.title === 'onboarding.serviceVouchers.modalOne.title'
  ) {
    return regionCode === RegionCode.BE_VLG
      ? slide.content.description.vl
      : slide.content.description.otherRegion;
  }
  return slide.content.description;
};
