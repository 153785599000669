import React, { AllHTMLAttributes, ChangeEvent, Ref } from 'react';
import { mergeClassNames } from '../utilities';

type SwTextAreaFieldProps = AllHTMLAttributes<HTMLTextAreaElement> & {
  modError?: boolean;
  modDisabled?: boolean;
  modSmall?: boolean;
  modInline?: boolean;
  modBlock?: boolean;
  modFocus?: boolean;
  modRequired?: boolean;
  maxLength?: number;
};

type Classes = {
  modError?: boolean;
  modDisabled?: boolean;
  modSmall?: boolean;
  modInline?: boolean;
  modBlock?: boolean;
};
const getClasses = ({
  modBlock,
  modError,
  modDisabled,
  modSmall,
  modInline,
}: Classes) => {
  return [
    'vl-textarea',
    modError ? 'vl-textarea--error' : '',
    modDisabled ? 'vl-textarea--disabled' : '',
    modSmall ? 'vl-textarea--small' : '',
    modInline ? 'vl-textarea--inline' : '',
    modBlock ? 'vl-textarea--block' : '',
  ].join(' ');
};

export const SwTextArea = React.forwardRef(
  (
    {
      className,
      modBlock,
      modDisabled,
      modError,
      modInline,
      modSmall,
      modFocus,
      maxLength,
      value,
      onChange,
      modRequired = false,
      ...rest
    }: SwTextAreaFieldProps,
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    const classes = getClasses({
      modBlock,
      modDisabled,
      modError,
      modInline,
      modSmall,
    });

    const changeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <textarea
        {...rest}
        value={value}
        className={mergeClassNames(classes, className)}
        ref={ref}
        disabled={modDisabled}
        onChange={changeHandler}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={modFocus}
        maxLength={maxLength}
        aria-required={modRequired}
      />
    );
  },
);
