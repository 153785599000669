const Patterns = {
  NATIONAL_NUMBER: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
  ],
  PHONE_NUMBER_ONE: [
    /[+0-9]/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  PHONE_NUMBER_ONE_WITH_COUNTRY_PREFIX_ONE: [
    /[+0-9]/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  PHONE_NUMBER_ONE_WITH_COUNTRY_PREFIX_TWO: [
    /[+0-9]/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  PHONE_NUMBER_TWO: [
    /[+0-9]/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  PHONE_NUMBER_TWO_WITH_COUNTRY_PREFIX_ONE: [
    /[+0-9]/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  PHONE_NUMBER_TWO_WITH_COUNTRY_PREFIX_TWO: [
    /[+0-9]/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  MOBILE_PHONE: [
    /[+0-9]/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  MOBILE_PHONE_WITH_COUNTRY_PREFIX_ONE: [
    /[+0-9]/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  MOBILE_PHONE_WITH_COUNTRY_PREFIX_TWO: [
    /[+0-9]/,
    /[+0-9]/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  IBAN_BE: [
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
  ],
  IBAN_FR: [
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
  ],
  IBAN_DE: [
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
  ],
  IBAN_LU: [
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
  ],
  IBAN_NL: [
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
    ' ',
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
  ],
  DATE: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
};

const PHONE_NUMBER_TWO_PREFIX = ['2', '3', '4', '9'];

export const Mask = {
  nationalNumber: () => {
    return Patterns.NATIONAL_NUMBER;
  },
  phoneNumber: (phoneNumber: string) => {
    if (phoneNumber[0] === '+') {
      if (PHONE_NUMBER_TWO_PREFIX.includes(phoneNumber[4])) {
        return Patterns.PHONE_NUMBER_TWO_WITH_COUNTRY_PREFIX_ONE;
      }
      return Patterns.PHONE_NUMBER_ONE_WITH_COUNTRY_PREFIX_ONE;
    }

    if (phoneNumber.slice(0, 2) === '00') {
      if (PHONE_NUMBER_TWO_PREFIX.includes(phoneNumber[5])) {
        return Patterns.PHONE_NUMBER_TWO_WITH_COUNTRY_PREFIX_TWO;
      }
      return Patterns.PHONE_NUMBER_ONE_WITH_COUNTRY_PREFIX_TWO;
    }

    if (PHONE_NUMBER_TWO_PREFIX.includes(phoneNumber[1])) {
      return Patterns.PHONE_NUMBER_TWO;
    }

    return Patterns.PHONE_NUMBER_ONE;
  },
  mobilePhoneNumber: (phoneNumber: string) => {
    if (phoneNumber[0] === '+') {
      return Patterns.MOBILE_PHONE_WITH_COUNTRY_PREFIX_ONE;
    }

    if (phoneNumber.slice(0, 2) === '00') {
      return Patterns.MOBILE_PHONE_WITH_COUNTRY_PREFIX_TWO;
    }

    return Patterns.MOBILE_PHONE;
  },

  iban: (iban: string) => {
    if (iban.slice(0, 2).toUpperCase() === 'BE') {
      return Patterns.IBAN_BE;
    }

    if (iban.slice(0, 2).toUpperCase() === 'FR') {
      return Patterns.IBAN_FR;
    }

    if (iban.slice(0, 2).toUpperCase() === 'DE') {
      return Patterns.IBAN_DE;
    }

    if (iban.slice(0, 2).toUpperCase() === 'LU') {
      return Patterns.IBAN_LU;
    }

    if (iban.slice(0, 2).toUpperCase() === 'NL') {
      return Patterns.IBAN_NL;
    }

    return Patterns.IBAN_BE;
  },
};
