import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/common/keys';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { SwFormColumn, SwFormGrid } from '../core';
import { SwFormMessageLabel } from '../form-message/SwFormMessageLabel';
import { Icon } from '../icon/SwIcon.constants';
import { SwLink } from '../link/SwLink';

type SwActionLabelProps = {
  label: string | ReactNode;
  id?: string;
  linkText?: string;
  value?: string;
  onClick?: () => void;
};

export const SwActionLabel = ({
  id,
  label,
  value,
  onClick,
  linkText,
}: SwActionLabelProps) => {
  const { t } = useTranslation();
  return (
    <SwFormColumn>
      <SwFormGrid>
        <SwFormColumn width="3" widthS="12">
          <SwFormMessageLabel tagName="span">{label}</SwFormMessageLabel>
        </SwFormColumn>
        <SwFormColumn width="9" widthS="12" className="vl-u-display-flex">
          <span style={{ marginRight: '2rem', minWidth: '40%' }}>{value}</span>
          {id && onClick && (
            <SwLink
              data-testid={dynamicDataTest(dataTest.SwActionLabel, { id })}
              tagName="button"
              icon={Icon.EDIT}
              type="button"
              onClick={onClick}
              modIconAfter
            >
              {linkText || t(i18nKeys.general.cta.edit)}
            </SwLink>
          )}
        </SwFormColumn>
      </SwFormGrid>
    </SwFormColumn>
  );
};
