import get from 'lodash.get';
import { getConfig } from '../../../common/config';
import { getPath, Routes } from '../../routes';

export const getRedirectUrlToInitiateEid = (state: string): string => {
  const kaaConfig = getConfig();

  const idpBaseUrl = get(kaaConfig, 'app.auth.oidc.authority');
  const returnUrl = window.location.origin + getPath(Routes.REGISTER_COMPLETE);
  return `${idpBaseUrl}/eid/initiate?returnUrl=${returnUrl}&state=${state}`;
};
