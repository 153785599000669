import {
  Author,
  ServiceDelivery,
  ServiceDeliveryDetails,
} from '@kaa/api/customers';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwColumn,
  SwContainer,
  SwDescriptionData,
  SwDescriptionDataItem,
  SwFetchErrorMessage,
  SwGrid,
  SwLoader,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { useApi } from '../../../../utils';
import {
  EventAction,
  EventLabel,
  sendModalInteractionToGTM,
} from '../../../../utils/google-analytics';
import { formatActivityDuration } from '../PrestationsWorksManagementScreen.utils';

type PrestationsWorkDetailsModalProps = {
  work: ServiceDelivery;
  customerId: number;
};

export const PrestationsWorkDetailsModal = ({
  work,
  customerId,
}: PrestationsWorkDetailsModalProps) => {
  const { t } = useTranslation();
  const [currentWork, setCurrentWork] = useState<ServiceDeliveryDetails>(work);

  const { customers } = useApi();

  const [{ error }, getServiceDetail] = useAsyncCallback(
    async () => {
      const {
        data: { data },
      } = await customers.getServiceDetail(customerId, work.id);

      setCurrentWork(data);
    },
    [customers],
    { loading: true },
  );

  useEffect(() => {
    getServiceDetail();
  }, [getServiceDetail]);

  const { DateTime } = useLuxon();

  if (error || !currentWork) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getServiceDetail} />
      </SwContainer>
    );
  }

  const {
    serviceDate,
    activityType,
    previousActivityType,
    durationInHours,
    previousDurationInHours,
    providerName,
    comments,
  } = currentWork;

  return (
    <SwGrid>
      <SwColumn width="12" widthS="12">
        <SwTitle tagName="h2" className="vl-u-spacer--small">
          {t(i18nKeys.worksManagement.detailsModal.title)}
        </SwTitle>
        <p className="vl-u-spacer">
          {t(i18nKeys.worksManagement.detailsModal.description, {
            count: durationInHours,
          })}
        </p>
      </SwColumn>
      <SwColumn width="12" widthS="12">
        <SwTitle tagName="h3" tagStyle="h4">
          {t(i18nKeys.worksManagement.detailsModal.details.title)}
        </SwTitle>
        <SwDescriptionData className="vl-u-spacer--small">
          <SwDescriptionDataItem
            label={
              serviceDate &&
              DateTime.fromISO(serviceDate).toLocaleString({
                weekday: 'long',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              })
            }
            column={{ width: '6', widthS: '12' }}
            style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
              margin: 0,
            }}
          >
            {t(i18nKeys.worksManagement.awaitingConfirmation.workDate)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={providerName}
            column={{ width: '6', widthS: '12' }}
            style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
              margin: 0,
            }}
          >
            {t(i18nKeys.worksManagement.awaitingConfirmation.performedBy)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={
              <>
                {!!previousActivityType && (
                  <span
                    style={{
                      textDecoration: 'line-through',
                      fontWeight: 100,
                      color: '#b3b8bd',
                    }}
                  >
                    {t(
                      i18nKeys.worksManagement.activities[previousActivityType],
                    )}
                  </span>
                )}{' '}
                {t(i18nKeys.worksManagement.activities[activityType])}
              </>
            }
            column={{ width: '6', widthS: '12' }}
            style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
              margin: 0,
            }}
          >
            {t(i18nKeys.worksManagement.awaitingConfirmation.activity.label)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={
              <>
                {!!previousDurationInHours && (
                  <span
                    style={{
                      textDecoration: 'line-through',
                      fontWeight: 100,
                      color: '#b3b8bd',
                    }}
                  >
                    {formatActivityDuration(previousDurationInHours)}
                  </span>
                )}{' '}
                {formatActivityDuration(durationInHours)}
              </>
            }
            column={{ width: '6', widthS: '12' }}
            style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
              margin: 0,
            }}
          >
            {t(i18nKeys.worksManagement.awaitingConfirmation.workTime)}
          </SwDescriptionDataItem>
        </SwDescriptionData>
        <hr className="vl-u-hr vl-u-spacer" />
        <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer--small">
          {t(i18nKeys.worksManagement.detailsModal.comments.title, {
            count: comments ? comments.length : 0,
          })}
        </SwTitle>
        {comments ? (
          comments.map((comment) => {
            const isCustomer = comment.author === Author.CUSTOMER;
            return (
              <div key={comment.publicationDate}>
                <SwColumn
                  width="12"
                  className={`${
                    isCustomer ? 'vl-u-align-right' : ''
                  } vl-u-spacer`}
                >
                  {DateTime.fromISO(comment.publicationDate).toFormat(
                    'dd/LL/yyyy - HH:mm',
                  )}
                  <br />
                  <strong>
                    {isCustomer
                      ? t(i18nKeys.worksManagement.detailsModal.you)
                      : providerName}
                  </strong>{' '}
                  {isCustomer
                    ? t(
                        i18nKeys.worksManagement.detailsModal
                          .suggestModifications,
                      )
                    : t(
                        i18nKeys.worksManagement.detailsModal
                          .bringsModifications,
                      )}
                </SwColumn>
                <SwColumn
                  key={comment.publicationDate}
                  width="9"
                  push={isCustomer ? '3' : '0'}
                  className={`${
                    isCustomer ? 'vl-u-align-right' : ''
                  } vl-u-spacer`}
                >
                  <div
                    className="vl-u-bg-alt vl-u-align-left vl-discussionItem"
                    style={{
                      padding: '1.5rem',
                      backgroundColor: isCustomer ? 'rgba(0, 85, 204, .1)' : '',
                    }}
                  >
                    {comment.text}
                  </div>
                </SwColumn>
              </div>
            );
          })
        ) : (
          <SwColumn>
            <SwLoader />
          </SwColumn>
        )}

        <SwActionGroup>
          <SwButton
            onClick={() => {
              toggleModalById(Modals.PRESTATIONS_DETAILS_MODAL_ID);
              sendModalInteractionToGTM(
                EventLabel.WORKS_DETAILS_MODAL,
                EventAction.CLOSE_MODAL,
              );
            }}
            type="button"
          >
            {t(i18nKeys.general.close)}
          </SwButton>
        </SwActionGroup>
      </SwColumn>
    </SwGrid>
  );
};
