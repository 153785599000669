import { ValueOf } from '@kaa/common/types';
import faker from 'faker';
import {
  CustomerActionType,
  GlobalUserContext,
  GlobalUserContextReponse,
  MessageCode,
  MessageType,
  User,
} from '../model';
import { getCustomer } from './customer';

export const getGlobalUser = (
  customersResourceIds = [
    ...new Array(faker.random.number({ min: 1, max: 5 })),
  ].map(() => faker.random.uuid()),
): GlobalUserContext => {
  return {
    user: getUser(),
    customers: customersResourceIds.map((resourceId) =>
      getCustomer({ resourceId }),
    ),
  };
};

type CustomerActions = { [key in ValueOf<typeof CustomerActionType>]: boolean };

export const getMe = (): GlobalUserContextReponse => {
  const customersResourceIds = [
    ...Array(faker.random.number({ min: 1, max: 5 })),
  ].map(() => faker.random.uuid());

  return {
    data: getGlobalUser(customersResourceIds),
    actions: customersResourceIds.reduce(
      (acc, it) => ({
        ...acc,
        [it]: Object.values(CustomerActionType).reduce<CustomerActions>(
          (acc, action) => ({
            ...acc,
            [action]: Math.random() < 0.8,
            CAN_NAVIGATE: true, // user blocked disable
          }),
          ({} as any) as CustomerActions,
        ),
      }),
      {},
    ),
    messages: customersResourceIds.reduce(
      (acc, it) => ({
        ...acc,
        [it]: [...Array(faker.random.number({ min: 0, max: 1 }))].map(() => ({
          type: faker.helpers.randomize(Object.values(MessageType)),
          code: faker.helpers.randomize(Object.values(MessageCode)),
        })),
      }),
      {},
    ),
  };
};

export const getUser = (): User => {
  const firstName = faker.name.firstName();
  const lastName = faker.name.lastName();

  const firstRandomWord = faker.random.word();
  const secondRandomWord = faker.random.word();
  const randomNumber = faker.random.number(2000);

  return {
    firstName,
    lastName,
    login: `${firstRandomWord}_${secondRandomWord}${randomNumber}`,
    emailAddress: faker.internet.email(firstName, lastName),
    isLocalLogin: faker.random.boolean(),
  };
};
