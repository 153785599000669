import {
  CustomerVoucherSet,
  VouchersOrderItemCreate,
} from '@kaa/api/customers';
import { StorageKeys } from '@kaa/common/enums';
import { notIf } from '@kaa/common/validation';
import { PaymentType } from './OrderModal.constants';

type ActualBuyableVouchers = {
  buyableVouchers: CustomerVoucherSet[];
  quantity: number;
};

export const getOrderItems = ({
  buyableVouchers,
  quantity,
}: ActualBuyableVouchers) => {
  const { orderItems } = buyableVouchers.reduce<{
    orderItems: VouchersOrderItemCreate[];
    quantity: number;
  }>(
    (acc, buyableVoucher) => {
      const { quantity, faceValue } = buyableVoucher;

      if (!acc.quantity) {
        return acc;
      }

      if (quantity > acc.quantity) {
        return {
          ...acc,
          orderItems: [
            ...acc.orderItems,
            { faceValue, quantity: acc.quantity },
          ],
          quantity: 0,
        };
      }

      return {
        ...acc,
        orderItems: [...acc.orderItems, { faceValue, quantity }],
        quantity: acc.quantity - quantity,
      };
    },
    { orderItems: [], quantity },
  );

  return orderItems;
};

export const getOrderTotal = (orderItems: VouchersOrderItemCreate[]) =>
  orderItems.reduce(
    (acc, { faceValue, quantity }) => acc + faceValue * quantity,
    0,
  );

export const validationAddressVoucherDelivery = (
  defaultAddressValidationDeliveryAddress: any,
) =>
  Object.entries(defaultAddressValidationDeliveryAddress).reduce(
    (acc, [key, validators]: any) => ({
      ...acc,
      [key]: notIf('useMainAddressForPaperVoucherDelivery', validators),
    }),
    {},
  );

export const getOrderPreviousPaymentType = () => {
  return (
    (localStorage.getItem(
      StorageKeys.ORDER_PREVIOUS_PAYMENT_TYPE,
    ) as PaymentType) || ''
  );
};
