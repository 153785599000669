import React, { AllHTMLAttributes, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClassNames } from '../utilities';

type LoaderProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  message?: string;
  modDark?: boolean;
  modMessageHidden?: boolean;
};

type Classes = {
  modDark?: boolean;
};
const getClasses = ({ modDark }: Classes) => {
  return ['vl-loader', modDark ? 'vl-loader--light' : ''].join(' ');
};

export const SwLoader = <T extends HTMLElement>({
  tagName,
  className,
  modDark,
  modMessageHidden,
  message = 'general.pageLoading',
  children,
  ...rest
}: LoaderProps<T>) => {
  const { t } = useTranslation();
  const TagName = tagName || 'div';
  const messageHidden = modMessageHidden || false;

  const classes = getClasses({
    modDark,
  });

  return createElement(
    TagName,
    { role: 'alert', 'aria-busy': 'true', ...rest, className },
    <div className={mergeClassNames(classes, className)} />,
    message && messageHidden ? (
      <p className="vl-u-visually-hidden">{t(message)}</p>
    ) : null,
    message && !messageHidden ? <p>{t(message)}</p> : null,
    children,
  );
};
