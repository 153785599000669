import { i18nKeys } from '@kaa/i18n/common/keys';
import { TFunction } from 'i18next';
import ibanValidator from 'iban';
import get from 'lodash.get';
import { ReactNode } from 'react';

export type ValidatorFunction = (...params: any) => ValidationError | boolean;

export const createValidator = <T = any>(
  rules: { [key in keyof T]: ValidatorFunction[] },
  validationError?: Partial<ValidationError>,
) => {
  return (data = {}): { [key in keyof T]: ValidationError | false } => {
    const errors = Object.keys(rules).reduce((acc: any, key: string) => {
      // concat enables both functions and arrays of functions
      const rule = join(
        ([] as ValidatorFunction[]).concat(rules[key as keyof T]),
      );
      const error = rule(get(data, key.split('.')), data) as ValidationError;

      return error
        ? { ...acc, [key as keyof T]: { ...error, ...validationError } }
        : acc;
    }, {} as any);

    return errors;
  };
};

export function join(rules: ValidatorFunction[]): ValidatorFunction {
  return (value: unknown, data: unknown) =>
    rules
      .map((rule) => rule(value, data))
      .filter((error) => !!error)[0]; /* first error */
}

export const overrideValidationError = (
  validationFunction: ValidatorFunction,
  validationError?: Partial<ValidationError>,
): ValidatorFunction => {
  return (value: any) => {
    const error = validationFunction(value);

    if (!error || !validationError) {
      return error;
    }

    return { ...(error as ValidationError), ...validationError };
  };
};

export function onlyIf(
  fieldNameOrCond: ((value: any, values: any) => boolean) | string,
  validators: ValidatorFunction[] | ValidatorFunction,
): ValidatorFunction {
  return (value: any, values: any) => {
    if (
      typeof fieldNameOrCond === 'string'
        ? get(values, fieldNameOrCond)
        : fieldNameOrCond(value, values)
    ) {
      const joined = join(([] as ValidatorFunction[]).concat(validators));
      return joined(value, values);
    }
    return false;
  };
}
export function notIf(
  fieldNameOrCond: ((value: any, values: any) => boolean) | string,
  validators: ValidatorFunction[] | ValidatorFunction,
): ValidatorFunction {
  return (value: any, values: any) => {
    if (
      typeof fieldNameOrCond === 'string'
        ? !get(values, fieldNameOrCond)
        : fieldNameOrCond(value, values)
    ) {
      const joined = join(([] as ValidatorFunction[]).concat(validators));
      return joined(value, values);
    }
    return false;
  };
}

const isEmpty = (value: unknown) =>
  (Array.isArray(value) && !value.length) ||
  value === undefined ||
  value === null ||
  value === '';

export const isIban: ValidatorFunction = (value: string) => {
  if (value && !ibanValidator.isValid(value)) {
    return {
      defaultMessage: 'Invalid iban format',
      id: i18nKeys.errors.form.ibanFormat,
    };
  }

  return false;
};

export const isValidForeignIban = (iban = '') => {
  return ibanValidator.isValid(iban) && !iban.startsWith('BE');
};

export const isBic: ValidatorFunction = (value: string) => {
  if (value && value.length !== 8 && value.length !== 11) {
    return {
      defaultMessage: 'Invalid BIC format',
      id: i18nKeys.errors.form.bicFormat,
    };
  }

  return false;
};

export type ValidationError = {
  id:
    | string
    | ((t: TFunction, values: { [key: string]: unknown }) => ReactNode);
  defaultMessage: string;
  values?:
    | { [key: string]: unknown }
    | ((t: TFunction) => { [key: string]: unknown });
  displayFocussed?: boolean;
};
/**
 * All validation error should be in the 'error' namespace, so
 * we should prefix it in the 'id'
 */

export const pattern = (
  pattern: RegExp,
  message?: ValidationError,
): ValidatorFunction => (value: string) => {
  if (!isEmpty(value) && !pattern.test(value)) {
    return (
      message || {
        defaultMessage: 'Invalid pattern',
        id: i18nKeys.errors.form.pattern,
      }
    );
  }
  return false;
};

const mobilePhoneRegex = /^((\+|00)32\s?|0)4(55|56|[6789]\d)(\s?\d{2}){3}$/;

export const mobilePhone = pattern(mobilePhoneRegex, {
  id: i18nKeys.errors.server.form.INVALID_FORMAT,
  defaultMessage: 'Invalid format',
});

const phoneRegex = /^((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$/;

export const phone = pattern(phoneRegex, {
  id: i18nKeys.errors.server.form.INVALID_FORMAT,
  defaultMessage: 'Invalid format',
});

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const email = pattern(emailRegex, {
  defaultMessage: 'Invalid email address',
  id: i18nKeys.errors.form.email,
});

// eslint-disable-next-line no-useless-escape
const websiteRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const website = pattern(websiteRegex, {
  defaultMessage: 'Invalid website',
  id: i18nKeys.errors.form.website,
});

// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\0-9*])(?=.{8,})/;
export const password = pattern(passwordRegex, {
  defaultMessage: 'Invalid password',
  id: i18nKeys.errors.form.password,
});

export const required: ValidatorFunction = (
  value: unknown,
): ValidationError | false => {
  if (isEmpty(value)) {
    return {
      defaultMessage: 'Required',
      id: i18nKeys.errors.form.required,
    };
  }
  return false;
};

export const checked: ValidatorFunction = (
  value: unknown,
): ValidationError | false => {
  if (!value) {
    return {
      defaultMessage: 'Checked',
      id: i18nKeys.errors.form.checked,
    };
  }
  return false;
};

export function min(
  minValue: number,
  message?: ValidationError,
): ValidatorFunction {
  return (value: unknown) => {
    if (typeof value === 'number' && value < minValue) {
      return (
        message || {
          defaultMessage: `Value should be at least ${minValue}`,
          id: i18nKeys.errors.form.minValue,
          values: {
            value: minValue,
          },
        }
      );
    }
    return false;
  };
}

export function max(
  maxValue: number,
  message?: ValidationError,
): ValidatorFunction {
  return (value: unknown) => {
    if (typeof value === 'number' && value > maxValue) {
      return (
        message || {
          defaultMessage: `Value should be at most ${maxValue}`,
          id: i18nKeys.errors.form.maxValue,
          values: {
            value: maxValue,
          },
        }
      );
    }
    return false;
  };
}

export function minLength(
  minimum: number,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string) => {
    if (!isEmpty(value) && value.toString().length < minimum) {
      return (
        message || {
          defaultMessage: 'Must be at least {minimum} characters',
          id: i18nKeys.errors.form.minLength,
          values: {
            count: minimum,
          },
        }
      );
    }
    return false;
  };
}

export function maxLength(
  maximum: number,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string) => {
    if (!isEmpty(value) && value.toString().length > maximum) {
      return (
        message || {
          defaultMessage: 'Must be at most {maximum} characters',
          id: i18nKeys.errors.form.maxLength,
          values: {
            count: maximum,
          },
        }
      );
    }
    return false;
  };
}

export function minLengthWithTrim(
  minimum: number,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string) => {
    if (!isEmpty(value) && value.trim().length < minimum) {
      return (
        message || {
          defaultMessage: 'Must be at least {minimum} characters',
          id: i18nKeys.errors.form.minLength,
          values: {
            count: minimum,
          },
        }
      );
    }
    return false;
  };
}

export function equalTo(
  toBeEqual: string,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string) => {
    if (!isEmpty(value) && value !== toBeEqual) {
      return (
        message || {
          defaultMessage: `Must be equal to ${toBeEqual}`,
          id: i18nKeys.errors.form.equal,
          displayFocussed: false,
          values: {
            value: toBeEqual,
          },
        }
      );
    }
    return false;
  };
}

export function equalToField(
  name: string,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string, values: any) => {
    if (!isEmpty(value) && value !== values[name]) {
      return (
        message || {
          defaultMessage: `Must be equal to ${name}`,
          id: i18nKeys.errors.form.equal,
          displayFocussed: false,
          values: {
            value: name,
          },
        }
      );
    }
    return false;
  };
}

export function equalToFieldInsensitive(
  name: string,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string, values: any) => {
    if (
      !isEmpty(value) &&
      value &&
      value.toLowerCase() !== (values[name] || '').toLowerCase()
    ) {
      return (
        message || {
          defaultMessage: `Must be equal to ${name}`,
          id: i18nKeys.errors.form.equal,
          displayFocussed: false,
          values: {
            value: name,
          },
        }
      );
    }
    return false;
  };
}

export function notEqualTo(
  toBeDifferent: string,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string) => {
    if (!isEmpty(value) && value === toBeDifferent) {
      return (
        message || {
          defaultMessage: `Must be different to ${toBeDifferent}`,
          id: i18nKeys.errors.form.different,
          values: {
            value: toBeDifferent,
          },
        }
      );
    }
    return false;
  };
}

export function notEqualToField(
  name: string,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string, values: any) => {
    if (!isEmpty(value) && value === values[name]) {
      return (
        message || {
          defaultMessage: `Must be different to  ${values[name]}`,
          id: i18nKeys.errors.form.different,
          displayFocussed: false,
          values: {
            value: values[name],
          },
        }
      );
    }
    return false;
  };
}

export function notEqualToPassword(
  name: string,
  message?: ValidationError,
): ValidatorFunction {
  return (value: string, values: any) => {
    if (!isEmpty(value) && value === values[name]) {
      return (
        message || {
          defaultMessage: 'Must be different from the previous password',
          id: i18nKeys.errors.form.differentPassword,
          displayFocussed: false,
        }
      );
    }
    return false;
  };
}

export function notPasted(isPasted: boolean, message?: ValidationError) {
  return () => {
    if (isPasted) {
      return (
        message || {
          defaultMessage: 'You are not allowed to paste into this field',
          id: i18nKeys.errors.form.noPaste,
          values: {},
          displayFocussed: true,
        }
      );
    }
    return false;
  };
}

export function existsInError(
  fieldName: string,
  message?: Partial<ValidationError>,
): ValidatorFunction {
  return (value: string, values: any) => {
    if (get(values, `errors.${fieldName}`)) {
      return {
        defaultMessage: 'The chosen value is invalid.',
        id: i18nKeys.errors.form.invalid,
        ...message,
      };
    }
    return false;
  };
}

export function setError({
  setFieldValue,
  name,
}: {
  setFieldValue: (field: string, value: any) => void;
  name: string;
}) {
  setFieldValue(`errors.${name}`, true);
}

/* When the error is resolved, remove the 'virtual' field from the form */
export function unsetError({
  values,
  name,
  setFieldValue,
}: {
  values: any;
  name: string | string[];
  setFieldValue: (field: string, value: any) => void;
}) {
  const path = [
    'errors',
    ...(typeof name === 'string' ? name.split('.') : name),
  ];

  if (!get(values, path)) {
    return;
  }

  const deepPath = path
    .map((key, index, keys) => keys.slice(0, index + 1).join('.'))
    .reverse();

  const firstValidIndex = deepPath.findIndex(
    (path) =>
      get(values, path) instanceof Object &&
      Object.keys(get(values, path)).length > 1,
  );

  if (firstValidIndex === -1) {
    setFieldValue('errors', undefined);
  } else {
    setFieldValue(deepPath[firstValidIndex - 1], undefined);
  }
}

export const isValidField = ({
  values,
  errors,
  fieldName,
  initialValues,
}: {
  initialValues: { [key: string]: any };
  values: { [key: string]: any };
  errors: { [key: string]: string | undefined };
  fieldName: string;
}) =>
  get(initialValues, fieldName) === get(values, fieldName) &&
  !get(errors, fieldName) &&
  get(values, fieldName);

export const isOtherFieldValid = (
  fieldName: string,
  validatorFunction: ValidatorFunction,
) => (value: any, values: any) => validatorFunction(get(values, fieldName));
