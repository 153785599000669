import { RouteComponentProps } from '@reach/router';
import React, { ReactNode } from 'react';

type ServicesVouchersScreenProps = {
  children?: ReactNode;
};

export const ServicesVouchersScreen = ({
  children,
}: RouteComponentProps<ServicesVouchersScreenProps>) => {
  return <>{children}</>;
};
