import { createElement } from 'react';
import { mergeClassNames } from '../utilities';
import { Classes, GridProps } from './SwGrid';

export const getClasses = ({
  modWide,
  modCenter,
  modEnd,
  modNoGutter,
  modSpaceAround,
  modSpaceBetween,
  modStacked,
  modStart,
  modVBottom,
  modVCenter,
  modVStretched,
  modVTop,
}: Classes) => {
  return [
    'vl-form-grid',
    modStacked ? 'vl-form-grid--is-stacked' : '',
    modNoGutter ? 'vl-form-grid--no-gutter' : '',
    modStart ? 'vl-form-grid--align-start' : '',
    modCenter ? 'vl-form-grid--align-center' : '',
    modEnd ? 'vl-form-grid--align-end' : '',
    modSpaceBetween ? 'vl-form-grid--align-space-between' : '',
    modSpaceAround ? 'vl-form-grid--align-space-around' : '',
    modVStretched ? 'vl-form-grid--v-stretched' : '',
    modVCenter ? 'vl-form-grid--v-center' : '',
    modVTop ? 'vl-form-grid--v-top' : '',
    modVBottom ? 'vl-form-grid--v-bottom' : '',
    modWide ? 'vl-form-grid--wide' : '',
  ].join(' ');
};

export const SwFormGrid = <T extends HTMLElement>({
  tagName,
  className,
  modCenter,
  modEnd,
  modNoGutter,
  modSpaceAround,
  modSpaceBetween,
  modStacked,
  modStart,
  modVBottom,
  modVCenter,
  modVStretched,
  modVTop,
  modWide,
  children,
  ...rest
}: GridProps<T>) => {
  const TagName = tagName || 'div';
  const classes = getClasses({
    modCenter,
    modEnd,
    modNoGutter,
    modSpaceAround,
    modSpaceBetween,
    modStacked,
    modStart,
    modVBottom,
    modVCenter,
    modVStretched,
    modVTop,
    modWide,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
