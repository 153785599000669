import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import German from 'flatpickr/dist/l10n/de.js';
import English from 'flatpickr/dist/l10n/default.js';
import French from 'flatpickr/dist/l10n/fr.js';
import Dutch from 'flatpickr/dist/l10n/nl.js';

export const LocalizationFlatpickr: { [key: string]: unknown } = {
  en: English,
  fr: French.fr,
  nl: Dutch.nl,
  de: German.de,
};

export const omitNil = (value: any) => {
  const obj = {
    ...value,
  };
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
};

export const isDiffDate = (
  date: string | Date | undefined,
  secondDate: string | Date | undefined,
): boolean => {
  if (!date && !secondDate) {
    return false;
  }

  if (date && !secondDate) {
    return true;
  }

  if (!date && secondDate) {
    return true;
  }

  if (typeof date === 'string' && typeof secondDate !== 'string') {
    return true;
  }

  if (typeof date !== 'string' && typeof secondDate === 'string') {
    return true;
  }

  if (typeof date === 'string' && typeof secondDate === 'string') {
    return date !== secondDate;
  }

  const dateTime = DateTime.fromJSDate(date as Date).startOf('day');

  const secondDateTime = DateTime.fromJSDate(secondDate as Date).startOf('day');

  return !!Math.floor(dateTime.diff(secondDateTime, 'days').days);
};

export function usePrevious<T = any>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
