import { ValueOf } from '@kaa/common/types';
import faker from 'faker';

/**
 * Generate a random Belgian IBAN
 * @returns The iban number.
 */
export const getIbanMock = () => {
  const bankCode = '123';
  const account = faker.random
    .number({ min: 1000000, max: 9999999 })
    .toFixed(0);
  const checksum = `0${parseInt(`${bankCode}${account}`, 10) % 97}`.substr(-2);
  const ibanNumber = parseInt(`${checksum}${checksum}111400`, 10);
  const ibanChecksum = `0${98 - (ibanNumber % 97)}`.substr(-2);
  const iban = `BE${ibanChecksum}${bankCode}${account}${checksum}`;
  return iban.replace(/(.{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
};

/**
 * It takes an object and returns an object with the same keys, but with the values being random
 * booleans
 * @param {T} obj - T
 */
export const getActionsMock = <T extends { [key: string]: string }>(obj: T) =>
  Object.values(obj).reduce<{ [key in ValueOf<typeof obj>]: boolean }>(
    (acc, action) => ({ ...acc, [action]: faker.random.boolean() }),
    ({} as any) as { [key in ValueOf<typeof obj>]: boolean },
  );

/**
 * `getResourceIdMock` returns a unique identifier for a resource
 * @param {string} entityName - The name of the entity that you want to create a mock for.
 * @param {string | number} id - The id of the resource.
 */
export const getResourceIdMock = (
  entityName: string,
  id: string | number = faker.random.uuid(),
) => `${entityName}-${id}`;

/**
 * It takes a base64 string and converts it to a Blob
 * @param {string} b64Data - The base64 encoded data.
 * @param [contentType=application/pdf] - The MIME type of the file you're creating.
 * @param [sliceSize=512] - The size of the byte array to create.
 * @returns A blob object.
 */
export const b64toBlob = (
  b64Data: string,
  contentType = 'application/pdf',
  sliceSize = 512,
): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
