import React, { AllHTMLAttributes, createElement, Ref } from 'react';
import { mergeClassNames } from '../utilities';

export type RegionProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  modAlt?: boolean;
  modSmall?: boolean;
  modMedium?: boolean;
  modNoSpace?: boolean;
  modNoSpaceTop?: boolean;
  modNoSpaceBottom?: boolean;
  modNoSpaceBordered?: boolean;
  modOverlap?: boolean;
};

export type Classes = {
  modAlt?: boolean;
  modSmall?: boolean;
  modMedium?: boolean;
  modNoSpace?: boolean;
  modNoSpaceTop?: boolean;
  modNoSpaceBottom?: boolean;
  modNoSpaceBordered?: boolean;
  modOverlap?: boolean;
};

export const getClasses = ({
  modAlt,
  modSmall,
  modMedium,
  modNoSpace,
  modNoSpaceBordered,
  modNoSpaceBottom,
  modNoSpaceTop,
  modOverlap,
}: Classes) => {
  return [
    'vl-region',
    modOverlap ? 'vl-region--overlap' : '',
    modAlt ? 'vl-region--alt' : '',
    modSmall ? 'vl-region--small' : '',
    modMedium ? 'vl-region--medium' : '',
    modNoSpace ? 'vl-region--no-space' : '',
    modNoSpaceTop ? 'vl-region--no-space-top' : '',
    modNoSpaceBottom ? 'vl-region--no-space-bottom' : '',
    modNoSpaceBordered ? 'vl-region--bordered' : '',
  ].join(' ');
};

export const SwRegion = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      className,
      modAlt,
      modMedium,
      modNoSpace,
      modNoSpaceBordered,
      modNoSpaceBottom,
      modNoSpaceTop,
      modOverlap,
      modSmall,
      children,
      ...rest
    }: RegionProps<T>,
    ref: Ref<T>,
  ) => {
    const TagName = tagName || 'section';
    const classes = getClasses({
      modOverlap,
      modNoSpaceTop,
      modNoSpaceBottom,
      modNoSpaceBordered,
      modNoSpace,
      modMedium,
      modSmall,
      modAlt,
    });

    return createElement(
      TagName,
      { className: mergeClassNames(classes, className), ref, ...rest },
      children,
    );
  },
);
