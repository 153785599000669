import { RoutesDefinition } from '../types';

export type CommonRoutes =
  | 'ROOT'
  | 'LANDING'
  | 'DASHBOARD'
  | 'LOGIN'
  | 'LOGOUT'
  | 'LOGIN_CALLBACK'
  | 'LOGOUT_CALLBACK'
  | 'SILENT_RENEW_HTML_CALLBACK'
  | 'SERVICE_UNAVAILABLE'
  | 'MAINTENANCE'
  | 'PAGE_404';

export const CommonRoutes = {
  ROOT: 'ROOT' as CommonRoutes,
  LANDING: 'LANDING' as CommonRoutes,
  DASHBOARD: 'DASHBOARD' as CommonRoutes,
  LOGIN: 'LOGIN' as CommonRoutes,
  LOGOUT: 'LOGOUT' as CommonRoutes,
  LOGIN_CALLBACK: 'LOGIN_CALLBACK' as CommonRoutes,
  LOGOUT_CALLBACK: 'LOGOUT_CALLBACK' as CommonRoutes,
  SILENT_RENEW_HTML_CALLBACK: 'SILENT_RENEW_HTML_CALLBACK' as CommonRoutes,
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE' as CommonRoutes,
  PAGE_404: 'PAGE_404' as CommonRoutes,
  MAINTENANCE: 'MAINTENANCE' as CommonRoutes,
};

export const COMMON_ROUTES = {
  [CommonRoutes.ROOT]: {
    title: CommonRoutes.ROOT,
    path: '/',
  },
  [CommonRoutes.LANDING]: {
    title: CommonRoutes.LANDING,
    path: '/',
  },
  // If you change this routes, please change it signin-callback.html on every project
  [CommonRoutes.DASHBOARD]: {
    title: CommonRoutes.DASHBOARD,
    path: '/dashboard',
  },
  [CommonRoutes.LOGIN]: {
    title: CommonRoutes.LOGIN,
    path: '/auth/login',
  },
  [CommonRoutes.LOGOUT]: {
    title: CommonRoutes.LOGOUT,
    path: '/auth/logout',
  },
  [CommonRoutes.LOGIN_CALLBACK]: {
    title: CommonRoutes.LOGIN_CALLBACK,
    path: '/signin-callback.html',
  },
  [CommonRoutes.LOGOUT_CALLBACK]: {
    title: CommonRoutes.LOGOUT_CALLBACK,
    path: '/logout-callback.html',
  },
  [CommonRoutes.SILENT_RENEW_HTML_CALLBACK]: {
    title: CommonRoutes.SILENT_RENEW_HTML_CALLBACK,
    path: '/silent-renew-callback.html',
  },
  // If you change this routes, please change it signin-callback.html on every project
  [CommonRoutes.SERVICE_UNAVAILABLE]: {
    title: CommonRoutes.SERVICE_UNAVAILABLE,
    path: '/service-unavailable',
  },
  [CommonRoutes.PAGE_404]: {
    title: CommonRoutes.PAGE_404,
    path: '/404',
  },
  [CommonRoutes.MAINTENANCE]: {
    title: CommonRoutes.MAINTENANCE,
    path: '/maintenance',
  },
} as RoutesDefinition<CommonRoutes>;
