import React, {
  createContext,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from 'react';
import { debugLog } from '@kaa/common/utils/debugLog';

enum AuthAction {
  UPDATE = 'update',
  RESET = 'reset',
  LOADING = 'loading',
}

type Action<T> = { type: AuthAction; payload?: T };
type Dispatch<T> = (action: Action<T>) => void;

type AuthProviderProps<T> = { children: ReactNode; initialState: T };

const AuthStateContext = createContext<any | null>(null);

const AuthDispatchContext = createContext<Dispatch<any> | null>(null);

function userReducer<TState, TAction>(
  state: TState,
  action: Action<TAction>,
): TState | null {
  const { type, payload } = action;
  switch (type) {
    case AuthAction.UPDATE: {
      return { ...state, ...payload };
    }
    case AuthAction.LOADING: {
      debugLog('AuthAction.LOADING');
      return { ...state, loading: true };
    }
    case AuthAction.RESET: {
      const { initialState } = (state as unknown) as { initialState: TState };
      return {
        ...initialState,
        initialState,
      };
    }
    default: {
      throw new Error(`Unhandled action: ${action}`);
    }
  }
}

const AuthProvider = <TState extends {}>({
  children,
  initialState,
}: AuthProviderProps<TState>) => {
  const [state, setAuth] = useReducer<
    Reducer<TState | null, Action<Partial<TState>>>
  >(userReducer, {
    ...initialState,
    initialState,
  });

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={setAuth}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthState = <TState extends {}>(): TState => {
  const context = useContext<TState | null>(AuthStateContext);

  if (context === null) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
};

const useAuthDispatch = <TState extends {}>(): Dispatch<Partial<TState>> => {
  const context = useContext<Dispatch<Partial<TState>> | null>(
    AuthDispatchContext,
  );

  if (context === null) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuthState, useAuthDispatch, AuthAction };
