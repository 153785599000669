import React, { createContext, ReactNode, useContext, useReducer } from 'react';

export enum ActionType {
  ADD = 'add',
}

type Action = {
  type: ActionType;
  payload: { [key: string]: { [key: string]: boolean } };
};
export type ActionDispatch = (action: Action) => void;
type State = { [key: string]: { [key: string]: boolean } };

type ActionProviderProps = { children: ReactNode };

const initialState: State = {};

const ActionStateContext = createContext<State | undefined>(undefined);
const ActionDispatchContext = createContext<ActionDispatch | undefined>(
  undefined,
);

function actionReducer(state: State, action: Action): State {
  const { type, payload } = action;
  switch (type) {
    case ActionType.ADD: {
      return { ...state, ...payload };
    }
    default: {
      throw new Error(`Unhandled action: ${action}`);
    }
  }
}

export const ActionProvider = ({ children }: ActionProviderProps) => {
  const [state, actionDispatch] = useReducer(actionReducer, initialState);
  return (
    <ActionStateContext.Provider value={state}>
      <ActionDispatchContext.Provider value={actionDispatch}>
        {children}
      </ActionDispatchContext.Provider>
    </ActionStateContext.Provider>
  );
};

export function useActionState() {
  const context = useContext(ActionStateContext);
  if (context === undefined) {
    throw new Error('useActionState must be used within a ActionProvider');
  }
  return context;
}

export function useActionByResource(
  resourceId?: string | number,
): { [key: string]: boolean } | undefined {
  const context = useActionState();

  if (!resourceId) {
    return;
  }

  return context[resourceId];
}

export function useActionDispatch() {
  const context = useContext(ActionDispatchContext);
  if (context === undefined) {
    throw new Error('useActionDispatch must be used within a ActionProvider');
  }
  return context;
}
