import {
  existsInError,
  maxLength,
  required,
  setError,
  unsetError,
  ValidatorFunction,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/common/keys';
import { FormikHandlers, FormikProps } from 'formik';
import get from 'lodash.get';
import { AddressFieldNames } from './SwFormAddress.constant';
import { City, Street } from './SwFormAddress.type';

export const getAutocompletePostcodeOption = (
  availableCities: City[] = [],
) => async (query: string) =>
  availableCities
    .map((option) => {
      if (option.postcode.startsWith(query)) {
        return {
          option,
          orderNumber: 0,
        };
      }
      if (
        option.names.some((name) =>
          name.toLowerCase().includes(query.toLowerCase()),
        )
      ) {
        return {
          option,
          orderNumber: 1,
        };
      }
      return {
        option,
        orderNumber: -1,
      };
    })
    .filter(({ orderNumber }) => orderNumber !== -1)
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .map(({ option }) => option);

export const getAutocompleteStreetOption = ({
  fieldNamePrefix,
  getStreets,
}: {
  fieldNamePrefix: string;
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
}) => async (
  query: string,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: any;
    name: string;
  },
  form: FormikProps<any>,
) => {
  const postcode = get(
    form.values,
    `${fieldNamePrefix}.${AddressFieldNames.POSTCODE}`,
  );

  if (!postcode || !query) {
    return [];
  }

  const [, value = []] = await getStreets(
    get(form.values, `${fieldNamePrefix}.${AddressFieldNames.POSTCODE}`),
    query,
  );
  return value;
};

export const onSelectPostcodeOption = ({
  fieldNamePrefix,
  getStreets,
}: {
  fieldNamePrefix: string;
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
}) => async (
  option: City,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: any;
    name: string;
  },
  form: FormikProps<any>,
) => {
  const { setFieldValue, values } = form;
  const street = get(values, `${fieldNamePrefix}.${AddressFieldNames.STREET}`);

  if (street) {
    // TODO: fix memory leak - pass setFieldValue to getStreets
    const [, streets = []] = await getStreets(option.postcode, street);

    if (streets.length === 1) {
      setFieldValue(
        `${fieldNamePrefix}.${AddressFieldNames.STREET_ID}`,
        streets[0].id,
      );
      setFieldValue(
        `${fieldNamePrefix}.${AddressFieldNames.CITY}`,
        streets[0].city,
      );
    } else {
      setError({
        setFieldValue,
        name: `${fieldNamePrefix}.${AddressFieldNames.STREET}.invalid`,
      });
    }
  }
};

export const onSelectStreetOption = (fieldNamePrefix: string) => async (
  { id, city }: Street,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: any;
    name: string;
  },
  form: FormikProps<any>,
) => {
  const { values, setFieldValue } = form;
  setFieldValue(`${fieldNamePrefix}.${AddressFieldNames.STREET_ID}`, id);
  setFieldValue(`${fieldNamePrefix}.${AddressFieldNames.CITY}`, city);
  unsetError({
    setFieldValue,
    values,
    name: `${fieldNamePrefix}.${AddressFieldNames.STREET}.invalid`,
  });
};

export const getDefaultAddressValidation = (
  fieldNamePrefix: string,
  validators?: {
    [AddressFieldNames.POSTCODE]?: ValidatorFunction[];
    [AddressFieldNames.STREET]?: ValidatorFunction[];
    [AddressFieldNames.CITY]?: ValidatorFunction[];
    [AddressFieldNames.HOUSE_NUMBER]?: ValidatorFunction[];
  },
) => ({
  [`${fieldNamePrefix}.${AddressFieldNames.POSTCODE}`]: [
    existsInError(
      `${fieldNamePrefix}.${AddressFieldNames.POSTCODE}.autocomplete.invalid`,
    ),
    required,
    ...((validators && validators[AddressFieldNames.POSTCODE]) || []),
  ],
  [`${fieldNamePrefix}.${AddressFieldNames.STREET}`]: [
    existsInError(
      `${fieldNamePrefix}.${AddressFieldNames.STREET}.autocomplete.invalid`,
    ),
    existsInError(`${fieldNamePrefix}.${AddressFieldNames.STREET}.invalid`, {
      id: i18nKeys.general.errors.form.invalidStreet,
      defaultMessage: 'Invalid street',
      displayFocussed: true,
    }),
    required,
    ...((validators && validators[AddressFieldNames.STREET]) || []),
  ],
  [`${fieldNamePrefix}.${AddressFieldNames.CITY}`]: [
    required,
    ...((validators && validators[AddressFieldNames.CITY]) || []),
  ],
  [`${fieldNamePrefix}.${AddressFieldNames.HOUSE_NUMBER}`]: [
    required,
    maxLength(12),
    ...((validators && validators[AddressFieldNames.HOUSE_NUMBER]) || []),
  ],
});
