import React, { AllHTMLAttributes, createElement, ReactNode, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/common/keys';
import { mergeClassNames } from '../utilities';

type BreadcrumbProps<T = HTMLElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  label?: string;

  modSeparators?: boolean;
  modBar?: boolean;

  search?: ReactNode;
  actions?: ReactNode;
};

const getClasses = () => {
  return 'vl-breadcrumb';
};

export const SwBreadcrumb = React.forwardRef(
  <T extends HTMLElement>(
    {
      tagName,
      label,
      modSeparators,
      modBar,
      className,
      children,
      search,
      actions,
      ...rest
    }: BreadcrumbProps<T>,
    ref: Ref<T>,
  ) => {
    const { t } = useTranslation();

    const TagName = tagName || 'nav';
    const classes = getClasses();
    const labelText = label || t(i18nKeys.general.breadcrumbLabel);

    const barClasses = modBar ? 'vl-breadcrumb-bar' : '';

    let childrenWithSeparator = children;
    if (modSeparators) {
      childrenWithSeparator = React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        return React.cloneElement<any>(child, {
          useSeparator: modSeparators,
        });
      });
    }

    return (
      <div className={barClasses}>
        {createElement(
          TagName,
          {
            className: mergeClassNames(classes, className),
            'aria-label': labelText,
            ref,
            ...rest,
          },
          <ol className="vl-breadcrumb__list">{childrenWithSeparator}</ol>,
        )}
        {search ? (
          <div className="vl-breadcrumb-bar__search">{search}</div>
        ) : null}
        {actions ? (
          <div className="vl-breadcrumb-bar__actions">{actions}</div>
        ) : null}
      </div>
    );
  },
);
