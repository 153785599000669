import * as client from '@govflanders/vl-widget-client';
import '@govflanders/vl-widget-polyfill';
import { httpTo } from '@kaa/api/customers/utilities';
import { useAuthState } from '@kaa/auth/common';
import { getConfig } from '@kaa/core-app/common/config';
import { AuthContext } from '@kaa/core-app/customers/types';
import { navigate } from '@reach/router';
import get from 'lodash.get';
import React, { useEffect, useRef, useState } from 'react';
import { configureSession, onLogoutRequest } from './Header.utils';

export const Header = () => {
  const [widget, setWidget] = useState<any>(null);
  const [isWidgetMounted, setIsWidgetMounted] = useState(false);
  const $el = useRef(null);

  const { getSigninRequestUrl, getLogoutRequestUrl, getUser } = useAuthState<
    AuthContext
  >();

  useEffect(() => {
    const getWidget = async () => {
      const [error, bootstrapWidget] = await httpTo(
        client.bootstrap(get(getConfig(), 'app.auth.definition.header')),
      );

      if (error) {
        setWidget(null);
        return;
      }

      setWidget(bootstrapWidget);
    };

    getWidget();
  }, []);

  useEffect(() => {
    if (!(widget && $el.current)) {
      return;
    }

    widget.setMountElement($el.current).mount();
    setIsWidgetMounted(true);
    return () => {
      widget.setMountElement(null).unmount();
      setIsWidgetMounted(false);
    };
  }, [widget]);

  useEffect(() => {
    if (!isWidgetMounted) {
      return;
    }

    client.capture((capturedWidget: any) => {
      if (capturedWidget.getPluginTypeId() !== 'global_header') {
        return;
      }

      /**
       * Allows to configure the loginUrl and logoutUrl that will redirect to IDP
       * with the correct configuration
       */
      capturedWidget
        .getExtension('citizen_profile.session')
        .then(async (session: any) => {
          const [error, data] = await httpTo(
            Promise.all([
              getSigninRequestUrl(),
              getLogoutRequestUrl(),
              getUser(),
            ]),
          );

          if (error || !data) {
            configureSession('', '', false, session);
            return navigate(
              get(getConfig(), 'buildConfig.errorPagePath') as string,
            );
          }

          configureSession(
            data[0] as string,
            data[1] as string,
            !!data[2],
            session,
          );
        });

      /**
       * Allows to handle logout click on header widget
       */
      capturedWidget.on(
        'citizen_profile.session.logout.request',
        onLogoutRequest,
      );
    });
  }, [isWidgetMounted, getSigninRequestUrl, getLogoutRequestUrl, getUser]);

  return <div ref={$el} />;
};
