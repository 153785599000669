/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type PurchaseStatus =
  | 'PAID'
  | 'PROCESSING'
  | 'SENT'
  | 'RECEIVED'
  | 'PENDING';

export const PurchaseStatus = {
  PAID: 'PAID' as PurchaseStatus,
  PROCESSING: 'PROCESSING' as PurchaseStatus,
  SENT: 'SENT' as PurchaseStatus,
  RECEIVED: 'RECEIVED' as PurchaseStatus,
  PENDING: 'PENDING' as PurchaseStatus,
};
