import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { FormikHandlers, FormikProps } from 'formik';
import React, { AllHTMLAttributes, ChangeEvent } from 'react';
import { dataTest } from '../../../../datatest/keys';
import { SwFormColumn, SwFormGrid } from '../../../core';
import { Column } from '../../../core/SwColumn';
import { SwFormInput } from '../../../form-input/SwFormInput';
import { SwFormMessageLabel } from '../../../form-message/SwFormMessageLabel';
import { SwSelect } from '../../../select/SwSelect';
import { useFormError } from '../../../util/hooks/useFormError';
import { FieldPropsClean } from '../../../utilities';
import { SwErrorMessageForField } from '../error-message-for-field/SwErrorMessageForField';

interface Option {
  value: any;
  text: string;
}

type SwSelectProps = AllHTMLAttributes<HTMLSelectElement> &
  FieldPropsClean & {
    type?: string;
    label?: string;
    options?: Option[];
    column?: Column;
    labelColumn?: Column;
    modError?: boolean;
    modSmall?: boolean;
    modInline?: boolean;
    modBlock?: boolean;
    modDisabled?: boolean;
    modRequired?: boolean;
    inputOnChange?: (
      event: ChangeEvent<EventTarget>,
      field: {
        onChange: FormikHandlers['handleChange'];
        onBlur: FormikHandlers['handleBlur'];
        value: any;
        name: string;
      },
      form: FormikProps<any>,
    ) => void;
    autoUnsetServerError?: boolean;
    parse?: (value: string) => string;
    format?: (value: string) => string;
    id?: string;
    placeholderText?: string;
  };

export const SwSelectField = ({
  field,
  form,
  options = [],
  column = { width: '9', widthS: '12' },
  labelColumn = { width: '3', widthS: '12' },
  label = '',
  modBlock = true,
  modRequired,
  inputOnChange,
  autoUnsetServerError = true,
  format,
  parse,
  id,
  ...props
}: SwSelectProps) => {
  const errorMessage = useFormError({ field, form });

  return (
    <SwFormColumn>
      <SwFormGrid>
        <SwFormColumn {...labelColumn}>
          <SwFormMessageLabel
            modRequired={modRequired}
            htmlFor={id || field.name}
          >
            {label}
          </SwFormMessageLabel>
        </SwFormColumn>
        <SwFormColumn {...column}>
          <SwFormInput>
            <SwSelect
              id={id || field.name}
              name={field.name}
              data-testid={dynamicDataTest(dataTest.SwSelectField, {
                fieldName: id || field.name,
              })}
              {...props}
              value={(format ? format(field.value) : field.value) || ''}
              onBlur={field.onBlur}
              modBlock={modBlock}
              modRequired={modRequired}
              modError={!!errorMessage}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                e.target.value = parse ? parse(e.target.value) : e.target.value;

                field.onChange(e);
                if (inputOnChange) {
                  inputOnChange(e, field, form);
                }
              }}
            >
              {options.map(({ value, text }, i) => {
                return (
                  <option key={`${text}${i}`} value={value}>
                    {text}
                  </option>
                );
              })}
            </SwSelect>
            <SwErrorMessageForField
              field={field}
              form={form}
              autoUnsetServerError={autoUnsetServerError}
              testId={dynamicDataTest(dataTest.SwSelectFieldError, {
                fieldName: field.name,
              })}
            />
          </SwFormInput>
        </SwFormColumn>
      </SwFormGrid>
    </SwFormColumn>
  );
};
