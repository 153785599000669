import { i18nKeys } from '@kaa/i18n/common/keys';
import { Link } from '@reach/router';
import React, {
  AllHTMLAttributes,
  createElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useEventListener } from '../modal/utils/useEventListener';

export type Tab = {
  to?: string;
  href?: string;
  hash?: string;
  tagName?: string;
  'data-testid'?: string;
  isActive?: boolean;
  uid: string;
  header: string | ReactNode;
  isDisabled?: boolean;
  className?: string;
  onClick?: (event: any) => void;
};

type TabsProps<T = HTMLDivElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  'data-testid'?: string;
  tabs: Tab[];
};

export const SwTabs = <T extends HTMLDivElement>({
  tagName,
  tabs = [],
  children,
  ...rest
}: TabsProps<T>) => {
  const TagName = tagName || 'div';
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [activeTabLabel, setActiveTabLabel] = useState<string | ReactNode>('');
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [currentTabs, setCurrentTabs] = useState<Tab[]>(tabs);
  const tabsRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentTabs(tabs);
    if (window.location.hash) {
      selectTab(getTabIndexByHash(window.location.hash));
    }
  }, [tabs]);

  useEffect(() => {
    if (window.location.hash) {
      selectTab(getTabIndexByHash(window.location.hash));
    }
    const activeTab = tabs.find(({ isActive }) => !!isActive);
    if (activeTab) {
      setActiveTabLabel(activeTab.header);
    }
  }, []);

  useEventListener(
    'hashchange',
    () => selectTab(getTabIndexByHash(window.location.hash)),
    window,
  );

  const keyupHandler = (event: any) => {
    switch (event.keyCode) {
      case 37: {
        // empty
      }
      // fallsthrough
      case 38: {
        selectTab(activeTabIndex - 1);
        // left arrow
        break;
      }
      // fallsthrough
      case 39: {
        // empty
      }
      // fallsthrough
      case 40: {
        // right arrow
        selectTab(activeTabIndex + 1);
        break;
      }
      default:
    }
  };

  const toggleMobileNav = () =>
    setIsMobileNavOpen((isMobileNavOpen) => !isMobileNavOpen);

  const getTabIndexByHash = (hash: string) => {
    const index = currentTabs.findIndex((tab) => {
      return tab.hash === hash;
    });

    if (index === -1) {
      return currentTabs.findIndex((tab) => !!tab.isActive);
    }

    return index;
  };

  const selectTab = (selectedTabIndex: number, updateHash = true) => {
    let newTabIndex = selectedTabIndex;

    if (newTabIndex > currentTabs.length - 1) {
      newTabIndex = currentTabs.length - 1;
    } else if (newTabIndex < 0) {
      newTabIndex = 0;
    }

    const selectedTab = currentTabs[newTabIndex];

    if (!selectedTab || selectedTab.isDisabled) {
      return;
    }

    setActiveTabIndex(newTabIndex);
    setCurrentTabs((currentTabs) =>
      currentTabs.map((tab) => ({
        ...tab,
        isActive: selectedTab.uid === tab.uid,
      })),
    );

    setActiveTabLabel(selectedTab.header);
    if (tabsRef && tabsRef.current) {
      const active = tabsRef.current.children[newTabIndex]
        .firstChild as HTMLElement;
      active.focus();
    }

    if (updateHash && selectedTab.hash) {
      window.location.hash = selectedTab.hash;
    }
  };

  return createElement(
    TagName,
    {
      ...rest,
      'data-vl-tabs': true,
      'data-vl-tabs-responsive-label': t(i18nKeys.general.navigation),
    },
    <>
      <div className="vl-tabs__wrapper">
        <ul
          className="vl-tabs"
          data-vl-tab-list
          role="tablist"
          data-vl-show={isMobileNavOpen}
          aria-hidden={!isMobileNavOpen}
          onKeyUp={keyupHandler}
          ref={tabsRef}
        >
          {currentTabs.map((tab, index) => {
            const TagNameLink = tab.tagName || Link;
            return (
              <li
                key={tab.uid}
                className={tab.isActive ? 'vl-tab vl-tab--active' : 'vl-tab'}
              >
                {createElement<any>(
                  TagNameLink,
                  {
                    role: 'tab',
                    'aria-selected': tab.isActive,
                    'data-testid': tab['data-testid']
                      ? tab['data-testid']
                      : `tab-link-${tab.uid}`,
                    id: `tab-link-${tab.uid}`,
                    onClick: (event: React.MouseEvent) => {
                      selectTab(index);
                      if (tab.onClick) {
                        tab.onClick(event);
                      }
                    },
                    className: `vl-tab__link ${tab.className}`,
                    ...(tab.to && { to: tab.to, 'aria-controls': tab.to }),
                    ...(tab.hash && {
                      href: tab.hash,
                      'aria-controls': tab.hash,
                    }),
                    ...(tab.href && {
                      href: tab.href,
                    }),
                  },
                  tab.header,
                )}
              </li>
            );
          })}
        </ul>
        <button
          type="button"
          data-vl-tabs-toggle
          aria-expanded={isMobileNavOpen}
          className="vl-tabs__toggle"
          data-vl-close={isMobileNavOpen}
          onClick={toggleMobileNav}
        >
          <span>{activeTabLabel || t(i18nKeys.general.activeTabLabel)}</span>
        </button>
      </div>
    </>,
  );
};
