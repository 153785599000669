import { RegionCode } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwColumn, SwDocument, SwTitle } from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../common/config';
import { REGION_WORKFOLLOWUP_URL } from '../../../../constants';
import { dataTest } from '../../../../datatest/keys';
import {
  EventLabel,
  sendDownloadEventToGTM,
} from '../../../../utils/google-analytics';

export const PrestationsWorkFollowUpSection = () => {
  const { t } = useTranslation();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const linkToTemplate = t(
    i18nKeys.worksManagement.workFollowUp.xlsTemplate.title,
  );

  return (
    <SwColumn width="12" widthS="12">
      <SwTitle tagName="h2" className="vl-u-spacer--xsmall">
        {t(i18nKeys.worksManagement.workFollowUp.title)}
      </SwTitle>
      <p className="vl-u-spacer--small">
        {t(i18nKeys.worksManagement.workFollowUp.introduction)}
      </p>
      <SwDocument
        className="vl-u-spacer"
        data-testid={dataTest.worksManagement.workFollowUp.downloadXLSTemplate}
        metadata={`${t(
          i18nKeys.worksManagement.workFollowUp.xlsTemplate.mimeType,
        )} - 191kb`}
        download
        mimeType={t(i18nKeys.worksManagement.workFollowUp.xlsTemplate.mimeType)}
        title={t(i18nKeys.worksManagement.workFollowUp.xlsTemplate.title)}
        href={t(REGION_WORKFOLLOWUP_URL[regionCode])}
        onClick={() =>
          sendDownloadEventToGTM(EventLabel.WORKS_FOLLOW_UP, linkToTemplate)
        }
      />
    </SwColumn>
  );
};
