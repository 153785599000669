import { City, Street, TitleCode } from '@kaa/api/customers';
import { Mask } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AddressFieldNames,
  Icon,
  SwCheckboxField,
  SwFormAddress,
  SwFormGrid,
  SwInputCalendarField,
  SwInputTextField,
  SwSelectField,
  SwTitle,
  SwFormColumn,
  SwIcon,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterCompleteScreenFieldNames } from '../RegisterCompleteScreen.constants';

type RegisterMainInfoFormProps = {
  availableCities?: City[];
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
  modDisabled?: boolean;
  isEidTitle?: boolean;
};

export const RegisterMainInfoForm = ({
  availableCities = [],
  getStreets,
  modDisabled = false,
  isEidTitle = false,
}: RegisterMainInfoFormProps) => {
  const { t } = useTranslation();

  const titleOptions = Object.values(TitleCode).map((title: TitleCode) => ({
    value: title,
    text: t(i18nKeys.general.title[title]),
  }));

  return (
    <>
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--xsmall">
        {t(i18nKeys.profile.personalInformation.title)}
      </SwTitle>

      <SwFormColumn>
        <p>
          <SwIcon
            icon={Icon.ALERT_CIRCLE}
            className="vl-u-text--action"
            style={{ marginRight: '1rem' }}
          />
          {t(i18nKeys.profile.personalInformation.subTitle)}
        </p>
        <br />
      </SwFormColumn>

      <SwFormGrid modStacked>
        <Field
          name={RegisterCompleteScreenFieldNames.TITLE}
          label={t(i18nKeys.general.label.title)}
          component={SwSelectField}
          modDisabled={isEidTitle}
          options={titleOptions}
          modRequired
        />

        <Field
          name={RegisterCompleteScreenFieldNames.FIRSTNAME}
          label={t(i18nKeys.general.label.firstName)}
          component={SwInputTextField}
          modDisabled={modDisabled}
          modRequired
        />

        <Field
          name={RegisterCompleteScreenFieldNames.LASTNAME}
          label={t(i18nKeys.general.label.name)}
          component={SwInputTextField}
          modDisabled={modDisabled}
          modRequired
        />

        <Field
          name={RegisterCompleteScreenFieldNames.BIRTHDATE}
          label={t(i18nKeys.profile.dateOfBirth)}
          component={SwInputCalendarField}
          modDisabled={modDisabled}
          modRequired
          visualFormat="d/m/Y"
          arial-label={`${t(i18nKeys.profile.dateOfBirth)} (${t(
            i18nKeys.general.label.dateFormat,
          )})`}
          format={(date: string) =>
            date ? [DateTime.fromISO(date).toJSDate()] : undefined
          }
          parse={(dates: Date[]) =>
            dates && dates.length
              ? DateTime.fromJSDate(dates[0]).toISODate()
              : ''
          }
        />

        <Field
          name={RegisterCompleteScreenFieldNames.NISS}
          label={t(i18nKeys.profile.nationalNumber)}
          component={SwInputTextField}
          modDisabled={modDisabled}
          modRequired
          mask={Mask.nationalNumber}
        />

        <SwFormAddress
          fieldNamePrefix={RegisterCompleteScreenFieldNames.MAIN_ADDRESS}
          availableCities={availableCities}
          getStreets={getStreets}
          modDisabled={modDisabled}
          modRequired={[
            AddressFieldNames.POSTCODE,
            AddressFieldNames.CITY,
            AddressFieldNames.HOUSE_NUMBER,
            AddressFieldNames.STREET,
          ]}
        />

        <Field
          name={
            RegisterCompleteScreenFieldNames.USE_MAIN_ADDRESS_FOR_DELIVERY_ADDRESS
          }
          label={t(i18nKeys.general.label.itIsMyDeliveryAddress)}
          component={SwCheckboxField}
          column={{ pushS: '0', widthS: '12', push: '3', width: '9' }}
        />
      </SwFormGrid>
    </>
  );
};
