import { FiscalAttestation } from '@kaa/api/customers';
import { StorageKeys } from '@kaa/common/enums';
import { toCurrencyFormat } from '@kaa/common/utils';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwColumn,
  SwDataTable,
  SwIcon,
  SwLink,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import {
  CurrentDocument,
  FiscalAttestationCodes,
  MINFIN_URL,
} from '../FiscalAttestationScreen.constants';

type FiscalAttestationDetailsProps = {
  fiscalAttestation: FiscalAttestation;
  openModal: (currentDocument: CurrentDocument) => void;
};

export const FiscalAttestationDetails = ({
  fiscalAttestation,
  openModal,
}: FiscalAttestationDetailsProps) => {
  const { t } = useTranslation();

  const { taxationYear, fiscalAttestationData, fiscalYear } = fiscalAttestation;

  const years = {
    income: taxationYear,
    exercice: fiscalYear,
  };

  if (!fiscalAttestationData) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer--small">
          {t(i18nKeys.fiscalAttestation.exercice.title, years)}
        </SwTitle>
        <p className="vl-u-spacer--medium">
          {t(i18nKeys.fiscalAttestation.exercice.infoNotReady, years)}
        </p>
      </>
    );
  }

  const { totalAmount, totalVouchers, documentId } = fiscalAttestationData;

  localStorage.setItem(StorageKeys.LAST_SEEN_TAXATION_YEAR, String(fiscalYear));

  if (!documentId) {
    return (
      <>
        <SwTitle tagName="h2" className="vl-u-spacer--small">
          {t(i18nKeys.fiscalAttestation.exercice.title, years)}
        </SwTitle>
        <p className="vl-u-spacer--medium">
          {t(i18nKeys.fiscalAttestation.exercice.infoNotApplicable, years)}
        </p>
      </>
    );
  }

  const totalAmountCodes = t(i18nKeys.fiscalAttestation.exercice.codes, {
    primary: FiscalAttestationCodes.TOTAL_AMOUNT_PRIMARY,
    secondary: FiscalAttestationCodes.TOTAL_AMOUNT_SECONDARY,
  });

  const totalVouchersCodes = t(i18nKeys.fiscalAttestation.exercice.codes, {
    primary: FiscalAttestationCodes.TOTAL_VOUCHERS_PRIMARY,
    secondary: FiscalAttestationCodes.TOTAL_VOUCHERS_SECONDARY,
  });

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--small">
        {t(i18nKeys.fiscalAttestation.exercice.title, years)}
      </SwTitle>
      <p className="vl-u-spacer--small">
        {t(i18nKeys.fiscalAttestation.exercice.info, years)}
      </p>
      <SwColumn width="6" widthM="8" widthS="12">
        <SwDataTable modCollapsedS className="vl-u-spacer--medium">
          <thead>
            <tr>
              <th>{totalAmountCodes}</th>
              <th>{totalVouchersCodes}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-title={totalAmountCodes}>
                {toCurrencyFormat(totalAmount)}
              </td>
              <td data-title={totalVouchersCodes}>
                {t(i18nKeys.general.voucher, {
                  count: totalVouchers,
                })}
              </td>
            </tr>
          </tbody>
        </SwDataTable>
      </SwColumn>
      <SwLink
        className="vl-u-spacer--small"
        tagName="button"
        onClick={(e) => {
          e.preventDefault();
          openModal({
            fiscalYear,
            documentId,
          });
        }}
        data-testid={dynamicDataTest(dataTest.fiscalAttestation.attestation, {
          file: fiscalYear,
        })}
      >
        <SwIcon
          icon={Icon.FILE_DOWNLOAD}
          style={{ marginRight: '0.5rem', fontSize: '4rem' }}
          modLarge
        />
        {fiscalYear}
      </SwLink>
      <p>
        {t(i18nKeys.fiscalAttestation.info)}{' '}
        <SwLink
          modIconAfter
          icon={Icon.EXTERNAL}
          data-testid={dataTest.fiscalAttestation.taxonweb}
          target="_blank"
          to={MINFIN_URL}
        >
          {t(i18nKeys.fiscalAttestation.taxonweb)}
        </SwLink>
      </p>
    </>
  );
};
