import React, { AllHTMLAttributes, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/common/keys';
import { useVisuallyHidden } from '../util/hooks/useVisuallyHidden';
import { mergeClassNames } from '../utilities';

type PagerBoundsProps<T = HTMLLIElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  from: string | number;
  to: string | number;
  total: string | number;
};

const getClasses = () => {
  return ['vl-pager__element'].join(' ');
};

export const SwPagerBounds = <T extends HTMLElement>({
  tagName,
  className,
  from,
  to,
  total,
  ...rest
}: PagerBoundsProps<T>) => {
  const { t } = useTranslation();
  const visuallyHidden = useVisuallyHidden();
  const TagName = tagName || 'li';

  const classes = getClasses();

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    <>
      <span ref={visuallyHidden}>{t(i18nKeys.general.page)}</span>
      <strong>
        {from} - {to}
      </strong>{' '}
      {`${t(i18nKeys.general.pager.of)} ${total}`}
    </>,
  );
};
