import i18n from 'i18next';
import { AvailableLanguages } from '../enums/AvailableLanguages';

const EURO_SYMBOL = '€';
const EURO_LABEL = 'EUR';

/**
 * Return a formatted value / price with the euro symbol or label according to
 * given parameters and current locale language.
 *
 * ie: '10 €', '€10', '10 EUR', 'EUR 10',
 *
 * @param value the value, price, you need to add symbol or label to
 * @param shouldUseSymbol whether you should add the euro symbol "€" or not,
 *        default to false since the symbol should only be used is graphs
 */
export const toCurrencyFormat = (
  value: number,
  shouldUseSymbol = false,
): string => {
  if (shouldUseSymbol) {
    // return '€10' for dutch and english locales else '10 €'
    return i18n.language === AvailableLanguages.DUTCH ||
      i18n.language === AvailableLanguages.ENGLISH
      ? `${EURO_SYMBOL}${toPriceFormat(value)}`
      : `${toPriceFormat(value)} ${EURO_SYMBOL}`;
  }

  // return 'EUR 10' for english locale else '10 EUR'
  return i18n.language === AvailableLanguages.ENGLISH
    ? `${EURO_LABEL} ${toPriceFormat(value)}`
    : `${toPriceFormat(value)} ${EURO_LABEL}`;
};

export const toPriceFormat = (value: number): string =>
  value
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    .replace(/\.([^.]+)$/, ',$1');
