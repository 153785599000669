import { Customer, CustomerActionType } from '@kaa/api/customers';
import { useActionsHandler } from '@kaa/common/handlers';
import { Routes } from '@kaa/core-app/customers/routes';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwColumn,
  SwLink,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../../constants';
import { dataTest } from '../../../../datatest/keys';
import {
  EventAction,
  EventCategory,
  EventLabel,
  EventPathname,
  sendCustomInteractionToGTM,
  sentPageViewEvent,
} from '../../../../utils/google-analytics';
import { EventLabelsModalsRoutes } from './ServicesVouchersModals.types';

const eventLabels = {
  [Routes.SERVICES_VOUCHERS_ORDER]: EventLabel.ORDER_SCREEN,
  [Routes.SERVICES_VOUCHERS_WALLET]: EventLabel.WALLET_SCREEN,
} as Record<EventLabelsModalsRoutes, EventLabel>;

const dataTests = {
  [Routes.SERVICES_VOUCHERS_ORDER]: dataTest.myorders,
  [Routes.SERVICES_VOUCHERS_WALLET]: dataTest.wallet,
} as Record<
  EventLabelsModalsRoutes,
  typeof dataTest.myorders | typeof dataTest.wallet
>;

const getEvents = (
  modal: Modals,
  route: EventLabelsModalsRoutes,
  category: EventCategory,
  pathname: EventPathname,
) => () => {
  toggleModalById(modal);
  sendCustomInteractionToGTM(category, EventAction.CLICK, eventLabels[route]);
  sentPageViewEvent({
    pathname,
  });
};

type ServicesVouchersModalsButtonsProps = {
  customer: Customer;
  route: EventLabelsModalsRoutes;
};

export const ServicesVouchersModalsButtons = ({
  customer,
  route,
}: ServicesVouchersModalsButtonsProps) => {
  const { t } = useTranslation();

  const canOrder = useActionsHandler(customer.resourceId, [
    CustomerActionType.CAN_ORDER,
  ]);

  const canAskRefund = useActionsHandler(customer.resourceId, [
    CustomerActionType.CAN_ASK_REFUND,
  ]);

  const canAskExchange = useActionsHandler(customer.resourceId, [
    CustomerActionType.CAN_ASK_EXCHANGE,
  ]);

  return (
    <>
      <SwColumn width="10" widthS="12">
        <SwButton
          data-testid={dataTests[route].buyNewVouchers}
          type="submit"
          modLarge
          modDisabled={!canOrder}
          onClick={getEvents(
            Modals.ORDER_MODAL_ID,
            route,
            EventCategory.ORDER_VOUCHERS_CTA,
            EventPathname.ORDER_ORDER_MODAL_ORDER,
          )}
        >
          {t(i18nKeys.general.cta.order)}
        </SwButton>
      </SwColumn>
      {(canAskRefund || canAskExchange) && (
        <SwColumn width="10" widthS="12">
          <SwActionGroup modCollapseS className="vl-u-spacer">
            {canAskRefund && (
              <SwLink
                data-testid={dataTests[route].askRefundVouchers}
                onClick={getEvents(
                  Modals.REFUND_MODAL_ID,
                  route,
                  EventCategory.REFUND_REQUEST_CTA,
                  EventPathname.REFUND_MODAL,
                )}
              >
                {t(i18nKeys.general.cta.refund)}
              </SwLink>
            )}
            {canAskRefund && canAskExchange && (
              <span style={{ marginRight: '1.4rem' }}>
                {t(i18nKeys.general.or)}
              </span>
            )}
            {canAskExchange && (
              <SwLink
                tagName="button"
                data-testid={dataTests[route].askExchangeVouchers}
                onClick={getEvents(
                  Modals.EXCHANGE_MODAL_ID,
                  route,
                  EventCategory.EXCHANGE_REQUEST_CTA,
                  EventPathname.EXCHANGE_MODAL,
                )}
              >
                {t(i18nKeys.general.cta.exchange)}
              </SwLink>
            )}
          </SwActionGroup>
        </SwColumn>
      )}
    </>
  );
};
