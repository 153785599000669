import { BankAccount } from '@kaa/api/customers';
import { toCurrencyFormat } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AlertType,
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modals } from '../../../../../../constants';

type RefundConfirmationProps = {
  quantity: number;
  amount: number;
  confirmedBankDetails: BankAccount;
};

export const RefundElectronicConfirmation = ({
  quantity,
  amount,
  confirmedBankDetails,
}: RefundConfirmationProps) => {
  const { t } = useTranslation();
  const confirmationValues = {
    quantity,
    amount: toCurrencyFormat(amount),
    confirmedBankDetails: confirmedBankDetails.iban,
  };

  return (
    <>
      <SwColumn>
        <SwTitle tagName="h2" tagStyle="h3">
          {t(i18nKeys.refund.success.title)}
        </SwTitle>
        <p className="vl-u-spacer--medium">
          <Trans
            count={quantity}
            i18nKey={i18nKeys.refund.success.confirmationText}
            values={confirmationValues}
          >
            We are processing the refund of{' '}
            <strong>{{ quantity }} vouchers</strong>. You’ll receive soon a bank
            transfer of <strong>{{ amount }} euros</strong> on{' '}
            <strong>{confirmedBankDetails}</strong>
          </Trans>
        </p>
      </SwColumn>

      <SwColumn>
        <SwAlert
          title={t(i18nKeys.refund.success.alert.title)}
          titleTagName="h3"
          content={t(i18nKeys.refund.success.alert.body)}
          type={AlertType.INFORMATION}
          icon={Icon.INFO}
        />
      </SwColumn>

      <SwActionGroup>
        <SwButton
          modBlock
          modLarge
          onClick={() => toggleModalById(Modals.REFUND_MODAL_ID)}
        >
          {t(i18nKeys.refund.success.cta.backToWallet)}
        </SwButton>
      </SwActionGroup>
    </>
  );
};
