/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type PaymentMethod =
  | 'BANK_TRANSFER'
  | 'ONLINE_PAYMENT'
  | 'INSTANT_PAYMENT'
  | 'VISA'
  | 'MASTERCARD'
  | 'BANCONTACT';

export const PaymentMethod = {
  BANK_TRANSFER: 'BANK_TRANSFER' as PaymentMethod,
  ONLINE_PAYMENT: 'ONLINE_PAYMENT' as PaymentMethod,
  INSTANT_PAYMENT: 'INSTANT_PAYMENT' as PaymentMethod,
  VISA: 'VISA' as PaymentMethod,
  MASTERCARD: 'MASTERCARD' as PaymentMethod,
  BANCONTACT: 'BANCONTACT' as PaymentMethod,
};
