export enum IDPModelTenant {
  Provider = 'provider',
  Customer = 'customer',
}
export type IDPModel = {
  password: null | string;
  allowRememberLogin: boolean;
  externalProviders: Array<{
    authenticationScheme: string;
    displayName: string;
  }>;
  isExternalLoginOnly: boolean;
  enableLocalLogin: boolean;
  rememberLogin: boolean;
  returnUrl: string;
  visibleExternalProviders: Array<{
    authenticationScheme: string;
    displayName: string;
  }>;
  externalLoginScheme: null | string;
  tenant: null | IDPModelTenant;
  username: null | string;
  uiLocale: null | string;
};
