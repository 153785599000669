import { RouteActive } from '@kaa/common/types';
import { BreadcrumbItem } from '@kaa/core-app/common/types';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwBreadcrumb,
  SwBreadcrumbItem,
  SwLink,
  SwPopover,
  SwPopverRenderProps,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoute, Routes } from '../../routes';
import {
  EventAction,
  EventCategory,
  sendCustomInteractionToGTM,
} from '../../utils/google-analytics';
import { BREADCRUMB_ITEMS } from './Breadcrumb.constants';

type BreadcrumbProps = {
  activeRoute?: RouteActive<Routes> | null;
};

export const Breadcrumb = ({ activeRoute }: BreadcrumbProps) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<Array<BreadcrumbItem<Routes>>>();

  useEffect(() => {
    if (activeRoute) {
      setItems(BREADCRUMB_ITEMS[activeRoute.route.id]);
    }
  }, [activeRoute]);

  if (!items || !activeRoute) {
    return null;
  }

  const showBackToInformationLink = activeRoute.route.id === Routes.LANDING;

  return (
    <SwBreadcrumb modBar>
      {showBackToInformationLink && (
        <SwBreadcrumbItem
          className="vl-u-text--small"
          to={t(i18nKeys.vl.general.serviceVouchers.publicWebsite.url)}
          linkText={t(
            i18nKeys.vl.header.funcHeader.cta.backToInfoWebsite.title,
          )}
        />
      )}
      {items.map(({ id, label, popover, testid, base }, index) => {
        const route = getRoute(id);
        if (!base && !activeRoute.route.path.includes(route.path)) {
          return null;
        }
        return (
          <SwBreadcrumbItem
            key={`item-${id}`}
            className="vl-u-text--small"
            useSeparator={showBackToInformationLink || !!index}
            to={route.path}
            linkText={t(label)}
            linkTestid={testid}
            modActive={!!activeRoute && activeRoute.route.id === id}
            onClick={() =>
              sendCustomInteractionToGTM(
                EventCategory.BREADCRUMB,
                EventAction.CLICK,
                route.id,
              )
            }
          >
            {popover && (
              <SwPopover
                className="vl-functional-header__action vl-u-text--small"
                offsetX="-9"
                offsetY="12"
                style={{ float: 'right', position: 'relative', top: '.3rem' }}
                modLeft
                basicLabel={{
                  hidden: true,
                }}
                modIconRotated
                action={popover.testid}
                icon={Icon.EXPAND}
              >
                {({ close }: SwPopverRenderProps) => (
                  <ul className="vl-popover__link-list">
                    {popover.links.map((link) => {
                      const linkRoute = getRoute(link.id);
                      return (
                        <li
                          key={`link-${link.id}`}
                          className="vl-popover__link-list__item"
                        >
                          <SwLink
                            modBlock
                            data-testid={link.testid}
                            to={linkRoute.path}
                            onClick={() => {
                              sendCustomInteractionToGTM(
                                EventCategory.BREADCRUMB,
                                route.id,
                                linkRoute.id,
                              );
                              close();
                            }}
                          >
                            {t(link.label)}
                          </SwLink>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </SwPopover>
            )}
          </SwBreadcrumbItem>
        );
      })}
    </SwBreadcrumb>
  );
};
