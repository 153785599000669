export enum StorageKeys {
  LAST_SELECTED_CUSTOMER_ID = 'last_selected_customer_id',
  LAST_SELECTED_LANGUAGE = 'last_selected_language',
  LAST_SEEN_TAXATION_YEAR = 'last_seen_taxation_year',
  ORDER_PREVIOUS_PAYMENT_TYPE = 'order_previous_payment_type',
  DISPLAY_LANGUAGE = 'display_language',
  ENTERPRISES = 'enterprises',
  NOTIFICATION_COUNT = 'notification_count',
  PENDING_WORKS = 'PENDING_WORKS',
  CONFIG = 'CONFIG',
  LAST_USED_WORKER_DATA = 'LAST_USED_WORKER_DATA',
  LAST_ENCODED_WORK = 'LAST_ENCODED_WORK',
  LAST_SELECTED_PRESTATIONS_TAB = 'last_selected_prestations_tab',
  MANUAL_EL_REMITTANCE_PREFERENCE = 'manual_el_remittance_preference',
}
