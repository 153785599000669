import { useAuthState } from '@kaa/auth/common';
import { useEffect } from 'react';
import { AuthContext } from '../../types';

export const UserAccessTokenExpiringListener = () => {
  const {
    registerOnAccessTokenExpiring,
    unregisterOnAccessTokenExpiring,
    signinSilent,
  } = useAuthState<AuthContext>();

  const refreshAuth = async () => {
    await signinSilent();
  };

  useEffect(() => {
    registerOnAccessTokenExpiring(refreshAuth);
    return () => unregisterOnAccessTokenExpiring(refreshAuth);
  }, [
    registerOnAccessTokenExpiring,
    unregisterOnAccessTokenExpiring,
    refreshAuth,
  ]);

  return null;
};
