/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type HandiwebRequestBeneficiary = 'SELF' | 'DEPENDENT';

export const HandiwebRequestBeneficiary = {
  SELF: 'SELF' as HandiwebRequestBeneficiary,
  DEPENDENT: 'DEPENDENT' as HandiwebRequestBeneficiary,
};
