import faker from 'faker';
import { DateTime } from 'luxon';
import { Dashboard, DashboardResponse } from '../model';

export const getDashboardResponse = (
  dashboardResponse: Partial<DashboardResponse> = {},
): DashboardResponse => {
  return { data: getDashboard(dashboardResponse.data) };
};

export const getDashboard = (dashboard: Partial<Dashboard> = {}): Dashboard => {
  return {
    notEnoughVouchers: faker.random.boolean(),
    pendingServicesCount: Math.random() < 0.6 ? faker.random.number(30) : 0,
    closestVouchersToExpiryQuantity:
      Math.random() < 0.6 ? faker.random.number(30) : 0,
    closestVouchersToExpiryExpirationDate: faker.date.future().toISOString(),
    availableVouchersQuantity: faker.random.number(30),
    latestAvailableFiscalAttestationYear: faker.helpers.randomize([
      DateTime.local()
        .startOf('year')
        .get('year'),
      DateTime.local()
        .startOf('year')
        .plus({ year: -1 })
        .get('year'),
    ]),
    ...dashboard,
  };
};
