import { Address, SupportType, VouchersOrderItemCreate } from '@kaa/api/customers';

export interface Options {
  color_border?: string;
  color_border_selected?: string;
  color_button?: string;
  color_button_text?: string;
  color_checkbox?: string;
  color_checkbox_checkmark?: string;
  color_details?: string;
  color_header?: string;
  color_link?: string;
  color_text?: string;
  color_text_secondary?: string;
  radius_border?: string;
}

export interface KlarnaInput {
  voucherOrderItems: VouchersOrderItemCreate[];
  supportType: SupportType;
  redirectUrl: string;
  saveSupportType: boolean;
  paperVoucherDeliveryAddress: Address | undefined;
  useMainAddressForPaperVoucherDelivery: boolean;
}

export interface KlarnaLoadCallbackResponse {
  show_form: boolean;
  error?: KlarnaLoadError;
}

export interface KlarnaAuthoriseCallbackResponse {
  show_form: boolean;
  approved: boolean;
  authorization_token?: string;
  finalize_required?: boolean;
  error?: KlarnaLoadError;
}

interface KlarnaLoadError {
  invalid_fields: string[];
}

export enum KlarnInternalWidgetError {
  InvalidClientTokenError = 'InvalidClientTokenError',
  PaymentLoadError = 'PaymentLoadError',
  AuthorizeError = 'AuthorizeError',
}

export interface KlarnaAuthorizeModel {
  purchase_country: string;
  purchase_currency: string;
  locale: string;
  billing_address: BillingAddress;
  customer: Customer;
}

interface BillingAddress {
  given_name: string;
  family_name: string;
  email: string;
  title: string;
  street_address: string;
  street_address2?: string;
  postal_code: string;
  city: string;
  region: string;
  phone: string;
  country: string;
}

interface Customer {
  date_of_birth: string;
  gender?: string;
  national_identification_number: string;
}
