import {
  email,
  equalToFieldInsensitive,
  existsInError,
  notEqualTo,
  required,
  setError,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwGrid,
  SwInputTextField,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../datatest/keys';
import { sendModalInteractionToGTM } from '../../../../utils/google-analytics';
import { createValidatorWithServerErrorHandled } from '../../../../utils/validation';
import { ProfileScreenChangeEmailFieldNames } from '../ProfileScreen.constants';
import { ProfileUserInfoChangeEmailFormState } from '../ProfileScreen.type';

type ConfirmEmailModalProps = {
  userEmailAddress: string;
  onSubmit: (
    data: ProfileUserInfoChangeEmailFormState,
    FormikActions: FormikActions<ProfileUserInfoChangeEmailFormState>,
  ) => Promise<[Error | undefined, any | undefined]>;
};

export const ProfileUserInfoChangeEmailModal = ({
  userEmailAddress,
  onSubmit,
  setConfirmCloseModal,
}: SwModalRenderProps<ConfirmEmailModalProps>) => {
  const { t } = useTranslation();

  const validate = createValidatorWithServerErrorHandled({
    emailAddress: [required, email, notEqualTo(userEmailAddress)],
    confirmEmailAddress: [
      existsInError(
        `${ProfileScreenChangeEmailFieldNames.CONFIRM_EMAIL_ADDRESS}.notPasted`,
        {
          id: i18nKeys.errors.form.noPaste,
          defaultMessage: 'You cannot paste',
          displayFocussed: true,
        },
      ),
      email,
      required,
      equalToFieldInsensitive(
        ProfileScreenChangeEmailFieldNames.EMAIL_ADDRESS,
        {
          defaultMessage: `Must be equal to ${t(
            i18nKeys.general.label.emailAddress,
          )}`,
          id: i18nKeys.errors.form.equal,
          displayFocussed: false,
          values: {
            value: t(i18nKeys.general.label.emailAddress),
          },
        },
      ),
    ],
  });

  return (
    <Formik
      initialValues={{
        emailAddress: '',
        confirmEmailAddress: '',
      }}
      validate={validate}
      onSubmit={(...props) => {
        sendModalInteractionToGTM('Change Email Address Modal', 'submit');
        onSubmit(...props);
      }}
      render={({ handleSubmit, dirty, isSubmitting, setFieldValue }) => {
        setConfirmCloseModal(dirty);
        return (
          <SwGrid>
            <SwColumn width="12">
              <SwTitle tagName="h2">
                {t(i18nKeys.profile.changeEmailModal.title)}
              </SwTitle>
            </SwColumn>
            <SwColumn width="12" widthS="12">
              <SwForm
                onSubmit={handleSubmit}
                data-testid={dataTest.profile.changeEmailModal.formChangeEmail}
              >
                <SwFormGrid modStacked>
                  <SwFormColumn>
                    <SwFormGrid>
                      <SwFormColumn width="12">
                        <span className="vl-form__label">
                          {t(i18nKeys.profile.changeEmailModal.oldEmailAddress)}
                        </span>
                      </SwFormColumn>

                      <SwFormColumn
                        width="12"
                        data-testid={
                          dataTest.profile.changeEmailModal.oldEmailAdress
                        }
                      >
                        {userEmailAddress}
                      </SwFormColumn>
                    </SwFormGrid>
                  </SwFormColumn>
                  <Field
                    data-testid={
                      dataTest.profile.changeEmailModal.newEmailAddress
                    }
                    name={ProfileScreenChangeEmailFieldNames.EMAIL_ADDRESS}
                    column={{ width: '12' }}
                    label={t(i18nKeys.general.label.emailAddress)}
                    labelColumn={{ width: '12' }}
                    component={SwInputTextField}
                    type="email"
                    autoComplete="email"
                    modRequired
                  />
                  <Field
                    data-testid={
                      dataTest.profile.changeEmailModal.confirmEmailAddress
                    }
                    name={
                      ProfileScreenChangeEmailFieldNames.CONFIRM_EMAIL_ADDRESS
                    }
                    column={{ width: '12' }}
                    label={t(i18nKeys.profile.emailAddressConfirmation)}
                    labelColumn={{ width: '12' }}
                    component={SwInputTextField}
                    type="email"
                    modRequired
                    onPaste={(e: ClipboardEvent) => {
                      e.preventDefault();
                      setError({
                        setFieldValue,
                        name: `${ProfileScreenChangeEmailFieldNames.CONFIRM_EMAIL_ADDRESS}.notPasted`,
                      });
                    }}
                  />
                  <SwActionGroup>
                    <SwButton
                      data-testid={
                        dataTest.profile.changeEmailModal.submitChangeEmailModal
                      }
                      type="submit"
                      modDisabled={!dirty}
                      modLoading={isSubmitting}
                    >
                      {t(i18nKeys.general.cta.validate)}
                    </SwButton>
                  </SwActionGroup>
                  <SwFormSubmitMessage />
                </SwFormGrid>
              </SwForm>
            </SwColumn>
          </SwGrid>
        );
      }}
    />
  );
};
