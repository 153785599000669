import faker from 'faker';
import { DateTime } from 'luxon';
import {
  CustomerFiscalAttestationResponse,
  FiscalAttestation,
  FiscalAttestationData,
  FiscalAttestationDocumentParams,
  FiscalAttestationInformation,
  FiscalAttestationParams,
  StringValueFileCreatedResponse,
} from '../model';
import { getRefundOrExchangeDocument } from './wallet';

const getFiscalAttestationData = (): FiscalAttestationData => {
  const totalVouchers = faker.random.number({ min: 0, max: 100 });
  const documentId =
    faker.random.number({ min: 1, max: 10 }) > 3
      ? String(faker.random.number(999))
      : undefined;

  return {
    totalAmount: totalVouchers * faker.random.number({ min: 9, max: 11 }),
    totalVouchers,
    documentId,
  };
};

const getFiscalAttestation = (taxationYear: number): FiscalAttestation => {
  const fiscalAttestationData = faker.random.boolean()
    ? getFiscalAttestationData()
    : undefined;

  return {
    fiscalYear: taxationYear + 1,
    taxationYear,
    fiscalAttestationData,
  };
};

const getFiscalAttestationInformation = (
  params?: FiscalAttestationParams,
): FiscalAttestationInformation => {
  const lastYear = DateTime.local()
    .startOf('year')
    .plus({ year: -1 })
    .get('year');

  return {
    activeFiscalAttestation: getFiscalAttestation(lastYear),
    historicalFiscalsAttestations: [
      ...Array(faker.random.number({ min: 0, max: 5 })),
    ]
      .map((e, i) => getFiscalAttestation(lastYear - (i + 1)))
      .filter(
        (e) => e.fiscalAttestationData && e.fiscalAttestationData.documentId,
      ),
  };
};

export const getFiscalAttestationResponse = (
  params?: FiscalAttestationParams,
): CustomerFiscalAttestationResponse => {
  return { data: getFiscalAttestationInformation(params) };
};

export const getFiscalAttestationDocumentResponse = (
  params?: FiscalAttestationDocumentParams,
): Blob | StringValueFileCreatedResponse => {
  return getRefundOrExchangeDocument(params?.sendResultInStringFormat);
};
