import { SwContainer, SwFetchErrorMessage } from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { getPath, Routes } from '../routes';

export const ServiceUnavailableScreen = (props: RouteComponentProps) => {
  return (
    <SwContainer error>
      <SwFetchErrorMessage
        onClick={() => {
          // use window.location.href navigate to / to force reload
          window.location.href = getPath(Routes.LANDING);
        }}
      />
    </SwContainer>
  );
};
