import { i18nKeys } from '@kaa/i18n/customers/keys';
import { BreadcrumbItem } from '../../../common/types';
import { dataTest } from '../../datatest/keys';
import { Routes } from '../../routes';

export const BREADCRUMB_ITEMS_DASHBOARD: Array<BreadcrumbItem<Routes>> = [
  {
    id: Routes.DASHBOARD,
    label: i18nKeys.navigation.dashboard,
    base: true,
    testid: dataTest.header.Dashboard,
    popover: {
      testid: dataTest.header.toggleDashboard,
      links: [
        {
          id: Routes.SERVICES_VOUCHERS,
          label: i18nKeys.navigation.serviceVouchers,
          testid: 'SwLink-a-servicesVouchers',
        },
        {
          id: Routes.PRESTATIONS,
          label: i18nKeys.navigation.prestations,
          testid: 'SwLink-a-prestations',
        },
        {
          id: Routes.ACCOUNT,
          label: i18nKeys.navigation.profile,
          testid: 'SwLink-a-myinfos',
        },
      ],
    },
  },
];

const BREADCRUMB_ITEMS_SERVICE_VOUCHERS = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.SERVICES_VOUCHERS,
    label: i18nKeys.navigation.serviceVouchers,
    testid: dataTest.header.linkWallet,
    popover: {
      testid: dataTest.header.toggleServicesVouchers,
      links: [
        {
          id: Routes.SERVICES_VOUCHERS_WALLET,
          label: i18nKeys.navigation.wallet,
          testid: dataTest.header.linkWallet,
        },
        {
          id: Routes.SERVICES_VOUCHERS_ORDER,
          label: i18nKeys.navigation.orders,
          testid: dataTest.header.linkMyOrder,
        },
      ],
    },
  },
  {
    id: Routes.SERVICES_VOUCHERS_WALLET,
    label: i18nKeys.navigation.wallet,
  },
  {
    id: Routes.SERVICES_VOUCHERS_ORDER,
    label: i18nKeys.navigation.orders,
  },
];

const BREADCRUMB_ITEMS_ACCOUNTS = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.ACCOUNT,
    label: i18nKeys.navigation.account,
    testid: dataTest.header.linkAccount,
    popover: {
      testid: dataTest.header.toggleAccount,
      links: [
        {
          id: Routes.ACCOUNT_PROFILE,
          label: i18nKeys.navigation.account,
          testid: dataTest.header.linkAccount,
        },
        {
          id: Routes.ACCOUNT_PREFERENCES,
          label: i18nKeys.navigation.preferences,
          testid: dataTest.header.linkPreferences,
        },
        {
          id: Routes.ACCOUNT_FISCAL_ATTESTATION,
          label: i18nKeys.navigation.fiscalAttestation,
          testid: dataTest.header.linkFiscalAttestation,
        },
      ],
    },
  },
  {
    id: Routes.ACCOUNT_PROFILE,
    label: i18nKeys.navigation.profile,
  },
  {
    id: Routes.ACCOUNT_PREFERENCES,
    label: i18nKeys.navigation.preferences,
  },
  {
    id: Routes.ACCOUNT_FISCAL_ATTESTATION,
    label: i18nKeys.navigation.fiscalAttestation,
  },
];

const BREADCRUMB_ITEMS_PRESTATIONS = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.PRESTATIONS,
    label: i18nKeys.navigation.prestations,
    testid: dataTest.header.linkPrestationManagement,
    popover: {
      testid: dataTest.header.toggleAccount,
      links: [
        {
          id: Routes.PRESTATIONS_WORKS_MANAGEMENT,
          label: i18nKeys.navigation.prestations,
          testid: dataTest.header.linkPrestationManagement,
        },
        {
          id: Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY,
          label: i18nKeys.navigation.worksHistory,
          testid: dataTest.header.linkPrestationManagementHistory,
        },
        {
          id: Routes.PRESTATIONS_PLAN_A_LEAVE,
          label: i18nKeys.navigation.planALeave,
          testid: dataTest.header.linkLeave,
        },
      ],
    },
  },
  {
    id: Routes.PRESTATIONS_WORKS_MANAGEMENT,
    label: i18nKeys.navigation.prestations,
  },
  {
    id: Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY,
    label: i18nKeys.navigation.worksHistory,
  },
  {
    id: Routes.PRESTATIONS_PLAN_A_LEAVE,
    label: i18nKeys.navigation.planALeave,
  },
];

const BREADCRUMB_ITEMS_LANGUAGE_REQUEST = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.LANGUAGE_REQUEST,
    label: i18nKeys.navigation.languageRequest,
  },
];

const BREADCRUMB_ITEMS_HANDIWEB_REQUEST = [
  ...BREADCRUMB_ITEMS_DASHBOARD,
  {
    id: Routes.HANDIWEB_REQUEST,
    label: i18nKeys.navigation.handiwebRequest,
  },
];

const BREADCRUMB_ITEMS_REGISTER = [
  {
    id: Routes.LANDING,
    base: true,
    label: i18nKeys.navigation.serviceVouchersLogin,
  },
  {
    id: Routes.REGISTER,
    label: i18nKeys.navigation.register,
  },
];

const BREADCRUMB_ITEMS_LANDING = [
  {
    id: Routes.LANDING,
    base: true,
    label: i18nKeys.navigation.landing,
  },
];

export const BREADCRUMB_ITEMS: Partial<
  { [key in Routes]: Array<BreadcrumbItem<Routes>> }
> = {
  [Routes.DASHBOARD]: BREADCRUMB_ITEMS_DASHBOARD,
  [Routes.ACCOUNT]: BREADCRUMB_ITEMS_ACCOUNTS,
  [Routes.ACCOUNT_PROFILE]: BREADCRUMB_ITEMS_ACCOUNTS,
  [Routes.ACCOUNT_PREFERENCES]: BREADCRUMB_ITEMS_ACCOUNTS,
  [Routes.ACCOUNT_FISCAL_ATTESTATION]: BREADCRUMB_ITEMS_ACCOUNTS,
  [Routes.PRESTATIONS]: BREADCRUMB_ITEMS_PRESTATIONS,
  [Routes.PRESTATIONS_WORKS_MANAGEMENT]: BREADCRUMB_ITEMS_PRESTATIONS,
  [Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY]: BREADCRUMB_ITEMS_PRESTATIONS,
  [Routes.PRESTATIONS_PLAN_A_LEAVE]: BREADCRUMB_ITEMS_PRESTATIONS,
  [Routes.SERVICES_VOUCHERS]: BREADCRUMB_ITEMS_SERVICE_VOUCHERS,
  [Routes.SERVICES_VOUCHERS_WALLET]: BREADCRUMB_ITEMS_SERVICE_VOUCHERS,
  [Routes.SERVICES_VOUCHERS_ORDER]: BREADCRUMB_ITEMS_SERVICE_VOUCHERS,
  [Routes.SERVICES_VOUCHERS_ORDER_DETAILS]: BREADCRUMB_ITEMS_SERVICE_VOUCHERS,
  [Routes.SERVICES_VOUCHERS_ORDER_PENDING]: BREADCRUMB_ITEMS_SERVICE_VOUCHERS,
  [Routes.LANGUAGE_REQUEST]: BREADCRUMB_ITEMS_LANGUAGE_REQUEST,
  [Routes.HANDIWEB_REQUEST]: BREADCRUMB_ITEMS_HANDIWEB_REQUEST,
  [Routes.REGISTER]: BREADCRUMB_ITEMS_REGISTER,
  [Routes.REGISTER_COMPLETE]: BREADCRUMB_ITEMS_REGISTER,
  [Routes.REGISTER_SUMMARY]: BREADCRUMB_ITEMS_REGISTER,
  [Routes.LANDING]: BREADCRUMB_ITEMS_LANDING,
  [Routes.ROOT]: BREADCRUMB_ITEMS_LANDING,
};
