import { useAuthState } from '@kaa/auth/common';
import { CommonRoutes } from '@kaa/common/constants';
import { RouteActive } from '@kaa/common/types';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwFunctionalHeader,
  SwIcon,
  SwTabs,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { getPath, getRoute, Routes } from '../../routes';
import { AuthContext } from '../../types';
import { useDispatchUpdateSelectedCustomer } from '../../utils';
import {
  EventAction,
  sendMainNavigationEventToGTM,
} from '../../utils/google-analytics';
import './VlFunctionalHeader.style.scss';
import { VlFunctionHeaderTab } from './VlFunctionalHeader.types';
import { getTabs } from './VlFunctionalHeader.utils';
import { VlFunctionalHeaderActions } from './VlFunctionalHeaderActions';

const ROUTES_TO_SHOW_INFORMATION_LINK = [Routes.ROOT, Routes.LANDING];

type VlFunctionalHeaderProps = {
  activeRoute?: RouteActive<Routes> | null;
};

export const VlFunctionalHeader = ({
  activeRoute,
}: VlFunctionalHeaderProps) => {
  const { t } = useTranslation();
  const { customers, selectedCustomerId, logout } = useAuthState<AuthContext>();
  const [{ loading }, logoutFunc] = useAsyncCallback(logout);
  const dispatchUpdateSelectedCustomer = useDispatchUpdateSelectedCustomer();
  const [tabs, setTabs] = useState<VlFunctionHeaderTab[]>();

  useEffect(() => {
    if (activeRoute) {
      setTabs(getTabs(activeRoute.route.id, t));
    }
  }, [activeRoute, t]);

  if (!tabs || !activeRoute) {
    return null;
  }

  const showBackToInformationLink = ROUTES_TO_SHOW_INFORMATION_LINK.includes(
    activeRoute.route.id as CommonRoutes,
  );

  return (
    <SwFunctionalHeader
      title={t(i18nKeys.general.appTitle)}
      actions={
        selectedCustomerId && (
          <VlFunctionalHeaderActions
            customers={customers}
            selectedCustomerId={selectedCustomerId}
            logout={logoutFunc}
            loadingLogout={loading}
            dispatchUpdateSelectedCustomer={dispatchUpdateSelectedCustomer}
          />
        )
      }
      modBorder
      subActions={
        tabs && (
          <SwTabs
            tabs={[
              ...(showBackToInformationLink
                ? [
                    {
                      tagName: 'a',
                      href: t(
                        i18nKeys.vl.general.serviceVouchers.publicWebsite.url,
                      ),
                      className: 'vl-link vl-u-display-inline-flex',
                      header: (
                        <>
                          <SwIcon
                            className="vl-link__icon"
                            icon={Icon.ARROW_LEFT}
                          />
                          {t(
                            i18nKeys.vl.header.funcHeader.cta.backToInfoWebsite
                              .title,
                          )}
                        </>
                      ),
                      'data-testid':
                        dataTest.header.backToPublicServiceVouchersLogin,
                      uid: 'showBackToInformationLink',
                      onClick: () => {
                        sendMainNavigationEventToGTM(
                          EventAction.BACK,
                          'public website',
                        );
                      },
                    },
                  ]
                : []),
              ...tabs.map(({ id, active, ...rest }) => ({
                ...rest,
                id,
                active,
                to: getPath(id),
                isActive: active || id === activeRoute.route.id,
                onClick: () => {
                  const route = getRoute(id);
                  if (route.parent) {
                    sendMainNavigationEventToGTM(route.parent.id, id);
                  } else {
                    sendMainNavigationEventToGTM(
                      activeRoute.route.id === Routes.DASHBOARD
                        ? EventAction.CLICK
                        : EventAction.BACK,
                      id,
                    );
                  }
                },
              })),
            ]}
          />
        )
      }
    />
  );
};
