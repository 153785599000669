import { AllHTMLAttributes, createElement } from 'react';
import { mergeClassNames } from '../utilities';

type DataTableProps<T = HTMLTableElement> = AllHTMLAttributes<T> & {
  tagName?: string;
  modMatrix?: boolean;
  modLine?: boolean;
  modZebra?: boolean;
  modHover?: boolean;
  modCollapsedXs?: boolean;
  modCollapsedS?: boolean;
  modCollapsedM?: boolean;
};

type Classes = {
  modMatrix?: boolean;
  modLine?: boolean;
  modZebra?: boolean;
  modHover?: boolean;
  modCollapsedXs?: boolean;
  modCollapsedS?: boolean;
  modCollapsedM?: boolean;
};
const getClasses = ({
  modMatrix,
  modLine,
  modZebra,
  modHover,
  modCollapsedM,
  modCollapsedS,
  modCollapsedXs,
}: Classes) => {
  return [
    'vl-data-table',
    modMatrix ? 'vl-data-table--matrix' : '',
    modLine ? 'vl-data-table--lined' : '',
    modZebra ? 'vl-data-table--zebra' : '',
    modHover ? 'vl-data-table--hover' : '',
    modCollapsedXs ? 'vl-data-table--collapsed-xs' : '',
    modCollapsedS ? 'vl-data-table--collapsed-s' : '',
    modCollapsedM ? 'vl-data-table--collapsed-m' : '',
  ].join(' ');
};

export const SwDataTable = <T extends HTMLElement>({
  tagName,
  modMatrix,
  modLine,
  modZebra,
  modHover,
  className,
  children,
  modCollapsedM,
  modCollapsedS,
  modCollapsedXs,
  ...rest
}: DataTableProps<T>) => {
  const TagName = tagName || 'table';

  const classes = getClasses({
    modMatrix,
    modLine,
    modZebra,
    modHover,
    modCollapsedM,
    modCollapsedS,
    modCollapsedXs,
  });

  return createElement(
    TagName,
    { className: mergeClassNames(classes, className), ...rest },
    children,
  );
};
