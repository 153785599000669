import { RouteActive } from '@kaa/common/types';
import { LocationProvider } from '@reach/router';
import React, { ReactElement } from 'react';
import { pickRoute, Routes } from '../routes';

type RenderReactElement = (props: {
  activeRoute: RouteActive<Routes> | null;
  [key: string]: unknown;
}) => JSX.Element | null;

export const ActiveRouteProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[] | RenderReactElement;
}) => {
  return (
    <LocationProvider>
      {({ location }) => {
        const activeRoute = pickRoute(location.pathname);

        if (typeof children === 'function') {
          return children({ activeRoute });
        }

        return React.Children.map(children, (child) =>
          React.cloneElement(child, { activeRoute }),
        );
      }}
    </LocationProvider>
  );
};
