import faker from 'faker';
import { DateTime } from 'luxon';
import {
  Bundle,
  CustomerExchangeableVouchersResponse,
  VouchersExchangeCreate,
  VouchersExchangeCreatedResponse,
  VouchersExchangeCreatedResponseDataData,
} from '../model';
import { getOrders } from './wallet';

const getCustomerExchangeableVouchers = (
  customerId: string | number,
): Bundle[] => {
  return getOrders();
};

const getVouchersExchangeCreatedResponse = (
  vouchersExchangeCreate: VouchersExchangeCreate,
): VouchersExchangeCreatedResponseDataData => {
  return {
    fullName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    contractNumber: faker.random.number(999),
    purchaseId: faker.random.number(999),
    previousExpirationDate: DateTime.fromJSDate(
      faker.date.recent(),
    ).toISODate(),
    expirationDate: DateTime.fromJSDate(faker.date.future()).toISODate(),
    quantity: faker.random.number({ min: 0, max: 99 }),
    faceValue: faker.random.arrayElement([9, 10]),
  };
};

export const getCustomerExchangeableVouchersResponse = (
  customerId: string | number,
): CustomerExchangeableVouchersResponse => ({
  data: getCustomerExchangeableVouchers(customerId),
});

export const confirmVouchersExchangeResponse = (
  vouchersExchangeCreate: VouchersExchangeCreate,
): VouchersExchangeCreatedResponse => ({
  data: getVouchersExchangeCreatedResponse(vouchersExchangeCreate),
});
