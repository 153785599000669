import { POSTCODES_CITIES } from '@kaa/api/common/mocks';
import faker from 'faker';
import {
  ContractType,
  CustomerBuyableVouchers,
  CustomerBuyableVouchersResponse,
  CustomerVoucherPurchasesResponse,
  CustomerVoucherSet,
  GetVoucherOrdersHistoryParams,
  PaymentMethod,
  PurchaseStatus,
  SupportType,
  VoucherOrder,
  VoucherOrderResponse,
  VoucherPurchase,
  VoucherPurchaseResponse,
} from '../model';

const FACE_VALUES = [9, 10, 11];

const ACTIONS_TYPE_VALUES = [
  'additionalProp1',
  'additionalProp2',
  'additionalProp3',
];

const getActions = (funct: () => {}): {} =>
  Object.values(ACTIONS_TYPE_VALUES).reduce(
    (acc: {}, action: string) => ({ ...acc, [action]: funct() }),
    {},
  );

const getVoucherSet = (
  customerBuyableVoucher: Partial<CustomerVoucherSet> = {},
): CustomerVoucherSet => {
  return {
    quantity: faker.random.number({ min: 1, max: 99 }),
    faceValue: faker.helpers.randomize(FACE_VALUES),
    ...customerBuyableVoucher,
  };
};

const getVoucherSets = (
  customerBuyableVoucher: Array<
    Partial<CustomerVoucherSet>
  > = FACE_VALUES.slice(
    0,
    faker.random.number({
      min: 1,
      max: 3,
    }),
  ).map((faceValue) => ({ faceValue })),
): CustomerVoucherSet[] => {
  return customerBuyableVoucher.map(getVoucherSet);
};

const getVoucher = (
  id: number = faker.random.number({ min: 1, max: 10000 }),
) => ({
  id,
  contractType: faker.helpers.randomize(
    Object.values(ContractType),
  ) as ContractType,
  paymentMethod: faker.helpers.randomize(
    Object.values(PaymentMethod),
  ) as PaymentMethod,
  supportType: faker.helpers.randomize(
    Object.values(SupportType),
  ) as SupportType,
  voucherSets: getVoucherSets(),
});

const getCustomerBuyableVouchers = ({
  pendingOrderAlreadyExists = faker.random.boolean(),
  buyableVouchers,
}: Partial<CustomerBuyableVouchers> = {}): CustomerBuyableVouchers => {
  return {
    pendingOrderAlreadyExists,
    supportTypePreference: faker.helpers.randomize(Object.values(SupportType)),
    buyableVouchers: getVoucherSets(buyableVouchers),
  };
};

export const getVoucherOrder = (
  voucherOrder: Partial<VoucherOrder> = {},
): VoucherOrder => {
  const POSTCODE_CITY = faker.helpers.randomize(POSTCODES_CITIES);
  const voucher = getVoucher();
  const totalPrice = voucher.voucherSets.reduce(
    (acc, e) => acc + e.quantity * e.faceValue,
    0,
  );
  return {
    ...voucher,
    totalPrice,
    requestedQuantity: faker.random.number({ min: 10, max: 100 }),
    paymentDelay: 30,
    paymentDueDate: faker.date.future().toISOString(),
    beneficiaryBankAccount: 'BE71199234524669',
    beneficiaryName: faker.name.lastName(),
    beneficiaryPostcodeAndCity: `${
      POSTCODE_CITY.postcode
    } ${faker.helpers.randomize(POSTCODE_CITY.names)}`,
    beneficiaryStreetAndHouseNumber: `${faker.address.streetName()}, ${faker.random.number(
      999,
    )}`,
    structuredMessage: faker.lorem.word(),
    paymentMethod: PaymentMethod.BANK_TRANSFER,
    ...voucherOrder,
  };
};

const STATUS_PAPER = [
  PurchaseStatus.PAID,
  PurchaseStatus.PROCESSING,
  PurchaseStatus.SENT,
];

const STATUS_ELECTRONIC = [PurchaseStatus.PAID, PurchaseStatus.RECEIVED];

const STATUS = {
  [SupportType.PAPER]: {
    [PaymentMethod.BANK_TRANSFER]: STATUS_PAPER,
    [PaymentMethod.ONLINE_PAYMENT]: STATUS_PAPER,
    [PaymentMethod.INSTANT_PAYMENT]: STATUS_PAPER,
    [PaymentMethod.VISA]: STATUS_PAPER,
    [PaymentMethod.MASTERCARD]: STATUS_PAPER,
    [PaymentMethod.BANCONTACT]: STATUS_PAPER,
  },
  [SupportType.ELECTRONIC]: {
    [PaymentMethod.BANK_TRANSFER]: STATUS_ELECTRONIC,
    [PaymentMethod.ONLINE_PAYMENT]: STATUS_ELECTRONIC,
    [PaymentMethod.INSTANT_PAYMENT]: STATUS_ELECTRONIC,
    [PaymentMethod.VISA]: STATUS_ELECTRONIC,
    [PaymentMethod.MASTERCARD]: STATUS_ELECTRONIC,
    [PaymentMethod.BANCONTACT]: STATUS_ELECTRONIC,
  },
};

export const getVoucherPurchase = (
  voucherPurchase: Partial<VoucherPurchase> = {},
): VoucherPurchase => {
  const { id = faker.random.number({ min: 1, max: 10000 }) } = voucherPurchase;
  const voucher = getVoucher(id);
  const status = faker.helpers.randomize(
    STATUS[voucher.supportType][voucher.paymentMethod],
  ) as PurchaseStatus;

  return {
    ...voucher,
    status,
    paymentDate: faker.date.recent().toISOString(),
    shippingDate: faker.date.future().toISOString(),
    estimatedDaysToDelivery: faker.random.number({ min: 3, max: 6 }),
    ...voucherPurchase,
  };
};

export const getBuyableVouchersResponse = ({
  data,
}: Partial<
  CustomerBuyableVouchersResponse
> = {}): CustomerBuyableVouchersResponse => {
  return { data: getCustomerBuyableVouchers(data) };
};

export const getPendingOrderResponse = (
  voucherOrderResponse: Partial<{ data: Partial<VoucherOrder> }> = {},
): VoucherOrderResponse => ({
  data: getVoucherOrder(voucherOrderResponse.data),
});

export const getVoucherPurchaseResponse = (
  voucherPurchase: Partial<{ data: Partial<VoucherPurchase> }> = {},
): VoucherPurchaseResponse => ({
  data: getVoucherPurchase(voucherPurchase.data),
});

export const getVoucherOrdersHistoryResponse = (
  params?: GetVoucherOrdersHistoryParams,
): CustomerVoucherPurchasesResponse => {
  const date = faker.date.recent();
  return {
    actions: getActions(() => getActions(() => faker.random.boolean())),
    data: {
      pendingOrder: getVoucherOrder(),
      purchaseHistory: [...Array(faker.random.number({ min: 1, max: 99 }))].map(
        (e) => {
          date.setDate(
            date.getDate() - faker.random.number({ min: 1, max: 30 }),
          );
          return getVoucherPurchase({ paymentDate: date.toISOString() });
        },
      ),
    },
  };
};
