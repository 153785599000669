import faker from 'faker';
import { GlobalRegionContext, GlobalRegionContextReponse } from '../model';
import { getLanguages } from './language';

export const MAX_VACANCY_DAYS = 30;

export const getRegion = (
  globalRegionContext: Partial<GlobalRegionContext> = {},
): GlobalRegionContext => {
  return {
    refundablePercentage: faker.random.number({ min: 1, max: 100 }),
    sodexoPostBox: faker.random.number({ min: 1, max: 100 }).toString(),
    maxVacancyDays: MAX_VACANCY_DAYS,
    availableLanguages: getLanguages(),
    servicesAutomaticValidationInDays: faker.random.number({
      min: 1,
      max: 4,
    }),
    minimalOrderQuantity: faker.helpers.randomize([1, 10]),
    contactUsPhoneNumber: faker.phone.phoneNumber('32 2 ### ## ##'),
    ...globalRegionContext,
  };
};

export const getGlobalRegionContextResponse = ({
  data,
}: Partial<GlobalRegionContextReponse> = {}): GlobalRegionContextReponse => {
  return { data: getRegion(data) };
};
