import { SupportType } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwFormColumn,
  SwFormInput,
  SwRadio,
} from '@kaa/ui-flanders/components';
import { RequiredStar } from '@kaa/ui-flanders/components/form-message/SwFormRequiredStar';
import React from 'react';
import { useTranslation } from 'react-i18next';

type RefundSupportTypeProps = {
  canRefundElectronic: boolean;
  setSupportType: (supportType: SupportType) => void;
  supportType: SupportType;
};

export const RefundSupportType = ({
  canRefundElectronic,
  setSupportType,
  supportType,
}: RefundSupportTypeProps) => {
  const { t } = useTranslation();
  return (
    <SwFormColumn>
      <fieldset>
        <legend className="vl-form__label vl-u-spacer--xsmall">
          {t(i18nKeys.general.label.voucherType)}
          <RequiredStar />
        </legend>

        {canRefundElectronic ? (
          <>
            <SwFormColumn>
              <SwFormInput>
                <SwRadio
                  name="supportType"
                  id={SupportType.ELECTRONIC}
                  defaultChecked={supportType === SupportType.ELECTRONIC}
                  modRequired
                  onChange={(
                    e: React.FormEvent<HTMLInputElement> & {
                      target: { id: SupportType };
                    },
                  ) => {
                    setSupportType(e.target.id);
                  }}
                >
                  {t(i18nKeys.general.supportType.ELECTRONIC)}
                </SwRadio>
              </SwFormInput>
            </SwFormColumn>

            <SwFormColumn>
              <SwFormInput>
                <SwRadio
                  name="supportType"
                  id={SupportType.PAPER}
                  defaultChecked={supportType === SupportType.PAPER}
                  modRequired
                  onChange={(
                    e: React.FormEvent<HTMLInputElement> & {
                      target: { id: SupportType };
                    },
                  ) => setSupportType(e.target.id)}
                >
                  {t(i18nKeys.general.supportType.PAPER)}
                </SwRadio>
              </SwFormInput>
            </SwFormColumn>
          </>
        ) : (
          <SwFormColumn>{t(i18nKeys.general.supportType.PAPER)}</SwFormColumn>
        )}
      </fieldset>
    </SwFormColumn>
  );
};
