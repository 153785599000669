import { useMaskedInput } from '@kaa/common/utils';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { FormikHandlers, FormikProps } from 'formik';
import React, { AllHTMLAttributes, ChangeEvent, useRef } from 'react';
import { dataTest } from '../../../../datatest/keys';
import { SwFormColumn, SwFormGrid } from '../../../core';
import { Column } from '../../../core/SwColumn';
import { SwFormInput } from '../../../form-input/SwFormInput';
import { SwFormMessageLabel } from '../../../form-message/SwFormMessageLabel';
import { SwInputField } from '../../../input-field/SwInputField';
import { useFormError } from '../../../util/hooks/useFormError';
import { FieldPropsClean } from '../../../utilities';
import { SwErrorMessageForField } from '../error-message-for-field/SwErrorMessageForField';

export type SwInputTextFieldProps = AllHTMLAttributes<HTMLInputElement> &
  FieldPropsClean & {
    type?: string;
    label?: string;
    helpText?: string;
    column?: Column;
    labelColumn?: Column;
    modError?: boolean;
    modSmall?: boolean;
    modInline?: boolean;
    modBlock?: boolean;
    modDisabled?: boolean;
    modRequired?: boolean;
    inputOnChange?: (
      event: ChangeEvent<HTMLInputElement>,
      field: {
        onChange: FormikHandlers['handleChange'];
        onBlur: FormikHandlers['handleBlur'];
        value: any;
        name: string;
      },
      form: FormikProps<any>,
    ) => void;
    autoUnsetServerError?: boolean;
    mask: Array<string | RegExp> | ((value: string) => Array<string | RegExp>);
    parse?: (value: string) => string;
    format?: (value: string) => string;
    id?: string;
  };

export const SwInputTextField = ({
  field,
  form,
  type = 'text',
  label = '',
  helpText = '',
  column = { width: '9', widthS: '12' },
  labelColumn = { width: '3', widthS: '12' },
  inputOnChange,
  modBlock = true,
  autoUnsetServerError = true,
  modRequired,
  mask,
  parse,
  format,
  children,
  id,
  ...props
}: SwInputTextFieldProps) => {
  const errorMessage = useFormError({ field, form });

  const ref = useRef(null);

  const currentValue = (format ? format(field.value) : field.value) || '';

  const onChange = useMaskedInput({
    input: ref,
    value: currentValue,
    mask,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      // We have to do mutation for formik
      e.target.value = parse ? parse(e.target.value) : e.target.value;
      field.onChange(e.target.name)(e);
      if (inputOnChange) {
        inputOnChange(e, field, form);
      }
    },
  });

  return (
    <SwFormColumn>
      <SwFormGrid>
        <SwFormColumn {...labelColumn}>
          <SwFormMessageLabel
            modRequired={modRequired}
            htmlFor={id || field.name}
          >
            {label}
          </SwFormMessageLabel>
          {helpText && <p className="vl-form__annotation">{helpText}</p>}
        </SwFormColumn>
        <SwFormColumn {...column}>
          <SwFormInput>
            <SwInputField
              id={id || field.name}
              name={field.name}
              ref={ref}
              type={type}
              data-testid={dynamicDataTest(dataTest.SwInputTextField, {
                fieldName: id || field.name,
              })}
              {...props}
              value={!mask ? currentValue : undefined}
              modBlock={modBlock}
              modRequired={modRequired}
              onBlur={(e) => {
                /* To fix the case where the click is trigger after the error css rendering
                (if you want to reproduce the bug go into a SwModal where you can click into an input and  then directly click to the close button) */
                if (
                  (e.relatedTarget as HTMLButtonElement)?.nodeName ===
                    'BUTTON' &&
                  (e.relatedTarget as HTMLButtonElement).classList.contains(
                    'vl-modal-dialog__close',
                  )
                ) {
                  return;
                }
                field.onBlur(e);
              }}
              onChange={onChange}
              modError={!!errorMessage}
            />
            <SwErrorMessageForField
              field={field}
              form={form}
              autoUnsetServerError={autoUnsetServerError}
              testId={dynamicDataTest(dataTest.SwInputTextFieldError, {
                fieldName: field.name,
              })}
            />
          </SwFormInput>
          {children}
        </SwFormColumn>
      </SwFormGrid>
    </SwFormColumn>
  );
};
