import {
  Address,
  City,
  ContractType,
  Customer,
  PaperVouchersExchangeCreate,
  SupportType,
} from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import {
  SwForm,
  SwFormSubmitMessage,
  SwModalRenderProps,
} from '@kaa/ui-flanders/components';
import { Formik, FormikActions } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { useApi } from '../../../../../../utils';
import {
  EventCategory,
  EventLabel,
  sendCustomInteractionToGTM,
  sentPageViewEvent,
} from '../../../../../../utils/google-analytics';
import { handleApiError } from '../../../../../../utils/validation';
import { ExchangeModalPaperFormKeys } from '../../ExchangeModal.constants';
import { ExchangeModalPaperAmount } from './ExchangeModalPaperAmount';
import { ExchangeModalPaperDelivery } from './ExchangeModalPaperDelivery';
import { ExchangeModalPaperSuccess } from './ExchangeModalPaperSuccess';

type ExchangeModalPaperProps = {
  customer: Customer;
  children: ReactNode;
  availableCities: City[];
  sodexoPostBox: string;
  onSuccess?: () => void;
  targetSupportType: SupportType;
};

type PaperVouchersFormProps = {
  [ExchangeModalPaperFormKeys.AUTHORIZATION_ID]: number | string;
  [ExchangeModalPaperFormKeys.QUANTITY]: number;
  [ExchangeModalPaperFormKeys.DELIVERY_ADDRESS]: Address;
  [ExchangeModalPaperFormKeys.USE_MAIN_ADDRESS]: boolean;
};

export const ExchangeModalPaper = ({
  customer,
  onSuccess,
  availableCities,
  sodexoPostBox,
  setConfirmCloseModal,
  targetSupportType,
  children,
}: SwModalRenderProps<ExchangeModalPaperProps>) => {
  const { customers } = useApi();

  const {
    contracts,
    mainAddress,
    useMainAddressForPaperVoucherDelivery,
    paperVoucherDeliveryAddress,
  } = customer;

  const [step, setStep] = useState(1);

  const [{ value: submitedForm }, submit] = useAsyncCallback(
    async (
      formikData: PaperVouchersFormProps,
      formikActions: FormikActions<PaperVouchersExchangeCreate>,
    ) => {
      const {
        quantity,
        authorizationId,
        paperVoucherDeliveryAddress,
        useMainAddressForPaperVoucherDelivery,
      } = formikData;

      const contract = contracts.find(
        (contract) =>
          String(contract.authorizationId) === String(authorizationId),
      );

      if (!contract) {
        return;
      }

      const data = {
        targetSupportType,
        quantity,
        contractType: contract.type,
        paperVoucherDeliveryAddress: useMainAddressForPaperVoucherDelivery
          ? mainAddress
          : paperVoucherDeliveryAddress,
      } as PaperVouchersExchangeCreate;

      const [error, response] = await httpTo(
        customers.confirmPaperVouchersExchange(customer.id, data),
      );

      return { error, response, formikData, formikActions };
    },
    [customers, customer, targetSupportType],
  );

  const [, getStreets] = useAsyncCallback(
    async (postcode: string, keyword: string) => {
      try {
        const {
          data: { data },
        } = await customers.getLocationsSuggestions({ postcode, keyword });
        return data;
      } catch (e) {
        return [];
      }
    },
    [customers],
  );

  useEffect(() => {
    if (submitedForm) {
      const { error, formikActions } = submitedForm;
      if (handleApiError(error, formikActions)) {
        return;
      }

      if (onSuccess) {
        onSuccess();
        sendCustomInteractionToGTM(
          EventCategory.EXCHANGE,
          `${EventLabel.PAPER} to ${targetSupportType.toLowerCase()}`,
          EventLabel.EXCHANGE_CONFIRMATION,
        );
        sentPageViewEvent({
          pathname: `/exchange-modal/paper/to-${targetSupportType.toLowerCase()}-confirmation`,
        });
      }
    }
  }, [submitedForm]);

  if (submitedForm?.response) {
    const documentBlob = new Blob([submitedForm.response.data as BlobPart], {
      type: 'application/pdf',
    });

    setConfirmCloseModal(false);

    return (
      <ExchangeModalPaperSuccess
        documentBlob={documentBlob}
        sodexoPostBox={sodexoPostBox}
      />
    );
  }

  const standardContract = contracts.find(
    (contract) => contract.type === ContractType.STANDARD,
  );

  const defaultContract = standardContract || contracts[0];

  return (
    <Formik
      onSubmit={submit}
      initialValues={{
        [ExchangeModalPaperFormKeys.AUTHORIZATION_ID]: defaultContract
          ? defaultContract.authorizationId
          : '',
        [ExchangeModalPaperFormKeys.QUANTITY]: 10,
        [ExchangeModalPaperFormKeys.USE_MAIN_ADDRESS]: useMainAddressForPaperVoucherDelivery,
        [ExchangeModalPaperFormKeys.DELIVERY_ADDRESS]: useMainAddressForPaperVoucherDelivery
          ? mainAddress
          : paperVoucherDeliveryAddress,
      }}
    >
      {({ handleSubmit, dirty, isSubmitting, values }) => {
        setConfirmCloseModal(dirty);

        const isElectronic = targetSupportType === SupportType.ELECTRONIC;

        return (
          <SwForm onSubmit={handleSubmit} style={{ width: '100%' }}>
            {step === 1 && (
              <>
                {children}
                <ExchangeModalPaperAmount
                  quantity={values.quantity}
                  contracts={contracts}
                  buttonType={isElectronic ? 'submit' : 'button'}
                  isSubmitting={isSubmitting}
                  next={isElectronic ? handleSubmit : () => setStep(2)}
                />
              </>
            )}
            {step === 2 && (
              <ExchangeModalPaperDelivery
                customer={customer}
                address={mainAddress}
                availableCities={availableCities}
                getStreets={getStreets}
                isSubmitting={isSubmitting}
                supportType={targetSupportType}
                useMainAddress={Boolean(
                  values[ExchangeModalPaperFormKeys.USE_MAIN_ADDRESS],
                )}
              />
            )}
            <SwFormSubmitMessage />
          </SwForm>
        );
      }}
    </Formik>
  );
};
