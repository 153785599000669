import Flickity from 'flickity';
/* eslint-disable */
Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

const { resize } = Flickity.prototype;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call(this);
  this.element.classList.add('flickity-resize');
};
