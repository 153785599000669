import { City, Customer, Street, SupportType } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  AddressFieldNames,
  Icon,
  SwAlert,
  SwCheckboxField,
  SwColumn,
  SwFormAddress,
  SwFormColumn,
  SwFormGrid,
  SwFormMessageLabel,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field } from 'formik';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../../../datatest/keys';
import { ExchangeModalPaperFormKeys } from '../../exchange-modal/ExchangeModal.constants';

const toggleDeliveryVariants = {
  close: { height: 0, overflow: 'hidden' },
  open: {
    height: 'auto',
    overflow: 'hidden',
    transitionEnd: {
      overflow: 'inherit',
    },
  },
};

type OrderStepDetailsProps = {
  supportType: SupportType;
  getStreets: (
    postcode: string,
    query: string,
  ) => Promise<[any, Street[] | undefined]>;
  availableCities?: City[];
  useMainAddressForPaperVoucherDelivery: boolean;
  customer: Customer;
};

export const OrderStepDetails = ({
  supportType,
  getStreets,
  availableCities = [],
  useMainAddressForPaperVoucherDelivery,
  customer,
}: OrderStepDetailsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SwColumn className="vl-u-spacer">
        <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--xsmall">
          {t(i18nKeys.newOrder.stepDetails.personalDetails)}
        </SwTitle>
        <SwGrid className="vl-u-spacer">
          <SwColumn width="4" widthM="12" className="vl-u-text--bold">
            <SwFormMessageLabel>
              {t(i18nKeys.general.label.firstName)}
            </SwFormMessageLabel>
          </SwColumn>
          <SwColumn
            width="8"
            widthM="12"
            className="vl-u-spacer--xsmall"
            data-testid={dataTest.wallet.order.details.customerFirstName}
          >
            {customer.firstName}
          </SwColumn>
          <SwColumn width="4" widthM="12" className="vl-u-text--bold">
            <SwFormMessageLabel>
              {t(i18nKeys.general.label.name)}
            </SwFormMessageLabel>
          </SwColumn>
          <SwColumn
            width="8"
            widthM="12"
            className="vl-u-spacer--xsmall"
            data-testid={dataTest.wallet.order.details.customerLastname}
          >
            {customer.lastName}
          </SwColumn>
          <SwColumn width="4" widthM="12" className="vl-u-text--bold">
            <SwFormMessageLabel>
              {t(i18nKeys.general.label.emailAddress)}
            </SwFormMessageLabel>
          </SwColumn>
          <SwColumn
            width="8"
            widthM="12"
            className="vl-u-spacer--xsmall"
            data-testid={dataTest.wallet.order.details.customerEmailAddress}
          >
            {customer.emailAddress}
          </SwColumn>
        </SwGrid>
        {supportType === SupportType.PAPER && (
          <>
            <hr className="vl-u-hr vl-u-spacer" />
            <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--xsmall">
              {t(i18nKeys.general.mainAddress)}
            </SwTitle>
            <SwGrid className="vl-u-spacer">
              <SwColumn width="4" widthM="12" className="vl-u-text--bold">
                <SwFormMessageLabel>
                  {t(i18nKeys.general.label.postcode)}
                </SwFormMessageLabel>
              </SwColumn>
              <SwColumn
                width="8"
                widthM="12"
                className="vl-u-spacer--xsmall"
                data-testid={
                  dataTest.wallet.order.details.customerMainAddressPostcode
                }
              >
                {customer.mainAddress.postcode}
              </SwColumn>
              <SwColumn width="4" widthM="12" className="vl-u-text--bold">
                <SwFormMessageLabel>
                  {t(i18nKeys.general.label.city)}
                </SwFormMessageLabel>
              </SwColumn>
              <SwColumn
                width="8"
                widthM="12"
                className="vl-u-spacer--xsmall"
                data-testid={
                  dataTest.wallet.order.details.customerMainAddressCity
                }
              >
                {customer.mainAddress.city}
              </SwColumn>
              <SwColumn width="4" widthM="12" className="vl-u-text--bold">
                <SwFormMessageLabel>
                  {t(i18nKeys.general.label.street)}
                </SwFormMessageLabel>
              </SwColumn>
              <SwColumn
                width="8"
                widthM="12"
                className="vl-u-spacer--xsmall"
                data-testid={
                  dataTest.wallet.order.details.customerMainAddressStreet
                }
              >
                {customer.mainAddress.street}
              </SwColumn>
              <SwColumn width="4" widthM="12" className="vl-u-text--bold">
                <SwFormMessageLabel>
                  {t(i18nKeys.general.label.number)}
                </SwFormMessageLabel>
              </SwColumn>
              <SwColumn
                width="8"
                widthM="12"
                className="vl-u-spacer--xsmall"
                data-testid={
                  dataTest.wallet.order.details.customerMainAddressHouseNumber
                }
              >
                {customer.mainAddress.houseNumber}
              </SwColumn>

              <SwColumn>
                <SwFormColumn className="vl-u-spacer">
                  <Field
                    name="useMainAddressForPaperVoucherDelivery"
                    label={t(i18nKeys.general.label.itIsMyDeliveryAddress)}
                    component={SwCheckboxField}
                    column={{ pushS: '0', widthS: '12', push: '4', width: '8' }}
                  />
                </SwFormColumn>
                <motion.div
                  data-testid={
                    dataTest.wallet.order.details.cardDeliveryAddress
                  }
                  initial="close"
                  animate={
                    useMainAddressForPaperVoucherDelivery ? 'close' : 'open'
                  }
                  variants={toggleDeliveryVariants}
                >
                  <SwTitle
                    tagName="h3"
                    tagStyle="h5"
                    className="vl-u-spacer--xsmall"
                  >
                    {t(i18nKeys.newOrder.stepDetails.deliveryAddress)}
                  </SwTitle>
                  <SwFormGrid modStacked style={{ marginTop: 0 }}>
                    <SwFormColumn className="vl-u-spacer--xsmall">
                      <SwAlert
                        data-testid={
                          dataTest.wallet.order.details.alertDefaultAddress
                        }
                        icon={Icon.ALERT_CIRCLE}
                        title={t(i18nKeys.newOrder.stepDetails.alert.title)}
                        titleTagName="h4"
                        content={t(i18nKeys.newOrder.stepDetails.alert.body)}
                      />
                    </SwFormColumn>
                    <SwFormAddress
                      fieldNamePrefix={String(
                        ExchangeModalPaperFormKeys.DELIVERY_ADDRESS,
                      )}
                      availableCities={availableCities}
                      getStreets={getStreets}
                      modRequired={[
                        AddressFieldNames.POSTCODE,
                        AddressFieldNames.CITY,
                        AddressFieldNames.STREET,
                        AddressFieldNames.HOUSE_NUMBER,
                      ]}
                    />
                  </SwFormGrid>
                </motion.div>
              </SwColumn>
            </SwGrid>
          </>
        )}
      </SwColumn>
    </>
  );
};
