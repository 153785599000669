import {
  Address,
  ApiError,
  KlarnaSession,
  SupportType,
  VouchersOrderCreate,
  VouchersOrderItemCreate,
} from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { CustomTrackEvent } from '@kaa/core-app/common/components';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import * as es6Promisify from 'es6-promisify';
import { useCallback, useEffect, useState } from 'react';
import { useApi, useSelectedCustomerIdState } from '../../../../../../utils';
import {
  KlarnaAuthoriseCallbackResponse,
  KlarnaInput,
  KlarnaLoadCallbackResponse,
  KlarnInternalWidgetError,
} from './klarna.type';
import { toKlarnaAuthorizeModel } from './klarna.utils';
import { useScript } from './use-script';

const { promisify } = es6Promisify;

// eslint-disable-next-line
declare var Klarna: any;

const DEFAULT_PAYMENT_KLARNA = 'pay_now';

type KlarnaSessionAnonymized = Pick<KlarnaSession, 'sessionId'> & {
  customerId: string;
};

export function useKlarna() {
  const customerId = useSelectedCustomerIdState();
  const { customers: customersApi } = useApi();

  const [isWidgetLoading, setIsWidgetLoading] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(false);

  const [isInitializedSuccessfully, setIsInitializedSuccessfully] = useState(
    false,
  );
  const [createdSessionId, setCreateSessionId] = useState<string | null>(null);
  const [sessionCustomerId, setSessionCustomerId] = useState(0);

  const [widget, setWidget] = useState<KlarnaLoadCallbackResponse | null>(null);

  const [error, setError] = useState<
    ApiError | KlarnInternalWidgetError | null | undefined
  >(null);

  const [inputState, setInputState] = useState<KlarnaInput | null>(null);

  const setInputStateDetails: (payload: KlarnaInput) => void = (
    payload: KlarnaInput,
  ) => {
    setInputState(payload);
  };

  const appInsights = useAppInsightsContext();

  const traceKlarnaCreateSessionResponse = useTrackEvent<
    KlarnaSessionAnonymized | undefined
  >(appInsights, CustomTrackEvent.ON_KLARNA_CREATE_SESSION, undefined, true);
  const traceKlarnaLoadCallbackResponse = useTrackEvent<
    (KlarnaLoadCallbackResponse & KlarnaSessionAnonymized) | undefined
  >(appInsights, CustomTrackEvent.ON_KLARNA_LOAD_CALLBACK, undefined, true);

  const traceKlarnaPaymentsAuthorizeResponse = useTrackEvent<
    (KlarnaAuthoriseCallbackResponse & KlarnaSessionAnonymized) | undefined
  >(appInsights, CustomTrackEvent.ON_KLARNA_PAYMENTS_AUTORIZE, undefined, true);

  const [
    { value: createSessionResponse, loading: sessionLoading },
    createSession,
  ] = useAsyncCallback(
    async (
      requests: VouchersOrderItemCreate[],
      supportType: SupportType,
      useMainAddressForPaperVoucherDelivery: boolean,
      saveSupportType: boolean,
      paperVoucherDeliveryAddress: any,
      customerId: string,
    ) => {
      if (inputState) {
        const customerIdNumber = Number(customerId);
        const [err, response] = await httpTo(
          customersApi.createSession(customerIdNumber, {
            supportType,
            requests,
            useMainAddressForPaperVoucherDelivery,
            saveSupportType,
            paperVoucherDeliveryAddress,
          }),
        );
  
        if (err || !response) {
          // We don't use the error from AsynCallback because we have to be able to reset error programmatically
          setError(err);
          return;
        }
  
        const {
          session: { sessionId },
        } = response.data;
  
        setCreateSessionId(sessionId);
        setSessionCustomerId(customerIdNumber);
  
        // traceKlarnaCreateSessionResponse({ customerId, sessionId });
  
        return response.data;
      }
     
    },
    [customerId, inputState],
  );

  const [
    { value: order, loading: orderLoading },
    initiateSession,
  ] = useAsyncCallback(
    async (
      customerId: string,
      sessionId: string,
      supportType: SupportType,
      requests: VouchersOrderItemCreate[],
    ) => {
      const customerIdNumber = Number(customerId);
      const [err, response] = await httpTo(
        customersApi.initiateSession(customerIdNumber, {
          supportType,
          requests,
          sessionId,
        }),
      );

      if (err || !response) {
        // We don't use the error from AsynCallback because we have to be able to reset error programmatically
        setError(err);
        return;
      }

      setIsInitializedSuccessfully(true);

      return {
        customerId,
        sessionId,
        ...response.data,
      };
    },
    [customerId, inputState],
  );

  // Load script && create createSessionResponse
  const [isJsSDKLoaded] = useScript('https://x.klarnacdn.net/kp/lib/v1/api.js');

  useEffect(() => {
    if (!inputState) {
      return;
    }

    setWidget(null);
    setIsWidgetLoading(false);
    setIsAuthorizing(false);
    setError(null);

    createSession(
      inputState.voucherOrderItems,
      inputState.supportType,
      inputState.useMainAddressForPaperVoucherDelivery,
      inputState.saveSupportType,
      inputState.paperVoucherDeliveryAddress,
      customerId,
    );
  }, [
    inputState,
    createSession,
    setWidget,
    setIsWidgetLoading,
    setIsAuthorizing,
    setError,
  ]);

  // Init Klarna SDK
  useEffect(() => {
    if (!isJsSDKLoaded || !createSessionResponse) {
      return;
    }

    setIsWidgetLoading(true);

    try {
      Klarna.Payments.init({
        client_token: createSessionResponse.session.clientToken,
      });

      // Load Widget in element
      Klarna.Payments.load(
        {
          container: '#klarna-payments-container',
          payment_method_category: DEFAULT_PAYMENT_KLARNA,
        },
        (res: KlarnaLoadCallbackResponse) => {
          setIsWidgetLoading(false);

          if (res.error) {
            setError(KlarnInternalWidgetError.PaymentLoadError);
          }

          setWidget(res);

          const { sessionId } = createSessionResponse.session;

          // traceKlarnaLoadCallbackResponse({ customerId, sessionId, ...res });
        },
      );
    } catch (e) {
      setIsWidgetLoading(false);

      setWidget(null);
      setError(KlarnInternalWidgetError.InvalidClientTokenError);
    }
  }, [
    isJsSDKLoaded,
    createSessionResponse,
    isInitializedSuccessfully,
    setIsWidgetLoading,
    setError,
    setWidget,
  ]);

  const authorizeKlarna = useCallback(
    async (vouchersOrderCreate: VouchersOrderCreate) => {
      if (!inputState || !Klarna || !createSessionResponse) {
        setError(KlarnInternalWidgetError.AuthorizeError);
        return;
      }

      const { sessionId } = createSessionResponse.session;

      const klarnaPaymentsAuthorize = promisify(Klarna.Payments.authorize);
      setIsAuthorizing(true);

      /*
       /!\ The response of the promise comeback into the reject flow due to the promisify library /!\
       /!\ that transform the first response in error and the second in response /!\
       /!\ but klarna library return the same object for error or response /!\
       /!\ Be carefull if you change the code below /!\
      */
      const response = await klarnaPaymentsAuthorize(
        {
          payment_method_category: DEFAULT_PAYMENT_KLARNA,
        },
        toKlarnaAuthorizeModel(createSessionResponse.customerData),
      ).catch(
        (error: {
          approved: boolean;
          authorization_token: string;
          finalize_required: boolean;
          show_form: boolean;
        }) => error,
      );

      // traceKlarnaPaymentsAuthorizeResponse({
      //   customerId,
      //   sessionId,
      //   ...response,
      // });

      if (!response || (response && !response.approved)) {
        setIsAuthorizing(false);
        setError(KlarnInternalWidgetError.AuthorizeError);
        return false;
      }

      setIsAuthorizing(false);

      return true;
    },
    [
      inputState,
      createSessionResponse,
      traceKlarnaPaymentsAuthorizeResponse,
      setIsAuthorizing,
      setError,
      initiateSession,
    ],
  );

  return {
    klarnaWidget: widget,
    klarnaIsJsSDKLoaded: isJsSDKLoaded,
    klarnaIsLoading:
      isWidgetLoading ||
      isAuthorizing ||
      !isJsSDKLoaded ||
      orderLoading ||
      sessionLoading,
    klarnaIsWidgetLoading: isWidgetLoading,
    klarnaIsAuthorizing: isAuthorizing,
    klarnaError: error,
    klarnaOrder: order,
    klarnaResetError: () => setError(null),
    authorizeKlarna,
    initiateSession,
    init: setInputStateDetails,
    sessionCustomerId,
    createdSessionId,
    inputState,
  };
}
