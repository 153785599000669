import { Customer, CustomerActionType, SupportType } from '@kaa/api/customers';
import { useActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwFetchErrorMessage,
  SwLoader,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../utils';
import { ExchangeModalElectronic } from './components/electronic/ExchangeModalElectronic';
import { ExchangeModalSwitch } from './components/ExchangeModalSwitch';
import { ExchangeModalPaper } from './components/paper/ExchangeModalPaper';

type RefundModalProps = {
  customer: Customer;
  onSuccess?: () => void;
};

export const ExchangeModal = ({
  customer,
  onSuccess,
  setConfirmCloseModal,
}: SwModalRenderProps<RefundModalProps>) => {
  const { t } = useTranslation();
  const { customers } = useApi();

  const canExchangeElectronic = useActionsHandler<CustomerActionType>(
    customer.resourceId,
    [CustomerActionType.CAN_ASK_EXCHANGE_ELECTRONIC],
  );

  const [supportType, setSupportType] = useState(
    canExchangeElectronic ? SupportType.ELECTRONIC : SupportType.PAPER,
  );
  const [targetSupportType, setTargetSupportType] = useState<SupportType>(
    SupportType.ELECTRONIC,
  );

  const [
    { value: exchangeData, loading, error },
    getExchangeableData,
  ] = useAsyncCallback(
    async () => {
      const [
        globalRegionContextReponse,
        locationsResponse,
        exchangeableVouchersResponse,
      ] = await Promise.all([
        customers.getGlobalRegionContext(),
        customers.getGlobalLocations(),
        customers.getCustomerExchangeableVouchers(customer.id),
      ]);

      return {
        exchangeableVouchers: exchangeableVouchersResponse.data.data,
        ...globalRegionContextReponse.data.data,
        ...locationsResponse.data.data,
      };
    },
    [customers, customer.id],
    { loading: true },
  );

  useEffect(() => {
    getExchangeableData();
  }, [getExchangeableData]);

  if (!exchangeData && loading) {
    return (
      <>
        <SwTitle tagName="h2">{t(i18nKeys.exchange.title)}</SwTitle>
        <SwLoader />
      </>
    );
  }

  if (error || !exchangeData) {
    return (
      <>
        <SwTitle tagName="h2">{t(i18nKeys.exchange.title)}</SwTitle>
        <SwFetchErrorMessage onClick={getExchangeableData} />
      </>
    );
  }

  const {
    cities: availableCities,
    sodexoPostBox,
    exchangeableVouchers,
  } = exchangeData;

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--xsmall">
        {t(i18nKeys.exchange.title)}
      </SwTitle>
      <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer">
        {t(i18nKeys.exchange.subtitle)}
      </SwTitle>
      {supportType === SupportType.ELECTRONIC ? (
        <ExchangeModalElectronic
          exchangeableVouchers={exchangeableVouchers}
          customer={customer}
          onSuccess={onSuccess}
          setConfirmCloseModal={setConfirmCloseModal}
          targetSupportType={targetSupportType}
        >
          <ExchangeModalSwitch
            canExchangeElectronic={canExchangeElectronic}
            supportType={supportType}
            setSupportType={setSupportType}
            setTargetSupportType={setTargetSupportType}
          />
        </ExchangeModalElectronic>
      ) : (
        <ExchangeModalPaper
          customer={customer}
          sodexoPostBox={sodexoPostBox}
          availableCities={availableCities}
          onSuccess={onSuccess}
          setConfirmCloseModal={setConfirmCloseModal}
          targetSupportType={targetSupportType}
        >
          <ExchangeModalSwitch
            canExchangeElectronic={canExchangeElectronic}
            setSupportType={setSupportType}
            supportType={supportType}
            setTargetSupportType={setTargetSupportType}
          />
        </ExchangeModalPaper>
      )}
    </>
  );
};
